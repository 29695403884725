import { useQueries, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { useCallback } from 'react';
import { getEngineUsageForAsset, getEngineUsageForAssets } from './requests';
import { engineUsageQueryKeys } from './queryKeys';
import { EngineUsage } from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetEngineUsageForAsset = <T = EngineUsage[]>(assetId: number, from: number | undefined, until: number | undefined, options?: Options<EngineUsage[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = engineUsageQueryKeys.forAsset(organisationId, assetId, from, until);
  return useQuery({
    queryKey,
    queryFn: async () => {
      if (from === undefined || until === undefined) return [];
      return getEngineUsageForAsset(organisationId, assetId, from, until);
    },
    enabled: from !== undefined && until !== undefined,
    ...options,
  });
};

export const useGetEngineUsageForAssets = <T = EngineUsage[]>(assetIds: number[], from?: number, until?: number, options?: Options<EngineUsage[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = engineUsageQueryKeys.forAssets(organisationId, assetIds, from, until);
  return useQuery<EngineUsage[], HttpResponseError, T>({
    queryKey,
    queryFn: async () => {
      if (from === undefined || until === undefined) return [];
      return getEngineUsageForAssets(organisationId, assetIds, from, until);
    },
    ...options,
    enabled: options?.enabled !== false && from !== undefined && until !== undefined,
  });
};

// NOTE: This is for one-off on-demand fetching of engine usage
export const useFetchEngineUsage = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useCallback(async (assetIds: number[], from: number, until: number, staleTime = 0) => Promise.all(
    await queryClient.fetchQuery({
      queryKey: engineUsageQueryKeys.forAssets(organisationId, assetIds, from, until),
      queryFn: () => getEngineUsageForAssets(organisationId, assetIds, from, until),
      staleTime,
    }).then(results => results.flat(1))
  ), [queryClient, organisationId]);
};
