import { useState, useEffect } from 'react';
import moment from 'utils/moment';
import useTimezone from 'hooks/session/useTimezone';

// This hook takes a datetime (as an ISO8601 string, Date() or moment()) and returns
// it as a string in the given format, converted to the current timezone as set
// in the Settings reducer.  Updates if the datetime, format or locale.timezone setting change.

const formatDate = (datetime, timezone, format) => {
  if (!datetime) return '--';
  const momentifiedDateTime = moment.isMoment(datetime) ? datetime : moment(datetime);
  return momentifiedDateTime.tz(timezone).format(format);
};

export default (datetime, format = 'YYYY-MM-DD hh:mm:ss z', givenTimezone = null) => {
  const tz = givenTimezone ?? useTimezone();
  const [formattedString, setFormattedString] = useState(formatDate(datetime, tz, format));

  useEffect(() => {
    setFormattedString(formatDate(datetime, tz, format));
  }, [datetime, format, tz]);

  return formattedString;
};
