import React from 'react';

const EngineExceedance = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M8,12c-0.52,0.03-0.97-0.37-1-0.89c0-0.04,0-0.08,0-0.11c-0.03-0.52,0.37-0.97,0.89-1c0.04,0,0.08,0,0.11,0
c0.52-0.03,0.97,0.37,1,0.89c0,0.04,0,0.08,0,0.11c0.03,0.52-0.37,0.97-0.89,1C8.08,12,8.04,12,8,12z"
    />
    <rect className="icon-detail" fill="#fff" x="7" y="4" width="2" height="5" />
  </svg>
);

export default EngineExceedance;
