import React, { ReactNode, useCallback, useState } from 'react';
import { debounce } from 'lodash/fp';
import { ViewportContext, Viewport } from './viewportContext';
import { SetViewportContext, SetViewportContextValue } from './setViewportContext';
import * as persistence from './persistence';

const getNewViewports = (oldViewports: Record<string, Viewport>, newViewport: Partial<Viewport> | undefined, mapId: string) => {
  if (newViewport) {
    if (oldViewports[mapId]) {
      return { ...oldViewports, [mapId]: { ...oldViewports[mapId], ...newViewport } };
    }
    return { ...oldViewports, [mapId]: (newViewport as Viewport) };
  }
  const newViewports = { ...oldViewports };
  delete newViewports[mapId];
  return newViewports;
};

const debouncedPersistenceSet = debounce(500, persistence.set);

// NOTE: We use separate contexts to hold the viewports state and the set viewports function.
//       This allows components that only consume set viewports function to not be re-rendered when the viewports state is updated.

const ViewportContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const [viewports, setViewports] = useState<Record<string, Viewport>>(persistence.get);

  const setViewport = useCallback<SetViewportContextValue>((mapId, viewport) => {
    setViewports(oldViewports => {
      const newViewports = typeof viewport === 'function'
        ? { ...oldViewports, [mapId]: viewport(oldViewports[mapId]) }
        : getNewViewports(oldViewports, viewport, mapId);

      // latitude of 90 or -90 are projected to infinity in Web Mercator projection.
      // This is a workaround to prevent the viewport from being set to infinity.
      if (newViewports[mapId]?.latitude && (newViewports[mapId].latitude >= 90)) {
        newViewports[mapId].latitude = 89.99999999999999;
      } else if (newViewports[mapId] && (newViewports[mapId].latitude <= -90)) {
        newViewports[mapId].latitude = -89.99999999999999;
      }

      debouncedPersistenceSet(newViewports);
      return newViewports;
    });
  }, [setViewports]);

  return (
    <ViewportContext.Provider value={viewports}>
      <SetViewportContext.Provider value={setViewport}>
        {children}
      </SetViewportContext.Provider>
    </ViewportContext.Provider>
  );
};

export default ViewportContextProvider;
