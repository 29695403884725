import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import View from './organisation-contact-modal-view';

export default Smart(
  View,
  state => ({
    permissions: permissionsForCurrentUserAndOrg(state)
  }),
  ac => ({
  })
);
