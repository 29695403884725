import React from 'react';

const Firetruck = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 23.354491,4.0539871 c -0.02466,0.4309479 0,4.1421172 0,4.1421172 h -1.418756 v 6.4484917 h 1.744888 c 0,0 -0.07611,3.672377 0.110163,3.863681 0.34624,0.355584 3.628883,0.274433 3.628883,0.274433 l -0.06232,2.018785 h -4.175268 v 9.983315 h 4.050633 v 3.925998 h -3.988316 v 7.54041 h -1.312447 v 6.047273 h 1.25013 v 2.490214 h -1.235657 v 6.055244 h 1.187738 v 4.494638 h 19.476772 v -4.494638 h 1.187738 v -6.055244 h -1.235657 v -2.490214 h 1.25013 v -6.047273 h -1.312447 v -7.54041 H 38.512382 V 30.78481 h 4.050633 v -9.983315 h -4.175268 l -0.06232,-2.018785 c 0,0 3.282643,0.08115 3.628883,-0.274433 0.186273,-0.191304 0.110163,-3.863681 0.110163,-3.863681 h 1.744888 V 8.1961043 h -1.418756 c 0,0 0.02466,-3.7111693 0,-4.1421172 -0.131508,-2.297724 -9.518057,-2.291384 -9.518057,-2.291384 0,0 -9.386549,-0.00634 -9.518057,2.291384 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Firetruck;
