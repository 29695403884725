import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import {
  altitudeFilledReports,
  calculatePolylinesFromReports,
  ReportsRepository,
  Segment,
  SegmentEvent,
  Timespan
} from './reports';
import { CatmulRomSpline, Polyline } from './spline';

export enum EventType {
  NEW_REPORT,
  NEW_REPORTS,
  NEW_ASSET,
  CLEAR,
  SET_ALL
}

export interface ReportEvent {
  type: EventType,
  reportId?: number // only set on NEW_REPORT event
  reportIds?: number[]
  assetId?: number
  assetIds?: number[]
}

// this is particularly used for legs, as the reports are filtered elsewhere using e.g. redux data.
export const generateSpline = (reports: Report[]): Polyline[] => {
  const polylines = calculatePolylinesFromReports(reports);
  return polylines.map(pl => new CatmulRomSpline(pl, 0.5, 15).interpolated);
};

export class ReportsDataRepository {
  subject = new Subject<ReportEvent>();
  reportRepository = new ReportsRepository();
  private timezone: string | null = null;
  private currentDay: DateTime = DateTime.now().startOf('day');

  public configureStartDay(timezone: string): void {
    this.timezone = timezone;
    this.currentDay = DateTime.now().setZone(timezone).startOf('day');
  }

  insertReports(reports: Report[], cullBeforeTime?: number): void {
    if (this.timezone) {
      const maybeNewDay = DateTime.now().setZone(this.timezone).startOf('day');
      const isPastMidnight = maybeNewDay > this.currentDay;
      if (isPastMidnight) {
        this.reportRepository.cullAllReportsBefore(maybeNewDay.toMillis());
        this.currentDay = maybeNewDay;
        this.subject.next({
          type: EventType.SET_ALL
        });
      }
    }
    this.reportRepository.insertReports(reports, cullBeforeTime);
    this.subject.next({
      type: EventType.NEW_REPORTS,
      reportIds: reports.map(r => r.id),
      assetIds: reports.map(r => r.assetId)
    });
  }

  setAssetReports(assetId: number, reports: Report[], updateAsset = true): void {
    this.reportRepository.setAssetReports(assetId, reports);
    if (updateAsset) {
      this.subject.next({
        type: EventType.NEW_ASSET,
        assetId
      });
    }
  }

  updateAllAssets(): void {
    this.subject.next({ type: EventType.SET_ALL });
  }

  getReportsForAsset(assetId: number, valid: boolean, leg: Timespan | null): Report[] {
    return valid
      ? this.reportRepository.getSortedPositionsForAsset(assetId, leg)
      : this.reportRepository.getSortedReportsForAsset(assetId, leg);
  }

  getReportsForAssets(assetIds: number[], valid: boolean, leg: Timespan | null): Record<number, Report[]> {
    const result = {} as Record<number, Report[]>;
    assetIds.forEach(id => {
      result[id] = (valid
        ? this.reportRepository.getSortedPositionsForAsset(id, leg)
        : this.reportRepository.getSortedReportsForAsset(id, leg));
    });
    return result;
  }

  getAltitudeFilledReportsForAsset(assetId: number, leg: Timespan | null): Report[] {
    return altitudeFilledReports(this.reportRepository.getSortedReportsForAsset(assetId, leg));
  }

  getAltitudeFilledReportsForAssets(assetIds: number[], leg: Timespan | null): Record<number, Report[]> {
    const result = {} as Record<number, Report[]>;
    assetIds.forEach(id => {
      result[id] = altitudeFilledReports(this.reportRepository.getSortedReportsForAsset(id, leg));
    });
    return result;
  }

  getReport(reportId: number): Report | undefined {
    return this.reportRepository.getReport(reportId);
  }

  getLatestPosition(assetId: number, leg?: Timespan): Report | undefined {
    return this.reportRepository.getLatestPosition(assetId, leg);
  }

  getClosestReport(latitude: number | undefined, longitude: number | undefined, assetIds: number[], leg?: Timespan): Report | undefined {
    if (latitude === undefined || longitude === undefined) return undefined;
    return this.reportRepository.getClosestReport(latitude, longitude, assetIds, leg);
  }

  getSplineForAsset(assetId: number): Polyline[] {
    return this.reportRepository.getSplineForAsset(assetId);
  }

  getSplinesForAssets(assetIds: number[], leg: Leg | null): Record<number, Polyline[]> {
    const result = {} as Record<number, Polyline[]>;
    if (leg) {
      const { assetId } = leg;
      const reports = this.reportRepository.getSortedReportsForAsset(assetId, leg);
      result[assetId] = generateSpline(reports);
    } else {
      assetIds.forEach(id => {
        result[id] = this.getSplineForAsset(id);
      });
    }
    return result;
  }

  getSegmentsForAssets(assetIds: number[], event: SegmentEvent, leg: Leg | null): Record<number, Segment<object>[]> {
    const result = {} as Record<number, Segment<object>[]>;

    if (leg) {
      const { assetId } = leg;
      result[assetId] = this.reportRepository.getSegmentsForAsset(assetId, event, leg);
    } else {
      assetIds.forEach(id => {
        result[id] = this.reportRepository.getSegmentsForAsset(id, event);
      });
    }

    return result;
  }

  clear(): void {
    this.reportRepository.clearReports();
    this.subject.next({ type: EventType.CLEAR });
  }
}
