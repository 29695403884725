import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useTranslations } from 'use-intl';

interface EngineCyclesProps {
  count: number
}

const EngineCycles = ({ count }: EngineCyclesProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.engineCycles');

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      sx={{ whiteSpace: 'nowrap' }}
    >
      <span>{count ? t('nCycles', { n: count }) : t('none')}</span>
      <Tooltip title={t('tooltip')} open={tooltipOpen && !dialogOpen} onOpen={() => setTooltipOpen(true)} onClose={() => setTooltipOpen(false)}>
        <IconButton
          onClick={event => {
            setDialogOpen(true);
            setTooltipOpen(false);
            event.stopPropagation();
          }}
          sx={{ '&.MuiIconButton-root': { my: -1 } }}
        >
          <HelpOutline />
        </IconButton>
      </Tooltip>
      <Box onClick={event => event.stopPropagation()}>
        <Dialog
          maxWidth="xs"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle>{t('dialog.title')}</DialogTitle>
          <DialogContent>
            <Typography>{t('dialog.description')}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setDialogOpen(false)}>{t('dialog.continue')}</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Stack>
  );
};

export default EngineCycles;
