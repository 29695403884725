import { IconLayer } from '@deck.gl/layers';

import outlinedFragmentShader from './shaders/outlined-fragment-shader.glsl';

interface OutlinedIconLayerProps {
  outlineColor: Color | ColorAlpha
}

export default class OutlinedIconLayer<T> extends IconLayer<T, OutlinedIconLayerProps> {
  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        outlineColor: [this.props.outlineColor[0] / 255, this.props.outlineColor[1] / 255, this.props.outlineColor[2] / 255, 1.0],
      }
    });
  }

  getShaders() {
    return { ...super.getShaders(), fs: outlinedFragmentShader };
  }
}

OutlinedIconLayer.defaultProps = {
  outlineColor: [255, 255, 255] as Color,
};

OutlinedIconLayer.layerName = 'OutlinedIconLayer';
