import React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import useStyles from './tabPanel-styles';

const TabPanel = ({
  children,
  currentTab,
  index
}) => {
  const classes = useStyles();

  return (
    <Box
      role="tabpanel"
      className={clsx({ [classes.hideTabPanel]: currentTab !== index })}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
    </Box>
  );
};

export default TabPanel;
