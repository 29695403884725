import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Box, SvgIcon } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { toggleContextbox } from 'slices/ui.slice';
const collapseButtonStyle = {
  position: 'absolute',
  display: 'flex',
  pt: '16px',
  pb: '16px',
  pr: '4px',
  pl: 1,
  top: 16,
  left: -26,
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  outline: '1px solid rgba(255,255,255,.2)',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.05s',
  transform: 'translateX(16px)',
  '&:hover': {
    transform: 'translateX(0px)',
  }
};

const CollapsePanel = () => {
  const t = useTranslation('contextbox.settings');
  const highContrastControls = useSelector(state => state.settings.map.highContrastControls);
  const contextboxOpen = useSelector(state => state.ui.contextboxOpen);
  const dispatch = useDispatch();
  const toggleContext = () => dispatch(toggleContextbox());

  return (
    <Tooltip
      title={contextboxOpen ? t('expandPanel') : t('collapsePanel')}
      placement="left"
      PopperProps={{
        popperOptions: {
          modifiers: [{
            name: 'offset',
            options: {
              enabled: true,
              offset: [0, 10],
            },
          }],
        },
      }}
    >
      <Box
        aria-label='collapseButton'
        sx={{
          ...collapseButtonStyle,
          backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)',
        }}
        onClick={toggleContext}
        role="button"
      >
        <SvgIcon
          aria-label='icon'
          sx={{
            transition: 'all 0.05s',
            ...(contextboxOpen && {
              transform: 'rotate(180deg)',
            })
          }}
          width="16" height="16" viewBox="0 0 16 16">
          <polygon fill="#fff" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8 " />
        </SvgIcon>
      </Box>
    </Tooltip>
  );
};

export default CollapsePanel;
