import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';

export interface CreateButtonProps {
  buttonText: string;
  onClick: () => void;
}

export const CreateButton: FC<CreateButtonProps> = ({
  buttonText,
  onClick,
  children
}) => {
  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mx={3} height="4em">
        <Button onClick={onClick} size="large" variant="contained">{buttonText}</Button>
      </Stack>
      {children}

    </>
  );
};
