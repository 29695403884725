import { createContext } from 'react';
import { Subject } from 'rxjs';

export interface NotificationContextValue {
  connected?: boolean
  subject: Subject<TPCNotification>
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NotificationContext = createContext<NotificationContextValue>(
  { subject: new Subject<TPCNotification>(), connected: undefined }
);
