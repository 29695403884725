import React from 'react';

export interface IconProps {
  fill: string,
  outline: string,
  outlineOpacity: number,
}

const Generic = ({
  fill,
  outline,
  outlineOpacity,
}: IconProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="64px" width="64px" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
      fill={fill}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outline}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill',
      }}
    />
  </svg>
);

export default Generic;
