import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { useSearchParams } from 'react-router-dom';
import usePermissions from 'hooks/session/usePermissions';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import GeofencesMapControlsView from './GeofencesMapControls.view';

const GeofencesMapControls: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const permissions = usePermissions();

  const isDrawing = useSelector(slice.selectors.selectIsDrawing);
  const { selectedFeatures } = useSelector(slice.selectors.selectMapDraw);

  return (
    <GeofencesMapControlsView
      onAdd={() => dispatch(slice.actions.startDrawing())}
      onRemove={() => {
        dispatch(slice.actions.stopDrawing());
        dispatch(slice.actions.deleteFeature(selectedFeatures));
      }}
      onImport={() => setSearchParams(params => {
        params.set('import', 'true');
        return params;
      })}
      draw={isDrawing}
      isViewOnly={!permissions.canEditGeofences}
      allowRemove={!!selectedFeatures.length}
      allowImport={!searchParams.has('import')}
    />
  );
};

export default GeofencesMapControls;
