import React from 'react';
import {
  Chip,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { IceContactGroupBackLinkState } from 'helpers/location';
import { ContactGroupPeopleTable, ContactGroupPeopleTableComponents } from '../../common/contactGroupPeopleTable';

const components: ContactGroupPeopleTableComponents = {
  PersonDetails: ({ person, order, children }) => {
    const t = useTranslations('pages.manage.ice.edit.people');
    return (
      <>
        {children}
        {order === 1 && <Chip label={t('primary')} color="error" />}
        {order === 2 && <Chip label={t('secondary')} color="warning" />}
      </>
    );
  },
};

const PeopleTable = ({ group, edit, noEndBorder }: { group: ContactGroup, edit: boolean, noEndBorder: boolean }): JSX.Element => (
  <ContactGroupPeopleTable
    group={group}
    components={components}
    edit={edit}
    noEndBorder={noEndBorder}
    editLinkProps={personId => ({
      to: `/manage/people/${personId}`,
      state: new IceContactGroupBackLinkState(group.id),
    })}
  />
);

export default PeopleTable;
