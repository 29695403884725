import React, { ReactNode } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { ContactGroupPeopleTable } from 'components/pages/manage/contactGroups/common/contactGroupPeopleTable';

interface AssetContactGroupProps {
  readOnly: boolean
  assetId: number
  groupsQuery: UseQueryResult<ContactGroup[], unknown>
  actions: (group: ContactGroup | undefined) => {
    navigate: ReactNode
    assign: ReactNode
    create: ReactNode
  }
  messages: (group: ContactGroup | undefined, defaultGroup: ContactGroup | undefined) => {
    loadingGroups: ReactNode
    noGroups: ReactNode
    noGroupAssigned: ReactNode
    noGroupAssignedWithDefault: ReactNode
    noPeople: ReactNode
  }
}

const AssetContactGroup = ({
  readOnly,
  assetId,
  groupsQuery,
  actions: getActions,
  messages: getMessages,
}: AssetContactGroupProps) => {
  const groups = groupsQuery.data ?? [];
  const group = groups.find(g => g.deviceAndAssetIds.some(x => x.assetId === assetId));
  const defaultGroup = groupsQuery.data?.find(g => g.isDefault);
  const messages = getMessages(group, defaultGroup);
  const actions = getActions(group);

  let content: ReactNode = null;

  if (groupsQuery.isLoading) {
    content = (
      <Box px={4}>
        <Typography>{messages.loadingGroups}</Typography>
      </Box>
    );
  } else if (!groups.length) {
    content = (
      <Box px={4}>
        <Alert severity="info">{messages.noGroups}</Alert>
      </Box>
    );
  } else if (!group) {
    content = (
      <Box px={4}>
        <Alert severity="info">{defaultGroup ? messages.noGroupAssignedWithDefault : messages.noGroupAssigned}</Alert>
      </Box>
    );
  } else {
    content = (
      <>
        <Box px={4}>
          <Typography variant="h3">{group.name}</Typography>
        </Box>
        {group.peopleWithOrder.length ? (
          <ContactGroupPeopleTable group={group} edit={!readOnly} noEndBorder={readOnly} />
        ) : (
          <Box px={4}>
            <Alert severity="warning">{messages.noPeople}</Alert>
          </Box>
        )}
      </>
    );
  }

  return (
    <Stack spacing={3}>
      {content}
      <Stack px={4} direction="row" justifyContent="flex-end" height="4rem" spacing={3}>
        {group && actions.navigate}
        {!readOnly && (groups.length ? actions.assign : actions.create)}
      </Stack>
    </Stack>
  );
};

export default AssetContactGroup;
