import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { useQuery } from '@tanstack/react-query';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { eventCodesQueryKeys } from './queryKeys';
import { EventCode, EventCodeGroup } from './types';
import { getEventCodeGroups, getEventCodes, getGeofenceCodes } from './requests';

export const useGetEventCodes = () => {
  const organisationId = useOrganisationId();
  const language = useLocaleLanguage();

  const queryKey = eventCodesQueryKeys.eventCodes(organisationId, language);

  return useQuery({
    queryKey,
    queryFn: async () => getEventCodes(organisationId, language),
    staleTime: Infinity,
  });
};

export const useGetEventCodeGroups = () => {
  const organisationId = useOrganisationId();
  const language = useLocaleLanguage();

  const queryKey = eventCodesQueryKeys.groupCodes(organisationId, language);
  return useQuery({
    queryKey,
    queryFn: async () => getEventCodeGroups(organisationId, language),
    staleTime: Infinity,
  });
};

export const useGetGeofenceCodes = () => {
  const organisationId = useOrganisationId();
  const language = useLocaleLanguage();

  const queryKey = eventCodesQueryKeys.geofenceCodes(organisationId, language);

  return useQuery({
    queryKey,
    queryFn: async () => getGeofenceCodes(organisationId, language),
    staleTime: Infinity,
  });
};
