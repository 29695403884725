import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthErrorCodes } from 'firebase/auth';
import { LoginResponse } from 'apis/auth';
import { noticeError } from 'helpers/newRelic';
import {
  joinOrganisationAction,
  leaveOrganisationAction,
  legacyLoginAction,
  loginAction,
  signupAction
} from './thunks';
import type { SessionState, UserData } from './types';

export const initialState: SessionState = {
  user: null,
  rememberMe: true,
  loggedIn: false,
  loggingIn: false,
  loginError: null,
  organisationId: '',
  statusColor: ''
};

const loginAndSignupRequest = (state: SessionState) => {
  state.loggingIn = true;
  state.loginError = null;
  state.loggedIn = false;
};

const loginAndSignUpSuccess = (state: SessionState) => {
  state.loggingIn = false;
  state.loggedIn = true;
  state.user = initialState.user;
  state.loginError = null;
  state.organisationId = '';
};

const loginAndSignUpFailure = (state: SessionState, { payload }: PayloadAction<string>) => {
  state.loggingIn = false;
  state.loggedIn = false;
  state.loginError = payload;
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginRequest: loginAndSignupRequest,
    signupRequest: loginAndSignupRequest,
    userLoaded: (state, { payload }: PayloadAction<UserData>) => {
      state.loggedIn = true;
      state.loggingIn = false;
      state.user = { ...payload, memberOf: payload.memberOf };
      if (!state.organisationId || !payload.memberOf.some(o => o.id === state.organisationId)) {
        const sortedMemberOf = payload.memberOf.toSorted((a, b) => a.name.localeCompare(b.name));
        state.organisationId = (sortedMemberOf.find(o => o.name !== 'Public') || sortedMemberOf[0])?.id;
        sessionStorage.setItem('organisationId', state.organisationId); // For the api headers
        localStorage.setItem('organisationId', state.organisationId); // For the api headers
      } else {
        localStorage.setItem('organisationId', state.organisationId); // For the api headers
      }
      if (!state.organisationId) {
        console.error('User is not a member of any Organisation.', payload);
      }
    },
    updateUser: (state, { payload }: PayloadAction<Pick<UserData, 'email' | 'name'>>) => {
      if (state.user) {
        state.user.name = payload.name;
        state.user.email = payload.email;
      }
    },
    setAuthToken: (state, { payload }: PayloadAction<Pick<SessionState, 'authToken'>>) => {
      state.authToken = payload.authToken;
      if (payload) {
        localStorage.setItem('bearerToken', payload.authToken ?? '');
      }
    },
    signUpSuccess: loginAndSignUpSuccess,
    loginSuccess: loginAndSignUpSuccess,
    loginFailure: loginAndSignUpFailure,
    signUpFailure: loginAndSignUpFailure,
    loggedOut: state => {
      state.loggedIn = false;
      state.loginError = null;
      state.user = initialState.user;
      state.organisationId = '';
      localStorage.removeItem('bearerToken');
    },
    setOrganisationId: (state, { payload }: PayloadAction<string>) => {
      state.organisationId = payload;
      sessionStorage.setItem('organisationId', payload); // For the api headers
      localStorage.setItem('organisationId', payload); // For the api headers
    },
    setRememberMe: (state, { payload }: PayloadAction<boolean>) => {
      state.rememberMe = payload;
    },
    setStatusColor: (state, { payload }: PayloadAction<string>) => {
      state.statusColor = payload;
    },
    unauthorized: () => {
      console.error('Unauthorized');
    },
    resetEverything: () => ({
      ...initialState
    }),
    setSSOSignupCallbackSuccess: (state, { payload }: PayloadAction<Pick<LoginResponse, 'token'>>) => {
      state.authToken = payload.token;
      loginAndSignUpSuccess(state);
    },
    setSSOSignupCallbackFailure: (state, action: PayloadAction<string>) => {
      loginAndSignUpFailure(state, action);
    },
    setSSOLoginCallbackFailure: (state, { payload }: PayloadAction<string | { code: string }>) => {
      if ((payload as { code: string }).code === 'auth/account-exists-with-different-credential') {
        state.loginError = 'differentProviderError';
        state.loggedIn = false;
        state.loggingIn = false;
      } else if (payload === 'Internal Server Error') {
        state.loginError = 'serverError';
        state.loggedIn = false;
        state.loggingIn = false;
      } else {
        state.loginError = 'loginFailed';
        state.loggedIn = false;
        state.loggingIn = false;
      }
    },
    toggleDemoMode: (state, { payload }: PayloadAction<boolean>) => {
      state.demoMode = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.authToken = payload.token;
        loginAndSignUpSuccess(state);
      })
      .addCase(loginAction.pending, state => {
        state.loggingIn = true;
        state.loginError = null;
        state.loggedIn = false;
      })
      .addCase(loginAction.rejected, (state, { payload }) => {
        if (payload === 'Internal server Error') {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'serverError';
        } else if ((payload as { code: string })?.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'tooManyAttempts';
        } else {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'loginFailed';
        }
      })
      .addCase(legacyLoginAction.fulfilled, (state, { payload }) => {
        localStorage.setItem('loginTimestamp', Date.now().toString());
        state.authToken = payload.token;
        loginAndSignUpSuccess(state);
      })
      .addCase(legacyLoginAction.pending, state => {
        state.loggingIn = true;
        state.loginError = null;
        state.loggedIn = false;
      })
      .addCase(legacyLoginAction.rejected, (state, { payload }) => {
        if (payload === 'Internal server Error') {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'serverError';
        } else if ((payload as { code: string })?.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'tooManyAttempts';
        } else {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = 'loginFailed';
        }
      })
      .addCase(signupAction.fulfilled, (state, { payload }) => {
        state.authToken = payload.token;
        loginAndSignUpSuccess(state);
      })
      .addCase(signupAction.pending, state => {
        state.loggingIn = true;
        state.loginError = null;
        state.loggedIn = false;
      })
      .addCase(signupAction.rejected, (state, { payload, meta }) => {
        if (typeof payload === 'string') {
          state.loggingIn = false;
          state.loggedIn = false;
          state.loginError = payload || 'signupFailed';
        } else {
          const error = payload as unknown as any;
          if (!error.message) {
            noticeError(error, { name: meta.arg.name, email: meta.arg.email });
          }
        }
      })
      .addCase('persist/REHYDRATE', state => {
        state.organisationId = sessionStorage.getItem('organisationId') || localStorage.getItem('organisationId') || '';
      })
      .addCase(joinOrganisationAction.fulfilled, (state, { meta }) => {
        if (state.user) {
          state.user.memberOf = [...state.user.memberOf, { id: meta.arg.organisationId, name: meta.arg.organisationName, publicKey: meta.arg.publicKey, role: meta.arg.membershipRole }];
        }
        state.organisationId = meta.arg.organisationId;
      })
      .addCase(joinOrganisationAction.rejected, (_, { payload }) => {
        console.error('Failed to join organisation', payload);
      })
      .addCase(leaveOrganisationAction.fulfilled, (state, { meta }) => {
        if (state.user) {
          state.user.memberOf = state.user.memberOf.filter(o => o.id !== meta.arg.organisationId);
        }
      });
  },
  selectors: {
    selectUser: slice => slice.user,
    selectStaff: slice => (slice.user?.isStaff ?? false) && !slice.demoMode,
    selectIsStaffMember: slice => slice.user?.isStaff ?? false,
    selectDemoMode: slice => slice.demoMode ?? false,
    loggingIn: slice => slice.loggingIn,
    loggedIn: slice => slice.loggedIn && !!slice.user,
    rememberMe: slice => slice.rememberMe,
    loginError: slice => slice.loginError,
  },
});

export const {
  loginRequest,
  signupRequest,
  userLoaded,
  updateUser,
  setAuthToken,
  signUpSuccess,
  loginSuccess,
  loginFailure,
  resetEverything,
  signUpFailure,
  loggedOut,
  setOrganisationId,
  setRememberMe,
  setStatusColor,
  unauthorized,
  setSSOSignupCallbackSuccess,
  setSSOSignupCallbackFailure,
  setSSOLoginCallbackFailure,
  toggleDemoMode
} = sessionSlice.actions;

export const {
  selectUser,
  selectStaff,
  selectIsStaffMember,
  loggedIn,
  loggingIn,
  loginError,
  rememberMe,
  selectDemoMode
} = sessionSlice.selectors;

const persistFields = [
  'user',
  'loggedIn',
  'rememberMe',
  'organisationId',
  'statusColour',
  'notifications'
];

export default {
  key: 'session',
  reducer: sessionSlice.reducer,
  version: 3,
  whitelist: persistFields,
  migrations: {
    0: () => initialState,
    1: (state: SessionState) => {
      const orgId = state.organisationId ?? null;
      localStorage.setItem('organisationId', JSON.stringify(orgId));
      return state;
    },
    2: (state: SessionState) => {
      // The previous migration incorrectly stored orgId stringified,
      // this undoes this if its set into localStorage
      const orgId = localStorage.getItem('organisationId');
      if (orgId?.startsWith('"')) {
        localStorage.setItem('organisationId', JSON.parse(orgId));
      }
      return state;
    },
    3: (state: SessionState) => {
      if (state.organisationId) {
        return state;
      }
      const organisationId = localStorage.getItem('organisationId');
      return {
        ...state,
        organisationId
      };
    },
  },
};
