import React, { useCallback, useMemo, useRef } from 'react';
// import { MarketSector, MarketSectorOrganisation } from 'apis/rest/marketSector/types';
import PersistentTable from 'components/shared/persistentTable';
import { Localization, MaterialTableProps } from '@material-table/core';
import StickyPagination from 'components/shared/stickyPagination';
import { Box, Typography } from '@mui/material';
import { Checklist, Delete, Edit } from '@mui/icons-material';
import usePermissions from 'hooks/session/usePermissions';
import { MarketSector, MarketSectorOrganisation } from 'apis/rest/marketSector/types';

interface RowData {
  id: string;
  name: string;
  description: string;
  priority?: string;
  organisationCount: number;
  secondaryOrganisationCount: number;
}

export interface MarketSectorTableProps {
  data?: MarketSector[],
  orgData?: MarketSectorOrganisation[],
  isLoading: boolean,
  onEditFeatures: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const MarketSectorTable = ({
  data,
  orgData,
  isLoading,
  onEditFeatures,
  onEdit,
  onDelete
}: MarketSectorTableProps): JSX.Element => {
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);
  const localization: Localization = { header: { actions: '' } };

  const rowData: RowData[] = useMemo(() => data?.map(x => {
    const o = orgData?.find(y => y.marketSectorId === x.id);
    return {
      ...x,
      organisationCount: o?.organisationIds.length ?? 0,
      secondaryOrganisationCount: o?.secondaryOrganisationIds.length ?? 0
    };
  }) ?? [], [data, orgData]);

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => ([
    {
      title: 'Name',
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (
        <Typography>{row.name}</Typography>
      )
    },
    {
      title: 'Priority',
      field: 'priority',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (
        <Typography>{row.priority}</Typography>
      )
    },
    {
      title: 'Organisations',
      field: 'organisationCount',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'desc',
      filtering: false,
      render: row => (
        <Typography>{row.organisationCount}</Typography>
      )
    },
    {
      title: 'Secondary Organisations',
      field: 'secondaryOrganisationCount',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'desc',
      filtering: false,
      render: row => (
        <Typography>{row.secondaryOrganisationCount}</Typography>
      )
    },
  ]), []);

  const actions = useMemo<MaterialTableProps<RowData>['actions']>(() => [
    row => ({
      icon: () => <Edit sx={{ color: 'common.text' }} />,
      tooltip: 'Edit',
      onClick: () => onEdit(row.id),
    }),
    // row => ({
    //   icon: () => <Checklist sx={{ color: 'common.text' }} />,
    //   tooltip: 'Toggle Features',
    //   onClick: () => onEditFeatures(row.id),
    // }),
    row => ({
      icon: () => <Delete sx={{ color: 'common.text' }} />,
      tooltip: 'Delete',
      onClick: () => onDelete(row.id),
    })
  ], [onEditFeatures, onEdit, onDelete]);

  return (
    <>
      <PersistentTable<RowData>
        settingsCategory="marketSectorsOrgTable"
        data={rowData}
        isLoading={isLoading}
        columns={columns}
        onRowClick={(_, row) => { onEdit(row?.id ?? ''); }}
        localization={localization}
        components={{
          Pagination,
          Container: Box,
          Toolbar: () => null
        }}
        options={{
          filtering: true,
          search: false,
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        actions={actions}
        sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 }, 'tbody tr': { height: 65 } }} />
      <Box ref={footerRef} position="sticky" bottom={0} sx={{ '& .MuiToolbar-regular': { padding: 10 / 3 } }} />
    </>
  );
};

export default MarketSectorTable;
