import { useMemo } from 'react';

export const useAssetsByDeviceId = (assetArray: AssetWithDevice[]) : Record<number, AssetWithDevice> => (
  useMemo(() => {
    if (!assetArray) return ([]);
    return (assetArray.reduce<Record<number, AssetWithDevice>>((acc, asset) => {
      acc[asset.deviceId] = asset;
      return acc;
    }, {}));
  }, [assetArray]));
