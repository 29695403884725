/* eslint-disable quotes */
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  jobWrapper: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    border: theme.border.default,
    borderRadius: 4,
    padding: '6px 10px 6px 6px',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: theme.spacing(0)
    },
  },
  list: {
    textAlign: 'left',
    position: 'relative',
    padding: theme.spacing(0),
  },
  left: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '1fr 1fr',
    gridArea: 'left',
    '&:before': {
      background: `linear-gradient(to bottom, ${theme.palette.common.lightGrey}, ${theme.palette.common.lightGrey} 67%, transparent 67%, transparent)`,
      backgroundSize: '16px 16px',
      backgroundPosition: 'center',
      backgroundRepeat: 'repeat-y',
      content: `''`,
      position: 'absolute',
      width: 4,
      left: 12.5,
      height: 'calc(100% - 26px)',
      marginTop: 14,
    }
  },
  job: {
    padding: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 6fr',
    gridTemplate: '"left right"',
    '&.MuiListItem-root': {
      alignItems: 'start',
    },
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: theme.spacing(0)
    },
  },
  jobContent: {
    color: theme.palette.common.text,
    width: '100%',
    gridArea: 'right',
    '& span': {
      width: '100%',
      overflow: 'hidden',
    }
  },
  finishIcon: {
    alignSelf: 'end',
  },
  iconWrapper: {
    position: 'relative',
    minWidth: 35,
    height: 28,
    marginTop: theme.spacing(1),
    marginBottom: 8,
    '& svg': {
      position: 'absolute',
      top: 3,
      bottom: 3,
      left: 3,
      width: 23,
      height: 23,
      zIndex: 1,
      '& .icon-base': {
        fill: theme.palette.common.lightGrey
      },
      '& .icon-detail': {
        fill: theme.palette.common.text
      }
    },
  },
  current: {
    '& svg': {
      '& .icon-base': {
        fill: theme.palette.primary.activeGreen
      },
      '& .icon-detail': {
        fill: theme.palette.common.whiteConstant
      }
    }
  },
  future: {
    '& svg': {
      '& .icon-base': {
        fill: theme.palette.primary.blue
      },
      '& .icon-detail': {
        fill: theme.palette.common.whiteConstant
      }
    }
  },
  smallIcon: {
    '& svg': {
      top: 6,
      bottom: 6,
      left: 5.5,
      width: 18,
      height: 18,
    }
  },
  clickable: {
    '& svg': {
      '&:hover': {
        '& .icon-base': {
          fill: theme.palette.common.text
        },
        '& .icon-detail': {
          fill: theme.palette.common.lightGrey
        },
        cursor: 'pointer',
      }
    }
  },
  startTime: {
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  startDate: {
    fontWeight: 100,
    fontSize: '0.9rem',
    marginBottom: 5
  },
  endTime: {
    fontWeight: 500,
    marginTop: 5,
    fontSize: '0.9rem',
  },
  endDate: {
    fontWeight: 100,
    fontSize: '0.9rem',
  },
  metadata: {
    fontSize: '0.9rem',
    padding: 0,
    marginTop: 5,
    marginBottom: 10,
    paddingLeft: 10,
    maxHeight: 80,
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      backgroundImage: `linear-gradient(to bottom, rgba(255,255,255, 0), ${theme.palette.background.paper} 95%);`,
      width: '100%',
      height: '4rem',
    },
  },
  expanded: {
    maxHeight: '100%',
    '&:after': {
      backgroundImage: 'none',
    }
  },
  seeMore: {
    marginBottom: 0,
    marginTop: -10,
    zIndex: 2,
    textAlign: 'center',
    width: '100%',
    padding: 0,
    '& svg': {
      '& .icon-base': {
        fill: theme.palette.common.lightGrey
      },
      '& .icon-detail': {
        fill: theme.palette.common.text
      }
    }
  },
  metadataKey: {
    marginTop: 5,
    fontWeight: 500
  },
  metadataValue: {
    fontWeight: 100,
    fontSize: '0.9rem',
  }
}));
