import { Alert, Box, AlertProps } from '@mui/material';
import TracPlusLabsLogo from './Logo';
import React, { forwardRef } from 'react';

const LabsAlert = forwardRef<HTMLDivElement, Pick<AlertProps, 'children' | 'action' | 'sx'>>(({ children, action, sx }, ref) => (
  <Alert
    ref={ref}
    severity="success"
    icon={false}
    variant="outlined"
    sx={{ bgcolor: 'background.paper', ...sx }}
    action={action}
  >
    <TracPlusLabsLogo />
    <Box mt={2}>
      {children}
    </Box>
  </Alert>
));

export default LabsAlert;
