import React, { useState } from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Delete, Edit } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetTemporalSharesFromList } from 'apis/rest/temporalShares/hooks';
import { TemporalGroupShare, TemporalShare, TemporalSharesResult } from 'apis/rest/temporalShares/types';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import usePermissions from 'hooks/session/usePermissions';
import {
  GroupedData,
  isTemporalGroupShare,
  Period,
  selectAssetsByDeviceId,
  selectDevicesById,
  useFilterState,
  useRecipientClusters,
  useSortSharesByDevice,
} from './helpers';
import { AssetDetails } from './assetDetails';
import ShareTime from './shareTime';
import { DeviceDetails } from './deviceDetails';
import Filters from './filters';
import EditDialog from './dialogs/editDialog';
import DeleteDialog from './dialogs/deleteDialog';
import CreateDialog from './dialogs/createDialog';
import useTimezone from "hooks/session/useTimezone";

type DialogState = { type: 'CREATE', cloneShare?: TemporalShare }
  | { type: 'EDIT', shareId: number, isGroupShare: boolean }
  | { type: 'DELETE', shareId: number, isGroupShare: boolean };

interface ShareRowProps {
  share: TemporalShare | TemporalGroupShare
  asset: AssetWithDevice | undefined
  device: DeviceBasic | undefined
  isAssetLoading: boolean
  isDeviceLoading: boolean
  timezone: string
  displayActions: boolean
  onEdit: (share: TemporalShare | TemporalGroupShare) => void
  onDelete: (share: TemporalShare | TemporalGroupShare) => void
}

const ShareRow = ({ share, asset, device, isAssetLoading, isDeviceLoading, timezone, displayActions, onEdit, onDelete }: ShareRowProps) => {
  const t = useTranslations('pages.sharing');
  return (
    <>
      <TableRow sx={share.notes ? { 'td, th': { border: 0 } } : { '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={{ py: 2 }}><AssetDetails asset={asset} isLoading={isAssetLoading} /></TableCell>
        <TableCell sx={{ py: 2 }}><DeviceDetails device={device} isLoading={isDeviceLoading} /></TableCell>
        <TableCell>
          <ShareTime share={share} timezone={timezone} />
        </TableCell>
        <TableCell>
          {share.canViewCurrent && !share.canViewHistory && t('trails.live')}
          {share.canViewCurrent && share.canViewHistory && t('trails.all')}
          {!share.canViewCurrent && share.canViewHistory && t('trails.historic')}
          {!share.canViewCurrent && !share.canViewHistory && t('trails.none')}
        </TableCell>
        <TableCell align="center">
          {share.canViewForms && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canViewForms')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendTextMessages && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendTextMessages')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendConfiguration && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendConfiguration')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canEditCallSign && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canEditCallSign')}</Box>
            </>
          )}
        </TableCell>
        {displayActions && (
          <TableCell sx={{ py: 2 }}>
            <Stack direction="row">
              <Tooltip title={t('actions.edit')}>
                <IconButton onClick={() => onEdit(share)}><Edit /></IconButton>
              </Tooltip>
              <Tooltip title={t('actions.delete')}>
                <IconButton onClick={() => onDelete(share)}><Delete /></IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        )}
      </TableRow>
      {share.notes && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell colSpan={100} sx={{ pt: 0 }}><Typography variant="body3">{t('columns.notes')}: {share.notes}</Typography></TableCell>
        </TableRow>
      )}
    </>
  );
};

const useDialog = () => {
  const [state, setDialog] = useState<DialogState>();

  return {
    state,
    close: () => setDialog(undefined),
    create: () => setDialog({ type: 'CREATE' }),
    clone: (share: TemporalShare) => setDialog({ type: 'CREATE', cloneShare: share }),
    edit: (share: TemporalShare) => setDialog({ type: 'EDIT', shareId: share.id, isGroupShare: 'groupId' in share }),
    delete: (share: TemporalShare) => setDialog({ type: 'DELETE', shareId: share.id, isGroupShare: 'groupId' in share }),
  };
};

const groupData = (data: TemporalSharesResult) => {
  const acc: GroupedData = {
    sharesById: {},
    groupSharesById: {},
    sharesByOrganisationId: {},
    sharesByGroupId: {},
    sharesByDeviceId: {},
    sharesByOrganisationIdDeviceId: {},
    sharesByGroupIdDeviceId: {},
    sharesByGroupIdOrganisationId: {},
    organisationIds: [],
    groupIds: [],
    deviceIds: [],
  };

  data.selfShares.forEach(share => {
    if (acc.sharesByDeviceId[share.deviceId]) {
      acc.sharesByDeviceId[share.deviceId].push(share);
    } else {
      acc.sharesByDeviceId[share.deviceId] = [share];
      acc.deviceIds.push(share.deviceId);
    }

    acc.sharesById[share.id] = share;
    if (acc.sharesByOrganisationId[share.organisationId]) {
      acc.sharesByOrganisationId[share.organisationId].push(share);
    } else {
      acc.sharesByOrganisationId[share.organisationId] = [share];
      acc.organisationIds.push(share.organisationId);
    }

    if (acc.sharesByOrganisationIdDeviceId[share.organisationId]) {
      if (acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId]) {
        acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId].push(share);
      } else {
        acc.sharesByOrganisationIdDeviceId[share.organisationId][share.deviceId] = [share];
      }
    } else {
      acc.sharesByOrganisationIdDeviceId[share.organisationId] = { [share.deviceId]: [share] };
    }
  });

  return acc;
};

const SharesSelf = (): JSX.Element => {
  const t = useTranslations('pages.sharing');
  const timezone = useTimezone();

  const user = usePermissions();

  const dialog = useDialog();

  const [filters, setFilters] = useFilterState();

  const sharesQuery = useGetTemporalSharesFromList({ select: groupData });

  const assetsByDeviceIdQuery = useGetAssetsList({ select: selectAssetsByDeviceId }).query;
  const assetsByDeviceId = assetsByDeviceIdQuery.data;

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesByIdQuery.data;

  const cluster = useRecipientClusters(sharesQuery.data, filters)[0];

  const sortShares = useSortSharesByDevice(assetsByDeviceId);

  return (
    <Box pb={10}>
      <Paper elevation={0} sx={{ p: 3, mb: 6 }}>
        <Stack direction="row" spacing={3} flexWrap="wrap" useFlexGap>
          <Filters
            state={filters}
            setFilters={setFilters}
            assetsByDeviceId={assetsByDeviceId ?? {}}
            devicesById={devicesById ?? {}}
            data={sharesQuery.data}
            participants={false}
          />

          {user.canEditShare && (
            <Stack direction="row" flex={1} justifyContent="flex-end">
              <Button variant="contained" onClick={dialog.create}>{t('actions.create')}</Button>
            </Stack>
          )}
        </Stack>
      </Paper>

      {sharesQuery.isLoading ? (
        <Paper elevation={0} sx={{ p: 3 }} component="article">
          <Typography textAlign="center">{t('loading')}</Typography>
        </Paper>
      ) : (
        cluster ? (
          <Paper
            elevation={0}
            sx={{ pb: 2, mb: 3 }}
            component="article"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '25%', whiteSpace: 'nowrap' }}>{t('columns.asset')}</TableCell>
                  <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.device')}</TableCell>
                  <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.time')}</TableCell>
                  <TableCell sx={{ width: '10%', whiteSpace: 'nowrap' }}>{t('columns.trails')}</TableCell>
                  <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.forms')}</TableCell>
                  <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.message')}</TableCell>
                  <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.configure')}</TableCell>
                  <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.callsign')}</TableCell>
                  {user.canEditShare && <TableCell sx={{ width: '0%' }} />}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortShares(cluster.shares).map(share => (
                  <ShareRow
                    key={isTemporalGroupShare(share) ? `groupShare-${share.id}` : `share-${share.id}`}
                    share={share}
                    asset={assetsByDeviceId?.[share.deviceId]}
                    device={devicesById?.[share.deviceId]}
                    isAssetLoading={assetsByDeviceIdQuery.isLoading}
                    isDeviceLoading={devicesByIdQuery.isLoading}
                    timezone={timezone}
                    displayActions={user.canEditShare}
                    onEdit={dialog.edit}
                    onDelete={dialog.delete}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Paper elevation={0} sx={{ p: 3 }} component="article">
            <Alert severity="info">
              {(!!filters.deviceIds.length || !!filters.organisationIds.length || filters.period !== Period.All) ? t('noSharesForFilters') : t('noSharesSelf')}
            </Alert>
          </Paper>
        )
      )}

      {user.canEditShare && (
        <>
          <CreateDialog
            open={dialog.state?.type === 'CREATE'}
            onClose={dialog.close}
            isSelfShare
            renderDeviceShareCount={deviceId => {
              if (!deviceId) return null;
              const n = sharesQuery.data?.sharesByDeviceId[deviceId]?.length;
              if (n) return null;
              return <Typography color="error">{t('noSelfShares')}</Typography>;
            }}
            cloneShare={dialog.state?.type === 'CREATE' ? dialog.state?.cloneShare : undefined}
          />
          <EditDialog
            shareId={dialog.state?.type === 'EDIT' ? dialog.state.shareId : undefined}
            isGroupShare={dialog.state?.type === 'EDIT' ? dialog.state.isGroupShare : undefined}
            isSelfShare
            onClose={dialog.close}
            onClone={share => dialog.clone(share)}
          />
          <DeleteDialog
            shareId={dialog.state?.type === 'DELETE' ? dialog.state.shareId : undefined}
            isGroupShare={dialog.state?.type === 'DELETE' ? dialog.state.isGroupShare : undefined}
            isSelfShare
            onClose={dialog.close}
          />
        </>
      )}
    </Box>
  );
};

export default SharesSelf;
