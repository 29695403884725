import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GeographicCoordinates } from 'components/pages/manage/markers/types'
import { PointIconType } from 'components/shared/icons/annotations/pointIcon'
import { v4 as uuidV4 } from 'uuid'

interface AnnotationBase {
  id: string
  name: string
  description: string
  colour: string
  isVisible: boolean
}

export interface AnnotationPoint extends AnnotationBase {
  location: GeographicCoordinates,
  type: PointIconType
}

export interface AnnotationPath extends AnnotationBase {
  points: GeographicCoordinates[]
}

export const DEFAULT_POINT: AnnotationPoint = {
  id: '',
  colour: '#ff0000',
  isVisible: true,
  name: '',
  description: '',
  location: { latitude: 0, longitude: 0, altitude: 0 },
  type: 'circle'
}
export const DEFAULT_PATH: AnnotationPath = {
  id: '',
  colour: '#ff0000',
  isVisible: true,
  name: '',
  description: '',
  points: []
}

type AnnotationState = {
  Points: AnnotationPoint[]
  Paths: AnnotationPath[]
  EditPointDialog?: AnnotationPoint
  EditPathDialog?: AnnotationPath
  IsDrawingPoint: boolean
  IsDrawingPath: boolean,
  CurrentPath?: AnnotationPath
}

export const POINTS_STORAGE_KEY = 'annotation-points';
export const PATHS_STORAGE_KEY = 'annotation-paths';

const initialState: AnnotationState = {
  IsDrawingPoint: false,
  IsDrawingPath: false,
  Paths: [],
  Points: [],
  EditPointDialog: undefined,
  EditPathDialog: undefined,
  CurrentPath: undefined
}

// Initial data is set from map view code on load, and setPoints is used to update if data changes in another tab.
// If data is changed in this tab through create / update / delete, the state also needs to be set since storage events arent fired for the same tab

export const annotationSlice = createSlice({
  name: 'localMapObject',
  initialState,
  reducers: {
    setPoints: (state, action: PayloadAction<AnnotationPoint[]>) => {
      state.Points = action.payload
    },
    setPaths: (state, action: PayloadAction<AnnotationPath[]>) => {
      state.Paths = action.payload
    },
    setEditPointDialog: (state, action: PayloadAction<AnnotationPoint | undefined>) => {
      state.EditPointDialog = action.payload
      state.IsDrawingPoint = false;
    },
    setEditPathDialog: (state, action: PayloadAction<AnnotationPath | undefined>) => {
      state.EditPathDialog = action.payload
      state.IsDrawingPath = false;
    },
    createOrUpdatePoint: (state, action: PayloadAction<AnnotationPoint>) => {
      if (!action.payload.id) { action.payload.id = uuidV4(); }
      state.Points = [...state.Points.filter(p => p.id !== action.payload.id), action.payload]
      localStorage.setItem(POINTS_STORAGE_KEY, JSON.stringify(state.Points))
      state.EditPointDialog = undefined;
      state.IsDrawingPoint = false;
    },
    deletePoint: (state, action: PayloadAction<string>) => {
      state.Points = state.Points.filter(p => p.id !== action.payload)
      localStorage.setItem(POINTS_STORAGE_KEY, JSON.stringify(state.Points))
    },
    createOrUpdatePath: (state, action: PayloadAction<AnnotationPath>) => {
      if (!action.payload.id) { action.payload.id = uuidV4(); }
      state.Paths = [...state.Paths.filter(p => p.id !== action.payload.id), action.payload]
      localStorage.setItem(PATHS_STORAGE_KEY, JSON.stringify(state.Paths))
      state.EditPathDialog = undefined;
      state.IsDrawingPath = false;
    },
    deletePath: (state, action: PayloadAction<string>) => {
      state.Paths = state.Paths.filter(p => p.id !== action.payload)
      localStorage.setItem(PATHS_STORAGE_KEY, JSON.stringify(state.Paths))
    },
    togglePointVisibility: (state, action: PayloadAction<string>) => {
      const point = state.Points.find(x => x.id === action.payload)
      if (point) { point.isVisible = !point.isVisible }
      localStorage.setItem(POINTS_STORAGE_KEY, JSON.stringify(state.Points))
    },
    togglePathVisibility: (state, action: PayloadAction<string>) => {
      const path = state.Paths.find(x => x.id === action.payload)
      if (path) { path.isVisible = !path.isVisible }
      localStorage.setItem(PATHS_STORAGE_KEY, JSON.stringify(state.Paths))
    },
    setIsDrawingPoint: (state, action: PayloadAction<boolean>) => {
      state.IsDrawingPoint = action.payload
    },
    setIsDrawingPath: (state, action: PayloadAction<boolean>) => {
      state.IsDrawingPath = action.payload
    },
    setCurrentPath: (state, action: PayloadAction<AnnotationPath | undefined>) => {
      state.CurrentPath = action.payload
    }
  },
  selectors: {
    selectEditPointDialog: (state) => state.EditPointDialog,
    selectEditPathDialog: (state) => state.EditPathDialog,
    selectPoints: (state) => state.Points,
    selectPaths: (state) => state.Paths,
    selectIsDrawingPoint: (state) => state.IsDrawingPoint,
    selectIsDrawingPath: (state) => state.IsDrawingPath,
  },
})

export const {
  setPoints,
  setPaths,
  setEditPointDialog,
  setEditPathDialog,
  createOrUpdatePoint,
  deletePoint,
  createOrUpdatePath,
  deletePath,
  togglePointVisibility,
  togglePathVisibility,
  setIsDrawingPoint,
  setIsDrawingPath,
  setCurrentPath } = annotationSlice.actions
export const {
  selectEditPointDialog,
  selectEditPathDialog,
  selectPoints,
  selectPaths,
  selectIsDrawingPoint,
  selectIsDrawingPath } = annotationSlice.selectors

export default annotationSlice.reducer
