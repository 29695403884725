import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface TPButtonProps extends Omit<ButtonProps, 'variant'> {
  variant?: 'primary' | 'outlined' | 'destructive';
}

export const TPButton: React.FC<TPButtonProps> = ({ variant, children, ...rest }) => (
  <Button
    variant={variant && variant !== 'primary' ? 'outlined' : 'contained'}
    size="large"
    color={variant === 'destructive' ? 'error' : 'primary'}
    sx={{ minWidth: '10rem', height: '4rem' }}
    {...rest}
  >
    {children}
  </Button>
);
