import { alpha, styled } from '@mui/material/styles';
import {
  ButtonBase,
  ButtonBaseProps,
  Grid,
  GridProps,
  Typography,
  TypographyProps,
} from '@mui/material';

interface DayButtonProps extends ButtonBaseProps {
  variant: DayVariant
}

export enum DayVariant {
  None = 'None',
  AloneSelected = 'AloneSelected',
  LeftSelected = 'LeftSelected',
  MiddleSelected = 'MiddleSelected',
  RightSelected = 'RightSelected',
  Left = 'Left',
  Middle = 'Middle',
  Right = 'Right',
  Disabled = 'Disabled',
}

export const DayButton = styled(ButtonBase, {
  shouldForwardProp: name => name !== 'variant'
})<DayButtonProps>(({ theme, variant }) => {
  const colors: Record<string, string> = {
    [DayVariant.AloneSelected]: theme.palette.common.white,
    [DayVariant.LeftSelected]: theme.palette.common.white,
    [DayVariant.MiddleSelected]: theme.palette.common.white,
    [DayVariant.RightSelected]: theme.palette.common.white,
    [DayVariant.Disabled]: alpha(theme.palette.common.black, 0.38),
  };

  const backgroundColors: Record<string, string> = {
    [DayVariant.AloneSelected]: theme.palette.primary.main,
    [DayVariant.LeftSelected]: theme.palette.primary.main,
    [DayVariant.MiddleSelected]: theme.palette.primary.main,
    [DayVariant.RightSelected]: theme.palette.primary.main,
    [DayVariant.Left]: alpha(theme.palette.common.black, 0.1),
    [DayVariant.Middle]: alpha(theme.palette.common.black, 0.1),
    [DayVariant.Right]: alpha(theme.palette.common.black, 0.1),
  };

  const borderRadii: Record<string, string> = {
    [DayVariant.LeftSelected]: '20px 0 0 20px',
    [DayVariant.MiddleSelected]: '0',
    [DayVariant.RightSelected]: '0 20px 20px 0',
    [DayVariant.Left]: '20px 0 0 20px',
    [DayVariant.Middle]: '0',
    [DayVariant.Right]: '0 20px 20px 0',
  };

  const hoverBackgroundColors: Record<string, string> = {
    [DayVariant.None]: alpha(theme.palette.common.black, 0.1)
  };

  return `
    display: block;
    width: 40px;
    height: 40px;
    border-radius: ${borderRadii[variant] ?? '20px'};
    background: ${backgroundColors[variant] ?? 'none'};
    :hover {
      background: ${hoverBackgroundColors[variant] ?? ''};
    }
    
    & .MuiTypography-root {
      color: ${colors[variant] ?? theme.palette.common.black}
    }
  `;
});

export const Header = styled(Grid)<GridProps>(({ theme }) => `
  background: ${theme.palette.primary.main};
  padding: ${theme.spacing(2, 4)};
`);

export const Title = styled(Typography)<TypographyProps>(({ theme }) => `
  color: ${theme.palette.common.white};
  font-size: 1.5rem;
  font-weight: 500;
`);
