import React, { useState } from 'react';
import { useTranslations } from 'use-intl';
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import { FeatureCollection } from 'geojson';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';
import { GeofenceImportFileArea } from './GeofenceImportFileArea';

interface GeofenceImportModalProps {
  open: boolean
  onConfirm: (name: string, featureCollection: FeatureCollection, replace: boolean) => void
  onCancel: () => void
  allowReplace?: boolean
}

const GeofenceImportModalView: React.FC<GeofenceImportModalProps> = ({ open, onConfirm, onCancel, allowReplace }) => {
  const t = useTranslations('pages.manage.geofencing');
  const [replace, setReplace] = useState(false);
  const [fileFeatures, setFileFeatures] = useState<FeatureCollection>();
  const [fileName, setFileName] = useState<string>('');

  const reset = () => {
    setReplace(false);
    setFileFeatures(undefined);
    setFileName('');
  };

  return (
    <ConfirmationDialog
      title={t('import.header')}
      cancelText={t('dialogs.cancelText')}
      confirmText={t('import.importText')}
      confirmButtonProps={{ color: 'error', variant: 'contained', size: 'large', sx: { minWidth: '10rem' }, disabled: !fileFeatures }}
      cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' } }}
      onConfirm={() => {
        if (fileFeatures) onConfirm(fileName, fileFeatures, replace);
      }}
      onCancel={onCancel}
      dialogProps={{
        open,
        fullWidth: true,
        TransitionProps: { onExited: reset },
      }}
    >
      <Stack spacing={3} my={3}>
        <GeofenceImportFileArea onFileAdd={(features, name) => {
          setFileFeatures(features);
          setFileName(name);
        }} />
        {allowReplace && (
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  value={replace}
                  onChange={event => setReplace(event.target.checked)}
                />
              )}
              label={t('import.replaceText')}
            />
          </FormGroup>
        )}
      </Stack>
    </ConfirmationDialog>
  );
};

export default GeofenceImportModalView;
