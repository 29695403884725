import React, { ReactNode } from 'react';
import {
  Stack,
  Dialog,
  DialogContent,
  Button,
  ButtonProps,
  DialogProps,
  IconButton,
  DialogContentProps
} from '@mui/material';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { TPDialogActions } from './TPDialogActions';

export interface ConfirmationDialogProps {
  title: string;
  cancelText?: string;
  confirmText?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  dialogProps: DialogProps;
  dialogContentProps?: DialogContentProps;
  children: ReactNode;
  hideActions?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  cancelText,
  confirmText,
  confirmButtonProps,
  cancelButtonProps,
  dialogProps,
  dialogContentProps,
  children,
  hideActions,
  onConfirm,
  onCancel
}: ConfirmationDialogProps) => (
  <Dialog onClose={onCancel} {...dialogProps}>
    <TPDialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
        {title}
        {(hideActions ?? false) && (
          <IconButton {...cancelButtonProps} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </TPDialogTitle>
    <DialogContent sx={{ p: 3, pb: 0 }} {...dialogContentProps}>
      {children}
    </DialogContent>
    {!hideActions && (
      <TPDialogActions sx={{ p: 3 }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4rem">
          <Button
            onClick={onCancel}
            {...cancelButtonProps}>
            {cancelText}
          </Button>
          <Button
            onClick={onConfirm}
            {...confirmButtonProps}>
            {confirmText}
          </Button>
        </Stack>
      </TPDialogActions>
    )}
  </Dialog>
);
