import React from 'react';

const SarCancel = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path
      className="icon-base"
      d="M14.16,1.01l-6-1C8.06,0,7.94,0,7.84,0.01l-6,1C1.35,1.09,1,1.51,1,2v7c0,3.87,3.13,7,7,7s7-3.13,7-7V2
C15,1.51,14.65,1.09,14.16,1.01z"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.33,5.67L10.33,5.67C8.99,4.43,6.91,4.49,5.67,5.82c-1.19,1.27-1.19,3.24,0,4.5l0,0.01l0.01,0
c1.32,1.24,3.41,1.18,4.65-0.15C11.52,8.92,11.52,6.95,10.33,5.67L10.33,5.67z M8,5.81c0.39,0,0.77,0.11,1.11,0.31L6.12,9.11
C5.92,8.77,5.81,8.39,5.81,8C5.81,6.79,6.79,5.81,8,5.81z M8,10.19c-0.39,0-0.77-0.11-1.11-0.31l2.99-2.99
c0.2,0.33,0.31,0.72,0.31,1.11C10.19,9.21,9.21,10.19,8,10.19z"
    />
  </svg>
);

export default SarCancel;
