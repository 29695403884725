import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { GeoJsonProperties } from 'geojson';
import { Source } from '@mui/icons-material';
import { useUserSettings } from 'contexts/userSettings/useUserSettings';

export interface HoveredElement {
  x: number,
  y: number,
  properties: GeoJsonProperties
  sourceLayerId?: string
}
interface HoveredElementPopperProps {
  hoveredElement?: HoveredElement
  highContrast: boolean
}

const IgnoredProperties = [
  'name',
  'description',
  'fill-opacity',
  'stroke-opacity',
  'stroke',
  'shape__area',
  'shape__length',
  'stroke-width',
  'styleurl',
  'label-scale',
  'icon-scale',
  'icon-offset',
  'icon-opacity',
  'icon-color',
  'label-opacity',
  'label-color',
  'fill',
  'icon-offset-units',
  'icon'
];

export const HoveredElementOverlay = ({ hoveredElement, highContrast }: HoveredElementPopperProps) => {
  const extraProps = useMemo(() => Object.entries(hoveredElement?.properties ?? {})
    .filter(([k, _]) => !IgnoredProperties.includes(k.toLowerCase())), [hoveredElement?.properties]);

  const [mapSettings, _] = useUserSettings('map');
  const description = useMemo(
    () => (typeof hoveredElement?.properties?.description === 'string'
      ? hoveredElement?.properties?.description
      : undefined),
    [hoveredElement?.properties?.description]
  );
  const title = useMemo(() => hoveredElement?.properties?.name ?? description, [description, hoveredElement?.properties?.name]);

  return (
    (hoveredElement && mapSettings.kml.displayOverlay) ? (
      <Box sx={theme => ({
        backgroundColor: highContrast ? theme.palette.mapControls.bgHighContrast : 'rgba(0,0,0,0)',
        borderRadius: 1,
        '& .MuiTypography-root, .MuiSvgIcon-root': {
          color: theme.palette.common.whiteConstant,
        },
        textAlign: 'left'
      })}>
        <Box sx={{
          backgroundColor: 'rgba(0,0,0,0.4)',
          borderRadius: 1,
        }}>
          <Stack px={3} py={2} spacing={1}>
            <Typography variant="h3">{title}</Typography>
            {description !== title && <Typography variant="subtitle1" lineHeight={1.2}>{description}</Typography>}
            {hoveredElement.sourceLayerId && (
              <Stack alignItems="center" direction="row" spacing={1}>
                <Source fontSize="small" />
                <Typography variant="subtitle2">{hoveredElement.sourceLayerId?.replace('kml-layer-', '')}</Typography>
              </Stack>
            )}
          </Stack>
          {!!extraProps.length && mapSettings.kml.displayAdditionalData
            && (
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                px={3}
                py={2}
                borderTop="1px solid rgba(255,255,255,.10)"
              >
                {extraProps.map(([k, v]) => (
                  <Box gridColumn={v.length > 10 ? 'span 2' : undefined}>
                    <Typography variant="overline" lineHeight={1}>{k}</Typography>
                    <Typography>{v}</Typography>
                  </Box>
                ))}
              </Box>
            )}
        </Box>
      </Box>
    ) : null
  );
};
