import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1)
  },
  eventIcon: {
    display: 'inline-flex',
    flexShrink: '0',
    minWidth: '19px',
    marginLeft: theme.spacing(1),
    '& svg': {
      width: '19px',
      height: '19px',
      '& .icon-base': {
        fill: theme.palette.common.whiteConstant
      },
      '& .icon-detail': {
        fill: theme.palette.common.blackConstant,
        opacity: 0.5
      }
    }
  }
}));
