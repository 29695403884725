import { distance } from 'helpers/unitsOfMeasure';
import { useCallback } from 'react';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

const defaultFormatter = (d: Distance, precision: number) => `${Number.parseFloat(d.unitValue.toFixed(precision))} ${d.unitLabel}`;

type DistanceUnit = ReduxState['unitSettings']['units']['distance'];

class Distance {
  private meters: number;
  readonly unit: DistanceUnit;
  readonly unitValue: number;
  readonly unitLabel: string;

  constructor(meters: number, unit: DistanceUnit) {
    this.meters = meters;
    this.unit = unit;
    this.unitValue = distance.fromSI(meters, unit);
    this.unitLabel = distance.label(unit);
  }

  toUnit(unit: DistanceUnit) {
    return new Distance(this.meters, unit);
  }

  format<T = string>(fn?: (d: Distance, precision: number) => T, precision = 2) {
    return (fn ?? defaultFormatter)(this, precision);
  }
}

const useDistance = () => {
  const unit = useUnitSettings().distance;
  const unitLabel = distance.label(unit);
  const create = useCallback((meters: number) => new Distance(meters, unit), [unit]);
  return { create, unit, unitLabel };
};

export default useDistance;
