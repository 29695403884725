import React from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { updateMapConfig, type MapSettings } from 'slices/map.slice';
import CirclePicker from 'components/shared/circlePicker';
import noneUrl from './iconNone.svg';
import selectedAssetUrl from './iconSelectedAsset.svg';
import allAssetsUrl from './iconAllAssets.svg';

interface ContainmentLinesOptionPickerProps {
  config: MapSettings;
  disabled: boolean;
}

const ContainmentLinesOptionPicker = ({ config, disabled }: ContainmentLinesOptionPickerProps) => {
  const t = useTranslations('pages.map.settingsDialog.labs.containmentLines.picker');

  const dispatch = useAppDispatch();

  return (
    <CirclePicker
      value={config.containmentLinesOption}
      onChange={value => dispatch(updateMapConfig({ mapId: config.id, config: { containmentLinesOption: value } }))}
      disabled={disabled}
      options={[
        {
          value: 'none',
          label: t('none'),
          image: (
            <img
              src={noneUrl}
              width={48}
              height={48}
              alt={t('none')}
            />
          ),
        },
        {
          value: 'selectedAsset',
          label: t('selectedAsset'),
          image: (
            <img
              src={selectedAssetUrl}
              width={48}
              height={48}
              alt={t('selectedAsset')}
            />
          ),
        },
        {
          value: 'allAssets',
          label: t('allAssets'),
          image: (
            <img
              src={allAssetsUrl}
              width={48}
              height={48}
              alt={t('allAssets')}
            />
          ),
        },
      ]}
    />
  );
};

export default ContainmentLinesOptionPicker;
