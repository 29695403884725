import React from 'react';
import Page from 'components/pages/page';
import {
  Fingerprint,
  Business,
  CloudUpload,
  NotificationImportant,
  Search,
  Schema
} from '@mui/icons-material';
import { getRefreshedToken } from 'apis/auth';
import {
  SettingsMenuContainer,
  SettingsMenuPanelGrid,
  SettingsMenuPanelItem,
  SettingsMenuSvgIcon,
  SettingsMenuPageWrapper,
} from 'components/shared/settingsMenu';

const FingerprintIcon = SettingsMenuSvgIcon.withComponent(Fingerprint);
const BusinessIcon = SettingsMenuSvgIcon.withComponent(Business);
const CloudUploadIcon = SettingsMenuSvgIcon.withComponent(CloudUpload);
const NotificationImportantIcon = SettingsMenuSvgIcon.withComponent(NotificationImportant);
const SearchIcon = SettingsMenuSvgIcon.withComponent(Search);
const SchemaIcon = SettingsMenuSvgIcon.withComponent(Schema);

const StaffPage = (): JSX.Element => (
  <Page>
    <SettingsMenuPageWrapper>
      <SettingsMenuContainer title="Support Tools">
        <SettingsMenuPanelGrid>
          <SettingsMenuPanelItem
            to="/staff/organisations"
            icon={<BusinessIcon />}
            title="Organisations"
            description="Manage all organisations"
          />
          <SettingsMenuPanelItem
            to="/staff/market-sectors"
            icon={<SchemaIcon />}
            title="Market Sectors"
            description="Manage market sectors and their features"
          />
          <SettingsMenuPanelItem
            to="/staff/distress-incidents"
            icon={<NotificationImportantIcon />}
            title="Active Incidents"
            description="All currently open distress and AMS incidents"
          />
          <SettingsMenuPanelItem
            to="/staff/asset-search"
            icon={<SearchIcon />}
            title="Asset & Device Search"
            description="Global asset & device search"
          />
          <SettingsMenuPanelItem
            to="/staff/provisioning"
            icon={<CloudUploadIcon />}
            title="Provisioning"
            description="Programmatic device creation"
          />
        </SettingsMenuPanelGrid>
      </SettingsMenuContainer>
      <SettingsMenuContainer title="Development">
        <SettingsMenuPanelGrid>
          <SettingsMenuPanelItem
            icon={<FingerprintIcon />}
            title="Copy Firebase Token"
            onClick={async () => {
              navigator.clipboard.writeText((await getRefreshedToken()) || '');
            }}
          />
        </SettingsMenuPanelGrid>
      </SettingsMenuContainer>
    </SettingsMenuPageWrapper>
  </Page>
);

export default StaffPage;
