import React from 'react';
import { useTranslations } from 'use-intl';

interface LimitedListProps<T> {
  items: T[]
  limit: number
  renderItem?: (props: { item: T, index: number }) => JSX.Element
  renderMore?: (props: { items: T[], children: React.ReactElement }) => JSX.Element
}

const DefaultRenderItem = <T, >({ item, index }: { item: T, index: number }): JSX.Element => (
  <span key={index}>{index > 0 && ', '}{item}</span>
);

const LimitedList = <T, >({ items, limit, renderItem, renderMore }: LimitedListProps<T>): JSX.Element => {
  const t = useTranslations('limitedList');
  const itemsToDisplay = items.slice(0, limit);
  const itemsToHide = items.slice(limit);
  const Item = renderItem ?? DefaultRenderItem<T>;
  const More = renderMore ?? 'span';

  return (
    <>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {itemsToDisplay.length > 0 && <span>{itemsToDisplay.map((item, index) => <Item key={index} index={index} item={item} />)}</span>}
      {itemsToHide.length > 0 && ' '}
      {itemsToHide.length > 0 && <More items={itemsToHide}><span>{t('more', { n: itemsToHide.length })}</span></More>}
    </>
  );
};

export default LimitedList;
