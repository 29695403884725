import React from 'react';
import { Typography } from '@mui/material';

const Quantity = ({
  value,
  precision = 2,
  units,
  smallUnits = true
}) => {
  if (typeof value !== 'number' && !value) {
    return <Typography>—</Typography>;
  }
  return (
    <Typography noWrap>
      {value.toFixed(precision)}
      {units ? (
        <>
          {' '}
          {smallUnits ? <small>{units}</small> : units}
        </>
      ) : null}
    </Typography>
  );
};

export default Quantity;
