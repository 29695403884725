import React from 'react';

const Yacht = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 33.535086,9.8038311 c 0,0 5.560599,11.9193449 6.226731,23.8618879 0.322401,5.780067 0.998287,8.869921 -0.694349,21.721868 -1.898423,2.466003 -12.956873,1.593753 -13.344155,0 C 24.030677,42.53564 24.706563,39.445786 25.028964,33.665719 25.695096,21.723176 31.277727,9.8038311 31.277727,9.8038311 c 0,0 0.792843,-3.9033496 1.117664,-3.9253821 0.32482,-0.022032 1.139695,3.9253821 1.139695,3.9253821 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="6"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Yacht;
