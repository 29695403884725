import { PathLayer, PathLayerProps } from '@deck.gl/layers';
import { AccessorFunction, createIterable, DefaultProps } from '@deck.gl/core';
// eslint-disable-next-line import/no-unresolved
import Attribute from '@deck.gl/core/lib/attribute/attribute';
import {
  vsDecl, vsMain, fsDecl, fsMainEnd
} from './shaders/ant-shader.glsl';

// This file will need to be updated if/when we move to DeckGL v8 due to breaking changes in the API

const defaultProps: DefaultProps<AntTrailLayerProps<never | any>> = {
  getTotalPositions: { type: 'accessor', value: () => [] },
  dashScale: 10,
  animationSpeed: 10,
  animate: false
};

interface AntTrailLayerProps<DataT> extends PathLayerProps<DataT> {
  getTotalPositions?: AccessorFunction<DataT, number[]>;
  dashScale: number
  animationSpeed: number
  animate: boolean
}

export default class AntTrailLayer<DataT> extends PathLayer<DataT, AntTrailLayerProps<DataT>> {
  initializeState() {
    super.initializeState();
    this.getAttributeManager()?.addInstanced({
      totalPositions: {
        size: 2,
        update: this.calculateInstanceTotalPositions
      },
    });
  }

  // @ts-ignore
  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        dashScale: this.props.dashScale,
        animationSpeed: this.props.animationSpeed,
        animate: this.props.animate,
      }
    });
  }

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': vsDecl,
      'vs:#main-end': vsMain,
      'fs:#decl': fsDecl,
      'fs:#main-end': fsMainEnd,
    };

    return shaders;
  }

  calculateInstanceTotalPositions(attribute: Attribute, { startRow, endRow }: { startRow: number, endRow: number }) {
    const { data, getTotalPositions } = this.props;

    if (!getTotalPositions) {
      attribute.constant = true;
      attribute.value = new Float32Array(2);
      return;
    }

    const { iterable, objectInfo } = createIterable(data, startRow, endRow);
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const object of iterable) {
      objectInfo.index++;

      const totalPositions = getTotalPositions(object, objectInfo);
      // For each line segment we have [startTotalPosition, endTotalPosition]
      for (let j = 0; j < totalPositions.length - 2; j++) {
        attribute.value![i++] = totalPositions[j];
        attribute.value![i++] = totalPositions[j + 1];
      }
    }

    attribute.constant = false;
  }
}

AntTrailLayer.layerName = 'AntTrailLayer';
AntTrailLayer.defaultProps = defaultProps;
