import React from 'react';

import { distance } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import Quantity from '../quantity';

export interface DistanceProps {
  distanceInMetres: number,
  precision?: number,
  smallUnits?: boolean
}

const Distance = ({
  distanceInMetres,
  precision,
  smallUnits = true
}: DistanceProps): JSX.Element => {
  const units = useUnitSettings().distance;
  const distanceInLocale = distance.fromSI(distanceInMetres, units);
  const unitsLabel = distance.label(units);
  return (
    <Quantity value={distanceInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Distance;
