import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LinkAbove from 'components/shared/linkAbove';
import RoutedTabs from 'components/shared/routedTabs';
import AllTab from './allTab';
import AssignAssetsTab from './assignAssetsTab';

const IcePage = (): JSX.Element => {
  const t = useTranslations('pages.manage.ice');

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
          </Box>

          <RoutedTabs
            label={t('tabs.label')}
            tabs={[
              {
                value: 'groups',
                to: '/manage/ice/groups',
                label: t('tabs.groups'),
                content: <AllTab />,
              },
              {
                value: 'assign',
                to: '/manage/ice/assign-assets',
                label: t('tabs.assignAssets'),
                content: <AssignAssetsTab />
              },
            ]}
          />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default IcePage;
