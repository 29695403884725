import React from 'react';

const DownArrow = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z" />
    <g>
      <rect className="icon-detail" fill="#fff" x="5.4" y="4.9" transform="matrix(0.7098 -0.7044 0.7044 0.7098 -4.0268 6.789)" width="1.6" height="6.9" />
      <rect className="icon-detail" fill="#fff" x="6.5" y="7.5" transform="matrix(0.7098 -0.7044 0.7044 0.7098 -2.9533 9.4162)" width="6.9" height="1.6" />
    </g>
  </svg>
);

export default DownArrow;
