import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Grid,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import LangSelector from 'components/shared/languageSelector';
import PasswordStrength from 'components/shared/passwordStrength';
import * as passwordHelper from 'helpers/password';

import useTranslation from 'hooks/useTranslation';
import useSnackbar from 'hooks/useSnackbar';
import { resetPassword } from 'apis/auth';
import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';
import useStyles from './setNewPassword-styles';

const ActionButton = ({
  disabled,
  updating,
  complete,
  onClick
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.setNewPassword');

  if (complete) {
    return (
      <Button
        className={classes.actionButton}
        onClick={onClick}
        variant="contained"
        color="primary"
      >
        {t('returnToLogIn')}
      </Button>
    );
  }

  if (updating) {
    return (
      <Button
        className={classes.actionButton}
        variant="contained"
        color="primary"
        disabled
      >
        {t('resettingPassword')}
      </Button>
    );
  }

  return (
    <SubmitButton
      disabled={disabled}
      text={t('resetPassword')}
      onSubmit={onClick}
    />
  );
};

const SetNewPasswordPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation('pages.setNewPassword');
  const snackbar = useSnackbar();

  const [fields, setField] = useState({
    password: '',
    confirm: ''
  });
  const update = (id, value) => setField({
    ...fields,
    [id]: value
  });

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    setIsValid(!passwordHelper.validate(fields.password, fields.confirm));
  }, [fields]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const parameters = new URLSearchParams(window.location.search);
  const token = parameters.get('oobCode');
  const mode = parameters.get('mode');

  if (!token || mode !== 'resetPassword') {
    navigate('/reset-password');
    return null;
  }

  const handleSubmit = e => {
    setIsUpdating(true);
    e.preventDefault();
    if (!isValid) return;
    resetPassword(fields.password, token)
      .then(() => {
        setIsUpdating(false);
        setIsComplete(true);
        navigate('/login');
        window.location.reload();
      }).catch(error => {
        snackbar.display({
          id: 'resetPasswordError',
          type: 'error',
          text: t('resetPasswordError'),
          persist: true,
        });
        console.log(error);
        // redirect to reset password page after giving the user 5 seconds to read the error
        setTimeout(() => {
          navigate('/reset-password');
          window.location.reload();
        }, 5000);
      });
  };

  return (
    <Box className={classes.container}>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.formWrapper}>
            <Box className={classes.resetPasswordForm}>
              <Box className={classes.iconWrapper}>
                <TracplusIcon />
              </Box>
              <Typography variant="h1" className={classes.title}>{t('setNewPassword')}</Typography>
              <Typography variant="body1" className={classes.subtitle}>{t('setNewPasswordMessage', { minimumPasswordLength: passwordHelper.MINIMUM_PASSWORD_LENGTH })}</Typography>
              <LoginInput
                id="password"
                type="password"
                placeholderText={t('newPassword')}
                value={fields.password}
                validate={() => passwordHelper.validateSingle(fields.password)}
                onChange={update}
                onEnterKeypress={handleSubmit}
                t={t}
              />
              {fields.password.length >= passwordHelper.MINIMUM_PASSWORD_LENGTH && <PasswordStrength value={fields.password} />}
              <LoginInput
                id="confirm"
                type="password"
                placeholderText={t('confirmPassword')}
                value={fields.confirm}
                validate={() => passwordHelper.validateSingle(fields.confirm, fields.password)}
                onChange={update}
                onEnterKeypress={handleSubmit}
                t={t}
              />

              <ActionButton
                disabled={!isValid}
                updating={isUpdating}
                complete={isComplete}
                onClick={handleSubmit}
              />

              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={() => navigate('/login')}
              >
                {t('cancelButton')}
              </Button>

              <LangSelector />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.signupImageWrapper}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/mandurah-water-rescue.png)' }}>
            <Box className={classes.imageOfTheWeekText}>Mandurah Water Rescue</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetNewPasswordPage;
