import React from 'react';
import { useTheme } from '@mui/material/styles';

const StatusIcon = ({
  status = 'green'
}) => {
  const theme = useTheme();
  const colorMap = {
    red: theme.palette.primary.distressRed,
    yellow: theme.palette.primary.yellow,
    green: theme.palette.primary.activeGreen
  };
  const color = colorMap[status.toLowerCase()];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 24 24"
    >
      <title>Status Icon</title>
      <g fill={color}>
        <path
          fill={color}
          d="M12,0A11.922,11.922,0,0,0,8,.7V13h3L7,18,3,13H6V1.624A11.983,11.983,0,0,0,16,23.3V11H13l4-5,4,5H18V22.376A11.99,11.99,0,0,0,12,0Z"
        />
      </g>
    </svg>
  );
};

export default StatusIcon;
