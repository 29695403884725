import React, { ReactNode } from 'react';
import useFeatureTrial from 'hooks/features/useFeatureTrial';
import { useStaff } from 'hooks/session/useStaff';

interface FeatureProps {
  id: string
  enabled?: ReactNode
  disabled?: ReactNode
  loading?: ReactNode
  expired?: ReactNode
  preTrial?: ReactNode
  allowStaff?: boolean
}

/**
 * @deprecated This is for feature toggles which will soon be removed
 */
export const Feature = ({ id, enabled, disabled, loading, expired, preTrial, allowStaff }: FeatureProps): JSX.Element => {
  const isStaff = useStaff();
  const value = useFeatureTrial(id);

  if (allowStaff && isStaff) {
    return enabled as JSX.Element;
  }

  const featureCanExpire = value && value.featureCanExpire;
  const expiry = value ? value.expiryDate : null;

  if (preTrial && featureCanExpire && expiry === null) {
    return preTrial as JSX.Element;
  }

  if (expired && featureCanExpire && expiry && Date.parse(expiry) < Date.now()) {
    return expired as JSX.Element;
  }

  return (
    <>
      {value && enabled}
      {value === false && disabled}
      {value === undefined && loading}
    </>
  );
};
