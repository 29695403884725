// Takes, for example, the package "DROP=100%;VOL=3024.00;ADD=Gel;UNIT=gal(US)"
// and makes { drop: '100%', vol: '3024', add: 'Gel', unit: 'gal(US)' }.
// This is what we get from DZMxs
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { DateTime } from 'luxon';

const kvpPackageToMetadata = (kvpPackage: string): any => kvpPackage
  .split(';')
  .reduce((prev, curr) => {
    const kvp = curr.split('=');
    return { ...prev, [kvp[0].toLowerCase()]: kvp[1] };
  }, {} as Record<string, string | number>);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterMetadata = (reportPackage: string): any => {
  if (!reportPackage) return {};

  let metadata: Record<string, string>;
  try {
    metadata = JSON.parse(reportPackage);
  } catch {
    try {
      metadata = kvpPackageToMetadata(reportPackage);
    } catch {
      return undefined;
    }
  }

  delete metadata.AtuProtocolVersion;
  delete metadata.ManufacturerId;
  delete metadata.ModelId;
  delete metadata.FirmwareMajor;
  delete metadata.FirmwareMinor;
  delete metadata.FirmwarePatch;
  delete metadata['_TP-FormId'];
  delete metadata['_TP-FormVersion'];
  delete metadata['_TP-FormLocation'];
  // delete anything that isn't a number or a non-empty string from the metadata to avoid trying to print objects etc
  const keys = Object.keys(metadata);
  keys.forEach(key => {
    if (typeof metadata[key] !== 'number' && (typeof metadata[key] !== 'string' || metadata[key].length < 1)) delete metadata[key];
  });

  // associate the keys in volumeKeys with the unit, then delete the unit
  // disabled because this might be bad for exported data
  // if (keys.includes('unit')) {
  //   const { unit } = metadata;
  //   volumeKeys.forEach(k => {
  //     if (keys.includes(k)) {
  //       metadata[k] += unit;
  //       delete metadata.unit; // only delete it if it gets used
  //     }
  //   });
  // }

  return metadata;
};

interface FormData {
  '_TP-FormData': {
    FormName: string | undefined
  }
  '_TP-FormLocation': string
  '_TP-FormId': number
  '_TP-FormVersion': number
}
const isValidFormData = (data: any): data is FormData => !!data?.['_TP-FormData'];

export const extractFormData = (reportPackage: string): Form | undefined => {
  let metadata: unknown;
  try {
    metadata = JSON.parse(reportPackage);
  } catch {
    return undefined;
  }
  if (!isValidFormData(metadata)) return undefined;
  const data = metadata['_TP-FormData'];
  const location = metadata['_TP-FormLocation'];
  const id = metadata['_TP-FormId'];
  const version = metadata['_TP-FormVersion'];

  return {
    title: data.FormName ?? 'Form',
    data,
    location,
    id,
    version
  };
};

export const parseReports = (reports: Report[]): Report[] => reports.map((r: Report) => ({ ...r, metadata: filterMetadata(r.package ?? ''), form: extractFormData(r.package ?? '') }));

export const dateRangeParams = (from: number, until: number) => new URLSearchParams([
  ['from', DateTime.fromMillis(from).toUTC().toISO()],
  ['until', DateTime.fromMillis(until).toUTC().toISO()]
]);
