import React from 'react';
import { useTranslations } from 'use-intl';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Dialog,
  DialogContent,
  Tooltip,
  ButtonBase, Tabs, Tab, Divider, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UnitFirestoreSettingsSelectorList } from 'components/statusbar/generalSettings/unitFirestoreSettingsSelectorList';
import TabPanel from 'components/shared/tabPanel';
import { UiFirestoreSettingsSelectorList } from 'components/statusbar/generalSettings/uiFirestoreSettingsSelectorList';
import { RegionalFirestoreSettingsDialog } from 'components/statusbar/regional/regional-dialog-firestore';

const GeneralSettingsButton = styled(ButtonBase)(({ theme }) => `
  color: ${theme.palette.common.textConstant};
  padding: ${theme.spacing(0, 3)};
  border-left: ${theme.border.default};
  &:hover {
    background-color: ${theme.palette.common.lightBlue};
  }
`);

const GeneralSettingsTab = styled(Tab)({
  fontSize: '1.3rem',
  textTransform: 'none',
});

export const GeneralSettingsDialog = () => {
  const t = useTranslations('statusbar');
  const [open, setOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <Tooltip title={t('display.title')} placement="top">
        <GeneralSettingsButton onClick={() => setOpen(true)} aria-label={t('display.tooltip')}>
          <TuneIcon />
        </GeneralSettingsButton>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent sx={{ height: '70vh', overflow: 'hidden', p: 0 }}>
          <Typography variant="h1" sx={{ pl: 4, pr: 4, pt: 3 }} gutterBottom>{t('settings.displaySettings')}</Typography>
          <Tabs value={currentTab} onChange={handleChange}>
            <GeneralSettingsTab label={t('settings.general')} />
            <GeneralSettingsTab label={t('settings.units')} />
            <GeneralSettingsTab label={t('settings.locale')} />
          </Tabs>
          <Divider style={{ marginTop: -1.5 }} />
          <Box style={{ overflowY: 'auto', height: 'calc(70vh - 110px)' }}>
            <TabPanel currentTab={currentTab} index={0}>
              <UiFirestoreSettingsSelectorList />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={1}>
              <UnitFirestoreSettingsSelectorList />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2}>
              <RegionalFirestoreSettingsDialog />
            </TabPanel>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
