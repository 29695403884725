import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslations } from 'use-intl';
import { Localization, MaterialTableProps } from '@material-table/core';
import { Typography, Stack, Box, Chip, Tooltip } from '@mui/material';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import { Delete, Edit, InfoOutlined, ErrorOutline } from '@mui/icons-material';
import type { EventNotificationGroupIds } from 'apis/rest/eventNotifications/types';
import LimitedList from 'components/shared/LimitedList';
import type { RowData } from './types';
import { EventNotificationAssetGroupsStatus, useEventNotificationAssetGroupsFeatureStatus } from '../../helpers';

export interface EventNotificationTableProps {
  data?: EventNotificationGroupIds[],
  isLoading: boolean,
  readonly: boolean,
  onRowDelete: (id: number, version: number) => void;
  onRowOpen: (id: number) => void;
}

const AssetGroupsStatus: React.FC<{ status: EventNotificationAssetGroupsStatus }> = ({ status }) => {
  const t = useTranslations('pages.manage.eventNotifications.tableView');

  if (status === EventNotificationAssetGroupsStatus.Never || status === EventNotificationAssetGroupsStatus.None) {
    return (
      <Tooltip title={t('status.noRelevantAssets')}>
        <ErrorOutline color="error" />
      </Tooltip>
    );
  }
  if (status === EventNotificationAssetGroupsStatus.Some) {
    return (
      <Tooltip title={t('status.someRelevantAssets')}>
        <ErrorOutline color="warning" />
      </Tooltip>
    );
  }
  return null;
};

export const EventNotificationTable = ({
  data,
  isLoading,
  readonly,
  onRowOpen,
  onRowDelete
}: EventNotificationTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.tableView');
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);
  const localization: Localization = { header: { actions: '' } };
  const getFeatureStatus = useEventNotificationAssetGroupsFeatureStatus();

  const rowData: RowData[] = useMemo(
    () => data?.map(eng => ({
      id: eng.id,
      name: eng.name,
      isDeleted: !eng.isActive,
      rowVersion: eng.rowVersion,
      notificationRuleNames: eng.notificationRuleNames,
      assetGroupNames: eng.assetGroupNames,
      featureStatus: getFeatureStatus(eng.assetGroupIds),
    })) ?? [],
    [data, getFeatureStatus],
  );

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{row.name}</Typography>
          {(row.featureStatus === EventNotificationAssetGroupsStatus.None || row.featureStatus === EventNotificationAssetGroupsStatus.Never) && (
            <Chip label={t('columns.disabled')} />
          )}
        </Stack>
      )
    },
    {
      title: t('columns.assetGroup'),
      field: 'assetGroup',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      filtering: false,
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <AssetGroupsStatus status={row.featureStatus} />
          <LimitedList<string>
            items={row.assetGroupNames}
            limit={3}
            renderMore={({ items, children }) => (
              <Tooltip title={items.map((name, index) => <div key={`tooltip-${index}`}>{name}</div>)}>
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      ),
    },
    {
      title: t('columns.events'),
      field: 'events',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      filtering: false,
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <LimitedList<string>
            items={row.notificationRuleNames ?? []}
            limit={3}
            renderMore={({ items, children }) => (
              <Tooltip
                title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}
              >
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      )
    },
  ]), [t]);

  const actions = useMemo<MaterialTableProps<RowData>['actions']>(() => {
    if (readonly) {
      return [
        row => ({
          icon: () => <InfoOutlined sx={{ color: 'common.text' }} />,
          tooltip: t('tooltips.view'),
          onClick: () => onRowOpen(row.id),
        })
      ];
    }
    return [
      row => ({
        icon: () => <Edit sx={{ color: 'common.text' }} />,
        tooltip: t('tooltips.edit'),
        onClick: () => onRowOpen(row.id),
      }),
      row => ({
        icon: () => <Delete sx={{ color: 'common.text' }} />,
        tooltip: t('tooltips.delete'),
        onClick: () => onRowDelete(row.id, row.rowVersion),
      }),
    ];
  }, [readonly, t, onRowDelete, onRowOpen]);

  return (
    <>
      <PersistentTable<RowData>
        settingsCategory="eventNotitficationGroupsTable"
        data={rowData}
        isLoading={isLoading}
        columns={columns}
        onRowClick={(_, row) => onRowOpen(row?.id ?? 0)}
        localization={localization}
        components={{
          Pagination,
          Container: Box,
          Toolbar: () => null
        }}
        options={{
          filtering: true,
          search: false,
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        actions={actions}
        sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 }, 'tbody tr': { height: 65 } }} />
      <Box ref={footerRef} position="sticky" bottom={0} sx={{ '& .MuiToolbar-regular': { padding: 10 / 3 } }} />
    </>
  );
};
