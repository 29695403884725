import React, { ReactNode, useMemo, useState } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import {
  Box,
  Chip,
  Skeleton,
  Stack,
  Tooltip, Typography,
} from '@mui/material';
import { Edit, Delete, InfoOutlined } from '@mui/icons-material';
import { Localization, MaterialTableProps } from '@material-table/core';
import { useTranslations } from 'use-intl';
import insensitiveSort from 'utils/insensitiveSort';
import PersistentTable from 'components/shared/persistentTable';
import LimitedList from 'components/shared/LimitedList';
import StickyPagination from 'components/shared/stickyPagination';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useGetPeople } from 'apis/rest/people/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import usePeopleById from 'hooks/people/usePeopleById';
import useLastDefined from 'hooks/useLastDefined';
import usePermissions from 'hooks/session/usePermissions';

export interface RowData {
  group: ContactGroup
  people: Person[]
  assets: (AssetBasic | undefined)[]
}

export interface ContactGroupsTableComponents {
  DeleteDialog: (props: {
    open: boolean
    group: ContactGroup
    onClose: (status: string, groupId: number) => void
    mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id'>>
  }) => JSX.Element | null
  NoGroups: () => JSX.Element | null
  DefaultGroup?: (props: { children: ReactNode }) => JSX.Element | null
}

interface ContactGroupsTableProps {
  data: ContactGroup[] | undefined
  isLoading: boolean
  settingsCategory: string
  components: ContactGroupsTableComponents
  navigateToRow: (row: RowData) => void
  deleteMutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id'>>
  createdGroupId: number | undefined
  hideAssetColumn?: boolean
}

export const defaultComponents: Required<Pick<ContactGroupsTableComponents, 'DefaultGroup'>> = {
  DefaultGroup: ({ children }) => <Chip label={children} color="primary" sx={{ my: -1 }} />,
};

export const ContactGroupsTable = ({
  data,
  isLoading,
  settingsCategory,
  navigateToRow,
  deleteMutation,
  createdGroupId,
  components: { DeleteDialog, NoGroups, DefaultGroup = defaultComponents.DefaultGroup },
  hideAssetColumn,
}: ContactGroupsTableProps): JSX.Element => {
  const permissions = usePermissions();
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupsTable');
  const [deleteContactGroupId, setDeleteContactGroupId] = useState<number>();

  const assetsQuery = useGetAssetsList().query;
  const { query: peopleQuery } = useGetPeople();

  const peopleById = usePeopleById(peopleQuery.data);

  const assetsById = useMemo(
    () => assetsQuery.data?.reduce<Record<number, AssetBasic | undefined>>((acc, asset) => {
      acc[asset.id] = asset;
      return acc;
    }, {}) ?? {},
    [assetsQuery.data],
  );

  const assetLabel = useAssetLabel();

  const rowData: RowData[] = useMemo(() => (
    data?.map(group => ({
      group,
      people: group.peopleWithOrder
        .reduce<{ person: Person, order: number }[]>((acc, x) => {
          const person = peopleById?.[x.personId];
          if (person) acc.push({ person, order: x.order });
          return acc;
        }, [])
        .sort((a, b) => a.order - b.order)
        .map(x => x.person),
      assets: (group.deviceAndAssetIds ?? []).map(x => assetsById[x.assetId]),
    })) ?? []
  ), [data, peopleById, assetsById]);

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => ([
    {
      title: t('columns.groupName'),
      field: 'group.name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.group.name, b.group.name),
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{row.group.name}</Typography>
          {row.group.isDefault && <Box><DefaultGroup>{t('default')}</DefaultGroup></Box>}
          {row.group.id === createdGroupId && <Chip label={t('created')} color="success" />}
        </Stack>
      ),
    },
    {
      title: t('columns.people'),
      field: 'people',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      customFilterAndSearch: (filter, row) => (
        row.people.some(person => person.name.toLowerCase().includes(filter.toLowerCase()))
      ),
      render: row => {
        if (peopleQuery.isLoading) return <Skeleton width="16em" />;
        if (!row.people.length) return <Typography fontStyle="italic" color="warning.main">{t('noPeople')}</Typography>;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <LimitedList<string>
              items={row.people.map(person => person.name)}
              limit={1}
              renderMore={({ items, children }) => (
                <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                  <Chip label={children} variant="outlined" />
                </Tooltip>
              )}
            />
          </Stack>
        );
      },
    },
    {
      title: t('columns.assignedAssets'),
      field: 'group.assetIds',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      customFilterAndSearch: (filter, row) => (
        row.assets.some(a => assetLabel(a)?.toLowerCase().includes(filter.toLowerCase()))
      ),
      render: row => (
        assetsQuery.isLoading ? <Skeleton width="16em" /> : (
          <Stack direction="row" spacing={1} alignItems="center">
            <LimitedList<string>
              items={row.assets.map(a => assetLabel(a) ?? t('unknownAsset'))}
              limit={1}
              renderMore={({ items, children }) => (
                <Tooltip title={items.map(x => <div key={x}>{x}</div>)}>
                  <Chip label={children} variant="outlined" />
                </Tooltip>
              )}
            />
          </Stack>
        )
      ),
      hidden: hideAssetColumn ?? false,
    },
  ]), [t, peopleQuery.isLoading, assetsQuery.isLoading, DefaultGroup, createdGroupId, assetLabel]);

  const actions = useMemo<MaterialTableProps<RowData>['actions']>(() => {
    if (permissions.canEditContactGroups) {
      return [
        row => ({
          icon: () => <Edit sx={{ color: 'common.text' }} />,
          tooltip: t('tooltips.edit'),
          onClick: () => navigateToRow(row),
        }),
        row => {
          return {
            icon: () => <Delete sx={{ color: 'common.text' }} />,
            tooltip: t('tooltips.delete'),
            onClick: () => setDeleteContactGroupId(row.group.id),
          };
        },
      ];
    }

    return [
      row => ({
        icon: () => <InfoOutlined sx={{ color: 'common.text' }} />,
        tooltip: t('tooltips.view'),
        onClick: () => navigateToRow(row),
      }),
    ];
  }, [permissions, t, navigateToRow]);

  const onCloseDeleteDialog = () => setDeleteContactGroupId(undefined);

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  const localization: Localization = { header: { actions: '' } };
  if (!isLoading && !rowData.length) {
    localization.body = { emptyDataSourceMessage: <Box my={3}><NoGroups /></Box> };
  }

  const groupForDeletion = useLastDefined(data?.find(g => g.id === deleteContactGroupId), []);

  return (
    <>
      <PersistentTable<RowData>
        settingsCategory={settingsCategory}
        data={rowData}
        isLoading={isLoading}
        columns={columns}
        actions={actions}
        localization={localization}
        components={{
          Toolbar: () => null,
          Container: Box,
          Pagination,
        }}
        options={{
          filtering: true,
          draggable: false,
          showTitle: false,
          search: false,
          actionsColumnIndex: -1,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        onRowClick={(e, row) => {
          if (row) navigateToRow(row);
        }}
        sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }}
      />
      {groupForDeletion && (
        <DeleteDialog
          open={deleteContactGroupId !== undefined}
          group={groupForDeletion}
          onClose={onCloseDeleteDialog}
          mutation={deleteMutation}
        />
      )}

      <Box
        ref={footerRef}
        position="sticky"
        bottom={0}
        sx={{ '& .MuiToolbar-regular': { padding: 10 / 3 } }}
      />
    </>
  );
};
