import React, { ComponentProps, useState } from 'react';
import { Grid, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import {
  SettingsMenuLink,
  SettingsMenuDetails,
  SettingsMenuDetailsDescription,
  SettingsMenuDetailsTitle,
  SettingsMenuIconWrapper,
  SettingsMenuDisabledLink,
} from './settingsMenu-styles';

interface SettingsMenuPanelItemProps {
  icon: React.ReactNode
  title: React.ReactNode
  description?: React.ReactNode
  to?: ComponentProps<typeof Link>['to']
  onClick?: React.EventHandler<React.MouseEvent>
  badge?: React.ReactNode
  disabled?: boolean
}

const StyledLink = SettingsMenuLink.withComponent(Link);
const StyledBox = SettingsMenuLink.withComponent(Box);
const StyledDisabledLink = SettingsMenuDisabledLink.withComponent(Box);

type WrapProps = Omit<ComponentProps<typeof StyledBox>, 'to'> & { to?: ComponentProps<typeof Link>['to'], disabled: boolean }

const Wrap = ({ to, disabled, ...rest }: WrapProps) => {
  if (disabled) return <StyledDisabledLink {...rest} role="link" aria-disabled="true" />;
  if (to) return <StyledLink to={to} {...rest} />;
  return <StyledBox {...rest} />;
};

export const SettingsMenuPanelItem = ({ to, onClick, icon, title, description, badge, disabled = false }: SettingsMenuPanelItemProps): JSX.Element => {
  const [labelId] = useState(() => uniqueId('settingsMenuPanelItemLabel'));
  return (
    <Grid item container xs={12} sm={6} xl={4} justifyItems="stretch">
      <Wrap to={to} onClick={onClick} aria-labelledby={labelId} disabled={disabled}>
        <SettingsMenuIconWrapper>{icon}</SettingsMenuIconWrapper>
        <SettingsMenuDetails>
          <Stack direction="row" spacing={1} alignItems="center">
            <SettingsMenuDetailsTitle id={labelId}>{title}</SettingsMenuDetailsTitle>
            {badge}
          </Stack>
          {description && <SettingsMenuDetailsDescription>{description}</SettingsMenuDetailsDescription>}
        </SettingsMenuDetails>
      </Wrap>
    </Grid>
  );
};

export default SettingsMenuPanelItem;
