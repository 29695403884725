import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { To } from 'history';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';

interface MissingPageProps {
  title: string
  description: React.ReactNode
  to: To
  linkText: React.ReactNode
}

const MissingPage = ({ title, description, to, linkText }: MissingPageProps) => (
  <Page title={title}>
    <SettingsMenuPageWrapper justifyContent="center" alignItems="center" spacing={3}>
      <Typography>{description}</Typography>
      <Button variant="contained" component={Link} to={to}>{linkText}</Button>
    </SettingsMenuPageWrapper>
  </Page>
);

export default MissingPage;
