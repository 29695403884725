import React from 'react';
import { Grid } from '@mui/material';
import Page from 'components/pages/page';

const missingOrganisationPage = () => (
  <Page>
    <Grid
      container
      spacing={0}
      align="center"
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <h2>The organisation you were looking for does not exist.</h2>
      </Grid>
    </Grid>
  </Page>
);

export default missingOrganisationPage;
