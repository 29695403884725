import React from 'react';

const B737 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 34.197249,59.411553 c 0,0 10.036231,2.707497 10.053823,2.444168 0.113954,-1.705859 -0.194795,-3.422035 -0.431568,-3.658809 -0.236774,-0.236773 -8.456581,-5.279054 -8.486179,-6.137358 -0.02959,-0.858304 0.654574,-1.862504 0.887814,-5.011493 0.233245,-3.148989 0.01485,-11.230737 0.01485,-11.230737 l 5.435078,-2.56e-4 c 0,0 16.905953,5.289274 16.919787,4.907972 0.120342,-3.316845 -0.807983,-4.885459 -0.807983,-4.885459 -1.541929,-0.781403 -14.289926,-7.900907 -14.44719,-8.026335 -0.157264,-0.125428 0.220771,-1.869675 0.193966,-3.311884 -0.03514,-1.890918 -0.500431,-3.125846 -0.523075,-3.245742 -0.04207,-0.2228 -3.751602,-0.458217 -4.173505,0.0105 -0.324754,0.360786 -0.129708,3.724808 -0.129708,3.724808 0,0 -2.13565,-0.915239 -2.313231,-1.2704 -0.177581,-0.35516 -0.181352,-8.273632 -0.270146,-12.446765 -0.08879,-4.1731333 -2.954387,-9.4710041 -3.909285,-9.4710041 -0.954899,0 -3.642396,5.2978708 -3.731185,9.4710041 -0.08879,4.173133 -0.09256,12.091605 -0.270146,12.446765 -0.177581,0.355161 -2.313231,1.2704 -2.313231,1.2704 0,0 0.195046,-3.364022 -0.129708,-3.724808 -0.421903,-0.468714 -4.131431,-0.233297 -4.173505,-0.0105 -0.02264,0.119896 -0.487933,1.354824 -0.523075,3.245742 -0.02681,1.442209 0.35123,3.186456 0.193966,3.311884 -0.157264,0.125428 -12.9052613,7.244932 -14.4471904,8.026335 0,0 -0.9283247,1.568614 -0.8079832,4.885459 0.013834,0.381302 17.3678586,-4.907972 17.3678586,-4.907972 l 4.987007,2.56e-4 c 0,0 -0.218399,8.081748 0.01485,11.230737 0.23324,3.148989 0.91741,4.153189 0.887814,5.011493 -0.02959,0.858304 -8.249405,5.900585 -8.486179,6.137358 -0.236774,0.236774 -0.545522,1.95295 -0.431568,3.658809 0.0176,0.263329 10.053824,-2.444168 10.053824,-2.444168 0,0 0.263991,-0.07474 1.59426,-0.06499 1.330268,-0.0098 2.200748,0.06499 2.200748,0.06499 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default B737;
