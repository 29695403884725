import React, { ReactNode, useCallback, useState, useMemo } from 'react';
import { UserSettingsContext, UserSettings, UserSettingsState } from './userSettingsContext';
import * as persistence from './persistence';

const UserSettingsContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const [userSettingsState, setUserSettingsState] = useState<UserSettings>(persistence.get);

  const setUserSettingsWithPersistence: React.Dispatch<React.SetStateAction<UserSettings>> = useCallback(inp => {
    setUserSettingsState(oldState => {
      const state = typeof inp === 'function' ? inp(oldState) : inp;
      persistence.set(state);
      return state;
    });
  }, [setUserSettingsState]);

  const state = useMemo(
    () => [userSettingsState, setUserSettingsWithPersistence] as UserSettingsState,
    [userSettingsState, setUserSettingsWithPersistence]
  );

  return (
    <UserSettingsContext.Provider value={state}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContextProvider;
