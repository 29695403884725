import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useTranslations } from 'use-intl';
import { PersonLanguageCode } from 'constants/person';

interface PersonLanguagePickerProps {
  value: PersonLanguageCode | undefined
  setValue: (value: PersonLanguageCode) => void
  disabled: boolean
}

export const PersonLanguagePicker = ({ value, setValue, disabled }: PersonLanguagePickerProps): JSX.Element => {
  const t = useTranslations('pages.manage.person.basicDetails');
  return (
    <TextField
      select
      label={t('prefLanguage')}
      value={value}
      onChange={event => {
        const code = event.target.value as PersonLanguageCode;
        setValue(code);
      }}
      disabled={disabled}
      fullWidth
    >
      {Object.values(PersonLanguageCode).map(code => (
        <MenuItem key={code} value={code}>{t(`language.${code}`)}</MenuItem>
      ))}
    </TextField>
  );
};
