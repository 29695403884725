import React from 'react';

const T6 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 21.398842,60.423202 0.03227,-5.47054 c 0,0 7.344585,-0.951237 7.542793,-1.455584 0.43111,-1.09697 0.617101,-4.35749 0.617101,-4.35749 0.477643,-2.687926 -0.685636,-12.511951 -1.040058,-15.46455 0,0 -0.310447,-0.01631 -3.740864,-0.225712 C 20.063928,33.159608 4.6272122,31.538807 2.2099093,31.052187 1.7076836,30.951085 1.6624795,26.308822 1.8813803,23.042597 10.527838,22.076903 27.578181,21.649637 28.649565,21.606607 l 0.330153,-11.199185 c 0,0 -1.635892,-0.212096 -0.872917,-2.2725349 0.152183,-0.4109739 2.072924,-0.6457656 2.130544,-1.0802071 0.353482,-2.6650197 1.568465,-4.0306632 1.903112,-4.0237997 0.310768,0.00637 1.532458,1.3657068 1.873958,4.0322885 0.05567,0.4346961 1.975333,0.6781189 2.125667,1.0897727 0.753706,2.0638475 -0.883123,2.2685885 -0.883123,2.2685885 l 0.279811,11.200556 c 1.071179,0.04784 18.11943,0.551746 26.761459,1.556295 0.204218,3.267176 0.138148,7.909188 -0.364527,8.008032 -2.419466,0.47575 -17.863311,2.027148 -22.610722,2.29553 -3.431323,0.19398 -3.74184,0.208895 -3.74184,0.208895 -0.36769,2.950976 -1.575115,12.769673 -1.109559,15.459719 0,0 0.171334,3.261323 0.597508,4.36022 0.195939,0.505232 7.536175,1.489473 7.536175,1.489473 l 0.0077,5.47063 -10.604367,1.235677 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default T6;
