import React, { Dispatch, ReducerAction, ReducerState, useMemo, useState } from 'react';
import { Autocomplete, Box, ButtonBase, Stack, TextField } from '@mui/material';
import { useTranslations } from 'use-intl';
import { camelCase } from 'lodash';
import { AircraftType, useGetAircraftTypes } from 'apis/aircraftTypes/hooks';
import { AssetCategory } from './categories';
import { reducer } from './reducer';

const useCategoryAircraftTypes = (category: AssetCategory | undefined) => {
  const aircraftTypes = useGetAircraftTypes().query.data;
  return useMemo(
    () => {
      if (!category?.aircraftDescription) return [];
      return aircraftTypes?.filter(t => category.aircraftDescription!.includes(t.type as AircraftType['type'])) ?? [];
    },
    [aircraftTypes, category?.aircraftDescription],
  );
};

interface AircraftFieldsProps {
  category: AssetCategory | undefined
  state: ReducerState<typeof reducer>
  dispatch: Dispatch<ReducerAction<typeof reducer>>
  disabled: boolean
}

export const AircraftFields = ({ category, state, dispatch, disabled }: AircraftFieldsProps): JSX.Element | null => {
  const t = useTranslations('pages.assets.create');
  const categoryAircraftTypes = useCategoryAircraftTypes(category);
  const [manualEntry, setManualEntry] = useState(false);
  const selectedAircraftType = useMemo(
    () => {
      const make = state.make?.trim().toLowerCase() ?? '';
      const model = state.model?.trim().toLowerCase() ?? '';
      if (!make && !model) return undefined;
      return categoryAircraftTypes.find(a => {
        if (make && !a.manufacturer.toLowerCase().startsWith(make)) return false;
        if (model && !a.model.toString().toLowerCase().startsWith(model)) return false;
        return true;
      });
    },
    [categoryAircraftTypes, state.make, state.model],
  );

  if (!category) return null;

  if (!manualEntry && categoryAircraftTypes.length > 0) {
    return (
      <Stack spacing={1}>
        <Autocomplete
          key={category.id}
          value={selectedAircraftType ?? null}
          options={categoryAircraftTypes}
          sx={{ width: '50ch' }}
          renderInput={params => <TextField {...params} label={t('aircraft.model', { category: camelCase(category.id) })} required />}
          getOptionLabel={option => `${option.manufacturer} ${option.model}`}
          onChange={(event, value) => dispatch({ type: 'SET_AIRCRAFT', payload: value })}
          disabled={disabled}
        />
        <Box>
          <ButtonBase
            sx={{ color: 'primary.main', '&:hover': { textDecoration: 'underline' } }}
            onClick={() => setManualEntry(true)}
            disabled={disabled}
            disableRipple
          >
            {t('aircraft.enterManually')}
          </ButtonBase>
        </Box>
      </Stack>
    );
  }

  if (manualEntry || !categoryAircraftTypes.length) {
    return (
      <Stack spacing={1}>
        <Stack direction="row" spacing={3}>
          <TextField
            label={t('manufacturer')}
            value={state.make ?? ''}
            onChange={event => dispatch({ type: 'SET_MAKE', payload: event.target.value })}
            required
            fullWidth
            disabled={disabled}
          />
          <TextField
            label={t('model')}
            value={state.model ?? ''}
            onChange={event => dispatch({ type: 'SET_MODEL', payload: event.target.value })}
            required
            fullWidth
            disabled={disabled}
          />
          <TextField
            label={t('variant')}
            value={state.variant ?? ''}
            onChange={event => dispatch({ type: 'SET_VARIANT', payload: event.target.value })}
            fullWidth
            disabled={disabled}
          />
        </Stack>
        {categoryAircraftTypes.length > 0 && (
          <Box>
            <ButtonBase
              sx={{ color: 'primary.main', '&:hover': { textDecoration: 'underline' } }}
              onClick={() => {
                setManualEntry(false);
                dispatch({ type: 'SET_AIRCRAFT', payload: selectedAircraftType ?? null });
              }}
              disabled={disabled}
              disableRipple
            >
              {t('aircraft.selectFromList')}
            </ButtonBase>
          </Box>
        )}
      </Stack>
    );
  }

  return null;
};
