import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const numberInputSx = {
  flex: 1,
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' }
};

export type NumberInputProps = Omit<TextFieldProps, 'type'>

export const NumberInput = ({
  ...rest
}: NumberInputProps): JSX.Element => (
  <TextField {...rest} type="number" sx={numberInputSx} />
);
