import { MapBaseLayerIds } from 'constants/map';
import { IntermediaryMapTemplate } from './types';
import { getAttribution, getName } from '../helpers';

const OpenSeaMapsTemplate = (id: string, lang: string): IntermediaryMapTemplate => {
  const tilePath = `${import.meta.env.VITE_SERENITY_TILE_PROXY}/openseamap/base/tile/{z}/{x}/{y}.png`;
  const seaMark = `${import.meta.env.VITE_SERENITY_TILE_PROXY}/openseamap/seamark/{z}/{x}/{y}.png`;
  const depths = `${import.meta.env.VITE_SERENITY_TILE_PROXY}/openseamap/depth/geoserver/gwc/service/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=gebco2021%3Agebco_2021&WIDTH=256&HEIGHT=256&SRS=EPSG%3A3857&STYLES=&BBOX={bbox-epsg-3857}`;

  return {
    version: 8,
    feature: 'map.marine',
    replacementId: MapBaseLayerIds.MapboxStreets,
    name: getName(id, lang),
    groupId: 'nauticalCharts',
    providerId: 'openSeaMap',
    sources: {
      'openseamaps-tile': {
        type: 'raster',
        tiles: [
          tilePath,
        ],
        minzoom: 0,
        maxzoom: 19,
        tileSize: 256,
        attribution: getAttribution('openSeaMap', lang),
      },
      'openseamaps-seamark': {
        type: 'raster',
        tiles: [
          seaMark,
        ],
        minzoom: 0,
        maxzoom: 19,
        tileSize: 256
      },
      'openseamaps-depths': {
        type: 'raster',
        tiles: [
          depths,
        ],
        minzoom: 0,
        maxzoom: 19,
        tileSize: 256
      }
    },
    layers: [
      {
        id: 'openseamaps-tile',
        type: 'raster',
        source: 'openseamaps-tile'
      },
      {
        id: 'openseamaps-seamark',
        type: 'raster',
        source: 'openseamaps-seamark'
      },
      {
        id: 'openseamaps-depths',
        type: 'raster',
        source: 'openseamaps-depths'
      }
    ],
    thumbnailUrl: '/img/base-layer-thumbnails/open_sea_map.jpg',
    dark: true
  };
};

export default OpenSeaMapsTemplate;
