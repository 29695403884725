import Smart from 'hocs/smart';
import ClockView from './clock-view';

export default Smart(
  ClockView,
  state => ({
    clocks: state.app.clocks
  }),
  ac => ({
  })
);
