import React from 'react';

interface DiagonalStripsLeftProps {
  color: string;
}

export const DiagonalStripsLeft = ({ color }: DiagonalStripsLeftProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
    <defs>
      <clipPath id="clip-path">
        <path id="SVGID" className="cls-1" d="M0 0h100v100H0z" />
      </clipPath>
      <pattern id="_19-2_diagonal" width="100" height="100" patternUnits="userSpaceOnUse">
        <path fill="none" d="M0 0h100v100H0z" />
        <g opacity=".8" clipPath="url(#clip-path)">
          <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="3px"
            d="M153.03 53.03 46.97-53.03M147.48 58.59 41.41-47.48M141.92 64.14 35.86-41.92M136.37 69.7 30.3-36.37M130.81 75.26 24.74-30.81M125.26 80.81 19.19-25.26M119.7 86.37 13.63-19.7M114.14 91.92 8.08-14.14M108.59 97.48 2.52-8.59M103.03 103.03-3.03-3.03M97.48 108.59-8.59 2.52M91.92 114.14-14.14 8.08M86.37 119.7-19.7 13.63M80.81 125.26-25.26 19.19M75.26 130.81-30.81 24.74M69.7 136.37-36.37 30.3M64.14 141.92-41.92 35.86M58.59 147.48-47.48 41.41M53.03 153.03-53.03 46.97"
          />
        </g>
      </pattern>
    </defs>
    <g id="Layer_2">
      <path fill="url(#_19-2_diagonal)" d="M0 0h100v100H0z" id="Layer_1-2" />
    </g>
  </svg>
);
