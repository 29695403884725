import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTab-root': {
    fontSize: '1.3rem',
    fontWeight: '500',
    textTransform: 'none',
    fontFamily: theme.typography.fontFamily,
    minHeight: '55px',
    flexDirection: 'row',
    minWidth: 160
  },
  '.MuiTab-iconWrapper': {
    marginBottom: theme.spacing(1),
    alignSelf: 'baseline'
  },
  '.MuiTab-wrapper': {
    display: 'inline'
  }
}));
