import { useListEventNotificationGroups } from 'apis/rest/eventNotifications/hooks';
import { EventNotificationGroup } from 'apis/rest/eventNotifications/types';
import validateName, { ValidateNameResult } from 'helpers/validation/validateName';
import { useMemo } from 'react';

export interface UseValidateNameResult extends ValidateNameResult {
  isLoading: boolean
}

const useValidateName = (group: EventNotificationGroup | null, name: string | undefined): UseValidateNameResult => {
  const { query } = useListEventNotificationGroups();

  const existingNames = useMemo(
    () => query.data?.filter(g => (group ? g.id !== group.id : true)).map(g => g.name) ?? [],
    [query.data, group]
  );
  return {
    ...validateName(name, group?.name ?? '', existingNames),
    isLoading: query.isLoading
  };
};

export default useValidateName;
