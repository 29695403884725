import React, { useState, useEffect } from 'react';
import {
  Grid, Box, Typography, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import * as emailHelper from 'helpers/email';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import LangSelector from 'components/shared/languageSelector';
import useTranslation from 'hooks/useTranslation';
import { sendPasswordResetEmail } from 'apis/auth';

import LoginInput from '../loginInput';
import SubmitButton from '../submitButton';

import useStyles from './resetPassword-styles';

const ResetPasswordPage = () => {
  const classes = useStyles();

  const t = useTranslation('pages.resetPassword');
  const [formValid, setFormValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fields, setField] = useState({ email: '' });

  useEffect(() => {
    setFormValid(!emailHelper.validate(fields.email));
  }, [fields]);

  const update = (id: string, value: string) => setField({
    ...fields,
    [id]: value
  });

  const handleSubmit = async () => {
    if (formValid) {
      await sendPasswordResetEmail(fields.email)
        .then(() => {
          setErrorMessage('');
          setSuccessMessage('resetPasswordLinkSent');
        })
        .catch(() => {
          setErrorMessage('errors.inv_email');
          setSuccessMessage('');
        });
    } else {
      setSuccessMessage('');
    }
  };

  return (
    <Box className={classes.container}>
      <Grid className={classes.gridWrapper} container spacing={0}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.formWrapper}>
            <Box className={classes.resetPasswordForm}>

              <Box className={classes.iconWrapper}>
                <TracplusIcon />
              </Box>
              <Typography variant="h1" className={classes.title}>{t('title')}</Typography>
              <Typography variant="body1" className={classes.subtitle}>{t('paragraph')}</Typography>

              <LoginInput
                id="email"
                type="email"
                placeholderText={t('emailPlaceholder')}
                value={fields.email}
                validate={() => emailHelper.validateSingle(fields.email)}
                onChange={update}
                onEnterKeypress={handleSubmit}
                t={t}
              />
              <SubmitButton
                disabled={!formValid}
                text={t('submitButton')}
                onSubmit={handleSubmit}
              />

              <Link to="/login">
                <Button variant="contained" className={classes.cancelButton}>{t('cancelButton')}</Button>
              </Link>

              {successMessage && <Typography className={classes.successMessage}>{t(successMessage)}</Typography>}
              {errorMessage && <Typography className={classes.errorMessage}>{t(errorMessage)}</Typography>}

              <LangSelector />

            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={7} className={classes.signupImageWrapper}>
          <Box className={classes.imageOfTheWeek} style={{ backgroundImage: 'url(/img/mandurah-water-rescue.png)' }}>
            <Box className={classes.imageOfTheWeekText}>Mandurah Water Rescue</Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ResetPasswordPage;
