import { IntermediaryMapTemplate } from './types';
import { getName } from '../helpers';

const MapTemplate = (id: string, lang: string): IntermediaryMapTemplate => ({
  mapboxStyle: 'mapbox://styles/mapbox/satellite-streets-v12',
  thumbnailUrl: '/img/base-layer-thumbnails/satellite-streets-v12.jpg', // TODO: create a specific thumbnail image
  name: getName(id, lang),
  providerId: 'mapbox',
  dark: true,
  maxzoom: 19,
});

export default MapTemplate;
