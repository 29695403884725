import React from 'react';
import AssetResult from '../asset';

interface QueryResultProps {
  result: AssetBasic,
  selectAsset: (asset: AssetBasic) => void,
  isHidden: boolean,
  isSelected: boolean
}

const QueryResult = React.memo(({
  result,
  selectAsset,
  isHidden,
  isSelected,
}: QueryResultProps): JSX.Element =>
// eslint-disable-next-line no-underscore-dangle
  <AssetResult asset={result} selectAsset={selectAsset} isHidden={isHidden} isSelected={isSelected} />);

export default QueryResult;
