import { FeatureCollection, Polygon, MultiPolygon, Position, Geometry, GeometryCollection, Feature } from 'geojson';
import unkinkPolygon from '@turf/unkink-polygon';

export function* createId(): Generator<number> {
  let n = 0;
  while (true) {
    yield ++n;
  }
}

const idGenerator = createId();
export const getId = (): number => idGenerator.next().value;

export const isPolygonClockWise = (coordinates: Position[]) => {
  let signedArea = 0;
  for (let j = 0; j < coordinates.length; j++) {
    const current = coordinates[j];

    const next = j === coordinates.length - 1 ? coordinates[0] : coordinates[j + 1];

    signedArea += (current[0] * next[1] - next[0] * current[1]);
  }
  return signedArea <= 0;
};

export const enforceClockwise = (coordinates: Position[]): Position[] => {
  if (isPolygonClockWise(coordinates)) {
    return coordinates;
  }
  return coordinates.reverse();
};

export const enforceClockwiseGeometry = <T extends Polygon | MultiPolygon, R = T extends Polygon ? Polygon : MultiPolygon>(geometry: T) : R => {
  if (geometry.type === 'Polygon') return { ...geometry, coordinates: geometry.coordinates.map(loop => enforceClockwise(loop)) } as R;
  return { ...geometry, coordinates: geometry.coordinates.map(polygon => polygon.map(loop => enforceClockwise(loop))) } as R;
};

export const getPolygonFromGeometryCollection = (g: Geometry): (Polygon | MultiPolygon)[] => {
  switch (g.type) {
    case 'GeometryCollection':
      return g.geometries
        .filter(x => x.type === 'MultiPolygon' || x.type === 'Polygon')
        .flatMap(x => (x.type === 'MultiPolygon' ? (x as MultiPolygon) : (x as Polygon)));
    case 'Polygon':
    case 'MultiPolygon':
      return [g];
    default:
      return [];
  }
};

export const getValidFeatureCollection = (inputFeatures: FeatureCollection | null): FeatureCollection => {
  try {
    const geometries = inputFeatures?.features.flatMap(feat => getPolygonFromGeometryCollection(feat.geometry));
    const cleanGeometries = geometries?.flatMap(g => unkinkPolygon((g.type === 'MultiPolygon' ? (g as MultiPolygon) : (g as Polygon))).features);
    const clockwiseFeatures = cleanGeometries?.map(feature => ({ ...feature,
      properties: {},
      id: getId(),
      geometry: enforceClockwiseGeometry(feature.geometry)
    }));
    return {
      features: clockwiseFeatures ?? [],
      type: 'FeatureCollection'
    };
  } catch {
    return {
      features: [],
      type: 'FeatureCollection',
    };
  }
};

export const getGeometriesFromMultiPolygon = (input: MultiPolygon) => input.coordinates.map<Polygon>(coordinate => ({ type: 'Polygon', coordinates: coordinate }));

const getGeometryCoordinates = (g: GeometryCollection | Polygon | MultiPolygon): Position[][][] => {
  switch (g.type) {
    case 'GeometryCollection':
      return g.geometries
        .filter(x => x.type === 'MultiPolygon' || x.type === 'Polygon')
        .flatMap(x => (x.type === 'MultiPolygon' ? (x as MultiPolygon).coordinates : [(x as Polygon).coordinates]));
    case 'Polygon':
      return [(g as Polygon).coordinates];
    default:
      return g.coordinates;
  }
};

const featureCollectionToMultiPolygon = (collection: FeatureCollection): MultiPolygon => {
  const geometries = collection.features
    .filter((c): c is Feature<GeometryCollection | Polygon | MultiPolygon> => ['GeometryCollection', 'Polygon', 'MultiPolygon'].includes(c.geometry.type))
    .flatMap(c => getGeometryCoordinates(c.geometry));

  return { type: 'MultiPolygon', coordinates: geometries };
};

export const getValidMultiPolygon = (geometry: MultiPolygon): MultiPolygon => {
  // unkink polygons to get rid of any self intersects
  const collection = unkinkPolygon(geometry);
  const multiPolygon = featureCollectionToMultiPolygon(collection);

  // reverse any counter clockwise polygons
  return {
    type: multiPolygon.type,
    coordinates: multiPolygon.coordinates.map(x => x.map(y => isPolygonClockWise(y) ? y : [...y].reverse()))
  };
};
