import { useTranslations } from 'use-intl';
import React, { Fragment, useCallback } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Check } from '@mui/icons-material';
import { useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import {
  ShareRangeType,
  TemporalGroupShare,
  TemporalShare,
  TemporalSharesResult,
} from 'apis/rest/temporalShares/types';
import useOrganisationId from 'hooks/session/useOrganisationId';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import ShareTime from 'components/pages/sharing/shareTime';
import { sortBy } from 'lodash/fp';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import useTimezone from "hooks/session/useTimezone";

interface AssetSharingProps {
  device: DeviceBasic | undefined
}

const noLastBorder: SxProps<Theme> = { '&:last-child td, &:last-child th': { border: 0 } };

const sortShares = sortBy<TemporalShare>([share => (share.shareRangeType === ShareRangeType.AllTime ? -Infinity : DateTime.fromISO(share.shareStart))]);

const AssetSharing = ({ device }: AssetSharingProps): JSX.Element | null => {
  const organisationId = useOrganisationId();
  const t = useTranslations('pages.assetView.sharingTo');
  const timezone = useTimezone();

  const isOwnDevice = device?.ownerId.toLowerCase() === organisationId.toLowerCase();

  const query = useGetTemporalSharesToList({
    select: useCallback(
      ({ shares, groupShares }: TemporalSharesResult): (TemporalShare | TemporalGroupShare)[] => (
        sortShares([...shares, ...groupShares].filter(share => share.deviceId === device?.id))
      ),
      [device],
    ),
    enabled: device && !isOwnDevice,
  });

  if (!device || isOwnDevice) return null;

  if (query.isLoading) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel p={3}>
          <Typography>{t('loading')}</Typography>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (!query.data?.length) return null;

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel>
        <Box px={3}>
          <Typography variant="h5" mb={1}>{t('owner', { owner: query.data[0].organisationName })}</Typography>
          <Typography>{t('explanation')}</Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('dateRange')}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('viewTrails')}</TableCell>
              <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('canViewForms')}</TableCell>
              <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('canSendTextMessages')}</TableCell>
              <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('canSendConfiguration')}</TableCell>
              <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('canEditCallSign')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {query.data.map(share => {
              const hasExtraRow = share.notes || 'groupName' in share;
              return (
                <Fragment key={share.id}>
                  <TableRow sx={hasExtraRow ? { 'td, th': { border: 0, pb: 1 } } : noLastBorder}>
                    <TableCell>
                      <ShareTime share={share} timezone={timezone} />
                    </TableCell>
                    <TableCell>
                      {share.canViewCurrent && !share.canViewHistory && t('liveTrails')}
                      {share.canViewCurrent && share.canViewHistory && t('allTrails')}
                      {!share.canViewCurrent && share.canViewHistory && t('historicTrails')}
                      {!share.canViewCurrent && !share.canViewHistory && t('noTrails')}
                    </TableCell>
                    <TableCell align="center">{share.canViewForms && <Check />}</TableCell>
                    <TableCell align="center">{share.canSendTextMessages && <Check />}</TableCell>
                    <TableCell align="center">{share.canSendConfiguration && <Check />}</TableCell>
                    <TableCell align="center">{share.canEditCallSign && <Check />}</TableCell>
                  </TableRow>
                  {hasExtraRow && (
                    <TableRow sx={noLastBorder}>
                      {'groupName' in share && (
                        <TableCell colSpan={share.notes ? 1 : 100} sx={{ pt: 0 }}>
                          <Typography variant="body3">{t('sharedViaGroup', { group: share.groupName })}</Typography>
                        </TableCell>
                      )}
                      {share.notes && (
                        <TableCell colSpan={100} sx={{ pt: 0 }}>
                          <Typography variant="body3">{t('notes')}: {share.notes}</Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </DetailPanel>
    </SettingsSection>
  );
};

export default AssetSharing;
