import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { TextLayer } from '@deck.gl/layers';
import AssetIcon from 'components/shared/icons/assetIcons';
import { AdsbAircraft } from 'apis/adsb/types';
import { useAdsbAircraftDataForMap } from 'apis/adsb/hooks';
import { visualKey } from 'apis/trackstar/maps';
import OutlinedIconLayer from './outlinedIconLayer';

interface Icon {
  properties: {
    name: string;
    size: number;
    color: number[];
    angle: number;
    icon: {
      url: string;
      height: number;
      width: number;
      id: string;
      anchorX: number;
      anchorY: number;
      mask: boolean;
    };
  };
  latitude: number;
  longitude: number;
  altitude: number;
}

// takes line features and renders them 3 times so they show on maps zoomed out to show more than a world width
// Would like to conditionally render these, but cant find bounds correctly see https://github.com/uber/deck.gl/issues/4060
const triplicate = (icons: Icon[]) => icons.flatMap(icon => [
  {
    ...icon,
    longitude: icon.longitude - 360,
  },
  icon,
  {
    ...icon,
    longitude: icon.longitude + 360,
  },
]);

const ICONSIZE = 128;

const useIconLayer = (mapId: string, nearestAdsbHex: string | undefined, visible: boolean, use3d: boolean) => {
  const query = useAdsbAircraftDataForMap(mapId);

  const data = useMemo(() => {
    if (!query) {
      return [];
    }

    const aircraft = query[0] as AdsbAircraft[];

    return triplicate(aircraft
      .map(ac => {
        const assetColor = [0, 0, 0, 128];

        // I cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
        // It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
        const iconUrl = `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(
          <AssetIcon type={visualKey(ac.category, ac.t).id} />,
        ))}`;

        const hovered = ac.hex === nearestAdsbHex;

        return {
          properties: {
            name: ac.r || ac.flight || ac.hex,
            size: 5,
            color: hovered ? [0, 0, 0, 255] : assetColor,
            angle: ac.track ?? 0,
            icon: {
              url: iconUrl,
              height: ICONSIZE,
              width: ICONSIZE,
              id: `${ac.category}-${ac.t}`,
              anchorX: ICONSIZE / 2,
              anchorY: ICONSIZE / 2,
              mask: true,
            },
          },
          latitude: ac.lat,
          longitude: ac.lon,
          altitude: use3d ? (ac.alt_baro === 'ground' ? 0 : ac.alt_baro * 0.3048) : 0,
        } satisfies Icon;
      }));
  }, [query, nearestAdsbHex, use3d]);

  return useMemo(() => new OutlinedIconLayer({
    id: 'adsb-icon-layer',
    data,
    billboard: false,
    pickable: false,
    autoHighlight: true,
    getIcon: (d: Icon) => d.properties.icon,
    sizeScale: 1,
    getPosition: d => [d.longitude, d.latitude, d.altitude],
    getSize: 30,
    getAngle: d => -d.properties.angle,
    getColor: d => d.properties.color as [number, number, number, number],
    outlineColor: [255, 255, 255],
    parameters: { depthTest: false },
    visible,
  }), [data, visible]);
};

const useLabelLayer = (nearestAdsb: AdsbAircraft | undefined, visible: boolean, use3d: boolean) => {
  const data = useMemo(() => {
    if (!nearestAdsb) return [];
    return [{
      name: nearestAdsb.r || nearestAdsb.flight || nearestAdsb.hex,
      latitude: nearestAdsb?.lat,
      longitude: nearestAdsb?.lon,
      altitude: use3d ? (nearestAdsb.alt_baro === 'ground' ? 0 : nearestAdsb.alt_baro * 0.3048) : 0,
    }];
  }, [nearestAdsb, use3d]);

  return useMemo(() => new TextLayer({
    id: 'adsb-label-layer',
    data,
    getText: d => d.name,
    pickable: false,
    fontWeight: 550,
    getPosition: d => [d.longitude, d.latitude, d.altitude],
    getSize: 12,
    // getColor: [36, 39, 48, 255],
    getColor: [255, 255, 255, 255],
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    getPixelOffset: [0, 24],
    visible,
    parameters: { depthTest: false },
    fontSettings: {
      sdf: true,
      fontSize: 30,
      radius: 12,
    },
    fontFamily: 'objektiv-mk2,sans-serif',
    background: true,
    getBackgroundColor: [0, 0, 0, 128],
    backgroundPadding: [3, 1, 3, 3],
    // outlineColor: [255, 255, 255, 235],
    // outlineWidth: 0.3,
  }), [data, visible]);
};

const useAdsbIconsLayer = (
  mapId: string,
  nearestAdsb: AdsbAircraft | undefined,
  visible: boolean,
  use3d = false
) => {
  const iconLayer = useIconLayer(mapId, nearestAdsb?.hex, visible, use3d);
  const labelLayer = useLabelLayer(nearestAdsb, visible, use3d);
  return [iconLayer, labelLayer];
};

export default useAdsbIconsLayer;
