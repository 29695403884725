import React from 'react';
import { Box, Fade, Paper, Popper, PopperProps, Stack, Typography } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { formatDateTime } from 'utils/time';
import useDuration from 'hooks/units/useDuration';

interface TripPopperProps {
  trip: Trip | undefined
  anchorEl: PopperProps['anchorEl']
  timezone: string
}

const TripPopper = ({ trip, anchorEl, timezone }: TripPopperProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.popper');
  const duration = useDuration();
  return (
    <Popper
      open={!!trip && !!anchorEl}
      anchorEl={anchorEl}
      transition
    >
      {({ TransitionProps }) => (trip ? (
        <Fade {...TransitionProps} timeout={300}>
          <Paper elevation={8} sx={{ m: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="max-content 1fr"
              gridTemplateRows="max-content 1fr 1fr"
              gridTemplateAreas="'header header' 'line from' 'line duration' 'line to'"
              rowGap={1}
              columnGap={1}
              p={2}
            >
              <Typography variant="h4" gridArea="header">{t('trip')}</Typography>
              <Stack gridArea="line" py={1}>
                <PlayArrow />
                <Box alignSelf="center" flex="1" borderLeft="2px dashed #999" />
                <Stop />
              </Stack>
              <Box alignSelf="start" gridArea="from">
                <Typography alignSelf="start" variant="h5">{trip.start}</Typography>
                <Typography alignSelf="start">{formatDateTime(trip.startTime, timezone, true)}</Typography>
              </Box>
              <Typography alignSelf="center" gridArea="duration">{`${t('duration')}: ${duration.fromMillis(trip.duration)}`}</Typography>
              <Box alignSelf="end" gridArea="to">
                <Typography alignSelf="end" variant="h5">{trip.end}</Typography>
                <Typography alignSelf="start">{formatDateTime(trip.endTime, timezone, true)}</Typography>
              </Box>
            </Box>
          </Paper>
        </Fade>
      ) : null)}
    </Popper>
  );
};

export default TripPopper;
