import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(3),
    alignItems: 'center',
    borderBottom: theme.border.default,
    borderTop: theme.border.default,
    opacity: 1,
    transition: 'all 0.25s',
    '&.closed': {
      opacity: 0,
      maxHeight: '0px',
      height: '0px',
      marginBottom: 0,
    },
    '&.omniClosed': {
      opacity: 0,
      maxHeight: '0px',
      height: '0px'
    }
  },
  label: {
    textAlign: 'left',
    fontSize: '.9rem'
  },
  selectWrapper: {
    textAlign: 'right'
  },
  select: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '.9rem',
      color: theme.palette.common.text,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none'
    },
    '& div:hover, & div:focus': {
      backgroundColor: 'transparent!important',
      border: 'none!important',
      boxShadow: 'none!important',
      outline: 'none!important'
    }
  },
  icon: {
    top: 'calc(50% - 10px)',
    right: '6px',
    position: 'absolute',
    pointerEvents: 'none',
    color: theme.palette.common.text
  }
}));
