import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash/fp';
import { useTranslations } from 'use-intl';
import Altitude from 'components/shared/altitude/altitude-view';
import Course from 'components/shared/course/course-view';
import { useGetTrip } from 'apis/rest/trips/hooks';
import Distance from 'components/shared/distance/distance-view';
import Quantity from 'components/shared/quantity';
import Speed from 'components/shared/speed';
import useDuration from 'hooks/units/useDuration';
import useFeature from 'hooks/features/useFeature';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { HelpOutline } from '@mui/icons-material';
import { InferredEventId } from 'apis/rest/inferredEvents/types';
import { SpeedUnit } from 'helpers/unitsOfMeasure';
import { gatewayToTransport } from 'helpers/transport';
import { labelToDisplayLabel } from 'helpers/events';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { TripCharts } from './tripCharts';
import { TripMap } from './tripMap';
import { Action } from '../types';
import TripHeader from './tripHeader';
import { TripSupplementaryData } from './tripSupplementaryData';
import { TripUpdateDialog } from './tripUpdateDialog';

type TabId = 'timeline' | 'table' | 'data' | 'drops';
const PossibleTabIds: TabId[] = ['timeline', 'table', 'data'];

interface ReportRowProps {
  report: TripSlimReport;
  inferredEvents: InferredEventId[];
  timezone: string;
  isSelected: boolean;
  setSelectedReportId: (id: number | undefined) => void;
  speedUnit: SpeedUnit;
}

const ReportRow = memo(({ report, inferredEvents, timezone, isSelected, setSelectedReportId, speedUnit }: ReportRowProps) => {
  const enableInferredEvents = useFeatureFlag('frontendInferredEventsTripAnalysis');
  const t = useTranslations('shared.inferredEvents');
  const translatedInferredEvents = useMemo(() => inferredEvents.map(e => t(e)).join(', '), [inferredEvents, t]);
  return (
    <TableRow
      onMouseEnter={() => setSelectedReportId(report.id)}
      onMouseLeave={() => setSelectedReportId(undefined)}
      sx={theme => ({ bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.05) : undefined })}
    >
      <TableCell>{DateTime.fromMillis(report.timeOfFix).setZone(timezone).toFormat('HH:mm:ss ZZZ')}</TableCell>
      {/* TODO: event translations */}
      <TableCell>{labelToDisplayLabel(report.events[0])}</TableCell>
      {enableInferredEvents && <TableCell>{translatedInferredEvents}</TableCell>}
      <TableCell>{report.coords[1].toFixed(5)},{report.coords[0].toFixed(5)}</TableCell>
      <TableCell><Altitude altitudeInMetres={report.altitude} precision={0} /></TableCell>
      <TableCell><Altitude altitudeInMetres={report.elevation} precision={0} /></TableCell>
      <TableCell><Course courseInDegreesTrue={report.track} position={report.coords} timestamp={report.timeOfFix} /></TableCell>
      <TableCell><Speed unit={speedUnit} speedInKmh={report.speed} precision={0} /></TableCell>
      <TableCell><Quantity value={report.latency} precision={1} units="s" /></TableCell>
      <TableCell><Distance distanceInMetres={report.distance * 1000} /></TableCell>
      <TableCell>{gatewayToTransport(report.gateway)}</TableCell>
    </TableRow>
  );
});

interface TripDetailProps {
  trip: Trip
  inferredEvents?: Record<number, InferredEventId[]>
  dispatch: React.Dispatch<Action>
  timezone: string
  hideHeader?: boolean
  hideMap?: boolean
}

interface LoadTripProps {
  assetId: number,
  tripId: string,
  loaded: (trip : Trip) => React.ReactNode
}

export const LoadTrip: React.FC<LoadTripProps> = ({ assetId, tripId, loaded }) => {
  const tripQuery = useGetTrip(assetId, tripId);
  const t = useTranslations('pages.reporting');
  if (tripQuery.isLoading) {
    return (
      <Box sx={theme => ({ margin: theme.spacing(1, 0, 4), padding: theme.spacing(3) })}>
        <Typography align="center">{t('loadingTripReports')}</Typography>
      </Box>
    );
  }
  if (!tripQuery.data) {
    return (
      <Box sx={theme => ({ margin: theme.spacing(1, 0, 4), padding: theme.spacing(3) })}>
        <Typography align="center">{t('trips.failedToLoadData')}</Typography>
      </Box>
    );
  }

  return loaded(tripQuery.data);
};

export const TripDetail = ({ trip, inferredEvents, dispatch, timezone, hideHeader = false, hideMap = false }: TripDetailProps) => {
  const supplementaryDataFeatureAssets = useFeatureAssets('reporting.supplementaryData');
  const supplementaryDataEnabled = supplementaryDataFeatureAssets.hasAssetId(trip.assetId);
  const t = useTranslations('pages.reporting');
  const duration = useDuration();
  const asset = useGetAssetsList({ select: data => data.find(a => a.id === trip.assetId) }).query.data;
  const [selectedReportId, setSelectedReportId] = useState<number>();

  const speedUnit = useSpeedByAsset(asset);

  const [tabId, setTabId] = useState<TabId>(() => {
    const stored = sessionStorage.getItem('tracplus.tripDetailTab');
    if (PossibleTabIds.includes(stored as TabId)) {
      return stored as TabId;
    }
    return 'timeline';
  });

  const onClose = useCallback(() => dispatch({ type: 'SET_SELECTED_TRIP' }), [dispatch]);
  const onPreviousTrip = useCallback(() => dispatch({ type: 'SELECT_PREVIOUS_TRIP' }), [dispatch]);
  const onNextTrip = useCallback(() => dispatch({ type: 'SELECT_NEXT_TRIP' }), [dispatch]);
  const onChangeRequest = useCallback((cr: Action['changeRequest']) => dispatch({ type: 'SET_CHANGE_REQUEST', changeRequest: cr }), [dispatch]);

  const aerialFirefightingReport = useFeature('reporting.aerialFirefightingReport');
  const enableInferredEvents = useFeatureFlag('frontendInferredEventsTripAnalysis');

  useEffect(() => {
    const selectedReport = trip.reports.find(r => r.id === selectedReportId);
    if (hideMap) { dispatch({ type: 'SET_SELECTED_REPORT', report: selectedReport }); }
  }, [trip, dispatch, selectedReportId, hideMap]);

  useEffect(() => {
    sessionStorage.setItem('tracplus.tripDetailTab', tabId);
  }, [tabId]);

  useEffect(() => {
    if (!supplementaryDataEnabled) {
      if (tabId === 'data') {
        setTabId('timeline');
      }
    }
  }, [supplementaryDataEnabled, tabId]);

  const gridColumns = useMemo(
    () => (hideMap
      ? '1fr'
      : { xs: '1fr', xl: '2fr 1fr' }),
    [hideMap]
  );
  const gridRows = useMemo(
    () => (hideMap
      ? { xs: `max-content ${tabId === 'timeline' ? 'max-content' : '500px'}` }
      : { xs: '500px max-content 500px', xl: '1fr 640px' }),
    [hideMap, tabId]
  );
  const gridAreas = useMemo(
    () => (hideMap
      ? { xs: '"tabs fix" "content content"', xl: '"tabs fix" "content content"' }
      : { xs: '"map map" "tabs fix" "content content"', xl: '"tabs fix" "content map"' }),
    [hideMap]
  );

  const tabGridArea = useMemo(
    () => (hideMap
      ? 'tabs-start / content-start / fix-end / content-end'
      : { xs: 'tabs-start / content-start / fix-end / content-end', xl: 'tabs' }
    ),
    [hideMap]
  );

  if (!asset) {
    return (<Typography>No Asset found</Typography>);
  }

  return (
    <Box p={3}>
      {!hideHeader && (
        <TripHeader
          trip={trip}
          onClose={onClose}
          onPreviousTrip={onPreviousTrip}
          onNextTrip={onNextTrip}
          timezone={timezone}
        />
      )}
      <Box
        display="grid"
        gridTemplateColumns={gridColumns}
        gridTemplateRows={gridRows}
        gridTemplateAreas={gridAreas}
        columnGap={3}
      >
        <Box gridArea={tabGridArea}>
          <Tabs
            value={tabId}
            onChange={(_, value) => setTabId(value as TabId)}
            sx={{ borderBottom: '1px solid', borderColor: 'common.midGrey', '& .MuiTab-root': { fontSize: '1rem' } }}
          >
            <Tab value="timeline" label={t('timeline')} />
            <Tab value="table" label={`${trip.reports.length} ${t('reports')}`} />
            {aerialFirefightingReport && <Tab value="drops" label={t('tripDetail.tabs.drops', { n: trip.drops.length })} />}
            {supplementaryDataEnabled
              ? (
                <Tab value="data" label={(
                  <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                    <span>{t('supplementary')}</span>
                  </Stack>
                  )} />
              )
              : (
                // span as the first child stops the tooltip complaining about a disabled button
                <Tooltip title={t('supplementaryComingSoon')}>
                  <span>
                    <Tab value="data" label={(
                      <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                        <span>{t('supplementary')}</span>
                        <HelpOutline />
                      </Stack>
                    )} disabled sx={{ '&.Mui-disabled': { pointerEvents: 'visible' } }} />
                  </span>
                </Tooltip>
              )}
          </Tabs>
        </Box>
        <Box gridArea="fix" display="flex" alignItems="center" justifyContent="end">
          <TripUpdateDialog timezone={timezone} sourceTrip={trip} sourceAsset={asset} onChangeRequest={onChangeRequest} />
        </Box>
        <Box gridArea="content">
          {tabId === 'timeline' && (
            <TripCharts
              trip={trip}
              asset={asset}
              inferredEvents={inferredEvents}
              selectedReportId={selectedReportId}
              setSelectedReportId={setSelectedReportId}
              timezone={timezone}
            />
          )}
          {tabId === 'table' && (
            <Box height="100%" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              <Table>
                <TableHead sx={{ position: 'sticky', top: 0, '& .MuiTableCell-root': { bgcolor: 'common.white', border: 'none' } }}>
                  <TableRow>
                    <TableCell>{t('time')}</TableCell>
                    <TableCell>{t('event')}</TableCell>
                    {enableInferredEvents && <TableCell>{t('inferredEvents')}</TableCell>}
                    <TableCell>{t('coordinates')}</TableCell>
                    <TableCell>{t('altitude')}</TableCell>
                    <TableCell>{t('elevation')}</TableCell>
                    <TableCell>{t('track')}</TableCell>
                    <TableCell>{t('speed')}</TableCell>
                    <TableCell>{t('latency')}</TableCell>
                    <TableCell>{t('distance')}</TableCell>
                    <TableCell>{t('transport')}</TableCell>
                  </TableRow>
                  <tr>
                    <Box component="th" colSpan={100} sx={{ bgcolor: 'common.midGrey', height: '1px', border: 'none !important' }} />
                  </tr>
                </TableHead>
                <TableBody sx={{ '& .MuiTableCell-root': { bgcolor: 'common.white' } }}>
                  {sortBy('timeOfFix', trip.reports).map(report => (
                    <ReportRow
                      key={report.id}
                      report={report}
                      inferredEvents={inferredEvents?.[report.id] ?? []}
                      timezone={timezone}
                      isSelected={report.id === selectedReportId}
                      setSelectedReportId={setSelectedReportId}
                      speedUnit={speedUnit}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          {tabId === 'drops' && (
            <Box height="100%" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              <Table>
                <TableHead sx={{ position: 'sticky', top: 0, '& .MuiTableCell-root': { bgcolor: 'common.white', border: 'none' } }}>
                  <TableRow>
                    <TableCell>{t('tripDetail.drops.columns.startTime')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.endTime')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.duration')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.type')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.volume')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.endVolume')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.suppressant')}</TableCell>
                    <TableCell>{t('tripDetail.drops.columns.split')}</TableCell>
                  </TableRow>
                  <tr>
                    <Box component="th" colSpan={100} sx={{ bgcolor: 'common.midGrey', height: '1px', border: 'none !important' }} />
                  </tr>
                </TableHead>
                <TableBody sx={{ '& .MuiTableCell-root': { bgcolor: 'common.white' } }}>
                  {sortBy('startTime', trip.drops).map(drop => (
                    <TableRow key={drop.id}>
                      <TableCell>{DateTime.fromMillis(drop.startTime).setZone(timezone).toFormat('HH:mm:ss ZZZ')}</TableCell>
                      <TableCell>{DateTime.fromMillis(drop.endTime).setZone(timezone).toFormat('HH:mm:ss ZZZ')}</TableCell>
                      <TableCell>{duration.fromMillis(drop.duration, 2, "m'm' s's'")}</TableCell>
                      <TableCell>{drop.type}</TableCell>
                      <TableCell>{drop.dropVolume === undefined ? '-' : `${drop.dropVolume.toFixed(0)} L`}</TableCell>
                      <TableCell>{drop.endVolume === undefined ? '-' : `${drop.endVolume.toFixed(0)} L`}</TableCell>
                      <TableCell>{drop.suppressant}</TableCell>
                      <TableCell>{t('tripDetail.drops.values.splitDrop', { splitDrop: drop.splitDrop })}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
          {tabId === 'data' && (
            <TripSupplementaryData
              trip={trip}
            />
          )}
        </Box>
        {trip.end && !hideMap && (
          <Box gridArea="map">
            <TripMap
              trip={trip}
              asset={asset}
              selectedReportId={selectedReportId}
              setSelectedReportId={setSelectedReportId}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
