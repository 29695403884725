import React from 'react';

const B429 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 18.42494,2.8559971 c -0.04471,-0.078336 -0.741016,0.2365823 -1.563913,0.7061492 -0.822897,0.4695669 -1.45077,0.912347 -1.406068,0.9906857 l 10.804083,18.928951 c 0.06294,1.849218 0.130947,3.326218 0.20146,4.749892 L 5.8492541,39.993213 c -0.078335,0.0447 0.2365825,0.743092 0.7061491,1.565989 0.4695669,0.822896 0.9102699,1.448693 0.9886089,1.403991 L 26.67027,32.051111 c 0.09095,1.40358 0.164076,2.384292 0.164076,2.384292 0,0 0.200918,3.251963 1.038455,3.67613 0.352773,0.178661 -0.394613,4.056204 -0.394613,4.056204 l 1.603373,0.06853 0.05193,8.191331 -5.593116,0.027 -0.07892,5.532886 h 2.305369 l 0.05193,-1.345837 3.466362,0.07685 0.348921,6.490342 1.121531,0.01246 1.119454,-0.01246 0.34892,-6.490342 3.466362,-0.07685 0.05192,1.345837 h 2.305369 l -0.07892,-5.532886 -5.593116,-0.027 0.05192,-8.191331 1.603374,-0.06853 c 0,0 -0.747387,-3.877543 -0.394612,-4.056204 0.128724,-0.0652 0.242521,-0.203368 0.342689,-0.380075 l 8.72925,15.296438 c 0.04471,0.07834 0.743093,-0.236582 1.56599,-0.70615 0.822897,-0.469567 1.450769,-0.91027 1.406067,-0.988608 L 34.822139,32.306571 c 0.0783,-1.185752 0.170449,-2.846432 0.269998,-4.994966 L 56.035691,15.361068 c 0.07834,-0.0447 -0.236582,-0.743093 -0.70615,-1.56599 -0.469566,-0.822897 -0.910269,-1.448693 -0.988608,-1.403991 L 35.25829,23.280323 C 35.29441,22.199264 35.32744,21.063811 35.35591,19.8555 35.59325,9.7807325 30.772169,9.8219511 30.772169,9.8219511 c 0,0 -3.746275,-0.028499 -4.496509,6.7935699 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default B429;
