import React from 'react';

const Text = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <rect className="icon-detail" fill="#fff" x="4.56" y="4.99" width="6.88" height="0.86" />
    <rect className="icon-detail" fill="#fff" x="4.56" y="6.71" width="4.3" height="0.86" />
    <rect className="icon-detail" fill="#fff" x="4.56" y="8.43" width="6.88" height="0.86" />
    <rect className="icon-detail" fill="#fff" x="4.56" y="10.15" width="4.3" height="0.86" />
  </svg>
);

export default Text;
