import React from 'react';

const GA8 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 31.350239,52.273424 -0.93222,1.312016 c 0,0 -9.475753,0.343798 -9.356736,-0.10358 0.233374,-0.877233 -0.766112,-6.297043 0.517901,-6.352913 2.437557,-0.10606 8.389987,-0.13811 8.389987,-0.13811 0,0 -1.352045,-6.828535 -1.657281,-10.288957 -0.1244,-1.410306 -0.06905,-4.246784 -0.06905,-4.246784 L 4.4194174,32.558677 1.1739077,30.970448 c 0,0 0.3836341,-7.770406 2.5549757,-7.768506 1.8409353,0.0016 24.4103756,-0.207161 24.4103756,-0.207161 0,0 0.188854,-3.104858 0.276214,-4.695631 0.09788,-1.782254 0.462234,-3.13732 0.794114,-5.075424 0.228968,-1.337142 1.596538,-1.020314 1.691808,-1.346542 0.194659,-0.666568 0.455735,-2.3030391 1.344386,-2.3021596 0.887627,8.784e-4 1.143623,1.6355916 1.338282,2.3021596 0.09527,0.326228 1.46284,0.0094 1.691808,1.346542 0.33188,1.938104 0.696238,3.29317 0.794114,5.075424 0.08736,1.590773 0.276214,4.695631 0.276214,4.695631 0,0 22.56944,0.208777 24.410376,0.207161 2.171341,-0.0019 2.554975,7.768506 2.554975,7.768506 l -3.245509,1.588229 -23.823423,-0.103581 c 0,0 0.05535,2.836478 -0.06905,4.246784 -0.305236,3.460422 -1.657281,10.288957 -1.657281,10.288957 0,0 5.95243,0.03205 8.389987,0.13811 1.284013,0.05587 0.284527,5.47568 0.517901,6.352913 0.119017,0.447378 -9.356736,0.10358 -9.356736,0.10358 l -0.93222,-1.312016 -0.89249,2.52045 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default GA8;
