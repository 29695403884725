import { Action } from 'redux';
import { Reducer } from 'react';
import { produce } from 'immer';
import { AircraftType } from 'apis/aircraftTypes/hooks';

export interface CreationState {
  name: string | null
  category: string | null
  make: string | null
  model: string | null
  variant: string | null
  messagingHandle: string | null
  watchlistGroup: string | null
  tailNumber: string | null
  callSign: string | null
}

export const initialState: CreationState = {
  name: null,
  category: null,
  make: null,
  model: null,
  variant: null,
  messagingHandle: null,
  watchlistGroup: null,
  tailNumber: null,
  callSign: null,
};

interface SetAircraftAction extends Action<'SET_AIRCRAFT'> {
  payload: AircraftType | null
}

interface SetAction extends Action<'SET_CATEGORY' | 'SET_NAME' | 'SET_MAKE' | 'SET_MODEL' | 'SET_VARIANT' | 'SET_TAIL_NUMBER' | 'SET_WATCHLIST_GROUP' | 'SET_MESSAGING_HANDLE' | 'SET_CALL_SIGN'> {
  payload: string | null
}

export const reducer: Reducer<CreationState, SetAction | SetAircraftAction | Action<'RESET'>> = (state, action) => {
  switch (action.type) {
    case 'RESET': return initialState;
    case 'SET_CATEGORY': return produce(state, draft => {
      draft.category = action.payload || null;
      draft.make = null;
      draft.model = null;
    });
    case 'SET_NAME': return produce(state, draft => {
      draft.name = action.payload ?? '';
    });
    case 'SET_AIRCRAFT': return produce(state, draft => {
      if (action.payload) {
        draft.make = action.payload.manufacturer;
        draft.model = action.payload.model.toString();
      } else {
        draft.make = null;
        draft.model = null;
      }
    });
    case 'SET_MAKE': return produce(state, draft => {
      draft.make = action.payload || null;
    });
    case 'SET_MODEL': return produce(state, draft => {
      draft.model = action.payload || null;
    });
    case 'SET_VARIANT': return produce(state, draft => {
      draft.variant = action.payload || null;
    });
    case 'SET_TAIL_NUMBER': return produce(state, draft => {
      draft.tailNumber = action.payload || null;
    });
    case 'SET_WATCHLIST_GROUP': return produce(state, draft => {
      draft.watchlistGroup = action.payload || null;
    });
    case 'SET_MESSAGING_HANDLE': return produce(state, draft => {
      draft.messagingHandle = action.payload || null;
    });
    case 'SET_CALL_SIGN': return produce(state, draft => {
      draft.callSign = action.payload || null;
    });
    default: return state;
  }
};
