import { createSlice } from '@reduxjs/toolkit';

const PREFIX = 'ASSET_GROUPS';

export interface AssetGroupsState {

}

export const initialState: AssetGroupsState = {

};

export const assetGroupsSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {

  },
  extraReducers: builder => {

  },
});

export const {

} = assetGroupsSlice.actions;

export const assetGroupsSelectors = {

};

export default assetGroupsSlice.reducer;
