import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    height: 5,
    marginTop: 10,
    borderRadius: 10,
    backgroundColor: 'transparent',
  },
  veryWeakColor: {
    '& > div': {
      backgroundColor: theme.palette.primary.distressRed,
      borderRadius: 10
    }
  },
  weakColor: {
    '& > div': {
      backgroundColor: theme.palette.primary.orange,
      borderRadius: 10
    }
  },
  moderateColor: {
    '& > div': {
      backgroundColor: theme.palette.primary.yellow,
      borderRadius: 10
    }
  },
  strongColor: {
    '& > div': {
      backgroundColor: theme.palette.primary.activeGreen,
      borderRadius: 10
    }
  },
  veryStrongColor: {
    '& > div': {
      backgroundColor: theme.palette.primary.blue,
      borderRadius: 10
    }
  },
  passwordStrengthMessage: {
    marginTop: theme.spacing(1),
    textAlign: 'left',
  }
}));
