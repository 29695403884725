import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import Dms from 'geodesy/dms';

interface LatitudeInputProps {
  value: number | undefined
  onChangeValue: (value: number | undefined) => void
  displayFormat: 'n' | 'dms' | 'dm' | 'd'
}

const degreesToFormated = (degrees: number, displayFormat: 'n' | 'dms' | 'dm' | 'd'): string | undefined => {
  if (displayFormat === 'n') {
    const value = Dms.parse(degrees);
    return Number.isNaN(value) ? undefined : (Dms.wrap90(value) as unknown as number).toFixed(7);
  }
  return Dms.toLat(degrees, displayFormat, 4) || undefined;
};

const LatitudeInput: React.FC<LatitudeInputProps & TextFieldProps> = ({ value, onChangeValue, displayFormat, ...otherProps }) => {
  const [pendingValue, setPendingValue] = useState<string>();
  const parsedValue = value === undefined ? undefined : degreesToFormated(value, displayFormat);
  const inputValue = pendingValue ?? parsedValue;

  return (
    <TextField
      {...otherProps}
      type="text"
      value={inputValue ?? ''}
      onChange={event => {
        setPendingValue(event.target.value);
        const degrees = Dms.parse(event.target.value);
        onChangeValue(Number.isNaN(degrees) ? undefined : Dms.wrap90(degrees) as unknown as number);
      }}
      onBlur={() => setPendingValue(undefined)}
    />
  );
};

export default LatitudeInput;
