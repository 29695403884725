import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loggedOut, resetEverything } from "slices/session/session.slice";

const initialState: Record<string, any> = {};

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    fetchIncidentSuccess: (state, action: PayloadAction<{ assetId: string; incident: any }>) => {
      const { assetId, incident } = action.payload;
      state[assetId] = incident;
    },
    fetchIncidentFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggedOut, () => initialState)
      .addCase(resetEverything, () => initialState);
  },
});

export const { fetchIncidentSuccess, fetchIncidentFailure } = incidentsSlice.actions;

export default incidentsSlice.reducer;
