import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  changeDeviceDialog: {
    width: '600px'
  },
  imagePanel: {
    textAlign: 'left',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imagePanelPopulated: {
    aspectRatio: 300 / 160,
  },
  cropDialog: {
    overflow: 'hidden'
  },
  media: {
    width: '100%',
    height: '100%',
  },
  imageIcon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2em',
    '& path': {
      fill: theme.palette.common.text
    }
  },
  uploadImageButton: {
    backgroundColor: 'rgba(36, 39, 48, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(36, 39, 48, 1)',
    },
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2, 6),
    fontSize: '14px',
    '& svg path': {
      fill: theme.palette.common.whiteConstant,
    },
  },
  removeImageButton: {
    backgroundColor: 'rgba(36, 39, 48, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(36, 39, 48, 1)',
    },
    color: theme.palette.common.whiteConstant,
    padding: theme.spacing(2, 6),
    fontSize: '14px',
    '& svg path': {
      fill: theme.palette.common.whiteConstant,
    },
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
    minWidth: '10rem'
  },
  inputButtonWrapper: {
    textAlign: 'right',
  },
  readOnly: {
    '&:hover': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.disabled,
      }
    },
  },
  helperText: {
    flexDirection: 'row',
    '& .MuiFormHelperText-root': {
      zIndex: 100,
      marginLeft: -130,
      alignSelf: 'center',
      fontSize: '0.9rem',
    }
  },
  formControl: {
    width: '100%',
  },
}));
