import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/system';

export default makeStyles<Theme>(theme => ({
  wrapper: {
    padding: theme.spacing(6)
  },
  gridContainer: {
    marginBottom: theme.spacing(4)
  },
  listItem: {
    padding: 0,
    marginBottom: theme.spacing(1),
    alignItems: 'flex-start'
  },
  detailPanelTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black
  },
  detailPanelDescription: {
    paddingTop: 10
  },
  label: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(2),
    fontWeight: 500,
    width: '20%',
    textAlign: 'right'
  }
}));
