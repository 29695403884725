import { parsePhoneNumber } from 'libphonenumber-js/max';
import { useMemo } from 'react';

export enum ContactType {
  SMS = 'sms',
  Phone = 'phone',
  Email = 'email',
}

interface ExtractedContact<T> {
  contact: Contact<T>
  value: string
}

export interface PersonWithExtractedContacts extends Person {
  voice: ExtractedContact<ContactType.Phone>[]
  sms: ExtractedContact<ContactType.SMS>[]
  email: ExtractedContact<ContactType.Email>[]
  defaultVoice?: ExtractedContact<ContactType.Phone>,
  defaultSms?: ExtractedContact<ContactType.SMS>,
  defaultEmail?: ExtractedContact<ContactType.Email>,
}

const sortCompare = (a: { contact: Contact }, b: { contact: Contact }) => (a.contact.isDefault ? -1 : 0);

const usePeopleWithExtractedContacts = (people?: Person[]): PersonWithExtractedContacts[] => {
  const result = useMemo(
    () => people?.map(person => {
      const contactValues = person.contacts.reduce<{
        voice: ExtractedContact<ContactType.Phone>[],
        sms: ExtractedContact<ContactType.SMS>[],
        email: ExtractedContact<ContactType.Email>[],
        defaultVoice?: ExtractedContact<ContactType.Phone>,
        defaultSms?: ExtractedContact<ContactType.SMS>,
        defaultEmail?: ExtractedContact<ContactType.Email>,
      }>((acc, contact) => {
        if (contact.contactType === ContactType.Phone) {
          let phoneNumber = contact.contactValue;
          try {
            phoneNumber = parsePhoneNumber(contact.contactValue)?.formatInternational();
          } catch (error) {
            // do nothing
          }
          const item = { contact, value: phoneNumber } as ExtractedContact<ContactType.Phone>;
          acc.voice.push(item);
          if (contact.isDefault) acc.defaultVoice = item;
        } else if (contact.contactType === ContactType.SMS) {
          let phoneNumber = contact.contactValue;
          try {
            phoneNumber = parsePhoneNumber(contact.contactValue)?.formatInternational();
          } catch (error) {
            // do nothing
          }
          const item = { contact, value: phoneNumber } as ExtractedContact<ContactType.SMS>;
          acc.sms.push(item);
          if (contact.isDefault) acc.defaultSms = item;
        } else if (contact.contactType === ContactType.Email) {
          const item = { contact, value: contact.contactValue } as ExtractedContact<ContactType.Email>;
          acc.email.push(item);
          if (contact.isDefault) acc.defaultEmail = item;
        }
        return acc;
      }, { voice: [], sms: [], email: [] });

      contactValues.voice.sort(sortCompare);
      contactValues.sms.sort(sortCompare);
      contactValues.email.sort(sortCompare);

      return {
        ...person,
        ...contactValues,
      };
    }) || [],
    [people],
  );

  return result;
};

export default usePeopleWithExtractedContacts;
