import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import LimitedList from 'components/shared/LimitedList';
import React from 'react';
import { useTranslations } from 'use-intl';
import { ContactGroupWithPeople } from './types';

interface ContactGroupLabelProps {
  contactGroup: ContactGroupWithPeople
}

export const ContactGroupLabel = ({ contactGroup }: ContactGroupLabelProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.rulesSection.rulesDialog');
  return (
    <Stack direction="row" flex={1} alignItems="center">
      <Stack direction="row" spacing={2} flex={1} alignItems="center">
        <Stack>
          <Typography>{contactGroup?.name}</Typography>
          {(!!(contactGroup?.peopleWithOrder.length) && (
            <Typography variant="body3">
              <LimitedList<string>
                items={contactGroup.people.map(x => x.name)}
                limit={2}
                renderMore={({ items, children }) => (
                  <Tooltip title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}>
                    <Chip label={children} variant="outlined" />
                  </Tooltip>
                )}
              />
            </Typography>
          )) || (<Typography variant="body3">{t('countLabel', { count: contactGroup?.peopleWithOrder?.length ?? 0 })}</Typography>)}
        </Stack>
      </Stack>
    </Stack>
  );
};
