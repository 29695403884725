import React, { forwardRef, useState } from 'react';
import { Typography, ContainerProps } from '@mui/material';
import { uniqueId } from 'lodash';
import { SettingsMenuContainerStyled } from './settingsMenu-styles';

export interface SettingsContainerProps extends Omit<ContainerProps, 'title'> {
  title: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
  labs?: boolean
}

const SettingsMenuContainer = forwardRef<HTMLDivElement, SettingsContainerProps>(({ title, description, children, maxWidth = 'md', labs = false, ...rest }, ref) => {
  const [labelId] = useState(() => uniqueId('settingsMenuContainerLabel'));
  return (
    <SettingsMenuContainerStyled {...rest} maxWidth={maxWidth} component="nav" aria-labelledby={labelId} labs={labs} ref={ref}>
      <Typography variant="h1" id={labelId}>{title}</Typography>
      {description && <Typography fontWeight="medium">{description}</Typography>}
      {children}
    </SettingsMenuContainerStyled>
  );
});

export default SettingsMenuContainer;
