import React from 'react';

const B146 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 33.381854,60.985479 c 0,0 12.088988,1.059616 12.275507,1.044724 0.186521,-0.01489 0.0247,-3.679459 -0.322634,-4.36326 -0.347339,-0.683799 -10.969603,-4.685893 -10.969603,-4.685893 0,0 2.012737,-9.451119 2.120175,-10.047788 0.107436,-0.596671 0.59918,-9.233518 0.59918,-9.233518 0,0 25.025111,3.440494 25.042651,3.134172 0.01754,-0.306322 -0.08862,-4.729053 -0.491634,-5.469438 C 61.341744,30.824819 48.73008,27.492854 48.73008,27.492854 l 0.230453,-0.291909 c 0,0 -0.06077,-5.290313 -0.03073,-5.730618 0.03004,-0.440306 -3.996801,-0.370016 -3.994533,-0.06145 -0.03767,1.628419 0.02835,3.287689 0,4.91635 l -2.150902,-0.675999 0.245817,-0.337998 c 0,0 0.03454,-5.483662 0.01537,-5.822801 -0.0207,-0.365936 -4.198104,-0.371637 -4.194259,-0.06145 0.0039,0.310179 0.07682,4.716621 0.07682,4.716621 l -2.01263,-0.737452 c 0,0 0.09804,-7.038045 -0.153634,-9.018428 -0.787904,-6.2001381 -4.426531,-11.8785104 -4.93281,-11.8785104 -0.50628,0 -3.927632,5.6783723 -4.715531,11.8785034 -0.251661,1.980383 -0.153632,9.018428 -0.153632,9.018428 l -2.012631,0.737452 c 0,0 0.07296,-4.406442 0.07682,-4.716621 0.0039,-0.310181 -4.173565,-0.30448 -4.19426,0.06145 -0.01918,0.339139 0.01537,5.822801 0.01537,5.822801 l 0.245817,0.337998 -2.150902,0.675999 c -0.02836,-1.628661 0.03767,-3.287931 0,-4.91635 0.0023,-0.308558 -4.024571,-0.378848 -3.994534,0.06145 0.03004,0.440305 -0.03073,5.730618 -0.03073,5.730618 l 0.230453,0.291909 c 0,0 -12.6116631,3.331965 -12.9054149,3.871624 -0.4030127,0.740386 -0.5091744,5.163116 -0.491635,5.469438 0.01754,0.306322 25.0426519,-3.134172 25.0426519,-3.134172 0,0 0.491744,8.636847 0.59918,9.233518 0.107436,0.596669 2.120175,10.047788 2.120175,10.047788 0,0 -10.622264,4.002094 -10.969603,4.685893 -0.347339,0.683801 -0.509155,4.348368 -0.322635,4.36326 0.186519,0.01489 12.275508,-1.044724 12.275508,-1.044724 0,0 0.853013,1.029361 1.444172,1.029361 0.591159,0 1.444172,-1.029361 1.444172,-1.029361 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default B146;
