import React from 'react';
import { Stack, Typography } from '@mui/material';

export interface SelectionCardProps {
  title: string;
  subTitle: string;
  handleClick: () => void;
  disabled?: boolean
}

export const SelectionCard = ({ title, subTitle, handleClick, disabled = false }: SelectionCardProps): JSX.Element => (
  <Stack
    role="radio"
    aria-checked={false}
    aria-disabled={disabled}
    onClick={disabled ? undefined : () => handleClick()}
    spacing={1}
    sx={{
      cursor: disabled ? undefined : 'pointer',
      borderRadius: 1,
      flex: 1,
      p: 4,
      pt: 6,
      color: undefined,
      bgcolor: 'common.lightGrey',
      opacity: disabled ? 0.4 : 1,
      alignItems: 'center',
      height: 150,
      '&:hover': disabled ? undefined : {
        bgcolor: 'common.midGrey',
      },
    }}
  >
    <Typography color="inherit" fontSize="1.5em" textAlign="center">{title}</Typography>
    <Typography color="inherit" fontSize="0.9em" textAlign="center">{subTitle}</Typography>
  </Stack>
);

export default SelectionCard;
