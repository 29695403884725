import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import SelectSerialType from 'components/shared/selectSerialType';
import { useTranslations } from 'use-intl';
import { AssetDevicePair } from 'hooks/assetGroup/useAssetAndDevices';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import useSerialType from 'hooks/settings/useSerialType';

export interface AssetGroupAssetTableProps {
  children: AssetDevicePair[];
}

export const AssetGroupAssetTable = ({
  children
}: AssetGroupAssetTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.assetGroups.edit.assets');
  const serialType = useSerialType();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('columns.asset')}
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {t('columns.device')}
                <SelectSerialType size="small" InputProps={{ sx: { width: '15rem' } }} />
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children.map(({ asset, device }, index) => {
            const border = index === children.length - 1 ? 0 : undefined;
            return (
              <TableRow key={`asset-group-assets-table-${index}`}>
                <TableCell sx={{ border }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {asset ? (
                      <>
                        <AssetColourMarker assetId={asset.id} />
                        <Stack>
                          <Typography fontStyle={asset.archived ? 'italic' : 'normal'}>
                            <AssetLabel asset={asset} />
                          </Typography>
                          <Typography variant="body3">{asset.make} {asset.model} {asset.variant}</Typography>
                        </Stack>
                      </>
                    )
                      : (
                        <Typography fontStyle="italic">{t('unknownAsset')}</Typography>
                      )}
                  </Stack>
                </TableCell>
                <TableCell sx={{ border }}>
                  <Stack direction="column" alignItems="flex-start">
                    {device ? (
                      <>
                        <Typography>{device.make} {device.model}</Typography>
                        <Typography>{device[serialType]}</Typography>
                      </>
                    )
                      : (
                        <Typography fontStyle="italic">{t('unknownDevice')}</Typography>
                      )}
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
