import { createTheme } from '@mui/material/styles';
import colorPalette from './colorPalette';
import createCommonTheme, { spacing, spacingPixels } from './commonTheme';

const mode = 'dark';
const colors = colorPalette(mode);
const lightColors = colorPalette('light');
const checkboxAndRadioInputOpacity = '1A'; // 10%

const commonTheme = createCommonTheme(mode);

const boxShadow = {
  default: '0 2px 4px -1px rgba(0, 0, 0, .20), 0 4px 5px 0 rgba(0, 0, 0, .15), 0 1px 10px 0 rgba(0, 0, 0, .13)',
};

const darkTheme = createTheme(commonTheme, {
  spacing: spacingPixels,
  spacingNumber: spacing, // same as spacing but without px
  palette: {
    mode,
    common: {
      black: colors.black,
      white: colors.white,
      text: colors.grey,
      textConstant: lightColors.grey,
      lightGrey: colors.greyLight,
      lightBlue: colors.greyLight,
      midGrey: colors.greyMid,
      offBlack: colors.black,
      whiteConstant: lightColors.white,
      blackConstant: lightColors.black,
      blueConstant: lightColors.primary,
      darkBlueConstant: lightColors.primaryDark,
      scrollBar: 'rgb(180,180,180)',
      scrollBarBackground: 'rgba(255,255,255,0)',
      disabled: 'rgba(255,255,255,0.3)',
      tpIconCircle: colors.black,
      actionIcon: colors.black
    },
    primary: {
      main: colors.primary,
      dark: colors.grey,
      mainHover: colors.black,
      darkBlue: colors.white,
      turquoise: colors.turquoise,
      yellow: colors.yellow,
      orange: colors.orange,
      blue: lightColors.primary,
      distressRed: colors.error,
      distressRedLight: colors.errorLight,
      activeGreen: colors.success,
      activeGreenLight: colors.successLight
    },
    secondary: {
      main: colors.primary,
      light: colors.greyLight,
      dark: colors.grey,
      contrastText: colors.primary,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
      contrastText: colors.white,
    },
    labs: {
      main: colors.labs,
      light: colors.labsDark,
      dark: colors.labsLight,
      contrastText: colors.white,
    },
    background: {
      paper: colors.primaryDark,
      standOut: '#29323C',
      standOutLight: colors.greyMid,
      default: colors.greyLight,
      overlay: '#29323C',
      hamburgerButton: 'rgba(0,0,0,0.1)',
      omniboxBackground: colors.greyLight,
      contextboxBackground: colors.greyLight,
      inputBackground: colors.white,
      activeButton: colors.black
    },
    messaging: {
      myMessageBackground: colors.blue,
      myMessageText: colors.black,
      theirMessageBackground: colors.white,
      theirMessageText: colors.grey
    },
    omnibox: {
      background: colors.greyLight,
      color: colors.grey,
      overlay: colors.white,
      overlayHover: colors.grey,
      resultHover: colors.white,
      highlight: colors.black,
      searchText: colors.white,
      searchBorder: 'none',
      searchBackground: colors.primary,
      battery: colors.primaryLight,
    },
    contextbox: {
      background: colors.greyLight,
      color: colors.grey,
      totalText: colors.grey,
      overlay: colors.white,
      highlight: colors.black,
      routeIcon: colors.greyLight,
      link: colors.primary,
      linkHover: colors.primaryLight
    },
    settingsDrawer: {
      background: colors.greyLight,
      label: colors.black
    },
    button: {
      primary: 'rgba(255,255,255,.85)',
      primaryColor: colors.greyLight,
      primaryHover: colors.black,
      checked: lightColors.primary,
      microsoftLoginButtonFg: '#FFF',
      microsoftLoginButtonBg: '#2F2F2F',
    },
    mapControls: {
      fg: colors.black,
      bg: 'rgba(0, 0, 0, 0.4)',
      bgLight: 'rgba(0, 0, 0, 0.2)',
      bgHover: 'rgba(0, 0, 0, 0.8)',
      bgActive: lightColors.black,
      fgActive: colors.black,
      bgDisabled: 'rgba(0, 0, 0, 0.6)',
      fgDisabled: 'rgba(255, 255, 255, 0.4)',
      bgDisabledHighContrast: lightColors.grey,
      fgDisabledHighContrast: 'rgba(255, 255, 255, 0.6)',
      fgHighContrast: colors.black,
      bgHighContrast: colors.white
    },
    toggleButtons: {
      selectedBg: lightColors.white,
      selectedText: colors.white,
      deselectedBg: colors.greyLight,
      deselectedText: colors.grey,
    },
    firefightingDrops: {
      water: colors.water,
      retardant: colors.retardant,
      foam: colors.foam,
      gel: colors.gel,
      unknown: colors.unknown,
    }
  },
  typography: {
    h1: {
      color: '#DDD',
    },
    h2: {
      color: '#DDD',
    },
    h3: {
      color: '#DDD',
    },
    h4: {
      color: '#DDD',
    },
    h5: {
      color: '#DDD',
    },
    h6: {
      color: '#DDD',
    },
    body1: {
      color: '#DDD',
    },
    body3: { // An additional variant
      ...commonTheme.typography.body1,
      color: colors.disabled,
    },
  },
  border: {
    default: `1px solid ${colors.greyMid}`,
    prettyGridDark: `1px solid ${colors.greyMid}`,
    prettyGridLight: `1px solid ${colors.greyMid}`,
    width: '1px',
    color: colors.greyMid,
    standOut: colors.greyMid
  },
  boxShadow,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          boxShadow: boxShadow.default,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: lightColors.black,
          color: colors.black
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        },
        primary: {
          backgroundColor: colors.primary,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.grey
          }
        }
      }
    },
    // TODO: check on this
    // MuiChip: {
    //   styleOverrides: {
    //     clickable: {
    //       backgroundColor: colors.white,
    //       color: colors.black,
    //       boxShadow: 'none',
    //       '&:hover': {
    //         boxShadow: 'none',
    //         backgroundColor: colors.grey
    //       },
    //       '&:focus': {
    //         boxShadow: 'none',
    //         backgroundColor: colors.grey,
    //         color: colors.white
    //       }
    //     }
    //   }
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        containedSecondary: {
          color: colors.primary,
          '&:hover': {
            boxShadow: 'none'
          },
          '&:focus': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: '500',
          color: 'rgba(255, 255, 255, 0.87)',
          paddingBottom: 6
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.primary
          },
          '&:hover': {
            backgroundColor: colors.primary + checkboxAndRadioInputOpacity
          },
          '&.MuiIconButton-colorSecondary.Mui-checked:hover': {
            backgroundColor: colors.primary + checkboxAndRadioInputOpacity
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.primary
          },
          '&:hover': {
            backgroundColor: colors.primaryLight + checkboxAndRadioInputOpacity
          },
          '&.MuiIconButton-colorSecondary.Mui-checked:hover': {
            backgroundColor: colors.primaryLight + checkboxAndRadioInputOpacity
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          zIndex: 1
        }
      }
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.greyLight,
        }
      }
    }
  }
});

export default darkTheme;
