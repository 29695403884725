import React from 'react';

const AscentExceedence = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M11.31,0H4.69L0,4.69v6.63L4.69,16h6.63L16,11.31V4.69L11.31,0z" />
    <polygon className="icon-detail" fill="#fff" points="4.79,7.38 5.46,8.05 7.52,5.99 7.52,11.83 8.48,11.83 8.48,5.99 10.54,8.05 11.21,7.38 8,4.17" />
  </svg>
);

export default AscentExceedence;
