import { useCallback, useMemo } from 'react';

const getEventPickingPriority = (event: string) => {
  switch (event) {
    case 'EVT_MANUAL':
    case 'EVT_OPSNORMAL':
    case 'EVT_POI':
    case 'EVT_ALERT': return 10;
    case 'EVT_DISTRESS':
    case 'EVT_DISTRESS_START':
    case 'EVT_DISTRESS_CANCEL': return 9;
    case 'EVT_LOAD_SETTLED': return 3;
    case 'EVT_DROP':
    case 'EVT_DROP_START':
    case 'EVT_DROP_STOP':
    case 'EVT_CONTAINMENT_LINE_START':
    case 'EVT_CONTAINMENT_LINE_STOP':
    case 'EVT_DROP_END': return 2;
    case 'EVT_STANDARD':
    case 'EVT_INFLIGHT':
    case 'EVT_SCHEDULED':
    case 'EVT_INVGPS': return -1;
    default: return 0;
  }
};

export const usePickingPriority = <T extends { pickingKey: string, event: string }>(data: T[]) => {
  const picks = useMemo(() => data.reduce<Record<string, T>>((acc, d) => {
    if (d.pickingKey in acc) {
      if (getEventPickingPriority(acc[d.pickingKey].event) < getEventPickingPriority(d.event)) {
        acc[d.pickingKey] = d;
      }
    } else {
      acc[d.pickingKey] = d;
    }
    return acc;
  }, {}), [data]);

  return useCallback((item: T) => picks[item.pickingKey], [picks]);
};
