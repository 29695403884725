import React, { ReactNode, useMemo, useState } from 'react';
import { CursorContext, CursorPosition } from './cursorContext';

const CursorContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const [cursorPosition, setCursorPosition] = useState<CursorPosition>();

  const cursorContextValue: CursorContext = useMemo(() => ({
    cursorPosition,
    setCursorPosition
  }), [cursorPosition, setCursorPosition]);

  return (
    <CursorContext.Provider value={cursorContextValue}>
      {children}
    </CursorContext.Provider>
  );
};

export default CursorContextProvider;
