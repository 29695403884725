import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { fetchAllAssetModules } from './requests';
import { AssetModule } from './types';
import { featureAssetsQueryKeys } from './queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetAssetModules = <T = AssetModule[]>(options?: Options<AssetModule[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = featureAssetsQueryKeys.all(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => fetchAllAssetModules(organisationId),
    ...options,
  });
};
