import React from 'react';
import { SvgIcon, Tooltip, Box, TooltipProps } from '@mui/material';
import { IconFab } from './controls-styles';

interface ControlButtonProps {
  id: string
  text: string
  onClick: (event: React.MouseEvent) => void
  children: React.ReactNode
  tooltipPlacement?: TooltipProps['placement']
  disabled?: boolean
  highContrastControls?: boolean
}

const ControlButton: React.FC<ControlButtonProps> = ({
  id,
  text,
  onClick,
  children,
  tooltipPlacement = 'right',
  disabled = false,
  highContrastControls = false,
}) => (
  <Tooltip title={text} placement={tooltipPlacement}>
    <Box>
      <IconFab size="small" color="primary" id={id} aria-label={text} highContrastControls={highContrastControls} onClick={onClick} disabled={disabled}>
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" sx={{ fontSize: '1.2rem' }}>
          {children}
        </SvgIcon>
      </IconFab>
    </Box>
  </Tooltip>
);

export default ControlButton;
