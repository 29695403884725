import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslations } from 'use-intl';

interface DismissButtonProps {
  snackbarKey: string
  label?: string
  onClose?: (snackbarKey: string) => void
}

const DismissButton = ({ snackbarKey, label, onClose }: DismissButtonProps) => {
  const { closeSnackbar } = useSnackbar();
  const t = useTranslations('components.Snackbar');

  return (
    <IconButton
      aria-label={label || t('dismiss')}
      color="inherit"
      onClick={() => {
        closeSnackbar(snackbarKey);
        if (typeof onClose === 'function') onClose(snackbarKey);
      }}
      size="large"
    >
      <Close />
    </IconButton>
  );
};

export default DismissButton;
