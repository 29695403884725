import { useEffect } from 'react';
import { DateTime } from 'luxon';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { getInferredEventsForOrganisation } from 'apis/rest/inferredEvents/requests';
import { useInferredEventsRepository } from 'repositories/inferredEvents/hooks';
import { useStartOfDay } from 'hooks/useStartOfDay';
import useTimezone from 'hooks/session/useTimezone';

export const HistoricInferredEventsDummy = () => {
  const eventsRepo = useInferredEventsRepository();
  const organisationId = useOrganisationId();

  const timezone = useTimezone();
  const startOfDay = useStartOfDay();

  useEffect(() => {
    const today = DateTime.now().setZone(timezone).startOf('day');

    // in historic mode, get events up to the end of that day
    let until = DateTime.now().toUTC();
    if (today > startOfDay) {
      until = startOfDay.endOf('day');
    }

    console.log(`getting historic inferred events for ${organisationId} from ${startOfDay.toISO()} until ${until.toISO()}`);
    getInferredEventsForOrganisation(organisationId, startOfDay, until).then(events => {
      eventsRepo.insertEvents(events);
    });
  }, [eventsRepo, organisationId, startOfDay, timezone]);

  return null;
};
