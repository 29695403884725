import React from 'react';

const LoadSettled = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`}
    viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      stroke="#fff"
      strokeLinejoin="round"
      strokeLinecap="round"
      d="M 3.93027,6.6971488 C 4.0238266,6.6100334 4.1173858,6.5229262 4.2109424,6.4378447 4.3044991,6.3527632 4.3980608,6.2697246 4.4916174,6.1906618 4.585174,6.1115909 4.6787307,6.0365126 4.7722873,5.96716 4.865844,5.8977991 4.9594005,5.8341975 5.0529571,5.7777945 5.1465138,5.7213828 5.2400705,5.6722303 5.3336272,5.6314133 c 0.093556,-0.040816 0.1871129,-0.073229 0.2806696,-0.09655 0.093557,-0.023313 0.187113,-0.037468 0.2806698,-0.042198 0.093557,-0.00471 0.1871136,4.18e-5 0.2806697,0.014122 0.093557,0.014088 0.1871136,0.037543 0.2806704,0.069779 0.093556,0.032236 0.1871128,0.073304 0.2806696,0.1221804 0.093556,0.048878 0.1871129,0.105624 0.2806698,0.1688652 0.093557,0.063233 0.1871128,0.1330045 0.2806696,0.2076474 0.093557,0.074643 0.1871136,0.1541745 0.2806697,0.2367277 0.093557,0.082553 0.1871137,0.168137 0.2806705,0.254742 0.093556,0.086604 0.1871128,0.1742308 0.2806958,0.2608358 0.093557,0.086613 0.1871129,0.1721801 0.2806695,0.2547418 0.093557,0.082561 0.1871137,0.1620765 0.2806698,0.2367277 0.093557,0.074651 0.1871136,0.1443973 0.2806704,0.2076559 0.093556,0.06325 0.187113,0.119963 0.2806697,0.1688569 0.093557,0.048903 0.1871129,0.089928 0.2806693,0.1221803 0.093557,0.032253 0.1871128,0.055682 0.2806704,0.069779 0.093557,0.014105 0.1871132,0.018835 0.2806692,0.014122 0.09356,-0.0047 0.187115,-0.018901 0.280672,-0.042198 0.09356,-0.023296 0.187111,-0.05575 0.280669,-0.096542 0.09356,-0.0408 0.187112,-0.089996 0.28067,-0.1463811 0.09355,-0.056395 0.187114,-0.1200215 0.280669,-0.1893654 0.09356,-0.069353 0.187113,-0.1444392 0.28067,-0.2235019 0.09356,-0.079063 0.187113,-0.1621101 0.28067,-0.2471831 0.09356,-0.085082 0.187113,-0.1721968 0.28067,-0.2593122"
       />
    <path
      className="icon-detail"
      stroke="#fff"
      strokeLinejoin="round"
      strokeLinecap="round"
      d="m 3.67936,10.49092 8.64128,0.01816" />
  </svg>
);

export default LoadSettled;
