import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button
} from '@mui/material';

/* a generic confirmation dialog */
const ConfirmDialog = ({
  message,
  title,
  okButtonText,
  cancelButtonText,
  onEnter,
  onConfirm,
  onCancel,
  open,
  setOpen,
  option,
  className,
  isCancelButtonHidden,
  setCancelButtonHidden
}) => {
  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) onConfirm();
    if (isCancelButtonHidden !== undefined) setCancelButtonHidden(false);
  };

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  // Allow providing either a single `message` or an Array of such
  const messages = Array.isArray(message) ? message : [message];

  return (
    <Dialog
      onClose={(event, reason) => (reason !== 'backdropClick') && handleCancel}
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      TransitionProps={{
        onEntering: onEnter
      }}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {messages.map(m => (
          <DialogContentText key={m}>{m}</DialogContentText>
        ))}
        <div>{option}</div>
      </DialogContent>
      <DialogActions>
        {!isCancelButtonHidden && (
          <Button autoFocus onClick={handleCancel} className={className}>
            {cancelButtonText}
          </Button>
        )}
        <Button onClick={handleConfirm} color="primary">
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
