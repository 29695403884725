import { useTranslations } from 'use-intl';
import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { stringify as csvStringify, Input } from 'csv-stringify/browser/esm/sync';
import { useAssetLabel } from 'components/shared/assetLabel';
import useDuration from 'hooks/units/useDuration';
import { Query } from '../form';
import { VisibleLeg } from '../types';

const useCSVExport = () => {
  const t = useTranslations('pages.reporting');
  const assetLabel = useAssetLabel();
  const duration = useDuration();

  return useCallback((
    legsByAsset: Record<VisibleLeg['asset']['id'], VisibleLeg[]>,
    assets: AssetWithDevice[],
    displayTZ: string,
    query: Query,
  ) => {
    const startDate = DateTime.fromSeconds(query.from).toISODate();
    const endDate = DateTime.fromSeconds(query.until).toISODate();

    const records = assets.reduce<Input>((acc, asset) => {
      if (!legsByAsset[asset.id]?.length) return acc;
      return [
        ...acc,
        ...legsByAsset[asset.id].map(leg => ({
          asset,
          assetLabel: assetLabel(asset),
          from: leg.from,
          to: leg.to,
          start: {
            event: leg.reports.start.events[0],
            time: DateTime.fromSeconds(leg.reports.start.received).setZone(displayTZ).toISO({ suppressMilliseconds: true }),
            coordinates: `${leg.reports.start.latitude.toFixed(3)}, ${leg.reports.start.longitude.toFixed(3)}`,
          },
          takeoff: {
            event: leg.reports.takeoff?.events[0],
            time: leg.reports.takeoff ? DateTime.fromSeconds(leg.reports.takeoff.received).setZone(displayTZ).toISO({ suppressMilliseconds: true }) : undefined,
            coordinates: leg.reports.takeoff ? `${leg.reports.takeoff.latitude.toFixed(3)}, ${leg.reports.takeoff.longitude.toFixed(3)}` : undefined,
          },
          landing: {
            event: leg.reports.landing?.events[0],
            time: leg.reports.landing ? DateTime.fromSeconds(leg.reports.landing.received).setZone(displayTZ).toISO({ suppressMilliseconds: true }) : undefined,
            coordinates: leg.reports.landing ? `${leg.reports.landing.latitude.toFixed(3)}, ${leg.reports.landing.longitude.toFixed(3)}` : undefined,
          },
          end: {
            event: leg.reports.end?.events[0],
            time: leg.reports.end ? DateTime.fromSeconds(leg.reports.end.received).setZone(displayTZ).toISO({ suppressMilliseconds: true }) : undefined,
            coordinates: leg.reports.end ? `${leg.reports.end.latitude.toFixed(3)}, ${leg.reports.end.longitude.toFixed(3)}` : undefined,
          },
          flightDuration: duration.fromMillis(leg.flightDuration),
          duration: duration.fromMillis(leg.duration),
        })),
      ];
    }, []);

    const csvString = csvStringify(records, {
      header: true,
      columns: [
        { key: 'assetLabel', header: t('asset') },
        { key: 'asset.make', header: t('make') },
        { key: 'asset.model', header: t('model') },
        { key: 'from', header: t('from') },
        { key: 'to', header: t('to') },
        { key: 'start.event', header: t('startEvent') },
        { key: 'start.time', header: t('startTime') },
        { key: 'start.coordinates', header: t('startCoordinates') },
        { key: 'takeoff.event', header: t('takeoffEvent') },
        { key: 'takeoff.time', header: t('takeoffTime') },
        { key: 'takeoff.coordinates', header: t('takeoffCoordinates') },
        { key: 'landing.event', header: t('landingEvent') },
        { key: 'landing.time', header: t('landingTime') },
        { key: 'landing.coordinates', header: t('landingCoordinates') },
        { key: 'end.event', header: t('endEvent') },
        { key: 'end.time', header: t('endTime') },
        { key: 'end.coordinates', header: t('endCoordinates') },
        { key: 'flightDuration', header: t('flightDuration') },
        { key: 'duration', header: t('duration') },
      ],
    });

    let exportFilename = `${t('exportFilename')} ${assets.map(assetLabel).join(', ')} `;
    if (startDate === endDate) {
      exportFilename += startDate;
    } else {
      exportFilename += t('dateRange', { from: startDate, until: endDate });
    }

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURI(csvString)}`);
    element.setAttribute('download', `${exportFilename}.csv`);
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
    document.body.removeChild(element);
  }, [t, assetLabel]);
};

export default useCSVExport;
