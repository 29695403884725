import React from 'react';

const Convair580 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 44.276524,52.658231 c 0.246777,-0.03513 -0.09348,-2.773126 -0.09348,-3.084713 0,-0.311587 -8.537489,-1.838361 -8.849076,-1.900681 -0.311587,-0.06232 0.261592,-1.63709 0.560858,-3.676728 0.299264,-2.039638 -0.09348,-8.50633 -0.09348,-8.50633 l 0.405064,-0.498539 1.21519,-3e-6 c 0,0 0.580066,0.946149 2.056475,0.997079 1.476408,0.05093 1.776046,-1.402142 1.776046,-1.402142 0,0 19.505356,-0.934761 20.003895,-1.184031 0.49854,-0.24927 0.311587,-2.617332 0.124635,-3.053554 -0.186952,-0.436222 -19.941577,-2.773125 -19.941577,-2.773125 -0.333003,-2.344702 -0.127516,-6.756904 -2.212269,-6.699124 -2.084753,0.05778 -2.279111,5.34246 -2.336901,6.200584 l -1.028238,-0.03116 c 0,0 0.186951,-3.520934 -1e-6,-8.94255 -0.186952,-5.421617 -3.365134,-6.917235 -3.365134,-6.917235 0,0 -3.178181,1.495618 -3.365133,6.917235 -0.186952,5.421616 -1e-6,8.94255 -1e-6,8.94255 l -1.028238,0.03116 c -0.05779,-0.858124 -0.252148,-6.142807 -2.336901,-6.200584 -2.084753,-0.05778 -1.879266,4.354422 -2.212269,6.699124 0,0 -19.7546241,2.336903 -19.9415763,2.773125 -0.1869523,0.436222 -0.3739046,2.804284 0.1246348,3.053554 0.4985395,0.24927 20.0038945,1.184031 20.0038945,1.184031 0,0 0.299638,1.453074 1.776046,1.402142 1.476409,-0.05093 2.056475,-0.997079 2.056475,-0.997079 l 1.21519,3e-6 0.405064,0.498539 c 0,0 -0.392744,6.466692 -0.09348,8.50633 0.299266,2.039638 0.872445,3.61441 0.560858,3.676728 -0.311587,0.06232 -8.849076,1.589094 -8.849076,1.900681 0,0.311587 -0.340257,3.049584 -0.09348,3.084713 8.755599,1.246349 10.812075,0.311587 11.777992,3.64557 0.965918,-3.333983 3.022394,-2.399221 11.777993,-3.64557 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Convair580;
