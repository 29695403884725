import makeStyles from '@mui/styles/makeStyles';

/**
 * @deprecated
 */
export default makeStyles(theme => ({
  root: {
    '& .SnackbarItem-message': {
      flex: 1,
    },
  },
  infoSnackbar: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main, // white in dark mode, blue in light mode
  }
}));
