import React from 'react';

const Balloon = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m23.317 29.624v-2.7927c0.03879-0.93091-0.35879-1.4448-0.37818-1.4642l-0.01939-0.01939-2.2982-2.337c0.46546-1.4545 0.86303-3.1127 1.1927-4.9552 0.71758-3.9855 0.84364-7.4182 0.84364-7.457v-0.0097c0-2.0267-0.32-3.7721-0.9503-5.1782-0.51394-1.1345-1.2218-2.0655-2.1236-2.7442-1.5418-1.1539-3.0642-1.2121-3.1224-1.2121h-0.0097-1.0279-0.0097c-0.06788 0-1.5806 0.058182-3.1224 1.2218-0.90182 0.67879-1.6097 1.6097-2.1236 2.7442-0.6303 1.4061-0.9503 3.1515-0.9503 5.1782v0.0097c0 0.03879 0.12606 3.4715 0.84364 7.457 0.34909 1.9103 0.76606 3.617 1.2509 5.12l-2.2497 2.2788c-0.0097 0.0097-0.0097 0.0097-0.019394 0.01939-0.019394 0.01939-0.41697 0.53333-0.37818 1.4642v2.7927c0 0.05818 0.0097 0.34909 0.26182 0.57212 0.18424 0.15515 0.42667 0.23273 0.73697 0.23273 0.14545 0 0.30061-0.01939 0.46545-0.04848l4.8097-0.97939c0.16485 0.10667 0.36848 0.20364 0.59152 0.26182 0.07758 0.50424 0.14546 0.58182 0.20364 0.64 0.03879 0.03879 0.09697 0.06788 0.15515 0.07758h0.04849c0.07758 0 0.14545-0.02909 0.20364-0.08727 0.04848-0.04849 0.12606-0.12606 0.20364-0.62061 0.25212-0.05818 0.50424-0.17455 0.75636-0.38788l4.7709 0.9697c0.16485 0.02909 0.32 0.04848 0.46546 0.04848 0.3103 0 0.55273-0.07758 0.72727-0.23273 0.25212-0.21333 0.26182-0.50424 0.25212-0.56242z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Balloon;
