import { DependencyList, useEffect, useRef } from 'react';

const useLastDefined = <T, >(value: T | undefined, deps: DependencyList) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (value !== undefined) ref.current = value;
  }, [value]);

  return value ?? ref.current;
};

export default useLastDefined;
