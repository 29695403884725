import React from 'react';

const CL415 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 35.356688,18.813954 c 0.337846,2.785098 0.308909,9.569301 0.308909,9.569301 l 2.066092,-0.0129 -0.114401,-3.805635 c 0,0 -0.03129,-4.683146 1.719913,-4.610531 1.578276,0.06544 1.802124,3.883463 1.833857,4.989782 0.03173,1.10632 -0.0543,1.813311 -0.0543,1.813311 l 1.018384,0.772247 18.617685,0.01115 1.542277,1.630408 -0.04406,4.494638 -1.542278,2.732035 -24.869121,-0.07118 c 0,0 -0.06888,0.966374 -0.242956,1.138132 -0.174074,0.171758 -0.445078,0.152662 -0.445078,0.152662 l -0.992057,9.113642 1.74057,-0.02203 c 0,0 0.446842,-0.525017 0.660975,-0.528781 0.214134,-0.0038 0.68301,0.572846 0.68301,0.572846 0,0 6.088086,-0.255786 6.257241,-0.04407 1.510202,1.890233 0.898567,5.858854 -0.616912,7.248705 -0.261603,0.239917 -9.429926,0.198298 -9.429926,0.198298 l -0.66098,-0.550814 c 0,0 -0.08927,1.564311 -0.654512,1.564311 -0.565239,0 -0.654512,-1.564311 -0.654512,-1.564311 l -0.660976,0.550814 c 0,0 -9.168323,0.04162 -9.429926,-0.198298 -1.515479,-1.389851 -2.127114,-5.358472 -0.616912,-7.248705 0.169155,-0.211716 6.257241,0.04407 6.257241,0.04407 0,0 0.468876,-0.576646 0.68301,-0.572846 0.214133,0.0038 0.660975,0.528781 0.660975,0.528781 l 1.74057,0.02203 -0.992057,-9.113642 c 0,0 -0.271004,0.0191 -0.445078,-0.152662 C 28.507279,37.292954 28.438399,36.32658 28.438399,36.32658 L 3.5692782,36.39776 2.0270005,33.665725 1.9829355,29.171087 3.5252132,27.540679 22.142898,27.529529 23.161282,26.757282 c 0,0 -0.08603,-0.706991 -0.0543,-1.813311 0.03173,-1.106319 0.255581,-4.924342 1.833857,-4.989782 1.751203,-0.07261 1.719913,4.610531 1.719913,4.610531 l -0.114401,3.805635 2.066092,0.0129 c 0,0 -0.02894,-6.784203 0.308909,-9.569301 0.528174,-4.354095 3.217668,-5.087951 3.217668,-5.087951 0,0 2.689494,0.733856 3.217668,5.087951 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default CL415;
