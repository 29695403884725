import { useMemo } from 'react';

type Resolver<T> = () => T

type Bindings<D extends Record<never, unknown>> = {
  [K in keyof D]?: Resolver<D[K]>
}

interface Dependency<T> {
  // Injects the dependency
  inject(): T
}

export class DependencyInjectionContainer<D extends Record<never, unknown>> {
  private bindings: Bindings<D> = {};

  // Registers a resolver function for a dependency which is lazily evaluated each time the dependency is injected
  bind<K extends string & keyof D>(id: K, resolver: Resolver<D[K]>): void {
    this.bindings[id] = resolver;
  }

  // Registers a resolver function for a dependency which is lazily evaluated only the first time the dependency is injected
  bindSingleton<K extends string & keyof D>(id: K, resolver: Resolver<D[K]>): void {
    let value: D[K];
    this.bindings[id] = () => {
      if (value === undefined) value = resolver();
      return value;
    };
  }

  // Returns a dependency
  depend<K extends string & keyof D>(id: K): Dependency<D[K]> {
    return {
      inject: () => {
        const resolver = this.bindings[id];
        if (!resolver) throw new Error(`DI no binding exists for ${id}`);
        return resolver();
      },
    };
  }
}

export const createUseDependency = <D extends Record<never, unknown>>(container: DependencyInjectionContainer<D>) => <K extends string & keyof D>(id: K) => (
  useMemo(() => container.depend(id).inject(), [id])
);
