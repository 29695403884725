import * as firebaseAuth from 'firebase/auth';
import { acceptInvite as acceptInviteApi, getInvite } from 'apis/rest/invites/requests';
import { getRedirectResult, LoginResponse, loginWithSSO, signup } from 'apis/auth';

/**
 * Verify an invite token with a call to the backend.
 *
 * @param token invite token to be verified
 * @returns A promise for the invite details, or an error if not verified
 */
export async function verifyInvite(token: string): Promise<Invite> {
  return getInvite(token)
    .then(invite => {
      if (invite.status === 'Pending') {
        return invite;
      }
      return Promise.reject(Error('Failed signup: invite unverified'));
    });
}

/**
 * Accept invitation, after user creation and invite verification.
 *
 * This adds the user to the organisation, and makes the invite invalid from now on.
 *
 * @param token token of invite to accept/remove
 * @param userCredentials credentials of user to now associate with organisation
 * @returns A promise for the loginresponse, resulting from the newly created user
 */
async function acceptInvite(token: string, userCredentials: firebaseAuth.UserCredential): Promise<LoginResponse> {
  return acceptInviteApi(token, userCredentials.user.uid)
    .then(() => LoginResponse.FromFirebaseCredential(userCredentials))
    .catch(() => Promise.reject(Error('Failed signup: could not accept invite')));
}

/**
 * The create user process simplifies the train of
 * verify -> create/signup -> accept/remove invite
 *
 * Applies only to email-password invites, SSO tokens will be different.
 *
 * @param name Information from form
 * @param email
 * @param password
 * @param token Token of invite to be verified and accepted with user creation
 *
 * @returns Promise for `LoginResponse`, to be used to subsequently login. User must reauthenticate to get new claims on login
 */
export async function createInvitedUser(name: string, email: string, password: string, token: string): Promise<LoginResponse> {
  return verifyInvite(token)
    .then(() => signup(name, email, password))
    .then(userCredentials => acceptInvite(token, userCredentials));
}

/**
 * First stage of the signup process (before redirect to SSO)
 *
 * Second stage is in `finishCreateInvitedMSUser`
 *
 * @param token Token which will be verified before signing in/creating user
 * @param providerURL URL of SSO/OAuth provider (e.g. 'microsoft.com')
 * @returns Promise<void> which resolves when the signup starts (user will be redirected though)
 */
export async function createInvitedSSOUser(token: string, providerURL: string): Promise<void> {
  return verifyInvite(token)
    .then(() => loginWithSSO(providerURL));
}

/**
 * The second stage of the signup process, after the user has done SSO.
 *
 * @param token Token which will be used to accept the invite/add to organisation
 * @returns Promise for LoginResponse, which can be used to sign in afterwards
 */
export async function finishCreateInvitedSSOUser(token: string): Promise<LoginResponse> {
  return getRedirectResult()
    .then(userCredentials => {
      if (userCredentials === null) {
        return Promise.reject(Error('No credentials in redirect'));
      }
      return acceptInvite(token, userCredentials);
    })
    .catch(e => Promise.reject(e));
}
