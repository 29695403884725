import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import usePermissions from 'hooks/session/usePermissions';
import { useParams } from 'react-router';
import { useGetAssetGroupsForOrganisation, useUpdateAssetGroup, useUpdateAssetGroupAssets } from 'apis/rest/assetGroups/hooks';

import { useTranslations } from 'use-intl';
import useSnackbar from 'hooks/useSnackbar';
import LoadingPage from 'components/pages/loading/loading-view';
import MissingPage from 'components/shared/missingPage';
import DetailPanel from 'components/shared/DetailPanel';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import LinkAbove from 'components/shared/linkAbove';
import Page from 'components/pages/page';
import SettingsSection from 'components/shared/settingsSection';
import { useAssetsAndDevices } from 'hooks/assetGroup/useAssetAndDevices';
import { AssignAssetGroupDialog } from 'components/dialogs/assetGroups/assignAssetsDialog';
import validateName from 'helpers/validation/validateName';
import { AssetGroupAssetTable } from '../components/assetGroupAssetTable';

const EditAssetGroupsPage = (): JSX.Element => {
  const {
    data,
    isLoading,
    isRefetching
  } = useGetAssetGroupsForOrganisation();
  const renameGroupMutation = useUpdateAssetGroup();
  const modifyAssetGroupAssetsMutation = useUpdateAssetGroupAssets();
  const [isAssignDevicesDialogOpen, setIsAssignDevicesDialogOpen] = useState(false);

  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const t = useTranslations('pages.manage.assetGroups.edit');
  const snackbar = useSnackbar();

  const id = Number(params.id);
  const assetGroup = useMemo(() => data?.find(g => g.id === id), [data, id]);
  const [groupName, setGroupName] = useState(assetGroup?.name);
  const assetsAndDevices = useAssetsAndDevices(assetGroup
    ?.assets
    .map(m => ({
      assetId: m.id, deviceId: m.deviceId ?? 0
    })) ?? []);
  const existingAssetNames = useMemo(() => data?.map(m => m.name) ?? [], [data]);
  const groupNameValid = useMemo(() => validateName(groupName, assetGroup?.name ?? '', existingAssetNames), [groupName, assetGroup?.name, existingAssetNames]);
  const canSave = useMemo(() => groupName !== assetGroup?.name && groupNameValid.valid, [groupName, assetGroup?.name, groupNameValid.valid]);

  const handleRenameCancel = () => setGroupName(assetGroup?.name);
  const handleRenameSubmit = () => {
    renameGroupMutation.mutate({
      assetGroupId: id,
      assetGroupName: groupName
        ?? assetGroup?.name
        ?? ''
    });
  };
  const handleRenameChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setGroupName(evt.target.value);
  const handleAssignAssets = (assetIds: number[]) => {
    modifyAssetGroupAssetsMutation.mutate({
      assetGroupId: id,
      assetIds,
    },
    { onSuccess: () => setIsAssignDevicesDialogOpen(false) });
  };
  const renderAssets = (): ReactNode => (
    <DetailPanel spacing={3} pt={0}>
      <AssetGroupAssetTable>
        {assetsAndDevices}
      </AssetGroupAssetTable>
      {permissions.canEditAssetGroups && (
        <Stack
          spacing={3}
          px={3}
          direction="row"
          justifyContent="flex-end"
          height="4em"
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => setIsAssignDevicesDialogOpen(true)}
          >
            {t('modifyButton')}
          </Button>
        </Stack>
      )}
    </DetailPanel>
  );
  const renderNoAssetView = (): ReactNode => (
    <DetailPanel spacing={3}>
      <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
        <Stack justifyContent="center">
          <Typography>{t('noAssets')}</Typography>
        </Stack>
        {permissions.canEditAssetGroups && (
          <Button variant="contained" size="large" onClick={() => setIsAssignDevicesDialogOpen(true)}>
            {t('assignButton')}
          </Button>
        )}
      </Stack>
    </DetailPanel>
  );

  useEffect(() => {
    if (renameGroupMutation.isSuccess) {
      snackbar.display({
        id: `assetGroupUpdated.${id}`,
        text: t('snackbar.rename', { name: groupName ?? '' }),
        type: 'success',
      });
    }
  }, [
    renameGroupMutation.isSuccess,
    id,
    snackbar,
    t,
    groupName
  ]);
  useEffect(() => {
    if (modifyAssetGroupAssetsMutation.isSuccess) {
      snackbar.display({
        id: `assetGroupUpdated.${id}`,
        text: t('snackbar.assetsUpdated'),
        type: 'success',
      });
    }
  }, [modifyAssetGroupAssetsMutation.isSuccess, snackbar, t, id]);

  if (!assetGroup) {
    if (isLoading || isRefetching) {
      return <LoadingPage />;
    }
    return (
      <MissingPage
        to="/manage/asset-groups"
        title={t('missing.title')}
        description={t('missing.description')}
        linkText={t('missing.link')}
      />
    );
  }

  return (
    <Page
      title={assetGroup.name}>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>
              {permissions.canEditAssetGroups ? t('title', { name: assetGroup.name }) : assetGroup.name}
            </Typography>
            <FeaturePageStaffAccessAlert feature="manage.assetGroups" />
          </Box>

          {permissions.canEditAssetGroups && (
            <SettingsSection title={t('basicDetails.title')}>
              <DetailPanel spacing={3}>
                <Stack spacing={3} px={3}>
                  <Stack spacing={3} direction="row" height="4em">
                    <TextField
                      label={t('basicDetails.name')}
                      value={groupName ?? assetGroup.name}
                      onChange={handleRenameChange}
                      error={groupNameValid?.valid === false}
                      helperText={groupNameValid?.helperText ? t(`basicDetails.${groupNameValid.helperText}`) : ''}
                      fullWidth
                    />
                  </Stack>
                  <Stack spacing={3} direction="row" justifyContent="flex-end" height="4em">
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{ minWidth: '10rem' }}
                      disabled={!canSave || renameGroupMutation.isPending}
                      onClick={handleRenameCancel}
                    >
                      {t('basicDetails.cancel')}
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ minWidth: '10rem' }}
                      disabled={!canSave || renameGroupMutation.isPending}
                      onClick={handleRenameSubmit}
                    >
                      {t(renameGroupMutation.isPending ? 'basicDetails.saving' : 'basicDetails.save')}
                    </Button>
                  </Stack>
                </Stack>
              </DetailPanel>
            </SettingsSection>
          )}

          <SettingsSection
            title={t('assets.title')}
            description={t('assets.description')}
          >
            {assetGroup.assets.length ? renderAssets() : renderNoAssetView()}
          </SettingsSection>
        </Container>
      </SettingsMenuPageWrapper>

      <AssignAssetGroupDialog
        title={t('manage.title', { group: assetGroup.name })}
        isReady={!modifyAssetGroupAssetsMutation.isPending}
        ariaLabel="Assign assets to group"
        assetDevicesPairs={assetsAndDevices}
        onClose={() => setIsAssignDevicesDialogOpen(false)}
        onSubmit={handleAssignAssets}
        open={isAssignDevicesDialogOpen}
      />
    </Page>
  );
};

export default EditAssetGroupsPage;
