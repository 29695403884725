import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  label: {
    fontSize: '.6rem',
    fontWeight: '400',
    textTransform: 'uppercase',
    marginBottom: 4
  },
  totalWrapper: {
    flex: 1,
    padding: theme.spacing(1, 2),
    marginBottom: 8,
    textAlign: 'left',
    backgroundColor: theme.palette.primary.main,
    border: theme.border.default,
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
    '& p': {
      color: theme.palette.common.white
    }
  }
}));
