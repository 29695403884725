export enum ShareRangeType {
  AllTime = 'AllTime',
  OpenEnded = 'OpenEnded',
  SpecificRange = 'SpecificRange',
}

export interface TemporalShareBase {
  id: number
  version: number
  organisationId: string
  organisationName: string
  deviceId: number
  assetId: number
  notes: string | null
}

export interface TemporalGroupShareBase extends TemporalShareBase {
  groupId: string
  groupName: string
}

export interface TemporalSharePermissions {
  canViewCurrent: boolean
  canViewHistory: boolean
  canSendConfiguration: boolean
  canSendTextMessages: boolean
  canViewForms: boolean
  canEditCallSign: boolean
}

export interface AllTime {
  shareRangeType: ShareRangeType.AllTime
  shareStart: null
  shareEnd: null
}

export interface OpenEnded {
  shareRangeType: ShareRangeType.OpenEnded
  shareStart: string
  shareEnd: null
}

export interface SpecificRange {
  shareRangeType: ShareRangeType.SpecificRange
  shareStart: string
  shareEnd: string
}

export type TemporalShare = TemporalShareBase & TemporalSharePermissions & (AllTime | OpenEnded | SpecificRange);

export type TemporalGroupShare = TemporalGroupShareBase & TemporalSharePermissions & (AllTime | OpenEnded | SpecificRange);

export interface TemporalSharesResult {
  shares: TemporalShare[]
  groupShares: TemporalGroupShare[]
  selfShares: TemporalShare[]
}

export type CreateSharesBody = Omit<TemporalShareBase, 'id' | 'assetId' | 'deviceId' | 'version' | 'organisationId' | 'organisationName'>
  & TemporalSharePermissions
  & (AllTime | OpenEnded | SpecificRange)
  & { shareToOrganisationId: string, deviceIds: number[] };

export type CreateGroupSharesBody = Omit<TemporalGroupShareBase, 'id' | 'assetId' | 'deviceId' | 'version' | 'organisationId' | 'organisationName' | 'groupId' | 'groupName'>
  & TemporalSharePermissions
  & (AllTime | OpenEnded | SpecificRange)
  & { shareToGroupId: string, deviceIds: number[] };

export type UpdateShareBody = Pick<TemporalShareBase, 'id' | 'version' | 'notes' | 'organisationId'>
  & TemporalSharePermissions
  & (AllTime | OpenEnded | SpecificRange);

export type UpdateGroupShareBody = Pick<TemporalGroupShareBase, 'id' | 'version' | 'notes' | 'groupId'>
  & TemporalSharePermissions
  & (AllTime | OpenEnded | SpecificRange);

export type DeleteShareBody = Pick<TemporalShare, 'id' | 'organisationId'>;

export type DeleteGroupShareBody = Pick<TemporalGroupShareBase, 'id' | 'groupId'>;
