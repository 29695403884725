import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { AdsbAircraft } from 'apis/adsb/types';
import { DateTime } from 'luxon';
import { useCoordinates } from 'hooks/settings';
import Speed from 'components/shared/speed';
import Course from 'components/shared/course';
import DirectionIcon from 'components/shared/icons/directionIcon';
import { useSpeedByCategory } from 'hooks/units/useSpeed';
import { OverlayWrapper, DetailsGrid, ScoreCard } from './common';

interface AdsbOverlayProps {
  aircraft?: AdsbAircraft
  highContrast: boolean
}

export const AdsbDetailsOverlay = ({ aircraft, highContrast }: AdsbOverlayProps) => {
  const speedUnit = useSpeedByCategory('Air');
  const coordinates = useCoordinates(aircraft?.lat, aircraft?.lon);
  return (
    (aircraft) ? (
      <OverlayWrapper highContrast={highContrast}>
          <Stack px={3} pt={2} spacing={1} direction="row">
            <Typography variant="h3">{aircraft.r || aircraft.flight || aircraft.hex || '--'}</Typography>
            <Box flex={1} />
            <DirectionIcon style={{ transition: 'all 0.15s', transform: `rotate(${aircraft?.track || '0'}deg)` }} fillColor="rgba(0,0,0,0.5)" circle />
          </Stack>
          <DetailsGrid withoutTopBorder>
            <ScoreCard bold label="Transport" value="ADS-B" />
            <ScoreCard bold label="Flight" value={aircraft.flight} />
          </DetailsGrid>
          <DetailsGrid>
            <ScoreCard label="Squawk" value={aircraft.squawk} />
            <ScoreCard label="Category" value={aircraft.category} />
            <ScoreCard label="Type" value={aircraft.t} />
          </DetailsGrid>
          <DetailsGrid>
            <ScoreCard wide label="Time of Fix" value={DateTime.fromMillis(aircraft.timeOfFix).toISO()} />
            <ScoreCard wide label="Coordinates" value={coordinates} />
            <ScoreCard label="Altitude (Baro)" value={aircraft.alt_baro} />
            <ScoreCard label="Air Speed">
              <Speed unit={speedUnit} speedInKmh={aircraft.tas ? aircraft.tas / 1.944 : undefined} />
            </ScoreCard>
            <ScoreCard label="Ground Speed">
              <Speed unit={speedUnit} speedInKmh={aircraft.gs ? aircraft.gs / 1.944 : undefined} />
            </ScoreCard>
            <ScoreCard label="Track">
              {aircraft.track && <Course courseInDegreesTrue={aircraft.track} timestamp={aircraft.timeOfFix} position={[aircraft.lon, aircraft.lat]} />}
            </ScoreCard>
          </DetailsGrid>
      </OverlayWrapper>
    ) : null
  );
};
