import { useMemo } from 'react';
import { useGetPeople } from 'apis/rest/people/hooks';
import validateName, { ValidateNameResult } from 'helpers/validation/validateName';

export interface UseValidateNameResult extends ValidateNameResult {
  isLoading: boolean
}

const useValidateName = (person: Person | null, name: string | undefined): UseValidateNameResult => {
  const { query } = useGetPeople();

  const existingNames = useMemo(
    () => query.data?.filter(person ? p => p.id !== person.id : p => !p.temporary).map(p => p.name) ?? [],
    [query.data, person],
  );

  return {
    ...validateName(name, person?.name ?? '', existingNames),
    isLoading: query.isLoading,
  };
};

export default useValidateName;
