import React, { ComponentProps, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack, TextField,
} from '@mui/material';
import { useGetPeople, useMutateUpdateContact } from 'apis/rest/people/hooks';
import { useAppDispatch } from 'store/types';
import { displaySnackbar } from 'slices/app.slice';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export enum EditPersonContactDialogStatus {
  Cancelled = 'cancelled',
  Saved = 'saved',
}

export interface Validation {
  valid: boolean
  missing?: boolean
  changed?: boolean
  helperText?: React.ReactNode
}

export type Validator = (nextValue: string, currentValue: string | null) => Validation;

interface InputComponentProps extends ComponentProps<typeof TextField> {
  label?: string
  value: string
  existingValue?: string
  setValue: (value: string) => void
  validation: Validation
  allowMissing?: boolean
}
export type InputComponent = (props: InputComponentProps) => JSX.Element;

interface EditPersonContactDialogProps {
  open: boolean
  onClose: (status: EditPersonContactDialogStatus, personId: number, contactId: number) => void
  validate: Validator
  personId?: number
  contactId?: number
  components: { Input: InputComponent }
}

const EditPersonContactDialog = ({
  open,
  onClose,
  personId,
  contactId,
  validate,
  components: { Input },
}: EditPersonContactDialogProps): JSX.Element | null => {
  const t = useTranslations('dialogs.people.editPersonContact');
  const dispatch = useAppDispatch();
  const { query: peopleQuery } = useGetPeople();
  const mutation = useMutateUpdateContact();

  const person = peopleQuery.data?.find(p => p.id === personId);
  const contact = person?.contacts.find(c => c.id === contactId);

  const [nextValue, setNextValue] = useState<string>();
  const [defaultChecked, setDefaultChecked] = useState<boolean>();

  const handleClose = (status: EditPersonContactDialogStatus): void => {
    if (personId !== undefined && contactId !== undefined) onClose(status, personId, contactId);
  };

  const handleCancel = (): void => {
    setNextValue(contact?.contactValue);
    handleClose(EditPersonContactDialogStatus.Cancelled);
  };

  const handleEdit = (): void => {
    if (!contact) return;
    const contactValue = nextValue ?? contact.contactValue;
    const isDefault = defaultChecked ?? contact.isDefault;
    if (isDefault === contact.isDefault && contactValue === contact.contactValue) return;

    mutation.mutate(
      { ...contact, contactValue, isDefault },
      {
        onSuccess: () => {
          dispatch(displaySnackbar({
            id: `personContactEdited.${personId}.${contactId}`,
            text: t('snackbar.editedSuccessfully', { value: contactValue, type: contact.contactType }),
            type: 'success',
          }));
          handleClose(EditPersonContactDialogStatus.Saved);
        },
        onError: error => {
          if (error.response.status === 409) {
            setDefaultChecked(undefined);
            setNextValue(undefined);
          }
        },
      },
    );
  };

  const onExited = (): void => {
    mutation.reset();
    setDefaultChecked(undefined);
    setNextValue(undefined);
  };

  if (!contact) return null;

  const type = contact.contactType;
  const validation = validate(nextValue ?? contact.contactValue, contact.contactValue);
  const canSave = person && contact && !peopleQuery.isLoading && !mutation.isPending && validation.valid && (validation.changed || defaultChecked);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isPending) handleCancel();
      }}
      aria-labelledby={type ? t('ariaLabel', { type }) : undefined}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{t('title', { type })}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          <Input
            value={nextValue ?? contact.contactValue}
            existingValue={contact.contactValue}
            setValue={setNextValue}
            validation={validation}
            disabled={mutation.isPending}
          />
          <FormGroup>
            <FormControlLabel
              disabled={contact.isDefault}
              control={(
                <Checkbox
                  checked={defaultChecked ?? contact.isDefault}
                  onChange={event => setDefaultChecked(event.target.checked)}
                />
              )}
              label={t('setDefault', { type })}
            />
          </FormGroup>
          {mutation.isError && (
            <Alert severity="error">
              {mutation.error?.response?.status === 409
                ? t.rich('errorEditing409', { type, br: () => <br /> })
                : t('errorEditing', { type })}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isPending}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ minWidth: '10rem' }}
            disabled={!canSave}
            onClick={handleEdit}
          >
            {t('save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default EditPersonContactDialog;
