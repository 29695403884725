import React from 'react';

const Drone = ({
  fillColor,
  outlineOpacity,
  outlineColor,
}) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{ paintOrder: 'markers stroke fill' }}
      d="m13 35 5.72-11.224L13.44 13 24 18.388 34.56 13l-5.28 10.776L35 35l-11-5.837L13 35Z"
    />
    <path
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{ paintOrder: 'markers stroke fill' }}
      d="M18 35a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
    />
    <path
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{ paintOrder: 'markers stroke fill' }}
      d="M40 35a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
    />
    <path
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{ paintOrder: 'markers stroke fill' }}
      d="M40 13a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
    />
    <path
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{ paintOrder: 'markers stroke fill' }}
      d="M18 13a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
    />
  </svg>
);

export default Drone;
