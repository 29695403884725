import React from 'react';
import { Stack, Typography } from '@mui/material';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import useSerialType from 'hooks/settings/useSerialType';

export interface AssetDeviceLabelProps {
  device?: Pick<DeviceBasic, 'make' | 'model' | 'tpSerial' | 'imei' | 'tracPlusSerial' | 'manufacturerSerial'>;
  asset?: Pick<AssetBasic, 'id' | 'make' | 'model' | 'variant'>;
}

export const AssetDeviceLabel = ({ device, asset }: AssetDeviceLabelProps): JSX.Element => {
  const serialType = useSerialType();

  return (
    <Stack direction="row" flex={1} alignItems="center">
      <Stack width="60%" direction="row" spacing={2} flex={1} alignItems="center">
        <AssetColourMarker assetId={asset?.id} />
        <Stack>
          <Typography>
            <AssetLabel asset={asset} />
          </Typography>
          <Typography variant="body3">{asset?.make} {asset?.model} {asset?.variant}</Typography>
        </Stack>
      </Stack>
      <Stack width="40%">
        <Typography>{device?.make} {device?.model}</Typography>
        <Typography>{device?.[serialType]}</Typography>
      </Stack>
    </Stack>
  );
};
