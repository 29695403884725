import { CircularProgress, Box } from '@mui/material';
import React from 'react';

interface LoadingIconProps {
  size: number
}

export const LoadingIcon = ({ size = 100 }: LoadingIconProps): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <CircularProgress sx={{ height: size, width: size, margin: 'auto' }} />
  </Box>

);
