import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  expandLegButton: {
    backgroundColor: theme.palette.background.lightGrey,
    padding: '2px 6px 0 0',
    textAlign: 'left',
  },
  legWrapper: {
    textAlign: 'left',
    border: theme.border.default,
    borderRadius: 4,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.common.text
    },
    transition: theme.transitions.create(['border-color'])
  },
  selectedLeg: {
    borderColor: theme.palette.primary.main
  },
  legReportsWrapper: {
    position: 'relative',
    padding: '6px 12px 6px 10px',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: theme.spacing(0)
    },
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 24,
      borderRight: theme.border.default,
      borderRadius: 2,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: theme.palette.common.lightGrey
    }
  },
  legCollapsed: {
    height: 0,
    overflow: 'hidden',
    padding: 0,
  },
  list: {
    textAlign: 'left',
    position: 'relative',
    padding: theme.spacing(0)
  },
  listItem: {
    padding: 0,
    marginTop: 8,
    marginBottom: 8
  },
  iconWrapper: {
    position: 'relative',
    minWidth: 38,
    height: 28,
    '& svg': {
      position: 'absolute',
      top: 3,
      bottom: 3,
      left: 3,
      width: 22,
      height: 22,
      zIndex: 1,
      '& .icon-base': {
        transition: theme.transitions.create(['fill']),
        fill: theme.palette.background.overlay
      },
      '& .icon-detail': {
        transition: theme.transitions.create(['fill']),
        fill: theme.palette.common.text
      }
    },
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 28,
      backgroundColor: theme.palette.contextbox.background,
      borderRadius: '50%',
      zIndex: 0
    },
    '&:hover': {
      '& svg': {
        '& .icon-base': {
          fill: theme.palette.common.black
        },
        '& .icon-detail': {
          fill: theme.palette.common.white
        }
      }
    }
  },
  icon: {
    cursor: 'pointer',
    '& path': {
      fill: theme.palette.common.text
    },
  },
  activeIcon: {
    '& path': {
      fill: theme.palette.primary.main
    }
  },
  listText: {
    color: theme.palette.common.text,
    width: '100%',
    margin: theme.spacing(0),
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  },
  groupedListText: {
    marginTop: 0,
    marginBottom: 0
  },
  reportTitle: {
    fontSize: '.8rem',
    fontWeight: 400,
    textAlign: 'left',
    flex: 1,
    marginRight: 'auto'
  },
  groupedEventName: {
    paddingLeft: 38,
    fontSize: '.8rem'
  },
  reportSince: {
    fontSize: '.8rem',
    fontWeight: 400,
    textAlign: 'right'
  },
  selected: {
    '& svg': {
      '& .icon-base': {
        fill: theme.palette.common.black
      },
      '& .icon-detail': {
        fill: theme.palette.common.white
      }
    }
  },
  locationsData: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(1, 2),
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    '& p': {
      fontSize: '.8rem'
    }
  },
  openAnalysisButton: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: '2px 6px 0 0',
    textAlign: 'right',
  },
  legDataWrapper: {
    padding: theme.spacing(1, 2)
  },
  label: {
    fontSize: '.6rem',
    fontWeight: '400',
    textTransform: 'uppercase',
    marginBottom: 4
  },
  data: {
    fontSize: '.9rem'
  },
  borderRight: {
    borderRight: theme.border.default
  },
  divider: {
    borderColor: theme.border.color
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  formLink: {
    fontSize: '.7rem',
    cursor: 'pointer',
    textDecoration: theme.typography.linkUnderline,
    marginTop: 2
  },
  formBadge: {
    position: 'absolute',
    left: 16,
    bottom: -6,
    width: 18,
    height: 18,
    zIndex: 1
  },
  badgeIconWrapper: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      width: 12,
      height: 12,
      top: 3,
      bottom: 3,
      left: 3
    },
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 18,
      height: 18,
      backgroundColor: theme.palette.contextbox.background,
      borderRadius: '50%',
      zIndex: 0
    }
  }
}));
