export const download = (filename: string, value: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', value);
  element.setAttribute('download', filename);
  document.body.appendChild(element); // Required for this to work in Firefox
  element.click();
  document.body.removeChild(element);
};

export const downloadCSV = (name: string, content: string) => download(
  `${name}.csv`,
  `data:text/csv;charset=utf-8,${encodeURI(content)}`,
);
