import React, { ReactNode, useEffect, useState } from 'react';
import CompatibilityPage from './compatibility';
import { compatibilityCheck } from './utils';

interface CompatibilityGuardProps {
  children: ReactNode
}

const CompatibilityGuard = ({ children }: CompatibilityGuardProps) => {
  const [ignore, setIgnore] = useState(() => window.sessionStorage.getItem('browserRequirements.ignore') === '1');

  useEffect(() => {
    window.sessionStorage.setItem('browserRequirements.ignore', ignore ? '1' : '0');
  }, [ignore]);

  // render the warning page if not compatible and not ignored
  if (!ignore && !compatibilityCheck()) return <CompatibilityPage ignore={() => setIgnore(true)} />;

  // otherwise render the given children
  return children;
};

export default CompatibilityGuard;
