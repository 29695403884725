import { createContext } from 'react';
import type { AssetModule } from 'apis/rest/featureAssets/types';

export interface FeatureAssetsContextValue {
  isLoading: boolean
  data: AssetModule[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const FeatureAssetsContext = createContext<FeatureAssetsContextValue>({ isLoading: true, data: [] });
