import React from 'react';
import clsx from 'clsx';
import { Stack, Tooltip } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import AssetColourMarker from 'components/shared/assetColourMarker';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import useStyles from './participantNameListItem-styles';

interface ParticipantNameListItemProps {
  participant: Participant;
  addComma: boolean;
  showDetails?: boolean;
  asset: AssetBasic | undefined
}

const ParticipantNameListItem = ({
  participant,
  addComma,
  showDetails = false,
  asset,
}: ParticipantNameListItemProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('shared.staffIndicator');
  const t2 = useTranslation('contextbox.asset.details');
  const assetLabel = useAssetLabel();
  const defaultSerialType = useUiSettings().serialType;

  /* this uses whitespace: nowrap to ensure the name is not broken up */
  const renderName = (p: Participant): JSX.Element => {
    const details = [asset?.make, asset?.model, asset?.variant].filter((s): s is string => !!s);
    const makeModel = showDetails && details.length ? `(${details.join(' ')})` : '';
    const serial = p[defaultSerialType];
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {p.type === 'asset' && <AssetColourMarker deviceId={p.deviceId} />}
        <span className={clsx(classes.noBreak, { [classes.blueStaff]: p.isStaff })}>
          {`${assetLabel(asset)} ${makeModel}`}
        </span>
        {showDetails && (
          <>
            <br />
            <span className={clsx(classes.details)}>
              {`${t2(defaultSerialType)}: ${serial} ${t2('owner')}: ${p.ownerName}`}
            </span>
          </>
        )}
      </Stack>
    );
  };

  /* this component colours tracplus staff names blue and adds a tooltip */
  return (
    <React.Fragment key={participant.id}>
      {
        participant.isStaff
          ? (
            <Tooltip title={t('staffmember')} placement="bottom" arrow>
              {renderName(participant)}
            </Tooltip>
          )
          : renderName(participant)
      }
      { /* this is rendered separately so that line breaks work at end of names */
        addComma && <span>{', '}</span>
      }
    </React.Fragment>
  );
};

export default ParticipantNameListItem;
