import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    display: 'block',
    position: 'relative',
    margin: '0px',
    height: '160px',
    minHeight: '160px',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.inputBackground,
  },
  loading: {
    lineHeight: 19,
  },
  image: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  ccIcon: {
    width: 20,
    position: 'absolute',
    left: 10,
    top: 13,
    opacity: 0.65,
  },
}));
