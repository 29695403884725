import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const ManageIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" width="16" height="16" viewBox="0 0 16 16" className={classes.navIcon}>
      <g>
        <path d="M14.17,8.05H1.83C0.82,8.05,0,8.87,0,9.88l0,0c0,1.01,0.82,1.83,1.83,1.83h12.34c1.01,0,1.83-0.82,1.83-1.83l0,0
                C16,8.87,15.18,8.05,14.17,8.05z M2.58,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98
                S3.12,10.86,2.58,10.86z M5.02,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S5.57,10.86,5.02,10.86z
                M10.98,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98C11.97,10.42,11.52,10.86,10.98,10.86z
                M13.42,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S13.97,10.86,13.42,10.86z"
        />
        <path d="M1.83,7.78h12.34c0.3,0,0.57,0.07,0.82,0.17L11.82,4.8C11.49,4.47,11.06,4.3,10.6,4.3H5.41c-0.46,0-0.9,0.18-1.22,0.51
                L1,7.96C1.25,7.84,1.53,7.78,1.83,7.78z"
        />
      </g>
    </SvgIcon>
  );
};

export default ManageIcon;
