import { styled } from '@mui/material/styles';
import { Box, Stack, Theme, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';

interface ScoreCardProps {
  label: string,
  value?: string | number,
  bold?: boolean,
  wide?: boolean,
  maxValueLines?: number
}

export const ScoreCard: React.FC<PropsWithChildren<ScoreCardProps>> = ({ label, value = '—', bold = false, wide = false, children, maxValueLines = 0 }) => (
  <Stack spacing={-0.8} gridColumn={wide ? 'span 2' : undefined}>
    <Typography variant="overline" sx={{ fontSize: '0.6rem' }}>{label}</Typography>
    {children
      || (
        <Typography fontSize="0.9rem" sx={{
          ...({
            overflowWrap: 'break-word'
          }),
          ...(bold
            ? { fontWeight: '500' }
            : undefined),
          ...(maxValueLines > 0
            ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: `${maxValueLines}`,
              WebkitBoxOrient: 'vertical',
            }
            : undefined)
        }}>{value}
        </Typography>
      )}
  </Stack>
);

interface DetailsGridProps {
  withoutTopBorder?: boolean
}

export const DetailsGrid = styled(Box)<DetailsGridProps>(({ withoutTopBorder = false, theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(1),
  gridTemplateColumns: '1fr 1fr',
  padding: theme.spacing(2, 3, 2, 3),
  borderTop: !withoutTopBorder ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
}));

interface OverlayHeaderProps {
  withTopBorder?: boolean
}

export const OverlayHeader = styled(Box)<OverlayHeaderProps>(({ withTopBorder = false, theme }) => ({
  padding: theme.spacing(2, 3, 2, 3),
  borderTop: withTopBorder ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
}));

export const OverlayWrapper: React.FC<PropsWithChildren<{ highContrast: boolean, sx?: SxProps<Theme> }>> = ({ highContrast, sx, children }) => (
  <Box sx={theme => ({
    backgroundColor: highContrast ? theme.palette.mapControls.bgHighContrast : 'rgba(0,0,0,0)',
    borderRadius: 1,
    outline: '1px solid rgba(255,255,255,.2)',
    '& .MuiTypography-root, .MuiSvgIcon-root': {
      color: theme.palette.common.whiteConstant,
    },
    textAlign: 'left',
    color: 'white'
  })}>
    <Box sx={{
      backgroundColor: 'rgba(0,0,0,0.4)',
      borderRadius: 1,
      ...sx,
    }}>
      {children}
    </Box>
  </Box>
);
