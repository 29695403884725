import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import ColorInput from 'components/shared/forms/inputs/color';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import validateLatLong from 'helpers/validation/validateLatLong';
import { useCoordFormat } from 'components/pages/manage/markers/hooks/useCoordFormat';
import PointIcon, { PointIconType } from 'components/shared/icons/annotations/pointIcon';
import { DEFAULT_POINT, AnnotationPoint } from 'slices/map/annotations.slice';
import Dms from 'geodesy/dms';

interface EditPointAnnotationDialogProps {
  open: boolean
  title: string
  point?: AnnotationPoint
  onClose: () => void
  onSubmit: (point: AnnotationPoint) => void
  isLoading: boolean
}

const WrapGeoCoordinates = (location: GeographicCoordinates): GeographicCoordinates => ({
  latitude: Number(Dms.wrap90(location.latitude ?? 0)),
  longitude: Number(Dms.wrap180(location.longitude ?? 0)),
  altitude: location.altitude
});

export const EditPointAnnotationDialog = ({
  open,
  title,
  point,
  onClose,
  onSubmit,
  isLoading
}: EditPointAnnotationDialogProps) => {
  const t = useTranslations('dialogs.annotations');

  const [name, setName] = useState<string>(point?.name ?? DEFAULT_POINT.name);
  const [description, setDescription] = useState<string>(point?.description ?? DEFAULT_POINT.description);
  const [icon, setIcon] = useState<PointIconType>(point?.type ?? DEFAULT_POINT.type);
  const [colour, setColour] = useState<string>(point?.colour ?? DEFAULT_POINT.colour);
  const [pendingMarkerPosition, setPendingMarkerPosition] = useState<GeographicCoordinates>(WrapGeoCoordinates(point?.location ?? DEFAULT_POINT.location));

  const coordinateValidation = useMemo(
    () => validateLatLong(pendingMarkerPosition),
    [pendingMarkerPosition]
  );

  const validationResult = useMemo(() => {
    const value = {
      name: !name ? t('errors.name') : (name.length > 20 ? t('errors.nameLength') : undefined),
      description: description && description.length > 250 ? t('errors.descriptionLength') : undefined,
      latitude: !coordinateValidation.valid && (coordinateValidation.field === 'latitude' || coordinateValidation.field === 'both') ?
        t('errors.latitude') : undefined,
      longitude: !coordinateValidation.valid && (coordinateValidation.field === 'longitude' || coordinateValidation.field === 'both') ?
        t('errors.longitude') : undefined,
      any: false
    };
    value.any = !!value.name || !!value.description || !!value.latitude || !!value.longitude;
    return value;
  }, [name, description, t, coordinateValidation]);

  const handleSubmit = useCallback(() => {
    if (validationResult.any) {
      return;
    }

    onSubmit({
      ...DEFAULT_POINT,
      ...point,
      name,
      description,
      colour,
      location: pendingMarkerPosition,
      type: icon
    });
  }, [onSubmit, point, validationResult, name, description, colour, icon, pendingMarkerPosition]);

  const onEnter = () => {
    setName(point?.name ?? DEFAULT_POINT.name);
    setDescription(point?.description ?? DEFAULT_POINT.description);
    setIcon(point?.type ?? DEFAULT_POINT.type);
    setColour(point?.colour ?? DEFAULT_POINT.colour);
    setPendingMarkerPosition(WrapGeoCoordinates(point?.location ?? DEFAULT_POINT.location));
  };

  const onExited = () => {
    setName('');
    setDescription('');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onEnter,
        onExited,
      }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('fields.name')}
            value={name}
            onChange={event => setName(event.target.value)}
            error={!!validationResult.name}
            helperText={validationResult.name}
            fullWidth
          />
          <TextField
            label={t('fields.description')}
            value={description}
            onChange={event => setDescription(event.target.value)}
            error={!!validationResult.description}
            helperText={validationResult.description}
            multiline
            fullWidth
          />
          <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="top">
            <LatitudeInput
              label={t('fields.latitude')}
              value={pendingMarkerPosition?.latitude}
              onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, latitude: value })}
              error={!!validationResult.latitude}
              helperText={validationResult.latitude}
              displayFormat={useCoordFormat()}
              fullWidth
            />
            <LongitudeInput
              label={t('fields.longitude')}
              value={pendingMarkerPosition?.longitude}
              onChangeValue={value => setPendingMarkerPosition({ ...pendingMarkerPosition, longitude: value })}
              error={!!validationResult.longitude}
              helperText={validationResult.longitude}
              displayFormat={useCoordFormat()}
              fullWidth
            />
          </Stack>
          <TextField
            select
            label={t('fields.icon')}
            value={icon}
            onChange={event => setIcon(event.target.value as PointIconType)}
            fullWidth
          >
            {['circle', 'pin'].map(option => (
              <MenuItem key={option} value={option}>
                {/* {t(`common.fields.icon.options.${option as PointIconType}`)} */}
                {`${option as PointIconType}`}
              </MenuItem>
            ))}
          </TextField>
          <ColorInput
            palette={SUGGESTED_ASSET_COLORS}
            value={colour}
            onChange={setColour}
            preview={(
              <PointIcon type={icon} fill={colour} />
            )}
            readOnly={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>{t('actions.cancel')}</Button>
        <Button onClick={handleSubmit} disabled={isLoading}>{t('actions.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPointAnnotationDialog;
