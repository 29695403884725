import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import destination from '@turf/destination';
import distance from '@turf/distance';
import { Feature, LineString, Units } from '@turf/helpers';
import { distanceRing } from 'utils/distanceRing';
import { useMemo } from 'react';
import WebMercatorViewport from '@math.gl/web-mercator';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

const WHITE: ColorAlpha = [255, 255, 255, 255];
const SHADOW: ColorAlpha = [0, 0, 0, 128];
const EMPTY = [] as const;

const triplicateCircles = (features: Feature<LineString>[]) => features.flatMap(({ bbox, ...feature }) => [
  {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: feature.geometry.coordinates.map(([lon, lat]) => ([lon - 360, lat])),
    }
  },
  feature,
  {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: feature.geometry.coordinates.map(([lon, lat]) => ([lon + 360, lat])),
    }
  },
]);

interface TextItem {
  name: string
  coordinates: [number, number]
  alignmentBaseline: 'top' | 'bottom'
}

const triplicateText = (items: TextItem[]): TextItem[] => items.flatMap(item => [
  { ...item, coordinates: [item.coordinates[0] - 360, item.coordinates[1]] },
  item,
  { ...item, coordinates: [item.coordinates[0] + 360, item.coordinates[1]] },
]);

const mapDistanceUnits: Record<'kilometres' | 'statuteMiles' | 'nauticalMiles' | 'metres' | 'feet', Units> = {
  kilometres: 'kilometers',
  statuteMiles: 'miles',
  nauticalMiles: 'nauticalmiles',
  metres: 'meters',
  feet: 'feet',
};

const distanceLabel = (n: number, units: Units) => {
  switch (units) {
    case 'kilometers': return `${n} km`;
    case 'miles': return `${n} mi`;
    case 'nauticalmiles': return `${n} nm`;
    case 'meters': return `${n} m`;
    case 'feet': return `${n} ft`;
    default: return `${n} ${units}`;
  }
};

const niceStep = (viewportDistance: number) => {
  const n = Math.min(viewportDistance, 10000) / 10;
  if (n >= 800) return 1000;
  if (n >= 400) return 500;
  if (n >= 200) return 200;
  if (n >= 100) return 100;
  if (n >= 45) return 50;
  if (n >= 20) return 20;
  if (n >= 10) return 10;
  if (n >= 4.5) return 5;
  if (n >= 2) return 2;
  if (n >= 1) return 1;
  return 0.5;
};

const useDistanceRingLayers = (
  visible: boolean,
  wmViewport: WebMercatorViewport,
  report: Pick<Report, 'longitude' | 'latitude'> | undefined,
  use3d = false
) => {
  const distanceUnit = useUnitSettings().distance;
  const units = mapDistanceUnits[distanceUnit];

  const center = useMemo(() => {
    if (!report) return undefined;
    return [report.longitude, report.latitude] as [number, number];
  }, [report]);

  const step = useMemo(() => {
    const topLeft = wmViewport.unproject([0, 0]);
    const bottomRight = wmViewport.unproject([wmViewport.height, wmViewport.width]);
    return niceStep(distance(topLeft, bottomRight, { units }));
  }, [wmViewport, units]);

  const distances = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => n * step), [step]);

  const circles = useMemo(() => {
    if (!center) return EMPTY;
    return triplicateCircles(distances.map(d => distanceRing(center, d, units, 128)));
  }, [distances, center, units]);

  const text = useMemo(() => {
    if (!center) return EMPTY;
    return triplicateText(distances.flatMap(d => {
      const name = distanceLabel(Math.round(d * 10) / 10, units);
      return [
        {
          name,
          coordinates: destination(center, d, 0, { units }).geometry.coordinates as [number, number],
          alignmentBaseline: 'bottom',
        },
        {
          name,
          coordinates: destination(center, d, 180, { units }).geometry.coordinates as [number, number],
          alignmentBaseline: 'top',
        },
      ];
    }));
  }, [distances, center, units]);

  const layers = useMemo(() => [
    new GeoJsonLayer({
      id: `distance-rings-circle-shadow-layer-${use3d ? '3d' : '2d'}`,
      data: circles,
      stroked: true,
      filled: false,
      pointType: 'circle',
      lineWidthMinPixels: 3,
      getLineColor: SHADOW,
      parameters: { depthTest: false },
      visible,
    }),
    new GeoJsonLayer({
      id: `distance-rings-circle-layer-${use3d ? '3d' : '2d'}`,
      data: circles,
      stroked: true,
      filled: false,
      pointType: 'circle',
      lineWidthMinPixels: 2,
      getLineColor: WHITE,
      parameters: { depthTest: false },
      visible,
    }),
    new TextLayer<TextItem>({
      id: `distance-rings-label-layer-${use3d ? '3d' : '2d'}`,
      data: text,
      getText: d => d.name,
      fontWeight: 600,
      getPosition: d => d.coordinates,
      getSize: 12,
      getColor: WHITE,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: d => d.alignmentBaseline,
      getPixelOffset: d => [0, d.alignmentBaseline === 'top' ? 2 : 0],
      visible,
      parameters: { depthTest: false },
      fontSettings: { sdf: true, fontSize: 30, radius: 12 },
      fontFamily: 'objektiv-mk2,sans-serif',
      outlineColor: SHADOW,
      outlineWidth: 4,
    }),
  ] as const, [use3d, visible, circles, text]);

  return layers;
};

export default useDistanceRingLayers;
