import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  submitButton: {
    fontSize: '1.1rem',
    textTransform: 'capitalize',
    padding: '12px',
    boxShadow: 'none',
    marginTop: '30px',
    marginBottom: '10px',
    width: '-webkit-fill-available',
    maxHeight: 49,
  }
}));
