import React, { ComponentProps } from 'react';
import { useTranslations } from 'use-intl';
import {
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { Outlet, useMatch, useResolvedPath } from 'react-router';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LinkTabs from 'components/shared/linkTabs';
import { Navigate } from 'react-router-dom';
import { useStaff } from 'hooks/session/useStaff';

const SharingPageLayout = (): JSX.Element => {
  const t = useTranslations('pages.sharing');
  const isStaff = useStaff();

  const fromPath = useResolvedPath('from');
  const toPath = useResolvedPath('to');
  const selfPath = useResolvedPath('self');
  const matchFrom = useMatch(fromPath.pathname);
  const matchTo = useMatch(toPath.pathname);
  const matchSelf = useMatch(selfPath.pathname);

  const items: ComponentProps<typeof LinkTabs>['items'] = [
    {
      value: fromPath.pathname,
      to: fromPath.pathname,
      label: t('tabs.sharedFrom'),
    },
    {
      value: toPath.pathname,
      to: toPath.pathname,
      label: t('tabs.sharedTo'),
    },
  ];

  if (isStaff) {
    items.push({
      value: selfPath.pathname,
      to: selfPath.pathname,
      label: t('tabs.sharedSelf'),
    });
  }

  // Redirect non-staff from self shares to shares from
  if (!isStaff && matchSelf) return <Navigate to={fromPath.pathname} replace />;

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} id="pageScrollParent">
        <Container maxWidth={false}>
          <Box>
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
          </Box>

          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={3} flex={1} height="4rem">
              {matchFrom || matchTo || matchSelf ? (
                <LinkTabs
                  label=""
                  value={matchFrom?.pathname ?? matchTo?.pathname ?? matchSelf?.pathname ?? ''}
                  items={items}
                />
              ) : null}
            </Stack>
          </Stack>

          <Outlet />

        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default SharingPageLayout;
