import React, { ComponentProps, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { useTranslations } from 'use-intl';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { BuildCircle, HelpOutline, InfoOutlined } from '@mui/icons-material';
import { MTableToolbar, MaterialTableProps, Column } from '@material-table/core';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import insensitiveSort from 'utils/insensitiveSort';
import useSnackbar from 'hooks/useSnackbar';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { deviceStatus, DeviceStatusDescription } from 'constants/deviceStatus';
import {
  useGetDevicesList,
  useGetLatestDeviceConfigurations,
  useGetLatestFirmwareVersions,
} from 'apis/rest/devices/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { Settings, updateSetting } from 'slices/settings.slice';
import { parseRock7Configuration } from 'helpers/config';
import tableIcons from 'components/shared/icons/tableIcons';
import PersistentTable from 'components/shared/persistentTable';
import AssetColourMarker from 'components/shared/assetColourMarker';
import StickyPagination from 'components/shared/stickyPagination';
import TableColumnsPicker from 'components/shared/tableColumnsPicker';
import { useAssetLabel } from 'components/shared/assetLabel';
import useStyles from './devicesList-styles';
import useTimezone from "hooks/session/useTimezone";

interface RowData {
  owner: string;
  id: number
  imei: string | null
  tpSerial: string | null
  manufacturerSerial: string | null
  makeModel: string
  firmwareVersion: string | undefined
  firmwareOutdated: boolean
  status: string
  assetLabel: string | undefined
  assetMakeModel: string | undefined
  ownedByUser: boolean
  lastActive: string | undefined
}

const Toolbar = (props: ComponentProps<typeof MTableToolbar>) => {
  const classes = useStyles();
  const t = useTranslations('pages.devices');

  const columns = [
    { id: 'makeModel', label: t('makeModel'), always: true },
    { id: 'firmwareVersion', label: t('firmwareVersion.label') },
    { id: 'tpSerial', label: t('tpSerial') },
    { id: 'imei', label: t('imei') },
    { id: 'manufacturerSerial', label: t('manufacturerSerial') },
    { id: 'status', label: t('status') },
    { id: 'lastActive', label: t('lastActive') },
    { id: 'asset', label: t('assignedAsset') },
    { id: 'owner', label: t('owner') },
  ];

  return (
    <Stack direction="row" justifyContent="space-between" m={3} height="4em">
      <Stack direction="row" spacing={3}>
        <TableColumnsPicker settingsCategory="devicesTable" columns={columns} />
      </Stack>
      <Stack direction="row" spacing={3}>
        <MTableToolbar {...props} className={classes.toolbar} />
      </Stack>
    </Stack>
  );
};

const selectConfigs = (data: DeviceConfiguration[]) => data.reduce<Record<number, Rock7Configuration | undefined>>((acc, value) => {
  acc[value.deviceId] = parseRock7Configuration(value);
  return acc;
}, {});

const DeviceListPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslations('pages.devices');
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const organisationId = useOrganisationId();
  const tableSettings = useSelector<ReduxState, Settings['devicesTable']>(state => state.settings.devicesTable);
  const timezone = useTimezone();

  const assetsQuery = useGetAssetsList({ select: d => d }).query;
  const devicesQuery = useGetDevicesList().query;
  const configsQuery = useGetLatestDeviceConfigurations({
    enabled: tableSettings.columns?.firmwareVersion ?? false,
    select: selectConfigs,
  }).query;

  useEffect(() => {
    if (assetsQuery.isError) {
      snackbar.display({
        id: 'assetLoadError',
        text: assetsQuery.isError ? assetsQuery.error.toString() : '',
        type: 'error',
      });
    }
    if (devicesQuery.isError) {
      snackbar.display({
        id: 'assetLoadError',
        text: devicesQuery.isError ? devicesQuery.error.toString() : '',
        type: 'error',
      });
    }
  }, [assetsQuery, devicesQuery, snackbar]);

  const assetsById = useMemo(
    () => assetsQuery.data?.reduce<Record<AssetBasic['id'], AssetBasic>>((acc, asset) => {
      acc[asset.id] = asset;
      return acc;
    }, {}),
    [assetsQuery.data],
  );

  const assetLabel = useAssetLabel();

  const latestFirmwareVersions = useGetLatestFirmwareVersions({
    enabled: !!devicesQuery.data,
  });

  const cleanedDevices = useMemo<RowData[]>(() => {
    if (!assetsById || !devicesQuery.data) return [];
    return devicesQuery.data.map(({ id, imei, tpSerial, manufacturerSerial, make, model, assetId, status, lastActive }) => {
      const asset = assetsById[assetId];
      const statusDescription = (deviceStatus.find(s => s.id === status)?.description ?? DeviceStatusDescription.Unknown).toLowerCase() as Lowercase<DeviceStatusDescription>;
      const config = configsQuery.data?.[id];
      const firmwareVersion = config?.VersionInfoElement?.SoftwareVersion;
      const latestFirmware = latestFirmwareVersions.data?.find(fw => fw.make === make && fw.model === model)?.version;
      return {
        id,
        imei,
        tpSerial,
        manufacturerSerial,
        makeModel: [make, model].filter(Boolean).join(' '),
        firmwareVersion: firmwareVersion ?? (make === 'Rock7' ? t('firmwareVersion.unknown') : ''),
        firmwareOutdated: firmwareVersion !== undefined && firmwareVersion !== latestFirmware,
        status: t(statusDescription),
        assetLabel: assetLabel(asset),
        assetMakeModel: asset ? [asset?.make, asset.model, asset.variant].filter(x => x).join(' ') : undefined,
        assetId,
        ownedByUser: !!asset && asset.ownerId.toLowerCase() === organisationId.toLowerCase(),
        lastActiveFormatted: lastActive ? DateTime.fromISO(lastActive).setZone(timezone).toFormat('dd MMM yyyy HH:mm:ss ZZZ') : undefined,
        lastActive,
        owner: asset ? asset.ownerName : '',
      };
    });
  }, [assetsById, devicesQuery.data, configsQuery.data, organisationId, timezone, t, assetLabel, latestFirmwareVersions.data]);

  const columns: Column<RowData>[] = [
    {
      title: t('makeModel'),
      field: 'makeModel',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.makeModel, b.makeModel)
    },
    {
      title: (
        <Stack direction="row" spacing={1}>
          <Box>
            {t('firmwareVersion.label')}
          </Box>
          <Tooltip title={t('firmwareVersion.help')}>
            <HelpOutline sx={{ color: 'common.text' }} />
          </Tooltip>
        </Stack>
      ),
      field: 'firmwareVersion',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.firmwareVersion, b.firmwareVersion),
      render: row => (row.firmwareOutdated ? (
        <Stack direction="row" spacing={1}>
          <Box>
            {row.firmwareVersion}
          </Box>
          <Tooltip title={t('firmwareVersion.outdated')}>
            <BuildCircle sx={{ color: 'primary.orange' }} />
          </Tooltip>
        </Stack>
      ) : row.firmwareVersion),
      hidden: !tableSettings.columns?.firmwareVersion,
    },
    {
      title: t('tpSerial'),
      field: 'tpSerial',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.tpSerial?.toString(), b.tpSerial?.toString()),
      render: row => row.tpSerial ?? '-',
      hidden: !tableSettings.columns?.tpSerial,
    },
    {
      title: t('imei'),
      field: 'imei',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.imei?.toString(), b.imei?.toString()),
      render: row => row.imei ?? '-',
      hidden: !tableSettings.columns?.imei,
    },
    {
      title: t('manufacturerSerial'),
      field: 'manufacturerSerial',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.manufacturerSerial?.toString(), b.manufacturerSerial?.toString()),
      render: row => row.manufacturerSerial ?? '-',
      hidden: !tableSettings.columns?.manufacturerSerial,
    },
    {
      title: t('status'),
      field: 'status',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.status, b.status),
      hidden: !tableSettings.columns?.status,
    },
    {
      title: t('lastActive'),
      field: 'lastActiveFormatted',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.lastActive, b.lastActive),
      hidden: !tableSettings.columns?.lastActive,
    },
    {
      title: t('assignedAsset'),
      field: 'asset',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(`${a.assetLabel ?? ''} ${a.assetMakeModel ?? ''}`, `${b.assetLabel ?? ''} ${b.assetMakeModel ?? ''}`),
      customFilterAndSearch: (filter, row) => [row.assetLabel?.toLowerCase(), row.assetMakeModel?.toLowerCase()].some(s => s?.includes(filter.toLowerCase())),
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          {row.assetLabel === undefined ? (
            <Typography fontStyle="italic">{t('unknownAsset')}</Typography>
          ) : (
            <>
              <AssetColourMarker deviceId={row.id} />
              <Stack>
                <Typography>{row.assetLabel}</Typography>
                <Typography variant="body3" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '20em' }}>{row.assetMakeModel}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      ),
      hidden: !tableSettings.columns?.asset,
    },
    {
      title: t('owner'),
      field: 'owner',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      customSort: (a, b) => insensitiveSort(a.owner, b.owner),
      hidden: !tableSettings.columns?.owner,
    },
  ];

  const actions: MaterialTableProps<RowData>['actions'] = [
    row => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      icon: () => <InfoOutlined className={classes.actionButton} />,
      tooltip: t('viewDevice'),
      onClick: () => {
        navigate(`/manage/devices/${row.id}`);
      },
    })
  ];

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);
  const resetFilters = () => dispatch(updateSetting({ category: 'devicesTable', field: 'filters', value: [] }));

  return (
    <Box
      className={classes.materialTable}
      bgcolor="common.white"
      borderRadius={1}
      border={1}
      borderColor="common.midGrey"
      mb={8}
      position="relative"
    >
      <Button
        variant="outlined"
        size="large"
        sx={{ minWidth: '10rem' }}
        className={classes.resetFiltersButton}
        onClick={() => resetFilters()}
        disabled={(tableSettings.filters?.length === 0)}
      >
        Reset Filters
      </Button>
      <PersistentTable<RowData>
        settingsCategory="devicesTable"
        title={t('title')}
        icons={tableIcons}
        data={cleanedDevices}
        isLoading={assetsQuery.isLoading || devicesQuery.isLoading}
        columns={columns}
        actions={actions}
        options={{
          filtering: true,
          draggable: false,
          showTitle: false,
          search: true,
          paging: true,
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          searchFieldStyle: {
            borderRadius: '4px',
            paddingLeft: '18px',
            paddingRight: '10px'
          },
          searchFieldVariant: 'outlined',
          thirdSortClick: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        onRowClick={(e, row) => {
          if (row) navigate(`/manage/devices/${row.id}`);
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
        components={{
          Container: Box,
          Pagination,
          Toolbar,
        }}
      />
      <Box ref={footerRef} position="sticky" bottom={0} />
    </Box>
  );
};

export default DeviceListPage;
