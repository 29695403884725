import useOrganisationId from "hooks/session/useOrganisationId";
import { UserHistory } from "./userHistory-types";
import { useQuery } from "@tanstack/react-query";
import { userHistoryQueryKeys } from "./userHistory-queryKeys";
import { getUserHistories } from "./userHistory-requests";
import { HttpResponseError } from "helpers/api";

export const useGetUserHistories = <T = UserHistory[]>(from: number, until: number, isStaff = false) => {
  const organisationId = useOrganisationId();
  const queryKey = userHistoryQueryKeys.all(organisationId, from, until, isStaff);
  const query = useQuery<UserHistory[], HttpResponseError, T>({ queryKey, queryFn: () => getUserHistories(organisationId, from, until, isStaff) });
  return { query, queryKey };
};
