import { useCallback } from 'react';
import { useGetPeople } from 'apis/rest/people/hooks';
import { ContactType } from 'hooks/people/usePeopleWithExtractedContacts';
import usePeopleById from './usePeopleById';

export const personCanBeImportantIce = (person: Person): boolean => {
  if (!person.contacts.some(c => c.contactType === ContactType.SMS && c.isDefault)) return false;
  if (!person.contacts.some(c => c.contactType === ContactType.Phone && c.isDefault)) return false;
  return true;
};

const usePersonCanBeImportantIce = (): (personId: number) => boolean | undefined => {
  const { query: peopleQuery } = useGetPeople();
  const peopleById = usePeopleById(peopleQuery.data);
  return useCallback((id: number) => {
    if (!peopleById) return undefined;
    if (peopleById[id]) return personCanBeImportantIce(peopleById[id]);
    return false;
  }, [peopleById]);
};

export default usePersonCanBeImportantIce;
