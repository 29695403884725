import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import TracPlusLabsIcon from './Icon';

const TracPlusLabsLogo = ({ sx }: { sx?: SxProps }) => (
  <Stack direction="row" alignItems="center" sx={{ color: 'labs.main', fontSize: '2rem', ...sx }}>
    <TracPlusLabsIcon sx={{ fontSize: '1em' }} />
    <Typography color="inherit" fontSize="1em" fontWeight="medium" pl="0.2em">TracPlus</Typography>
    <Typography color="inherit" fontSize="1em" fontWeight="bold" pl="0.2em">Labs</Typography>
  </Stack>
);

export default TracPlusLabsLogo;
