import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  logo: {
    width: '1.8rem',
    height: '1.8rem'
  },
  smallCircle: {
    fill: theme.palette.common.tpIconCircle
  },
  curve: {
    fill: theme.palette.primary.turquoise
  }
}));
