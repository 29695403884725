import { getAllTimezones, getCountryForTimezone, Timezone } from 'countries-and-timezones';

const toFriendlyString = ({ name, countries, utcOffsetStr, utcOffset }: Timezone) => {
  let label = '';
  if (name === 'Etc/UTC') {
    label = 'Coordinated Universal Time (UTC)';
  } else {
    if (countries.length) label = getCountryForTimezone(name)?.name ?? '';
    label += ` (${name.replace(/_/g, ' ')}) GMT ${utcOffsetStr}`;
  }

  return { id: name, label, utcOffset };
};

const timezones = Object
  .values(getAllTimezones())
  .sort((a, b) => a.utcOffset - b.utcOffset)
  .filter(({ name }) => (/(\w+\/\w+$)/g.test(name) && !/etc\//gi.test(name)) || name === 'Etc/UTC')
  .map(toFriendlyString);

export default timezones;
