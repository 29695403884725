import React from 'react';
import { useTranslations } from 'use-intl';
import { Alert, Chip, Collapse, Grow, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useGetIceContactGroups, useMutateIceContactGroupAssignPeople } from 'apis/rest/iceContactGroups/hooks';
import useSnackbar from 'hooks/useSnackbar';
import useLastDefined from 'hooks/useLastDefined';
import usePersonCanBeImportantIce from 'hooks/people/usePersonCanBeImportantIce';
import {
  AssignPeopleDialog,
  AssignPeopleDialogProps,
  AssignPeopleDialogStatus,
} from '../../contactGroups/assignPeople';
import { PeopleTableComponents } from '../../contactGroups/assignPeople/peopleTable-view';

const components: PeopleTableComponents = {
  Person: ({ person, order }) => {
    const t = useTranslations('dialogs.ice.assignPeople');
    const importantPerson = usePersonCanBeImportantIce();

    return (
      <>
        <Stack>
          <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{person.name}</Typography>
          <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body3">{person.role}</Typography>
        </Stack>
        {!importantPerson(person.id) && (
          <Tooltip title={t('tooltips.missingRequiredContacts')}>
            <ErrorOutline color="error" fontSize="large" />
          </Tooltip>
        )}
        <Chip label={person.languageCode} />
        <Grow in={order === 1 || order === 2} unmountOnExit timeout={1000} appear={false}>
          <Chip label={t(order === 1 ? 'primary' : 'secondary')} color={order === 1 ? 'error' : 'warning'} />
        </Grow>
      </>
    );
  },
};

interface AssignPeopleIceDialogProps extends Pick<AssignPeopleDialogProps, 'open' | 'onClose' | 'title' | 'ariaLabel' | 'skip'> {
  groupId: number | undefined
}

export const AssignPeopleIceDialog = ({ onClose, groupId, ...rest }: AssignPeopleIceDialogProps): JSX.Element | null => {
  const t = useTranslations('dialogs.ice.assignPeople');
  const { query: groupsQuery } = useGetIceContactGroups({ enabled: groupId !== undefined });
  const mutation = useMutateIceContactGroupAssignPeople();
  const snackbar = useSnackbar();
  const importantPerson = usePersonCanBeImportantIce();
  const error = useLastDefined(mutation.error ?? undefined, [groupId]);

  const group = groupsQuery.data?.find(g => g.id === groupId);
  if (!group) return null;

  const validate = (g: ContactGroup, [personId1, personId2]: number[]) => {
    if (g.isDefault) return importantPerson(personId1) && importantPerson(personId2);
    return true;
  };

  return (
    <AssignPeopleDialog
      {...rest}
      onClose={(status, id) => {
        onClose(status, id);
        if (status === AssignPeopleDialogStatus.Assigned) {
          snackbar.display({
            id: `groupPeopleAdded.${id}`,
            text: t('snackbar.success', { group: group.name }),
            type: 'success',
          });
        }
      }}
      group={group}
      mutation={mutation}
      validate={validate}
      components={components}
      renderErrors={(g, ids, valid) => (
        <>
          <Collapse in={valid === false} unmountOnExit>
            <Alert severity="error">{t('failsDefaultGroupRequirements')}</Alert>
          </Collapse>
          <Collapse in={mutation.isError} unmountOnExit>
            <Alert severity="error">
              {error?.response.status === 409 ? t('error409') : t('error')}
            </Alert>
          </Collapse>
        </>
      )}
    />
  );
};
