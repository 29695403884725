import { Stack, Paper } from '@mui/material';
import React from 'react';
import useDistance from 'hooks/units/useDistance';
import { sumBy } from 'lodash/fp';
import { EngineUsage } from 'apis/rest/engineUsage/types';
import { Feature } from 'components/shared/Feature';
import useDuration from 'hooks/units/useDuration';
import Score from '../../tripAnalysis/score';
import EngineCycles from '../../tripAnalysis/engineCycles';

interface DailyDetailProps {
  trips: Trip[]
  isEngineUsageLoading: boolean
  engineUsage: EngineUsage[] | undefined
}

export const DailyDetail = ({ trips, isEngineUsageLoading, engineUsage }: DailyDetailProps) => {
  const duration = useDuration();
  const totalDistance = sumBy('distance', trips);
  const totalDuration = sumBy('duration', trips);
  const totalEngineDuration = sumBy('duration', engineUsage);
  const distance = useDistance();

  const dropsById = trips.flatMap(t => t.drops).reduce<Record<number, Drop>>((acc, drop) => ({
    ...acc,
    [drop.id]: drop,
  }), {});

  const dropCount = Object.values(dropsById).filter(drop => drop.type === 'Drop').length;
  const totalDropVolume = Object.values(dropsById).filter(drop => drop.type === 'Drop').reduce((sum, drop) => (
    sum + (drop.dropVolume ?? 0) - (drop.endVolume ?? 0)
  ), 0);

  return (
    <Paper elevation={0}>
      <Stack direction="row" p={3} spacing={6}>
        <Score label="Flights" value={trips.length} />
        <Score label="Total Flight Duration" value={duration.fromMillis(totalDuration)} />
        <Score label="Total Flight Distance" value={distance.create(totalDistance * 1000).format()} />
        <Score
          label="Engine Cycles"
          value={<EngineCycles count={engineUsage?.length ?? 0} />}
          loading={isEngineUsageLoading}
        />
        {!!engineUsage?.length && (
          <Score label="Total Engine Duration" value={duration.fromMillis(totalEngineDuration)} loading={isEngineUsageLoading} />
        )}
        <Feature
          id="reporting.aerialFirefightingReport"
          enabled={(
            <>
              {dropCount === 0 && <Score label="Total Drops" value="None detected" />}
              {dropCount > 0 && <Score label="Total Drops" value={dropCount} />}
              {dropCount > 0 && <Score label="Total Drop Volume" value={`${totalDropVolume.toFixed(0)} L`} />}
            </>
          )}
        />
      </Stack>
    </Paper>
  );
};
