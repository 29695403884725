import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { tryGetFirmwareVersion } from 'helpers/config';
import {
  assignDeviceToAsset,
  ConfigurationProfile,
  fetchLatestFirmwareVersions,
  getCapabilitiesList,
  getConfigChanges,
  getDevice,
  getDevicesList,
  getLatestConfigurations,
  removeDeviceFromAsset,
  requestConfig,
  sendCustomConfig,
  setConfigurationProfile,
} from './requests';
import { assetsQueryKeys } from '../assets/queryKeys';
import { deviceQueryKeys } from './queryKeys';
import { FirmwareVersion } from './types';

type Options<QueryData, ResultData> = Omit<UseQueryOptions<QueryData, HttpResponseError, ResultData>, 'queryKey' | 'queryFn'>;

export const useGetDevicesList = <T = DeviceBasic[]>(options?: Options<DeviceBasic[], T>, owned: boolean = false) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.lists(organisationId, owned);

  const query = useQuery({
    queryKey,
    queryFn: () => getDevicesList(organisationId, owned),
    ...options,
  });
  return { query, queryKey };
};

export const useGetDeviceBasic = (deviceId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => deviceQueryKeys.detail(organisationId, deviceId), [organisationId, deviceId]);

  const query = useQuery({
    queryKey,
    queryFn: () => getDevice(organisationId, deviceId),
    enabled,
  });
  return { query, queryKey };
};

export const useGetLatestDeviceConfigurations = <T = DeviceConfiguration[]>(options: Options<DeviceConfiguration[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.listsConfigurations(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => getLatestConfigurations(organisationId),
    ...options,
  });
  return { query, queryKey };
};

export const useGetLatestDeviceConfiguration = (deviceId: number) => useGetLatestDeviceConfigurations({ select: data => data.find(c => c.deviceId === deviceId) });

export const useDeviceConfigChanges = (deviceId: number) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.configChanges(organisationId, deviceId);
  return useQuery({
    queryKey,
    queryFn: () => getConfigChanges(organisationId, deviceId)
  });
};

export const useSetDeviceConfigurationProfile = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { deviceId: number, profile: ConfigurationProfile }) => setConfigurationProfile(organisationId, value.deviceId, value.profile),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: deviceQueryKeys.listsConfigurations(organisationId) }),
    mutationKey: ['setDeviceConfigurationProfile'],
  });
};

export const useGetCapabilitiesList = <T = Capability[]>(options?: Options<Capability[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.listsCapabilities(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getCapabilitiesList(organisationId),
    ...options
  });
  return { query, queryKey };
};

export const useGetCapabilitiesForDevice = (deviceId: number) => {
  const deviceQuery = useGetDeviceBasic(deviceId).query;
  return useGetCapabilitiesList({
    enabled: !!deviceQuery.data?.scriptId,
    select: c => c.filter(cap => cap.scriptId === deviceQuery.data?.scriptId)
  }).query;
};

export const useGetLatestFirmwareVersions = <T = FirmwareVersion[]>(options?: UseQueryOptions<FirmwareVersion[], HttpResponseError, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceQueryKeys.firmwareVersions(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => fetchLatestFirmwareVersions(organisationId),
    ...options,
  });
};

export const useLatestFirmwareVersionForDevice = (device: DeviceBasic | undefined) => {
  const select = useCallback(
    versions => versions.find((v: FirmwareVersion) => v.make === device?.make && v.model === device?.model)?.version,
    [device]
  );

  const organisationId = useOrganisationId();
  const config = useGetLatestDeviceConfiguration(device?.id ?? -1).query;
  const latestFirmwareVersion = useGetLatestFirmwareVersions({
    queryKey: deviceQueryKeys.firmwareVersions(organisationId),
    enabled: !!device && !!config.data,
    select,
  }).data;

  return useMemo(
    () => {
      const firmwareVersion = config.data && tryGetFirmwareVersion(config.data);

      return ({
        outdated: !firmwareVersion && !latestFirmwareVersion && latestFirmwareVersion !== firmwareVersion,
        latest: latestFirmwareVersion,
      });
    },
    [config.data, latestFirmwareVersion],
  );
};

export const useAssignAssetToDevice = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { assetId: number, deviceId: number }) => assignDeviceToAsset(organisationId, value.assetId, value.deviceId),
    onSuccess: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: deviceQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    ]),
    mutationKey: ['assignDeviceToAsset'],
  });
};

export const useUnassignAssetFromDevice = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { assetId: number, deviceId: number }) => removeDeviceFromAsset(organisationId, value.assetId, value.deviceId),
    mutationKey: ['unassignDeviceToAsset'],
    onSuccess: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: deviceQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    ]),
  });
};

export const useUpdateCustomConfig = () => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (value: { deviceId: number, config: ConfigRequest }) => sendCustomConfig(organisationId, value.deviceId, value.config),
    mutationKey: ['updateConfig'],
  });
};

export const useRequestConfig = () => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (value: { deviceId: number }) => requestConfig(organisationId, value.deviceId),
    mutationKey: ['updateConfig'],
  });
};
