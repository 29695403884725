/* eslint-disable @typescript-eslint/ban-types,react/no-array-index-key */
import React from 'react';
import { MaterialTableProps } from '@material-table/core';
import { ClassNameMap } from '@mui/styles';
import useStyles from './tableActions-styles';

// This component is meant to replace the default MTableActions
// https://github.com/material-table-core/core/blob/485c1e0a525ca54c62c9e3738f070aa7ffe8ed31/src/components/MTableActions/index.js

interface Components {
  Action: React.ElementType;
  [x: string]: React.ElementType;
}
interface TableActionsProps<RowData extends object> extends Pick<MaterialTableProps<RowData>, 'actions' | 'data'> {
  components: Components;
  size: number;
  disabled: boolean;
  forwardedRef: React.ForwardedRef<HTMLDivElement>;
}

const TableActions = <RowData extends object>({
  actions,
  components,
  data,
  size,
  disabled,
  forwardedRef
}: TableActionsProps<RowData>): JSX.Element | null => {
  const classes: ClassNameMap = useStyles();

  if (!actions) return null;

  return (
    <div className={classes.tableActions} ref={forwardedRef}>
      {actions.map((action, index) => (
        <components.Action
          action={action}
          key={`action-${index}`}
          data={data}
          size={size}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default TableActions;
