import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    display: 'flex',
    flex: '0 0 350px',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    borderRight: theme.border.default
  },
  right: {
    flex: 1
  },
  newConversation: {
    width: 45.5,
    height: 45.5,
    top: 5,
  },
  conversationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    maxHeight: 80,
    padding: theme.spacing(4, 3, 3, 3),
    borderBottom: theme.border.default,
    '& h4': {
      color: theme.palette.common.text
    },
    justifyContent: 'space-between'
  },
  conversationWrapper: {
    width: '100%',
    overflow: 'hidden',
    overflowY: 'auto'
  },
  addButton: {
    textAlign: 'left'
  },
  hidden: {
    display: 'none'
  },
  conversationContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  messageHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: 80,
    padding: theme.spacing(4, 6, 3, 6),
    borderBottom: theme.border.default,
    '& h4': {
      color: theme.palette.common.text
    }
  },
  messageHeaderWithAutocomplete: {
    maxHeight: 80
  },
  searchIcon: {
    color: theme.palette.omnibox.searchText
  },
  omniSearch: {
    borderRadius: '30px',
    boxShadow: 'none',
    textAlign: 'left',
    border: theme.palette.omnibox.searchBorder,
    backgroundColor: theme.palette.omnibox.searchBackground,
    color: theme.palette.omnibox.searchText,
    display: 'flex',
    alignItems: 'center',
    width: '82%',
    paddingRight: theme.spacing(2),
    transition: 'all 0.15s',
    '&:hover': {
      borderWidth: '1px'
    }
  },
  input: {
    color: theme.palette.omnibox.searchText,
    fontWeight: '500',
    flex: 1
  },
}));
