import { useCallback, useContext, useMemo } from 'react';
import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { UserSettings, UserSettingsContext } from './userSettingsContext';

export const useUserSettings = <K extends keyof UserSettings>(key: K): [UserSettings[K], ((state: UserSettings[K]) => void) | ((updater: (draftState: WritableDraft<UserSettings[K]>) => void) => void)] => {
  const [userSettings, setUserSettings] = useContext(UserSettingsContext);

  const value = useMemo(() => userSettings[key], [userSettings, key]);
  const setValue = useCallback(inp => {
    setUserSettings(val => ({ ...val, [key]: typeof inp === 'function' ? produce(val[key], inp) : inp }));
  }, [key, setUserSettings]);

  return [value, setValue];
};
