import React from 'react';

const None = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <circle className="icon-detail" fill="#fff" cx="8" cy="8" r="1.19" />
    <circle className="icon-detail" fill="#fff" cx="4.44" cy="8" r="1.19" />
    <circle className="icon-detail" fill="#fff" cx="11.56" cy="8" r="1.19" />
  </svg>
);

export default None;
