import React, { useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { TextLayer } from '@deck.gl/layers';
import { memoize } from 'lodash';
import { MapSettings } from 'slices/map.slice';
import AssetIcon from 'components/shared/icons/assetIcons';
import { useAssetLabel } from 'components/shared/assetLabel';
import { hexToRGBArray } from 'helpers/color';
import { useStartOfDay } from 'hooks/useStartOfDay';
import PulseIconLayer from './pulseIconLayer';
import OutlinedIconLayer from './outlinedIconLayer';
import IconLayerCollisionExtension from './modules/iconLayerCollisionExtension';

const ICON_SIZE = 128;
const LABEL_COLOR: Color = [255, 255, 255];
const LABEL_BACKGROUND: ColorAlpha = [0, 0, 0, 128];
const LABEL_PADDING: [number, number, number, number] = [3, 1, 3, 1];

const PULSE_ICON = {
  id: 'pulse-icon',
  url: 'img/radial-gradient.png',
  height: ICON_SIZE,
  width: ICON_SIZE,
  anchorX: ICON_SIZE / 2,
  anchorY: ICON_SIZE / 2,
  mask: true
} as const;

interface PulseIconData {
  id: 'pulse'
  icon: typeof PULSE_ICON
  position: [number, number, number]
}

const usePulseLayer = (
  visible: boolean,
  follow: boolean,
  report: Report | undefined,
  pulseColor: Color | ColorAlpha,
  followPulseColor: Color | ColorAlpha,
  use3d = false
) => {
  const data = useMemo<PulseIconData[]>(() => {
    let pulseIcons: PulseIconData[] = [];

    if (report) {
      const icon: PulseIconData = {
        id: 'pulse',
        icon: PULSE_ICON,
        position: [report.longitude, report.latitude, use3d ? report.altitude : 0],
      };
      pulseIcons = [
        icon,
        { ...icon, position: [report.longitude - 360, report.latitude, use3d ? report.altitude : 0] },
        { ...icon, position: [report.longitude + 360, report.latitude, use3d ? report.altitude : 0] },
      ];
    }

    return pulseIcons;
  }, [report, use3d]);

  const layer = new PulseIconLayer<PulseIconData>({
    id: 'icon-layer-pulse',
    data,
    pickable: false,
    getIcon: d => d.icon,
    sizeScale: 1,
    getPosition: d => d.position,
    getSize: 59,
    speedModifier: follow ? 0.5 : 1.0,
    getColor: follow ? followPulseColor : pulseColor,
    opacity: follow ? 0.7 : 0.4,
    billboard: false,
    parameters: { depthTest: false },
    visible,
  });

  return layer;
};

interface LabelData {
  text: string
  assetId: number,
  position: [number, number, number]
}

const useLabelLayer = (
  visible: boolean,
  assets: AssetBasic[],
  latestPositions: Record<number, Report | undefined>,
  selectedAssetId: number | undefined,
  use3d = false
) => {
  const assetLabel = useAssetLabel();

  const data = useMemo<LabelData[]>(() => assets.flatMap(asset => {
    const report = latestPositions[asset.id];
    if (!report) return [];

    const item: LabelData = {
      text: assetLabel(asset, ''),
      assetId: asset.id,
      position: [report.longitude, report.latitude, use3d ? report.altitude : 0],
    };

    return [
      item,
      { ...item, position: [report.longitude - 360, report.latitude, use3d ? report.altitude : 0] },
      { ...item, position: [report.longitude + 360, report.latitude, use3d ? report.altitude : 0] },
    ] as LabelData[];
  }), [assets, latestPositions, assetLabel, use3d]);

  const layer = useMemo(() => new TextLayer<LabelData>({
    id: 'label-layer',
    data,
    getText: d => `${d.text}`,
    pickable: false,
    fontWeight: 550,
    getPosition: d => d.position,
    getSize: 12,
    getColor: LABEL_COLOR,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    // when this is changed you must also change it in the iconLayerCollisionExtension shader - pixelOffset2 (can't be bothered making it a uniform)
    getPixelOffset: [0, 32],
    fontSettings: { sdf: true, fontSize: 30, radius: 12 },
    fontFamily: 'objektiv-mk2,sans-serif',
    background: true,
    getBackgroundColor: LABEL_BACKGROUND,
    backgroundPadding: LABEL_PADDING,
    parameters: { depthTest: false },
    extensions: [new IconLayerCollisionExtension()],
    visible
  }), [data, visible]);

  return layer;
};

interface AssetIconData {
  reportId: number
  assetId: number
  size: number
  colorKey: string
  course: number
  reportedToday: boolean
  icon: {
    id: string
    url: string
    height: number
    width: number
    anchorX: number
    anchorY: number
    mask: boolean
  }
  position: [number, number, number]
}

const getAssetIcon = memoize((assetIconId: string) => ({
  id: assetIconId,
  // NOTE: We cannot pass in the correct asset color to the SVG because the way deck.gl renders it makes it black.
  //       It is passed into the OutlinedFragmentShader, which maps the white outline/black fill to white outline/<specified color> fill
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<AssetIcon type={assetIconId} />))}`,
  height: ICON_SIZE,
  width: ICON_SIZE,
  anchorX: ICON_SIZE / 2,
  anchorY: ICON_SIZE / 2,
  mask: true
}));

const getColorItem = memoize((hex: string) => {
  const rgb = hexToRGBArray(hex);
  return {
    full: rgb,
    low: [rgb[0], rgb[1], rgb[2], 200] as ColorAlpha,
  };
});

const useOutlinedIconsLayer = (
  visible: boolean,
  assets: AssetBasic[],
  latestPositions: Record<number, Report | undefined>,
  selectedAssetId: number | undefined,
  use3d = false
) => {
  const iconCache = useRef<Record<number, [AssetIconData, AssetIconData, AssetIconData] | undefined>>({});
  const iconCache3D = useRef<Record<number, [AssetIconData, AssetIconData, AssetIconData] | undefined>>({});
  const startOfDay = useStartOfDay();

  const data = useMemo<AssetIconData[]>(() => assets.flatMap(asset => {
    const report = latestPositions[asset.id];
    if (!report) return [];

    const colorKey = asset.colour ?? '#ff69b4';

    let items = use3d ? iconCache3D.current[asset.id] : iconCache.current[asset.id];

    if (!items || items[0].reportId !== report.id || items[0].colorKey !== colorKey) {
      const reportedToday = startOfDay.toSeconds() < report.received;
      const item: AssetIconData = {
        reportId: report.id,
        assetId: asset.id,
        size: 5,
        colorKey,
        reportedToday,
        course: report.course,
        icon: getAssetIcon(asset.assetIconId),
        position: [report.longitude, report.latitude, use3d ? report.altitude : 0],
      };
      items = [
        item,
        { ...item, position: [report.longitude - 360, report.latitude, use3d ? report.altitude : 0] },
        { ...item, position: [report.longitude + 360, report.latitude, use3d ? report.altitude : 0] },
      ];
      if (use3d) {
        iconCache3D.current[asset.id] = items;
      } else {
        iconCache.current[asset.id] = items;
      }
    }

    return items;
  }), [assets, latestPositions, startOfDay, use3d]);

  const layer = useMemo(() => new OutlinedIconLayer<AssetIconData>({
    id: 'asset-icon-layer',
    data,
    billboard: false,
    pickable: false,
    autoHighlight: true,
    getIcon: d => d.icon,
    sizeScale: 1,
    getPosition: d => d.position,
    getSize: 40,
    getAngle: d => -d.course,
    getColor: d => {
      const color = getColorItem(d.colorKey);
      if (selectedAssetId === undefined) {
        return d.reportedToday ? color.full : color.low;
      }
      return selectedAssetId === d.assetId ? color.full : color.low;
    },
    outlineColor: LABEL_COLOR,
    parameters: { depthTest: false },
    updateTriggers: {
      getColor: selectedAssetId,
    },
    visible,
  }), [selectedAssetId, data, visible]);

  return layer;
};

const useAssetIconLayers = (
  assets: AssetBasic[],
  latestPositions: Record<number, Report | undefined>,
  appSelectedItemId: number | undefined,
  selectedItemId: number | undefined,
  selectedLegFinished: boolean,
  selectedItemIsHidden: boolean,
  config: MapSettings,
  follow: boolean,
  use3d = false
) => {
  const sortedAssets = useMemo(() => {
    if (!selectedItemId) { return assets; }
    const selectedAsset = assets.find(a => a.id === selectedItemId);
    if (!selectedAsset) { return assets; }
    return [...assets.filter(a => a.id !== selectedItemId), selectedAsset];
  }, [assets, selectedItemId]);

  const pulseLayer = usePulseLayer(
    !selectedItemIsHidden && !selectedLegFinished,
    follow,
    latestPositions[appSelectedItemId as number],
    config.defaultPulseColor,
    config.followPulseColor,
    use3d
  );

  const outlinedIconsLayer = useOutlinedIconsLayer(
    true,
    sortedAssets,
    latestPositions,
    selectedItemId,
    use3d
  );

  const labelLayer = useLabelLayer(
    config.permanentLabels,
    sortedAssets,
    latestPositions,
    selectedItemId,
    use3d
  );

  return [pulseLayer, outlinedIconsLayer, labelLayer];
};

export default useAssetIconLayers;
