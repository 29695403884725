import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface mapSettingsState {
  highlightDropMode: boolean
}

const initialState: mapSettingsState = {
  highlightDropMode: false,
};

export const manageMapSettingsSlice = createSlice({
  name: 'mapSettings',
  initialState,
  reducers: {
    setFirefightingMode: (state, action: PayloadAction<boolean>) => {
      state.highlightDropMode = action.payload;
    }
  },
  selectors: {
    selectFirefightingMode: state => state.highlightDropMode,
  }
});

export const { selectFirefightingMode } = manageMapSettingsSlice.selectors;
export const { setFirefightingMode } = manageMapSettingsSlice.actions;

export default manageMapSettingsSlice.reducer;
