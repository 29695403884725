import React from 'react';

const Inflatable = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 28.828098,2.5958951 c 0,0 -7.401535,9.2851689 -8.334189,22.6799259 -0.759205,10.903685 0,32.790253 0,32.790253 l 1.639512,3.415652 2.425113,-0.03416 1.639513,-3.244869 -0.10247,-2.186017 c 0,0 2.925797,-0.0062 6.39726,-0.0062 3.471463,0 6.445564,0.0062 6.445564,0.0062 l -0.10247,2.186017 1.639513,3.244869 2.425113,0.03416 1.639512,-3.415652 c 0,0 0.759205,-21.886568 0,-32.790253 C 43.607415,11.881064 36.20588,2.5958951 36.20588,2.5958951 h -3.678217 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Inflatable;
