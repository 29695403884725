import React, { useRef, useMemo, useEffect } from 'react';
import {
  FormControl,
  Button,
  ButtonBase,
  Paper,
  Stack,
  Grid,
} from '@mui/material';
import { Colorize } from '@mui/icons-material';
import { throttle } from 'lodash';
import useTranslation from 'hooks/useTranslation';

import useStyles from './input-styles';

const ColorPicker = ({
  palette,
  onChange,
  readOnly,
  value,
  preview
}) => {
  const classes = useStyles();
  const t = useTranslation('colorPicker');
  const colorInput = useRef();
  const handleInputChange = useMemo(
    () => throttle(event => onChange(event.target.value), 20),
    [onChange],
  );

  // NOTE: The color input is uncontrolled for performance reasons.
  //       Here - only on the initial render - we set the initial value
  useEffect(() => {
    if (colorInput.current) colorInput.current.value = value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl variant="standard" className={classes.swatchWrapper}>
      <Stack spacing={3} alignItems="flex-start">
        <Stack direction="row" spacing={3} className={classes.circlePickerContainer}>
          <Stack
            component={Paper}
            sx={{ backgroundColor: value, width: 120, height: 120, flex: '0 0 auto' }}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {preview}
          </Stack>
          {!readOnly && (
            <Stack spacing={2} alignItems="flex-start">
              <Grid container spacing={1}>
                {palette.map(color => (
                  <Grid item key={color}>
                    <Paper
                      component={ButtonBase}
                      sx={theme => ({
                        backgroundColor: color,
                        width: 32,
                        height: 32,
                        '&:hover': {
                          outline: `1px solid ${theme.palette.common.black}`,
                        },
                      })}
                      elevation={0}
                      onClick={() => {
                        onChange(color);
                        if (colorInput.current) colorInput.current.value = color;
                      }}
                      aria-label={color}
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                onClick={() => colorInput.current?.click()}
                startIcon={<Colorize />}
                sx={{ position: 'relative' }}
              >
                {t('useCustomColor')}
                {/* NOTE: this input is uncontrolled to allow smooth color scrubbing */}
                <input
                  type="color"
                  onChange={handleInputChange}
                  ref={colorInput}
                  style={{ position: 'absolute', top: '100%', left: 0, visibility: 'hidden', width: 0, height: 0 }}
                />
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </FormControl>
  );
};

export default ColorPicker;
