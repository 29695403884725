import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { useAppDispatch } from 'store/types';
import { useMutation } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import { changeDisplayName, changeEmail, sendPasswordResetEmail } from 'apis/auth';
import { updateUser } from 'slices/session/session.slice';
import { useUser } from 'hooks/session/useUser';
import { validate as validateEmail } from 'helpers/email';
import { UserProfile as UserProfileView } from './UserProfile.view';
import { CredentialDialog } from './CredentialDialog.view';

export const UserProfile = () => {
  const t = useTranslations('pages.accountSettings');
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const user = useUser();

  const [name, setName] = useState(user?.name ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [emailError, setEmailError] = useState<false | 'emailRequired' | 'invalidEmail'>(false);
  const [formValuesAreValid, setFormValuesAreValid] = useState<boolean>(false);

  const [credentialOpen, setCredentialOpen] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [prevUser, setPrevUser] = useState({ name, email });

  const hasMadeChanges = useMemo(() => name !== user?.name || email !== user?.email, [user, name, email]);

  useEffect(() => {
    setEmailError(validateEmail(email));
    setFormValuesAreValid(!!name?.trim() && !validateEmail(email));
  }, [name, email]);

  const onClose = () => {
    setCredentialOpen(false);
    setCurrentPassword('');
  };

  const updateUserMutation = useMutation({
    mutationKey: ['updateUser'],
    mutationFn: () => {
      if (!user || currentPassword.length === 0) {
        // Re-authentication is required if they want to change their email
        // Firebase making the rules...
        throw new Error('no user or current password');
      }

      setPrevUser({ name: user.name, email: user.email });
      const updateEmail = changeEmail(email, user.email, currentPassword);
      const updateName = changeDisplayName(name);
      dispatch(updateUser({ name, email }));
      return Promise.all([updateEmail, updateName]);
    },
    onSuccess: () => {
      snackbar.display({
        id: 'savedUserSuccess',
        type: 'success',
        text: t('savedChanges'),
      });
      onClose();
    },
    onError: (err: { code: string }) => {
      console.error(err);
      const errorText = err.code.search('auth/email-already-in-use') > 0
        ? t('emailInUse')
        : err.code.search('auth/wrong-password') > 0
          ? t('wrongPassword')
          : t('failedToSaveUser');
      snackbar.display({
        id: 'savedUserError',
        type: 'error',
        text: errorText,
      });
      dispatch(updateUser({ name: prevUser.name, email: prevUser.email }));
      onClose();
    },
  });

  const resetPasswordMutation = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async () => {
      if (email) {
        await sendPasswordResetEmail(email);
      } else {
        snackbar.display({
          id: 'newPasswordError',
          type: 'error',
          text: t('newPasswordError'),
        });
      }
    },
    onSuccess: () => {
      snackbar.display({
        id: 'newPasswordSuccess',
        type: 'success',
        text: `${t('newPasswordSuccess')} ${email}`,
      });
    },
    onError: () => {
      snackbar.display({
        id: 'newPasswordError',
        type: 'error',
        text: t('newPasswordError'),
      });
    },
  });

  if (!user) {
    return null;
  }

  return (
    <>
      <UserProfileView
        name={name}
        onNameChange={setName}
        email={email}
        onEmailChange={setEmail}
        emailError={emailError}
        saveDisabled={!hasMadeChanges || !formValuesAreValid}
        onSave={() => setCredentialOpen(true)}
        onResetPassword={() => resetPasswordMutation.mutate()}
      />

      <CredentialDialog
        open={credentialOpen}
        onClose={onClose}
        email={email}
        password={currentPassword}
        onPasswordChange={setCurrentPassword}
        onSave={() => updateUserMutation.mutate()}
        saveDisabled={!currentPassword || updateUserMutation.isPending}
        cancelDisabled={updateUserMutation.isPending}
      />
    </>
  );
};
