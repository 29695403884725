import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import {
  createFirestoreLocaleSettings,
  updateFirestoreLocaleSettings
} from 'apis/rest/settings/requests';
import { doc, getDoc } from 'firebase/firestore';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getTimezone } from 'countries-and-timezones';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { loggedOut } from '../session/session.slice';
import { di } from '../../di';

const firebaseDep = di.depend('firebaseService');

export interface LocaleSettingsState {
  locale: {
    timezone: string
    language: 'en' | 'es'
    schema: number
  }
  loaded: boolean
}

export interface UpdateLocaleFieldRequestType<F extends keyof LocaleSettingsState['locale']>{
  field: F
  value: LocaleSettingsState['locale'][F]
}

const debouncedSetDoc = debounce(async (request: LocaleSettingsState['locale']) => updateFirestoreLocaleSettings(request), 3000, { leading: true, trailing: true });

export const updateLocaleSettingField = createAppAsyncThunk(
  'updateLocaleSettingField',
  async (request: UpdateLocaleFieldRequestType<keyof LocaleSettingsState['locale']>, thunkAPI) => {
    const state = thunkAPI.getState();
    const payload = { ...state.localeSettings.locale, [request.field]: request.value };

    // NOTE: we don't need to await this
    debouncedSetDoc(payload);

    return payload;
  }
);

export const convertDeprecatedTimezone = (name: string) => {
  const zone = getTimezone(name);
  return zone?.aliasOf ?? zone?.name ?? 'Etc/UTC';
};

const getOldLocaleState = (): undefined | { timezone: string, language: 'en' | 'es' } => {
  const localeSettings = window.localStorage.getItem('persist:settings');
  if (!localeSettings) return undefined;
  try {
    const { timezone, language } = JSON.parse(JSON.parse(localeSettings).locale);
    if (typeof timezone !== 'string') return undefined;
    if (language !== 'en' && language !== 'es') return undefined;
    return { timezone: convertDeprecatedTimezone(timezone), language };
  } catch (error) {
    return undefined;
  }
};

export const getLocaleSettings = createAppAsyncThunk<LocaleSettingsState['locale'], string, {state: ReduxState}>(
  'getLocaleSettings',
  async (uid: string, { getState }) => {
    const { firestore } = firebaseDep.inject();
    const settingsCategory = doc(firestore, `settings/${uid}/userSettings/localeSettings`);
    const localeSettingsDoc = await getDoc(settingsCategory);
    if (localeSettingsDoc.exists()) {
      return localeSettingsDoc.data() as LocaleSettingsState['locale'];
    }
    // user does not exist in firestore - get setting from local storage, upload to firestore and update slice state
    const state = getState() as ReduxState;
    const { timezone, language } = state.localeSettings.locale ?? getOldLocaleState();
    const payload = { timezone: convertDeprecatedTimezone(timezone), language, schema: 0 };

    // NOTE: we don't need to await this
    createFirestoreLocaleSettings(payload);

    return payload;
  }
);

export const manageLocaleSettingsSlice = createSlice({
  name: 'localeSettings',
  initialState: (): LocaleSettingsState => {
    const old = getOldLocaleState();
    return {
      locale: {
        timezone: old?.timezone ?? 'Etc/UTC',
        language: old?.language ?? 'en',
        schema: 0
      },
      loaded: false,
    };
  },
  reducers: {
    localeLoaded: state => {
      state.loaded = true;
    },
    updateLocalLocaleField: (state, action: PayloadAction<UpdateLocaleFieldRequestType<keyof LocaleSettingsState['locale']>>) => {
      state.locale = { ...state.locale, [action.payload.field]: action.payload.value };
    }
  },
  selectors: {
    selectLocaleSettings: state => state.locale,
    selectLocaleLoaded: state => state.loaded,
  },
  extraReducers: builder => {
    builder
      .addCase(loggedOut, state => {
        state.loaded = false;
      })
      .addCase(updateLocaleSettingField.fulfilled, (state, payload) => {
        state.locale = payload.payload;
      })
      .addCase(getLocaleSettings.fulfilled, (state, payload) => {
        state.locale = payload.payload;
        state.loaded = true;
      });
  }
});
export const { selectLocaleSettings, selectLocaleLoaded } = manageLocaleSettingsSlice.selectors;

export const { updateLocalLocaleField, localeLoaded } = manageLocaleSettingsSlice.actions;

export default persistReducer({
  key: 'localeSettings',
  whitelist: ['locale'],
  stateReconciler: (inbound, original) => {
    // ignore persisted state if it's missing required values
    if (!inbound.locale.timezone || !inbound.locale.language) return original;
    return inbound;
  },
  storage,
}, manageLocaleSettingsSlice.reducer);
