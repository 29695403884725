import React, { useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import LinkAbove from 'components/shared/linkAbove';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LoadingPage from 'components/pages/loading/loading-view';
import { useCreateMarker, useMarkers } from 'apis/rest/markers/hooks';
import { Marker } from 'apis/rest/markers/types';
import EditDialog from 'components/dialogs/markers/editDialog';
import MarkersMap from 'components/pages/manage/markers/markers-map';
import MarkersEdit from 'components/pages/manage/markers/markers-edit';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';

const MarkersView = () => {
  const t = useTranslations('pages.manage.markers');

  const { query: markersQuery } = useMarkers();
  const markers = useMemo(() => markersQuery.data ?? [], [markersQuery.data]);

  const [focusedMarker, setFocusedMarker] = useState<Marker | undefined>();
  const [hiddenMarkers, setHiddenMarkers] = useState<number[]>([]);
  const [newMarker, setNewMarker] = useState<GeographicCoordinates>();
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);

  const createMutation = useCreateMarker();

  const handleOnFocus = (id: number) => {
    if (focusedMarker?.id === id) {
      setFocusedMarker(undefined);
    } else {
      setFocusedMarker(markers.find(m => m.id === id));
    }
  };

  const handleToggleHidden = (id: number) => {
    if (focusedMarker?.id === id) {
      setFocusedMarker(undefined);
    }
    if (hiddenMarkers.includes(id)) {
      setHiddenMarkers(hiddenMarkers.filter(n => n !== id));
    } else {
      setHiddenMarkers([...hiddenMarkers, id]);
    }
  };

  const handleCreateNewMarker = (coordinates: GeographicCoordinates) => {
    setIsCreatingNew(false);
    setNewMarker({
      latitude: coordinates.latitude ?? 0,
      longitude: coordinates.longitude ?? 0,
    });
  };

  if (markersQuery.isLoading) {
    return <LoadingPage />;
  }

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
          </Box>

          <Box display="grid" gridTemplateColumns="35% 1fr" gap={2}>
            <MarkersEdit
              markers={markers}
              focusedMarker={focusedMarker}
              onFocus={handleOnFocus}
              hiddenMarkers={hiddenMarkers}
              onToggleHidden={handleToggleHidden}
              isCreatingNew={isCreatingNew}
              onCreateNewMarker={handleCreateNewMarker}
              onToggleCreateNew={() => setIsCreatingNew(!isCreatingNew)}
            />
            <MarkersMap
              markers={markers}
              focusedMarker={focusedMarker}
              hiddenMarkers={hiddenMarkers}
              isCreatingNew={isCreatingNew}
              onCreateNewMarker={handleCreateNewMarker}
            />
          </Box>
        </Container>
        <EditDialog
          open={newMarker !== undefined}
          title={t('dialogs.create.title')}
          latLng={newMarker}
          onClose={() => setNewMarker(undefined)}
          onSubmit={marker => createMutation.mutate({ marker }, {onSuccess: () => setNewMarker(undefined)})}
          isLoading={createMutation.isPending}
        />
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default MarkersView;
