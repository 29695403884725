import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import destination from '@turf/destination';
import { multiLineString } from '@turf/helpers';
import { useMemo } from 'react';
import WebMercatorViewport from '@math.gl/web-mercator';

const WHITE: ColorAlpha = [255, 255, 255, 255];
const SHADOW: ColorAlpha = [0, 0, 0, 128];
const EMPTY = [] as const;

interface TextItem {
  name: string;
  coordinates: [number, number];
}

const niceStep = (zoom: number, speed: number) => {
  const n = speed >= 300 ? zoom + 1 : zoom;
  if (n >= 10) return 5;
  if (n >= 9) return 15;
  if (n >= 8) return 25;
  if (n >= 7) return 50;
  if (n >= 6) return 100;
  if (n >= 5) return 150;
  return 180;
};

const useVelocityLeaders = (
  visible: boolean,
  wmViewport: WebMercatorViewport,
  report?: Pick<Report, 'longitude' | 'latitude' | 'speed' | 'course' | 'altitude'>,
  use3d = false,
) => {
  const nSteps = 5;
  const minSpeed = 1;

  const center = useMemo(() => {
    if (!report) return undefined;
    return [report.longitude, report.latitude, use3d ? report.altitude : 0];
  }, [report, use3d]);

  const distancePerMinute = useMemo(() => {
    if (!report?.course) return 0;
    return report.speed / 60;
  }, [report]);

  const steps = useMemo(() => {
    if (!center || !report?.course) return EMPTY;
    const stepSize = niceStep(wmViewport.zoom, report.speed);
    const minutesPerStep = stepSize / nSteps;
    const s = [];
    for (let i = 0; i < nSteps; i++) {
      const n = (i + 1) * minutesPerStep;
      const point = destination(center, n * distancePerMinute, report.course, {});
      const left = destination(point, (i + 1) * 0.02 * stepSize, (report.course + 90) % 360);
      const right = destination(point, (i + 1) * 0.02 * stepSize, (report.course - 90) % 360);
      s.push({
        point: [...point.geometry.coordinates, use3d ? report.altitude : 0],
        left: [...left.geometry.coordinates, use3d ? report.altitude : 0],
        right: [...right.geometry.coordinates, use3d ? report.altitude : 0],
      });
    }
    return s;
  }, [center, distancePerMinute, report, use3d, wmViewport.zoom]);

  const lines = useMemo(() => {
    if (!center || !report) return EMPTY;
    const out = steps.map(s => [s.left, s.right]);
    const distance = niceStep(wmViewport.zoom, report.speed) * distancePerMinute;
    const dest = destination(center, distance, report.course, {});
    out.push([center, [...dest.geometry.coordinates, use3d ? report.altitude : 0]]);
    return multiLineString(out);
  }, [center, distancePerMinute, report, steps, use3d, wmViewport.zoom]);

  const text = useMemo(() => {
    if (!center || !report) return EMPTY;
    const minutesPerStep = niceStep(wmViewport.zoom, report.speed) / nSteps;
    return steps.map(((s, idx) => ({
      name: `${(idx + 1) * minutesPerStep}${idx + 1 === nSteps ? ' min' : ''}`,
      coordinates: (report.course >= 180 ? s.left : s.right) as [number, number, number],
      alignmentBaseline: 'bottom',
    })));
  }, [center, report, steps, wmViewport]);

  const layers = useMemo(() => [
    new GeoJsonLayer({
      id: `velocity-leaders-line-shadow-layer-${use3d ? '3d' : '2d'}`,
      data: lines,
      stroked: true,
      filled: false,
      pointType: 'circle',
      lineCapRounded: true,
      lineWidthMinPixels: 4,
      getLineColor: SHADOW,
      parameters: { depthTest: false },
      visible,
    }),
    new GeoJsonLayer({
      id: `velocity-leaders-line-layer-${use3d ? '3d' : '2d'}`,
      data: lines,
      stroked: true,
      filled: false,
      pointType: 'circle',
      lineCapRounded: true,
      lineWidthMinPixels: 2,
      getLineColor: WHITE,
      parameters: { depthTest: false },
      visible,
    }),
    new TextLayer<TextItem>({
      id: `velocity-leaders-label-layer-${use3d ? '3d' : '2d'}`,
      data: text,
      getText: d => d.name,
      fontWeight: 600,
      getPosition: d => d.coordinates,
      getSize: 14,
      getColor: WHITE,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'bottom',
      getPixelOffset: [0, 0],
      visible,
      parameters: { depthTest: false },
      fontSettings: {
        sdf: true,
        fontSize: 30,
        radius: 12,
      },
      fontFamily: 'objektiv-mk2,sans-serif',
      outlineColor: SHADOW,
      outlineWidth: 4,
    }),
  ] as const, [use3d, lines, visible, text]);

  return (report?.speed ?? 0) >= minSpeed ? layers : [] as const;
};

export default useVelocityLeaders;
