import React, { createContext, useCallback, useState, ReactNode, useContext } from 'react';
import DeletePersonContactDialog from 'components/dialogs/people/deletePersonContact';

type Value = (personId: number, contactId: number) => void;

const DeleteContactContext = createContext<Value>(() => undefined);

interface DeleteContactProviderProps {
  children: ReactNode
}

export const DeleteContactProvider = ({ children }: DeleteContactProviderProps): JSX.Element => {
  const [personId, setPersonId] = useState<number>();
  const [contactId, setContactId] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);

  const deleteContact = useCallback<Value>((nextPersonId, nextContactId) => {
    setPersonId(nextPersonId);
    setContactId(nextContactId);
    setOpen(true);
  }, [setPersonId, setContactId, setOpen]);

  return (
    <DeleteContactContext.Provider value={deleteContact}>
      {children}
      <DeletePersonContactDialog
        personId={personId}
        contactId={contactId}
        open={open}
        onClose={() => setOpen(false)}
      />
    </DeleteContactContext.Provider>
  );
};

export const useDeleteContact = (): Value => useContext(DeleteContactContext);
