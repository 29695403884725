import React from 'react';
import useSerialType, { SerialType } from 'hooks/settings/useSerialType';

type AssetBasicSerialType = 'deviceTracPlusSerial' | 'deviceImei' | 'deviceManufacturerSerial';

const serialTypeToKey: Record<SerialType, AssetBasicSerialType> = {
  tpSerial: 'deviceTracPlusSerial',
  imei: 'deviceImei',
  manufacturerSerial: 'deviceManufacturerSerial',
};

export const AssetBasicDeviceSerial = ({ asset }: { asset: AssetBasic }): JSX.Element | null => {
  const serialType = useSerialType();
  const value = asset[serialTypeToKey[serialType]];

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return value ? <>{value}</> : null;
};
