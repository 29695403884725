import { useContext, useMemo } from 'react';
import { CursorContext } from './cursorContext';

export const useCursor = () => {
  const { cursorPosition: contextCursorPosition, setCursorPosition: contextSetCursorPosition } = useContext(CursorContext);

  const cursorPosition = useMemo(() => (contextCursorPosition), [contextCursorPosition]);
  const setCursorPosition = useMemo(() => (contextSetCursorPosition), [contextSetCursorPosition]);

  return [cursorPosition, setCursorPosition] as const;
};
