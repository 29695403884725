import React from 'react';

const PointOfInterest = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.28,7.09l0.91-2.28H5.72c0-0.27-0.18-0.46-0.46-0.46S4.81,4.54,4.81,4.81v6.38c0,0.27,0.18,0.46,0.46,0.46
s0.46-0.18,0.46-0.46V9.37h5.46L10.28,7.09z"
    />
  </svg>
);

export default PointOfInterest;
