import React, { ComponentProps } from 'react';
import { Box, Portal, TablePagination } from '@mui/material';

interface StickyPaginationProps {
  container?: HTMLElement | (() => HTMLElement)
}

const StickyPagination = ({ container, ...rest }: StickyPaginationProps & ComponentProps<typeof TablePagination>): JSX.Element => (
  <Portal container={container}>
    <Box
      bgcolor="common.white"
      zIndex={5}
      borderTop={1}
      borderColor="common.lightGrey"
      borderRadius={1}
      sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
    >
      <TablePagination {...rest} component="div" />
    </Box>
  </Portal>
);

export default StickyPagination;
