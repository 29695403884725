import React from 'react';

const Manual = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M12.52,6.36l-2.73-0.4L8.57,3.49c-0.21-0.43-0.92-0.43-1.14,0L6.21,5.97l-2.73,0.4
C2.96,6.44,2.75,7.08,3.13,7.44L5.1,9.37l-0.47,2.72C4.55,12.61,5.09,13,5.56,12.76L8,11.48l2.44,1.29
c0.46,0.24,1.01-0.15,0.92-0.67L10.9,9.37l1.98-1.93C13.25,7.08,13.04,6.44,12.52,6.36z"
    />
  </svg>
);

export default Manual;
