import React, { ReactNode, useMemo } from 'react';
import { LabsContext, LabsContextValue, useLabs } from './labs-context';

const LabsContextProvider = ({ children, loading = false, enabled = false }: { children: ReactNode | undefined, loading?: boolean, enabled?: boolean }) => {
  const inheritted = useLabs();

  const value = useMemo<LabsContextValue>(() => {
    if (inheritted?.isLoading || inheritted?.value === false) return inheritted;
    return { isLoading: loading, value: loading ? undefined : enabled };
  }, [inheritted, loading, enabled]);

  return (
    <LabsContext.Provider value={value}>
      {children}
    </LabsContext.Provider>
  );
};

export default LabsContextProvider;
