import React from 'react';

const MD500 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 38.154297 1.1855469 C 37.67689 1.233505 37.254391 1.5531866 37.105469 2.03125 L 34.337891 10.917969 C 33.044455 9.9791311 31.857422 9.8183594 31.857422 9.8183594 C 31.857422 9.8183594 26.684382 10.557345 26.279297 17.701172 L 13.572266 9.0292969 C 13.013226 8.647792 12.247847 8.7799094 11.857422 9.3261719 L 10.796875 10.8125 C 10.406447 11.358758 10.540568 12.104829 11.099609 12.486328 L 26.455078 22.962891 C 26.528469 23.788115 26.622276 24.556316 26.726562 25.291016 L 10.914062 36.962891 C 10.574019 37.213915 10.404625 37.601118 10.425781 37.986328 C 10.438481 38.217455 10.519695 38.449074 10.673828 38.648438 L 11.791016 40.091797 C 12.202047 40.623431 12.971555 40.729763 13.515625 40.328125 L 27.669922 29.878906 C 28.809542 33.934598 30.21875 35.613281 30.21875 35.613281 L 30.650391 52.707031 L 26.212891 53.539062 L 26.212891 56.859375 L 32.193359 56.777344 L 38.173828 56.779297 L 38.126953 53.457031 L 33.677734 52.685547 L 33.837891 38.027344 L 37.328125 47.890625 C 37.550913 48.520353 38.248121 48.852459 38.892578 48.634766 L 40.644531 48.041016 C 41.288988 47.823323 41.629039 47.141446 41.40625 46.511719 L 35.957031 31.105469 C 36.326663 29.945608 36.680918 28.541052 36.966797 26.861328 L 55.380859 26.525391 C 56.062619 26.512951 56.60258 25.966957 56.589844 25.300781 L 56.554688 23.488281 C 56.541958 22.822104 55.98254 22.298104 55.300781 22.310547 L 37.460938 22.636719 C 37.545204 21.424557 37.596168 20.127466 37.591797 18.716797 C 37.588459 17.639858 37.483366 16.695058 37.308594 15.861328 L 41.234375 3.2597656 C 41.432936 2.6223469 41.068342 1.9518375 40.416016 1.7578125 L 38.642578 1.2324219 C 38.479496 1.1839155 38.313432 1.1695608 38.154297 1.1855469 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default MD500;
