import React, { useEffect, useMemo, useRef } from 'react';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import ReactMapGl, { AttributionControl, Layer, MapRef, Source } from 'react-map-gl';
import { Box } from '@mui/system';
import type { FeatureCollection, MultiPolygon } from 'geojson';
import { safeBounds } from 'helpers/geo';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { useSize } from 'hooks/useSize';
import type { GeofenceListItem } from './types';

export type Geofence = Pick<GeofenceListItem, 'id' | 'name' | 'geometry' | 'category'>

export interface ReadonlyGeofencingMapProps {
  geofence: Geofence
}

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const GEOFENCE_COLORS: Record<Geofence['category'], [number, number, number, number]> = {
  AreaOfOperation: [0, 255, 0, 0.5],
  RestrictedArea: [255, 0, 0, 0.5],
  Generic: [255, 255, 0, 0.5],
};

const getGeofenceColor = (geofence: Geofence) => GEOFENCE_COLORS[geofence.category];

export const ReadonlyGeofencingMap = ({ geofence }: ReadonlyGeofencingMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const mapTemplate = useMapTemplate();

  const geoJson = useMemo((): FeatureCollection<MultiPolygon> => ({
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      properties: {
        id: geofence.id,
        name: geofence.name,
        color: `rgba(${getGeofenceColor(geofence).join(',')})`,
      },
      geometry: geofence.geometry
    }]
  }), [geofence]);

  const { width, height } = useSize(ref);

  const viewport = useMemo(() => {
    const view = new WebMercatorViewport({ width, height });
    const bounds = safeBounds(geoJson);
    if (bounds && !Number.isNaN(bounds[0][0])) {
      view.fitBounds(bounds, { padding: Math.min(width, height, 20) });
    }
    return { ...view };
  }, [width, height, geoJson]);

  useEffect(() => {
    if (geoJson) {
      const bounds = safeBounds(geoJson);
      if (bounds && !Number.isNaN(bounds[0][0])) {
        mapRef.current?.getMap()
          .fitBounds(bounds, { padding: Math.min(width, height, 20) });
      }
    }
  }, [width, height, geoJson]);

  return (
    <Box
      ref={ref}
      sx={theme => ({
        width: '100%',
        height,
        minHeight: 300,
        minWidth: 300,
        borderLeft: theme.border.default,
        position: 'sticky',
        top: -50 + theme.spacingNumber(3)
      })}>
      <ReactMapGl
        ref={mapRef}
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={viewport}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        attributionControl
      >
        <Source
          type="geojson"
          data={geoJson}
        >
          <Layer id="unselectedLayers" type="fill" paint={{ 'fill-color': ['get', 'color'] }} />
          <Layer id="unselectedLayersBorder" type="line" paint={{ 'line-color': '#FFF', 'line-width': 1 }} />
        </Source>
      </ReactMapGl>
    </Box>
  );
};
