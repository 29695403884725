import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  MenuItem,
  TextField,
} from '@mui/material';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  title: string;
  description: string;
  options: { label: string, value: string }[],
  defaultOption?: string,
  disabled?: boolean;
}

export const SelectDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  options,
  defaultOption,
  disabled,
}: SelectDialogProps) => {
  const [value, setValue] = useState<string>(defaultOption ?? options.at(0)?.value ?? '');

  const onEnter = () => {
    setValue(defaultOption ?? options.at(0)?.value ?? '');
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" TransitionProps={{ onEnter }}>
      <TPDialogTitle id="form-dialog-title">{title}</TPDialogTitle>
      <DialogContent>
        <TextField
          select
          value={value}
          onChange={e => setValue(e.target.value)}
          fullWidth
          sx={{ py: 3 }}
        >
          {options.map(opt => (
            <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
          ))}
        </TextField>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <TPDialogActions>
        <Button autoFocus onClick={onClose} disabled={disabled}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onConfirm(value)} color="primary" disabled={disabled}>
          Confirm
        </Button>
      </TPDialogActions>
    </Dialog>
  );
};
