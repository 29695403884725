import React, { useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Feature, FeatureCollection, MultiPolygon } from 'geojson';
import { Layer, MapboxEvent, MapMouseEvent, Source, useMap } from 'react-map-gl';
import type { GeofenceCategory, GeofenceResponseItem } from 'apis/rest/geofence/types';
import { Pattern, PatternType } from 'components/shared/patterns/Pattern';
import { useGetGeofences } from 'apis/rest/geofence/hooks';

interface GeofenceLayersProps {
  hoveredGeofenceIds: number[];
  setHoveredGeofences: (geofences: GeofenceResponseItem[]) => void;
  filterIds?: number[];
}

type PatternMapValue = {
  category: GeofenceCategory,
  pattern: PatternType,
  color: string,
};

const PATTERN_MAP: readonly PatternMapValue[] = [{
  category: 'Generic',
  pattern: 'DiagonalStripsLeft',
  color: '#f6e67c'
}, {
  category: 'RestrictedArea',
  pattern: 'DiagonalStripsRight',
  color: '#ff8025',
}, {
  category: 'AreaOfOperation',
  pattern: 'HorizontalStrips',
  color: '#72C472',
}] as const;

const getPatternId = (val: PatternMapValue) => `${val.pattern}-${val.category}`;

const getPatternName = (category: GeofenceCategory) => {
  const val = PATTERN_MAP.find(p => p.category === category);
  if (!val) {
    throw new Error('could not find pattern map value');
  }
  return getPatternId(val);
};

const rasterize = (pattern: PatternType, color: string, size = 50, interpolation = 1): Promise<string> => {
  const canvas = document.createElement('canvas');
  canvas.width = size * interpolation;
  canvas.height = size * interpolation;
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onerror = reject;
    image.onload = () => {
      const context = canvas.getContext('2d');
      for (let i = 0; i < interpolation; i++) {
        for (let j = 0; j < interpolation; j++) {
          context?.drawImage(image, i * size, j * size, size, size);
        }
      }
      resolve(canvas.toDataURL('image/png'));
    };
    image.src = `data:image/svg+xml;base64,${window.btoa(ReactDOMServer.renderToString(<Pattern type={pattern} color={color} />))}`;
  });
};

export const GeofenceLayers = ({ hoveredGeofenceIds, setHoveredGeofences, filterIds }: GeofenceLayersProps) => {
  const geofenceData = useGetGeofences().data;
  const geofences = filterIds?.length ? geofenceData?.filter(g => filterIds.includes(g.id)) : geofenceData;
  const map = useMap().current;
  const [firstSymbolLayer, setFirstSymbolLayer] = useState('');

  const [previousHoveredIds, setPreviousHoveredIds] = useState<string>('');

  const geofenceFeatureCollection = useMemo((): FeatureCollection<MultiPolygon> => ({
    type: 'FeatureCollection',
    features: (geofences ?? []).map(g => ({
      type: 'Feature',
      properties: {
        id: g.id,
        name: g.name,
        pattern: getPatternName(g.category),
        borderWidth: hoveredGeofenceIds.includes(g.id) ? 2 : 1,
        borderOpacity: hoveredGeofenceIds.includes(g.id) ? 1 : 0.5,
      },
      geometry: g.geometry
    }))
  }), [geofences, hoveredGeofenceIds]);

  useEffect(() => {
    const imageMissingListener = (e: MapboxEvent & { id: string }) => {
      PATTERN_MAP.forEach(p => {
        if (e.id !== getPatternId(p)) { return; }
        rasterize(p.pattern, p.color).then(png => e.target.loadImage(png, (err, img) => {
          if (err) throw err;
          if (img && !e.target.hasImage(getPatternId(p))) {
            e.target.addImage(getPatternId(p), img);
          }
        }));
      });
    };

    const loadingListener = (e: MapboxEvent) => {
      const { layers } = e.target.getStyle();
      setFirstSymbolLayer(layers.find(l => l.type === 'symbol')?.id ?? '');
    };

    const hoverListener = (e: MapMouseEvent & { features?: Feature[] }) => {
      const featureIds = [...new Set(e?.features?.map(feat => feat.properties?.id).sort())];
      const stringifiedArr = featureIds.join(',');
      if (stringifiedArr !== previousHoveredIds) {
        setPreviousHoveredIds(stringifiedArr);
        const matchingGeofences = e.features
          ?.map(f => geofences?.find(g => g.id === f.properties?.id))
          .filter(l => l !== undefined) as GeofenceResponseItem[] | undefined;
        if (matchingGeofences) {
          const uniqueGeofences = matchingGeofences
            .filter((val, idx) => matchingGeofences.indexOf(val) === idx)
            .toSorted((a, b) => a.id - b.id);
          setHoveredGeofences(uniqueGeofences);
        }
      }
    };

    const leaveListener = () => {
      setPreviousHoveredIds('');
      setHoveredGeofences([]);
    };

    map?.getMap().on('styleimagemissing', imageMissingListener);
    map?.getMap().on('styledata', loadingListener);
    map?.getMap().on('mousemove', 'geofences', hoverListener);
    map?.getMap().on('mouseleave', 'geofences', leaveListener);
    return () => {
      setFirstSymbolLayer('');
      map?.getMap().off('styleimagemissing', imageMissingListener);
      map?.getMap().off('styledata', loadingListener);
      map?.getMap().off('mousemove', 'geofences', hoverListener);
      map?.getMap().off('mouseleave', 'geofences', leaveListener);
    };
  }, [geofences, map, previousHoveredIds, setHoveredGeofences]);

  return (
    <Source type="geojson" data={geofenceFeatureCollection} id="geofenceJson">
      <Layer
        id="geofences"
        beforeId={firstSymbolLayer}
        type="fill"
        paint={{
          'fill-pattern': ['get', 'pattern'],
        }}
        interactive
      />
      <Layer
        id="geofencesBorder"
        beforeId={firstSymbolLayer}
        type="line"
        paint={{ 'line-color': '#FFF', 'line-width': ['get', 'borderWidth'], 'line-opacity': ['get', 'borderOpacity'] }}
      />
    </Source>
  );
};
