import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'utils/moment';
import { useDateTime } from 'hooks/settings';

const TIME_FORMAT = 'HH:mm:ss z';

interface ClocksProps {
  clocks: string[]
}

const Clocks = ({ clocks }: ClocksProps) => {
  // Create a clock and "tick" it every 1000ms
  const [time, updateTime] = useState(() => moment());
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  });

  const timeStringLocal = useDateTime(time, TIME_FORMAT);
  const timeStringClock = useDateTime(time, TIME_FORMAT, clocks[0]);
  const text = timeStringLocal === timeStringClock ? timeStringClock : `${timeStringLocal}  ${timeStringClock}`;

  return (
    <Box width={200} mr="100px" alignSelf="center" ml={1}>
      <Typography fontSize="0.9em" align="left" color="common.text">{text}</Typography>
    </Box>
  );
};

export default Clocks;
