import React, { useState } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Button, Paper, Stack } from '@mui/material';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';

interface GeofenceDeleteViewProps {
  onDelete: () => void
  isLoading: boolean
}

const GeofenceDeleteView: React.FC<GeofenceDeleteViewProps> = ({ onDelete, isLoading }) => {
  const t = useTranslations('pages.manage.geofencing');
  const [open, setOpen] = useState(false);

  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {t('removeThisGeofence')}
        <Button
          color="error"
          variant="outlined"
          sx={{ minWidth: '10rem', height: '4rem' }}
          onClick={() => setOpen(true)}
          disabled={open || isLoading}
        >
          {t('remove')}
        </Button>
      </Stack>
      <ConfirmationDialog
        confirmText={t(isLoading ? 'dialogs.delete.confirmTextPending' : 'dialogs.delete.confirmText')}
        title={t('dialogs.delete.title')}
        confirmButtonProps={{ color: 'error', variant: 'contained', size: 'large', sx: { minWidth: '10rem' }, disabled: isLoading }}
        cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' }, disabled: isLoading }}
        cancelText={t('dialogs.cancelText')}
        onConfirm={() => onDelete()}
        onCancel={() => setOpen(false)}
        dialogProps={{ open }}
      >
        <Box my={3}>{t('dialogs.delete.text')}</Box>
      </ConfirmationDialog>
    </Paper>
  );
};

export default GeofenceDeleteView;
