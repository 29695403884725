/* eslint-disable react/jsx-one-expression-per-line */
import React, { SetStateAction, useCallback } from 'react';
import {
  Typography, Box, Paper, Tooltip, ListItemText, IconButton
} from '@mui/material';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import JSZip from 'jszip';

import { useTranslations } from 'use-intl';
import DragAndDropList from 'components/shared/dragAndDropList';
import ldb from 'helpers/ldb';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import kmlConverter from 'services/kmlConverter';
import { KmlOrder, KmlStatuses } from './useKmlManager';

interface KmlLoaderProps {
  kmlOrder: KmlOrder
  kmlStatus: KmlStatuses,
  setKmlStatus: React.Dispatch<SetStateAction<KmlStatuses>>
  setKmlOrder: React.Dispatch<SetStateAction<KmlOrder>>
}

const KmlLoader = ({ kmlOrder, setKmlOrder, kmlStatus, setKmlStatus }: KmlLoaderProps) => {
  const t = useTranslations('pages.map.settingsDialog');

  const storeKmlFile = useCallback((filename: string, fileContents: string) => {
    let filenameToUse = filename;
    let counter = 0;
    while (kmlOrder.includes(filenameToUse)) {
      filenameToUse = filename + counter.toString();
      counter++;
    }

    ldb.set(filenameToUse, fileContents, () => {
      setKmlOrder([...kmlOrder, filenameToUse]);
      setKmlStatus(oldKmlStatus => ({ ...oldKmlStatus, [filenameToUse]: 'visible' }));
    });
  }, [setKmlOrder, kmlOrder, setKmlStatus]);

  const removeKml = useCallback((kmlFilename: string) => {
    ldb.delete(kmlFilename);
    ldb.delete(`json-${kmlFilename}`);

    setKmlStatus(oldStatus => {
      const copy = { ...oldStatus };
      delete copy[kmlFilename];
      return copy;
    });
    setKmlOrder(oldKmlOrder => oldKmlOrder.filter(k => k !== kmlFilename));
  }, [setKmlStatus, setKmlOrder]);

  const toggleVisibility = useCallback((kmlFilename: string) => {
    setKmlStatus(oldStatus => {
      const copy = { ...oldStatus };
      copy[kmlFilename] = copy[kmlFilename] === 'visible' ? 'hidden' : 'visible';
      return copy;
    });
  }, [setKmlStatus]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (monitor) {
        // @ts-ignore
        const { files } = monitor.getItem();
        const extension = files[0].name.split('.').pop().toLowerCase();
        const file = files[0];
        if (file.name.toLowerCase() === 'markers.xml') {
          console.log('Reading Markers file...');
          const reader = new FileReader();
          reader.onload = e => {
            if (e.target && typeof e.target.result === 'string') {
              storeKmlFile(files[0].name, kmlConverter(e.target.result));
            }
          };
          reader.readAsText(file);
        } else if (extension === 'kml') {
          console.log('Reading KML file...');
          const reader = new FileReader();
          reader.onload = e => {
            if (e.target && typeof e.target.result === 'string') {
              storeKmlFile(files[0].name, e.target.result);
            }
          };
          reader.readAsText(file);
        } else if (extension === 'kmz') {
          const zip = new JSZip();
          zip.loadAsync(file)
            .then(zf => {
              // For now just extract the root doc.kml document. KMZ files can contain other stuff too, and doc.kml is only a convention not a requirement so we might want to be more robust on that in the future.
              zf.file('doc.kml')?.async('string').then(data => {
                storeKmlFile(files[0].name, data);
              }, () => console.warn('Failed to extract KML from KMZ'));
            });
        } else {
          console.warn(`Unknown extension: ${extension}. Only KML and KMZ files are supported.`);
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  // URL based loading is not enabled for now.
  return (
    <Box>
      <Paper sx={{ m: '8px', textAlign: 'center', bgcolor: isOver ? '#4A525C' : undefined }} ref={drop} variant="outlined">
        <Typography style={{ userSelect: 'none', marginTop: '8px', whiteSpace: 'pre-wrap' }}>{(canDrop && isOver) ? t('dropFile') : t('dropFilesHere')}</Typography>
        <Typography style={{
          fontSize: '64px', opacity: '0.4', userSelect: 'none', marginTop: '-18px'
        }}
        >+
        </Typography>
      </Paper>
      <DragAndDropList
        items={[...kmlOrder].reverse()}
        onReorder={setKmlOrder}
        itemMap={item => (
          <Tooltip title={item}>
            <ListItemText
              key={item}
              primary={item}
              primaryTypographyProps={{ style: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
            />
          </Tooltip>
        )}
        secondaryActionMap={item => (
          <>
            <IconButton onClick={() => toggleVisibility(item)}>{kmlStatus[item] === 'visible' ? <Visibility /> : <VisibilityOff />}</IconButton>
            <IconButton onClick={() => removeKml(item)}><DeleteIcon /></IconButton>
          </>
        )}
      />
    </Box>
  );
};

export default KmlLoader;
