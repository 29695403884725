import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useGetCapabilitiesForDevice } from 'apis/rest/devices/hooks';
import {
  Alert,
  Box,
  Button,
  CircularProgress, Collapse,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { supportsAMS } from 'helpers/deviceSupport';
import useTranslation from 'hooks/useTranslation';
import useSnackbar from 'hooks/useSnackbar';
import { useDeregisterAms, useGetAmsSettings, useGetAssetBasic, useSetAmsSettings } from 'apis/rest/assets/hooks';
import {
  ChevronRight,
  NearMeOutlined,
  NotificationImportantOutlined,
  NotificationsOutlined,
  SvgIconComponent
} from '@mui/icons-material';

interface AssetAmsViewProps {
    assetId: number
}

const StateDescriptionView = ({
  name,
  Icon
}: { name: string, Icon: SvgIconComponent }): JSX.Element => (
  <Stack alignItems="center" spacing={1}>
    <Icon fontSize="large" />
    <Typography fontWeight="bold">{name}</Typography>
  </Stack>
);

const lineBreak = () => <br />;
const noLineBreak = () => <></>;

const DividerHorizontal = ({ arrowhead = false }): JSX.Element => (
  arrowhead ? (
    <Box height="1px" sx={{ flexGrow: 1, position: 'relative' }} border={theme => theme.border.default}>
      <ChevronRight sx={{ position: 'absolute', right: '-0.5em', top: '-0.5em', color: 'common.midGrey' }} fontSize="large" />
    </Box>
  ) : <Box height="1px" sx={{ flexGrow: 1 }} border={theme => theme.border.default} />
);

const Transition = ({ time, stationaryLimit, stationaryDisabled }: { time: number, stationaryLimit: number, stationaryDisabled: boolean}): JSX.Element => {
  const t = useTranslation('pages.assetView.amsSettings');

  return (
    <Stack alignItems="center">
      {
      stationaryDisabled
        ? <Typography textAlign="center">{t.rich('minutesWithoutReporting', { time, br: lineBreak })}</Typography>
        : (
          <Box sx={{ position: 'relative' }}>
            <Typography sx={{ position: 'absolute', bottom: '100%', left: '50%', transform: 'translate(-50%)', width: 'max-content' }}>
              {t.rich('minutesWithoutReporting', { time, br: noLineBreak })}
            </Typography>
            <Typography variant="overline">{t('or')}</Typography>
            <Typography sx={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%)', width: 'max-content' }}>
              {t('stationaryReports', { reports: stationaryLimit })}
            </Typography>
          </Box>
        )
    }
    </Stack>
  );
};

const AmsDiagram = ({
  amsSettings,
  stationaryDisabled
}: { amsSettings: AMSSettings, stationaryDisabled: boolean}): JSX.Element => {
  const t = useTranslation('pages.assetView.amsSettings');

  return (
    <Stack spacing={2} direction="row" sx={{ borderRadius: 1 }} p={3} justifyContent="space-between" alignItems="center" border={theme => theme.border.default}>
      <StateDescriptionView name={t('normalTracking')} Icon={NearMeOutlined} />
      <DividerHorizontal />
      <Transition time={amsSettings.ofConcernTimeoutMinutes} stationaryLimit={amsSettings.stationaryLimit || 0} stationaryDisabled={stationaryDisabled} />
      <DividerHorizontal arrowhead />
      <StateDescriptionView name={t('ofConcern')} Icon={NotificationsOutlined} />
      <DividerHorizontal />
      <Transition time={amsSettings.overdueTimeoutMinutes} stationaryLimit={amsSettings.stationaryLimit || 0} stationaryDisabled={stationaryDisabled} />
      <DividerHorizontal arrowhead />
      <StateDescriptionView name={t('overdue')} Icon={NotificationImportantOutlined} />
    </Stack>
  );
};

const AssetAmsView = ({
  assetId
}: AssetAmsViewProps): JSX.Element => {
  const asset = useGetAssetBasic(assetId).query.data;
  const deviceId = asset?.deviceId || -1;
  const capabilitiesQuery = useGetCapabilitiesForDevice(deviceId);
  const amsEnabled = capabilitiesQuery.data?.find(supportsAMS);
  const t = useTranslation('pages.assetView.amsSettings');
  const inputSx = { width: '20rem' };
  const amsSettingsQuery = useGetAmsSettings(assetId);
  const amsSettings = amsSettingsQuery.data;
  const [uncommittedSettings, setUncommittedSettings] = useState<AMSSettings>(() => {
    if (amsSettings) {
      return { ...amsSettings };
    }
    return { assetId, stationaryLimit: undefined, ofConcernTimeoutMinutes: 10, overdueTimeoutMinutes: 10 };
  });
  const [stationaryDisabled, setStationaryDisabled] = useState((amsSettings?.stationaryLimit || -1) < 0);
  const isDeregistered = amsSettings === undefined && !amsSettingsQuery.isLoading;

  const [dialogOpen, setDialogOpen] = useState(false);

  const updateAmsSettings = useSetAmsSettings();
  const deregisterAms = useDeregisterAms();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (amsSettings) {
      setUncommittedSettings(amsSettings);
      setStationaryDisabled((amsSettings.stationaryLimit || -1) <= 0);
    }
  }, [amsSettings]);

  useEffect(() => {
    if (!stationaryDisabled && amsSettings && uncommittedSettings.stationaryLimit === undefined) {
      setUncommittedSettings({ ...amsSettings, stationaryLimit: 10 });
    }
  }, [amsSettings, stationaryDisabled, uncommittedSettings.stationaryLimit]);

  const validateNumber = useCallback(input => {
    const int = parseInt(input, 10);
    if (Number.isNaN(int)) {
      return 0;
    }
    return int;
  }, []);

  const updateSettings = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: 'stationaryLimit' | 'overdueTimeoutMinutes' | 'ofConcernTimeoutMinutes'): void => {
    const num = validateNumber(event.target.value);
    setUncommittedSettings({ ...uncommittedSettings, [field]: num });
  }, [setUncommittedSettings, uncommittedSettings, validateNumber]);

  const ofConcernValid = useMemo(() => uncommittedSettings.ofConcernTimeoutMinutes > 0 && uncommittedSettings.ofConcernTimeoutMinutes < 100, [uncommittedSettings]);
  const overdueValid = useMemo(() => uncommittedSettings.overdueTimeoutMinutes > 0 && uncommittedSettings.overdueTimeoutMinutes < 100, [uncommittedSettings]);
  const stationaryValid = useMemo(() => stationaryDisabled || (uncommittedSettings.stationaryLimit ?? -1) > 0, [uncommittedSettings, stationaryDisabled]);
  const contentUnchanged = useMemo(() => uncommittedSettings.ofConcernTimeoutMinutes === amsSettings?.ofConcernTimeoutMinutes
          && uncommittedSettings.overdueTimeoutMinutes === amsSettings?.overdueTimeoutMinutes
          && (stationaryDisabled ? amsSettings?.stationaryLimit === 0 || amsSettings?.stationaryLimit === null : amsSettings?.stationaryLimit === uncommittedSettings.stationaryLimit), [uncommittedSettings, stationaryDisabled, amsSettings]);

  const allValid = useMemo(() => ofConcernValid && overdueValid && stationaryValid, [ofConcernValid, overdueValid, stationaryValid]);

  const cancel = useCallback(() => {
    if (amsSettings) {
      const committedStationaryDisabled = (amsSettings.stationaryLimit || -1) <= 0;
      setStationaryDisabled(committedStationaryDisabled);
      if (committedStationaryDisabled) {
        setUncommittedSettings({ ...amsSettings, stationaryLimit: undefined });
      } else {
        setUncommittedSettings({ ...amsSettings });
      }
    }
  }, [setUncommittedSettings, amsSettings]);

  const save = useCallback(() => {
    if (stationaryDisabled) {
      updateAmsSettings.mutate({ ...uncommittedSettings, stationaryLimit: undefined }, {
        onSuccess: () => snackbar.display({ id: 'updateAmsSuccess', type: 'success', text: t('amsUpdated') }),
        onError: () => snackbar.display({ id: 'updateAmsError', type: 'error', text: t('updateAmsError') })
      });
    } else {
      updateAmsSettings.mutate(uncommittedSettings, {
        onSuccess: () => snackbar.display({ id: 'updateAmsSuccess', type: 'success', text: t('amsUpdated') }),
        onError: () => snackbar.display({ id: 'updateAmsError', type: 'error', text: t('updateAmsError') })
      });
    }
  }, [stationaryDisabled, updateAmsSettings, uncommittedSettings, snackbar, t]);

  const deregister = useCallback(() => {
    setDialogOpen(false);
    deregisterAms.mutate({ assetId }, {
      onSuccess: () => snackbar.display({ id: 'removeAmsSuccess', type: 'success', text: t('amsUnset') }),
      onError: () => snackbar.display({ id: 'removeAmsFailed', type: 'error', text: t('amsUnsetError') })
    });
  }, [assetId, deregisterAms, snackbar, t]);

  if (capabilitiesQuery.isInitialLoading) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel>
          <Box py={2}><LoadingIcon size={50} /></Box>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (!amsEnabled) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel>
          <Typography variant="h2" width="100%" textAlign="center" py={4} color="common.text">{t('assetUnsupported')}</Typography>
        </DetailPanel>
      </SettingsSection>
    );
  }

  return (
    <>
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel>
          <Stack spacing={3} px={3}>
            <Collapse in={isDeregistered}><Stack pb={2}><Alert severity="warning">{t('assetDeregistered')}</Alert></Stack></Collapse>
            <Stack spacing={3} direction="row">
              <TextField
                sx={inputSx}
                label={t('ofConcernTimeout')}
                value={uncommittedSettings?.ofConcernTimeoutMinutes}
                onChange={e => updateSettings(e, 'ofConcernTimeoutMinutes')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('minutes')}</InputAdornment>
                }}
                error={!ofConcernValid}
                helperText={ofConcernValid ? undefined : t('ofConcernInvalid')}
              />
              <TextField
                sx={inputSx}
                label={t('overdueTimeout')}
                value={uncommittedSettings?.overdueTimeoutMinutes.toString(10)}
                onChange={e => updateSettings(e, 'overdueTimeoutMinutes')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('minutes')}</InputAdornment>
                }}
                error={!overdueValid}
                helperText={overdueValid ? undefined : t('overdueInvalid')}
              />
            </Stack>
            <Stack spacing={3} direction="row">
              <TextField
                sx={inputSx}
                label={t('stationaryThreshold')}
                value={stationaryDisabled ? '' : uncommittedSettings?.stationaryLimit}
                onChange={e => updateSettings(e, 'stationaryLimit')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('reports')}</InputAdornment>
                }}
                disabled={stationaryDisabled}
                error={!stationaryValid}
                helperText={stationaryValid ? undefined : t('stationaryInvalid')}
              />
              <FormControlLabel control={<Switch checked={!stationaryDisabled} onChange={e => setStationaryDisabled(!e.target.checked)} />} label={t('stationaryEnabled')} />
            </Stack>
            <AmsDiagram amsSettings={uncommittedSettings} stationaryDisabled={stationaryDisabled} />
            <Stack direction="row" justifyContent="space-between" height="4rem">
              <Button size="large" variant="contained" color="error" sx={{ minWidth: '10rem' }} disabled={isDeregistered} onClick={() => setDialogOpen(true)}>
                {deregisterAms.isLoading ? <CircularProgress size="2rem" color="secondary" /> : t('deregisterButton')}
              </Button>
              <Stack spacing={3} direction="row">
                <Button size="large" variant="outlined" sx={{ minWidth: '10rem' }} disabled={contentUnchanged || isDeregistered} onClick={() => cancel()}>
                  {t('cancelButton')}
                </Button>
                <Button size="large" variant="contained" sx={{ minWidth: '10rem' }} disabled={contentUnchanged || !allValid} onClick={() => save()}>
                  {updateAmsSettings.isLoading ? <CircularProgress size="2rem" color="secondary" /> : t(isDeregistered ? 'registerButton' : 'saveButton')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DetailPanel>
      </SettingsSection>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {t('confirmDeregisterTitle')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('confirmDeregisterDescription')}
          </Typography>
        </DialogContent>
        <DialogActions sx={theme => ({ p: 3, borderTop: theme.border.default })}>
          <Stack direction="row" spacing={3} height="4rem">
            <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => setDialogOpen(false)}>
              {t('cancelButton')}
            </Button>
            <Button variant="contained" sx={{ minWidth: '10rem' }} onClick={() => deregister()} color="error">
              {deregisterAms.isLoading ? <CircularProgress size="2rem" color="secondary" /> : t('deregisterButton')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetAmsView;
