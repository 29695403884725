import React from 'react';
import { Box } from '@mui/material';
import { useTranslations } from 'use-intl';
import { ClassNameMap } from '@mui/styles';
import { GetApp } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import tableIcons from 'components/shared/icons/tableIcons';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import insensitiveSort from 'utils/insensitiveSort';
import { fetchMissionReports } from 'apis/trackstar/serenity';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import useDuration from 'hooks/units/useDuration';
import useStyles from '../reporting-styles';

const MissionReports = (): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslations('pages.reporting');
  const duration = useDuration();

  const getMissionReports = useQuery({
    queryKey: ['missionReports'],
    queryFn: () => fetchMissionReports()
  });
  if (getMissionReports.isError) console.log(getMissionReports.error);

  const data = getMissionReports.isSuccess ? getMissionReports.data.map(mr => {
    const millisDiff = DateTime.fromISO(mr.endTime).toMillis() - DateTime.fromISO(mr.startTime).toMillis();
    return ({
      ...mr,
      asset: `${mr.assetRegistration} (${mr.assetMake} ${mr.assetModel})`,
      duration: duration.fromMillis(millisDiff, undefined, duration.findFormat(millisDiff)),
    });
  }) : [];

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  return (
    <Box
      className={classes.materialTable}
      bgcolor="common.white"
      borderRadius={1}
      border={1}
      borderColor="common.midGrey"
      mb={8}
    >
      <PersistentTable
        settingsCategory="missionReportsTable"
        icons={tableIcons}
        actions={[{
          icon: () => <GetApp />,
          iconProps: { color: 'primary' },
          tooltip: t('downloadPDF'),
          onClick: (_: Event, rowData) => {
            if (Array.isArray(rowData)) return;
            const element = document.createElement('a');
            element.setAttribute('href', rowData.reportUrl);
            element.setAttribute('download', rowData.reportUrl);
            element.setAttribute('target', '_blank');
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            mixpanel.track('Download Mission Report', { missionReport: rowData });
          },
        }]}
        isLoading={getMissionReports.isLoading}
        data={data}
        columns={[
          {
            title: t('missionId'),
            field: 'missionId',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            defaultSort: 'asc',
            customSort: (a: MissionReport, b: MissionReport) => insensitiveSort(a.missionId, b.missionId),
            render: (rowData: MissionReport) => rowData.missionId || '-',
          },
          {
            title: t('asset'),
            field: 'asset',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
          },
          {
            title: t('start'),
            field: 'startTime',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            render: (rowData: MissionReport) => DateTime.fromISO(rowData.startTime).toFormat('d MMM yyyy, HH:mm:ss'),
          },
          {
            title: t('end'),
            field: 'endTime',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
            render: (rowData: MissionReport) => DateTime.fromISO(rowData.endTime).toFormat('d MMM yyyy, HH:mm:ss'),
          },
          {
            title: t('duration'),
            field: 'duration',
            headerStyle: { textAlign: 'left' },
            cellStyle: { textAlign: 'left' },
          }
        ]}
        options={{
          draggable: false,
          showTitle: false,
          search: true,
          paging: true,
          pageSizeOptions: [10, 25, 50, 100],
          pageSize: 10,
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          searchFieldVariant: 'outlined',
          thirdSortClick: false,
        }}
        localization={{
          header: {
            actions: t('download')
          }
        }}
        components={{
          Container: Box,
          Pagination,
        }}
      />
      <Box ref={footerRef} position="sticky" bottom={0} />
    </Box>
  );
};

export default MissionReports;
