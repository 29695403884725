import { useMemo, useRef } from 'react';
import { Polyline } from 'repositories/reports/spline';

/**
 * haversineKm computes the distance, in kilometers, between two points on the
 * globe using the Haversine formula.
 */
const haversineKm = (latStart: number, lonStart: number, latEnd: number, lonEnd: number): number => {
  if ((latStart === latEnd) && (lonStart === latEnd)) {
    return 0;
  }

  const radlat1 = (Math.PI * latStart) / 180;
  const radlat2 = (Math.PI * latEnd) / 180;
  const theta = lonStart - lonEnd;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;
  return dist;
};

export const calculateDistanceTravelled = (assetTrails: Record<number, Polyline[]>): Record<number, number[][]> => Object.entries(assetTrails).reduce((prev, [assetId, trails]) => {
  prev[parseInt(assetId, 10)] = trails.map(trail => {
    let distance = 0;
    return trail.map((t, i) => {
      if (i === 0) return 0;
      if (Number.isFinite(t[0]) && Number.isFinite(t[1])) {
        const l = trail[i - 1];
        distance += haversineKm(l[0], l[1], t[0], t[1]);
      }
      return distance;
    });
  });
  return prev;
}, {} as Record<number, number[][]>);

/**
 * Compute the total distance traveled by assets, upto each report, in
 * kilometers.
 */
export default (assetTrails: Record<number, Polyline[]>): Record<number, number[][]> => {
  const distanceTraveled = useRef<Record<number, number[][]>>({});
  distanceTraveled.current = useMemo(() => calculateDistanceTravelled(assetTrails), [assetTrails]);

  return distanceTraveled.current;
};
