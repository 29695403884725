import React from 'react';

const Tug = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 41.735184,3.9126731 c 6.025324,6.6461219 6.064961,16.7236669 6.134685,21.4472449 0.124707,8.448612 -1.631057,21.355328 -3.526237,26.760752 -3.261414,9.302192 -8.84633,9.854139 -11.20667,9.854139 -2.312035,0 -8.71813,-0.551947 -11.979544,-9.854139 -1.89518,-5.405424 -3.650944,-18.31214 -3.526237,-26.760752 0.06972,-4.723578 0.109361,-14.801123 6.134685,-21.4472449 1.607851,-1.7735103 8.960506,-1.4974429 8.960506,-1.4974429 0,0 7.400961,-0.2760674 9.008812,1.4974429 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Tug;
