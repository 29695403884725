import Smart from 'hocs/smart';
import MeasurementPanel from './measurementPanel-view';

export default Smart(
  MeasurementPanel,
  (state, ownProps) => ({
    selectedItemId: state.app.selectedItem?.id,
    measurementMarker: state.map.measurementMarkers[ownProps.mapId].currentMeasurementMarker,
    selectedAssets: state.map.selectedAssets,
  }),
  () => ({ })
);
