import React from 'react';
import { IconProps } from './pin';

const CircleIcon = ({
  colors,
  outlineOpacity,
  size
}: IconProps) => (
  <svg height={`${size.height}px`}
    width={`${size.width}px`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2a1 1 0 0 1 2 0v2.062A8.004 8.004 0 0 1 19.938 11H22a1 1 0 0 1 0 2h-2.062A8.004 8.004 0 0 1 13 19.938V22a1 1 0 0 1-2 0v-2.062A8.004 8.004 0 0 1 4.062 13H2a1 1 0 0 1 0-2h2.062A8.004 8.004 0 0 1 11 4.062V2zm7 10a6 6 0 1 0-12 0 6 6 0 0 0 12 0z"
      fill={colors.fill}
      stroke={colors.outline}
      strokeWidth={0.75}
      strokeOpacity={outlineOpacity} />
    <path
      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
      fill={colors.fill}
      stroke={colors.outline}
      strokeWidth={0.75} />
  </svg>
);

export default CircleIcon;
