import React from 'react';

const Config = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path className="icon-detail" fill="#fff" d="M11.5,5.5h-2C9.2,5.5,9,5.7,9,6s0.2,0.5,0.5,0.5h2C11.8,6.5,12,6.3,12,6S11.8,5.5,11.5,5.5z" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M6.5,4.5c-0.65,0-1.2,0.45-1.4,1c-0.05,0-0.05,0-0.1,0H4.5C4.2,5.5,4,5.7,4,6s0.2,0.5,0.5,0.5H5
c0.05,0,0.05,0,0.1,0c0.2,0.55,0.75,1,1.4,1C7.35,7.5,8,6.85,8,6S7.35,4.5,6.5,4.5z"
    />
    <path className="icon-detail" fill="#fff" d="M4.5,10.5h2C6.8,10.5,7,10.3,7,10S6.8,9.5,6.5,9.5h-2C4.2,9.5,4,9.7,4,10S4.2,10.5,4.5,10.5z" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M11.5,9.5H11c-0.05,0-0.05,0-0.1,0c-0.2-0.6-0.75-1-1.4-1C8.65,8.5,8,9.15,8,10s0.65,1.5,1.5,1.5
c0.65,0,1.2-0.45,1.4-1c0.05,0,0.05,0,0.1,0h0.5c0.3,0,0.5-0.2,0.5-0.5S11.8,9.5,11.5,9.5z"
    />
  </svg>
);

export default Config;
