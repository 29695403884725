import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import UserPocket from './modules/userPocket';
import OrganisationSelector from './modules/organisationSelector';
import { useUser } from 'hooks/session/useUser';

export const HeaderToolbar = styled(Toolbar)(({ theme }) => `
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  min-height: var(--header-bar-height);
  height: var(--header-bar-height);
`);

const Header = () => {
  const t = useTranslations('header')
  const user = useUser();
  if (!user) return null;

  return (
    <AppBar>
      <HeaderToolbar disableGutters variant="dense">
        <Link to="/">
          <TracplusIcon overrideStyle={{}} />
        </Link>
        <OrganisationSelector />
        <Button
          variant="outlined"
          color="secondary"
          component="a"
          href="https://tracplus.freshdesk.com/en/support/home"
          target="_blank"
          sx={{ mr: 3 }}
        >
          {t('modules.links.knowledgeBase')}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          component="a"
          href="https://tracplus.freshdesk.com/support/solutions/44000818932"
          target="_blank"
          sx={{ mr: 3 }}
        >
          {t('modules.links.troubleshooting')}
        </Button>
        <UserPocket user={user} />
      </HeaderToolbar>
    </AppBar>
  );
};

export default Header;
