import React, { useCallback, useState } from 'react';
import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { Input, stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useFetchEngineUsage } from 'apis/rest/engineUsage/hooks';
import { downloadCSV } from 'utils/download';
import mixpanel from 'mixpanel-browser';
import useDuration from 'hooks/units/useDuration';

export const useDownload = () => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const assetLabel = useAssetLabel();
  const duration = useDuration();

  const fetchEngineUsage = useFetchEngineUsage();

  return useCallback(async (
    assets: AssetWithDevice[],
    from: number,
    until: number,
    timezone: string
  ) => {
    const assetIds = assets.map(asset => asset.id);
    const engineUsages = await fetchEngineUsage(assetIds, from, until, 60000);

    const records = assets.reduce<Input>((acc, asset) => {
      if (!engineUsages.filter(engineUsage => engineUsage.assetId === asset.id).length) return acc;
      return [
        ...acc,
        ...engineUsages.filter(engineUsage => engineUsage.assetId === asset.id).map(engineUsage => ({
          asset,
          assetLabel: assetLabel(asset),
          startTime: DateTime.fromMillis(engineUsage.startTime).setZone(timezone).toISO({ suppressMilliseconds: true }),
          endTime: engineUsage.endTime ? DateTime.fromMillis(engineUsage.endTime).setZone(timezone).toISO({ suppressMilliseconds: true }) : undefined,
          duration: duration.fromMillis(engineUsage.duration),
        })),
      ];
    }, []);

    const csvString = csvStringify(records, {
      header: true,
      columns: [
        { key: 'assetLabel', header: t('columns.asset') },
        { key: 'asset.make', header: t('columns.make') },
        { key: 'asset.model', header: t('columns.model') },
        { key: 'startTime', header: t('columns.engineStartTime') },
        { key: 'endTime', header: t('columns.engineEndTime') },
        { key: 'duration', header: t('columns.engineDuration') },
      ],
    });

    let exportFilename = `${t('filename.engineCycles')} ${assets.map(assetLabel).join(', ')} `;
    const startDate = DateTime.fromMillis(from).toISODate();
    const endDate = DateTime.fromMillis(until).toISODate();
    if (startDate === endDate) {
      exportFilename += startDate;
    } else {
      exportFilename += t('filename.dateRange', { from: startDate, until: endDate });
    }

    downloadCSV(exportFilename, csvString);
  }, [fetchEngineUsage, assetLabel, t]);
};

interface DownloadEngineUsageMenuItemProps {
  assets: AssetWithDevice[]
  from: number | undefined
  until: number | undefined
  timezone: string
  close: () => void
  disabled?: boolean
}

const DownloadEngineUsageMenuItem = ({ assets, from, until, timezone, close, disabled: disabledProp = false }: DownloadEngineUsageMenuItemProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const [isLoading, setIsLoading] = useState(false);
  const download = useDownload();

  const disabled = disabledProp || !assets.length || !from || !until || isLoading;

  const onClick = async () => {
    if (disabled) return;
    mixpanel.track('Engine Usage Download');
    setIsLoading(true);
    try {
      await download(assets, from, until, timezone);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" minWidth="12rem">
        <Typography>{t('button.engineCycles')}</Typography>
        {isLoading && <CircularProgress size="1.5rem" />}
      </Stack>
    </MenuItem>
  );
};

export default DownloadEngineUsageMenuItem;
