import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@mui/material';
import { labelToIconType } from 'helpers/events';
import Icon from 'components/shared/icons/eventIcons';
import useStyles from './filter-styles';

const FormListItem = ({
  selected,
  onSelect
}) => {
  const classes = useStyles();

  return (
    <ListItem dense button onClick={() => onSelect(!selected)}>
      <ListItemIcon className={classes.iconWrapper}>
        <Icon type={form} />
      </ListItemIcon>
      <ListItemText primary="Form" />
      <ListItemIcon className={classes.iconAlignment}>
        <Checkbox
          edge="end"
          tabIndex={-1}
          checked={selected}
        />
      </ListItemIcon>
    </ListItem>
  );
};

export default FormListItem;
