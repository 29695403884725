import React from 'react';

const MI17 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 35.773438 2.640625 C 35.747678 2.642785 35.734992 2.64721 35.732422 2.65625 L 32.794922 13.007812 C 31.890493 12.526413 31.050781 12.558594 31.050781 12.558594 C 31.050781 12.558594 26.90686 12.373968 27.001953 17.9375 C 27.032421 19.720015 27.006576 22.201154 26.966797 24.683594 L 7.8808594 12.298828 C 7.8169867 12.257383 7.3160292 12.889992 6.7578125 13.714844 C 6.1995957 14.539695 5.8013617 15.2359 5.8652344 15.277344 L 26.884766 28.916016 C 26.872701 29.422695 26.870532 29.501695 26.859375 29.9375 L 8.4277344 44.279297 C 8.3677414 44.325977 8.8244656 44.986982 9.453125 45.761719 C 10.081784 46.536457 10.637273 47.120893 10.697266 47.074219 L 26.746094 34.589844 C 26.777333 35.989305 27.130043 38.700787 29.308594 40.144531 C 29.684732 40.393801 29.259766 51.96875 29.259766 51.96875 L 26.431641 52.341797 L 26.501953 54.902344 L 29.332031 55.460938 C 29.332031 55.460938 29.083701 61.863278 31.003906 61.863281 C 32.924111 61.863284 32.652344 55.460938 32.652344 55.460938 L 35.505859 54.902344 L 35.576172 52.341797 L 32.748047 51.96875 C 32.748047 51.96875 32.323079 40.393801 32.699219 40.144531 C 33.156457 39.841515 33.525292 39.477997 33.835938 39.085938 L 39.779297 54.673828 C 39.806097 54.744118 40.590358 54.523773 41.537109 54.177734 C 42.483857 53.831698 43.224062 53.498026 43.197266 53.427734 L 35.212891 32.486328 C 35.198702 31.996407 35.172086 30.885878 35.138672 29.546875 L 59.171875 28.53125 C 59.248435 28.52795 59.274011 27.728835 59.230469 26.740234 C 59.186929 25.751634 59.092177 24.959659 59.015625 24.962891 L 35.064453 25.974609 C 35.015837 23.345401 34.980462 20.627565 35.001953 18.566406 L 39.246094 3.6132812 C 39.266611 3.5409923 38.499046 3.2688575 37.525391 3.0039062 C 36.67344 2.772074 35.953688 2.6255071 35.773438 2.640625 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default MI17;
