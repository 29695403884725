// Has lots of helper classes for the KML
// eslint-disable-next-line max-classes-per-file
import { create } from 'xmlbuilder2';
import { parseColor } from './kmlConverter';
import { Marker } from 'apis/rest/markers/types';

interface MarkerObject {
  '@Name': string,
  '@UserNotes': string,
  '@Position': string,
  '@Colour': string,
  '@Type': string,
}


const markerConverter = (input: string): Array<Omit<Marker, 'id'>> => {

  function parseMarkers(markerStr: string): Array<Omit<Marker, 'id'>> {
    const doc = create(markerStr);
    const obj = doc.end({ format: 'object' }) as unknown as { ArrayOfMarker: { Marker: MarkerObject[] } };
    let markers: Array<Omit<Marker, 'id'>> = [];
    try {
      const markerData = Array.isArray(obj.ArrayOfMarker.Marker)
        ? obj.ArrayOfMarker.Marker
        : [obj.ArrayOfMarker.Marker];

      markers = markerData.map((m: MarkerObject) => ({
        name: m['@Name'],
        icon: 'generic',
        latitude: Number(m['@Position'].split(' ')[0]),
        longitude: Number(m['@Position'].split(' ')[1]),
        altitude: 0,
        colour: parseColor(m['@Colour']),
        type: 'POI',
        notes: m['@UserNotes'],
      }));
    } catch (e) {
      console.error('Error parsing markers', e);
      throw e;
    }
    const filteredMarkers = markers.filter(m => {
      if (Math.abs(m.latitude) > 90 || Math.abs(m.longitude) > 180) {
        console.warn('Marker has invalid coordinates', m);
        return false;
      }
      return true;
    });
    return filteredMarkers;
  }

  return parseMarkers(input);
};
export default markerConverter;
