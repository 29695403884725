import React, { FC } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { ArrowForward } from '@mui/icons-material';

export interface UnselectedItemProps {
  addItemText: string;
  isReadOnly?: boolean
  onAddClick: () => void;
}

export const UnselectedItem: FC<UnselectedItemProps> = ({ addItemText, isReadOnly, onAddClick, children }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderBottom={1}
      mt="-1px"
      borderColor="common.midGrey"
      p={2}
      alignItems="center"
      role="button"
      sx={{ cursor: 'pointer', ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
      onClick={onAddClick}
    >
      <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
        {children}
      </Stack>
      {!isReadOnly && (
        <Tooltip title={addItemText}>
          <IconButton>
            <ArrowForward />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
