import React from 'react';

const DropStart = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 32 32">
    <circle className="icon-base" cx="16" cy="16" r="16" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M 27 12 c 0 1.1 -0.9 2 -2 2 c -0.18 0 -0.35 -0.02 -0.51 -0.07 l -3.56 3.55 C 20.98 17.64 21 17.82 21 18 c 0 1.1 -0.9 2 -2 2 s -2 -0.9 -2 -2 c 0 -0.18 0.02 -0.36 0.07 -0.52 l -2.55 -2.55 C 14.36 14.98 14.18 15 14 15 c -0.18 0 -0.36 -0.02 -0.52 -0.07 l -4.55 4.56 C 8.98 19.65 9 19.82 9 20 c 0 1.1 -0.9 2 -2 2 s -2 -0.9 -2 -2 s 0.9 -2 2 -2 c 0.18 0 0.35 0.02 0.51 0.07 l 4.56 -4.55 C 12.02 13.36 12 13.18 12 13 c 0 -1.1 0.9 -2 2 -2 s 2 0.9 2 2 c 0 0.18 -0.02 0.36 -0.07 0.52 l 2.55 2.55 C 18.64 16.02 18.82 16 19 16 c 0.18 0 0.36 0.02 0.52 0.07 l 3.55 -3.56 C 23.02 12.35 23 12.18 23 12 c 0 -1.1 0.9 -2 2 -2 S 27 10.9 27 12 z"
    />
  </svg>
);

export default DropStart;
