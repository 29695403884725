import React from 'react';
import { Grid, Typography } from '@mui/material';
import { StyledBox } from './settingsSection-styles';

interface SettingsSectionProps {
  title: React.ReactNode
  description?: React.ReactNode
  children: React.ReactNode
}

const SettingsSection = ({ title, description, children }: SettingsSectionProps): JSX.Element => (
  <StyledBox component="section">
    <Grid container spacing={3}>
      <Grid item xs={12} xl={4} component="header">
        <Typography variant="h5" gutterBottom>{title}</Typography>
        {description && <Typography variant="body1">{description}</Typography>}
      </Grid>
      <Grid item xs={12} xl={8}>{children}</Grid>
    </Grid>
  </StyledBox>
);

export default SettingsSection;
