import React from 'react';

const B206 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 50.431641 4.4882812 C 50.134105 4.4775427 49.833793 4.5793096 49.597656 4.796875 L 36.310547 17.037109 C 36.293128 15.352731 36.245639 13.840744 36.130859 12.964844 C 35.489563 8.0710372 31.671875 7.9082031 31.671875 7.9082031 C 31.671875 7.9082031 27.854683 8.0213938 27.148438 12.90625 C 26.771871 15.510825 26.866397 23.233009 26.902344 25.707031 L 9.4609375 41.775391 C 8.988664 42.210521 8.9626672 42.938206 9.4023438 43.40625 L 11.074219 45.185547 C 11.513895 45.653591 12.24843 45.679271 12.720703 45.244141 L 27.673828 31.466797 C 28.248122 33.781996 28.95507 35.803897 29.076172 36.033203 C 29.418616 36.681619 29.621094 45.011719 29.621094 45.011719 C 29.621094 45.011719 25.779442 44.955426 25.753906 45.130859 C 25.728366 45.306298 25.800738 49.361354 25.835938 49.421875 C 25.871137 49.482395 29.818359 49.412109 29.818359 49.412109 C 29.818359 49.412109 29.939186 59.669818 30.144531 61.105469 C 30.245066 61.808351 31.302734 63.335937 31.302734 63.335938 C 31.302734 63.335938 32.382326 61.822579 32.492188 61.121094 C 32.716585 59.688297 32.974609 49.431641 32.974609 49.431641 C 32.974609 49.431641 36.92103 49.556136 36.957031 49.496094 C 36.993031 49.436044 37.120864 45.382793 37.097656 45.207031 C 37.074456 45.031273 33.230469 45.037109 33.230469 45.037109 C 33.230469 45.037109 33.543505 36.710217 33.894531 36.066406 C 34.105463 35.679542 36.089471 30.164888 36.179688 26.744141 C 36.187809 26.436155 36.222034 25.13275 36.255859 23.558594 L 52.855469 8.265625 C 53.327742 7.8304942 53.353739 7.1028101 52.914062 6.6347656 L 51.244141 4.8554688 C 51.024302 4.6214465 50.729177 4.4990198 50.431641 4.4882812 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default B206;
