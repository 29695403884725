import React from 'react';

const TrackingSuspended = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M7,8.23c0.13-0.09,0.16-0.26,0.08-0.39C7.05,7.81,7.03,7.79,7,7.77l-3.25-1.9c-0.13-0.07-0.3-0.03-0.37,0.1
        C3.35,6.01,3.34,6.06,3.34,6.1V9.9c0,0.15,0.12,0.27,0.27,0.27c0.05,0,0.09-0.01,0.13-0.04L7,8.23z"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.22,8.15c0.08-0.06,0.11-0.17,0.05-0.25c-0.01-0.02-0.03-0.04-0.05-0.05L8.1,6.62
        C8.02,6.57,7.91,6.6,7.86,6.68C7.85,6.71,7.84,6.74,7.84,6.77v2.46c0,0.1,0.08,0.18,0.18,0.18c0.03,0,0.06-0.01,0.09-0.02
        L10.22,8.15z"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M12.61,8.1c0.06-0.04,0.07-0.11,0.03-0.17c-0.01-0.01-0.02-0.02-0.03-0.03l-1.42-0.83
        c-0.06-0.03-0.13-0.01-0.16,0.04c-0.01,0.02-0.02,0.04-0.02,0.06v1.65c0,0.07,0.05,0.12,0.12,0.12c0.02,0,0.04-0.01,0.06-0.02
        L12.61,8.1z"
    />
  </svg>
);

export default TrackingSuspended;
