import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useListEventNotificationGroups, useListNotificationRules } from 'apis/rest/eventNotifications/hooks';
import { EventNotificationGroup, EventNotificationRule } from 'apis/rest/eventNotifications/types';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import LimitedList from '../LimitedList';

export interface NotificationViewTableProps {
  namesHeader: string;
  rulesHeader: string;
  notAssignedText: string;
  filterGroup: (group: EventNotificationGroup) => boolean;
  filterRule: (rule: EventNotificationRule) => boolean;
}

export const NotificationViewTable = ({
  namesHeader,
  rulesHeader,
  filterGroup,
  filterRule,
  notAssignedText
}: NotificationViewTableProps): JSX.Element => {
  const notificationGroups = useListEventNotificationGroups().query.data;
  const notificationRules = useListNotificationRules().data;
  notificationGroups?.forEach(g => {
    g.notificationRules = notificationRules?.filter(r => r.notificationGroupId === g.id) ?? [];
  });
  const navigate = useNavigate();

  const filtered = notificationGroups?.filter(filterGroup) ?? [];

  const handleRowClick = (id: number) => () => navigate(`/manage/event-notifications/${id}`);

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{namesHeader}</TableCell>
            <TableCell>{rulesHeader}</TableCell>
            <TableCell sx={{ width: 0 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {(filtered?.length > 0)
            ? filtered?.map(m => (
              <TableRow key={m.id} onClick={handleRowClick(m.id)} sx={{ cursor: 'pointer' }}>
                <TableCell>
                  <Typography sx={{ whiteSpace: 'nowrap', overflow: 'clip' }}>{m.name}</Typography>
                </TableCell>
                <TableCell>
                  <LimitedList<string>
                    items={m.notificationRules?.filter(filterRule)?.map(r => r.name) ?? []}
                    limit={3} />
                </TableCell>
                <TableCell align="right">
                  <IconButton size="small" edge="end">
                    <ArrowForwardIos sx={theme => ({ color: theme.palette.common.text })} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
            : <TableCell sx={{ border: 0 }}><Typography>{notAssignedText}</Typography></TableCell>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
