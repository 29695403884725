import { uniqBy, uniqWith, sumBy } from 'lodash/fp';
import { InferredEvent, InferredEventId } from 'apis/rest/inferredEvents/types';
import { Subject } from 'rxjs';

export interface InferredEventsEvent {
  assetIds: number[];
}

const isSameInferredEvent = (a: InferredEvent, b: InferredEvent) => a.id === b.id || (a.eventId === b.eventId && a.reportId === b.reportId);

export class InferredEventsRepository {
  subject = new Subject<InferredEventsEvent>();
  private assetEvents: Map<number, InferredEvent[]> = new Map(); // assetId -> InferredEvent[]

  clear(): void {
    this.assetEvents = new Map();
  }

  insertEvents(events: InferredEvent[]): void {
    if (events.length === 0) {
      return;
    }

    const eventsByAsset = events.reduce<Record<number, InferredEvent[]>>((acc, evt) => {
      if (!acc[evt.assetId]?.push(evt)) acc[evt.assetId] = [evt];
      return acc;
    }, {});

    const newEvents = Object.entries(eventsByAsset).map(([assetId, evts]) => this.insertEventsForAsset(Number(assetId), evts));
    console.log(`inserted ${sumBy('count', newEvents)} inferred events`);
    this.subject.next({
      assetIds: uniqBy('assetId', newEvents).map(t => t.assetId),
    });
  }

  private insertEventsForAsset(assetId: number, events: InferredEvent[]) {
    const existingEvents = this.assetEvents.get(assetId) ?? [];
    const newEvents = existingEvents.concat(events).toSorted((a, b) => a.reportTime.toMillis() - b.reportTime.toMillis());
    const uniqueEvents = uniqWith(isSameInferredEvent, newEvents);
    this.assetEvents.set(assetId, uniqueEvents);
    return {
      assetId,
      count: uniqueEvents.length - existingEvents.length
    };
  }

  getEventsForAsset(assetId: number): InferredEvent[] {
    return this.assetEvents.get(assetId) ?? [];
  }

  getEventsForAssetByReportId(assetId: number): Record<number, InferredEventId[]> {
    const events = this.assetEvents.get(assetId) ?? [];
    return events.reduce<Record<number, InferredEventId[]>>((acc, evt) => {
      if (!acc[evt.reportId]?.push(evt.eventId)) acc[evt.reportId] = [evt.eventId];
      return acc;
    }, {});
  }

  getEventsForAssetsByReportId(assetIds: number[]): Record<number, Record<number, InferredEventId[]>> {
    return assetIds.reduce<Record<number, Record<number, InferredEventId[]>>>((acc, assetId) => {
      acc[assetId] = this.getEventsForAssetByReportId(assetId);
      return acc;
    }, {});
  }
}
