import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  result: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    border: theme.border.default,
    borderRadius: 4,
    fontSize: '.8rem',
    fontWeight: '400',
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    transition: 'all 0.15s'
  },
  visibleResult: {
    // Style as green by default
    borderColor: theme.palette.primary.activeGreen,
    backgroundColor: theme.palette.omnibox.background,
    '&:hover': {
      backgroundColor: theme.palette.primary.activeGreenLight
    },
    '& p, & span, & svg': {
      color: theme.palette.primary.activeGreen,
      fill: theme.palette.primary.activeGreen
    },
    '&.inactive': {
      borderColor: theme.palette.common.text,
      backgroundColor: theme.palette.omnibox.background,
      '&:hover': {
        backgroundColor: theme.palette.omnibox.resultHover
      },
      '& p, & span, & svg': {
        color: theme.palette.common.text,
        fill: theme.palette.common.text
      }
    },
    '&.selected': {
      borderColor: theme.palette.primary.activeGreen,
      backgroundColor: theme.palette.primary.activeGreen,
      '&:hover': {
        backgroundColor: theme.palette.primary.activeGreen
      },
      '& p, & span, & svg': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      }
    },
    '&.distress,&.inactive.distress': {
      borderColor: theme.palette.primary.distressRed,
      backgroundColor: theme.palette.omnibox.background,
      '&:hover': {
        backgroundColor: theme.palette.primary.distressRedLight
      },
      '& p, & span, & svg': {
        color: theme.palette.primary.distressRed,
        fill: theme.palette.primary.distressRed,
      }
    },
    '&.selected.inactive': {
      borderColor: theme.palette.common.text,
      backgroundColor: theme.palette.common.text,
      '&:hover': {
        backgroundColor: theme.palette.common.text
      },
      '& p, & span, & svg': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white
      }
    },
    '&.selected.distress,&.selected.inactive.distress': {
      borderColor: theme.palette.primary.distressRed,
      backgroundColor: theme.palette.primary.distressRed,
      '&:hover': {
        backgroundColor: theme.palette.primary.distressRed
      },
      '& p, & span, & svg': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white
      }
    },
  },
  hiddenResult: {
    display: 'none', // just completely hide hidden results in the omnibox
    cursor: 'not-allowed',
    backgroundColor: theme.palette.omnibox.resultHover,
    border: theme.border.default,
    '& p, & span': {
      color: theme.palette.common.text
    },
    '&:hover': {
      backgroundColor: theme.palette.omnibox.resultHover
    },
    '& p.distressMessage': {
      color: theme.palette.primary.distressRed
    },
  },
  resultIconWrapper: {
    position: 'relative',
    padding: theme.spacing(1),
    borderRadius: '30px',
    backgroundColor: theme.palette.primary.activeGreen,
    zIndex: 1,
    transition: 'all 0.15s'
  },
  visibleResultIconWrapper: {
    '&.inactive': {
      backgroundColor: theme.palette.common.text
    },
    '&.distress': {
      backgroundColor: theme.palette.primary.distressRed
    }
  },
  hiddenResultIconWrapper: {
    backgroundColor: theme.palette.common.lightGrey
  },
  noResult: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    textAlign: 'left',
    backgroundColor: '#fff',
    fontSize: '.8rem',
    fontWeight: '400',
    color: theme.palette.common.text,
    padding: theme.spacing(2, 3),
    transition: 'all 0.15s',
    '& p:first-child, @span:first-child': {
      fontSize: '.9rem',
      fontWeight: '400'
    }
  },
  details: {
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left'
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p, & span': {
      fontSize: '.8rem',
      fontWeight: '400'
    },
    '& svg.warning': {
      fill: theme.palette.primary.orange
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p, & span': {
      fontSize: '.8rem'
    }
  },
  rowTextLeft: {
    maxWidth: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  rowTextRight: {
    maxWidth: '40%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  statusImage: {
    height: '30px'
  },
  deviceStatus: {
    textTransform: 'capitalize',
    maxWidth: '40%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  resultIconBackground: {
    padding: 4,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    zIndex: 0
  },
  resultIcon: {
    display: 'block',
    width: 13,
    height: 13,
    fill: theme.palette.common.white,
    '& svg': {
      display: 'block',
      width: 13,
      height: 13
    }
  },
  closeButton: {
    transition: 'background-color 0.5s ease',
    color: theme.palette.common.lightGrey,
    '&:hover': {
      backgroundColor: 'rgba(255,0,0,0.75)'
    }
  },
  truncate: {
    width: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'right',
  },
  titleText: {
    fontWeight: 'bold !important'
  }
}));
