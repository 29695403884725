import React from 'react';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslations } from 'use-intl';

interface GeofencesListFiltersViewProps {
  filterText: string
  category: string
  onChangeFilter: (value: string) => void
  onChangeCategory: (value: string) => void
}

const GeofencesListFiltersView: React.FC<GeofencesListFiltersViewProps> = ({
  filterText,
  category,
  onChangeFilter,
  onChangeCategory,
}) => {
  const t = useTranslations('pages.manage.geofencing');
  return (
    <Stack spacing={2} m={3}>
      <TextField
        label={t('fields.search')}
        variant="outlined"
        value={filterText}
        onChange={event => onChangeFilter(event.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start" disableTypography><SearchIcon /></InputAdornment>,
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="createSelectCategoryLabel">{`${t('list.category.geofenceCategory')}`}</InputLabel>
        <Select
          labelId="createSelectCategoryLabel"
          label={`${t('list.category.geofenceCategory')}`}
          value={category}
          onChange={event => onChangeCategory(event.target.value)}
        >
          <MenuItem value="All">{t('list.category.anyCategory')}</MenuItem>
          <MenuItem value="RestrictedArea">{t('list.category.enter')}</MenuItem>
          <MenuItem value="AreaOfOperation">{t('list.category.exit')}</MenuItem>
          <MenuItem value="Generic">{t('list.category.both')}</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default GeofencesListFiltersView;
