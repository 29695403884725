import React from 'react';

const AW109 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 44.546875 3.0410156 L 35.392578 16.992188 C 35.07906 8.4277647 31.210937 8.0644531 31.210938 8.0644531 C 31.210938 8.0644531 26.586208 8.466161 27.027344 19.539062 C 27.0321 19.658455 27.038094 20.12543 27.042969 20.371094 L 11.136719 9.9375 C 11.083029 9.902274 10.686672 10.413443 10.248047 11.082031 C 9.8094199 11.750619 9.5009933 12.316337 9.5546875 12.351562 L 27.113281 23.873047 C 27.146535 25.484122 27.192346 27.182374 27.269531 28.851562 L 16.232422 45.677734 C 16.197202 45.731424 16.706412 46.12778 17.375 46.566406 C 18.043588 47.005033 18.611258 47.313462 18.646484 47.259766 L 27.675781 33.498047 C 28.190943 36.835162 29.126433 46.489087 29.019531 46.945312 C 28.998151 47.036572 24.081658 46.794105 23.833984 47.087891 C 23.586311 47.381675 23.773937 50.504496 24.105469 50.539062 C 24.436998 50.573632 29.46875 51.095703 29.46875 51.095703 C 29.46875 51.095703 29.94431 61.828216 31.210938 61.859375 C 32.477565 61.890535 32.953125 51.095703 32.953125 51.095703 C 32.953125 51.095703 37.982923 50.573632 38.314453 50.539062 C 38.645985 50.504493 38.833611 47.381675 38.585938 47.087891 C 38.338264 46.794105 33.421771 47.03657 33.400391 46.945312 C 33.289353 46.471434 34.310055 36.005088 34.808594 33.076172 C 34.938011 32.315831 35.033925 31.117344 35.109375 29.748047 L 51.609375 40.572266 C 51.663065 40.607486 52.05942 40.096323 52.498047 39.427734 C 52.936672 38.759147 53.2451 38.19343 53.191406 38.158203 L 35.246094 26.382812 C 35.290503 24.915699 35.319063 23.532977 35.339844 22.335938 L 46.958984 4.625 C 46.99421 4.571305 46.486948 4.1730009 45.818359 3.734375 C 45.149772 3.2957489 44.582101 2.9873206 44.546875 3.0410156 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default AW109;
