import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {useTranslations} from 'use-intl';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import Icon from 'components/shared/icons/eventIcons';
import Linky from 'components/shared/linky';
import useStyles from './jobs-styles';
import Module from '../../modules/module';

const isPast = (job: Job, now = new Date()): boolean => !!(job.endUTC && new Date(job.endUTC) < now);
const isCurrent = (job: Job, now = new Date()): boolean => new Date(job.startUTC) < now && (!job.endUTC || new Date(job.endUTC) > now);
const isFuture = (job: Job, now = new Date()): boolean => new Date(job.startUTC) > now;

interface JobProps {
  job: Job;
  timezone: string;
  now: Date;
}

const JobView = ({ job, timezone, now }: JobProps): JSX.Element => {
  const [expanded, toggle] = useState(false);
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.jobs');
  const tense = isPast(job, now) ? 'past' : isCurrent(job, now) ? 'current' : 'future';

  return (
    <ListItem key={job.id} className={classes.job}>
      <Box className={classes.left}>
        <ListItemIcon className={clsx([classes.iconWrapper, (classes as any)[tense]])}>
          <Icon type="EVT_STARTUP" />
        </ListItemIcon>
        {job.metadata && (
          <ListItemIcon onClick={() => toggle(!expanded)} className={clsx([classes.clickable, classes.iconWrapper, classes.smallIcon])}>
            {expanded
              ? (<Icon type="minus" />)
              : (<Icon type="plus" />)}
          </ListItemIcon>
        )}
        <ListItemIcon className={clsx([classes.iconWrapper, (classes as any)[tense], classes.finishIcon, !job.endUTC && classes.smallIcon])}>
          {job.endUTC
            ? (<Icon type="EVT_SHUTDOWN" />)
            : (<Icon type="downArrow" />)}
        </ListItemIcon>
      </Box>
      <ListItemText className={classes.jobContent}>
        <Box className={classes.startTime}>
          {`${t('start')} ${moment(job.startUTC).tz(timezone).format('hh:mma')}`}
        </Box>
        <Box className={classes.startDate}>
          {moment(job.startUTC).tz(timezone).format('dddd, MMMM D, zz')}
        </Box>
        {job.metadata ? (
          <Box className={clsx([classes.metadata, expanded && classes.expanded])}>
            {Object.keys(job.metadata).map(key => (
              <Box key={key}>
                <Box className={classes.metadataKey}>{key}</Box>
                <Box className={classes.metadataValue}>
                  <Linky>
                    {job.metadata[key]}
                  </Linky>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (<Box m={2.5} />)}
        {job.endUTC && (
          <>
            <Box className={classes.endTime}>
              {`${t('finish')} ${moment(job.endUTC).tz(timezone).format('hh:mma')}`}
            </Box>
            <Box className={classes.endDate}>
              {moment(job.endUTC).tz(timezone).format('dddd, MMMM D, zz')}
            </Box>
          </>
        )}
      </ListItemText>
    </ListItem>
  );
};

interface AssetContextBoxJobsProps {
  jobs: Job[]
  timezone: string,
  now: Moment | undefined,
}

const AssetContextBoxJobs: React.FC<AssetContextBoxJobsProps> = ({ jobs, timezone, now }) => {
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.jobs');
  const nowDate = now ? moment(now).toDate() : new Date();

  const dateSort = (a: any, b: any): number => moment(b.startUTC).tz(timezone).unix() - moment(a.startUTC).tz(timezone).unix();
  // exclude past jobs that are more than 24 hours finished
  const pastJobs = jobs.filter(job => isPast(job, nowDate)).sort(dateSort);
  const currentJobs = jobs.filter(job => isCurrent(job, nowDate)).sort(dateSort);
  const futureJobs = jobs.filter(job => isFuture(job, nowDate)).sort(dateSort);

  return (
    <Module headerText={t('title')}>
      <Box className={classes.jobWrapper} key="jobs">
        <List className={classes.list}>
          {futureJobs.map(job => (
            <JobView key={job.id} job={job} timezone={timezone} now={nowDate} />
          ))}
          {currentJobs.map(job => (
            <JobView key={job.id} job={job} timezone={timezone} now={nowDate} />
          ))}
          {pastJobs.map(job => (
            <JobView key={job.id} job={job} timezone={timezone} now={nowDate} />
          ))}
        </List>
      </Box>
    </Module>
  );
};

export default AssetContextBoxJobs;
