import React from 'react';

const R22 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 31.67609,9.5226458 c 0,0 -4.501942,0.237114 -4.934339,6.4392022 -0.153046,2.19522 -0.02988,4.223996 0.214342,5.983724 L 4.1643571,30.825149 c -1.0408189,0.405487 -1.5540956,1.568762 -1.1498574,2.610065 l 0.3750991,0.966773 c 0.4042381,1.041305 1.5692458,1.553111 2.6100648,1.147626 L 28.105951,26.937962 c 0.30172,0.907323 0.550013,1.489095 0.593907,1.56068 0.145056,0.23657 1.109282,0.345913 1.248097,0.464408 0.262576,0.224136 1.041283,18.802308 1.281588,24.682862 l 0.573813,1.245865 c 0,-10e-7 -0.04859,1.412001 -0.03795,1.741532 0.0024,0.07432 5.319565,-0.86494 6.428038,-0.830578 0.895559,0.02776 0.585256,-3.362892 0.584976,-3.487529 -5.02e-4,-0.223932 -4.799884,0.06865 -4.800376,-0.178618 -0.01398,-7.025258 0.04275,-23.049233 0.276859,-23.267308 0.133548,-0.124401 1.091107,-0.273125 1.22577,-0.515761 0.07659,-0.138003 0.777464,-1.951425 1.223538,-4.766885 l 22.834155,-8.895207 c 1.040819,-0.405487 1.551863,-1.568762 1.147625,-2.610065 l -0.3751,-0.966773 C 59.906653,10.07328 58.743878,9.5614738 57.70306,9.9669588 L 37.054747,18.011497 C 37.031297,17.27625 36.982387,16.519918 36.89399,15.740807 36.193097,9.5632968 31.676093,9.5226458 31.676093,9.5226458 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default R22;
