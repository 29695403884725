import { getMembershipsForCurrentUser } from 'apis/rest/memberships/requests';
import { setCustomAttribute } from 'helpers/newRelic';
import { useEffect } from 'react';
import { loginFailure, userLoaded } from 'slices/session/session.slice';
import { UserData } from 'slices/session/types';
import { useAppDispatch } from 'store/types';
import { useInjectedDependency } from '../../di';

const UserLoader = (): null => {
  const dispatch = useAppDispatch();
  const { auth } = useInjectedDependency('firebaseService');


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      console.log('Firebase auth state changed', user?.uid);
      setCustomAttribute('firebaseUID', user?.uid ?? null, true);
      if (!user) {
        return;
      }
      console.time('fetchUser');
      const tokenResult = await user.getIdTokenResult(true);
      console.timeLog('fetchUser', 'got token');
      localStorage.setItem('bearerToken', tokenResult.token);
      const currentMemberships = await getMembershipsForCurrentUser();
      console.timeLog('fetchUser', 'got usercodes');
      console.timeLog('fetchUser', 'got global roles');
      console.timeEnd('fetchUser');

      const data: UserData = {
        id: user.uid,
        name: user.displayName!,
        email: user.email!,
        profilePicUrl: user.photoURL,
        isStaff: tokenResult.claims?.isStaff || false,
        memberOf: currentMemberships.map(m => ({ id: m.organisationId, name: m.organisationName, role: m.role, publicKey: m.publicKey })),
      };

      if (!(data.memberOf.length > 0)) {
        dispatch(loginFailure('contactSupport'));
      } else {
        dispatch(userLoaded(data));
      }
    });

    return unsubscribe;
  }, [auth, dispatch]);

  return null;
};

export default UserLoader;
