import React from 'react';

const Ambulance = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 44.55255,53.561935 V 25.036616 H 42.270232 V 15.73104 h 0.883442 v -5.716103 h -0.970406 c 0,0 0.0041,-1.8365755 0,-2.0545254 -0.02858,-1.5349336 -8.53149,-1.5547763 -8.53149,-1.5547763 0,0 -8.502909,0.019843 -8.53149,1.5547763 -0.0041,0.2179499 0,2.0545254 0,2.0545254 h -0.970406 v 5.716103 h 0.883441 v 9.305576 h -2.282317 v 28.525319 c 7.267182,0 14.534362,0 21.801544,0 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Ambulance;
