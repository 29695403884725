import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import { Typography } from '@mui/material';
import React from 'react';
import useTimezone from 'hooks/session/useTimezone';
import { useTranslations } from 'use-intl';
import { DetailsGrid, OverlayHeader, OverlayWrapper, ScoreCard } from './common';

interface MapIntervalOverlayProps {
  template: MapTemplate,
  highContrast: boolean
}

const dateFormat = 'yyyy MM dd HH:mm ZZZZ';

export const MapIntervalOverlay = ({ template, highContrast }: MapIntervalOverlayProps) => {
  const timezone = useTimezone();
  const t = useTranslations('pages.map.mapInterval');
  return (
    !template.interval
      ? null
      : (
        <OverlayWrapper highContrast={highContrast}>
          <OverlayHeader>
            <Typography variant="h5">{t('title')}</Typography>
          </OverlayHeader>
          <DetailsGrid>
            <ScoreCard label={t('start')} wide>
              <Typography>{template.interval.start.setZone(timezone).toFormat(dateFormat)}</Typography>
            </ScoreCard>
            <ScoreCard label={t('end')} wide>
              <Typography>{template.interval.end.setZone(timezone).toFormat(dateFormat)}</Typography>
            </ScoreCard>
          </DetailsGrid>
        </OverlayWrapper>
      )
  );
};
