import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { Marker } from 'apis/rest/markers/types';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

interface DeleteDialogProps {
  open: boolean
  marker?: Marker;
  onClose: () => void;
  onSubmit: (markerId: number) => void;
}

const DeleteDialog = ({
  open,
  marker,
  onClose,
  onSubmit,
}: DeleteDialogProps) => {
  const t = useTranslations('dialogs.markers');

  const handleSubmit = () => {
    if (!marker) {
      return;
    }
    onSubmit(marker.id);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <TPDialogTitle>{t('delete.title', { name: marker?.name ?? '' })}</TPDialogTitle>
      <DialogContent>
        <DialogContentText pt={3}>{t('delete.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.actions.cancel')}</Button>
        <Button color="error" onClick={handleSubmit}>{t('common.actions.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
