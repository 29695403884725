import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import { useMemo } from 'react';

const useUndeletablePeopleIds = (): number[] | undefined => {
  const { query } = useGetIceContactGroups();
  return useMemo(() => {
    const peopleWithOrder = query.data?.find(g => g.isDefault)?.peopleWithOrder ?? [];
    if (query.isLoading) return undefined;
    return [...peopleWithOrder].sort((a, b) => a.order - b.order).slice(0, 2).map(x => x.personId);
  }, [query]);
};

export default useUndeletablePeopleIds;
