import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LatLon from 'geodesy/latlon-spherical';
import clsx from 'clsx';
import { useTranslations } from 'use-intl';
import Distance from 'components/shared/distance';
import Speed from 'components/shared/speed';
import AssetLabel from 'components/shared/assetLabel';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { useLatestPosition } from 'repositories/reports/hooks';
import { useCursor } from 'contexts/cursor/useCursor';
import { useSelector } from 'react-redux';
import { selectMeasurementMarkers, selectMeasurementState } from 'slices/map/multiPointMeasurement.slice';
import { DateTime, Duration } from 'luxon';
import useStyles from './measurementPanel-styles';

interface MultiMeasurementPanelProps {
  highContrastControls: boolean,
  mapId: string,
  selected: boolean | undefined,
}

const MultiMeasurementPanel = ({
  highContrastControls,
  mapId,
  selected,
}: MultiMeasurementPanelProps) => {
  const classes = useStyles();
  const t = useTranslations('pages.map.measurementPanel');

  const measurementMarkers = useSelector(selectMeasurementMarkers);
  const selectedItemId = useSelector<ReduxState, number | undefined>(state => state.app.selectedItem?.id);
  const selectedAssets = useSelector<ReduxState, ReduxState['map']['selectedAssets']>(state => state.map.selectedAssets);
  const asset = useLatestPosition(selectedItemId);
  const [cursor] = useCursor();
  const measurementState = useSelector(selectMeasurementState);

  // Create a clock and "tick" it every 1000ms
  const [time, updateTime] = useState(DateTime.now());
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(DateTime.now());
    }, 1000);

    return () => clearInterval(interval);
  });

  const cursorLat = (cursor?.latitude || 0);
  let cursorLng = (cursor?.longitude || 0);
  // When rendering in triplicate, lng can be outside [-180, 180] so adjust here.
  if (cursorLng > 180) cursorLng -= 360;
  else if (cursorLng < -180) cursorLng += 360;

  const TIME_FORMAT = 'hh:mm:ss';

  const latestMarkerPos = useMemo(() => {
    if (measurementMarkers) return new LatLon(measurementMarkers.at(-1).latitude, measurementMarkers.at(-1).longitude);
    return null;
  }, [measurementMarkers]);

  // calculate confirmed path distance
  const pathDistance = useMemo(() => {
    let culmulativeDistance = 0;
    if (measurementMarkers) {
      measurementMarkers.forEach((marker, index, markers) => {
        // if index is 0 and there is no asset, then is starting point -> skip
        if (index === 0) {
          if (asset) {
            const assetPos = new LatLon(asset.latitude, asset.longitude);
            const markerPos = new LatLon(marker?.latitude ?? 0, marker?.longitude ?? 0);
            culmulativeDistance += assetPos.distanceTo(markerPos);
          }
          return;
        }
        const currentMarkerPos = new LatLon(marker?.latitude ?? 0, marker?.longitude ?? 0);
        const prevMarkerPos = new LatLon(markers[index - 1].latitude, markers[index - 1].longitude);
        culmulativeDistance += prevMarkerPos.distanceTo(currentMarkerPos);
      });
    }
    return culmulativeDistance;
  }, [asset, measurementMarkers]);

  const cursorDistance = useMemo(() => {
    if (!cursor || (cursorLat === 0 && cursorLng === 0)) return 0;
    const cursorPos = new LatLon(cursorLat, cursorLng);

    // measure from asset to marker only in create mode
    if (asset && measurementState === 'create') {
      const assetPos = new LatLon(asset.latitude, asset.longitude);
      return assetPos.distanceTo(cursorPos);
    }

    // from latest marker to cursor
    if (measurementMarkers) {
      return latestMarkerPos?.distanceTo(cursorPos) ?? 0;
    }

    return 0;
  }, [asset, cursor, cursorLat, cursorLng, latestMarkerPos, measurementMarkers, measurementState]);

  const totalCursorDistance = measurementState === 'create' ? cursorDistance : cursorDistance + pathDistance;

  const panelData = {
    // latest point/asset to cursor
    cursor: {
      key: 'cursormeasurepanel',
      title: t('toCursor'),
      position: new LatLon(cursorLat, cursorLng),
      distance: totalCursorDistance,
      formattedEta: '--:--:--',
      ete: 0,
      formattedEte: '--:--:--',
      visible: selected || !!measurementMarkers
    },
    // currently created path
    marker: {
      key: 'markermeasurepanel',
      title: t('toMarker'),
      position: latestMarkerPos,
      distance: pathDistance,
      formattedDistance: 0,
      formattedEta: '--:--:--',
      ete: 0,
      formattedEte: '--:--:--',
      formattedSpeed: 0,
      visible: !!measurementMarkers
    }
  };

  const selectedAsset = selectedAssets[mapId];
  const speedUnit = useSpeedByAsset(selectedAsset);

  const assetAvailable = !!selectedAsset && !!asset;

  Object.keys(panelData).forEach(key => {
    const measurement = panelData[key];
    // if the gps location is 0,0 the cursor is probably off screen
    if (measurement.position && assetAvailable && measurement.position.latitude !== 0.0 && measurement.position.longitude !== 0.0) {
      // get travel time in milliseconds
      measurement.ete = asset.speed > 0.0 ? measurement.distance / (asset.speed / 3600) : 0.0;

      if (measurement.ete > 0) {
        const ete = Duration.fromMillis(measurement.ete);
        measurement.formattedEte = ete.toFormat(TIME_FORMAT);
        measurement.formattedEta = time.plus(ete).toFormat(TIME_FORMAT);
      }
    }
  });

  return (
    <Box
      className={clsx(classes.measurementPanel, { [classes.highContrastBackground]: highContrastControls })}
      sx={{ borderRadius: 1 }}
    >
      {Object.values(panelData).filter(x => x.visible).map(measurement => (
        <React.Fragment key={measurement.key}>
          <Box className={classes.measurementPanelHeader}>
            <Typography>{assetAvailable && (<AssetLabel asset={selectedAsset} />)} {measurement.title}:</Typography>
          </Box>
          <Box className={classes.measurementPanelBody}>
            <Box className={classes.measurementBox} key={measurement.key}>
              <Grid className={classes.gridContainer} container spacing={0}>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('distance')}:`}
                  </Typography>
                  <Distance distanceInMetres={measurement.distance} />
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('speed')}:`}
                  </Typography>
                  {assetAvailable && (<Speed unit={speedUnit} speedInKmh={asset.speed} />)}
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('ETE')}:`}
                  </Typography>
                  <Typography noWrap>{measurement.formattedEte}</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('ETA')}:`}
                  </Typography>
                  <Typography noWrap>
                    {measurement.formattedEta}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MultiMeasurementPanel;
