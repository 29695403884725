import { DateTime } from 'luxon';
import { getRefreshedToken } from 'apis/auth';
import {
  mapTrackstarReportToTPCReport,
  mapTSConversation,
  mapTSMessage,
} from './maps';
import { objToXml, parseSOAP } from './soap-utils';

const SERENITY_SOAP_URL = import.meta.env.VITE_SERENITY_SOAP_URL;

/**
 * @deprecated
 */
const getSoapHeaders = (endpoint: string): Headers => {
  const headers = new Headers();
  headers.append('SOAPAction', `http://serenity.tracplus.com/services/${endpoint}`);
  headers.append('Content-Type', 'text/xml');
  return headers;
};

/**
 * @deprecated
 */
const getXMLBody = async (endpoint: string, extraXMLOptions?: any): Promise<string> => (
  `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://serenity.tracplus.com/services/">
     <soapenv:Header/>
     <soapenv:Body>
        <ser:${endpoint}>
           <ser:userId>${localStorage.getItem('organisationId')}</ser:userId>
           <ser:user>${localStorage.getItem('organisationId')}</ser:user>
           <ser:password>${await getRefreshedToken()}</ser:password>
           ${objToXml(extraXMLOptions)}
        </ser:${endpoint}>
     </soapenv:Body>
  </soapenv:Envelope>`
);

/**
 * Wraps a fetch and parsing call to serenity.
 *
 * @deprecated
 * @param endpoint The SOAP endpoint to call on /serenity.asmx
 * @param extraXMLOptions Extra xml options to provide aside from usercode/password
 * @returns parsed SOAP result, 4 levels down from root
 */
const fetchSerenity = async (endpoint: string, extraXMLOptions?: any): Promise<any> => {
  const response = await fetch(`${SERENITY_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getSoapHeaders(endpoint),
    body: await getXMLBody(endpoint, extraXMLOptions),
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request serenity/${endpoint}.`);
  return parseSOAP(await response.text());
};

// #----------------------#
// # USERS & ORGANISATION #
// #----------------------#
/**
 * @deprecated
 */
export const saveUsercode = async (usercode: Organisation): Promise<Result> => {
  const extraXMLOptions = { usercode };
  return fetchSerenity('updateUsercode', extraXMLOptions);
};

/**
 * This is used on login for initial setup.
 * @deprecated
 * @returns Usercodes with minimal roles (just the admin role)
 */
export const fetchUsercodes = async (): Promise<Organisation[]> => {
  const result = await fetchSerenity('getUsercodes');
  const data = result?.usercodes.usercode;
  return data.map((d: any) => ({
    ...d,
    id: d.id.toString(),
  })) as Organisation[];
};

// #---------------------------#
// # CONVERSATIONS & MESSAGING #
// #---------------------------#
/**
 * List conversations available for the current user.
 *
 * TracStar conversations are held an organisation and a device/terminal.
 * Each conversation includes information on the latest message, intended for use in rendering previews.
 *
 * @deprecated
 * @returns A list of conversations visible to the current user.
 */
export const fetchConversations = async (): Promise<Conversation[]> => {
  const result = await fetchSerenity('getConversations');
  const conversations = (
    result?.conversations.conversation as TSConversation[])
    ?.map(mapTSConversation) || [];
  // filter out conversations with no latest message timestamp (empty conversations)
  return conversations.filter(c => c.latestMessage);
};

/**
 * List messages between the user and a device.
 *
 * @deprecated
 * @param deviceId device to find messages with.
 * @param cursor Offset, in messages, to read from. Zero is most recent.
 * @param pageSize Messages to return in one response, starting at {@link cursor}.
 *
 * @returns A list of messages with a device.
 */
export const fetchMessages = async (
  deviceId: number,
  cursor = 0,
  pageSize = 0
): Promise<Message[]> => {
  const extraXMLOptions = { terminalId: deviceId, cursor, pageSize };
  const result = await fetchSerenity('getMessages', extraXMLOptions);
  const messages = (result?.messages.message as TSMessage[])?.map(mapTSMessage) || [];
  // reverse order by timestamp (oldest messages first)
  return messages.sort((a, b) => a.timestamp - b.timestamp);
};

// #-----------#
// # REPORTING #
// #-----------#
/**
 * @deprecated
 */
export const fetchMissionReports = async (
  pageSize = 5000,
  cursor = 0
): Promise<MissionReport[]> => {
  const extraXMLOptions = { cursor, pageSize };

  const result = await fetchSerenity('getMissionReports', extraXMLOptions);
  const missionReports: MissionReport[] = result?.missionReports.missionReport || [];
  // reverse order by timestamp (newest messages first)
  return missionReports.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());
};

/**
 * @deprecated
 */
export const fetchEventReportsForDevice = async (
  deviceId: string,
  fromSeconds: number,
  untilSeconds: number,
  eventTypes: string,
): Promise<U1Report[]> => {
  const format = 'LL/dd/yyyy HH:mm:ss';
  const from = DateTime.fromSeconds(fromSeconds).toUTC().toFormat(format);
  const until = DateTime.fromSeconds(untilSeconds).toUTC().toFormat(format);

  const extraXMLOptions = {
    terminalId: deviceId,
    from,
    until,
    eventTypes,
  };

  const result = await fetchSerenity('getEventReportsForTerminal', extraXMLOptions);
  const reports: TSReport[] = result?.reports.report ?? [];
  // reverse order by timestamp (newest messages first)
  return reports.map(mapTrackstarReportToTPCReport).sort((a, b) => (
    DateTime.fromISO(a.time, { zone: 'etc/UTC' }).toMillis() - DateTime.fromISO(b.time, { zone: 'etc/UTC' }).toMillis()
  ));
};
