import React from 'react';
import { DateTime } from 'luxon';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Schedule } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { ShareRangeType, TemporalShare } from 'apis/rest/temporalShares/types';
import { formatDate, formatDateTime } from './helpers';

interface ShareTimeProps {
  share: TemporalShare
  timezone: string
}

const ShareTime = ({ share, timezone }: ShareTimeProps) => {
  const t = useTranslations('pages.sharing.timeRange');

  if (share.shareRangeType === ShareRangeType.AllTime) return <>{t('all')}</>;

  const title = (
    <span>
      {share.shareRangeType === ShareRangeType.OpenEnded && (
        <>{t('from', { date: formatDateTime(share.shareStart, timezone) })}</>
      )}
      {share.shareRangeType === ShareRangeType.SpecificRange && (
        <>
          {t('from', { date: formatDateTime(share.shareStart, timezone) })}<br />
          {t('to', { date: formatDateTime(share.shareEnd, timezone) })}
        </>
      )}
    </span>
  );

  const icon = (
    <Tooltip title={title} placement="right">
      <IconButton>
        <Schedule />
      </IconButton>
    </Tooltip>
  );

  if (share.shareRangeType === ShareRangeType.OpenEnded) {
    return (
      <Stack direction="row" alignItems="center">
        <span>{t('from', { date: formatDate(share.shareStart, timezone) })}</span>
        {icon}
      </Stack>
    );
  }

  const start = DateTime.fromISO(share.shareStart).setZone(timezone);
  const end = DateTime.fromISO(share.shareEnd).setZone(timezone);
  if (start.hasSame(end, 'day')) {
    return (
      <Stack direction="row" alignItems="center">
        <span>{formatDate(share.shareStart, timezone)}</span>
        {icon}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center">
      <span>
        {t('from', { date: formatDate(share.shareStart, timezone) })}<br />
        {t('to', { date: formatDate(share.shareEnd, timezone) })}
      </span>
      {icon}
    </Stack>
  );
};

export default ShareTime;
