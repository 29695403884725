import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetAssetModules } from 'apis/rest/featureAssets/hooks';
import { loggedIn as selectLoggedIn } from 'slices/session/session.slice';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureAssetsContext, type FeatureAssetsContextValue } from './featureAssetsContext';

const FeatureAssetsContextProvider: React.FC = ({ children }) => {
  const loggedIn = useSelector(selectLoggedIn);
  const featureModules = useFeatureFlag('featureModules');

  const query = useGetAssetModules({
    enabled: loggedIn && !!featureModules,
    staleTime: 60_000,
    refetchOnWindowFocus: true,
  });

  const value = useMemo<FeatureAssetsContextValue>(() => ({
    isLoading: query.isLoading,
    data: query.data ?? [],
  }), [query.isLoading, query.data]);

  return (
    <FeatureAssetsContext.Provider value={value}>
      {children}
    </FeatureAssetsContext.Provider>
  );
};

export default FeatureAssetsContextProvider;
