import { styled } from '@mui/material/styles';
import { Box, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

export const AccountWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${theme.spacing(1)};
`);

export const OffsetWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${theme.spacing(0, 2, 2)};
  margin-bottom: ${theme.spacing(2)};
  border-bottom: ${theme.border.default};
`);

export const PocketListItemButton = styled(ListItemButton)(({ theme }) => `
  border-radius: 4px;
  background-color: ${theme.palette.common.white};
  transition: all 0.15s;
  &:hover {
    background-color: ${theme.palette.common.lightBlue};
  }
  & .MuiTouchRipple-ripple {
    color: ${theme.palette.common.black};
  }
`);

export const IconSvg = styled('svg')(({ theme }) => `
  width: 24px;
  height: 20px;
  & line, & polyline, & path, & circle, & polygon {
    stroke: ${theme.palette.primary.main};
    fill: transparent;
  }
`);

export const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
`;
