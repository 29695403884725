import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { markersQueryKeys } from './queryKeys';
import { Marker, CreateMarkerVariables, UpdateMarkerVariables, DeleteMarkerVariables, CreateMarkersVariables } from './types';
import { createMarker, createMarkers, deleteMarker, fetchAllMarkers, updateMarker } from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useMarkers = <T = Marker[]>(options?: Options<Marker[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = markersQueryKeys.allMarkers(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => fetchAllMarkers(organisationId),
    ...options,
  });

  return {
    query,
    queryKey,
  };
};

export const useCreateMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: ({ marker }: CreateMarkerVariables) => createMarker(organisationId, marker),
    onSuccess: (newMarker) => {
      queryClient.setQueryData(
        markersQueryKeys.allMarkers(organisationId),
        (oldData: Marker[] | undefined) => oldData ? [...oldData, newMarker].sort((a, b) => a.name.localeCompare(b.name)) : [newMarker]
      );
    },
  });
};

export const useCreateMarkers = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (markers: CreateMarkersVariables) => createMarkers(organisationId, markers),
    onSuccess: (newMarkers) => queryClient.setQueryData(
      markersQueryKeys.allMarkers(organisationId),
      (oldData: Marker[] | undefined) => oldData ? [...oldData, ...newMarkers].sort((a, b) => a.name.localeCompare(b.name)) : newMarkers
    ),
  });
};

export const useUpdateMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: ({
      markerId,
      marker,
    }: UpdateMarkerVariables) => updateMarker(organisationId, markerId, marker),
    onSuccess: (_, { markerId, marker }) => {
      queryClient.setQueryData(
        markersQueryKeys.allMarkers(organisationId),
        (oldData: Marker[] | undefined) => oldData?.map(m => m.id === markerId ? marker : m)
      );
    },
  });
};

export const useDeleteMarker = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: ({ markerId }: DeleteMarkerVariables) => deleteMarker(organisationId, markerId),
    onSuccess: (_, { markerId }) => {
      queryClient.setQueryData(
        markersQueryKeys.allMarkers(organisationId),
        (oldData: Marker[] | undefined) => oldData?.filter(marker => marker.id !== markerId)
      );
    },
  });
};
