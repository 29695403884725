import Smart from 'hocs/smart';
import Component from './connections-view';

export default Smart(
  Component,
  state => ({
    organisationId: state.session.organisationId,
    pubkey: state.session.user.memberOf.find(org => org.id === state.session.organisationId).publicKey,
  }),
  ac => ({
  })
);
