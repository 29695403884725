import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import Arrow from '@mui/icons-material/KeyboardArrowDown';

import useStyles from './input-styles';

/* display a dropdown with grouped item, expects options to look like this:
  {
    'fred': {
      name: 'Name of the Group',
      children: [
        {
          id: 1,
          label: 'Name of the item'
        },
        ...
      ]
    },
    'barney': {
      name: 'Another Group',
      children: [
        {
          id: 13,
          label: 'Name of the other item'
        },
        ...
      ]
    }
  }
*/
/**
 * @deprecated
 */
const GroupedSelectInput = ({
  id,
  label,
  options,
  value,
  onChange,
  disabled,
  readOnly
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.field} variant="outlined" fullWidth>
      {label
        && (
        <InputLabel
          className={classes.label}
          htmlFor={id}
          shrink
        >
          {label}
        </InputLabel>
        )}
      <Select
        variant="outlined"
        native
        value={value}
        disabled={disabled || readOnly}
        // readOnly seems to be broken at the moment with the native flag. I have set disabled instead in the meantime
        // readOnly={readOnly}
        onChange={e => onChange(id, e.target.value)}
        IconComponent={() => (<Arrow className={classes.icon} />)}
        className={classes.selectInput}
        inputProps={{
          name: 'value',
          id: label
        }}
        label={label}
      >
        {Object.keys(options).sort().map(key => {
          const group = options[key];
          return (
            <optgroup label={group.name} key={group.name}>
              {group.children.map(option => (
                <option key={option.id} value={option.id}>{option.label || option.value}</option>
              ))}
            </optgroup>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default GroupedSelectInput;
