import React from 'react';
import {
  ButtonBase, Typography, Box, SvgIcon
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import DateTime from 'components/shared/datetime';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ClassNameMap } from '@mui/styles';
import useStyles from './preview-styles';
import ParticipantNameListItem from '../participantNameListItem';
import useTimezone from "hooks/session/useTimezone";

interface PreviewProps {
  userId: string;
  participants: Participant[]
  msg: Message | null;
  isSeen: boolean;
  handleClick: () => void;
  isSelected: boolean;
  assetsByDeviceId: Record<number, AssetBasic> | undefined;
}

const Preview = ({
  userId,
  participants,
  msg,
  isSeen,
  handleClick,
  isSelected,
  assetsByDeviceId,
}: PreviewProps): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const timezone = useTimezone();

  // default date format
  let datetimeFormat = 'D MMM YYYY';

  const now = moment();
  const time = msg ? moment.unix(msg.timestamp).tz(timezone) : null;

  // if message received this year omit the year
  if (now.isSame(time, 'year')) datetimeFormat = 'D MMM';
  // if message received today just display the time
  if (now.isSame(time, 'day')) datetimeFormat = 'hh:mm A';

  // TODO this should probably be done with css so that it can fill the box
  const content = msg ? msg.content : '';
  const snippet = content.length < 20 ? content : `${content.toString().substr(0, 20)}...`;

  const assetParticipant = participants.find(p => p.type === 'asset');
  const recipients = assetParticipant ? [assetParticipant] : participants.filter(p => p.id !== userId);

  const devicesIcon = (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
      <path d="M14.17,8.05H1.83C0.82,8.05,0,8.87,0,9.88l0,0c0,1.01,0.82,1.83,1.83,1.83h12.34c1.01,0,1.83-0.82,1.83-1.83l0,0
              C16,8.87,15.18,8.05,14.17,8.05z M2.58,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98
              S3.12,10.86,2.58,10.86z M5.02,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S5.57,10.86,5.02,10.86z
              M10.98,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98C11.97,10.42,11.52,10.86,10.98,10.86z
              M13.42,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S13.97,10.86,13.42,10.86z"
      />
      <path d="M1.83,7.78h12.34c0.3,0,0.57,0.07,0.82,0.17L11.82,4.8C11.49,4.47,11.06,4.3,10.6,4.3H5.41c-0.46,0-0.9,0.18-1.22,0.51
              L1,7.96C1.25,7.84,1.53,7.78,1.83,7.78z"
      />
    </SvgIcon>
  );
  const userIcon = (
    <SvgIcon className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
      <path d="M16,0c-2.31,0-4.17,1.87-4.17,4.17S13.69,8.35,16,8.35s4.17-1.87,4.17-4.17l0,0
            C20.17,1.87,18.3,0,16,0"
      />
      <path d="M13.22,9.74c-2.3,0-4.17,1.86-4.17,4.17c0,0,0,0.01,0,0.01v6.96c0,0.32,0.22,0.6,0.53,0.68
            l2.3,0.57l0.65,9.24c0.03,0.36,0.33,0.64,0.69,0.64h5.57c0.36,0,0.66-0.28,0.69-0.64l0.65-9.24l2.3-0.57
            c0.31-0.08,0.53-0.36,0.53-0.68v-6.96c0-2.3-1.86-4.17-4.17-4.18c0,0-0.01,0-0.01,0H13.22z"
      />
    </SvgIcon>
  );

  return (
    <ButtonBase className={clsx({ [classes.previewUnread]: !isSeen }, classes.preview, isSelected && 'selected')} focusRipple onClick={() => handleClick()}>
      <Box className={classes.previewText}>
        <Avatar className={classes.avatarIcon}>{assetParticipant ? devicesIcon : userIcon}</Avatar>
        <Typography variant="subtitle1" className={classes.participantsText}>
          {recipients.map((p, index) => (
            <ParticipantNameListItem
              key={p.id}
              // @ts-ignore - until we make Smart component type-safe
              participant={p}
              addComma={index < recipients.length - 1}
              asset={assetsByDeviceId?.[p.deviceId]}
            />
          ))}
        </Typography>
        <Typography noWrap variant="body1" className={classes.messagePreview}>{snippet}</Typography>
        {time && <DateTime className={clsx(classes.timestampText, 'timestampText')} value={time} format={datetimeFormat} />}
      </Box>
    </ButtonBase>
  );
};

export default Preview;
