import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import { useTranslations } from 'use-intl';
import { DropGroup } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import { DetailsGrid, ScoreCard, OverlayHeader, OverlayWrapper } from './mapCommon';
import { calculateEfficiencyStats } from './statistics';

interface DropsByAssetScorecardProps extends PaperOwnProps {
  dropGroups: DropGroup[]
  trips: TripBasic[]
}

const HoveredInfoOverlay = ({ dropGroups, trips, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const statistics = useMemo(() => calculateEfficiencyStats(trips, dropGroups), [trips, dropGroups]);

  const volPerPeriod = useVolumePerPeriod();
  const volume = useVolume();

  if (dropGroups.length === 0) {
    return null;
  }

  return (
    <OverlayWrapper highContrast {...props}>
      <OverlayHeader>
        <Typography variant="h3">Statistics</Typography>
      </OverlayHeader>
      <DetailsGrid>
        <ScoreCard label="Drops">
          <Typography>{dropGroups.length}</Typography>
        </ScoreCard>
        <ScoreCard label="Total Volume">
          <Typography>{volume.create(sumBy('volumeDroppedLitres', dropGroups)).format()}</Typography>
        </ScoreCard>
        <ScoreCard label="Drops per hour">
          <Typography>{statistics.dropsPerHour.toFixed(2)}</Typography>
        </ScoreCard>
        <ScoreCard label="Drops per flight hour">
          <Typography>{statistics.dropsPerFlightHour.toFixed(2)}</Typography>
        </ScoreCard>
        <ScoreCard label="Vol per hour">
          <Typography>{volPerPeriod.create(statistics.volumePerHour).format()}</Typography>
        </ScoreCard>
        <ScoreCard label="Vol per flight hour">
          <Typography>{volPerPeriod.create(statistics.volumePerFlightHour).format()}</Typography>
        </ScoreCard>
      </DetailsGrid>
    </OverlayWrapper>
  );
};

export default HoveredInfoOverlay;
