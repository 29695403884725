import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  moduleHeader: {
    padding: theme.spacing(3),
  },
  moduleHeaderText: {
    textAlign: 'left',
    color: theme.palette.common.text,
    fontSize: '.9rem',
    fontWeight: '500'
  },
  moduleContent: {
    borderBottom: theme.border.default,
    padding: theme.spacing(0, 3, 3, 3)
  }
}));
