import React from 'react';

const EngineStart = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M8,11.75c-1.93,0-3.5-1.57-3.5-3.5c0-1,0.42-1.94,1.17-2.61l0.67,0.74c-1.03,0.92-1.12,2.5-0.2,3.53
s2.5,1.12,3.53,0.2c1.03-0.92,1.12-2.5,0.2-3.53c-0.06-0.07-0.13-0.14-0.2-0.2l0.67-0.74c1.44,1.29,1.56,3.5,0.27,4.94
C9.94,11.33,9,11.75,8,11.75z"
    />
    <rect className="icon-detail" fill="#fff" x="7.5" y="4.25" width="1" height="3.5" />
  </svg>
);

export default EngineStart;
