import React from 'react';
import { Grid } from '@mui/material';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';

const MissingAssetPage = () => {
  const t = useTranslation('pages.assetView');
  return (
    <Page>
      <Grid
        container
        spacing={0}
        align="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <h2>{t('missingAsset')}</h2>
        </Grid>
      </Grid>
    </Page>
  );
};

export default MissingAssetPage;
