import React from 'react';

const Truck = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 22.64945,7.9317138 c -0.121778,0.1478083 -0.121967,0.572846 -0.121967,0.572846 h -1.11186 v 7.4029332 h 1.277888 v 13.132429 l -0.993258,4.575534 h 5.096238 v 8.291337 h -0.810127 v -1.744888 h -4.736125 v 7.04187 l 5.035454,0.01102 -0.04406,-1.339825 0.710656,-0.01102 v 5.797199 H 26.32911 v -2.025316 l -5.084217,0.01102 0.05508,7.093171 h 5.091452 v -2.243428 h 0.778968 v 3.302824 l 1.612723,0.931106 h 5.534958 l 1.612723,-0.931106 v -3.302824 h 0.778968 v 2.243428 h 5.091452 l 0.05508,-7.093171 -5.084217,-0.01102 v 2.025316 H 36.148901 V 45.86395 l 0.710656,0.01102 -0.04406,1.339825 5.035454,-0.01102 v -7.04187 h -4.736125 v 1.744888 h -0.810127 v -8.291337 h 5.096238 L 40.407679,29.039922 V 15.907493 h 1.277888 V 8.5045598 h -1.11186 c 0,0 -1.89e-4,-0.4250377 -0.121967,-0.572846 -2.255468,-2.7375783 -8.901145,-2.4191504 -8.901145,-2.4191504 0,0 -6.645677,-0.3184279 -8.901145,2.4191504 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Truck;
