import React, { ReactNode, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslations } from 'use-intl';
import { Action } from 'redux';
import { DateTime } from 'luxon';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import {
  AllTime,
  CreateGroupSharesBody,
  CreateSharesBody,
  OpenEnded,
  ShareRangeType,
  SpecificRange,
  TemporalShare,
  TemporalSharePermissions,
} from 'apis/rest/temporalShares/types';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useSnackbar from 'hooks/useSnackbar';
import useTimezone from 'hooks/session/useTimezone';
import { GroupFriend, OrganisationFriend } from 'apis/rest/friends/types';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { useCreateShares } from 'apis/rest/temporalShares/hooks';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { useGetFriendGroupsList } from 'apis/rest/friends/hooks';
import ShareDateRangePicker from './shareDateRangePicker';
import PermissionsPicker from './permissionsPicker';
import NoteField from './noteField';
import SelectAssets from '../selectAssets';
import {
  isTemporalGroupShare,
  selectDevicesById,
  getGroupOptionsForOwnedAssets,
  getMaximumShareDateUtc, getMinimumShareDateUtc,
} from '../helpers';
import SelectFriend, { FriendValue } from './selectFriend';

interface SetOrganisationIdAction extends Action<'SET_ORGANISATION'> {
  payload: OrganisationFriend | undefined
}

interface SetGroupIdAction extends Action<'SET_GROUP'> {
  payload: GroupFriend | undefined
}

interface SetDeviceIdsAction extends Action<'SET_DEVICE_IDS'> {
  payload: number[]
}

interface SetRangeTypeAction extends Action<'SET_RANGE_TYPE'> {
  payload: ShareRangeType
}
interface SetDateRangeAction extends Action<'SET_DATE_RANGE'> {
  payload: {
    shareStart: string | undefined
    shareEnd: string | undefined
  }
}
interface SetPermissionsAction extends Action<'SET_PERMISSIONS'> {
  payload: Partial<TemporalSharePermissions>
}

interface SetNotesAction extends Action<'SET_NOTES'> {
  payload: string
}

interface CloneShareAction extends Action<'CLONE_SHARE'> {
  payload: TemporalShare
}

interface State {
  recipientId: string | undefined
  isGroupShare: boolean
  deviceIds: number[]
  shareRangeType: ShareRangeType
  shareStart: string | undefined
  shareEnd: string | undefined
  notes: string | undefined
  permissions: TemporalSharePermissions
}

type ValidState = State & ({
  recipientId: string | undefined
  isGroupShare: boolean
}) & ({
  shareRangeType: ShareRangeType.OpenEnded,
  shareStart: string
} | {
  shareRangeType: ShareRangeType.SpecificRange,
  shareStart: string
  shareEnd: string
});

const INITIAL_STATE: State = {
  recipientId: undefined,
  isGroupShare: false,
  deviceIds: [],
  shareRangeType: ShareRangeType.AllTime,
  shareStart: undefined,
  shareEnd: undefined,
  notes: undefined,
  permissions: {
    canViewCurrent: true,
    canViewHistory: true,
    canViewForms: false,
    canSendTextMessages: false,
    canSendConfiguration: false,
    canEditCallSign: false,
  },
};

const reducer: Reducer<State, Action<'RESET'> | SetRangeTypeAction | SetDateRangeAction | SetPermissionsAction | SetNotesAction | SetDeviceIdsAction | SetOrganisationIdAction | SetGroupIdAction | CloneShareAction> = (state, action) => {
  if (action.type === 'RESET') return INITIAL_STATE;
  if (action.type === 'SET_RANGE_TYPE') return { ...state, shareRangeType: action.payload };
  if (action.type === 'SET_DATE_RANGE') return { ...state, shareStart: action.payload.shareStart, shareEnd: action.payload.shareEnd };
  if (action.type === 'SET_PERMISSIONS') return { ...state, permissions: { ...state.permissions, ...action.payload } };
  if (action.type === 'SET_NOTES') return { ...state, notes: action.payload };
  if (action.type === 'SET_DEVICE_IDS') return { ...state, deviceIds: action.payload };
  if (action.type === 'SET_ORGANISATION') return { ...state, recipientId: action.payload?.organisationId, isGroupShare: false };
  if (action.type === 'SET_GROUP') {
    const group = action.payload;
    if (group) return { ...state, recipientId: group.groupId, isGroupShare: true };
    return { ...state, recipientId: undefined, isGroupShare: false };
  }
  if (action.type === 'CLONE_SHARE') {
    const share = action.payload;
    const recipient = isTemporalGroupShare(share)
      ? { recipientId: share.groupId, isGroupShare: true }
      : { recipientId: share?.organisationId, isGroupShare: false };

    return {
      ...recipient,
      deviceIds: [share.deviceId],
      shareRangeType: share.shareRangeType,
      shareStart: share.shareStart ?? undefined,
      shareEnd: share.shareEnd ?? undefined,
      notes: share.notes ?? undefined,
      permissions: {
        canViewCurrent: share.canViewCurrent,
        canViewHistory: share.canViewHistory,
        canViewForms: share.canViewForms,
        canSendTextMessages: share.canSendTextMessages,
        canSendConfiguration: share.canSendConfiguration,
        canEditCallSign: share.canEditCallSign
      },
    };
  }
  return state;
};

const isStateValid = (state: State, isSelfShare: boolean): state is ValidState => {
  if (state.recipientId === undefined) return isSelfShare;
  if (!state.deviceIds.length) return false;

  if (state.shareRangeType === undefined) return true;
  if (state.shareRangeType === ShareRangeType.AllTime) return true;

  const start = state.shareStart ? DateTime.fromISO(state.shareStart) : undefined;
  if (!start?.isValid) return false;
  if (state.shareRangeType === ShareRangeType.OpenEnded) return true;

  const end = state.shareEnd ? DateTime.fromISO(state.shareEnd) : undefined;
  return state.shareRangeType === ShareRangeType.SpecificRange && !!end?.isValid && start < end;
};

const getDateRange = ({ shareRangeType, shareStart, shareEnd }: State): AllTime | OpenEnded | SpecificRange => {
  if (shareRangeType === ShareRangeType.AllTime) return { shareRangeType, shareStart: null, shareEnd: null };

  if (shareStart === undefined) throw new Error(`Start date must be present for ${shareRangeType} share`);
  const clampedStart = DateTime.fromISO(shareStart, { zone: 'utc' }).year > 1999
    ? shareStart
    : getMinimumShareDateUtc('utc').toISO();
  if (shareRangeType === ShareRangeType.OpenEnded) return { shareRangeType, shareStart: clampedStart, shareEnd: null };

  if (shareEnd === undefined) throw new Error(`End date must be present for ${shareRangeType} share`);
  const clampedEnd = DateTime.fromISO(shareEnd, { zone: 'utc' }).year < 3000
    ? shareEnd
    : getMaximumShareDateUtc('utc').toISO();
  return { shareRangeType, shareStart, shareEnd: clampedEnd };
};

interface CreateDialogProps {
  open: boolean
  onClose: () => void
  isSelfShare?: boolean
  renderDeviceShareCount?: (deviceId?: number) => ReactNode
  cloneShare?: TemporalShare
}

const CreateDialog = ({ open, onClose, isSelfShare = false, renderDeviceShareCount, cloneShare }: CreateDialogProps) => {
  const t = useTranslations('pages.sharing.create');
  const tz = useTimezone();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const mutation = useCreateShares();
  const organisationId = useOrganisationId();
  const snackbar = useSnackbar();

  const groupsQuery = useGetFriendGroupsList();

  const assetGroupsQuery = useGetAssetGroupsForOrganisation();
  const assetGroups = assetGroupsQuery.data;

  const isLoading = assetGroupsQuery.isFetching;

  const groupId = state.isGroupShare ? state.recipientId : undefined;
  const requiredPermissions = useMemo<Partial<TemporalSharePermissions>>(() => {
    const group = groupsQuery.data?.find(g => g.groupId === groupId);
    return group ? {
      canViewCurrent: group.canViewCurrent,
      canViewHistory: group.canViewHistory,
      canViewForms: group.canViewForms,
      canSendTextMessages: group.canSendTextMessages,
      canSendConfiguration: group.canSendConfiguration,
      canEditCallSign: group.canEditCallSign,
    } : {};
  }, [groupsQuery, groupId]);

  useEffect(() => {
    if (cloneShare) dispatch({ type: 'CLONE_SHARE', payload: cloneShare });
  }, [cloneShare]);

  const selectSharableAssets = useCallback((data: AssetBasic[]) => {
    const isSharableAsset = (asset: AssetBasic): asset is AssetWithDevice => !asset.archived && asset.ownerId.toLowerCase() === organisationId.toLowerCase() && asset.deviceId !== null;
    return data.reduce<Record<number, AssetWithDevice>>((acc, asset) => {
      if (isSharableAsset(asset)) acc[asset.deviceId] = asset;
      return acc;
    }, {});
  }, [organisationId]);
  const assetsQuery = useGetAssetsList({
    enabled: open,
    select: selectSharableAssets,
    staleTime: Infinity,
  }).query;

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesByIdQuery.data;

  const canSave = !mutation.isPending
    && (state.isGroupShare ? !groupsQuery.isLoading : true)
    && isStateValid(state, isSelfShare)
    && (isSelfShare ? true : !!state.recipientId)
    && state.deviceIds.length > 0;

  const onSave = () => {
    if (!canSave) return;

    const { recipientId: rid, isGroupShare, notes = null, permissions, ...rest } = state;
    const recipientId = isSelfShare ? organisationId : rid;
    if (!recipientId) return;

    const recipient = isGroupShare ? { shareToGroupId: recipientId } : { shareToOrganisationId: recipientId };

    const body: CreateSharesBody | CreateGroupSharesBody = {
      ...rest,
      ...recipient,
      ...permissions,
      ...getDateRange(state),
      notes,
    };

    // set required permissions
    Object.entries(requiredPermissions).forEach(([key, value]) => {
      if (value) body[key as keyof TemporalSharePermissions] = true;
    });

    mutation.mutate(body, {
      onSuccess: () => {
        snackbar.display({ id: 'createShareSuccess', type: 'success', text: t('notifications.success') });
        onClose();
      },
    });
  };

  const onExited = () => {
    dispatch({ type: 'RESET' });
    mutation.reset();
  };

  let friendValue: FriendValue;
  if (state.recipientId) friendValue = state.isGroupShare ? { groupId: state.recipientId } : { organisationId: state.recipientId };

  return (
    <Dialog
      open={open}
      TransitionProps={{ onExited }}
      fullWidth
      maxWidth="md"
    >
      <TPDialogTitle>{t(isSelfShare ? 'titleSelf' : 'title')}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} mt={3}>
          {!isSelfShare && (
            <SelectFriend
              label={t('selectRecipient.label')}
              value={friendValue}
              setValue={value => {
                if (!value) return dispatch({ type: 'SET_ORGANISATION', payload: undefined });
                if ('groupId' in value) return dispatch({ type: 'SET_GROUP', payload: value });
                return dispatch({ type: 'SET_ORGANISATION', payload: value });
              }}
              disabled={mutation.isPending}
            />
          )}

          <SelectAssets
            label={t('selectAssets.label')}
            deviceIds={assetsQuery.data ? Object.values(assetsQuery.data).map(a => a.deviceId) : undefined}
            selectedDeviceIds={state.deviceIds}
            assetsByDeviceId={assetsQuery.data}
            devicesById={devicesById}
            setSelectedDeviceIds={value => dispatch({
              type: 'SET_DEVICE_IDS',
              payload: typeof value === 'function' ? value(state.deviceIds ?? []) : value,
            })}
            renderCount={renderDeviceShareCount}
            disabled={mutation.isPending}
            assetGroups={assetGroups}
            isLoading={isLoading}
            getGroupOptionsFn={getGroupOptionsForOwnedAssets}
          />

          <ShareDateRangePicker
            tz={tz}
            rangeType={state.shareRangeType}
            range={{ start: state.shareStart, end: state.shareEnd }}
            onChangeRangeType={value => dispatch({ type: 'SET_RANGE_TYPE', payload: value })}
            onChangeRange={value => dispatch({ type: 'SET_DATE_RANGE', payload: { shareStart: value.start ?? undefined, shareEnd: value.end ?? undefined } })}
            disabled={mutation.isPending}
          />

          <PermissionsPicker
            permissions={state.permissions}
            requiredPermissions={requiredPermissions}
            setPermissions={value => dispatch({ type: 'SET_PERMISSIONS', payload: value })}
            disabled={mutation.isPending}
          />

          <NoteField
            value={state.notes ?? ''}
            setValue={value => dispatch({ type: 'SET_NOTES', payload: value })}
            disabled={mutation.isPending}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Box flex={1}>
          <Collapse in={mutation.isError}>
            <Alert sx={{ mb: 3 }} severity="error">{t('notifications.error')}</Alert>
          </Collapse>
          <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              disabled={mutation.isPending}
              onClick={onClose}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={onSave}
              disabled={!canSave}
            >
              {t(mutation.isPending ? 'actions.saving' : 'actions.save')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
