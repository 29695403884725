import React from 'react';

import {
  Stack,
  Typography
} from '@mui/material';
import StatusIcon from '../statusIcon-view';
import { Service } from '../types';

export const SystemStatusServiceWidget = ({ service }: { service: Service }) => {
  const {
    name,
    status,
  } = service;

  return (
    <Stack direction="row" justifyContent="space-between" py={2} alignItems="center">
      <Typography variant="h3">{name}</Typography>
      <StatusIcon status={status} />
    </Stack>
  );
};
