import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  editableInput: {

  },
  editableTextarea: {

  },
  editing: {
    '&:firstChild': {
      width: '90%'
    }
  },
  notEditing: {
    '&:hover > p': {
      color: 'lightblue'
    },
    '&:hover > svg': {
      opacity: 1,
      color: 'lightblue'
    }
  },
  editIcon: {
    opacity: 0,
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    verticalAlign: 'text-bottom'
  },
  plainText: {
    display: 'inline-block'
  },
  textField: {
    width: '100%'
  }
}));
