import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { setAutoFreeze } from 'immer';
import createReducers from 'reducers';
import { configureStore, type Middleware } from '@reduxjs/toolkit';

const isProduction = process.env.NODE_ENV === 'production';

const userTiming: Middleware = () => next => action => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`
  );
  return result;
};

const initialState = {};
const middleware: Middleware[] = [];

// Records Redux events in performance profiler:
if (!isProduction) {
  middleware.push(userTiming);
}

const rootReducer = createReducers();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  devTools: !isProduction,
  middleware: defaultMiddleware => defaultMiddleware(
    {
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    },
  ).concat(middleware),
});

// If immer auto-freezes reducers, redux-persist fails (it attempts
// to add an '_persist' key to the returned state)
// https://github.com/rt2zz/redux-persist/issues/747
setAutoFreeze(false);

(window as any).store = store;

export type AppDispatch = typeof store.dispatch;
