import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(`
  background-color: transparent;
  text-align: right;
  position: relative;
`);

export const Content = styled(Box)<{ open: boolean }>(({ theme, open }) => `
  overflow-y: auto;
  background-color: ${theme.palette.common.white};
  border: ${theme.border.default};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 12px;
  border-radius: 4px;
  flex-wrap: wrap;
  position: absolute;
  padding: 10px;
  user-select: none;
  outline: none;

  ${open ? `
    top: 55px;
    right: 5px;
    width: 260px;
    z-index: 999999;
    display: flex;
  ` : `
    z-index: -1;
    display: none;
  `}
`);
