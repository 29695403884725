import React, { ReactNode } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';

interface FeatureFlagProps {
  feature: string
  enabled?: ReactNode
  disabled?: ReactNode
  loading?: ReactNode
}
export const FeatureFlag = ({ feature, enabled, disabled, loading }: FeatureFlagProps): JSX.Element => {
  const value = useFeatureFlag(feature);
  return (
    <>
      {value === true && enabled}
      {value === false && disabled}
      {value === undefined && loading}
    </>
  );
};
