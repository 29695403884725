import React from 'react';
import {
  Grid,
  List,
  ListItem,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './detailPanel-styles';

const DetailPanel = ({
  rowData
}) => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationsList');

  const primaryContact = {
    name: rowData.contactName,
    phone: [rowData.contactNumberPrimary, rowData.contactNumberAlternate].filter(Boolean).join(', '),
    email: rowData.email,
    city: rowData.contactCity,
    country: rowData.contactCountry,
  };

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item sm={12}>
        <Typography className={classes.detailPanelTitle}>{t('primaryContactDetail')}</Typography>
        <List>
          {primaryContact && Object
            .keys(primaryContact)
            .filter(c => !!primaryContact[c])
            .map(c => (
              <ListItem key={c} className={classes.listItem}>
                <Typography className={classes.label}>{t(`${c}`)}</Typography>
                <Typography className={classes.contactDetail}>{primaryContact[c]}</Typography>
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default DetailPanel;
