import { Box, Typography } from '@mui/material';
import React from 'react';

interface MapInstructionProps {
  text: string
}

export const MapInstruction = ({ text }: MapInstructionProps): React.JSX.Element => {
  const drawInstructionStyle = {
    position: 'absolute',
    left: '50%',
    bottom: '36px',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    p: 1,
    borderRadius: 2,
    overflow: 'hidden',
    opacity: 1,
    textAlign: 'center',
    zIndex: 999,
    boxShadow: 'none',
    backgroundColor: 'rgba(0,0,0,.4)',
    '& p': {
      fontWeight: 500,
      fontSize: '1rem',
      color: 'common.whiteConstant'
    },
  };

  return (
    <Box
      aria-label="drawingInstructions"
      sx={drawInstructionStyle}>
      <Typography>{text}</Typography>
    </Box>
  );
};
