import React, { ReactNode, useRef } from 'react';
import { useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { Box } from '@mui/material';
import { DeviceCluster, RecipientCluster } from './helpers';

interface VirtualizedClustersProps<T> {
  clusters: T[]
  renderCluster: (virtualItem: VirtualItem, cluster: T, virtualizer: Virtualizer<HTMLElement, Element>) => ReactNode
}

const VirtualizedClusters = <T extends (RecipientCluster | DeviceCluster)>({ clusters, renderCluster }: VirtualizedClustersProps<T>) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: clusters.length,
    estimateSize: index => 128 + (73 * clusters[index].shares.length),
    getScrollElement: () => document.getElementById('pageScrollParent'),
    overscan: 2,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <Box
      ref={parentRef}
      style={{
        height: virtualizer.getTotalSize(),
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
        }}
      >
        {virtualItems.map(virtualRow => renderCluster(virtualRow, clusters[virtualRow.index], virtualizer))}
      </Box>
    </Box>
  );
};

export default VirtualizedClusters;
