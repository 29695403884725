import useFeatureAssets, { FeatureAssetsResult } from 'contexts/featureAssets/useFeatureAssets';
import { useCallback } from 'react';

export enum EventNotificationAssetGroupsStatus {
  All,
  Some,
  None,
  Never,
  Loading,
  Empty,
}

export const getEventNotificationAssetGroupsFeatureStatus = (
  featureAssets: FeatureAssetsResult,
  assetGroupIds: number[] | undefined,
): EventNotificationAssetGroupsStatus => {
  if (!assetGroupIds?.length) return EventNotificationAssetGroupsStatus.Empty;
  if (featureAssets.isLoading) return EventNotificationAssetGroupsStatus.Loading;
  if (featureAssets.all) return EventNotificationAssetGroupsStatus.All;
  if (!featureAssets.some) return EventNotificationAssetGroupsStatus.Never;

  if (assetGroupIds.every(id => featureAssets.completeAssetGroups.includes(id))) return EventNotificationAssetGroupsStatus.All;
  if (assetGroupIds.some(id => featureAssets.completeAssetGroups.includes(id) || featureAssets.partialAssetGroups.includes(id))) return EventNotificationAssetGroupsStatus.Some;

  return EventNotificationAssetGroupsStatus.None;
};

export const useEventNotificationAssetGroupsFeatureStatus = () => {
  const featureAssets = useFeatureAssets('manage.notifications');

  return useCallback((assetGroupIds: number[] | undefined) => getEventNotificationAssetGroupsFeatureStatus(
    featureAssets,
    assetGroupIds,
  ), [featureAssets]);
};
