import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Check, Group } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import { TemporalGroupShare, TemporalShare } from 'apis/rest/temporalShares/types';
import {
  groupData,
  GroupedData,
  isTemporalGroupShare,
  Period,
  selectAssetsByDeviceId,
  selectDevicesById,
  sortSharerClusters,
  useFilterState,
  useRecipientClusters,
  useSortSharesByDevice,
} from './helpers';
import { AssetDetails } from './assetDetails';
import ShareTime from './shareTime';
import { DeviceDetails } from './deviceDetails';
import Filters from './filters';
import { GroupParticipant, OrganisationParticipant } from './selectParticipants';
import VirtualizedClusters from './virtualizedClusters';
import useTimezone from "hooks/session/useTimezone";

interface ShareRowProps {
  share: TemporalShare | TemporalGroupShare
  asset: AssetWithDevice | undefined
  device: DeviceBasic | undefined
  isAssetLoading: boolean
  isDeviceLoading: boolean
  timezone: string
}

const ShareRow = ({ share, asset, device, isAssetLoading, isDeviceLoading, timezone }: ShareRowProps) => {
  const t = useTranslations('pages.sharing');
  return (
    <>
      <TableRow sx={share.notes ? { 'td, th': { border: 0 } } : { '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={{ py: 2 }}><AssetDetails asset={asset} isLoading={isAssetLoading} /></TableCell>
        <TableCell sx={{ py: 2 }}><DeviceDetails device={device} isLoading={isDeviceLoading} /></TableCell>
        <TableCell>
          <ShareTime share={share} timezone={timezone} />
        </TableCell>
        <TableCell>
          {share.canViewCurrent && !share.canViewHistory && t('trails.live')}
          {share.canViewCurrent && share.canViewHistory && t('trails.all')}
          {!share.canViewCurrent && share.canViewHistory && t('trails.historic')}
          {!share.canViewCurrent && !share.canViewHistory && t('trails.none')}
        </TableCell>
        <TableCell align="center">
          {share.canViewForms && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canViewForms')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendTextMessages && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendTextMessages')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendConfiguration && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendConfiguration')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canEditCallSign && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canEditCallSign')}</Box>
            </>
          )}
        </TableCell>
      </TableRow>
      {share.notes && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell colSpan={100} sx={{ pt: 0 }}><Typography variant="body3">{t('columns.notes')}: {share.notes}</Typography></TableCell>
        </TableRow>
      )}
    </>
  );
};

const useSharers = (data: GroupedData | undefined) => useMemo(() => {
  const organisationSharers = data?.organisationIds.map<OrganisationParticipant>(organisationId => {
    const share = data.sharesByOrganisationId[organisationId][0];
    return {
      organisationId,
      name: share.organisationName,
    };
  }) ?? [];

  const groupSharers = data?.groupIds.map<GroupParticipant>(groupId => {
    const share = data.sharesByGroupId[groupId][0];
    return {
      groupId,
      name: share.groupName,
    };
  }) ?? [];

  return [...organisationSharers, ...groupSharers].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
}, [data]);

const SharesTo = (): JSX.Element => {
  const t = useTranslations('pages.sharing');
  const timezone = useTimezone();

  const [filters, setFilters] = useFilterState();

  const sharesQuery = useGetTemporalSharesToList({ select: groupData });

  const assetsByDeviceIdQuery = useGetAssetsList({ select: selectAssetsByDeviceId }).query;
  const assetsByDeviceId = assetsByDeviceIdQuery.data;

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesByIdQuery.data;

  const sortShares = useSortSharesByDevice(assetsByDeviceId);

  const clusters = useRecipientClusters(sharesQuery.data, filters, sortSharerClusters);

  const sharers = useSharers(sharesQuery.data);

  return (
    <Box pb={10}>
      <Paper elevation={0} sx={{ p: 3, mb: 6 }}>
        <Stack direction="row" spacing={3} flexWrap="wrap" useFlexGap>
          <Filters
            state={filters}
            setFilters={setFilters}
            assetsByDeviceId={assetsByDeviceId ?? {}}
            devicesById={devicesById ?? {}}
            data={sharesQuery.data}
            participants={sharers}
          />
        </Stack>
      </Paper>

      {sharesQuery.isLoading ? (
        <Paper elevation={0} sx={{ p: 3 }} component="article">
          <Typography textAlign="center">{t('loading')}</Typography>
        </Paper>
      ) : (
        clusters.length ? (
          <VirtualizedClusters
            clusters={clusters}
            renderCluster={(virtualItem, cluster, virtualizer) => (
              <Paper
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                elevation={0}
                sx={{ pb: 2, mb: 3 }}
                component="article"
              >
                <Box p={3} component="header">
                  <Typography variant="subtitle1">{t('sharedBy')}</Typography>
                  <Typography variant="h3">{cluster.organisationName}</Typography>
                  {'groupId' in cluster && (
                    <Typography variant="subtitle1">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <span>{t('via')}</span><Group /><span>{cluster.groupName}</span>
                      </Stack>
                    </Typography>
                  )}
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '25%', whiteSpace: 'nowrap' }}>{t('columns.asset')}</TableCell>
                      <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.device')}</TableCell>
                      <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.time')}</TableCell>
                      <TableCell sx={{ width: '10%', whiteSpace: 'nowrap' }}>{t('columns.trails')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.forms')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.message')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.configure')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.callsign')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortShares(cluster.shares).map(share => (
                      <ShareRow
                        key={isTemporalGroupShare(share) ? `groupShare-${share.id}` : `share-${share.id}`}
                        share={share}
                        asset={assetsByDeviceId?.[share.deviceId]}
                        device={devicesById?.[share.deviceId]}
                        isAssetLoading={assetsByDeviceIdQuery.isLoading}
                        isDeviceLoading={devicesByIdQuery.isLoading}
                        timezone={timezone}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          />
        ) : (
          <Paper elevation={0} sx={{ p: 3 }} component="article">
            <Alert severity="info">
              {(!!filters.deviceIds.length || !!filters.organisationIds.length || filters.period !== Period.All) ? t('noSharesForFilters') : t('noSharesTo')}
            </Alert>
          </Paper>
        )
      )}
    </Box>
  );
};

export default SharesTo;
