import React from 'react';

const Form = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M11,4H5C4.7,4,4.5,4.2,4.5,4.5v7C4.5,11.8,4.7,12,5,12h6c0.3,0,0.5-0.2,0.5-0.5v-7C11.5,4.2,11.3,4,11,4z
M8,10.5H6v-1h2V10.5z M10,8.5H6v-1h4V8.5z M10,6.5H6v-1h4V6.5z"
    />
  </svg>
);

export default Form;
