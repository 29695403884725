import React, { useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { match, P } from 'ts-pattern';
import useSnackbar from 'hooks/useSnackbar';
import useIceContactGroupCanSetDefault, { CanSetDefault } from 'hooks/ice/useIceContactGroupCanSetDefault';
import { useMutateIceContactGroup } from 'apis/rest/iceContactGroups/hooks';
import {
  SetDefaultContactGroupDialog,
  SetDefaultContactGroupDialogStatus,
} from 'components/dialogs/contactGroups/setDefault';

interface SetDefaultProps {
  group: ContactGroup
}

const SetDefault = ({ group }: SetDefaultProps): JSX.Element | null => {
  const t = useTranslations('pages.manage.ice.edit.default');
  const [open, setOpen] = useState(false);
  const canSet = useIceContactGroupCanSetDefault(group);
  const mutation = useMutateIceContactGroup();
  const snackbar = useSnackbar();

  if (group.isDefault && !mutation.isPending) return null;

  const message = match<CanSetDefault>(canSet)
    .with(CanSetDefault.Loading, () => <Typography>{t('loading')}</Typography>)
    .with(CanSetDefault.Valid, () => <Typography>{t('canSet')}</Typography>)
    .with(CanSetDefault.Invalid, () => (
      <>
        <Typography>{t('cannotSet')}</Typography>
        <Typography>{t('cannotSetExplanation')}</Typography>
      </>
    ))
    .exhaustive();

  return (
    <>
      <Stack spacing={3} px={3} direction="row" justifyContent="space-between" height="4em">
        <Stack justifyContent="center">{message}</Stack>
        <Button
          size="large"
          variant="outlined"
          sx={{ minWidth: '10rem' }}
          disabled={canSet !== CanSetDefault.Valid}
          onClick={() => setOpen(true)}
        >
          {t('set')}
        </Button>
      </Stack>

      <SetDefaultContactGroupDialog
        open={open}
        onClose={status => {
          setOpen(false);
          if (status === SetDefaultContactGroupDialogStatus.Saved) {
            snackbar.display({
              id: `iceContactGroupSetDefault.${group.id}`,
              text: t('dialog.snackbar.success', { name: group.name }),
              type: 'success',
            });
          }
        }}
        group={group}
        mutation={mutation}
        valid={canSet === CanSetDefault.Valid}
        ariaLabel={t('dialog.title')}
        title={t('dialog.title')}
      >
        <Typography>
          {t.rich('dialog.message', { name: group.name, strong: chunks => <strong>{chunks}</strong> })}
        </Typography>
        <Typography>{t('dialog.explanation')}</Typography>
        {mutation.isError && <Alert severity="error">{t('dialog.unexpectedError')}</Alert>}
      </SetDefaultContactGroupDialog>
    </>
  );
};

export default SetDefault;
