import { groupBy, sumBy } from 'lodash/fp';
import { useSupplementaryDataFields, useSupplementaryDataForTrips } from 'apis/rest/trips/hooks';
import { FieldAccessor } from 'helpers/supplementaryData';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

const useTotalSupplementaryDataForTrips = (tripIds: string[]) => {
  const supplementaryDataEnabled = useFeatureAssets('reporting.supplementaryData').some;
  const suppDataQueries = useSupplementaryDataForTrips(tripIds);
  const suppDataFieldsQuery = useSupplementaryDataFields({ enabled: supplementaryDataEnabled });

  const suppDataFields = suppDataFieldsQuery.data ?? [];
  const suppData = groupBy('name', suppDataQueries.flatMap(q => q.data?.fields ?? []));

  const isLoading = suppDataQueries.some(q => q.isLoading) || suppDataFieldsQuery.isLoading;

  if (isLoading) {
    return {};
  }

  return Object.entries(suppData).reduce<Record<string, { total: number | string, displayName: string }>>((acc, [name, fields]) => {
    const relevantField = suppDataFields.find(d => d.fieldName === fields[0].name);
    if (fields[0].dataType === 'String') {
      acc[name] = {
        total: fields.map(f => f.stringValue).join(', '),
        displayName: relevantField?.displayName || fields[0].name
      };
    } else {
      acc[name] = {
        total: sumBy(FieldAccessor[fields[0].dataType], fields),
        displayName: relevantField?.displayName || fields[0].name
      };
    }
    return acc;
  }, {});
};

export default useTotalSupplementaryDataForTrips;
