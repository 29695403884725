import React from 'react';

const Oooiin = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path className="icon-detail" fill="#fff" d="M7,4.8L6.3,5.5l2,2H4v1h4.3l-2,2L7,11.2l2.85-2.85c0.2-0.2,0.2-0.5,0-0.7L7,4.8z" />
    <rect className="icon-detail" fill="#fff" x="11" y="4.5" width="1" height="7" />
  </svg>
);

export default Oooiin;
