import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Tooltip, Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import DetailPanel from 'components/shared/DetailPanel';
import {
  useGetAllFeaturesList,
  useGetOrganisationFeaturesList,
  useSetOrganisationSelectedFeatures
} from 'apis/rest/features/hooks';
import { FeatureDetails } from 'apis/rest/features/types';
import { useGetAllOrganisations } from 'apis/rest/memberships/hook';
import { isDefined } from 'utils/type';
import LinkAbove from 'components/shared/linkAbove';
import UseSnackbar from 'hooks/useSnackbar';

interface SelectedFeature {
  featureKey: string
  featureName: string
  description: string
  organisationId: string
  selected: boolean
  canExpire: boolean
  expiryDate: string | null
  disabled: boolean
}

const OrganisationFeaturesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const organisationId = params.id ?? '';
  const getAllFeaturesListQuery = useGetAllFeaturesList().query;
  const getOrganisationFeaturesListQuery = useGetOrganisationFeaturesList(organisationId).query;
  const getAllOrganisations = useGetAllOrganisations().query;

  const snackbar = UseSnackbar();
  const hasOrganisationFeaturesError = getAllFeaturesListQuery.isError || getOrganisationFeaturesListQuery.isError || getAllOrganisations.isError;
  useEffect(() => {
    if (hasOrganisationFeaturesError) {
      snackbar.display({ id: 'getOrganisationFeaturesFailedSnackbar', text: 'Failed to get organisation features.', type: 'error' });
    }
  }, [hasOrganisationFeaturesError, snackbar]);

  const getOrganisationName = useCallback(() => {
    const organisations = getAllOrganisations.isSuccess ? getAllOrganisations.data : [];
    const matchingOrganisations = organisations.filter(organisation => organisation.id === organisationId);
    let organisationName = '';
    if (matchingOrganisations.length === 1) {
      organisationName = matchingOrganisations[0].name;
    }
    return organisationName;
  }, [getAllOrganisations.data, getAllOrganisations.isSuccess, organisationId]);

  const getInitialSelectedFeatures = useCallback((): SelectedFeature[] => {
    const sortFunc = (a: FeatureDetails, b: FeatureDetails) => {
      const nameA = a.featureName.toUpperCase();
      const nameB = b.featureName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };

    return getAllFeaturesListQuery.data?.sort(sortFunc).map(featureDetails => {
      const data = getOrganisationFeaturesListQuery.data?.find(organisationFeature => organisationFeature.featureKey === featureDetails.featureKey) ?? false;
      return {
        featureKey: featureDetails.featureKey,
        featureName: featureDetails.featureName,
        description: featureDetails.description,
        organisationId,
        selected: data !== false,
        canExpire: data ? data.featureCanExpire ?? false : false,
        expiryDate: data ? data.expiryDate : null,
        disabled: data ? data.setForMarketSector : false
      };
    }).filter(isDefined) ?? [];
  }, [getAllFeaturesListQuery.data, getOrganisationFeaturesListQuery.data, organisationId]);

  const [selectedFeatures, setSelectedFeatures] = useState(() => getInitialSelectedFeatures());

  const isLoadingData = getAllFeaturesListQuery.isLoading || getOrganisationFeaturesListQuery.isLoading;
  useEffect(() => {
    if (!isLoadingData) setSelectedFeatures(getInitialSelectedFeatures());
  }, [isLoadingData, setSelectedFeatures, getInitialSelectedFeatures]);

  const toggleFeatureSelected = useCallback((featureKey: string, checked: boolean) => {
    setSelectedFeatures(value => value.map(feature => {
      if (feature.featureKey === featureKey) {
        return {
          ...feature,
          selected: checked
        };
      }
      return feature;
    }));
  }, []);

  const toggleFeatureTrialSelected = useCallback((featureKey: string, checked: boolean) => {
    setSelectedFeatures(value => value.map(feature => {
      if (feature.featureKey === featureKey && feature.selected) {
        return {
          ...feature,
          canExpire: checked
        };
      }
      return feature;
    }));
  }, []);

  const setOrganisationFeaturesMutation = useSetOrganisationSelectedFeatures();

  const saveSelectedFeatures = () => {
    setOrganisationFeaturesMutation.mutate(
      {
        organisationId,
        selectedFeatures: {
          features: selectedFeatures
            .filter(selectedFeature => selectedFeature.selected)
            .map(selectedFeature => (
              {
                featureKey: selectedFeature.featureKey,
                featureCanExpire: selectedFeature.canExpire,
                expiryDate: selectedFeature.canExpire ? selectedFeature.expiryDate : null,
                setForMarketSector: selectedFeature.disabled
              }
            ))
        }
      },
      {
        onSuccess: () => {
          snackbar.display({ id: 'organisationFeaturesSaved', text: `Features Saved For ${getOrganisationName()}`, type: 'success' });
          navigate('/staff/organisations');
        },
        onError: () => {
          snackbar.display({ id: 'organisationFeaturesSaveError', text: `Failed To Save Features For ${getOrganisationName()}`, type: 'error' });
        },
      },
    );
  };

  const isLoading = isLoadingData || setOrganisationFeaturesMutation.isPending;

  return (
    <Page title="Organisation Features">
      <SettingsMenuPageWrapper p={8} id="pageScrollParent">
        <Container maxWidth={false}>
          <LinkAbove />
          <Box>
            <Typography variant="h1" gutterBottom>{getOrganisationName()}</Typography>
            <Typography paragraph>Toggle features available to to {getOrganisationName()}. Then click &lsquo;Save&rsquo;</Typography>
          </Box>

          <DetailPanel spacing={3} pl={2} pb={2} pr={2} pt={2}>
            <Box>
              {selectedFeatures.map(selectedFeature => (
                <Tooltip title={selectedFeature.disabled ? 'Active for market sector for this organisation' : ''} placement="top-start">
                  <FormGroup key={selectedFeature.featureKey}>
                    <FormControlLabel
                      control={<Checkbox checked={selectedFeature.selected} />}
                      label={(
                        <>
                          <strong>{selectedFeature.featureName}</strong>
                          &nbsp;-&nbsp;
                          {selectedFeature.description}
                        </>
                      )}
                      onChange={(_, checked) => toggleFeatureSelected(selectedFeature.featureKey, checked)}
                      disabled={isLoading || selectedFeature.disabled}
                    />
                    {selectedFeature.selected
                      && (
                        <FormControlLabel
                          sx={{ pl: 4 }}
                          control={<Checkbox checked={selectedFeature.canExpire} />}
                          label={(
                            <>
                              Trial
                              {
                                selectedFeature.expiryDate
                                  ? <>&nbsp;-&nbsp;Expires:&nbsp;{selectedFeature.expiryDate}</>
                                  : ''
                              }
                            </>
                          )}
                          onChange={(_, checked) => toggleFeatureTrialSelected(selectedFeature.featureKey, checked)}
                          disabled={isLoading || selectedFeature.disabled}
                        />
                      )}
                  </FormGroup>
                </Tooltip>
              ))}
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={saveSelectedFeatures}
                sx={{ minWidth: '10rem', px: 6, py: 2, fontSize: '1rem' }}
                disabled={isLoading}
              >
                Save
              </Button>
            </Box>
          </DetailPanel>

        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default OrganisationFeaturesPage;
