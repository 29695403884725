import React from 'react';
import clsx from 'clsx';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from '../leg-styles';

const InsignificantReports = ({
  reports
}) => {
  const classes = useStyles();

  const t = useTranslation('contextbox.asset.activity');

  if (!reports?.length > 0) {
    return (
      <ListItem className={classes.listItem}>
        <ListItemIcon className={classes.iconWrapper}>
          <img alt="no event icon" src="eventIcons/none.svg" />
        </ListItemIcon>
        <ListItemText className={classes.listText}>
          <Box className={classes.eventName}>No reports during this leg</Box>
        </ListItemText>
      </ListItem>
    );
  }

  const eventCount = reports.filter(r => r.event).length;
  const formCount = reports.filter(r => !r.event && r.form).length;
  const reportCount = reports.filter(r => !r.event && !r.form).length;

  const counts = [];
  if (reportCount) {
    counts.push(`${reportCount} ${t(reportCount > 1 ? 'reports' : 'report')}`);
  }
  if (eventCount) {
    counts.push(`${eventCount} ${t(eventCount > 1 ? 'filteredEvents' : 'filteredEvent')}`);
  }
  if (formCount) {
    counts.push(`${formCount} ${t(formCount > 1 ? 'filteredForms' : 'filteredForms')}`);
  }
  return (
    <ListItem className={classes.listItem}>
      <ListItemText className={clsx(classes.listText, classes.groupedListText)}>
        <Box className={clsx(classes.eventName, classes.groupedEventName)}>
          { counts.join(', ')}
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export default InsignificantReports;
