import { Viewport } from './viewportContext';

const defaultRecord: Record<string, Viewport> = {
  default: {
    bearing: 0,
    height: 0,
    longitude: 0,
    latitude: 0,
    zoom: 0,
    pitch: 0,
    width: 0,
  }
};

export const get = () => {
  try {
    const viewports = JSON.parse(window.localStorage.getItem('viewports') ?? '') as Record<string, Viewport>;

    // omit any potentially persisted transitionInterpolator property
    Object.values(viewports)
      .forEach(viewport => {
        if ('transitionInterpolator' in viewport) delete viewport.transitionInterpolator;
      });

    return viewports;
  } catch (e) {
    return defaultRecord;
  }
};

const replacer = (key: string, value: any) => {
  // don't persist transitionInterpolator
  if (key === 'transitionInterpolator') return undefined;
  return value;
};

export const set = (viewports: Record<string, Viewport>) => window.localStorage.setItem('viewports', JSON.stringify(viewports, replacer));
