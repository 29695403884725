import Smart from 'hocs/smart';
import { signupAction } from 'slices/session/thunks';
import { setSSOSignupCallbackFailure, setSSOSignupCallbackSuccess } from 'slices/session/session.slice';
import SignupView from './signup-view';

export default Smart(
  SignupView,
  () => ({}),
  ac => ({
    signup: signupAction,
    SSOSignupSuccess: setSSOSignupCallbackSuccess,
    SSOSignupFailure: setSSOSignupCallbackFailure,
  })
);
