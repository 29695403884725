import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This component scrolls to the element with the ID as specified in the location hash
const HashScroll = () => {
  const location = useLocation();

  useEffect(() => {
    document.getElementById(location.hash.slice(1))?.scrollIntoView({ behavior: 'smooth' });
  }, [location.hash]);

  return null;
};

export default HashScroll;
