import { DefaultUserSettings, UserSettings, UserSettingsV1 } from './userSettingsContext';

const migrate = (oldSettings: UserSettingsV1 | UserSettings): UserSettings => {
  if (oldSettings.version === 1) {
    return { ...DefaultUserSettings, map: { ...DefaultUserSettings.map, kml: oldSettings.map.kml } };
  }
  return oldSettings;
};

export const get = () => {
  const userSettings = JSON.parse(
    window.localStorage.getItem('userSettings') ?? JSON.stringify(DefaultUserSettings)
  ) as UserSettings;

  if (userSettings.version !== DefaultUserSettings.version) {
    return migrate(userSettings);
  }

  return userSettings;
};

export const set = (settings: UserSettings) => window.localStorage.setItem('userSettings', JSON.stringify(settings));
