import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useControl } from 'react-map-gl';
import MapboxDraw, { constants, lib } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { featureCollection } from '@turf/helpers';
import { Feature, FeatureCollection, Point } from 'geojson';
import { selectHiddenSearchPatternIds, selectSelectedSearchPattern } from 'slices/searchPatterns.slice';
import { calculateBoundingBox, calculateFeatures } from './helpers';
import SearchPatternsDrawMode, {
  Events,
  MoveEvent,
  ScaleEvent,
  RotateEvent,
  SelectEvent
} from './SearchPatternsDrawMode';

const drawStyle = [
  {
    id: 'sp-fill-inactive',
    type: 'fill',
    filter: ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
    ],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.15
    }
  },
  {
    id: 'sp-fill-stroke-inactive',
    type: 'line',
    filter: ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-opacity': 0.5,
      'line-width': 2
    },
  },
  {
    id: 'sp-fill-active',
    type: 'fill',
    filter: ['all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
    ],
    paint: {
      'fill-color': '#fbb03b',
      'fill-outline-color': '#fbb03b',
      'fill-opacity': 0.5
    }
  },
  {
    id: 'sp-fill-stroke-active',
    type: 'line',
    filter: ['all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#fbb03b',
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    id: 'sp-point-stroke',
    type: 'circle',
    filter: ['==', '$type', 'Point'],
    paint: {
      'circle-radius': 6,
      'circle-color': '#fff'
    }
  },
  {
    id: 'sp-point',
    type: 'circle',
    filter: ['==', '$type', 'Point'],
    paint: {
      'circle-radius': 4,
      'circle-color': '#fbb03b'
    }
  },
  {
    id: 'sp-origin',
    type: 'circle',
    filter: ['all',
      ['==', '$type', 'Point'],
      ['==', 'user_meta', 'origin'],
    ],
    paint: {
      'circle-radius': 6,
      'circle-color': '#00e34a'
    },
  },
  {
    id: 'sp-arrows',
    type: 'line',
    filter: ['all',
      ['==', '$type', 'LineString'],
      ['==', 'user_meta', 'arrow'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00e34a',
      'line-width': 2,
    },
  },
  {
    id: 'sp-path',
    type: 'line',
    filter: ['all',
      ['==', '$type', 'LineString'],
      ['==', 'user_meta', 'path'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00e34a',
      'line-width': 3,
    },
  },
];

type SearchPatternsControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  modeValue: 'draw_line_string' | 'search_pattern';
  canInteract: boolean;

  searchPatternOnMove?: (e: MoveEvent) => void,
  searchPatternOnScale?: (e: ScaleEvent) => void,
  searchPatternOnRotate?: (e: RotateEvent) => void,
  searchPatternOnSelect?: (e: SelectEvent) => void,

  pathFeatures?: FeatureCollection;
  pathOnCreate?: (evt: { features: Feature[] }) => void;
  pathOnUpdate?: (evt: { features: Feature[]; action: string }) => void;
  pathOnDelete?: (evt: { features: Feature[] }) => void;
  pathOnSelect?: (evt: { features: Feature[], points: Point[], type: string }) => void;
}

const MapDrawControl: React.FC<SearchPatternsControlProps> = ({
  modeValue,
  canInteract,
  searchPatternOnMove,
  searchPatternOnScale,
  searchPatternOnRotate,
  searchPatternOnSelect,
  pathFeatures,
  pathOnCreate,
  pathOnUpdate,
  pathOnDelete,
  pathOnSelect,
  ...props
}) => {
  const selected = useSelector(selectSelectedSearchPattern);
  const hiddenIds = useSelector(selectHiddenSearchPatternIds);

  // @ts-ignore
  const mapDraw = useControl<MapboxDraw>(
    () => new MapboxDraw({
      displayControlsDefault: false,
      controls: undefined,
      userProperties: true,
      modes: {
        search_pattern: SearchPatternsDrawMode,
        // draw_line_string relies on transitioning to simple_select upon completion
        simple_select: SearchPatternsDrawMode,
        draw_line_string: MapboxDraw.modes.draw_line_string,
      },
      defaultMode: 'search_pattern',
      styles: (lib.theme as object[]).concat(drawStyle),
      ...props,
    }),
    ({ map }) => {
      if (searchPatternOnMove) map.on(Events.MOVE, searchPatternOnMove);
      if (searchPatternOnScale) map.on(Events.SCALE, searchPatternOnScale);
      if (searchPatternOnRotate) map.on(Events.ROTATE, searchPatternOnRotate);
      if (searchPatternOnSelect) map.on(Events.SELECT, searchPatternOnSelect);
      if (pathOnCreate) map.on(constants.events.CREATE, pathOnCreate);
      if (pathOnUpdate) map.on(constants.events.UPDATE, pathOnUpdate);
      if (pathOnDelete) map.on(constants.events.DELETE, pathOnDelete);
      if (pathOnSelect) map.on(constants.events.SELECTION_CHANGE, pathOnSelect);
    },
    ({ map }) => {
      if (searchPatternOnMove) map.off(Events.MOVE, searchPatternOnMove);
      if (searchPatternOnScale) map.off(Events.SCALE, searchPatternOnScale);
      if (searchPatternOnRotate) map.off(Events.ROTATE, searchPatternOnRotate);
      if (searchPatternOnSelect) map.off(Events.SELECT, searchPatternOnSelect);
      if (pathOnCreate) map.off(constants.events.CREATE, pathOnCreate);
      if (pathOnUpdate) map.off(constants.events.UPDATE, pathOnUpdate);
      if (pathOnDelete) map.off(constants.events.DELETE, pathOnDelete);
      if (pathOnSelect) map.off(constants.events.SELECTION_CHANGE, pathOnSelect);
    },
    {
      position: 'top-left',
    }
  );

  const geojson = useMemo(() => {
    if (!selected) return featureCollection([]) as FeatureCollection;
    const feats = calculateFeatures(selected);
    const bbox = calculateBoundingBox(selected, feats.lineString.geometry);
    return featureCollection(feats.suppFeatures.concat(feats.origin, feats.lineString, bbox));
  }, [selected]);

  useEffect(() => {
    switch (modeValue) {
      case 'draw_line_string':
        mapDraw.set(pathFeatures ?? featureCollection([]));
        break;
      case 'search_pattern':
        mapDraw.set(geojson);
        break;
      default:
        throw new Error();
    }
  }, [mapDraw, modeValue, geojson, pathFeatures]);

  useEffect(() => {
    const opts = modeValue === 'search_pattern' ? {
      featureId: selected?.id,
      isLocked: selected?.isLocked,
      canInteract,
    } : {};
    mapDraw.changeMode(modeValue as string, opts);
  }, [mapDraw, modeValue, hiddenIds, selected?.id, selected?.isLocked, canInteract]);

  return null;
};

export default MapDrawControl;
