import React from 'react';
import {
  Alert,
  Box,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Button,
  Stack,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import { useEditContact } from './editContactContext';
import { useDeleteContact } from './deleteContactContext';

interface ContactDetailsRow {
  contact: Contact
  value: string
}

interface ContactDetailsProps {
  title: string
  description: React.ReactNode
  contactName: string
  rows: ContactDetailsRow[]
  onAdd: () => void
  addText: React.ReactNode
  noItemsText: React.ReactNode
  input: React.ReactNode
  addDisabled: boolean
  renderContact: (props: { row: ContactDetailsRow }) => JSX.Element
  deleteTooltipTitle: React.ReactNode
  editTooltipTitle: React.ReactNode
  canDelete: (contact: Contact) => boolean
}

export const ContactDetails = (
  {
    title,
    description,
    contactName,
    rows,
    onAdd,
    addText,
    noItemsText,
    input,
    addDisabled,
    renderContact,
    deleteTooltipTitle,
    editTooltipTitle,
    canDelete,
  }: ContactDetailsProps,
): JSX.Element => {
  const ContactComponent = renderContact;
  const editContact = useEditContact();
  const deleteContact = useDeleteContact();

  return (
    <SettingsSection
      title={title}
      description={description}
    >
      <DetailPanel spacing={3} pt={0}>
        {rows.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{contactName}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {[...rows].sort(a => (a.contact.isDefault ? -1 : 0)).map(row => (
                  <TableRow key={row.contact.id}>
                    <TableCell>
                      <ContactComponent row={row} />
                    </TableCell>
                    <TableCell align="right">
                      {row.contact.temporary ? (
                        <>
                          <IconButton disabled>
                            <Edit />
                          </IconButton>
                          <IconButton disabled>
                            <Delete />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <Tooltip title={editTooltipTitle}>
                            <IconButton onClick={() => editContact(row.contact.peopleId, row.contact.id)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          {canDelete(row.contact) ? (
                            <Tooltip title={deleteTooltipTitle}>
                              <IconButton onClick={() => deleteContact(row.contact.peopleId, row.contact.id)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton disabled>
                              <Delete />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box px={3} pt={3}>
            <Alert severity="warning">{noItemsText}</Alert>
          </Box>
        )}
        <Stack direction="row" spacing={3} px={3} height="4em">
          {input}
          <Button
            variant="contained"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={addDisabled}
            onClick={() => onAdd()}
          >
            {addText}
          </Button>
        </Stack>
      </DetailPanel>
    </SettingsSection>
  );
};
