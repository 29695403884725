import React, { ReactNode, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { UseMutationResult } from '@tanstack/react-query';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import { HttpResponseError } from 'helpers/api';
import { ValidateNameResult } from 'helpers/validation/validateName';

interface AddPersonProps {
  open: boolean
  mutation: UseMutationResult<ContactGroup, HttpResponseError, Pick<ContactGroup, 'name'>>
  validateName: (name: string) => ValidateNameResult | undefined
  cancel: () => void
  created: (group: ContactGroup) => void
  title: ReactNode
  ariaLabel: string
}

const AddGroupDialog = ({ open, mutation, created, cancel, title, ariaLabel, validateName }: AddPersonProps) => {
  const t = useTranslations('dialogs.contactGroups.addGroup');
  const [name, setName] = useState('');
  const validation = validateName(name);

  const createNewGroup = (): void => mutation.mutate(
    { name: name.trim() },
    { onSuccess: group => created(group) },
  );

  const onExited = () => {
    setName('');
    mutation.reset();
  };

  const canSave = !mutation.isSuccess && !mutation.isPending && validation?.valid;

  return (
    <Dialog
      open={open && !mutation.isSuccess}
      aria-label={ariaLabel}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('name')}
            value={name}
            onChange={event => setName(event.target.value)}
            error={validation && !validation.valid && !validation.missing}
            helperText={validation && !validation.missing && validation.helperText ? t(`validation.${validation.helperText}`) : ''}
            autoFocus
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Box flex={1}>
          <Collapse in={mutation.isError}>
            <Alert sx={{ mb: 3 }} severity="error">{t('alert.error')}</Alert>
          </Collapse>
          <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={cancel}
              disabled={mutation.isSuccess || mutation.isPending}
            >
              {t('cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={createNewGroup}
              disabled={!canSave}
            >
              {t(mutation.isPending ? 'saving' : 'save')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupDialog;
