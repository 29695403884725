import { InferredEventId } from 'apis/rest/inferredEvents/types';

export const labelToIconType = (label: string) => label.toLowerCase()
  .replace(/(evt)|\s|_/g, '');

export const labelToDisplayLabel = (label?: string | any) => {
  if (typeof label !== 'string'){
    // this is a fix to allow the display of jsx in the omnibox not really ideal
    return label;
  }
  else {
    return label
    .substring(4)
    .replace(/[-_]/g, ' ')
    .toLowerCase()
    .replace(/opsnormal/g, 'Ops Normal')
    .replace(/oooi/g, 'OOOI')
    .replace(/([eE])ngine/g, 'engine ')
    .replace(/\soff/g, ' stop')
    .replace(/\son/g, ' start')
    .replace(/([Pp])oi/g, 'POI')
    .replace(/^(.)|\s+(.)/g, (c: string) => c.toUpperCase());
  }
};

interface ReportLikeWithInferredEvents {
  events: string[];
  inferredEvents?: InferredEventId[] | null;
}

interface ReportEvent {
  eventId: string;
  isInferred: boolean;
}

interface InferredReportEvent {
  eventId: InferredEventId;
  isInferred: boolean;
}

export const isInferredEvent = (event: ReportEvent): event is InferredReportEvent => event.isInferred;

export const hasInferredEvents = (report: ReportLikeWithInferredEvents | undefined) => (report?.inferredEvents?.length ?? 0) > 0;

export const isSignificantInferredEvent = (e: string) => ['INFERRED_TAKEOFF', 'INFERRED_LANDING'].includes(e);
export const isSignificantEvent = (e: string) => !['EVT_STANDARD', 'EVT_INFLIGHT', 'EVT_SCHEDULED', 'EVT_INVGPS'].includes(e);
export const isSignificantReport = (report: ReportLikeWithInferredEvents, enableInferredEvents: boolean) => report.events.some(isSignificantEvent) || (enableInferredEvents && hasInferredEvents(report));

export const isPriorityEvent = (e: string) => ['EVT_MANUAL', 'EVT_OPSNORMAL', 'EVT_POI', 'EVT_ALERT'].includes(e);
export const isPriorityReport = (report: Report) => report.events.some(isPriorityEvent);

export const getSignificantEvents = (report: ReportLikeWithInferredEvents, enableInferredEvents: boolean) => {
  const events = report.events.filter(isSignificantEvent);
  if (enableInferredEvents) {
    return events.concat(report.inferredEvents ?? []);
  }
  return events;
};

export const getMostSignificantEvent = (report: ReportLikeWithInferredEvents, enableInferredEvents: boolean, fallback = 'EVT_STANDARD'): ReportEvent => {
  const firstPriorityEvent = report.events.find(isPriorityEvent);
  if (firstPriorityEvent) {
    return {
      eventId: firstPriorityEvent,
      isInferred: false,
    };
  }

  const firstSignificantEvent = report.events.find(isSignificantEvent);
  if (firstSignificantEvent) {
    return {
      eventId: firstSignificantEvent,
      isInferred: false,
    };
  }

  if (enableInferredEvents && hasInferredEvents(report) && !!report.inferredEvents) {
    const firstSignificantInferredEvent = report.inferredEvents.find(isSignificantInferredEvent);
    if (firstSignificantInferredEvent) {
      return {
        eventId: firstSignificantInferredEvent,
        isInferred: true,
      };
    }
    return {
      eventId: report.inferredEvents[0],
      isInferred: true,
    };
  }

  if (report.events.length > 0) {
    return {
      eventId: report.events[0],
      isInferred: false,
    };
  }

  return {
    eventId: fallback,
    isInferred: false,
  };
};

export const eventDoesntMakeSense = (report: Report, assetCategory: string, deviceMake: string | null) => assetCategory === 'Helicopter'
  && ['EVT_TAKEOFF', 'EVT_LANDING'].includes(report.events[0])
  && report.speed > 30
  && deviceMake?.toLowerCase() !== 'flightcell';
