import React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Rock7ConfigBlock, Rock7ConfigBlockSpec } from 'apis/rest/rock7config/types';

type Value = string | boolean | number | undefined;

interface Rock7ConfigInputProps {
  config: Rock7ConfigBlock;
  options: Rock7ConfigBlockSpec;
  onChange: (key: string, value: Value) => void;
}

interface InputProps<T> {
  configKey: string;
  value: Value;
  // eslint-disable-next-line react/no-unused-prop-types
  options: T;
  onChange: (value: Value) => void;
}

const BooleanInput = ({
  configKey,
  value,
  onChange,
}: InputProps<'boolean'>) => (
  <FormControlLabel
    control={(
      <Checkbox
        checked={!!value}
        onChange={e => onChange(e.target.checked)}
      />
    )}
    label={configKey}
  />
);

const NumberInput = ({
  configKey,
  value,
  options,
  onChange,
}: InputProps<[number, number]>) => {
  const min = Number(options[0]);
  const max = Number(options[1]);

  const isNaN = (v: Value) => Number.isNaN(Number(v)) || v === '';
  const isOutOfBounds = (v: Value) => Number(v) < min || Number(v) > max;
  const clampedValue = (v: Value) => (!isNaN(v) ? Math.max(Math.min(Number(v), max), min) : v);

  const handleOnChange = (val: string) => {
    if (val === '') {
      onChange('');
    } else {
      const newValue = clampedValue(val);
      onChange(newValue);
    }
  };

  return (
    <TextField
      label={configKey}
      type="number"
      value={clampedValue(value)}
      error={value !== '' && (isNaN(value) || isOutOfBounds(value))}
      helperText={`${configKey} must be a number between ${min} and ${max}`}
      onChange={e => handleOnChange(e.target.value)}
    />
  );
};

const SelectInput = ({
  configKey,
  value,
  options,
  onChange,
}: InputProps<string[]>) => (
  <TextField
    label={configKey}
    select
    value={value}
    onChange={e => onChange(e.target.value)}
  >
    <MenuItem value="unset">Not set</MenuItem>
    <ListSubheader>
      <Stack sx={{
        minHeight: 20,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Divider />
      </Stack>
    </ListSubheader>
    {options.map(opt => (
      <MenuItem key={opt} value={opt}>{opt}</MenuItem>
    ))}
  </TextField>
);

export const Rock7ConfigForm = ({
  config,
  options,
  onChange,
}: Rock7ConfigInputProps) => {
  const handleOnChange = (key: string, value: Value) => {
    onChange(key, value);
  };

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" pt={3} gap={3}>
      {Object.entries(options)
        .map(([key, opts]) => {
          if (opts === 'boolean') {
            return (
              <BooleanInput
                key={key}
                configKey={key}
                value={config[key]}
                options={opts}
                onChange={value => handleOnChange(key, value)}
              />
            );
          }

          if (opts.length === 2 && !Number.isNaN(Number(opts[0])) && !Number.isNaN(Number(opts[1]))) {
            return (
              <NumberInput
                key={key}
                configKey={key}
                value={config[key]}
                options={opts as [number, number]}
                onChange={value => handleOnChange(key, value)}
              />
            );
          }

          return (
            <SelectInput
              key={key}
              configKey={key}
              value={config[key]}
              onChange={value => handleOnChange(key, value)}
              options={opts as string[]}
            />
          );
        })}
    </Box>
  );
};
