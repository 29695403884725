import { debounce } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFirestoreOmniboxSettings, updateFirestoreOmniboxSettings } from 'apis/rest/settings/requests';
import { doc, getDoc } from 'firebase/firestore';
import { loggedOut } from '../session/session.slice';
import { di } from '../../di';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';

const firebaseDep = di.depend('firebaseService');

type AssetGroupType = 'latestActivity' | 'make' | 'model' | 'owner' | 'category' | 'watchlistGroup' | 'operatorName';
type AssetSortType = 'name' | 'activity';

export interface OmniboxSettingsState {
  omnibox: {
    assetsGroupBy: AssetGroupType
    assetsSortBy: AssetSortType
    schema: number
  }
  loaded: boolean
}

export interface UpdateOmniboxRequestType<F extends keyof OmniboxSettingsState['omnibox']>{
  field: F
  value: OmniboxSettingsState['omnibox'][F];
}

export const initialOmniboxSettings: OmniboxSettingsState = {
  omnibox: {
    assetsGroupBy: 'latestActivity',
    assetsSortBy: 'name',
    schema: 0
  },
  loaded: false,
};

const debouncedSetDoc = debounce(async (request: OmniboxSettingsState['omnibox']) => updateFirestoreOmniboxSettings(request), 3000, { leading: true, trailing: true });

export const updateOmniboxSettings = createAppAsyncThunk(
  'updateUnitSettingsField',
  async (request: UpdateOmniboxRequestType<keyof OmniboxSettingsState['omnibox']>, thunkAPI) => {
    const state = thunkAPI.getState();
    const payload = { ...state.omniboxSettings.omnibox, [request.field]: request.value };
    debouncedSetDoc(payload);
    return payload;
  }
);

export const getOmniboxSettings = createAppAsyncThunk<OmniboxSettingsState['omnibox'], string>(
  'getOmniboxSettings',
  async (uid: string, { getState }) => {
    const { firestore } = firebaseDep.inject();
    const settingsCategory = doc(firestore, `settings/${uid}/userSettings/omniboxSettings`);
    const localeSettingsDoc = await getDoc(settingsCategory);
    const state = getState();
    if (localeSettingsDoc.exists()) {
      return localeSettingsDoc.data() as OmniboxSettingsState['omnibox'];
    }

    const existingOmniboxSettings = {
      assetsGroupBy: state.app.groupBy.assets as AssetGroupType,
      assetsSortBy: state.app.sortBy.assets as AssetSortType
    } as OmniboxSettingsState['omnibox'];
    await createFirestoreOmniboxSettings(existingOmniboxSettings);

    return existingOmniboxSettings;
  }
);

export const manageOmniboxSettingsSlice = createSlice({
  name: 'omniboxSettings',
  initialState: initialOmniboxSettings,
  reducers: {
    updateLocalOmniboxSettings: (state, action: PayloadAction<OmniboxSettingsState['omnibox']>) => {
      state.omnibox = action.payload;
      state.loaded = true;
    }
  },
  selectors: {
    selectOmniboxSettings: state => state.omnibox,
  },
  extraReducers: builder => {
    builder
      .addCase(loggedOut, () => initialOmniboxSettings)
      .addCase(getOmniboxSettings.fulfilled, (state, payload) => {
        state.omnibox = payload.payload;
        state.loaded = true;
      })
      .addCase(updateOmniboxSettings.fulfilled, (state, payload) => {
        state.omnibox = payload.payload;
      });
  }
});

export const { selectOmniboxSettings } = manageOmniboxSettingsSlice.selectors;
export const { updateLocalOmniboxSettings } = manageOmniboxSettingsSlice.actions;
export default manageOmniboxSettingsSlice.reducer;
