import { useMemo } from 'react';
import { displaySnackbar } from 'slices/app.slice';
import { destroySnackbar } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';

export interface SnackbarSettings {
  id: string
  text: string
  type: 'info' | 'error' | 'warning' | 'success'
  toDelete?: boolean
  persist?: boolean
}

const useSnackbar = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => ({
    display: (settings: SnackbarSettings) => dispatch(displaySnackbar(settings)),
    destroy: (id: string) => dispatch(destroySnackbar({ id })),
  }), [dispatch]);
};

export default useSnackbar;
