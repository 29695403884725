import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import AssetLabel from 'components/shared/assetLabel';
import { useTranslations } from 'use-intl';
import { DropGroup } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useVolume from 'hooks/units/useVolume';
import DateTime from 'components/shared/datetime';
import { DetailsGrid, ScoreCard, OverlayHeader, OverlayWrapper } from './mapCommon';

interface DropsByAssetScorecardProps extends PaperOwnProps {
  assetIds: number[]
  dropGroups: DropGroup[]
  trips: TripBasic[]
  visibleSuppressants: Record<Suppressant, boolean>
  hoveredDrop?: string
}

const HoveredDropGroupOverlay = ({ assetIds, hoveredDrop, dropGroups, visibleSuppressants, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;
  const hoveredDropGroup = useMemo(() => dropGroups.find(d => d.id === hoveredDrop), [hoveredDrop, dropGroups]);
  const hoveredAsset = useMemo(() => assetsQuery.data?.find(a => a.id === hoveredDropGroup?.assetId), [assetsQuery.data, hoveredDropGroup]);

  const distance = useDistance();
  const duration = useDuration();
  const volume = useVolume();

  if (!hoveredDropGroup || !hoveredAsset) {
    return null;
  }

  return (
    <OverlayWrapper highContrast {...props}>
      <OverlayHeader>
        <Typography variant="h3"><AssetLabel asset={hoveredAsset} /></Typography>
      </OverlayHeader>
      <DetailsGrid>
        <ScoreCard label="Suppressant">
          <Typography>{t(`suppressant.${hoveredDropGroup.suppressant}`)}</Typography>
        </ScoreCard>
        <ScoreCard label="Volume">
          <Typography>{volume.create(hoveredDropGroup.volumeDroppedLitres).format()}</Typography>
        </ScoreCard>
        <ScoreCard label="Drop Time" wide>
          <DateTime value={hoveredDropGroup.endTimeMs} />
        </ScoreCard>
        <ScoreCard label="Drop" wide value={hoveredDropGroup.locations.averageDrop.placeName} />
        <ScoreCard label="Fill" wide value={hoveredDropGroup.locations.maxFill?.placeName ?? '—'} />
        {hoveredDropGroup.flight
            && (
            <>
              <ScoreCard label="Flight Distance">
                <Typography>{distance.create(hoveredDropGroup.flight.distanceKm * 1000).format()}</Typography>
              </ScoreCard>
              <ScoreCard label="Flight Duration">
                <Typography>{duration.fromMillis(hoveredDropGroup.flight.durationMs)}</Typography>
              </ScoreCard>
            </>
            )}
      </DetailsGrid>
    </OverlayWrapper>
  );
};

export default HoveredDropGroupOverlay;
