import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import useStyles from './tracplusIcon-styles';

const TracPlusIcon = ({
  overrideStyle
}) => {
  const classes = useStyles();

  return (
    <SvgIcon className={clsx(classes.logo)} style={overrideStyle}>
      <g transform="translate(1846.336 -21.274)">
        <circle className={classes.circle} cx="-1834.44" cy="33.2" r="4.84" />
        <path
          className={classes.curve}
          d="M-1843.98,37.25c-1.26-1.08-2.07-2.59-2.27-4.23c-0.23-1.66,0.02-3.36,0.73-4.88
      c0.7-1.53,1.82-2.83,3.23-3.74c1.38-0.92,3.05-1.29,4.68-1.04l0.07,0.01c0.06,0.01,0.1,0.07,0.09,0.13c0,0,0,0,0,0
      c-0.01,0.04-0.03,0.07-0.07,0.08c-1.26,0.55-2.42,1.31-3.41,2.26c-0.98,0.95-1.78,2.08-2.33,3.33c-0.58,1.24-0.92,2.57-1,3.94
      c-0.07,1.37,0.11,2.75,0.52,4.06c0.02,0.06-0.02,0.13-0.08,0.14c-0.04,0.01-0.08,0-0.11-0.02L-1843.98,37.25z"
        />
        <path
          className={classes.curve}
          d="M-1824.69,29.3c1.26,1.08,2.07,2.59,2.27,4.23c0.23,1.66-0.02,3.36-0.73,4.88
      c-0.7,1.53-1.82,2.83-3.23,3.74c-1.38,0.92-3.05,1.29-4.68,1.04l-0.07-0.01c-0.06-0.01-0.1-0.07-0.09-0.13
      c0.01-0.04,0.03-0.07,0.07-0.08c1.26-0.55,2.42-1.31,3.41-2.26c0.98-0.95,1.78-2.08,2.33-3.33c0.58-1.24,0.92-2.57,1-3.94
      c0.07-1.37-0.11-2.74-0.52-4.06c-0.02-0.06,0.01-0.13,0.07-0.14c0,0,0,0,0,0c0.04-0.01,0.08,0,0.11,0.02L-1824.69,29.3z"
        />
      </g>
    </SvgIcon>
  );
};

export default TracPlusIcon;
