import React from 'react';

const Startup = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M12.46,8c0-0.19-0.09-0.36-0.25-0.47L6.44,3.49c-0.18-0.12-0.41-0.14-0.6-0.04c-0.19,0.1-0.31,0.3-0.31,0.51
v8.08c0,0.22,0.12,0.41,0.31,0.51c0.19,0.1,0.42,0.08,0.6-0.04l5.77-4.04C12.37,8.37,12.46,8.19,12.46,8C12.46,8,12.46,8,12.46,8
C12.46,8,12.46,8,12.46,8z"
    />
  </svg>
);

export default Startup;
