import { GeographicCoordinates } from 'components/pages/manage/markers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MeasurementState = 'disabled' | 'create' | 'append';
export interface MultiPointMeasurementState {
  measurementMarkers?: GeographicCoordinates[]
  assetId?: number
  state: MeasurementState
}

export const initialMultiPointMeasurementState: MultiPointMeasurementState = {
  measurementMarkers: undefined,
  assetId: undefined,
  state: 'disabled'
};

export const multiPointMeasurementSlice = createSlice({
  name: 'multiPointMeasurement',
  initialState: initialMultiPointMeasurementState,
  reducers: {
    setMeasurementMarkers: (state, action: PayloadAction<GeographicCoordinates[]>) => {
      state.measurementMarkers = action.payload;
    },
    appendMeasurementMarker: (state, action: PayloadAction<GeographicCoordinates>) => {
      state.state = 'append';

      if (state.measurementMarkers !== undefined) {
        state.measurementMarkers.push(action.payload);
      } else {
        state.measurementMarkers = [action.payload];
      }
    },
    clearMeasurementMarkers: state => {
      state.measurementMarkers = undefined;
    },
    setMeasurementState: (state, action: PayloadAction<MeasurementState>) => {
      state.state = action.payload;
    },
    toggleMeasurementState: state => {
      if (state.state === 'disabled') {
        state.state = 'create';
      } else {
        state.state = 'disabled';
      }
    },
    updateSelectedAssetId: (state, action: PayloadAction<MultiPointMeasurementState['assetId']>) => {
      // check if asset changed
      if (action.payload !== state.assetId) {
        state.measurementMarkers = undefined;
        state.assetId = action.payload;
        if (state.state === 'append') {
          state.state = 'create';
        }
      }
    }
  },
  selectors: {
    selectMeasurementMarkers: state => state.measurementMarkers,
    selectMeasurementAssetId: state => state.assetId,
    selectMeasurementState: state => state.state,
  }
});

export const { selectMeasurementMarkers, selectMeasurementState } = multiPointMeasurementSlice.selectors;
export const { setMeasurementMarkers, appendMeasurementMarker, clearMeasurementMarkers, setMeasurementState, toggleMeasurementState, updateSelectedAssetId } = multiPointMeasurementSlice.actions;
export default multiPointMeasurementSlice.reducer;