import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TracPlusLabsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 24">
    <path
      d="M3.66659 24C2.53325 24 1.7277 23.4944 1.24992 22.4833C0.772143 21.4722 0.888809 20.5333 1.59992 19.6667L8.99992 10.6667V2.66667H7.66659C7.28881 2.66667 6.97214 2.53889 6.71659 2.28333C6.46103 2.02778 6.33325 1.71111 6.33325 1.33333C6.33325 0.955556 6.46103 0.638889 6.71659 0.383333C6.97214 0.127778 7.28881 0 7.66659 0H18.3333C18.711 0 19.0277 0.127778 19.2833 0.383333C19.5388 0.638889 19.6666 0.955556 19.6666 1.33333C19.6666 1.71111 19.5388 2.02778 19.2833 2.28333C19.0277 2.53889 18.711 2.66667 18.3333 2.66667H16.9999V10.6667L24.3999 19.6667C25.111 20.5333 25.2277 21.4722 24.7499 22.4833C24.2721 23.4944 23.4666 24 22.3333 24H3.66659ZM6.33325 20H19.6666L15.1333 14.6667H10.8666L6.33325 20ZM3.66659 21.3333H22.3333L14.3333 11.6V2.66667H11.6666V11.6L3.66659 21.3333Z"
      fill="#369A22"
    />
  </SvgIcon>
);

export default TracPlusLabsIcon;
