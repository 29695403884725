import { serenityFetch } from 'helpers/api';

export const startSession = async (organisationId: string): Promise<void> => {
  await serenityFetch('POST', `/organisations/${organisationId}/session/start`, {}, true);
};

export const endSession = async (organisationId: string): Promise<void> => {
  await serenityFetch('POST', `/organisations/${organisationId}/session/end`, {}, true);
};

export const pauseSession = async (organisationId: string): Promise<void> => {
  await serenityFetch('POST', `/organisations/${organisationId}/session/pause`, {}, true);
};

export const resumeSession = async (organisationId: string): Promise<void> => {
  await serenityFetch('POST', `/organisations/${organisationId}/session/resume`, {}, true);
};
