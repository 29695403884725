import { useEffect } from 'react';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import useTimezone from 'hooks/session/useTimezone';

export const StaleReportCuller = () => {
  const timezone = useTimezone();
  const reportsRepository = useReportsDataRepository();
  useEffect(() => {
    reportsRepository.configureStartDay(timezone);
  }, [reportsRepository, timezone]);
  return null;
};
