import { LoginResponse, login, loginWithUsercode } from 'apis/auth';
import { createInvitedUser } from 'apis/invite';
import { joinOrganisationOrUpdateRole, leaveOrganisation } from 'apis/rest/memberships/requests';
import { queryClient } from 'queryClient';
import { membershipQueryKeys } from 'apis/rest/memberships/queryKeys';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import {
  JoinOrganisationParams,
  LeaveOrganisationParams,
  LegacyLoginParams,
  LoginParams,
  SignupParams,
  StandardApiConfig
} from './types';
import { pauseSession, resumeSession } from 'apis/rest/session/sessionRequests';
import { setOrganisationId } from './session.slice';

export const loginAction = createAppAsyncThunk<LoginResponse, LoginParams, StandardApiConfig>(
  'session/login',
  async ({ email, password, rememberMe }, { rejectWithValue }) => {
    try {
      const response = await login(email, password, rememberMe);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const legacyLoginAction = createAppAsyncThunk<LoginResponse, LegacyLoginParams, StandardApiConfig>(
  'session/legacyLogin',
  async ({ usercode, password, rememberMe }, { rejectWithValue }) => {
    try {
      const response = await loginWithUsercode(usercode, password, rememberMe);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const signupAction = createAppAsyncThunk<LoginResponse, SignupParams, StandardApiConfig>(
  'session/signup',
  async ({ name, email, password, token }, { rejectWithValue }) => {
    try {
      const response = await createInvitedUser(name, email, password, token);
      return response;
    } catch (error) {
      const value = (error as { code: string }).code;
      return rejectWithValue({ code: value });
    }
  }
);

export const joinOrganisationAction = createAppAsyncThunk<void, JoinOrganisationParams, StandardApiConfig>(
  'session/joinOrganisation',
  async ({ organisationId, userId, membershipRole }, { rejectWithValue }) => {
    try {
      await joinOrganisationOrUpdateRole(organisationId, userId, membershipRole);
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all(organisationId) });
    } catch (error) {
      rejectWithValue({ code: 'joinOrganisationError' });
    }
  }
);

export const leaveOrganisationAction = createAppAsyncThunk<void, LeaveOrganisationParams, StandardApiConfig>(
  'session/leaveOrganisation',
  async ({ organisationId, userId }, { rejectWithValue }) => {
    try {
      await leaveOrganisation(organisationId, userId);
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all(organisationId) });
    } catch (error) {
      rejectWithValue({ code: 'leaveOrganisationError' });
    }
  }
);

export const setOrganisationIdAction = createAppAsyncThunk(
  'session/setOrganisationId',
  async (organisationId: string, { dispatch, getState }) => {
    // Get the current state to retrieve the original organisationId
    const state = getState();
    const originalOrganisationId = state.session.organisationId;

    await pauseSession(originalOrganisationId); // Use the original organisationId

    dispatch(setOrganisationId(organisationId));

    await resumeSession(organisationId);
  }
);
