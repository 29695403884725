export interface AssetCategory<T extends string = string> {
  id: T
  alias?: string[]
  aircraftDescription?: ('LandPlane' | 'SeaPlane' | 'Amphibian' | 'Helicopter' | 'Gyrocopter' | 'Drone')[]
  items?: AssetCategory[]
}

export type GroupId = 'Air' | 'Land' | 'Sea' | 'Person' | 'Object';

export const categories: AssetCategory<GroupId>[] = [
  {
    id: 'Air',
    items: [
      // NOTE: Aircraft should be Aeroplane or Fixed Wing but remains as is to match existing
      { id: 'Aircraft', aircraftDescription: ['LandPlane', 'SeaPlane', 'Amphibian'] },
      { id: 'Helicopter', aircraftDescription: ['Helicopter'] },
      // TODO: add Gyrocopter
      { id: 'Drone' },
      { id: 'Airship' },
      { id: 'Balloon' },
      { id: 'Rocket' },
    ],
  },
  {
    id: 'Land',
    items: [
      { id: 'Truck' },
      { id: 'Fire Truck' },
      { id: 'Car' },
      { id: 'Police Car' },
      { id: 'Ambulance' },
      { id: 'Bicycle' },
      { id: 'Tractor' },
      { id: 'Bus' },
      { id: 'Minibus' },
      { id: 'Motorcycle' },
      { id: 'Motor Home' },
    ],
  },
  {
    id: 'Sea',
    items: [
      { id: 'Powerboat' },
      { id: 'Yacht' },
      { id: 'Container Ship' },
      { id: 'Tug' },
    ],
  },
  {
    id: 'Person',
  },
  {
    id: 'Object',
    items: [
      { id: 'Container' },
      { id: 'Unknown' },
    ],
  },
];
