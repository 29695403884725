import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useState } from 'react';
import { useTranslations } from 'use-intl';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export interface AddNewNotificationGroupProps {
  open: boolean;
  isDisabled: boolean;
  onCancel: () => void;
  onSubmit: (name: string) => void;
  title: string;
  ariaLabel: string;
}

export const AddNewNotificationGroupDialog = ({
  open,
  isDisabled,
  onCancel,
  onSubmit,
  title,
  ariaLabel }: AddNewNotificationGroupProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.create');
  const [name, setName] = useState('');
  const [isNew, setIsNew] = useState<boolean>(true);

  const onExited = useCallback(() => {
    setName('');
    setIsNew(true);
  }, []);

  return (
    <Dialog
      open={open}
      aria-label={ariaLabel}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}>
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('text')}
            value={name}
            onChange={event => { setName(event.target.value); if (isNew) { setIsNew(false); } }}
            error={!isNew && !name}
            helperText={(!isNew && !name) ? t('validation') : undefined}
            disabled={isDisabled}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3,
          borderTop: 1,
          borderColor: 'common.midGrey',
          justifyContent: 'space-between',
        }}>
        <Box flex={1}>
          <Stack
            spacing={3}
            direction="row"
            height="4em"
            justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={onCancel}
              disabled={isDisabled}>
              {t('cancelText')}
            </Button>
            <Button
              size="large"
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={() => onSubmit(name)}
              disabled={!name || isDisabled}>
              {t(isDisabled ? 'savingText' : 'saveText')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
