import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Button, Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useLastDefined from 'hooks/useLastDefined';
import AddPersonDialog from 'components/dialogs/people/addPerson';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import PeopleTable, { PeopleTableComponents } from './peopleTable-view';
import AddContactsDialog from '../../people/addContacts';

export enum AssignPeopleDialogStatus {
  Cancelled = 'cancelled',
  Assigned = 'assigned',
}

export interface AssignPeopleDialogProps {
  open: boolean
  onClose: (status: AssignPeopleDialogStatus, groupId: number) => void
  group: ContactGroup
  mutation: UseMutationResult<void, HttpResponseError, Pick<ContactGroup, 'id' | 'peopleWithOrder' | 'peopleVersion'>>
  validate: (group: ContactGroup, peopleIds: number[]) => boolean | undefined
  renderErrors: (group: ContactGroup, peopleIds: number[], valid: boolean | undefined) => ReactNode
  title: ReactNode
  ariaLabel: string
  components: PeopleTableComponents
  skip?: boolean
}

export const AssignPeopleDialog = ({
  open,
  onClose,
  group,
  mutation,
  validate,
  renderErrors,
  title,
  ariaLabel,
  components,
  skip = false,
}: AssignPeopleDialogProps): JSX.Element => {
  const [addPerson, setAddPerson] = useState(false);
  const [addContactsPerson, setAddContactsPerson] = useState<Person>();
  const [addedPerson, setAddedPerson] = useState<Person>();
  const addedName = useLastDefined(addedPerson?.name, [addedPerson]);
  const t = useTranslations('dialogs.contactGroups.assignPeople');

  const existingPeopleIds = useMemo(
    () => [...group.peopleWithOrder].sort((a, b) => a.order - b.order).map(item => item.personId),
    [group.peopleWithOrder],
  );
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<number[]>([]);

  useEffect(() => setSelectedPeopleIds(existingPeopleIds), [existingPeopleIds]);

  const handleClose = (status: AssignPeopleDialogStatus) => onClose(status, group.id);
  const handleCancel = () => handleClose(AssignPeopleDialogStatus.Cancelled);
  const handleSave = () => {
    const peopleWithOrder = selectedPeopleIds.map((id, index) => ({ personId: id, order: index + 1 }));
    mutation.mutate(
      { ...group, peopleWithOrder },
      { onSuccess: () => handleClose(AssignPeopleDialogStatus.Assigned) },
    );
  };

  const onExited = () => {
    setSelectedPeopleIds(existingPeopleIds);
    mutation.reset();
  };

  const hasChange = existingPeopleIds.join(',') !== selectedPeopleIds.join(',');
  const valid = validate(group, selectedPeopleIds);
  const canSave = hasChange && valid && !mutation.isPending;

  useEffect(() => {
    if (canSave && mutation.isError) mutation.reset();
  }, [mutation, canSave]);

  useEffect(() => {
    setAddedPerson(person => {
      if (person && selectedPeopleIds[selectedPeopleIds.length - 1] !== person.id) return undefined;
      return person;
    });
  }, [setAddedPerson, selectedPeopleIds]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (!mutation.isPending) handleCancel();
        }}
        aria-label={ariaLabel}
        fullWidth
        maxWidth="lg"
        TransitionProps={{ onExited }}
      >
        <TPDialogTitle>{title}</TPDialogTitle>
        <DialogContent sx={{ p: 0, mb: '-1px' }}>
          <PeopleTable
            selectedIds={selectedPeopleIds}
            setSelectedIds={setSelectedPeopleIds}
            disabled={mutation.isPending}
            addContacts={person => setAddContactsPerson(person)}
            components={components}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
          <Stack spacing={3} flex={1}>
            {renderErrors(group, selectedPeopleIds, valid)}
            <Collapse in={!!addedPerson}>
              <Alert severity="success">{t('alert.addPersonSuccess', { name: addedName })}</Alert>
            </Collapse>
            <Stack my={1} spacing={3} direction="row" height="4em" justifyContent="space-between">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                disabled={mutation.isPending}
                onClick={() => setAddPerson(true)}
              >
                {t('addPerson')}
              </Button>
              <Stack direction="row" spacing={3}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ minWidth: '10rem' }}
                  disabled={mutation.isPending}
                  onClick={handleCancel}
                >
                  {t(skip ? 'skip' : 'cancel')}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: '10rem' }}
                  color="primary"
                  disabled={!canSave}
                  onClick={handleSave}
                >
                  {t(mutation.isPending ? 'saving' : 'save')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>

      <AddPersonDialog
        open={addPerson}
        created={person => {
          setAddPerson(false);
          setSelectedPeopleIds(value => [...value, person.id]);
          setAddedPerson(person);
        }}
        cancel={() => setAddPerson(false)}
      />

      <AddContactsDialog
        person={addContactsPerson}
        done={() => setAddContactsPerson(undefined)}
      />
    </>
  );
};
