import React from 'react';
import { Button } from '@mui/material';
import useStyles from './submitButton-styles';

const SubmitButton = ({
  text,
  onSubmit,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.submitButton}
      variant="contained"
      color="primary"
      onClick={onSubmit}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
