import React, { useCallback, useMemo, useState } from 'react';
import { Chip, Stack, TextField, Typography } from '@mui/material';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { useTranslations } from 'use-intl';
import { useMutateNewContact } from 'apis/rest/people/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import useUndeletablePeopleIds from 'hooks/people/useUndeletablePeopleIds';
import useSnackbar from 'hooks/useSnackbar';
import { ContactDetails } from './contactDetails';
import { EditContactProvider, Validator, InputComponent } from './editContactContext';

interface PhoneContactProps {
  person?: Person
  contactType: 'phone' | 'sms'
}

export const PhoneInput: InputComponent = ({ label, value, existingValue, setValue, validation, allowMissing, ...rest }) => {
  const t = useTranslations('pages.manage.person.phone');
  const missingAllowed = allowMissing && existingValue === undefined && validation.missing;

  return (
    <TextField
      variant="outlined"
      value={value.replace(/\s+/g, '')}
      placeholder="+00 000 000"
      onChange={event => {
        let v = event.target.value.trim();
        if (v.length && v[0] !== '+') v = `+${v}`;
        setValue(v);
      }}
      error={!missingAllowed && !validation.valid}
      helperText={missingAllowed ? undefined : validation.helperText}
      label={label ?? t('contactType')}
      fullWidth
      {...rest}
    />
  );
};

export const useValidatePhone = (rows: { value: string }[], contactType: string): Validator => {
  const t = useTranslations('pages.manage.person.phone');

  return useCallback((nextValue: string, value: string | null) => {
    if (!nextValue.trim()) {
      return {
        valid: false,
        missing: true,
        helperText: t('missing'),
      };
    }
    if (!isValidPhoneNumber(nextValue)) {
      return {
        valid: false,
        helperText: t('invalid'),
      };
    }
    const parsedNumber = parsePhoneNumber(nextValue);
    if (value !== null && parsePhoneNumber(value)
      .isEqual(parsedNumber)) {
      return {
        valid: true,
        changed: false,
      };
    }
    if (rows.some(c => parsePhoneNumber(c.value)
      .isEqual(parsedNumber))) {
      return {
        valid: false,
        helperText: t('exists', { type: contactType }),
      };
    }
    return {
      valid: true,
      changed: true
    };
  }, [t, rows, contactType]);
};

const PrivacyPolicyLink = (chunks: React.ReactNode) => <a href="https://tracplus.com/tracplus-privacy-policy" target="_blank" rel="noreferrer">{chunks}</a>;
const TermsAndConditionsLink = (chunks: React.ReactNode) => <a href="https://tracplus.com/terms-and-conditions" target="_blank" rel="noreferrer">{chunks}</a>;

export const PhoneContacts = ({ person, contactType }: PhoneContactProps): JSX.Element => {
  const t = useTranslations('pages.manage.person.phone');

  const snackbar = useSnackbar();
  const [newNumber, setNewNumber] = useState<string>('');
  const addMutation = useMutateNewContact();

  const onAddContact = (): void => {
    if (!person) return;
    addMutation.mutate(
      {
        contactType,
        contactValue: parsePhoneNumber(newNumber).number,
        peopleId: person.id,
        isDefault: false,
      },
      {
        onSuccess: () => {
          snackbar.display({
            id: 'personUpdated',
            text: t('snackbar.addContact.success', {
              value: parsePhoneNumber(newNumber).formatInternational(),
              type: contactType,
            }),
            type: 'success'
          });
        },
        onError: () => {
          snackbar.display({
            id: 'contactUpdatedError',
            text: t('snackbar.addContact.error', { type: contactType }),
            type: 'error'
          });
        },
      },
    );
    setNewNumber('');
  };

  const rows = useMemo(
    () => person?.contacts.filter(c => c.contactType === contactType)
      .map(contact => {
        try {
          return { contact, value: parsePhoneNumber(contact.contactValue).formatInternational() };
        } catch {
          return { contact, value: contact.contactValue };
        }
      }) ?? [],
    [person?.contacts, contactType],
  );

  const validate = useValidatePhone(rows, contactType);

  const undeletablePeopleIds = useUndeletablePeopleIds();
  const canRemovePerson = person && undeletablePeopleIds && !undeletablePeopleIds?.includes(person.id);

  const newValidation = validate(newNumber, null);

  return (
    <EditContactProvider validate={validate} Input={PhoneInput}>
      <ContactDetails
        title={t('title', { type: contactType })}
        description={(
          <Stack spacing={2}>
            <p>{t('description', { type: contactType })}</p>
            {contactType === 'sms' && (
              <p>
                {t.rich('smsDisclaimer', {
                  privacyPolicy: PrivacyPolicyLink,
                  termsAndConditions: TermsAndConditionsLink
                })}
              </p>
            )}
          </Stack>
        )}
        contactName={t('contactType', { type: contactType })}
        rows={rows}
        onAdd={onAddContact}
        addText={
          addMutation.isPending ? <LoadingIcon size={20} /> : t('add')
        }
        noItemsText={t('noContacts', { type: contactType })}
        renderContact={({ row }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{row.value}</Typography>
            {row.contact.isDefault && <Chip label={t('default')} color="primary" />}
          </Stack>
        )}
        input={(
          <PhoneInput
            value={newNumber}
            setValue={setNewNumber}
            validation={newValidation}
            disabled={addMutation.isPending}
            allowMissing
          />
        )}
        addDisabled={!newValidation.valid || addMutation.isPending}
        canDelete={contact => canRemovePerson || !contact.isDefault}
        editTooltipTitle={t('tooltips.edit')}
        deleteTooltipTitle={t('tooltips.delete')}
      />
    </EditContactProvider>
  );
};
