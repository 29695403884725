import React from 'react';
import { useTranslations } from 'use-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { closeLabInfo, selectLabsFeatureKey } from 'slices/labs.slice';
import useLastDefined from 'hooks/useLastDefined';

const LabsInfoDialog = () => {
  const t = useTranslations('labs');
  const dispatch = useAppDispatch();

  const featureKey = useSelector(selectLabsFeatureKey);
  const lastKnownFeatureKey = useLastDefined(featureKey, []);

  const close = () => dispatch(closeLabInfo());

  if (!lastKnownFeatureKey) return null;

  const imageUrl = undefined;

  return (
    <Dialog
      open={featureKey !== undefined}
      onClose={close}
      maxWidth="xs"
    >
      {imageUrl && <img src={imageUrl} alt={t(`features.${lastKnownFeatureKey}.imageAlt`)} />}

      <DialogTitle sx={{ p: 3, pb: 0, mb: 1 }}>{t(`features.${lastKnownFeatureKey}.title`)}</DialogTitle>

      <DialogContent sx={{ px: 3 }}>
        <Typography>{t(`features.${lastKnownFeatureKey}.description`)}</Typography>
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 0, justifyContent: 'stretch' }}>
        <Stack spacing={3} direction="row" height="4em" flex={1}>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={close}
          >
            {t('dialog.continue')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LabsInfoDialog;
