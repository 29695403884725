import React, { ForwardedRef } from 'react';
import { useTranslations } from 'use-intl';

interface TranslationProps<T> {
  render: (t: ReturnType<typeof useTranslations<never>>, ref: ForwardedRef<T>) => React.ReactNode
}

const Translation = React.forwardRef(<T, >({ render }: TranslationProps<T>, ref: ForwardedRef<T>): JSX.Element => {
  const t = useTranslations();
  return <>{render(t, ref)}</>;
});

export default Translation;
