import React from 'react';

const Aircraft = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 15.594748,25.671931 -0.77413,0.872655 -2.800939,-0.436328 c 0,0 -1.036357,-0.820678 -1.238606,-1.196381 -0.20225,-0.375704 -0.435163,-1.364696 0.267426,-1.759384 0.70259,-0.394689 4.025471,-0.802278 4.025471,-0.802278 0,0 -0.465135,-3.220321 -0.577077,-4.841826 -0.01678,-0.243432 -0.01407,-0.731903 -0.01407,-0.731903 l -1.618635,0.02815 -10.5422277,-2.209787 c 0,0 -0.5666134,-2.476718 0.056301,-3.068365 0.1970278,-0.187143 10.2466447,-1.520107 10.2466447,-1.520107 l 1.871986,-0.014074 c 0,0 0.05014,-2.1264184 0.154824,-3.2654181 0.04489,-0.4885082 1.590484,-0.3941012 1.590484,-0.3941012 0,0 1.545584,-0.094407 1.590486,0.3941012 0.104689,1.1389997 0.154825,3.2654181 0.154825,3.2654181 l 1.871984,0.014074 c 0,0 10.04962,1.332964 10.246653,1.520108 0.622913,0.591647 0.05631,3.068365 0.05631,3.068365 l -10.542231,2.209787 -1.618633,-0.02816 c 0,0 0.0027,0.488471 -0.01407,0.731905 -0.111945,1.621504 -0.577077,4.841823 -0.577077,4.841823 0,0 3.32288,0.407592 4.02547,0.80228 0.702589,0.394687 0.469676,1.383681 0.267426,1.759384 -0.20225,0.375703 -1.238606,1.196381 -1.238606,1.196381 l -2.815014,0.436328 -0.77413,-0.872655 -0.633379,1.097856 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Aircraft;
