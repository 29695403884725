import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useTranslations } from 'use-intl';
import { DropSummary } from 'apis/rest/firefighting/types';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useVolume from 'hooks/units/useVolume';
import DateTime from 'components/shared/datetime';
import { DetailsGrid, ScoreCard, OverlayHeader, OverlayWrapper } from './mapCommon';

interface DropsByAssetScorecardProps extends PaperOwnProps {
  data: DropSummary
  hoveredDropClusterId?: string
}

const HoveredDropClusterOverlay = ({ data, hoveredDropClusterId, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;
  const hoveredDropCluster = useMemo(() => data.dropClusters.find(d => d.id === hoveredDropClusterId), [hoveredDropClusterId, data.dropClusters]);
  const assets = useMemo(() => assetsQuery.data?.filter(a => hoveredDropCluster?.assetIds.includes(a.id)) ?? [], [hoveredDropCluster, assetsQuery.data]);
  // const hoveredAsset = useMemo(() => assetsQuery.data?.find(a => a.id === hoveredDropGroup?.assetId), [assetsQuery.data, hoveredDropCluster]);

  const volume = useVolume();
  const assetLabel = useAssetLabel();

  if (!hoveredDropCluster) {
    return null;
  }

  return (
    <OverlayWrapper highContrast {...props}>
      <OverlayHeader>
        <Typography variant="h3">Incident</Typography>
      </OverlayHeader>
      <DetailsGrid>
        <ScoreCard label="Place" wide>
          <Typography>{hoveredDropCluster.placeName}</Typography>
        </ScoreCard>
        <ScoreCard label="Volume">
          <Typography>{volume.create(hoveredDropCluster.volumeDroppedLitres).format()}</Typography>
        </ScoreCard>
        <ScoreCard label="Assets" wide>
          <Typography>{assets.map(a => <p>{assetLabel(a)}</p>)}</Typography>
        </ScoreCard>
      </DetailsGrid>
    </OverlayWrapper>
  );
};

export default HoveredDropClusterOverlay;
