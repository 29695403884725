/* eslint-disable react/destructuring-assignment, react/no-unused-state */
import React from 'react';

import ErrorPage from 'components/pages/error';
import { noticeError } from 'helpers/newRelic';

class GlobalErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  static getDerivedStateFromError(error, info) {
    return { hasError: true, error, info };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && !prevState.hasError) {
      // error transition state
      noticeError(this.state.error, { info: this.state.info, errorBoundary: true });
    }
  }

  render() {
    if (this.state.hasError) return (<ErrorPage />);
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
