// The Airac cycle is a fixed update period for aeronautical publications.
// See https://en.wikipedia.org/wiki/Aeronautical_Information_Publication#AIRAC_effective_dates_.2828-day_cycle.29
// David Graves from SkyVector says (via email):
// > You MUST update this every 28 days, as expired tiles are not stored on the tileservers
// > for long after the next edition goes live. I recommend changing this exactly at 0901Z
// > every 4th thursday, but you can safely change it 2 days early or 14 days late
const getAiracCycle = () => {
  const list = [
    '1701', '1702', '1703', '1704', '1705', '1706', '1707', '1708', '1709', '1710', '1711', '1712', '1713',
    '1801', '1802', '1803', '1804', '1805', '1806', '1807', '1808', '1809', '1810', '1811', '1812', '1813',
    '1901', '1902', '1903', '1904', '1905', '1906', '1907', '1908', '1909', '1910', '1911', '1912', '1913',
    '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014',
    '2101', '2102', '2103', '2104', '2105', '2106', '2107', '2108', '2109', '2110', '2111', '2112', '2113',
    '2201', '2202', '2203', '2204', '2205', '2206', '2207', '2208', '2209', '2210', '2211', '2212', '2213',
    '2301', '2302', '2303', '2304', '2305', '2306', '2307', '2308', '2309', '2310', '2311', '2312', '2313',
    '2401', '2402', '2403', '2404', '2405', '2406', '2407', '2408', '2409', '2410', '2411', '2412', '2413',
    '2501', '2502', '2503', '2504', '2505', '2506', '2507', '2508', '2509', '2510', '2511', '2512', '2513',
    '2601', '2602', '2603', '2604', '2605', '2606', '2607', '2608', '2609', '2610', '2611', '2612', '2613'
  ];
  const today = new Date();
  const seconds = Math.round(today.getTime() / 1000);
  const twentyEightDaysInSeconds = 2419200;
  const magicTimespan = 1483606860;
  const cycleIndex = Math.floor((seconds - magicTimespan) / twentyEightDaysInSeconds);
  return list[cycleIndex];
};
const airacCycle = getAiracCycle();

export default airacCycle;
