export enum MapBaseLayerIds {
  MapboxOutdoors = 'mapbox-outdoors',
  MapboxStreets = 'mapbox-streets',
  MapboxSatellite = 'mapbox-satellite',
  MapboxSatelliteStreets = 'mapbox-satellite-streets',
  MapboxDark = 'mapbox-dark',
  GibsSNPP = 'nasa-gibs-snpp',
  SkyVectorVFR = 'reactmapgl-sv-vfr',
  SkyVectorIFRHi = 'reactmapgl-sv-ifr-hi',
  SkyVectorIFRLo = 'reactmapgl-sv-ifr-lo',
  OpenSeaMap = 'open-seamap',
  Linz = 'linz'
}
