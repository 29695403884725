import { DateTime } from 'luxon';

export interface SearchParams {
  fromUrl: number | null;
  untilUrl: number | null;
  devicesUrl: number[];
}

export const parseSearchParams = (searchParams: URLSearchParams, tz: string): SearchParams => {
  const from = searchParams.get('from') ?? '';
  const until = searchParams.get('until') ?? '';
  const devices = searchParams.getAll('devices');
  let fromMillis = null;
  let toMillis = null;
  try {
    toMillis = until ? DateTime.fromISO(until, { zone: tz }).endOf('day').toMillis() : null;
  } catch (e) {
    toMillis = null;
  }
  try {
    fromMillis = from ? DateTime.fromISO(from, { zone: tz }).startOf('day').toMillis() : null;
    if (fromMillis && toMillis && fromMillis > toMillis) {
      fromMillis = toMillis - 6 * 24 * 60 * 60 * 1000;
    }
  } catch (e) {
    fromMillis = null;
  }

  let parsedFrom = Number.isNaN(fromMillis) ? null : fromMillis;
  let parsedUntil = Number.isNaN(toMillis) ? null : toMillis;

  if (parsedFrom == null && parsedUntil) {
    parsedFrom = parsedUntil - 6 * 24 * 60 * 60 * 1000;
  }

  if (parsedUntil == null && parsedFrom) {
    parsedUntil = parsedFrom + 6 * 24 * 60 * 60 * 1000;
  }

  const parsedDevices = devices?.map(Number.parseFloat).filter(f => !Number.isNaN(f)) ?? [];

  return {
    fromUrl: parsedFrom,
    untilUrl: parsedUntil,
    devicesUrl: parsedDevices,
  };
};
