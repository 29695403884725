import React from 'react';

const Containership = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 29.98942,3.2790254 c -0.345716,0.2460106 -2.810339,2.3078571 -3.193635,3.057008 -1.253062,2.4491049 -0.905147,5.6870596 -0.905147,5.6870596 l -0.204939,44.266843 c 0,0 0.729941,2.376549 1.127164,2.373878 0.985042,-0.0066 4.209064,0.0015 4.209064,0.0015 0,0 3.216211,-0.0043 4.19344,-0.0015 0.397231,0.0011 1.127164,-2.373878 1.127164,-2.373878 L 36.137592,12.023093 c 0,0 0.347915,-3.2379547 -0.905147,-5.6870596 C 34.849149,5.5868825 32.384526,3.525036 32.03881,3.2790254 31.77317,3.0899973 31.014115,3.0740863 31.014115,3.0740863 c 0,0 -0.759055,0.015911 -1.024695,0.2049391 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Containership;
