import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import AppleAppStoreIcon from './appleAppStoreIcon-view';
import GooglePlayIcon from './googlePlayIcon-view';

const APP_STORE_URL = 'https://apps.apple.com/nz/app/tracplus/id307298018';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.tracplus.android';

const UserGuideMessageAndDownloadIcon = ({
  report,
  t,
  classes,
  ignore,
}) => {
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      {report.ios && (
        <Box>
          <Typography className={classes.continueText} mb={1}>{t('downloadTheAppFromAppleAppStore')}</Typography>
          <Box className={classes.storeIconWrapper}>
            <Link to={APP_STORE_URL} target="_blank">
              <AppleAppStoreIcon />
            </Link>
          </Box>
        </Box>
      )}
      {report.android && (
        <Box>
          <Typography className={classes.continueText} mb={1}>{t('downloadTheAppFromGooglePlay')}</Typography>
          <Box className={classes.storeIconWrapper}>
            <Link to={GOOGLE_PLAY_URL} target="_blank">
              <GooglePlayIcon />
            </Link>
          </Box>
        </Box>
      )}
      <Box>
        <Typography className={classes.continueText} mb={3}>{t('continueAnyway')}</Typography>
        <Button
          type="button"
          variant="contained"
          className={classes.continueButton}
          onClick={ignore}
          size="large"
        >
          {t('continueAnywayButtonText')}
        </Button>
      </Box>
    </Stack>
  );
};

export default UserGuideMessageAndDownloadIcon;
