import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import useStyles from './tracplusIconNoBG-styles';

const TracPlusIconNoBG = ({
  overrideClass,
  overrideStyle
}) => {
  const classes = useStyles();

  return (
    <SvgIcon className={clsx(classes.logo, overrideClass)} style={overrideStyle} width="32px" height="32px" viewBox="0 0 32 32">
      <g transform="translate(3.103941,3.0739994)">
        <circle
          r="4.8400002"
          cy="11.926001"
          cx="11.896059"
          className={classes.smallCircle}
        />
        <path
          className={classes.curve}
          d="m 2.356,15.976 c -1.26,-1.08 -2.07,-2.59 -2.27,-4.23 -0.23,-1.66 0.02,-3.36 0.73,-4.88 0.7,-1.53 1.82,-2.83 3.23,-3.74 1.38,-0.92 3.05,-1.29 4.68,-1.04 l 0.07,0.01 c 0.06,0.01 0.1,0.07 0.09,0.13 0,0 0,0 0,0 -0.01,0.04 -0.03,0.07 -0.07,0.08 -1.26,0.55 -2.42,1.31 -3.41,2.26 -0.98,0.95 -1.78,2.08 -2.33,3.33 -0.58,1.24 -0.92,2.57 -1,3.94 -0.07,1.37 0.11,2.75 0.52,4.06 0.02,0.06 -0.02,0.13 -0.08,0.14 -0.04,0.01 -0.08,0 -0.11,-0.02 z"
        />
        <path
          className={classes.curve}
          d="m 21.646,8.026 c 1.26,1.08 2.07,2.59 2.27,4.23 0.23,1.66 -0.02,3.36 -0.73,4.88 -0.7,1.53 -1.82,2.83 -3.23,3.74 -1.38,0.92 -3.05,1.29 -4.68,1.04 l -0.07,-0.01 c -0.06,-0.01 -0.1,-0.07 -0.09,-0.13 0.01,-0.04 0.03,-0.07 0.07,-0.08 1.26,-0.55 2.42,-1.31 3.41,-2.26 0.98,-0.95 1.78,-2.08 2.33,-3.33 0.58,-1.24 0.92,-2.57 1,-3.94 0.07,-1.37 -0.11,-2.74 -0.52,-4.06 -0.02,-0.06 0.01,-0.13 0.07,-0.14 0,0 0,0 0,0 0.04,-0.01 0.08,0 0.11,0.02 z"
        />
      </g>
    </SvgIcon>
  );
};

export default TracPlusIconNoBG;
