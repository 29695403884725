import { useSelector } from 'react-redux';
import mapTemplates from 'mapTemplates';
import { Style } from 'mapbox-gl';
import useLocaleLanguage from "hooks/settings/useLocale";

const useMapTemplate = (mapId = 'default') => {
  const language = useLocaleLanguage();
  const factory = useSelector<ReduxState, (language: string, now: null) => {
    provider: string,
    template: Style | string
  }>(state => mapTemplates[state.map.maps[mapId]?.baseLayerId]);
  return factory(language, null);
};

export default useMapTemplate;
