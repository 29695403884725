import React from 'react';

const Airship = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 36.309624,52.701832 c 0.2273,-0.382444 12.602612,-0.17626 12.602612,-0.17626 V 45.73955 l -6.433501,-5.199679 c 0,0 1.586223,-9.427406 1.674473,-15.775297 C 44.447769,3.5766936 32.431897,3.1726855 32.431897,3.1726855 c 0,0 -12.015872,0.4040081 -11.721311,21.5918885 0.08825,6.347891 1.674473,15.775297 1.674473,15.775297 l -6.433501,5.199679 v 6.786022 c 0,0 12.375312,-0.206184 12.602612,0.17626 1.291877,2.173652 3.877727,4.847158 3.877727,4.847158 0,0 2.58585,-2.673506 3.877727,-4.847158 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Airship;
