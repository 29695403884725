import { useCallback } from 'react';
import { duration } from 'helpers/unitsOfMeasure';
import { Duration } from 'luxon';
import { useFormatter } from 'use-intl';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

const findFormat = (count: number) => {
  if (count > 24 * 60 * 60 * 1000) {
    return "d'd' h'h' m'm' s's'";
  }
  if (count > 60 * 60 * 1000) {
    return "h'h' m'm' s's'";
  }
  if (count > 60 * 1000) {
    return "m'm' s's'";
  }
  return "s's'";
};

const useDuration = () => {
  const unit = useUnitSettings().duration;
  const unitLabel = duration.label(unit);
  const { number: numberFormatter } = useFormatter();

  const create = useCallback(
    (time: Duration, fractionDigits?: number, format?: string) => (
      duration.toUnit(time, unit, {
        format,
        hoursFormatter(d) {
          const seconds = d.as('seconds');
          const hours = numberFormatter(d.as('hour'), { maximumFractionDigits: fractionDigits ?? (seconds < 3600 ? 2 : 1) });
          return `${hours} h`;
        }
      })
    ),
    [unit, numberFormatter],
  );

  const fromMillis = useCallback(
    (count: number | undefined, fractionDigits?: number, format?: string, fallback = '—') => (
      count ? duration.toUnit(Duration.fromMillis(count), unit, {
        format,
        hoursFormatter(d) {
          const seconds = d.as('seconds');
          const hours = numberFormatter(d.as('hour'), { maximumFractionDigits: fractionDigits ?? (seconds < 3600 ? 2 : 1) });
          return `${hours} h`;
        }
      }) : fallback
    ),
    [unit, numberFormatter],
  );

  return { create, fromMillis, findFormat, unit, unitLabel };
};

export default useDuration;
