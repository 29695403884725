import Smart from 'hocs/smart';
import MessagingView from './messaging-view';

export default Smart(
  MessagingView,
  state => ({
    userId: state.session.user.id,
    organisationId: state.session.organisationId,
  }),
  ac => ({
  })
);
