/* eslint-disable react/destructuring-assignment */
import React, {
  ReactElement,
  useState,
  useEffect,
  useRef
} from 'react';

import useStateRef from 'hooks/data/useStateRef';

// Check browser support for passive event listeners
const isPassiveSupported = (): boolean => {
  let passiveSupported = false;

  const testOptions = {
    // Only supporting browsers attempt to read the prop
    get passive(): boolean {
      passiveSupported = true;
      return true;
    }
  };

  try {
    document.addEventListener('testPassive', null, testOptions);
    document.removeEventListener('testPassive', null);
  } catch (e) {
    // ignore
  }
  return passiveSupported;
};

interface InfiniteScrollProps {
  children: ReactElement
  hasMore: boolean | undefined
  loadingPlaceholder: ReactElement
  loadMore: (arg0: number) => void
  isLoading: boolean
  threshold?: number
}

const InfiniteScroll = ({
  children,
  hasMore,
  loadingPlaceholder,
  loadMore,
  isLoading,
  threshold
}: InfiniteScrollProps): ReactElement => {
  const [pagesLoaded, setPagesLoaded] = useState(0);
  // I would like to have useStateRef<LoadingState>('notLoading'); for type checking but not sure how currently
  type LoadingState = 'notLoading' | 'loadingRequested' | 'loadingStarted';
  const [loadingState, setLoadingState, loadingRef] = useStateRef('notLoading');
  const [beforeScrollHeight, setBeforeScrollHeight] = useState(0);
  const [beforeScrollTop, setBeforeScrollTop] = useState(0);
  const options = {
    passive: isPassiveSupported()
  };

  // This feels hacky. But at this point it makes it work
  const hasMoreRef = useRef(hasMore);
  useEffect(() => {
    hasMoreRef.current = hasMore;
  }, [hasMore]);

  const scrollComponent = useRef(null);

  const mousewheelListener = (e: WheelEvent): void => {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1 && !options.passive) {
      e.preventDefault();
    }
  };

  // Actually perform the load
  useEffect(() => {
    if (loadingState === 'loadingRequested' && !isLoading) {
      loadMore(pagesLoaded + 1);
      setPagesLoaded(pageCount => pageCount + 1);
    }
    if (loadingState === 'loadingRequested' && isLoading) {
      setLoadingState('loadingStarted');
    }
    if (loadingState === 'loadingStarted' && !isLoading) {
      const scrollEl = scrollComponent.current.parentNode;
      scrollEl.scrollTop = scrollEl.scrollHeight - beforeScrollHeight + beforeScrollTop;

      setBeforeScrollHeight(scrollEl.scrollHeight);
      setBeforeScrollTop(scrollEl.scrollTop);

      setLoadingState('notLoading'); // loading finished
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      attachScrollListener();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState, isLoading]);

  const scrollListener = (): void => {
    if (!scrollComponent.current) return;
    const scrollEl = scrollComponent.current.parentNode;
    const offset = scrollEl.scrollTop;

    // Here we make sure the element is visible as well as checking the offset
    if (offset < threshold && hasMoreRef.current !== false && loadingRef.current === 'notLoading') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      detachScrollListener();

      setBeforeScrollHeight(scrollEl.scrollHeight);
      setBeforeScrollTop(scrollEl.scrollTop);

      setLoadingState('loadingRequested');
    }
  };

  const attachScrollListener = (): void => {
    const scrollEl = scrollComponent.current.parentNode;

    scrollEl.addEventListener('mousewheel', mousewheelListener, options);
    scrollEl.addEventListener('scroll', scrollListener, options);
    scrollEl.addEventListener('resize', scrollListener, options);
  };

  const detachScrollListener = (): void => {
    if (!scrollComponent.current) return;
    const scrollEl = scrollComponent.current.parentNode;

    scrollEl.removeEventListener('scroll', scrollListener);
    scrollEl.removeEventListener('resize', scrollListener);
  };

  const detachMouseWheelListener = (): void => {
    if (!scrollComponent.current) return;
    const scrollEl = scrollComponent.current?.parentNode;

    scrollEl.removeEventListener('mousewheel', mousewheelListener);
  };

  useEffect(() => {
    attachScrollListener();
    return (): void => {
      detachScrollListener();
      detachMouseWheelListener();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollListener();
  });

  useEffect(() => {
    const scrollEl = scrollComponent.current.parentNode;

    if (beforeScrollHeight !== scrollEl.scrollHeight // height changed
      && Math.abs(scrollEl.scrollHeight - scrollEl.scrollTop - scrollEl.clientHeight) < 100 // and we're at bottom
    ) {
      // keep at bottom
      scrollEl.scrollTop = scrollEl.scrollHeight;

      setBeforeScrollHeight(scrollEl.scrollHeight);
      setBeforeScrollTop(scrollEl.scrollTop);
    }
  });

  return (
    <div ref={scrollComponent}>
      {loadingState !== 'notLoading' && loadingPlaceholder}
      {children}
    </div>
  );
};

InfiniteScroll.defaultProps = {
  threshold: 50
};

export default InfiniteScroll;
