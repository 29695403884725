import React, { createContext, useCallback, useState, ReactNode, useContext } from 'react';
import EditPersonContactDialog, { Validator, InputComponent } from 'components/dialogs/people/editPersonContact';

export type { Validator, InputComponent } from 'components/dialogs/people/editPersonContact';

type Value = (personId: number, contactId: number) => void;

const EditContactContext = createContext<Value>(() => undefined);

interface EditContactProviderProps {
  children: ReactNode
  validate: Validator
  Input: InputComponent
}

export const EditContactProvider = ({ children, validate, Input }: EditContactProviderProps): JSX.Element => {
  const [personId, setPersonId] = useState<number>();
  const [contactId, setContactId] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);

  const editContact = useCallback<Value>((nextPersonId, nextContactId) => {
    setPersonId(nextPersonId);
    setContactId(nextContactId);
    setOpen(true);
  }, [setPersonId, setContactId, setOpen]);

  return (
    <EditContactContext.Provider value={editContact}>
      {children}
      <EditPersonContactDialog
        personId={personId}
        contactId={contactId}
        open={open}
        onClose={() => setOpen(false)}
        validate={validate}
        components={{ Input }}
      />
    </EditContactContext.Provider>
  );
};

export const useEditContact = (): Value => useContext(EditContactContext);

