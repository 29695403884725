import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material';
import { PersonAddDisabled } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { styled } from '@mui/material/styles';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import { UserData } from 'slices/session/types';

const StyledTable = styled(Table)(({ theme }) => `
  & th, & td {
    border-bottom: ${theme.border.default};
  },
  & th {
    font-weight: 500;
  }
`);

const StyledTableRow = styled(TableRow)(({ theme }) => `
  transition: background-color 100ms ease;
  &:hover {
    background-color: ${theme.palette.common.midGrey};
    cursor: pointer;
  }
`);

interface UserOrganisationsProps {
  user: UserData;
  isStaff: boolean;
  organisationId: string;
  onTableRowClick: (orgId: string) => void;
  onDialogOpen: (org: MemberOf) => void;
}

export const UserOrganisationsView = ({ user, isStaff, organisationId, onTableRowClick, onDialogOpen }: UserOrganisationsProps) => {
  const t = useTranslations('pages.accountSettings');

  return (
    <SettingsSection title={t('orgTitle')} description={t('orgDescription')}>
      <DetailPanel pt={0} pb={0} spacing={3}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('orgTableName')}</TableCell>
              <TableCell align={isStaff ? 'left' : 'right'}>{t('orgTableRole')}</TableCell>
              {isStaff && <TableCell align="center">{t('leave')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {user && user.memberOf.toSorted((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(org => (
              <StyledTableRow key={org.id}>
                <TableCell scope="row" onClick={() => onTableRowClick(org.id)}>{org.name}</TableCell>
                <TableCell align={isStaff ? 'left' : 'right'} onClick={() => onTableRowClick(org.id)}>{org.role}</TableCell>
                {isStaff && (
                  <TableCell align="center">
                    {org.id !== organisationId && (
                      <Tooltip title={t('leaveOrg', { orgName: org.name })}>
                        <PersonAddDisabled
                          sx={theme => ({ color: theme.palette.common.actionIcon })}
                          onClick={() => onDialogOpen(org)}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </DetailPanel>
    </SettingsSection>
  );
};
