import React from 'react';
import useStyles from './languageOptionButton-styles';
import { Chip } from '@mui/material';
import clsx from 'clsx';

const LanguageOptionButton = ({ isActive, language, onClick }) => {
  const classes = useStyles();

  const isSelected = isActive ? 'active' : 'inactive';

  return (
    <Chip
      onClick={e => onClick(language)}
      label={language}
      className={clsx(classes.chip, isSelected)}
      clickable
    />
  );
};

export default LanguageOptionButton;
