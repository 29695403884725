import React, { useCallback, useState } from 'react';
import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { Input, stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useAssetLabel } from 'components/shared/assetLabel';
import useDistance from 'hooks/units/useDistance';
import { useFetchTrips } from 'apis/rest/trips/hooks';
import { useFetchEngineUsage } from 'apis/rest/engineUsage/hooks';
import { downloadCSV } from 'utils/download';
import mixpanel from 'mixpanel-browser';
import useDuration from 'hooks/units/useDuration';

export const useDownload = () => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const assetLabel = useAssetLabel();

  const fetchTrips = useFetchTrips();
  const fetchEngineUsage = useFetchEngineUsage();

  const distance = useDistance();
  const duration = useDuration();

  return useCallback(async (
    assets: AssetWithDevice[],
    from: number,
    until: number,
  ) => {
    mixpanel.track('Trip Analysis Download', { type: 'Summary' });
    const assetIds = assets.map(asset => asset.id);
    const [trips, engineUsage] = await Promise.all([
      fetchTrips(assetIds, from, until, 60000),
      fetchEngineUsage(assetIds, from, until, 60000),
    ]);

    const records = assets.reduce<Input>((acc, asset) => {
      const assetTrips = trips.filter(trip => trip.assetId === asset.id);
      const assetEngineUsage = engineUsage.filter(item => item.assetId === asset.id);
      const totalDistance = assetTrips.reduce((sum, trip) => sum + (trip.distance ?? 0), 0);
      const totalDuration = assetTrips.reduce((sum, trip) => sum + (trip.duration ?? 0), 0);
      const totalEngineDuration = assetEngineUsage.reduce((sum, item) => sum + (item.duration ?? 0), 0);

      return [
        ...acc,
        {
          asset,
          assetLabel: assetLabel(asset),
          tripCount: assetTrips.length,
          totalDistance: distance.create(totalDistance * 1000).format(d => +d.unitValue.toFixed(3)),
          totalDuration: duration.fromMillis(totalDuration),
          engineUsageCount: assetEngineUsage.length,
          totalEngineDuration: duration.fromMillis(totalEngineDuration),
        },
      ];
    }, []);

    const csvString = csvStringify(records, {
      header: true,
      columns: [
        {
          key: 'assetLabel',
          header: t('columns.asset')
        },
        {
          key: 'asset.make',
          header: t('columns.make')
        },
        {
          key: 'asset.model',
          header: t('columns.model')
        },
        {
          key: 'tripCount',
          header: t('columns.tripCount')
        },
        {
          key: 'totalDistance',
          header: t('columns.totalDistance', { unit: distance.unitLabel })
        },
        {
          key: 'totalDuration',
          header: t('columns.totalDuration')
        },
        {
          key: 'engineUsageCount',
          header: t('columns.engineUsageCount')
        },
        {
          key: 'totalEngineDuration',
          header: t('columns.totalEngineDuration')
        },
      ],
    });

    let exportFilename = `${t('filename.summary')} ${assets.map(assetLabel).join(', ')} `;
    const startDate = DateTime.fromMillis(from).toISODate();
    const endDate = DateTime.fromMillis(until).toISODate();
    if (startDate === endDate) {
      exportFilename += startDate;
    } else {
      exportFilename += t('filename.dateRange', { from: startDate, until: endDate });
    }

    downloadCSV(exportFilename, csvString);
  }, [fetchTrips, fetchEngineUsage, assetLabel, t, distance]);
};

interface DownloadSummaryMenuItemProps {
  assets: AssetWithDevice[]
  from: number | undefined
  until: number | undefined
  close: () => void
  disabled?: boolean
}

const DownloadSummaryMenuItem = ({ assets, from, until, close, disabled: disabledProp = false }: DownloadSummaryMenuItemProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.download');
  const [isLoading, setIsLoading] = useState(false);
  const download = useDownload();

  const disabled = disabledProp || !assets.length || !from || !until || isLoading;

  const onClick = async () => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await download(assets, from, until);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" minWidth="12rem">
        <Typography>{t('button.summary')}</Typography>
        {isLoading && <CircularProgress size="1.5rem" />}
      </Stack>
    </MenuItem>
  );
};

export default DownloadSummaryMenuItem;
