import React from 'react';

interface HorizontalStripsProps {
  color: string;
}

export const HorizontalStrips = ({ color }: HorizontalStripsProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 94" width="100" height="94">
    <defs>
      <clipPath id="clip-path">
        <path id="SVGID" className="cls-1" d="M0 0h100v100H0z" />
      </clipPath>
      <pattern id="_19-2_diagonal" width="100" height="94" patternUnits="userSpaceOnUse">
        <path fill="none" d="M0 0h100v100H0z" />
        <g opacity=".8" clipPath="url(#clip-path)">
          <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="3px"
            d="M100-53.4-53-53.4M100-45.6-53-45.6M100-37.7-53-37.7M100-29.9-53-29.9M100-22-53-22M100-14.1-53-14.1M100-6.3-53-6.3M100 1.6-53 1.6M100 9.4-53 9.4M100 17.3-53 17.3M100 25.1-53 25.1M100 33-53 33M100 40.9-53 40.9M100 48.7-53 48.7M100 56.6-53 56.6M100 64.4-53 64.4M100 72.3-53 72.3M100 80.1-53 80.1M100 88-53 88"
          />
        </g>
      </pattern>
    </defs>
    <g id="Layer_2">
      <path fill="url(#_19-2_diagonal)" d="M0 0h100v100H0z" id="Layer_1-2" />
    </g>
  </svg>
);
