import React from 'react';
import TracPlusIconNoBG from 'components/shared/icons/tracplusIconNoBG';
import {
  Tooltip
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './staffIndicator-styles';

const StaffIndicator = ({
  user,
  overrideStyle
}) => {
  const classes = useStyles();
  const t = useTranslation('shared.staffIndicator');
  return user?.isStaff ? (
    <Tooltip title={t('staffmember')} placement="bottom" arrow>
      <span className={classes.staffIndicator}>
        <TracPlusIconNoBG overrideClass={classes.logo} overrideStyle={overrideStyle} />
      </span>
    </Tooltip>
  ) : null;
};

export default StaffIndicator;
