import React, { useMemo } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import Distance from 'components/shared/distance';
import { sumBy } from 'lodash';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';

// @ts-ignore
import useDuration from 'hooks/units/useDuration';
import useTimezone from 'hooks/session/useTimezone';
import { useSelector } from 'react-redux';
import { getSelectedDay } from 'slices/app.slice';
import useStyles from './legsTotals-styles';

export interface LegTotalsProps {
  activityGroups: ActivityGroup[],
  loading: boolean
}

const LegsTotals = ({
  loading,
  activityGroups,
}: LegTotalsProps): JSX.Element => {
  const timezone = useTimezone();
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.activity');
  const duration = useDuration();

  const totalDistance = useMemo(() => sumBy(activityGroups, a => a.length), [activityGroups]);

  const selectedDay = useSelector(getSelectedDay);
  const endOfDay = useMemo(() => (
    selectedDay ? moment.tz(selectedDay, timezone).endOf('d').unix() : undefined
  ), [selectedDay, timezone]);

  const totalTime = useMemo(() => duration.fromMillis(sumBy(activityGroups, legActivity => {
    if (!legActivity.leg?.start) {
      return 0;
    }

    if (endOfDay) {
      if (!legActivity.leg?.end || legActivity.leg.end > endOfDay) {
        return (endOfDay - legActivity.leg.start) * 1000;
      }
    }

    if (!legActivity.leg?.end) {
      return ((legActivity.reports.at(0)?.received || legActivity.leg.start) - legActivity.leg.start) * 1000;
    }

    return (legActivity.leg.end - legActivity.leg.start) * 1000;
  })), [activityGroups, duration, endOfDay]);

  return (
    <Stack direction="row" spacing={2} px={2}>
      <Box flex={1} textAlign="left">
        <Typography className={classes.label}>{t('totalDistance')}</Typography>
        {loading
          ? <Skeleton />
          : <Distance distanceInMetres={totalDistance} />}
      </Box>
      <Box flex={1} textAlign="right">
        <Typography className={classes.label}>{t('totalTime')}</Typography>
        {loading
          ? <Skeleton />
          : <Typography>{totalTime}</Typography>}
      </Box>
    </Stack>
  );
};

export default LegsTotals;
