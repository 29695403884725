import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  resetFiltersButton: {
    position: 'absolute',
    top: '-56px',
    right: '16px',
  },
  select: {
    position: 'absolute',
    padding: '5px 5px 5px 15px',
    borderRadius: '4px',
    marginLeft: '20px',
    marginTop: '20px',
    boxShadow: 'none',
    border: theme.border.default,
    zIndex: 1,
    minWidth: '204px',
    '& :focus': {
      backgroundColor: 'inherit'
    }
  },
  tableContainer: {
    padding: 0,
    position: 'relative'
  },
  panel: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    border: theme.border.default
  },
  link: {
    textDecoration: 'none'
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  actionButton: {
    color: theme.palette.common.text
  },
  fab: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 12px',
    '&:hover': {
      backgroundColor: theme.palette.button.primaryHover
    }
  },
  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& .MuiTableRow-root  input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& .MuiPaper-root': {
      marginTop: 0
    },
    '& .MuiToolbar-regular': {
      padding: theme.spacing(10 / 3)
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  toolbar: {
    '&.MuiToolbar-root': {
      margin: 0,
      padding: 0,
      minHeight: 0,
    },
  },
}));
