import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import LatLon from 'geodesy/latlon-spherical';
import clsx from 'clsx';
import moment from 'utils/moment';
import { useTranslations } from 'use-intl';
import DateTime from 'components/shared/datetime';
import Distance from 'components/shared/distance';
import Speed from 'components/shared/speed';
import AssetLabel from 'components/shared/assetLabel';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { useLatestPosition } from 'repositories/reports/hooks';
import { useCursor } from 'contexts/cursor/useCursor';
import useStyles from './measurementPanel-styles';

const MeasurementPanel = ({
  measurementMarker,
  selectedAssets,
  mapId,
  selected,
  selectedItemId,
  highContrastControls
}) => {
  const classes = useStyles();
  const t = useTranslations('pages.map.measurementPanel');

  const [cursor] = useCursor();

  const latestReportForCurrentAsset = useLatestPosition(selectedItemId);

  // Create a clock and "tick" it every 1000ms
  const [time, updateTime] = useState(moment());
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  });

  const cursorLat = (cursor?.latitude || 0);
  let cursorLng = (cursor?.longitude || 0);
  // When rendering in triplicate, lng can be outside [-180, 180] so adjust here.
  if (cursorLng > 180) cursorLng -= 360;
  else if (cursorLng < -180) cursorLng += 360;

  const TIME_FORMAT = 'HH:mm:ss';

  const panelData = {
    cursor: {
      key: 'cursormeasurepanel',
      title: t('toCursor'),
      position: selected ? new LatLon(cursorLat, cursorLng) : null,
      distance: 0,
      formattedEta: '--:--:--',
      ete: 0,
      formattedEte: '--:--:--',
      visible: selected
    },
    marker: {
      key: 'markermeasurepanel',
      title: t('toMarker'),
      position: measurementMarker ? new LatLon(measurementMarker.lat, measurementMarker.lng) : null,
      distance: 0,
      formattedDistance: 0,
      formattedEta: '--:--:--',
      ete: 0,
      formattedEte: '--:--:--',
      formattedSpeed: 0,
      visible: !!measurementMarker
    }
  };

  const selectedAsset = selectedAssets[mapId];
  const speedUnit = useSpeedByAsset(selectedAsset);

  if (!selectedAsset) return null;

  // Selected Asset details
  const asset = latestReportForCurrentAsset;
  if (!asset) return null;

  const assetPos = new LatLon(asset.latitude, asset.longitude);

  Object.keys(panelData).forEach(key => {
    const measurement = panelData[key];
    // if the gps location is 0,0 the cursor is probably off screen
    if (measurement.position && measurement.position.latitude !== 0.0 && measurement.position.longitude !== 0.0) {
      // work out the distance to position of cursor or marker
      measurement.distance = assetPos.distanceTo(measurement.position); // this is in meters

      // Now we have distance, we can calculate Ete in seconds (converting speed from km/h to m/s)
      measurement.ete = asset.speed > 0.0 ? measurement.distance / (asset.speed / 3.6) : 0.0;

      if (measurement.ete > 0) {
        const ete = moment.duration(measurement.ete, 'seconds');
        measurement.formattedEte = moment.utc(ete.asMilliseconds()).format('HH:mm:ss');
        // add Ete data to our clock to calculate Eta time
        measurement.formattedEta = moment(time).add(ete);
      }
    }
  });

  return (
    <Box
      className={clsx(classes.measurementPanel, { [classes.highContrastBackground]: highContrastControls })}
      sx={{ borderRadius: 1 }}
    >
      {Object.values(panelData).filter(x => x.visible).map(measurement => (
        <React.Fragment key={measurement.key}>
          <Box className={classes.measurementPanelHeader}>
            <Typography><AssetLabel asset={selectedAsset} /> {measurement.title}:</Typography>
          </Box>
          <Box className={classes.measurementPanelBody}>
            <Box className={classes.measurementBox} key={measurement.key}>
              <Grid className={classes.gridContainer} container spacing={0}>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('distance')}:`}
                  </Typography>
                  <Distance distanceInMetres={measurement.distance} />
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('speed')}:`}
                  </Typography>
                  <Speed unit={speedUnit} speedInKmh={asset.speed} />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('ETE')}:`}
                  </Typography>
                  <Typography noWrap>{measurement.formattedEte}</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6}>
                  <Typography>
                    {`${t('ETA')}:`}
                  </Typography>
                  { measurement.ete > 0
                    ? (
                      <Typography>
                        <DateTime
                          value={measurement.formattedEta}
                          format={TIME_FORMAT}
                          Component="span"
                        />
                      </Typography>
                    ) : <Typography noWrap>{measurement.formattedEta}</Typography>}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MeasurementPanel;
