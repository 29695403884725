import React from 'react';

const Switch = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M9.5,5.5h-3C5.1,5.5,4,6.6,4,8s1.1,2.5,2.5,2.5h3c1.4,0,2.5-1.1,2.5-2.5S10.9,5.5,9.5,5.5z M6.5,9.5
C5.65,9.5,5,8.85,5,8s0.65-1.5,1.5-1.5S8,7.15,8,8S7.35,9.5,6.5,9.5z"
    />
  </svg>
);

export default Switch;
