import React from 'react';
import { DiagonalStripsLeft } from './DiagonalStripsLeft';
import { DiagonalStripsRight } from './DiagonalStripsRight';
import { HorizontalStrips } from './HorizontalStrips';

export type PatternType = 'DiagonalStripsLeft' | 'DiagonalStripsRight' | 'HorizontalStrips';

interface PatternProps {
  type: PatternType;
  color: string;
}

export const Pattern = ({ type, color }: PatternProps) => {
  switch (type) {
    case 'DiagonalStripsLeft':
      return <DiagonalStripsLeft color={color} />;
    case 'DiagonalStripsRight':
      return <DiagonalStripsRight color={color} />;
    case 'HorizontalStrips':
      return <HorizontalStrips color={color} />;
    default:
      throw new Error('unsupported pattern');
  }
};
