import { useEffect, useMemo } from 'react';
import { useSetViewport, useViewport } from 'contexts/viewport/useViewport';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';

interface CustomSource {
  minzoom: number,
  maxzoom: number
}

export const useManageMapWithTemplate = (mapId: string, template: MapTemplate) => {
  const viewport = useViewport(mapId);
  const patchViewport = useSetViewport(mapId);

  const [minZoom, maxZoom] = useMemo(() => {
    if (typeof template.template !== 'string' && template.template.sources) {
      const sources = Object.values(template.template.sources) as CustomSource[];
      return [Math.min(...sources.map(s => s.minzoom)), Math.max(...sources.map(s => s.maxzoom))];
    }
    return [undefined, template.maxzoom];
  }, [template]);

  useEffect(() => {
    if (minZoom && maxZoom) {
      const newZoom = viewport.zoom > maxZoom
        ? maxZoom
        : (viewport.zoom < minZoom
          ? minZoom
          : viewport.zoom);
      if (newZoom !== viewport.zoom) {
        patchViewport({ zoom: newZoom });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minZoom, maxZoom, patchViewport]);
  return [minZoom, maxZoom];
};
