import React from 'react';

export interface IconProps {
  colors: {
    fill: string,
    outline: string,
    stroke: string,
    detail: string,
  },
  outlineOpacity: number,
  size: {
    width: number,
    height: number,
  }
}

const MapPin = ({
  colors,
  outlineOpacity,
  size
}: IconProps) => (
  <svg
    viewBox="0 0 64 64"
    height={`${size.height}px`}
    width={`${size.width}px`}
    xmlns="http://www.w3.org/2000/svg"
    strokeOpacity={outlineOpacity}>
    <g
      id="Outline"
      strokeWidth={5}
      stroke={colors.outline}
      fill={colors.outline}
      strokeLinecap="round">
      <circle cx="32" cy="18.38" r="10.76" />
      <line x1="32" y1="52.2" x2="32" y2="29.14" />
      <path d="M35.66,48.87a3.93,3.93,0,0,1,2.24,3.3c0,2.32-2.64,4.21-5.9,4.21s-5.9-1.89-5.9-4.21a3.89,3.89,0,0,1,2.19-3.27" />
    </g>
    <g
      id="Icon"
      strokeWidth="2"
      stroke={colors.stroke}
      fill={colors.fill}
      strokeLinecap="round">
      <circle cx="32" cy="18.38" r="10.76" />
      <line x1="32" y1="52.2" x2="32" y2="29.14" />
      <path d="M35.66,48.87a3.93,3.93,0,0,1,2.24,3.3c0,2.32-2.64,4.21-5.9,4.21s-5.9-1.89-5.9-4.21a3.89,3.89,0,0,1,2.19-3.27" fill={colors.stroke} />
    </g>
    <g id="Detail" strokeLinecap="round">
      <path
        d="M32.54,12.17a6.12,6.12,0,0,1,5.66,4.67"
        strokeWidth="4"
        stroke={colors.stroke} />
      <path
        d="M32.54,12.17a6.12,6.12,0,0,1,5.66,4.67"
        strokeWidth="2"
        stroke={colors.detail} />
    </g>
  </svg>
);

export default MapPin;
