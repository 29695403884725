import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';

interface IridiumLbsDialogProps {
    open: boolean;
    onClose: () => void;
    imei: string;
    assetName: string;
}

function uint16(n: number) {
  return n & 0xFFFF;
}

function calcHash1(imei: string) {
  let hash = 0;

  for (let i = 0; i < imei.length; i += 1) {
    hash ^= uint16(uint16(hash << 5) + uint16(hash >>> 2) + imei.charCodeAt(i));
  }

  return (`00000${hash}`).slice(-5);
}

function calcHash2(imei: string) {
  let hash = parseInt(calcHash1(imei), 10);

  for (let i = imei.length; i > 0; i -= 1) {
    hash ^= uint16(uint16(hash << 5) + uint16(hash >>> 2) + imei.charCodeAt(i - 1));
  }

  return (`00000${hash}`).slice(-5);
}

function validateIridiumForm(imei: string, code: string) {
  if (!/^\d{15}$/.test(imei)) return 'A 15-digit numerical IMEI is required.';
  if (!/^\d{5}$/.test(code)) return 'A 5-digit numerical code is required.';
  if (calcHash1(imei) !== code) return 'Validation Error.  Please double-check your IMEI and Code match those on your Iridium Extreme.';
  return null;
}

export const IridiumLbsDialog = ({
  open,
  onClose,
  imei,
  assetName
}: IridiumLbsDialogProps) => {
  const [hash1, setHash1] = useState<string>('');

  const error = useMemo(() => validateIridiumForm(imei, hash1), [imei, hash1]);
  const hash2 = useMemo(() => (error == null ? calcHash2(imei) : null), [imei, error]);

  const onEnter = useCallback(() => {
    setHash1('');
  }, [setHash1]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" TransitionProps={{ onEnter }}>
      <TPDialogTitle id="form-dialog-title">Get code for activating {assetName}</TPDialogTitle>
      <DialogContent>
        <TextField
          value={hash1}
          onChange={e => setHash1(e.target.value)}
          fullWidth
          sx={{ py: 3 }} />
        {error
          ? <Alert severity="error">{error}</Alert>
          : <p>Code: {hash2}</p>}
      </DialogContent>
      <TPDialogActions>
        <Button autoFocus onClick={onClose}>
          Exit
        </Button>
      </TPDialogActions>
    </Dialog>
  );
};
