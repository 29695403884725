import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { Marker } from 'apis/rest/markers/types';
import { useLatestPosition } from 'repositories/reports/hooks';
import { calculateDistance } from 'helpers/unitsOfMeasure';
import Distance from 'components/shared/distance';
import Coordinate from 'components/shared/coordinate';
import { DateTime, Duration } from 'luxon';
import turfBearing from '@turf/bearing';
import useTimezone from 'hooks/session/useTimezone';
import { DetailsGrid, OverlayHeader, OverlayWrapper, ScoreCard } from './common';
import { AnnotationPath, AnnotationPoint } from 'slices/map/annotations.slice';

interface HoveredAnnotationOverlayProps {
  point?: AnnotationPoint,
  path?: AnnotationPath,
  selectedAsset?: AssetBasic
  highContrast: boolean
}

export const HoveredAnnotationOverlay = ({ point, path, selectedAsset, highContrast }: HoveredAnnotationOverlayProps) => {
  const t = useTranslations('pages.map.annotations.hoverOverlay');
  const timezone = useTimezone();

  const latestPosition = useLatestPosition(selectedAsset?.id);
  const [distance, ete, bearing] = useMemo(() => {
    if (!point || !latestPosition) {
      return [undefined, undefined, undefined];
    }
    const distanceToMarker = calculateDistance({ latitude: point.location.latitude, longitude: point.location.longitude }, latestPosition, 'metres');
    if (distanceToMarker === '--') {
      return [undefined, undefined, undefined];
    }
    const estimatedTime = latestPosition.speed > 0 ? distanceToMarker / (latestPosition.speed / 3.6) : null;
    const southBearing = turfBearing([point.location.longitude ?? 0, point.location.latitude ?? 0], [latestPosition.longitude, latestPosition.latitude]);
    return [distanceToMarker, estimatedTime, 180 + southBearing];
  }, [point, latestPosition]);

  const [time, updateTime] = useState(DateTime.now().setZone(timezone));
  const eta = useMemo(() => {
    if (!ete) {
      return undefined;
    }
    return (time.plus(Duration.fromObject({ seconds: ete }))).toFormat('HH:mm:ss');
  }, [ete, time]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime(DateTime.now().setZone(timezone));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  if (point) {
    return (<OverlayWrapper highContrast={highContrast}>
      <DetailsGrid>
        <ScoreCard label={`${t('title')} - ${t('type.point')}`} wide>
          <Typography variant="h3">{point.name}</Typography>
        </ScoreCard>
        <ScoreCard label={t('headers.description')} value={point.description} />
      </DetailsGrid>
      <DetailsGrid>
        <ScoreCard label={t('headers.coordinates')} wide>
          <Coordinate latitude={point.location.latitude} longitude={point.location.longitude} />
        </ScoreCard>
        <ScoreCard label={t('headers.type')} value={point.type} />
      </DetailsGrid>
      {latestPosition && selectedAsset && (
        <>
          <OverlayHeader withTopBorder mb={-2}>
            <Typography variant="h5">{t('assetToMarker', { asset: selectedAsset.name, marker: point.name })}</Typography>
          </OverlayHeader>
          <DetailsGrid withoutTopBorder>
            <ScoreCard label={t('headers.distance')}>
              <Distance distanceInMetres={distance ?? 0} />
            </ScoreCard>
            <ScoreCard label={t('headers.bearing')} value={`${bearing?.toFixed(0)} °T`} />
            <ScoreCard label={t('headers.ete')} value={ete ? Duration.fromObject({ seconds: ete }).toFormat('hh:mm:ss') : '--:--:--'} />
            <ScoreCard label={t('headers.eta')} value={eta} />
          </DetailsGrid>
        </>
      )}
    </OverlayWrapper>);
  } else if (path) {
    return (
      <OverlayWrapper highContrast={highContrast}>
        <DetailsGrid>
          <ScoreCard label={`${t('title')} - ${t('type.path')}`} wide>
            <Typography variant="h3">{path.name}</Typography>
          </ScoreCard>
          <ScoreCard label={t('headers.description')} value={path.description} />
        </DetailsGrid>
      </OverlayWrapper>);
  }
  return null;
};
