import React from 'react';
import DetailPanel from 'components/shared/DetailPanel';
import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import {
  ContactGroupsAssetAssignmentTable,
  ContactGroupsAssetAssignmentTableComponents,
} from 'components/pages/manage/contactGroups/common/contactGroupsAssetAssignmentTable';
import AssignAssetsDialog from 'components/dialogs/iceContactGroups/assignAssets';

const components: ContactGroupsAssetAssignmentTableComponents = {
  AssignAssetsDialog,
};

const IceAssignAssetsTab = (): JSX.Element => {
  const { query: groupsQuery } = useGetIceContactGroups();

  return (
    <DetailPanel pb={0} mb={8}>
      <ContactGroupsAssetAssignmentTable
        settingsCategory="iceAssetsTable"
        groups={groupsQuery.data}
        isLoading={groupsQuery.isLoading}
        components={components}
      />
    </DetailPanel>
  );
};

export default IceAssignAssetsTab;
