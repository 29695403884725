/* eslint-disable no-underscore-dangle */
import React, {
  useMemo, useCallback, useEffect, useRef
} from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import moment from 'utils/moment';
import { useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import useTimezone from 'hooks/session/useTimezone';
import { useSelector } from 'react-redux';
import { getSelectedDay, getSelectedItem, selectItem } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import QueryResult from '../queryResult';
import useStyles from '../results-styles';
import {
  unassignItemFromMap,
  hideAssetsGroup,
  removeFromHiddenAssetGroups,
  hideAssetsOnMap,
  showAssetsOnMap,
} from 'slices/map.slice';
import { Moment } from 'moment';

interface GroupedQueryResultsProps {
  results: AssetBasic[]
  groupBy: string
  sortBy: string
  selectAsset: (asset: AssetBasic) => void
}

interface BucketHeader {
  type: 'header'
  bucket: string
  visibilityIcon: React.ReactNode
  height: number
}

interface BucketResult {
  type: 'assetResult',
  result: AssetBasic
  isHidden: boolean
  isSelected: boolean
  height: number
}

const GroupedQueryResults = ({
  results,
  groupBy,
  sortBy,
  selectAsset
}: GroupedQueryResultsProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('omnibox.modules.groupQueryResults');
  const t2 = useTranslations('omnibox.modules.results');
  const listRef = useRef<List | null>();
  const dispatch = useAppDispatch();

  const latestPositionsByAsset = useLatestPositionsForAssets(results);
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const hiddenInactiveAssets = useSelector<ReduxState, ReduxState['map']['hiddenInactiveAssets']>(state => state.map.hiddenInactiveAssets);
  const hiddenAssets = useSelector<ReduxState, ReduxState['map']['hiddenAssets']>(state => state.map.hiddenAssets);
  const hiddenAssetGroups = useSelector<ReduxState, ReduxState['map']['hiddenAssetGroups']>(state => state.map.hiddenAssetGroups);
  const selectedItem = useSelector(getSelectedItem);
  const activeQuery = useSelector<ReduxState, ReduxState['app']['query']>(state => state.app.query);

  const getBucketNameForLatestActivity = useCallback((latestActivity: Moment) => {
    const now = selectedDay ? moment.tz(selectedDay, timezone) : moment();
    if (latestActivity.isAfter(now.clone().subtract(15, 'minutes'))) return t('timeBuckets.fifteenMinutes');
    if (latestActivity.isAfter(now.clone().subtract(1, 'hour'))) return t('timeBuckets.hour');
    if (latestActivity.isAfter(now.clone().subtract(1, 'day'))) return t('timeBuckets.day');
    if (latestActivity.isAfter(now.clone().subtract(1, 'week'))) return t('timeBuckets.week');
    if (latestActivity.isAfter(now.clone().subtract(1, 'month'))) return t('timeBuckets.month');
    return t('timeBuckets.older');
  }, [selectedDay, timezone, t]);

  const assetLabel = useAssetLabel();

  // group into buckets
  const buckets: Record<string, AssetBasic[]> = useMemo(() => {
    const sortedResults = [...results]
      .sort((assetA, assetB) => {
        switch (sortBy) {
          case 'activity':
            return (latestPositionsByAsset[assetB.id]?.received || 0) - (latestPositionsByAsset[assetA.id]?.received || 0);
          case 'name':
          default:
            return assetLabel(assetA, '').localeCompare(assetLabel(assetB, ''));
        }
      });

    return sortedResults.reduce((acc, r) => {
      let field = r[groupBy as keyof AssetBasic];
      let time;
      switch (groupBy) {
        case 'category':
          field = r.category;
          break;
        case 'owner':
          field = r.ownerName;
          break;
        case 'latestActivity':
          time = latestPositionsByAsset[r.id]?.received;
          field = time
            ? getBucketNameForLatestActivity(moment.unix(time))
            : t('timeBuckets.never');
          break;
        case 'assetGroup':
          if (assetGroupsQuery.isLoading) {
            field = t('loading');
          } else {
            const filteredAssetGroups = assetGroupsQuery.data?.filter(ag => ag.assets.some((x: AssetBasic) => x.id === r.id)) ?? [];

            filteredAssetGroups.forEach(ag => {
              acc[ag.name] = acc[ag.name] || [];
              if (!acc[ag.name].includes(r)) {
                acc[ag.name].push(r);
              }
            });

            if (filteredAssetGroups.length > 0) {
              field = 'InAssetGroup';
            }
          }
          break;
        default:
          break;
      }

      if (field && groupBy !== 'assetGroup') {
        acc[field as string] = acc[field as string] || [];
        acc[field as string].push(r);
      }
      if (!field || field.toString().trim() === '') {
        const unknownBucket = t('other');
        acc[unknownBucket] = acc[unknownBucket] || [];
        acc[unknownBucket].push(r);
      }

      return acc;
    }, {} as Record<string, AssetBasic[]>);
  }, [latestPositionsByAsset, getBucketNameForLatestActivity, groupBy, sortBy, results, t, assetLabel, assetGroupsQuery]);

  const sortedBuckets = useMemo(() => {
    const timeBucketOrder = ['fifteenMinutes', 'hour', 'day', 'week', 'month', 'older', 'never'];
    if (groupBy === 'latestActivity') {
      return timeBucketOrder.map(bucket => t(`timeBuckets.${bucket}`));
    }

    if (groupBy === 'assetGroup') {
      return [
        ...Object.keys(buckets)
          .sort()
          .filter(x => x !== t('other')),
        t('other')
      ];
    }

    return Object.keys(buckets).sort();
  }, [groupBy, buckets, t]);

  const toggleVisibility = useCallback((e: React.MouseEvent, groupName: string): void => {
    e.stopPropagation();

    const assetsToBeToggledOnMap = buckets[groupName].filter(a => !hiddenInactiveAssets.find(asset => asset.id === a.id));

    if (!hiddenAssetGroups.includes(groupName) && !buckets[groupName].every(asset => !!hiddenAssets.find(a => a.id === asset.id))) {
      const otherVisibleEntries = Object.entries(buckets)
        .filter(([bucketName]) => bucketName !== groupName && !hiddenAssetGroups.includes(bucketName))
        .flatMap(x => x[1])
        .map(x => x.id);
      const assetsToBeHiddenOnMap = assetsToBeToggledOnMap.filter(x => !otherVisibleEntries.includes(x.id));

      dispatch(hideAssetsGroup(groupName));
      dispatch(hideAssetsOnMap(assetsToBeHiddenOnMap));
    } else {
      dispatch(removeFromHiddenAssetGroups(groupName));
      dispatch(showAssetsOnMap(assetsToBeToggledOnMap));
    }
  }, [buckets, hiddenAssetGroups, hiddenInactiveAssets, hiddenAssets, dispatch]);

  const displayVisibilityIcon = useCallback((groupName: string): JSX.Element | null => {
    if (!buckets[groupName]) return null;

    if (groupBy !== 'assetGroup' && buckets[groupName].every(asset => hiddenInactiveAssets.find(asset => asset.id === asset.id))) {
      return <VisibilityOff className={classes.visibilityIconDisabled} />;
    }

    if (hiddenAssetGroups.includes(groupName) || buckets[groupName].every(asset => hiddenAssets.find(a => a.id === asset.id))) {
      if (!!selectedItem && hiddenAssets.find(a => a.id === selectedItem.id)) {
        dispatch(unassignItemFromMap());
      }
      return (
        <Tooltip title={t('showOnMap')}>
          <Visibility onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('hideOnMap')}>
        <VisibilityOff onClick={e => toggleVisibility(e, groupName)} className={classes.visibilityIcon} />
      </Tooltip>
    );
  }, [buckets, hiddenAssetGroups, t, hiddenInactiveAssets, selectedItem, hiddenAssets, dispatch, toggleVisibility, groupBy, classes]);

  const getBucketResults = useCallback((bucket: string): AssetBasic[] => buckets[bucket] ?? [], [buckets]);

  const theme = useTheme();

  const bucketList = useMemo(
    () => sortedBuckets.map<(BucketHeader | BucketResult)[]>(bucket => {
      const bucketResults = getBucketResults(bucket);
      if (!bucketResults.length) return [];
      const visibleItems = bucketResults.map<BucketResult>(result => ({
        type: 'assetResult',
        result,
        isHidden: hiddenAssetGroups.includes(bucket) || !!hiddenAssets.find(ha => ha.id === result.id),
        isSelected: !!selectedItem && selectedItem.id === result.id,
        height: 57
      })).filter(({ isHidden }) => !isHidden);

      if (visibleItems.length) visibleItems[visibleItems.length - 1].height += theme.spacingNumber(3);

      return [
        {
          type: 'header',
          bucket,
          visibilityIcon: displayVisibilityIcon(bucket),
          height: theme.spacingNumber(visibleItems.length ? 4 : 7)
        },
        ...visibleItems
      ];
    }).reduce((acc, curr) => acc.concat(...curr), []),
    [sortedBuckets, displayVisibilityIcon, getBucketResults, hiddenAssets, selectedItem, theme, groupBy, hiddenAssetGroups]
  );

  const generateListElement = useCallback(({ index, key, style }: ListRowProps): JSX.Element | null => {
    const bucketElement = bucketList[index];
    if (!bucketElement) { return null; }

    if (bucketElement.type === 'header') {
      return (
        <div key={key} style={style} className={classes.groupHeaderRow}>
          <Typography className={classes.groupHeaderTitle}>{groupBy === 'status' ? t2(bucketElement.bucket) : bucketElement.bucket}</Typography>
          {activeQuery?.name === 'Assets' && bucketElement.visibilityIcon}
        </div>
      );
    }

    return <div key={key} style={style} className={classes.assetRow}><QueryResult selectAsset={selectAsset} {...bucketElement} /></div>;
  }, [activeQuery?.name, bucketList, classes.groupHeaderRow, classes.groupHeaderTitle, classes.assetRow, groupBy, t2, selectAsset]);

  const getHeight = useCallback((item: { index: number }) => (bucketList[item.index]?.height), [bucketList]);

  const mediaQuery = {
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  useEffect(() => {
    listRef.current?.recomputeRowHeights();
  }, [mediaQuery.md, mediaQuery.lg, mediaQuery.xl, bucketList]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={listRef}
          rowCount={bucketList.length}
          overscanRowCount={10}
          height={height}
          rowHeight={getHeight}
          rowRenderer={generateListElement}
          width={width}
        />
      )}
    </AutoSizer>
  );
};

export default GroupedQueryResults;
