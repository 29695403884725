import { useMemo } from 'react';
import { BitmapLayer } from 'deck.gl';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import windSpeedImageUrl from './wind/wind-speed-0.25.png';

const useWindVelocityLayers = (
  visible: boolean,
) => useMemo(() => [
  new BitmapLayer({
    id: 'wind-velocity-bitmap-layer',
    bounds: [-180, -90, 180, 90],
    image: windSpeedImageUrl,
    _imageCoordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    opacity: 0.3,
    parameters: { depthTest: false },
    visible,
  }),
], [visible]);

export default useWindVelocityLayers;
