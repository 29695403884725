import React from 'react';

const CourseChange = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M9.35,3.44L8.56,4.23l2.27,2.27H7.65c-1.87,0-3.4,1.53-3.4,3.4v1.7h1.13V9.9c0-1.25,1.02-2.27,2.27-2.27h3.17
L8.56,9.9l0.79,0.79l3.23-3.23c0.23-0.23,0.23-0.57,0-0.79L9.35,3.44z"
    />
  </svg>
);

export default CourseChange;
