import React from 'react';

const Winch = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <polygon
      className="icon-detail"
      fill="#fff"
      points="11.63,6.64 11.63,5.73 7.09,5.28 7.09,4.37 5.73,4.37 5.73,5.28 4.37,5.73 4.37,6.64 5.73,6.64
5.73,10.72 4.37,10.72 4.37,11.63 8.45,11.63 8.45,10.72 7.09,10.72 7.09,6.64"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.72,8.46V7.09h-0.45v1.36H9.81c-0.25,0-0.45,0.2-0.45,0.45v0.9c0,0.25,0.2,0.45,0.45,0.45h1.36
c0.25,0,0.45-0.2,0.45-0.45v-0.9c0-0.25-0.2-0.45-0.45-0.45H10.72z"
    />
  </svg>
);

export default Winch;
