import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  tabIcon: {
    width: '1.2em',
    height: '1.2em',
    marginRight: theme.spacing(1.5),
  },
  missionReportsIcon: {
    width: '1.5em',
    height: '1.5em'
  },
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  Tabs: {
    '& .MuiTab-root': {
      fontSize: '1.3rem',
      fontWeight: '500',
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      minHeight: '55px',
      flexDirection: 'row',
      minWidth: 160
    },
    '& .MuiTab-iconWrapper': {
      marginBottom: 0,
      alignSelf: 'center'
    },
    '& .MuiTab-wrapper': {
      display: 'inline'
    }
  },
  contentWrapper: {
    textAlign: 'left'
  },
  buttonGroup: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3)
  },
  button: {
    borderColor: theme.border.color,
    textTransform: 'capitalize',
    fontWeight: '500',
    padding: theme.spacing(2, 5),
    transition: 'all 0.15s',
    '&:hover, &:focus': {
      textShadow: '0 0 0.01px rgba(0, 0, 0, 0.87)',
      backgroundColor: theme.palette.common.white,
      borderColor: theme.border.color
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main
    }
  },

  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      padding: theme.spacing(9 / 5),
    },
    '& .MuiInputBase-adornedStart.MuiInputBase-adornedEnd input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& > .MuiPaper-root': {
      boxShadow: 'none',
      border: theme.border.default
    },
    '& .MuiToolbar-regular': {
      padding: theme.spacing(10 / 3)
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiIconButton-root': {
      color: theme.palette.common.text
    }
  },
  container: {
    display: 'inline-block',
    marginTop: theme.spacing(1)
  },
  formControl: {
    display: 'block',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.common.white,
      padding: '0 4px 0 0',
    },
  },
  goButton: {
    fontSize: '1rem',
    padding: theme.spacing(4 / 3, 3),
    borderRadius: theme.spacing(4)
  },
  downloadAsButton: {
    height: '3rem',
    marginLeft: theme.spacing(4 / 3),
    padding: theme.spacing(0, 7 / 3, 0, 5 / 3),
    borderRadius: theme.spacing(4)
  }
}));
