import './di-bindings';

import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntercomProvider } from 'react-use-intercom';
import ErrorBoundary from 'components/shared/errorBoundary';
import { router } from 'router';
import { Provider } from 'react-redux';
import { useInjectedDependency } from './di';

import 'react-virtualized/styles.css';
import './index.css';

// NOTE: we polyfill for some unsupported browser versions only where practical and with no further workarounds in source code
import arrayAt from 'array.prototype.at';
import stringAt from 'string.prototype.at';
import arrayToSorted from 'array.prototype.tosorted';

import { queryClient } from './queryClient';

// Chrome/Edge <92, Firefox <90, Safari <15.4
arrayAt.shim();
stringAt.shim();
arrayToSorted.shim();

const INTERCOM_APP_ID = 'u9wrr5iy';

window.newrelic?.addRelease('TracPlus Cloud', import.meta.env.PACKAGE_VERSION);

Modal.setAppElement('#root');

const App = () => {
  const store = useInjectedDependency('reduxStore');
  const persistor = useInjectedDependency('reduxStorePersistor');

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary organisationId={null}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <RouterProvider router={router} />
            </PersistGate>
          </Provider>
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </IntercomProvider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
