import React, { ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { Alert, Box, Collapse, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { Item } from './types';
import ScrollList from '../scrollList';
import { UnselectedItem } from './UnselectedItem';
import { SelectedItem } from './SelectedItem';

export interface MultiSelectProps<T> {
  items: Item<T>[];
  preselected?: Item<T>[];
  unselectedLabel: ReactNode;
  selectedLabel: ReactNode;
  isReadOnly?: boolean;
  maxItems?: number;
  onChange: (items: Item<T>[]) => void;
  renderItemElement: (items: Item<T>) => ReactNode;
  filterOperation: (item: Item<T>, filterText: string) => boolean;
}

export const MultiSelect = <T extends object>({
  items,
  preselected,
  unselectedLabel,
  selectedLabel,
  isReadOnly,
  maxItems,
  onChange,
  renderItemElement,
  filterOperation,
}: MultiSelectProps<T>): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<Item<T>[]>(preselected ?? []);
  const [filterText, setFilterText] = useState<string | null>('');
  const t = useTranslations('components.MultiSelect');

  const handleRemove = (item: Item<T>) => {
    const newItems = selectedItems.filter(m => m.id !== item.id);
    setSelectedItems(newItems);
    onChange(newItems);
  };

  const handleAdd = (itemId: number) => {
    const itemToAdd = items?.find(m => m.id === itemId);
    if (itemToAdd) {
      const newItems = [...selectedItems, itemToAdd];
      setSelectedItems(newItems);
      onChange(newItems);
    }
  };

  const allFilteredItems = useMemo(() => items
    .filter(m => !selectedItems.find(s => s.id === m.id))
    .filter(m => filterOperation(m, filterText ?? '')), [items, selectedItems, filterOperation, filterText]);

  const filteredItems = useMemo(
    () => allFilteredItems.slice(0, allFilteredItems.length > (maxItems ?? 50) ? (maxItems ?? 50) : allFilteredItems.length),
    [allFilteredItems, maxItems]
  );

  const getAlertMessage = (): JSX.Element => {
    if (items.length !== selectedItems.length) {
      return <Alert severity="info">{t('noFiltered')}</Alert>;
    }
    return (<Alert severity="warning" sx={{ width: 1 }}>{t('noUnselected')}</Alert>);
  };

  return (
    <Box mx={3} mt={3}>
      <Grid direction="row" container spacing={3}>
        {!isReadOnly && (
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem" flexWrap="wrap">
              <Typography variant="h5">{unselectedLabel} ({filteredItems.length !== allFilteredItems.length ? `${filteredItems.length} of ${allFilteredItems.length}` : filteredItems.length.toString()})</Typography>
              <TextField
                type="search"
                size="small"
                value={filterText}
                onChange={event => setFilterText(event.target.value)}
                InputProps={{
                  placeholder: 'Search',
                  endAdornment: filterText && (
                    <IconButton size="small" onClick={() => setFilterText('')}>
                      <Clear sx={{ color: 'common.text' }} />
                    </IconButton>
                  ),
                  sx: { pr: 1, width: '15rem' },
                  'aria-label': 'Search',
                }}
              />
            </Stack>
            <Stack
              flex={1}
              borderTop={1}
              borderColor="common.midGrey"
            >
              <ScrollList
                ids={filteredItems.map(m => m.id)}
                renderItem={id => (
                  <UnselectedItem
                    addItemText="Add"
                    isReadOnly={isReadOnly}
                    onAddClick={() => { if (!isReadOnly) { handleAdd(id); } }}
                  >
                    {renderItemElement(filteredItems.find(m => m.id === id)!)}
                  </UnselectedItem>
                )}
                height="30rem"
                empty={(
                  <Collapse>
                    <Box mt={2}>
                      {getAlertMessage()}
                    </Box>
                  </Collapse>
                )}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={isReadOnly ? 12 : 6}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem">
            <Typography variant="h5">{selectedLabel} ({selectedItems.length})</Typography>
          </Stack>
          <Box
            flex={1}
            borderTop={1}
            borderColor="common.midGrey"
          >
            <ScrollList
              ids={selectedItems.map(m => m.id)}
              renderItem={id => (
                <SelectedItem
                  removeText="Remove"
                  isReadOnly={isReadOnly}
                  onRemoveClick={() => { if (!isReadOnly) { handleRemove(selectedItems.find(m => m.id === id)!); } }}
                >
                  {renderItemElement(selectedItems.find(m => m.id === id)!)}
                </SelectedItem>
              )}
              height="30rem"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
