import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import clsx from 'clsx';
import { sortBy } from 'lodash';
import { useTranslations } from 'use-intl';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Map from 'components/map';
import { GRIDSIZES, MAPLAYOUTS } from 'constants/maplayouts';
import mapTemplates from 'mapTemplates';
import mixpanel from 'mixpanel-browser';
import { useAppDispatch } from 'store/types';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { getSelectedDay, selectItem } from 'slices/app.slice';
import { selectMap, updateMapLayout } from 'slices/map.slice';
import { useAllKMLs } from '../map/modules/settingsDrawer/modules/kmlLoader/useKmlManager';
import useStyles from './mapContainer-styles';

const MapContainer = () => {
  const language = useLocaleLanguage();
  const classes = useStyles();
  const t = useTranslations('pages.map.mapLayoutSpeeddial');
  const [kmls, refetchKMLs] = useAllKMLs();
  const dispatch = useAppDispatch();
  const maps = useSelector((state: ReduxState) => state.map.maps);
  const mapLayout = useSelector((state: ReduxState) => state.map.selectedMapLayout);
  const selectedMapId = useSelector((state: ReduxState) => state.map.selectedMapId);
  const selectedAssets = useSelector((state: ReduxState) => state.map.selectedAssets);
  const defaultMapSettings = useSelector((state: ReduxState) => state.map.maps.default);
  const highContrastControls = useSelector((state: ReduxState) => state.settings.map.highContrastControls);

  // Handle SpeedDial open and close
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const setMapLayout = (value: ReduxState['map']['selectedMapLayout']) => {
    if (value) {
      mixpanel.track('Split Map', {
        mapSplitSetting: value
      });
      dispatch(updateMapLayout({ layout: value, defaultMapSettings }));
    }
    setOpen(true);
  };

  // the map select is tied to mousedown, which appears to be a lot faster than onclick
  const handleMouseDown = (mapId: string) => {
    if (mapId === selectedMapId) return;
    dispatch(selectMap(mapId));
    // selecting a map should also reselect the previously selected asset in the results view,
    const previousSelection = selectedAssets[mapId];
    if (previousSelection) dispatch(selectItem(previousSelection));
  };

  const gridTitles = [
    t('singleMap'),
    t('twoMapsHorizontal'),
    t('twoMapsVertical'),
    t('threeMapsA'),
    t('threeMapsB'),
    t('fourMaps')
  ];
  const gridSVGIcons = [
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <rect width="16" height="16" rx="1" ry="1" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M0,6V1c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,7,0,6.55,0,6z" />
      <path d="M0,15v-5c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,16,0,15.55,0,15z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,16H1c-0.55,0-1-0.45-1-1V1c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v14C7,15.55,6.55,16,6,16z" />
      <path d="M15,16h-5c-0.55,0-1-0.45-1-1V1c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v14C16,15.55,15.55,16,15,16z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
      <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
      <path d="M0,15v-5c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,16,0,15.55,0,15z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
      <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
      <path d="M0,6V1c0-0.55,0.45-1,1-1h14c0.55,0,1,0.45,1,1v5c0,0.55-0.45,1-1,1H1C0.45,7,0,6.55,0,6z" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
      <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
      <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
      <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
    </svg>
  ];

  const day = useSelector(getSelectedDay);

  const highContrastClass = highContrastControls ? 'highContrastClass' : null;

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={0}>
        {sortBy(Object.values(maps), x => x.sortOrder).map((config, index) => {
          const selected = (config.id === selectedMapId);

          if (GRIDSIZES[mapLayout][index] > 0) {
            return (
              <Map
                key={`map${config.id}`}
                index={index}
                xs={GRIDSIZES[mapLayout][index]}
                template={mapTemplates[config.baseLayerId](language, day)}
                selected={selected}
                config={config}
                kmlFilenames={kmls}
                refetchKmls={refetchKMLs}
                onMouseDown={() => handleMouseDown(config.id)}
                selectedItemForThisMap={selectedAssets[config.id]}
              />
            );
          }
          return null;
        })}
      </Grid>
      <SpeedDial
        ariaLabel={t('title')}
        className={clsx(classes.speedDial, { highContrastClass })}
        hidden={false}
        icon={(
          <Tooltip title={t('title')} placement="left">
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
              <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
              <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
              <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
              <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
            </svg>
          </Tooltip>
        )}
        open={open}
        direction="up"
      >
        {MAPLAYOUTS.map((layout, index) => (
          <SpeedDialAction
            className={clsx(classes.speedDialAction, { highContrastClass, speedDialActionActive: layout === mapLayout })}
            key={`layoutbutton${layout}`}
            icon={gridSVGIcons[index]}
            tooltipTitle={gridTitles[index]}
            onClick={() => setMapLayout(layout as ReduxState['map']['selectedMapLayout'])}
          />
        ))}
      </SpeedDial>
      <button id="splitMapButton" className={clsx(classes.mapLayoutButtonWrapper, { highContrastClass })} onClick={toggleOpen} type="button">
        <Tooltip title={t('title')} placement="left">
          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
            <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z" />
            <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z" />
            <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z" />
            <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z" />
          </svg>
        </Tooltip>
      </button>
    </Box>
  );
};

export default MapContainer;
