import React, { ReactNode } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import LabsContextProvider from './labs-context-provider';

const LabsFeatureFlagContextProvider = ({ children, featureFlagKey, reverse = false }: { children: ReactNode | undefined, featureFlagKey: string, reverse?: boolean }) => {
  const flag = useFeatureFlag(featureFlagKey);
  const enabled = flag !== undefined && (reverse ? !flag : flag);

  return (
    <LabsContextProvider loading={flag === undefined} enabled={enabled}>
      {children}
    </LabsContextProvider>
  );
};

export default LabsFeatureFlagContextProvider;
