import React, { useMemo, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Collapse,
  TextField,
} from '@mui/material';
import { ArrowForward, Clear, Close } from '@mui/icons-material';
import { alpha, useTheme } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { uniqBy } from 'lodash';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useSerialType from 'hooks/settings/useSerialType';
import AssetColourMarker from 'components/shared/assetColourMarker';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import ScrollList from 'components/shared/scrollList';
import AssetLabel, { useAssetLabel } from 'components/shared/assetLabel';

interface Item {
  device: DeviceBasic
  asset: AssetWithDevice
  selected: boolean
}

const ItemLabel = ({ device, asset }: { device: DeviceBasic, asset: AssetWithDevice }): JSX.Element => {
  const serialType = useSerialType();

  return (
    <Stack direction="row" flex={1} alignItems="center">
      <Stack width="60%" direction="row" spacing={2} flex={1} alignItems="center">
        <AssetColourMarker assetId={asset.id} />
        <Stack>
          <Typography><AssetLabel asset={asset} /></Typography>
          <Typography variant="body3">{asset.make} {asset.model} {asset.variant}</Typography>
        </Stack>
      </Stack>
      <Stack width="40%">
        <Typography>{device.make} {device.model}</Typography>
        <Typography>{device[serialType]}</Typography>
      </Stack>
    </Stack>
  );
};

const SelectedItem = ({ device, asset, remove }: {
  device: DeviceBasic
  asset: AssetWithDevice
  remove: (device: DeviceBasic) => void
}) => {
  const theme = useTheme();
  const t = useTranslations('dialogs.contactGroups.assignAssetsSingleGroup');

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderBottom={1}
      mt="-1px"
      borderColor="common.midGrey"
      bgcolor="common.white"
      p={2}
      alignItems="center"
      sx={{ ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
    >
      <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
        <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
          <ItemLabel device={device} asset={asset} />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Tooltip title={t('tooltips.removeAsset')}>
          <IconButton onClick={() => remove(device)}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const UnselectedItem = ({ device, asset, add }: {
  device: DeviceBasic
  asset: AssetWithDevice
  add: (device: DeviceBasic) => void
}) => {
  const theme = useTheme();
  const t = useTranslations('dialogs.contactGroups.assignAssetsSingleGroup');

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderBottom={1}
      mt="-1px"
      borderColor="common.midGrey"
      p={2}
      alignItems="center"
      role="button"
      sx={{ cursor: 'pointer', ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
      onClick={() => add(device)}
    >
      <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
        <ItemLabel device={device} asset={asset} />
      </Stack>
      <Tooltip title={t('tooltips.addAsset')}>
        <IconButton>
          <ArrowForward />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const selectDevicesById = (devices: DeviceBasic[]) => devices.reduce<Record<number, DeviceBasic>>((acc, device) => {
  acc[device.id] = device;
  return acc;
}, {});

const NoAssets: Record<number, AssetWithDevice> = {};
const NoDevices: Record<number, DeviceBasic> = {};

interface AssetsTableProps {
  selectedItems: { deviceId: number, assetId: number }[]
  setSelectedItems: Dispatch<SetStateAction<{ deviceId: number, assetId: number }[] | undefined>>
  disabled: boolean
}

const AssetsTable = ({ selectedItems, setSelectedItems, disabled }: AssetsTableProps): JSX.Element => {
  const theme = useTheme();
  const t = useTranslations('dialogs.contactGroups.assignAssetsSingleGroup');
  const serialType = useSerialType();
  const assetLabel = useAssetLabel();

  const organisationId = useOrganisationId();
  const selectOwnAssetsByDeviceId = useCallback((assets: AssetBasic[]) => assets.reduce<Record<number, AssetWithDevice>>((acc, asset) => {
    if (asset.ownerId.toLowerCase() === organisationId.toLowerCase() && typeof asset.deviceId === 'number') {
      acc[asset.deviceId] = asset as AssetWithDevice;
    }
    return acc;
  }, {}), [organisationId]);

  const assetsQuery = useGetAssetsList({ select: selectOwnAssetsByDeviceId }).query;
  const assetsByDeviceId = assetsQuery.data ?? NoAssets;

  const devicesQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesQuery.data ?? NoDevices;

  const items = useMemo<Item[]>(() => {
    const selectedDeviceIds = new Set(selectedItems.map(x => x.deviceId));
    return Object.values(devicesById).map(device => ({
      device,
      asset: assetsByDeviceId[device.id],
      selected: selectedDeviceIds.has(device.id),
    })).filter(item => !!item.asset);
  }, [devicesById, assetsByDeviceId, selectedItems]);

  const removeDevice = useCallback((device: DeviceBasic) => {
    setSelectedItems((value = selectedItems) => value.filter(item => item.deviceId !== device.id));
  }, [setSelectedItems, selectedItems]);

  const addDevice = useCallback((device: DeviceBasic) => {
    setSelectedItems((value = selectedItems) => uniqBy([...value, { deviceId: device.id, assetId: device.assetId }], 'deviceId'));
  }, [setSelectedItems, selectedItems]);

  const [filterText, setFilterText] = useState<string>('');

  const filteredRows = useMemo(() => {
    const value = filterText.trim().toLowerCase();

    const baseFilter = (item: Item) => !item.selected;

    const filter = value ? (item: Item) => baseFilter(item) && [
      assetLabel(item.asset),
      [item.asset.make, item.asset.model, item.asset.variant].filter(s => s).join(' '),
      [item.device.make, item.device.model].filter(s => s).join(' '),
      item.device[serialType],
    ].some(s => (s ?? '').toLowerCase().includes(value)) : baseFilter;

    const filtered = items.filter(filter);
    return [...filtered].sort((a, b) => assetLabel(a.asset, '').localeCompare(assetLabel(b.asset, '')));
  }, [items, filterText, serialType, assetLabel]);

  if (assetsQuery.isLoading) {
    return (
      <Box p={3}>
        <Typography>{t('assetsLoading')}</Typography>
      </Box>
    );
  }

  const selectedRows = items.filter(item => item.selected);

  return (
    <Box mx={3} mt={3}>
      <Grid direction="row" container spacing={3}>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem">
            <Typography variant="h5">{t('otherAssets')} ({filteredRows.length}):</Typography>
            <TextField
              type="search"
              size="small"
              value={filterText}
              onChange={event => setFilterText(event.target.value)}
              InputProps={{
                placeholder: t('searchAssetsPlaceholder'),
                endAdornment: filterText && (
                  <IconButton size="small" onClick={() => setFilterText('')}>
                    <Clear sx={{ color: theme.palette.common.text }} />
                  </IconButton>
                ),
                sx: { pr: 1, width: '15rem' },
                'aria-label': t('searchAssets'),
              }}
            />
          </Stack>
          <Stack
            flex={1}
            borderTop={1}
            borderColor="common.midGrey"
          >
            <ScrollList
              ids={filteredRows.map(row => row.device.id)}
              renderItem={(id, index) => (
                <UnselectedItem
                  asset={filteredRows[index].asset}
                  device={filteredRows[index].device}
                  add={addDevice}
                />
              )}
              height="30rem"
              empty={(
                <Collapse>
                  <Box mt={2}>
                    {items.length !== selectedRows.length ? <Alert severity="info">{t('noFilteredAssets')}</Alert> : <Alert severity="warning">{t('noUnselectedAssets')}</Alert>}
                  </Box>
                </Collapse>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} height="3rem">
            <Typography variant="h5">{t('selectedAssets')} ({selectedRows.length}):</Typography>
          </Stack>
          <Box
            flex={1}
            borderTop={1}
            borderColor="common.midGrey"
          >
            <ScrollList
              ids={selectedRows.map(row => row.device.id)}
              renderItem={(id, index) => (
                <SelectedItem
                  asset={selectedRows[index].asset}
                  device={selectedRows[index].device}
                  remove={removeDevice}
                />
              )}
              height="30rem"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetsTable;
