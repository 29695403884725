import React from 'react';

export interface IconProps {
  fill: string,
  outline: string,
  outlineOpacity: number,
}

const Fire = ({
  fill,
  outline,
  outlineOpacity,
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="64px" width="64px" viewBox="0 0 24 24">
    <path
      d="M12,12.9l-2.13,2.09C9.31,15.55,9,16.28,9,17.06C9,18.68,10.35,20,12,20s3-1.32,3-2.94c0-0.78-0.31-1.52-0.87-2.07 L12,12.9z"
      fill={fill}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outline}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill',
      }}
    />
    <path
      d="M16,6l-0.44,0.55C14.38,8.02,12,7.19,12,5.3V2c0,0-8,4-8,11c0,2.92,1.56,5.47,3.89,6.86C7.33,19.07,7,18.1,7,17.06 c0-1.32,0.52-2.56,1.47-3.5L12,10.1l3.53,3.47c0.95,0.93,1.47,2.17,1.47,3.5c0,1.02-0.31,1.96-0.85,2.75 c1.89-1.15,3.29-3.06,3.71-5.3C20.52,10.97,18.79,7.62,16,6z"
      fill={fill}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outline}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill',
      }}
    />
  </svg>
);

export default Fire;
