import Smart from 'hocs/smart';
import NavigationView from './Navigation.view';

export default Smart(
  NavigationView,
  state => ({
    organisationId: state.session.organisationId,
    hasUnreadMessages: !!Object.keys(state.reports.textMessages).length,
  }),
  () => ({})
);
