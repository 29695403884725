import { Feature, MultiPolygon, Point } from 'geojson';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { getGeometriesFromMultiPolygon, getId } from 'components/pages/manage/geofences/helpers';
import type { GeofenceAltitudeRestriction, GeofenceResponseItem } from 'apis/rest/geofence/types';

export interface MapDrawState {
  features: Feature[]
  selectedPoints: Point[]
  selectedFeatures: Feature[]
}

export type GeofenceFormData = Pick<GeofenceResponseItem, 'name' | 'description' | 'category' | 'altitudeRestriction'>

export type EditGeofencePayload = Pick<GeofenceResponseItem, 'name' | 'description' | 'category' | 'altitudeRestriction' | 'geometry'>

export interface ManageGeofencesState {
  formData: GeofenceFormData
  initialFormData: GeofenceFormData
  mapDraw: MapDrawState
  initialFeatures: Feature[]
  highlightedGeofenceIds: number[]
  drawing: boolean,
}

export const INITIAL_STATE: ManageGeofencesState = {
  formData: {
    name: '',
    description: '',
    category: 'Generic',
    altitudeRestriction: null,
  },
  initialFormData: {
    name: '',
    description: '',
    category: 'Generic',
    altitudeRestriction: null,
  },
  mapDraw: {
    features: [],
    selectedPoints: [],
    selectedFeatures: [],
  },
  initialFeatures: [],
  highlightedGeofenceIds: [],
  drawing: false,
};

const createDefaultAltitudeRestriction = (): GeofenceAltitudeRestriction => ({
  minimumAltitude: {
    altitude: 0,
    type: 'MSL',
  },
  maximumAltitude: {
    altitude: 0,
    type: 'MSL',
  },
});

export const manageGeofencesSlice = createSlice({
  name: 'manageGeofences',
  initialState: INITIAL_STATE,
  reducers: {
    createGeofence: () => INITIAL_STATE,
    editGeofence: (state, action: PayloadAction<Pick<GeofenceResponseItem, 'name' | 'description' | 'altitudeRestriction' | 'category' | 'geometry'>>) => {
      state.formData = {
        name: action.payload.name,
        description: action.payload.description,
        category: action.payload.category,
        altitudeRestriction: action.payload.altitudeRestriction,
      };
      state.initialFormData = state.formData;

      const selectedFenceGeometries = getGeometriesFromMultiPolygon(action.payload.geometry);
      const features: Feature[] = selectedFenceGeometries.map(geometry => ({ type: 'Feature', geometry, id: getId(), properties: {} }));

      state.initialFeatures = features;
      state.mapDraw = {
        features,
        selectedFeatures: [],
        selectedPoints: [],
      };

      state.highlightedGeofenceIds = [];
    },
    reset: state => {
      state.formData = state.initialFormData;
      state.mapDraw.features = state.initialFeatures;
      state.mapDraw.selectedFeatures = [];
      state.mapDraw.selectedPoints = [];
      state.highlightedGeofenceIds = [];
      state.drawing = false;
    },
    setName: (state, action: PayloadAction<GeofenceFormData['name']>) => {
      state.formData.name = action.payload;
    },
    setCategory: (state, action: PayloadAction<GeofenceFormData['category']>) => {
      state.formData.category = action.payload;
    },
    setDescription: (state, action: PayloadAction<GeofenceFormData['description']>) => {
      state.formData.description = action.payload;
    },
    enableAltitudeRestriction: (state, action: PayloadAction<boolean>) => {
      state.formData.altitudeRestriction = action.payload ? createDefaultAltitudeRestriction() : null;
    },
    setAltitudeRestrictionValue: (state, action: PayloadAction<{ value: number, place: 'min' | 'max' }>) => {
      if (!state.formData.altitudeRestriction) {
        state.formData.altitudeRestriction = createDefaultAltitudeRestriction();
      }

      if (action.payload.place === 'min') {
        state.formData.altitudeRestriction.minimumAltitude.altitude = action.payload.value;
      } else {
        state.formData.altitudeRestriction.maximumAltitude.altitude = action.payload.value;
      }
    },
    setAltitudeRestrictionType: (state, action: PayloadAction<{ value: 'MSL' | 'AGL', place: 'min' | 'max' }>) => {
      if (!state.formData.altitudeRestriction) {
        state.formData.altitudeRestriction = createDefaultAltitudeRestriction();
      }

      if (action.payload.place === 'min') {
        state.formData.altitudeRestriction.minimumAltitude.type = action.payload.value;
      } else {
        state.formData.altitudeRestriction.maximumAltitude.type = action.payload.value;
      }
    },
    setInitialFeatures: (state, action: PayloadAction<MultiPolygon>) => {
      const selectedFenceGeometries = getGeometriesFromMultiPolygon(action.payload);
      const features: Feature[] = selectedFenceGeometries.map(geometry => ({ type: 'Feature', geometry, id: getId(), properties: {} }));

      state.initialFeatures = features;
      state.mapDraw.features = features;
      state.mapDraw.selectedFeatures = [];
      state.mapDraw.selectedPoints = [];
    },
    updateFeature: (state, action: PayloadAction<Feature[]>) => {
      state.mapDraw.features = [
        ...state.mapDraw.features.filter(f => f.id !== undefined && !action.payload.some(x => x.id === f.id)),
        ...action.payload,
      ];
    },
    deleteFeature: (state, action: PayloadAction<Feature[]>) => {
      state.mapDraw.features = state.mapDraw.features.filter(f => !action.payload.some(x => x.id === f.id));
      state.mapDraw.selectedFeatures = state.mapDraw.selectedFeatures.filter(f => !action.payload.some(x => x.id === f.id));
      state.mapDraw.selectedPoints = [];
    },
    selectFeature: (state, action: PayloadAction<{ features: Feature[], points: Point[] }>) => {
      state.mapDraw.selectedFeatures = action.payload.features;
      state.mapDraw.selectedPoints = action.payload.points;
    },
    deleteSelectedFeature: state => {
      const selectedIds = state.mapDraw.selectedFeatures.map(f => f.id);
      state.mapDraw.features = state.mapDraw.features.filter(f => !selectedIds.includes(f.id));
      state.mapDraw.selectedFeatures = [];
      state.mapDraw.selectedPoints = [];
    },
    clearFeatures: state => {
      state.mapDraw = INITIAL_STATE.mapDraw;
    },
    highlightGeofences: (state, action: PayloadAction<number[]>) => {
      state.highlightedGeofenceIds = action.payload;
    },
    startDrawing: state => {
      state.drawing = true;
    },
    stopDrawing: state => {
      state.drawing = false;
    },
  },
  selectors: {
    selectFormData: state => state.formData,
    selectHasAltitudeRestriction: state => state.formData.altitudeRestriction !== null,
    selectMapDraw: state => state.mapDraw,
    selectHasChanges: state => {
      if (state.initialFormData.name !== state.formData.name) return true;
      if (state.initialFormData.category !== state.formData.category) return true;
      if (state.initialFormData.description !== state.formData.description) return true;
      if (!isEqual(state.initialFormData.altitudeRestriction, state.formData.altitudeRestriction)) return true;
      if (!isEqual(state.initialFeatures, state.mapDraw.features)) return true;
      return false;
    },
    selectValidations: state => {
      const validations = {
        name: state.formData.name.length > 0,
        features: state.mapDraw.features.length > 0,
        all: false,
      };
      validations.all = validations.name && validations.features;
      return validations;
    },
    selectHighlightedGeofenceIds: state => state.highlightedGeofenceIds,
    selectIsDrawing: state => state.drawing,
  },
});

export default manageGeofencesSlice.reducer;
