import React from 'react';
import { Dialog, DialogContent, Stack, TextField } from '@mui/material';
import { useTranslations } from 'use-intl';
import { TPButton } from 'components/shared/button/TPButton';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';

interface CredentialDialogProps {
  open: boolean;
  onClose: () => void;
  email: string;
  password: string
  onPasswordChange: (password: string) => void;
  onSave: () => void;
  saveDisabled?: boolean;
  cancelDisabled?: boolean;
}

export const CredentialDialog = ({
  open,
  onClose,
  email,
  password,
  onPasswordChange,
  onSave,
  saveDisabled,
  cancelDisabled,
}: CredentialDialogProps) => {
  const t = useTranslations('pages.accountSettings');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <TPDialogTitle>{t('reauthenticateHeader')}</TPDialogTitle>
      <DialogContent>
        <Stack pt={3} spacing={3}>
          <TextField
            label={t('emailLabel')}
            variant="outlined"
            value={email}
            fullWidth
            disabled
          />
          <TextField
            label={t('passwordLabel')}
            variant="outlined"
            value={password}
            onChange={evt => onPasswordChange(evt.target.value)}
            fullWidth
            type="password"
          />
        </Stack>
      </DialogContent>
      <TPDialogActions>
        <TPButton
          variant="outlined"
          onClick={onClose}
          disabled={cancelDisabled}
        >
          {t('cancel')}
        </TPButton>
        <TPButton
          onClick={onSave}
          disabled={saveDisabled}
        >
          {t('userButton')}
        </TPButton>
      </TPDialogActions>
    </Dialog>
  );
};
