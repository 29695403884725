import React from 'react';

const Other = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 33.105408,15.883937 c -1.031436,0.06752 -13.847714,28.448611 -12.227535,30.523999 1.62018,2.075388 6.025025,-2.158152 12.567565,-2.117817 6.54254,0.04034 10.531452,4.235669 11.955814,2.254438 1.424361,-1.981232 -11.26441,-30.728149 -12.295844,-30.66062 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Other;
