import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  Alert
} from '@mui/material';
import CheckBox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { EventNotificationRule } from 'apis/rest/eventNotifications/types';
import { MultiSelect } from 'components/shared/multiselect';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import {useTranslations} from 'use-intl';
import * as FlagsHelper from 'helpers/flags';
import { EventCode, EventCodeGroup } from 'apis/rest/eventCodes/types';
import timezones from 'constants/timezones';
import { useSelector } from 'react-redux';
import { AccessTime } from '@mui/icons-material';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { useStaff } from 'hooks/session/useStaff';
import { Geofence, ReadonlyGeofencingMap } from 'components/pages/manage/geofences/readonlyGeofenceMap';
import { ContactGroupLabel } from './contactGroupLabel';
import { ContactGroupWithPeople } from './types';
import useTimezone from "hooks/session/useTimezone";


export interface NotificationRulesDialogProps {
  isReadOnly: boolean;
  open: boolean;
  isSaving: boolean;
  peopleGroups: ContactGroupWithPeople[];
  rule: EventNotificationRule | undefined;
  title: ReactNode;
  ariaLabel: string;
  eventCodes: EventCode[];
  eventGroups: EventCodeGroup[];
  geofences: Geofence[]
  contactTypeOptions: Record<string, string>[];
  daysOfWeekOptions: Record<string, string>[];
  onSave: (rule: EventNotificationRule | undefined) => void;
  onClose: () => void;
  displayType?: 'default' | 'minimal';
}

const startEndTimesIsValid = (start: string, end: string) => {
  const [startHour, startMin] = start.split(':').map(Number);
  const [endHour, endMin] = end.split(':').map(Number);
  const dateStart = new Date(0, 0, 0, startHour, startMin);
  const dateEnd = new Date(0, 0, 0, endHour, endMin);

  return dateStart < dateEnd;
};

export const NotificationRulesDialog = ({ isReadOnly,
  open,
  isSaving,
  peopleGroups,
  rule,
  title,
  ariaLabel,
  eventCodes,
  eventGroups,
  geofences,
  contactTypeOptions,
  daysOfWeekOptions,
  onSave,
  onClose,
  displayType = 'default' }: NotificationRulesDialogProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.rulesSection.rulesDialog');

  const allTimezones = timezones;

  const isStaff = useStaff();
  const userTimezone = useTimezone();

  const [name, setName] = useState<string>();
  const [event, setEvent] = useState<EventCode | null>(null);
  const [eventGroup, setEventGroup] = useState<EventCodeGroup | null>(null);
  const [selectedGeofence, setSelectedGeofence] = useState<Geofence | null>(null);
  const [timezone, setTimezone] = useState<string>();
  const [contactTypes, setContactTypes] = useState<string[]>([]);
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [hasTimeRestriction, setHasTimeRestriction] = useState<boolean>(false);
  // uses this state to render multiselect component so it initial state is updated based on props
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [isDialogDisplyed, setIsDialogDisplyed] = useState<boolean>(false);
  const [eventChanged, setEventChanged] = useState<boolean>(false);
  const [geofenceChanged, setGeofenceChanged] = useState<boolean>(false);
  const [contactChanged, setContactChanged] = useState<boolean>(false);
  const [startTimeChanged, setStartTimeChanged] = useState<boolean>(false);
  const [endTimeChanged, setEndTimeChanged] = useState<boolean>(false);

  const isGeofence = useMemo(() => rule?.sourceType === 'GEOFENCE', [rule?.sourceType]);

  const handleDaysOfWeekChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked && daysOfWeek.includes(e.target.name)) {
      setDaysOfWeek(daysOfWeek.filter(x => x !== e.target.name));
    } else if (checked && !daysOfWeek.includes(e.target.name)) {
      setDaysOfWeek([...daysOfWeek, e.target.name]);
    }
    setFormChanged(true);
  }, [daysOfWeek]);

  const handleContactTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked && contactTypes.includes(e.target.name)) {
      setContactTypes(contactTypes.filter(x => x !== e.target.name));
    } else if (checked && !contactTypes.includes(e.target.name)) {
      setContactTypes([...contactTypes, e.target.name]);
    }
    setContactChanged(true);
    setFormChanged(true);
  }, [contactTypes]);

  const sourceTypeOptions: Record<string, string>[] = useMemo(() => [
    { key: 'GROUP', value: t('options.sourceType.group') },
    { key: 'EVENT', value: t('options.sourceType.event') },
  ], [t]);

  const [sourceType, setSourceType] = useState<string>('GROUP');

  const isValid = useMemo(() => {
    const validations = {
      name: name != null && !!name.trim(),
      sourceId: isGeofence || !!(sourceType === 'EVENT' ? event?.event_id : eventGroup?.event_group_code),
      sourceType: isGeofence || !isStaff || !!sourceType,
      geofence: !isGeofence || !!selectedGeofence,
      contactTypes: contactTypes?.length !== 0,
      daysOfWeek: !hasTimeRestriction || daysOfWeek?.length !== 0,
      timezone: !hasTimeRestriction || (timezone !== '' && timezone !== undefined),
      startTime: !hasTimeRestriction || !!startTime,
      endTime: !hasTimeRestriction || (!!startTime && !!endTime && startEndTimesIsValid(startTime, endTime)),
      all: false,
      messages: {
        name: t('validationMessages.name'),
        sourceId: sourceType === 'EVENT' ? t('validationMessages.event') : t('validationMessages.eventGroup'),
        geofence: t('validationMessages.geofence'),
        contactTypes: t('validationMessages.contactType'),
        startTime: t('validationMessages.startTime'),
        endTime: !endTime ? t('validationMessages.endTime') : t('validationMessages.endTimeOrder'),
        timezone: t('validationMessages.timezone'),
        daysOfWeek: t('validationMessages.daysOfWeek')
      }
    };
    validations.all = validations.name
      && validations.sourceId
      && validations.sourceType
      && validations.geofence
      && validations.contactTypes
      && (!hasTimeRestriction || (validations.timezone && validations.startTime && validations.endTime));

    return validations;
  }, [name, isGeofence, sourceType, event?.event_id, eventGroup?.event_group_code, isStaff, selectedGeofence, contactTypes?.length, hasTimeRestriction, daysOfWeek?.length, timezone, startTime, endTime, t]);

  const validationSummary = useMemo(() => [
    !isValid.name ? isValid.messages.name : undefined,
    !isValid.sourceId ? isValid.messages.sourceId : undefined,
    !isValid.geofence ? isValid.messages.geofence : undefined,
    !isValid.contactTypes ? isValid.messages.contactTypes : undefined,
    !isValid.startTime ? isValid.messages.startTime : undefined,
    !isValid.endTime ? isValid.messages.endTime : undefined,
    !isValid.timezone ? isValid.messages.timezone : undefined,
    !isValid.daysOfWeek ? isValid.messages.daysOfWeek : undefined
  ].filter(x => x), [isValid]);

  const handleCancel = useCallback(() => { onClose(); }, [onClose]);

  const updatedRule: EventNotificationRule | undefined = useMemo(() => (rule ? {
    id: rule.id,
    name: name || '',
    sourceId: (isGeofence ? `${selectedGeofence?.id ?? ''}` : (sourceType === 'EVENT' ? event?.event_id : eventGroup?.event_group_code)) ?? '',
    sourceType: isGeofence ? rule.sourceType : (sourceType ?? 'GROUP'),
    transport: FlagsHelper.arrayToflags(contactTypes),
    daysOfWeek: FlagsHelper.arrayToflags(hasTimeRestriction ? daysOfWeek : daysOfWeekOptions.map(x => x.key)),
    startTime: hasTimeRestriction ? startTime : undefined,
    endTime: hasTimeRestriction ? endTime : undefined,
    timeZone: timezone ?? userTimezone,
    peopleGroupIds: selectedItems,
    notificationGroupId: rule.notificationGroupId,
    rowVersion: rule.rowVersion ?? 1,
  } : undefined), [
    rule, name, isGeofence, selectedGeofence, sourceType, event?.event_id, eventGroup?.event_group_code, contactTypes,
    hasTimeRestriction, daysOfWeek, daysOfWeekOptions, startTime, endTime, timezone, userTimezone, selectedItems]);

  const handleSave = useCallback(() => {
    if (!updatedRule) { return; }
    onSave(updatedRule);
  }, [onSave, updatedRule]);

  const onEnterDialog = useCallback((node: HTMLElement, isAppearing: boolean) => {
    if (rule) {
      if (rule?.daysOfWeek === 'ALL') { rule.daysOfWeek = FlagsHelper.arrayToflags(daysOfWeekOptions.map(d => d.key)); }

      setName(rule.name === '' ? undefined : rule.name);
      if (rule.sourceType === 'GEOFENCE') {
        setSelectedGeofence(geofences.find(x => x.id === Number(rule.sourceId)) ?? null);
      } else {
        const source = sourceTypeOptions.find(s => s.key === rule.sourceType) ?? sourceTypeOptions[0];
        setSourceType(source.key);
        source.key !== 'GROUP' ? setEvent(eventCodes.find(e => e.event_id === rule?.sourceId) ?? null)
          : setEventGroup(eventGroups.find(e => e.event_group_code === rule.sourceId) ?? null);
      }

      setContactTypes(FlagsHelper.flagsToArray(rule.transport ?? ''));
      setTimezone(
        (allTimezones.find(tz => tz.id === rule?.timeZone) ?? allTimezones.find(tz => tz.id === userTimezone) ?? undefined)?.id
      );

      const days = FlagsHelper.flagsToArray(rule.daysOfWeek ?? '');
      setStartTime(rule.startTime);
      setEndTime(rule.endTime);
      setDaysOfWeek(days);
      setHasTimeRestriction(!!rule.startTime || !!rule.endTime || days.length !== 7);

      setSelectedItems(rule.peopleGroupIds);
    }
    setIsDialogDisplyed(true);
    setFormChanged(false);
  }, [allTimezones, daysOfWeekOptions, eventCodes, eventGroups, geofences, rule, sourceTypeOptions, userTimezone]);

  const onExitedDialog = useCallback(() => {
    // Reset values on dialog is closed
    setName(undefined);
    setEvent(null);
    setSelectedGeofence(null);
    setTimezone(undefined);
    setContactTypes([]);
    setDaysOfWeek([]);
    setStartTime('');
    setEndTime('');
    setSelectedItems([]);

    setEventChanged(false);
    setGeofenceChanged(false);
    setIsDialogDisplyed(false);
    setFormChanged(false);
    setContactChanged(false);
    setEventChanged(false);
    setStartTimeChanged(false);
    setEndTimeChanged(false);
    setHasTimeRestriction(false);
  }, []);

  const alert = useMemo(() => {
    if (isGeofence && selectedGeofence) {
      return {
        type: 'info',
        message: t(`information.geofenceBreak.${selectedGeofence.category}`)
      };
    }
    if (!isGeofence && !isStaff) {
      if (sourceType === 'EVENT') {
        return {
          type: 'warning',
          message: t('information.lockedEvent')
        };
      }
      return {
        type: 'info',
        message: t('information.additionalEvents')
      };
    }
    return null;
  }, [isGeofence, selectedGeofence, isStaff, sourceType, t]);

  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => { if (reason === 'backdropClick') { return; } onClose(); }}
      aria-label={ariaLabel}
      fullWidth maxWidth="lg"
      TransitionProps={{ onEnter: onEnterDialog, onExited: onExitedDialog }}>
      <TPDialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title}
        </Stack>
      </TPDialogTitle>
      <DialogContent>
        <Stack direction="column" style={{ paddingTop: '15px' }} spacing={2}>
          {displayType === 'default'
            && (
              <>
                <Grid container spacing={isGeofence ? 2 : 0}>
                  <Grid item xs={12} md={isGeofence ? 7 : 12}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField label={t('labels.name')}
                          value={name ?? ''}
                          onChange={ev => {
                            setName(ev.target.value);
                            setFormChanged(true);
                          }}
                          error={name === undefined ? false : !isValid.name}
                          helperText={name === undefined ? undefined : !isValid.name && isValid.messages.name}
                          disabled={isReadOnly || isSaving}
                          inputProps={{ maxLength: 255 }}
                          fullWidth />
                      </Grid>
                      {!isGeofence && (
                        <Grid item xs={12} sm={12}>
                          <Stack direction="row" spacing={2}>
                            {isStaff && (
                              <ToggleButtonGroup
                                value={sourceType}
                                exclusive
                                onChange={(e, v) => setSourceType(v)}
                                disabled={isReadOnly || isSaving}>
                                {sourceTypeOptions.map(s => (<ToggleButton key={s.key} value={s.key} sx={{ padding: '5px 11px' }}>{s.value}</ToggleButton>))}
                              </ToggleButtonGroup>
                            )}
                            {(sourceType === 'EVENT' && (
                              <Autocomplete disablePortal options={eventCodes} fullWidth
                                onChange={(ev: any, value: EventCode | null) => {
                                  setEvent(value);
                                  !eventChanged && setEventChanged(true);
                                  setFormChanged(true);
                                }}
                                value={event} getOptionLabel={x => x?.name ?? ''} getOptionKey={x => x?.event_id ?? ''}
                                disabled={isReadOnly || isSaving || !isStaff}
                                renderInput={params => (
                                  <TextField {...params} label={t('labels.event')}
                                    error={!isValid.sourceId && eventChanged}
                                    helperText={!isValid.sourceId && eventChanged && t('validationMessages.event')} />
                                )}
                                ListboxProps={{ style: { border: '1px solid lightgray' } }} />
                            ))
                              || (
                                <Autocomplete disablePortal options={eventGroups} fullWidth
                                  onChange={(ev: any, value: EventCodeGroup | null) => {
                                    setEventGroup(value);
                                    !eventChanged && setEventChanged(true);
                                    setFormChanged(true);
                                  }}
                                  value={eventGroup} getOptionLabel={x => x?.name ?? ''} getOptionKey={x => x?.event_group_code ?? ''}
                                  disabled={isReadOnly || isSaving}
                                  renderInput={params => (
                                    <TextField {...params} label={t('labels.eventGroup')}
                                      error={!isValid.sourceId && eventChanged}
                                      helperText={!isValid.sourceId && eventChanged && t('validationMessages.eventGroup')} />
                                  )}
                                  ListboxProps={{ style: { border: '1px solid lightgray' } }} />
                              )}
                          </Stack>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={isGeofence ? 12 : 6}>
                        <Stack direction="row" justifyContent="space-between" sx={{ paddingLeft: '6px' }} alignItems="center" flexWrap="wrap">
                          <Stack>
                            <Stack direction="row" justifyContent="flex-start">
                              {contactTypeOptions.map(c => (
                                <FormControlLabel key={c.key}
                                  control={(
                                    <CheckBox
                                      disabled={isReadOnly || isSaving}
                                      checked={contactTypes.includes(c.key)}
                                      onChange={(e, checked) => { handleContactTypeChange(e, checked); }}
                                      name={c.key} />
                                  )} label={c.value} />
                              ))}
                            </Stack>
                            {contactChanged && !isValid.contactTypes && (
                              <Typography variant="caption" sx={{ color: '#CE2525' }}>
                                {isValid.messages.contactTypes}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                      {alert && (
                        <Grid item xs={12} sm={isGeofence ? 12 : 6}>
                          <Alert severity={alert.type}>{alert.message}</Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isGeofence && (
                    <Grid item xs={12} md={5}>
                      <Stack direction="column" spacing={2}>
                        <Autocomplete disablePortal options={geofences} fullWidth
                          onChange={(ev: any, value: Geofence | null) => {
                            setSelectedGeofence(value);
                            !geofenceChanged && setGeofenceChanged(true);
                            setFormChanged(true);
                          }}
                          value={selectedGeofence}
                          getOptionLabel={x => x?.name ?? ''} getOptionKey={x => x?.id ?? ''}
                          disabled={isReadOnly || isSaving}
                          renderInput={params => (
                            <TextField {...params} label={t('labels.geofence')}
                              error={!isValid.geofence && geofenceChanged}
                              helperText={!isValid.geofence && geofenceChanged && isValid.messages.geofence} />
                          )}
                          ListboxProps={{ style: { border: '1px solid lightgray' } }} />
                        {selectedGeofence && (
                          <ReadonlyGeofencingMap geofence={selectedGeofence} />
                        )}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                <Divider variant="middle" />
                <Stack direction="row" justifyContent="space-between">
                  <Stack spacing={1}>
                    <Typography variant="h5">{t('sections.schedule')}</Typography>
                    <Typography>{t('sections.scheduleDescription')}</Typography>
                  </Stack>
                  <ToggleButtonGroup disabled={isReadOnly || isSaving} value={hasTimeRestriction} exclusive onChange={(e, v) => setHasTimeRestriction(v ?? false)}>
                    <ToggleButton value={false}>{t('options.timeRestriction.anytime')}</ToggleButton>
                    <ToggleButton value>{t('options.timeRestriction.restricted')}</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                {hasTimeRestriction && (
                  <Grid container spacing={2}>
                    <Grid xs={6} md={4}>
                      <TextField label={t('labels.startTime')}
                        value={startTime}
                        onChange={ev => {
                          setStartTime(ev.target.value);
                          setFormChanged(true);
                        }}
                        error={startTimeChanged && !isValid.startTime}
                        helperText={startTimeChanged && !isValid.startTime && isValid.messages.startTime}
                        disabled={isReadOnly || isSaving}
                        inputProps={{ step: 300, endAdornment: <InputAdornment position="end"><AccessTime /></InputAdornment> }}
                        fullWidth type="time" InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid xs={6} md={4}>
                      <TextField label={t('labels.endTime')}
                        value={endTime}
                        onChange={ev => {
                          setEndTime(ev.target.value);
                          setFormChanged(true);
                        }}
                        error={endTimeChanged && !isValid.endTime}
                        helperText={endTimeChanged && !isValid.endTime && isValid.messages.endTime}
                        disabled={isReadOnly || isSaving}
                        inputProps={{ step: 300 }}
                        fullWidth type="time" InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <Autocomplete disablePortal fullWidth
                        options={allTimezones.map(({ id, label }) => ({ id, label }))}
                        getOptionLabel={x => x.label} getOptionKey={x => x.id}
                        isOptionEqualToValue={(options, value) => Object.keys(value).length === 0 || value.id === options.id}
                        value={timezone ? allTimezones.find(tz => tz.id === timezone) : null}
                        disabled={isReadOnly || isSaving}
                        onChange={(ev, value) => {
                          setTimezone(value?.id ?? '');
                          setFormChanged(true);
                        }}
                        renderInput={params => (
                          <TextField {...params} label={t('labels.timezone')}
                            error={timezone === undefined ? undefined : !isValid.timezone}
                            helperText={timezone === undefined ? undefined : !isValid.timezone && isValid.messages.timezone}
                            fullWidth placeholder={t('labels.timezone')}
                            onChange={() => setFormChanged(true)} />
                        )}
                        ListboxProps={{ style: { border: '1px solid lightgray' } }} />
                    </Grid>
                    <Grid xs={12}>
                      <Stack sx={{ paddingLeft: '6px' }}>
                        <Stack direction="row" justifyContent="flex-start" flexWrap="wrap">
                          {daysOfWeekOptions.map(c => (
                            <FormControlLabel control={(
                              <CheckBox
                                checked={daysOfWeek.includes(c.key)}
                                disabled={isReadOnly || isSaving}
                                onChange={(e, checked) => handleDaysOfWeekChange(e, checked)}
                                name={c.key} />
                            )} label={c.value} />
                          ))}
                        </Stack>
                        {!isValid.daysOfWeek && (
                          <Typography variant="caption" sx={{ color: '#CE2525' }}>
                            {isValid.messages.daysOfWeek}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
                <Divider variant="middle" />
              </>
            )}
          <Stack spacing={1}>
            <Typography variant="h5">{t('sections.contactGroups')}</Typography>
            <Typography>{t('sections.contactGroupsDescription')}</Typography>
          </Stack>
          {isDialogDisplyed && (
            <MultiSelect
              items={peopleGroups.map(a => ({ id: a.id, item: a }))}
              preselected={peopleGroups.filter(a => selectedItems?.some(s => s === a.id)).map(a => ({ id: a.id, item: a }))}
              selectedLabel={t('selectedHeader')}
              unselectedLabel={t('availableHeader')}
              isReadOnly={isReadOnly}
              onChange={items => {
                setSelectedItems(items.map(i => i.id));
                setFormChanged(true);
              }}
              filterOperation={({ item }, filterText) => item.name?.toLowerCase().includes(filterText.toLowerCase()) ?? false}
              renderItemElement={({ item }) => (
                <ContactGroupLabel contactGroup={item} />
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Stack spacing={3} flex={1}>
          <Stack my={1} spacing={3} direction="row" height="4em" alignSelf="flex-end">
            <Button variant="outlined" size="large" disabled={isSaving}
              onClick={handleCancel} sx={{ minWidth: '10rem' }}>{t(isReadOnly ? 'closeButton' : 'cancelButton')}
            </Button>
            {!isReadOnly && (
              ((isValid.all || displayType === 'minimal') && (
                <Button variant="contained" size="large" disabled={isSaving || !formChanged}
                  onClick={handleSave} sx={{ minWidth: '10rem' }}>{isSaving ? t('savingButton') : t('saveButton')}
                </Button>
              )) || (
                <Tooltip placement="top" title={validationSummary.map((x, i) => (<div key={`validation-${i}`}>{x}</div>))}>
                  <span>
                    <Button variant="contained" size="large" disabled sx={{ minWidth: '10rem', height: '100%' }}>
                      {t('saveButton')}
                    </Button>
                  </span>
                </Tooltip>
              ))}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
