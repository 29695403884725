import React from 'react';

const TakeOff = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <rect className="icon-detail" fill="#fff" x="3.85" y="11.46" width="5.89" height="0.69" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.71,4.52L7.25,6.17L6.54,5.61L5.45,6.13l1.94,2.2L9.57,7.3L9.35,9.64l1.46-0.69l0.23-2.34l0.55-0.26
C12.09,6.1,12.3,5.51,12.05,5l0,0C11.81,4.49,11.22,4.28,10.71,4.52z M9.13,4.38L8.38,3.85L6.92,4.54L7.7,5.06L9.13,4.38z"
    />
  </svg>
);

export default TakeOff;
