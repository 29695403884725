import React from 'react';
import Generic from '../markerIcons/generic';
import MapPin from './pin';
import CircleIcon from './circle';

export type PointIconType = 'pin' | 'circle';

interface PointIconProps {
  type: PointIconType | 'cluster',
  fill?: string,
}

const PointIcon: React.FC<PointIconProps> = ({ type, fill }) => {
  const iconProps = {
    outline: '#333',
    fill: fill ?? '#FF0000',
    outlineOpacity: 1,
  };
  const pinProps = {
    colors: {
      fill: fill ?? '#FF0000',
      outline: '#FFF',
      stroke: '#000',
      detail: '#FFF',
    },
    outlineOpacity: 1,
    size: {
      width: 64,
      height: 64,
    }
  };

  switch (type) {
    case 'pin':
      return <MapPin {...pinProps} />;
    case 'circle':
    case 'cluster':
    default:
      return <CircleIcon {...pinProps} />;
  }
};

export default PointIcon;
