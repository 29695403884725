import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  memo,
  Dispatch,
  SetStateAction,
} from 'react';
import { Box, Grid, Typography, Stack, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as d3 from 'd3';
import { useTranslations } from 'use-intl';
import Altitude from 'components/shared/altitude/altitude-view';
import Speed from 'components/shared/speed';
import { formatDateTime } from 'utils/time';
import { gatewayToTransport } from 'helpers/transport';
import { useSize } from 'hooks/useSize';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { InferredEventId } from 'apis/rest/inferredEvents/types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import TerrainSpeedChart, { AltitudeMetric, ChartTranslations, XAxis } from './charts/terrainSpeedChart';

interface TripChartsProps {
  trip: Trip
  asset: AssetBasic
  inferredEvents?: Record<number, InferredEventId[]>
  selectedReportId: number | undefined
  setSelectedReportId: (index: number | undefined) => void
  timezone: string
  showSwitches?: boolean
  showTripBoundaries?: boolean
  userTransitions?: Pick<UserTransition, 'toState' | 'reportTime'>[]
}

interface ChartSettingsProps {
  xAxis: XAxis
  setXAxis: Dispatch<SetStateAction<XAxis>>
  altitudeMetric: AltitudeMetric
  setAltitudeMetric:Dispatch<SetStateAction<AltitudeMetric>>
}

const ChartSettings = memo(({ xAxis, setXAxis, altitudeMetric, setAltitudeMetric }: ChartSettingsProps) => {
  const t = useTranslations('pages.reporting');
  return (
    <Stack direction="row" spacing={2}>
      <ToggleButtonGroup
        value={xAxis}
        exclusive
        onChange={(_, value) => { if (value) setXAxis(value as XAxis); }}
    >
        <ToggleButton value="distance">{t('byDistance')}</ToggleButton>
        <ToggleButton value="time">{t('byTime')}</ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={altitudeMetric}
        exclusive
        onChange={(_, value) => { if (value) setAltitudeMetric(value as AltitudeMetric); }}
    >
        <ToggleButton value="amsl">
          <Tooltip title={t('tooltips.amsl')}>
            <span>AMSL</span>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="agl">
          <Tooltip title={t('tooltips.agl')}>
            <span>AGL</span>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
});

const margins = {
  top: 20,
  right: 30,
  bottom: 40,
  left: 60,
};

export const TripCharts = ({ trip, asset, inferredEvents, setSelectedReportId, selectedReportId, timezone, showSwitches = true, showTripBoundaries = false, userTransitions = [] }: TripChartsProps) => {
  const [xAxis, setXAxis] = useState<XAxis>('time');
  const [altitudeMetric, setAltitudeMetric] = useState<AltitudeMetric>('amsl');
  const d3Container = useRef<HTMLDivElement>(null);
  const { width } = useSize(d3Container);
  const theme = useTheme();
  const t = useTranslations('pages.reporting');
  const unitsT = useTranslations('shared.units');
  const { distance: distanceUnit, altitude: altitudeUnit } = useUnitSettings();
  const speedUnit = useSpeedByAsset(asset);

  const enableInferredEvents = useFeatureFlag('frontendInferredEventsTripAnalysis');

  const translations: ChartTranslations = useMemo(() => ({
    altitude: unitsT('altitude'),
    time: unitsT('time'),
    speed: unitsT('speed'),
    distance: unitsT('distance')
  }), [unitsT]);

  const terrainSpeedChart = useMemo(() => {
    const height = d3Container.current?.clientHeight ?? 0;
    d3.select(d3Container.current).selectAll('svg').remove();

    const svg = d3.select(d3Container.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);

    const newChart = new TerrainSpeedChart(
      trip,
      enableInferredEvents ?? false,
      inferredEvents,
      svg,
      height,
      width,
      margins,
      20,
      asset.colour ?? '#000',
      showTripBoundaries,
      theme,
      distanceUnit,
      altitudeUnit,
      speedUnit,
      translations,
      timezone,
      altitudeMetric === 'amsl'
    );
    newChart.addSelectedReportListener(id => setSelectedReportId(id));
    return newChart;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip.id, width, asset.colour, setSelectedReportId, showTripBoundaries, theme]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setReports(trip.reports);
  }, [trip.reports, terrainSpeedChart]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setUserTransitions(userTransitions);
  }, [userTransitions, terrainSpeedChart]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setTranslations(translations);
  }, [translations, terrainSpeedChart]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setTimezone(timezone);
  }, [timezone, terrainSpeedChart]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.showSelectedReport(selectedReportId);
  }, [selectedReportId, terrainSpeedChart]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setXAxisScale(xAxis);
  }, [terrainSpeedChart, xAxis]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setShowTerrain(altitudeMetric === 'amsl');
  }, [terrainSpeedChart, altitudeMetric]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setDistanceUnit(distanceUnit);
  }, [terrainSpeedChart, distanceUnit]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setAltitudeUnit(altitudeUnit);
  }, [terrainSpeedChart, altitudeUnit]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setSpeedUnit(speedUnit);
  }, [terrainSpeedChart, speedUnit]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setEnableInferredEvents(enableInferredEvents ?? false);
  }, [terrainSpeedChart, enableInferredEvents]);

  useEffect(() => {
    if (!terrainSpeedChart) { return; }
    terrainSpeedChart.setInferredEvents(inferredEvents);
  }, [terrainSpeedChart, inferredEvents]);

  const selectedReport = useMemo(() => trip.reports.find(r => r.id === selectedReportId), [trip.reports, selectedReportId]);

  if (!trip.endTime) {
    return <Typography>Trip unfinished</Typography>;
  }

  return (
    <Box
      display="grid"
      height="100%"
      gridTemplateColumns={{ xs: 'max-content 1fr' }}
      gridTemplateRows={{ xs: '430px 1fr', xl: '560px 1fr' }}
      gridTemplateAreas={{ xs: '"charts charts" "controls report"' }}
      gap={3}
    >
      <Box gridArea="charts" pt={3}>
        <div style={{ height: '100%' }} ref={d3Container} />
      </Box>
      {showSwitches && (
        <>
          <Box gridArea="controls" alignSelf="center">
            <ChartSettings
              xAxis={xAxis}
              setXAxis={setXAxis}
              altitudeMetric={altitudeMetric}
              setAltitudeMetric={setAltitudeMetric}
            />
          </Box>
          {selectedReport && (
            <Box gridArea="report" alignSelf="center">
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography variant="h5">{unitsT('time')}</Typography>
                  <Typography>{formatDateTime(selectedReport.timeOfFix, timezone)}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">{unitsT('altitude')}</Typography>
                  <Altitude altitudeInMetres={selectedReport.altitude} precision={0}/>
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">{unitsT('elevation')}</Typography>
                  <Altitude altitudeInMetres={selectedReport.elevation} precision={0} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">{unitsT('speed')}</Typography>
                  <Speed unit={speedUnit} speedInKmh={selectedReport.speed} precision={0} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">{t('transport')}</Typography>
                  <Typography>{gatewayToTransport(selectedReport.gateway)}</Typography>
                  {/* TODO: transport color and icon */}
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
