import { createTheme, alpha } from '@mui/material/styles';
import colorPalette from './colorPalette';
import createCommonTheme, { spacing, spacingPixels } from './commonTheme';

const mode = 'light';
const colors = colorPalette(mode);
const darkColors = colorPalette('dark');
const checkboxAndRadioInputOpacity = '1A'; // 4%

const commonTheme = createCommonTheme(mode);

const boxShadow = {
  default: '0 2px 4px -1px rgba(0, 0, 0, .15), 0 4px 5px 0 rgba(0, 0, 0, .10), 0 1px 10px 0 rgba(0, 0, 0, .08)',
};

const lightTheme = createTheme(commonTheme, {
  spacing: spacingPixels,
  spacingNumber: spacing, // same as spacing but without px
  palette: {
    mode,
    common: {
      black: colors.black,
      white: colors.white,
      text: colors.grey,
      textConstant: colors.grey,
      lightGrey: colors.greyLight,
      lightBlue: colors.greyLight,
      midGrey: colors.greyMid,
      offBlack: colors.greyDark,
      whiteConstant: colors.white,
      blackConstant: colors.black,
      blueConstant: colors.primary,
      darkBlueConstant: colors.primaryDark,
      scrollBar: 'rgb(180,180,180)',
      scrollBarBackground: 'rgba(255,255,255,25)',
      disabled: 'rgba(0,0,0,0.26)',
      tpIconCircle: colors.grey,
      actionIcon: colors.grey
    },
    primary: {
      main: colors.primary,
      dark: colors.primaryLight,
      mainHover: colors.primaryLight,
      darkBlue: colors.primaryDark,
      turquoise: colors.turquoise,
      yellow: colors.yellow,
      orange: colors.orange,
      blue: colors.primary,
      distressRed: colors.error,
      distressRedLight: colors.errorLight,
      activeGreen: colors.success,
      activeGreenLight: colors.successLight
    },
    secondary: {
      main: colors.greyLight,
      light: colors.greyLight,
      dark: colors.primaryLight,
      contrastText: colors.primary,
    },
    error: {
      main: colors.error,
      light: colors.errorDark,
      dark: colors.errorLight,
      contrastText: colors.white,
    },
    labs: {
      main: colors.labs,
      light: colors.labsLight,
      dark: colors.labsDark,
      contrastText: colors.white,
    },
    background: {
      paper: colors.white,
      standOut: colors.primary,
      standOutLight: colors.primaryLight,
      default: '#fafafa',
      overlay: colors.white,
      hamburgerButton: 'rgba(0,0,0,0.1)',
      omniboxBackground: colors.white,
      contextboxBackground: colors.white,
      inputBackground: colors.white,
      activeButton: colors.primaryDark
    },
    messaging: {
      myMessageBackground: colors.primary,
      myMessageText: colors.white,
      theirMessageBackground: colors.greyLight,
      theirMessageText: colors.grey
    },
    omnibox: {
      background: colors.white,
      color: colors.grey,
      overlay: colors.primary,
      overlayHover: colors.primaryLight,
      resultHover: colors.disabledLight,
      highlight: colors.primaryDark,
      searchText: colors.primaryDark,
      searchBorder: `1px solid ${colors.primary}`,
      searchBackground: 'none',
      battery: colors.primary
    },
    contextbox: {
      background: colors.white,
      color: colors.grey,
      totalText: colors.white,
      overlay: colors.disabledLight,
      highlight: colors.primary,
      routeIcon: colors.primaryDark,
      link: colors.primary,
      linkHover: colors.primaryLight
    },
    settingsDrawer: {
      background: colors.white,
      label: colors.primaryDark
    },
    button: {
      primary: colors.primary,
      primaryColor: colors.white,
      primaryHover: colors.primaryLight,
      checked: colors.primary,
      microsoftLoginButtonFg: '#5E5E5E',
      microsoftLoginButtonBg: '#FFF',
    },
    mapControls: {
      fg: colors.white,
      bg: 'rgba(0, 0, 0, 0.4)',
      bgLight: 'rgba(0, 0, 0, 0.2)',
      bgHover: 'rgba(0, 0, 0, 0.8)',
      bgActive: colors.black,
      fgActive: colors.white,
      bgDisabled: 'rgba(0, 0, 0, 0.6)',
      fgDisabled: 'rgba(255, 255, 255, 0.4)',
      bgDisabledHighContrast: colors.grey,
      fgDisabledHighContrast: 'rgba(255, 255, 255, 0.6)',
      fgHighContrast: colors.greyLight,
      bgHighContrast: darkColors.white
    },
    toggleButtons: {
      selectedBg: colors.primary,
      selectedText: colors.white,
      deselectedBg: colors.greyLight,
      deselectedText: colors.grey,
    },
    firefightingDrops: {
      water: colors.water,
      retardant: colors.retardant,
      foam: colors.foam,
      gel: colors.gel,
      unknown: colors.unknown,
    }
  },
  typography: {
    h1: {
      color: '#444',
    },
    h2: {
      color: '#444',
    },
    h3: {
      color: '#444',
    },
    h4: {
      color: '#444',
    },
    h5: {
      color: '#444',
    },
    h6: {
      color: '#444',
    },
    body1: {
      color: '#444',
    },
    body3: { // An additional variant
      ...commonTheme.typography.body1,
      color: colors.grey,
    },
  },
  border: {
    default: `1px solid ${colors.greyMid}`,
    prettyGridDark: `1px solid ${alpha(colors.black, 0.25)}`,
    prettyGridLight: `1px solid ${colors.greyMid}`,
    width: '1px',
    color: colors.greyMid,
    standOut: colors.primary
  },
  boxShadow,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryDark,
          boxShadow: boxShadow.default,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: darkColors.primaryDark,
          color: colors.white
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        },
        primary: {
          backgroundColor: colors.primary,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.primaryLight
          }
        }
      }
    },
    // TODO: check on this
    // MuiChip: {
    //   styleOverrides: {
    //     clickable: {
    //       // backgroundColor: colors.primary,
    //       // color: colors.white,
    //       boxShadow: 'none',
    //       '&:hover': {
    //         boxShadow: 'none',
    //         backgroundColor: colors.primaryLight
    //       },
    //       '&:focus': {
    //         boxShadow: 'none',
    //         backgroundColor: colors.primaryLight,
    //         color: colors.white
    //       }
    //     }
    //   }
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        containedSecondary: {
          color: colors.primary,
          '&:hover': {
            color: colors.white,
            boxShadow: 'none'
          },
          '&:focus': {
            color: colors.white,
            boxShadow: 'none'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.87)',
          paddingBottom: 6
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.primary
          },
          '&:hover': {
            backgroundColor: colors.primary + checkboxAndRadioInputOpacity
          },
          '&.MuiIconButton-colorSecondary.Mui-checked:hover': {
            backgroundColor: colors.primary + checkboxAndRadioInputOpacity
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.primary
          },
          '&:hover': {
            backgroundColor: colors.primaryLight + checkboxAndRadioInputOpacity
          },
          '&.MuiIconButton-colorSecondary.Mui-checked:hover': {
            backgroundColor: colors.primaryLight + checkboxAndRadioInputOpacity
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          zIndex: 1,
        }
      }
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
          '.MuiTypography-root': {
            color: colors.white
          },
          '.MuiTypography-overline': {
            color: alpha(colors.white, 0.54)
          }
        }
      }
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          color: alpha(colors.black, 0.38)
        }
      }
    }
  }
});

export default lightTheme;
