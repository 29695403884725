import airac from 'utils/airac';
import { IntermediaryMapTemplate } from './types';
import { getAttribution, getName } from '../helpers';

const SkyVectorIFRLo = (id: string, lang: string): IntermediaryMapTemplate => ({
  version: 8,
  name: getName(id, lang),
  providerId: 'skyVector',
  sources: {
    'skyVector-ifr-lo': {
      type: 'raster',
      tiles: [
        `${import.meta.env.VITE_SERENITY_TILE_PROXY}/skyvector/lo/${airac}/{z}/{x}/{y}.jpg`
      ],
      maxzoom: 9,
      minzoom: 3,
      tileSize: 256,
      attribution: getAttribution('skyVector', lang),
    }
  },
  layers: [{
    id: 'ifr-lo',
    type: 'raster',
    source: 'skyVector-ifr-lo'
  }],
  thumbnailUrl: '/img/base-layer-thumbnails/ifr-map-thumbnail.jpg',
  dark: false,
});

export default SkyVectorIFRLo;
