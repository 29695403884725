import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InferredEventsState {
  helpDialogOpen: boolean;
}

const initialState: InferredEventsState = { helpDialogOpen: false };

export const inferredEventsSlice = createSlice({
  name: 'inferredEvents',
  initialState,
  reducers: {
    openInferredEventsHelpDialog(state) {
      state.helpDialogOpen = true;
    },
    closeInferredEventsHelpDialog(state) {
      state.helpDialogOpen = false;
    },
  },
  selectors: {
    selectInferredEventsHelpOpen: sliceState => sliceState.helpDialogOpen,
  },
});

export const { openInferredEventsHelpDialog, closeInferredEventsHelpDialog } = inferredEventsSlice.actions;
export const { selectInferredEventsHelpOpen } = inferredEventsSlice.selectors;

export default inferredEventsSlice.reducer;
