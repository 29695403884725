/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useGetLatestDeviceConfiguration } from 'apis/rest/devices/hooks';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { useTranslations } from 'use-intl';
import usePermissions from 'hooks/session/usePermissions';
import { displayKey, parseRock7Configuration } from 'helpers/config';
import DeviceSetConfiguration from './deviceSetConfiguration-view';
import useTimezone from "hooks/session/useTimezone";

const Item = ({ title, description }: { title: string, description: string | boolean | number }): JSX.Element | null => {
  if (description === '') { return null; }
  if (title.endsWith('OnOff') && typeof description === 'boolean') {
    return (
      <>
        <Grid item xs={4} component="dt">{displayKey(title)}:</Grid>
        <Grid item xs={8} component="dd">{description ? 'On' : 'Off'}</Grid>
      </>
    );
  }
  return (
    <>
      <Grid item xs={4} component="dt">{displayKey(title)}:</Grid>
      <Grid item xs={8} component="dd">{description === '' ? '-' : description}</Grid>
    </>
  );
};

export const isValidConfiguration = (configuration: unknown): configuration is Rock7Configuration => {
  if (typeof configuration !== 'object') return false;
  return Object.keys(configuration as object).length > 0;
};

export const DeviceConfiguration = ({ device }: { device: DeviceBasic }): JSX.Element | null => {
  const t = useTranslations('pages.deviceView.configuration');
  const permissions = usePermissions();

  const configQuery = useGetLatestDeviceConfiguration(device.id).query;
  const timezone = useTimezone();

  let configJson: Rock7Configuration = {};
  let configReported;
  if (configQuery.status === 'success' && configQuery.data) {
    const value = parseRock7Configuration(configQuery.data);
    if (value) {
      configJson = value;
      configReported = typeof configQuery.data?.configTime === 'number'
        ? DateTime.fromSeconds(configQuery.data.configTime).setZone(timezone).toFormat('dd MMM yyyy HH:mm:ss ZZZ')
        : undefined;
    }
  }

  if (configQuery.isLoading) {
    return (
      <SettingsSection title={t('title')} description={t('description')}>
        <DetailPanel p={3}>
          <Box py={2}><LoadingIcon size={50} /></Box>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (!isValidConfiguration(configJson)) return null;
  const keys = Object.keys(configJson);

  const items = keys.map((element, index) => {
    const configElement = configJson[element];
    if (typeof configElement !== 'object') return null;

    const elementKeys = Object.keys(configElement);
    if (!elementKeys.length) return null;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Box key={index} mb={2}>
        <Typography variant="h6">{displayKey(element)}</Typography>
        <Grid container component="dl">
          {elementKeys.map(key => <Item key={key} title={key} description={configElement[key]} />)}
        </Grid>
      </Box>
    );
  });

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel p={3}>
        {permissions.canEditAssets && <DeviceSetConfiguration deviceId={device.id} />}
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={4} component="dt">
              <Typography variant="h6">{t('lastUpdated')}:</Typography>
            </Grid>
            <Grid item xs={8} component="dd">{configReported ?? t('unknown')}</Grid>
          </Grid>
          {items}
        </Stack>
      </DetailPanel>
    </SettingsSection>
  );
};
