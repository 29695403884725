import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const SettingsIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <path d="M30.735,20.7,27.5,18.318a11.756,11.756,0,0,0,0-4.638L30.735,11.3A1,1,0,0,0,31.009,10L28.7,6a1,1,0,0,0-1.268-.417L23.747,7.2a11.694,11.694,0,0,0-4-2.317L19.3.89A1,1,0,0,0,18.305,0H13.7A1,1,0,0,0,12.7.89l-.444,4a11.694,11.694,0,0,0-4,2.317L4.564,5.586A1,1,0,0,0,3.3,6L.991,10A1,1,0,0,0,1.265,11.3L4.5,13.682a11.766,11.766,0,0,0,0,4.64L1.266,20.7A1,1,0,0,0,.991,22L3.3,26a1,1,0,0,0,1.267.416L8.253,24.8a11.694,11.694,0,0,0,4,2.317l.444,4A1,1,0,0,0,13.7,32h4.61a1,1,0,0,0,.994-.89l.444-4a11.676,11.676,0,0,0,4-2.316l3.689,1.618A1,1,0,0,0,28.7,26l2.3-4A1,1,0,0,0,30.735,20.7ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z" />
    </SvgIcon>
  );
};

export default SettingsIcon;
