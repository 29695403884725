/* eslint-disable no-param-reassign */
// Latitudes cut off at ~85 deg
// https://math.gl/modules/web-mercator/docs/developer-guide/about-coordinates
const MERCATOR_MIN_LAT = -85.051129;
const MERCATOR_MAX_LAT = 85.051129;
const MERCATOR_MIN_LNG = -180;
const MERCATOR_MAX_LNG = 180;

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const clampLat = lat => clamp(lat || 0, MERCATOR_MIN_LAT, MERCATOR_MAX_LAT);
const clampLng = lng => clamp(lng || 0, MERCATOR_MIN_LNG, MERCATOR_MAX_LNG);

/**
 * Projects the given lat/lng onto the viewport (Mercator)
 * Clamps lat/lng to valid range
 * Falsy lat/lng snap to 0
 * lngOffsetFrames is an integer number of frames (360 deg) to offset the longitude
 * (eg: -1, 0, 1 for triplicate)
 */
export const project = (viewport, lat, lng, lngOffsetFrames = 0) => {
  lat = clampLat(lat);
  lng = clampLng(lng);
  return viewport.project([lng + lngOffsetFrames * 360, lat]);
};

export const unProject = (viewport, x, y) => viewport.unproject([x, y]);
