import React from 'react';

import { bearing } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import Quantity from '../quantity';

interface CourseProps {
  courseInDegreesTrue: number
  timestamp: number
  position: [number, number]
  precision?: number
  smallUnits?: boolean
}

const Course = ({
  courseInDegreesTrue,
  timestamp,
  position,
  precision = 0,
  smallUnits = false
}: CourseProps) => {
  // position is expected to be in unix ms
  const units = useUnitSettings().bearing;
  const speedInLocale = bearing.fromSI(courseInDegreesTrue, timestamp, { latitude: position[1], longitude: position[0] }, units);
  const unitsLabel = bearing.label(units, timestamp);
  return (
    <Quantity value={speedInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Course;
