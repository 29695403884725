import React, { useState } from 'react';
import { Button, Select, MenuItem, Stack, CircularProgress } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { isValidEmail } from 'utils/strings';
import TextInput from 'components/shared/forms/inputs/text';
import DetailPanel from 'components/shared/DetailPanel';
import mixpanel from 'mixpanel-browser';
import { useGetMemberships } from 'apis/rest/memberships/hook';
import { useGetInvites, useInviteUser } from 'apis/rest/invites/hook';
import { useAppDispatch } from 'store/types';
import { displaySnackbar } from 'slices/app.slice';
import useOrganisationId from 'hooks/session/useOrganisationId';

const UserInvitationView = ({
}) => {
  const t = useTranslation('pages.organisationSettings');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [selectedRole, setSelectedRole] = useState('ViewOnly'); // default to 'view only' role
  const getMemberships = useGetMemberships().query;
  const getInvites = useGetInvites().query;
  const dispatch = useAppDispatch();
  const orgId = useOrganisationId();

  //
  // Invite User
  //
  const inviteUser = useInviteUser();
  const doInviteUser = () => inviteUser.mutate(
    { email, name, role: selectedRole },
    {
      onError: () => {
        dispatch(displaySnackbar({ id: 'inviteSendFailed', text: t('inviteSendFailed'), type: 'error' }));
        mixpanel.track('Invite User', { success: false, orgId });
      },
      onSuccess: () => {
        dispatch(displaySnackbar({ id: 'inviteSent', text: t('inviteSent'), type: 'success' }));
        mixpanel.track('Invite User', { success: true, orgId });
      }
    }
  );

  const isInvitedUserExisted = getMemberships.isSuccess && getMemberships.data.some(m => m.email?.toLowerCase() === email);
  const isInvitedUserStillPending = getInvites.isSuccess && getInvites.data.some(m => m.email?.toLowerCase() === email);

  const showUserExistedHelperText = isInvitedUserExisted ? t('userExistedHelperText') : '';
  const showUserPendingHelperText = isInvitedUserStillPending ? t('userPendingHelperText') : '';

  const emailValid = isValidEmail(email);
  const isValid = emailValid && name && !isInvitedUserExisted && !isInvitedUserStillPending;

  const sendUserInvite = () => {
    doInviteUser();
    setName('');
    setEmail('');
  };

  return (
    <DetailPanel p={3}>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <TextInput
            id="email"
            label={t('emailLabel')}
            type="email"
            value={email.toLowerCase()}
            onChange={(field, value) => setEmail(value.toLowerCase())}
            hasError={isInvitedUserExisted || isInvitedUserStillPending}
            helperText={showUserExistedHelperText || showUserPendingHelperText}
          />
          <TextInput
            id="name"
            label={t('nameLabel')}
            value={name}
            onChange={(field, value) => setName(value)}
          />
          <Select
            id={orgId}
            value={selectedRole}
            onChange={e => setSelectedRole(e.target.value)}
            variant="outlined"
            fullWidth
          >
            <MenuItem key="ViewOnly" value="ViewOnly">{t('ViewOnly')}</MenuItem>
            <MenuItem key="Administrator" value="Administrator">{t('Administrator')}</MenuItem>
          </Select>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ height: '4rem' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={!isValid}
            onClick={sendUserInvite}
          >
            {doInviteUser.isLoading ? (
              <CircularProgress size={30} />
            ) : (
              t('inviteButton')
            )}
          </Button>
        </Stack>
      </Stack>
    </DetailPanel>
  );
};

export default UserInvitationView;
