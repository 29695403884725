import { Alert, Box, Button, Container, Paper, Typography } from "@mui/material";
import { useGetUserHistories } from "apis/rest/userHistory/userHistory-hooks";
import Page from "components/pages/page";
import LinkAbove from "components/shared/linkAbove";
import PersistentTable from 'components/shared/persistentTable';
import { SettingsMenuPageWrapper } from "components/shared/settingsMenu";
import useFeatureAssets from "contexts/featureAssets/useFeatureAssets";
import { stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useDemoMode, useStaff } from "hooks/session/useStaff";
import useTimezone from 'hooks/session/useTimezone';
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useTranslations } from "use-intl";
import { downloadCSV } from 'utils/download';
import UsersHistoryDateRangeView, { UsersHistoryDateRange } from "./usersHistoryDateRange-view";

export function parseUsersHistoryParams(params: URLSearchParams, timezone: string) {
  const defaultFrom = DateTime.now().minus({ days: 30 }).startOf('day');
  const defaultUntil = DateTime.now().endOf('day');

  const from = params.get('from');
  const until = params.get('until');

  let parsedFrom = defaultFrom;
  let parsedUntil = defaultUntil;

  if (from) {
    const attemptedFrom = DateTime.fromFormat(from, 'yyyy-MM-dd', { zone: timezone }).startOf('day');
    if (attemptedFrom.isValid) {
      parsedFrom = attemptedFrom;
    }
  }

  if (until) {
    const attemptedUntil = DateTime.fromFormat(until, 'yyyy-MM-dd', { zone: timezone }).endOf('day');
    if (attemptedUntil.isValid) {
      parsedUntil = attemptedUntil;
    }
  }

  if (parsedFrom > parsedUntil) {
    parsedFrom = parsedUntil.minus({ days: 30 }).startOf('day');
  }

  if (until && !from) {
    parsedFrom = parsedUntil.minus({ days: 30 }).startOf('day');
  }

  if (from && !until) {
    parsedUntil = parsedFrom.plus({ days: 30 }).endOf('day');
  }

  return {
    from: parsedFrom.toMillis(),
    until: parsedUntil.toMillis()
  };
}

export const UsersHistoryView: React.FC = () => {
  const t = useTranslations('pages.manage.usersHistory');
  const isStaff = useStaff();
  const demoMode = useDemoMode();
  const zone = useTimezone();
  const [searchParams, setSearchParams] = useSearchParams();
  const userActivity = useFeatureAssets('manage.userActivity', true);

  const parsedParams = parseUsersHistoryParams(searchParams, zone);
  const [dateRange, _setDateRange] = useState<UsersHistoryDateRange>(parsedParams);

  const setDateRange = (newDateRange: UsersHistoryDateRange) => {
    _setDateRange(newDateRange);
    setSearchParams(prev => {
      prev.set('from', DateTime.fromMillis(newDateRange.from).toFormat('yyyy-MM-dd'));
      prev.set('until', DateTime.fromMillis(newDateRange.until).toFormat('yyyy-MM-dd'));
      return prev;
    });
  };

  const { query } = useGetUserHistories(dateRange.from, dateRange.until, isStaff && !demoMode);

  useEffect(() => {
    const searchParamsFrom = searchParams.get('from');
    const searchParamsUntil = searchParams.get('until');
    if (!searchParamsFrom || !searchParamsUntil || (searchParamsFrom !== DateTime.fromMillis(dateRange.from).toFormat('yyyy-MM-dd') || searchParamsUntil !== DateTime.fromMillis(dateRange.until).toFormat('yyyy-MM-dd'))) {
      setSearchParams({
        from: DateTime.fromMillis(dateRange.from).toFormat('yyyy-MM-dd'),
        until: DateTime.fromMillis(dateRange.until).toFormat('yyyy-MM-dd'),
      }, { replace: true }); // Add replace: true to prevent adding to history
    }
  }, [searchParams, setSearchParams, dateRange]);

  const handleDownloadCSV = () => {
    if (!query.data) return;

    const csvData = csvStringify(query.data, {
      header: true,
      columns: {
        name: t('name'),
        email: t('email'),
        type: t('type'),
        ip: t('ipAddress'),
        timestamp: t('time')
      }
    });

    downloadCSV(`users-history-${DateTime.fromMillis(dateRange.from).toFormat('yyyy-MM-dd')}-to-${DateTime.fromMillis(dateRange.until).toFormat('yyyy-MM-dd')}.csv`, csvData);
  };

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
            {!userActivity.some && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                {t('staffOnlyWarning')}
              </Alert>
            )}
            <Paper sx={{ pt: 2, pb: 1, pr: 2, pl: 2, mb: 2 }}>
              <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
                <UsersHistoryDateRangeView
                  dateRange={dateRange}
                  onChange={setDateRange}
                  zone={zone}
                />
                <Button variant="contained" onClick={handleDownloadCSV}>
                  {t('downloadCSV')}
                </Button>
              </Box>
            </Paper>
            <PersistentTable
              settingsCategory="usersHistoryTable"
              data={query.data ?? []}
              title={t('title')}
              columns={[
                {
                  title: t('name'),
                  field: 'name',
                  headerStyle: { textAlign: 'left' },
                  cellStyle: { textAlign: 'left' },
                },
                {
                  title: t('email'),
                  field: 'email',
                  headerStyle: { textAlign: 'left' },
                  cellStyle: { textAlign: 'left' },
                },
                {
                  title: t('type'),
                  field: 'type',
                  headerStyle: { textAlign: 'left' },
                  cellStyle: { textAlign: 'left' },
                },
                {
                  title: t('ipAddress'),
                  field: 'ip',
                  headerStyle: { textAlign: 'left' },
                  cellStyle: { textAlign: 'left' },
                },
                {
                  title: t('time'),
                  field: 'timestamp',
                  type: 'datetime',
                  headerStyle: { textAlign: 'left' },
                  cellStyle: { textAlign: 'left' },
                },
              ]}
              isLoading={query.isLoading}
            />
          </Box>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};
