import { MapBaseLayerIds } from 'constants/map';
import { IntermediaryMapTemplate } from './types';
import { getAttribution, getName } from '../helpers';

const LinzTemplate = (id: string, lang: string): IntermediaryMapTemplate => {
  const tilePath = `${import.meta.env.VITE_SERENITY_TILE_PROXY}/linz/{z}/{x}/{y}.webp`;

  return {
    version: 8,
    feature: 'map.marine',
    replacementId: MapBaseLayerIds.MapboxStreets,
    name: getName(id, lang),
    groupId: 'nauticalCharts',
    providerId: 'linz',
    sources: {
      linz: {
        type: 'raster',
        tiles: [tilePath],
        minzoom: 1,
        maxzoom: 16,
        tileSize: 256,
        attribution: getAttribution('linz', lang),
      },
    },
    layers: [
      {
        id: 'linz',
        type: 'raster',
        source: 'linz'
      },
    ],
    thumbnailUrl: '/img/base-layer-thumbnails/linz-thumbnail.jpg',
    dark: false,
  };
};

export default LinzTemplate;
