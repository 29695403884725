import React from 'react';
import { Alert, AlertTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { BuildCircle, HelpOutline } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import { deviceStatus } from 'constants/deviceStatus';
import { goToFirmwareVersionOutdatedHelpArticle } from 'helpers/externalLinks';
import { useLatestFirmwareVersionForDevice } from 'apis/rest/devices/hooks';
import useTimezone from "hooks/session/useTimezone";

export const DeviceGeneral = ({ device }: { device: DeviceBasic }): JSX.Element => {
  const t = useTranslations('pages.deviceView.general');
  const timezone = useTimezone();

  const statusText = deviceStatus.find(s => s.id === device.status)?.description ?? t('unknownStatus');
  const lastActive = device.lastActive && DateTime.fromISO(device.lastActive)
    .setZone(timezone)
    .toFormat('dd MMM yyyy HH:mm:ss ZZZ');

  // TODO: check device capabilities
  const showPhoneNumbers = device.make.toLowerCase() === 'flightcell' || !!device.satNumber || !!device.smsNumber;

  const latestFirmware = useLatestFirmwareVersionForDevice(device);

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel p={3} spacing={3}>
        {latestFirmware.outdated && (
          <Alert
            severity="warning"
            icon={<BuildCircle />}
            action={(
              <Tooltip title={t('firmwareOutdated.help')}>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={e => {
                    e.preventDefault();
                    goToFirmwareVersionOutdatedHelpArticle(device.model);
                  }}
                >
                  <HelpOutline fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          >
            <AlertTitle>{t('firmwareOutdated.title')}</AlertTitle>
            {t('firmwareOutdated.description', { latest: latestFirmware.latest })}
          </Alert>
        )}
        <Grid container component="dl">
          <Grid item xs={4} component="dt">{t('make')}:</Grid>
          <Grid item xs={8} component="dd">{device.make}</Grid>
          <Grid item xs={4} component="dt">{t('model')}:</Grid>
          <Grid item xs={8} component="dd">{device.model}</Grid>
          <Grid item xs={4} component="dt">{t('imei')}:</Grid>
          <Grid item xs={8} component="dd">{device.imei}</Grid>
          <Grid item xs={4} component="dt">{t('manufacturerSerial')}:</Grid>
          <Grid item xs={8} component="dd">{device.manufacturerSerial}</Grid>
          <Grid item xs={4} component="dt">{t('tpSerial')}:</Grid>
          <Grid item xs={8} component="dd">{device.tracPlusSerial}</Grid>
          {showPhoneNumbers && (
            <>
              <Grid item xs={4} component="dt">{t('satNumber')}:</Grid>
              <Grid item xs={8} component="dd">{device.satNumber || '–'}</Grid>
              <Grid item xs={4} component="dt">{t('smsNumber')}:</Grid>
              <Grid item xs={8} component="dd">{device.smsNumber || '–'}</Grid>
            </>
          )}
          <Grid item xs={4} component="dt">{t('status')}:</Grid>
          <Grid item xs={8} component="dd">{statusText}</Grid>
          <Grid item xs={4} component="dt">{t('lastActive')}:</Grid>
          <Grid item xs={8} component="dd">{lastActive}</Grid>
        </Grid>
      </DetailPanel>
    </SettingsSection>
  );
};
