interface TrackingRates {
  satellite: string | null
  cellular: string | null
  distress: string | null
  burst?: {
    satellite: string | null,
    cellular: string | null
    distress: string | null
  }
  inactive?: {
    satellite: string | null,
    cellular: string | null
    distress: string | null
  }
}

export const parseRock7Configuration = (config: DeviceConfiguration): Rock7Configuration | undefined => {
  try {
    return JSON.parse(config.config) as Rock7Configuration;
  } catch (error) {
    return undefined;
  }
};

export const tryGetTrackingRates = (config: DeviceConfiguration): TrackingRates | null => {
  const value = parseRock7Configuration(config);
  if (!value) return null;

  const trackingSettings = value.TrackingSettingsElement;

  if (trackingSettings) {
    if (trackingSettings.TransmitFrequency?.toLowerCase() === 'burst') {
      // RockSTAR (sometimes)
      return {
        satellite: trackingSettings.BurstTransmitFrequency ?? null,
        cellular: null,
        distress: null
      };
    }

    if (trackingSettings.ActivitySenseMode?.toLowerCase() !== 'off') {
      // Activity-sense burst mode
      return {
        cellular: trackingSettings.CellularBurstTransmitFrequency ?? null,
        satellite: trackingSettings.IridiumBurstTransmitFrequency ?? null,
        distress: null,
        burst: {
          cellular: trackingSettings.CellularBurstFixFrequency ?? null,
          satellite: trackingSettings.IridiumBurstFixFrequency ?? null,
          distress: null
        },
        inactive: {
          cellular: trackingSettings.CellularTransmitFrequency ?? null,
          satellite: trackingSettings.IridiumTransmitFrequency ?? null,
          distress: null
        }
      };
    }

    const rates: TrackingRates = {
      cellular: trackingSettings.CellularTransmitFrequency ?? null,
      satellite: trackingSettings.IridiumTransmitFrequency ?? null,
      distress: trackingSettings.DistressTransmitFrequency ?? null,
    };

    const cellBurst = rates.cellular?.toLowerCase() === 'burst';
    const satBurst = rates.satellite?.toLowerCase() === 'burst';
    const distressBurst = rates.distress?.toLowerCase() === 'burst';

    if (cellBurst || satBurst || distressBurst) {
      rates.burst = {
        cellular: null,
        satellite: null,
        distress: null
      };

      if (cellBurst) {
        rates.cellular = trackingSettings.CellularBurstTransmitFrequency ?? null;
        rates.burst.cellular = trackingSettings.CellularBurstFixFrequency ?? null;
      }

      if (satBurst) {
        rates.satellite = trackingSettings.IridiumBurstTransmitFrequency ?? null;
        rates.burst.satellite = trackingSettings.IridiumBurstFixFrequency ?? null;
      }

      if (distressBurst) {
        rates.distress = trackingSettings.DistressBurstTransmitFrequency ?? null;
        rates.burst.distress = trackingSettings.DistressBurstFixFrequency ?? null;
      }
    }

    return rates;
  }

  return null;
};

export const tryGetFirmwareVersion = (config: DeviceConfiguration): string | null => (
  parseRock7Configuration(config)?.VersionInfoElement?.SoftwareVersion ?? null
);

export const tryGetTrackingActive = (config: DeviceConfiguration): boolean | null => (
  parseRock7Configuration(config)?.TrackingSettingsElement?.TrackingOnOff ?? null
);

export const tryGetGprsStrategy = (config: DeviceConfiguration): string | null => {
  const value = parseRock7Configuration(config);
  return value?.MailboxSettingsElement?.GPRSStrategy ?? value?.SystemSettingsElement?.GPRSStrategy ?? null;
};

// separate camelcase to regular non-camelcase, handling the case with an initialised acronym e.g. GPSSettings => GPS Settings
const separateRegex = /(?=[A-Z][a-z])/g;
export const displayKey = (key: string): string => (
  key
    .replace('Element', '')
    .replace('OnOff', '')
    .replaceAll(separateRegex, ' ')
    .trim()
);
