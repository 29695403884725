import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';
import type { ControlPosition } from 'react-map-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import React, { useEffect } from 'react';
import { Feature, FeatureCollection, Point } from 'geojson';

type GeofencesMapDrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: Feature[] }) => void;
  onUpdate?: (evt: { features: Feature[]; action: string }) => void;
  onDelete?: (evt: { features: Feature[] }) => void;
  onSelect?: (evt: { features: Feature[], points: Point[], type: string }) => void;
  featureCollection?: FeatureCollection;
  modeValue: 'draw_polygon' | 'simple_select' | 'direct_select';
};

const GeofencesMapDrawControl: React.FC<GeofencesMapDrawControlProps> = ({ onCreate, onDelete, onUpdate, onSelect, position, featureCollection, modeValue, ...props }) => {
  const mapDraw = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }) => {
      if (onCreate) {
        map.on('draw.create', onCreate);
      }
      if (onUpdate) {
        map.on('draw.update', onUpdate);
      }
      if (onDelete) {
        map.on('draw.delete', onDelete);
      }
      if (onSelect) {
        map.on('draw.selectionchange', onSelect);
      }
    },
    ({ map }) => {
      if (onCreate) {
        map.off('draw.create', onCreate);
      }
      if (onUpdate) {
        map.off('draw.update', onUpdate);
      }
      if (onDelete) {
        map.off('draw.delete', onDelete);
      }
      if (onSelect) {
        map.off('draw.selectionchange', onSelect);
      }
    },
    {
      position
    }
  );

  useEffect(() => {
    mapDraw.changeMode(modeValue);
  }, [mapDraw, modeValue]);

  useEffect(() => {
    if (featureCollection) {
      mapDraw.set(featureCollection);
    }
  }, [featureCollection, mapDraw]);

  return null;
};

export default GeofencesMapDrawControl;
