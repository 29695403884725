import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import LinkAbove from 'components/shared/linkAbove';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { UserProfile } from './UserProfile';
import { UserOrganisations } from './UserOrganisations';

export const AccountSettings = () => {
  const t = useTranslations('pages.accountSettings');

  return (
    <Page>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <LinkAbove />
          <Typography variant="h1" component="h1" gutterBottom>{t('title')}</Typography>
          <UserProfile />
          <UserOrganisations />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};
