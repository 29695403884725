import { Box, CircularProgress, IconButton, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Check, Delete, Edit } from '@mui/icons-material';
import { FieldAccessor, FieldTypesToInputTypes } from 'helpers/supplementaryData';
import { useUpdateSupplementaryData } from 'apis/rest/trips/hooks';
import { useNotification } from 'contexts/notification/useNotification';
import useSnackbar from 'hooks/useSnackbar';
import useFeature from 'hooks/features/useFeature';
import useFeatureAssets from "contexts/featureAssets/useFeatureAssets";

interface EditFieldInputProps {
    field: SupplementaryDataFieldType | null | undefined
    fieldValue: string
    setFieldValue: (f: string) => void
    disabled?: boolean
}

interface EditFieldDisplayProps {
    field: SupplementaryDataFieldType | null | undefined
    fieldValue: string
    deleting: boolean
}

export const EditFieldInput = ({ field, fieldValue, setFieldValue, disabled = false }: EditFieldInputProps) => (
  (field?.dataType === 'Boolean' ? (
    <ToggleButtonGroup
      value={fieldValue}
      exclusive
      onChange={(e, newValue) => setFieldValue(`${newValue}`)}
    >
      <ToggleButton value="True">Yes</ToggleButton>
      <ToggleButton value="False">No</ToggleButton>
    </ToggleButtonGroup>
  )
    : (
      <TextField
        disabled={!field || disabled}
        type={FieldTypesToInputTypes[field?.dataType ?? 'String']}
        inputProps={field?.dataType === 'Int' ? { step: 1, pattern: '\\d*', min: 0 } : undefined}
        sx={{ '& .MuiInputBase-root': { height: '100%' }, alignSelf: 'stretch' }}
        value={fieldValue}
        onChange={e => setFieldValue(`${e.target.value}`)}
        fullWidth
    />
    ))
);
const EditFieldDisplay = ({ field, fieldValue, deleting }: EditFieldDisplayProps) => (
  <Typography>
    {(field?.dataType === 'Boolean'
      ? (fieldValue === 'True' ? (deleting ? <s>Yes</s> : 'Yes')
        : (fieldValue === 'False'
          ? (deleting ? <s>No</s> : 'No')
          : ''))
      : (deleting
        ? <s>{fieldValue}</s>
        : fieldValue))}
  </Typography>
);

export const SupplementaryDataFieldView = ({ field, fields, update, trip }: {
    field: SupplementaryDataField,
    fields: SupplementaryDataFieldType[],
    update: ReturnType<typeof useUpdateSupplementaryData>,
    trip: Trip
}) => {
  const supplementaryDataFeatureAssets = useFeatureAssets('reporting.supplementaryData');
  const supplementaryDataEnabled = supplementaryDataFeatureAssets.hasAssetId(trip.assetId);
  const relevantField = fields.find(f => f.fieldName === field.name);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fieldValue, setFieldValue] = useState(`${field[FieldAccessor[field.dataType]]}`);
  const snackbar = useSnackbar();

  const onWebSocketsServerSaveSuccessHandler = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const onWebSocketsServerSaveFailureHandler = useCallback(() => {
    setSubmitting(false);
    // Just the one snackbar should get raised if something goes wrong from websockets and that one is the notification catcher on the parent.
  }, [setSubmitting]);

  const onSaveSuccessHandler = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const onSaveFailedHandler = useCallback(() => {
    setEditing(false);
    setSubmitting(false);
    snackbar.display({ type: 'error', text: 'Failed to save supplementary data.', id: 'serverSaveFailedSupplementaryData' });
  }, [snackbar, setEditing, setSubmitting]);

  const onDeleteSuccessHandler = useCallback(() => {
    setEditing(false);
    setDeleting(false);
  }, [setEditing]);

  const onDeleteFailedHandler = useCallback(() => {
    setEditing(false);
    setSubmitting(false);
    setDeleting(false);
    snackbar.display({ type: 'error', text: 'Failed to delete supplementary data.', id: 'serverDeleteFailedSupplementaryData' });
  }, [snackbar, setEditing, setSubmitting, setDeleting]);

  useNotification('supplementaryDataEventFinished', onWebSocketsServerSaveSuccessHandler, supplementaryDataEnabled);
  useNotification('supplementaryDataEventFailed', onWebSocketsServerSaveFailureHandler, supplementaryDataEnabled);

  const onSave = useCallback(() => {
    if (!relevantField || !fieldValue) {
      return;
    }
    const event: SupplementaryDataEvent = {
      dataTypeId: relevantField?.id,
      eventTime: DateTime.fromMillis(trip.startTime).toISO(),
      enteredTime: DateTime.now().toISO(),
      value: fieldValue,
      id: crypto.randomUUID()
    };
    setSubmitting(true);
    update.mutate([event], {
      onError: () => onSaveFailedHandler(),
      onSuccess: () => onSaveSuccessHandler()
    });
  }, [fieldValue, relevantField, trip.startTime, update, onSaveFailedHandler, onSaveSuccessHandler]);

  const onDelete = useCallback(() => {
    if (!relevantField || !fieldValue) {
      return;
    }
    const event: SupplementaryDataEvent = {
      dataTypeId: relevantField?.id,
      eventTime: DateTime.fromMillis(trip.startTime).toISO(),
      enteredTime: DateTime.now().toISO(),
      value: undefined,
      id: crypto.randomUUID()
    };
    setDeleting(true);
    update.mutate([event], {
      onError: () => onDeleteFailedHandler(),
      onSuccess: () => onDeleteSuccessHandler()
    });
  }, [relevantField, fieldValue, trip.startTime, update, onDeleteFailedHandler, onDeleteSuccessHandler]);

  useEffect(() => {
    setFieldValue(`${field[FieldAccessor[field.dataType]]}`);
  }, [setFieldValue, field]);

  return (
    <>
      <Typography variant="h5">{deleting ? <s>{relevantField?.displayName ?? field.name}</s> : (relevantField?.displayName ?? field.name)}</Typography>
      {editing
        ? (<EditFieldInput field={relevantField} fieldValue={fieldValue} setFieldValue={setFieldValue} disabled={submitting} />)
        : <EditFieldDisplay field={relevantField} fieldValue={fieldValue} deleting={deleting} />}
      <Stack direction="row">
        {deleting || submitting
          ? <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
          : (
            <>
              <Tooltip title={editing ? 'save' : 'edit'}>
                {editing
                  ? <IconButton onClick={() => onSave()}><Check /></IconButton>
                  : <IconButton onClick={() => setEditing(true)}><Edit /></IconButton>}
              </Tooltip>
              <IconButton onClick={() => onDelete()}><Delete /></IconButton>
            </>
          )}
      </Stack>
    </>
  );
};
