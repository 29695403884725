import React from 'react';
import { speed, SpeedUnit } from 'helpers/unitsOfMeasure';
import { useSpeedByCategory } from 'hooks/units/useSpeed';
import Quantity from '../quantity';

interface SpeedProps {
  speedInKmh?: number;
  precision?: number;
  smallUnits?: boolean;
  unit?: SpeedUnit;
}

const Speed = ({
  unit,
  speedInKmh,
  precision,
  smallUnits = true
}: SpeedProps) => {
  const defaultUnits = useSpeedByCategory('Default');
  const speedInLocale = speedInKmh ? speed.fromKmh(speedInKmh, unit ?? defaultUnits) : undefined;
  const unitsLabel = speed.label(unit ?? defaultUnits);
  return (
    <Quantity value={speedInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Speed;
