import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({

  previewUnread: {
    borderBottom: theme.border.default,
    backgroundColor: theme.palette.common.lightBlue,
    '& p, & h6': {
      color: theme.palette.primary.main
    },
    '& .MuiAvatar-circular': {
      backgroundColor: theme.palette.primary.main
    },
    '& .timestampText': {
      color: theme.palette.primary.main
    }
  },
  preview: {
    width: '100%',
    borderBottom: theme.border.default,
    backgroundColor: theme.palette.background.default,
    transition: 'all 0.05s',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.01)'
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      '& p, & h6': {
        color: theme.palette.common.white
      },
      '& .MuiAvatar-circular': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main
      },
      '& .timestampText': {
        color: theme.palette.common.white
      }
    }
  },
  previewText: {
    padding: theme.spacing(2, 3),
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '40px 4fr 1fr',
    gridColumnGap: 10
  },
  avatarIcon: {
    gridArea: '1 / 1 / span 2 / 1',
    alignSelf: 'center'
  },
  participantsText: {
    textAlign: 'left',
    gridArea: '1 / 2 / 1 / 2'
  },
  messagePreview: {
    textAlign: 'left',
    fontSize: '.8rem',
    color: theme.palette.common.text,
    gridArea: '2 / 2 / 2 / 2'
  },
  timestampText: {
    fontSize: '.8rem',
    fontWeight: '400',
    textAlign: 'right',
    gridArea: '1 / 3 / span 2 / 3',
    color: theme.palette.common.text,
    alignSelf: 'center'
  }
}));

export default useStyles;
