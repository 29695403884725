import React, { useMemo, ReactNode } from 'react';
import { useUiSettings } from 'hooks/settings/useUiSettings';

type LabelKey = ReduxState['settings']['ui']['assetLabel']

export const useAssetLabelKey = () => useUiSettings().assetLabel;

export const getAssetLabel = (labelKey: LabelKey) => {
  function assetLabel(asset: AssetBasic | undefined, fallback?: never): string | undefined
  function assetLabel<F>(asset: AssetBasic | undefined, fallback: F): string | F
  function assetLabel(asset: AssetBasic | undefined, fallback = undefined) {
    if (asset === undefined) return fallback;
    const label = asset[labelKey] || asset.name;
    if (label) return label;
    return fallback;
  }
  return assetLabel;
};

export const useAssetLabel = () => {
  const labelKey = useAssetLabelKey();
  return useMemo(() => getAssetLabel(labelKey), [labelKey]);
};

const AssetLabel = ({ asset, fallback }: { asset: AssetBasic | undefined, fallback?: ReactNode }): JSX.Element => <>{useAssetLabel()(asset, fallback)}</>;

export default AssetLabel;
