import React, { ReactNode } from 'react';
import { Badge, Typography } from '@mui/material';
import MapIcon from 'components/shared/icons/mapIcon';
import MessagingIcon from 'components/shared/icons/messagingIcon';
import SharingIcon from 'components/shared/icons/sharingIcon';
import FriendsIcon from 'components/shared/icons/friendsIcon';
import SettingsIcon from 'components/shared/icons/settingsIcon';
import ManageIcon from 'components/shared/icons/manageIcon';
import StaffPageIcon from 'components/shared/icons/staffPageIcon';
import TracPlusLabsIcon from 'components/shared/labs/Icon';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavigationButtonIconWrapper, NavigationButtonLink } from './Navigation.styles';

const iconMap = {
  Map: MapIcon,
  Messaging: MessagingIcon,
  Sharing: SharingIcon,
  Friends: FriendsIcon,
  Reporting: AssignmentIcon,
  Settings: SettingsIcon,
  Manage: ManageIcon,
  StaffPage: StaffPageIcon,
  Labs: TracPlusLabsIcon,
};

interface NavigationButtonProps {
  iconName: keyof typeof iconMap,
  text: ReactNode,
  link: string,
  badge?: boolean
  labs?: boolean
}

const NavigationButtonView = ({
  iconName,
  text,
  link,
  badge,
  labs = false,
}: NavigationButtonProps) => {
  const Icon = iconMap[iconName];

  return (
    <NavigationButtonLink to={link || '/'} end={!link} labs={labs}>
      <NavigationButtonIconWrapper>
        <Icon sx={{ width: 43 }} />
        <Badge variant="dot" overlap="circular" invisible={!badge} />
      </NavigationButtonIconWrapper>
      <Typography>{text}</Typography>
    </NavigationButtonLink>
  );
};

export default NavigationButtonView;
