import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import LinkAbove from 'components/shared/linkAbove';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import AssetsList from './assetsList';

const AssetsPage = () => {
  const t = useTranslations('pages.assets');

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <LinkAbove />
          <Box>
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
          </Box>
          <AssetsList />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default AssetsPage;
