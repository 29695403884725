import React from 'react';

interface DiagonalStripsRightProps {
  color: string;
}

export const DiagonalStripsRight = ({ color }: DiagonalStripsRightProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
    <defs>
      <clipPath id="clip-path">
        <path id="SVGID" className="cls-1" d="M0 0h100v100H0z" />
      </clipPath>
      <pattern id="_19-2_diagonal" width="100" height="100" patternUnits="userSpaceOnUse">
        <path fill="none" d="M0 0h100v100H0z" />
        <g opacity=".8" clipPath="url(#clip-path)">
          <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="3px"
            d="M46.97 153.03 153.03 46.97M41.41 147.48 147.48 41.41M35.86 141.92 141.92 35.86M30.3 136.37 136.37 30.3M24.74 130.81 130.81 24.74M19.19 125.26 125.26 19.19M13.63 119.7 119.7 13.63M8.08 114.14 114.14 8.08M2.52 108.59 108.59 2.52M-3.03 103.03 103.03-3.03M-8.59 97.48 97.48-8.59M-14.14 91.92 91.92-14.14M-19.7 86.37 86.37-19.7M-25.26 80.81 80.81-25.26M-30.81 75.26 75.26-30.81M-36.37 69.7 69.7-36.37M-41.92 64.14 64.14-41.92M-47.48 58.59 58.59-47.48M-53.03 53.03 53.03-53.03"
          />
        </g>
      </pattern>
    </defs>
    <g id="Layer_2">
      <path fill="url(#_19-2_diagonal)" d="M0 0h100v100H0z" id="Layer_1-2" />
    </g>
  </svg>
);
