import React from 'react';
import { TextField } from '@mui/material';
import { useTranslations } from 'use-intl';

interface NoteFieldProps {
  value: string
  setValue: (value: string) => void
  disabled?: boolean
}

const NoteField = ({ value, setValue, disabled = false }: NoteFieldProps): JSX.Element => {
  const t = useTranslations('pages.sharing.note');
  return (
    <TextField
      label={t('label')}
      value={value}
      onChange={event => setValue(event.target.value)}
      variant="outlined"
      placeholder={t('placeholder')}
      disabled={disabled}
      spellCheck
    />
  );
};

export default NoteField;
