import React, {
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  Stack,
  TextField,
} from '@mui/material';
import { Group } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { isDefined } from 'utils/type';
import { VirtualizedListbox } from 'components/shared/virtualizedAutocomplete';

export interface OrganisationParticipant {
  organisationId: string
  name: string
}

export interface GroupParticipant {
  groupId: string
  name: string
}

export type Participant = OrganisationParticipant | GroupParticipant;

export interface SelectParticipantsProps {
  selectedOrganisationIds: string[]
  setSelectedParticipantIds: Dispatch<SetStateAction<{ organisationIds: string[], groupIds: string[] }>>
  selectedGroupIds: string[]
  renderCount?: (participant: Participant) => ReactNode
  participants: Participant[]
}

const getKey = (s: OrganisationParticipant | GroupParticipant) => ('groupId' in s ? `group-${s.groupId}` : `organisation-${s.organisationId}`);

const renderTags = (selectedItems: Participant[], getTagProps: AutocompleteRenderGetTagProps) => selectedItems.map((item, index) => (
  <Chip
    {...getTagProps({ index })}
    key={getKey(item)}
    label={item.name}
    icon={'groupId' in item ? <Group /> : undefined}
  />
));

const SelectParticipants = ({
  selectedOrganisationIds,
  setSelectedParticipantIds,
  selectedGroupIds,
  renderCount,
  participants,
}: SelectParticipantsProps) => {
  const t = useTranslations('pages.sharing.selectParticipants');

  const selectedParticipants = useMemo(() => participants.filter(participant => {
    if ('groupId' in participant) return selectedGroupIds.includes(participant.groupId);
    return selectedOrganisationIds.includes(participant.organisationId);
  }), [participants, selectedGroupIds, selectedOrganisationIds]);

  const renderInput = useCallback((params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={t('label')}
      variant="outlined"
      // 😡 to override consequences of our non-standard base font size
      InputProps={{ ...params.InputProps, sx: { input: { height: '23px', lineHeight: '23px' } } }}
      InputLabelProps={{ ...params.InputLabelProps, sx: { lineHeight: '23px' } }}
    />
  ), [t]);

  const renderOption = useCallback((props: HTMLAttributes<HTMLLIElement>, participant: Participant) => (
    <Box {...props} component="li" key={getKey(participant)}>
      <Stack direction="row" spacing={1} flex="1" alignItems="center">
        {'groupId' in participant && <Group />}
        <span>{participant.name}</span>
      </Stack>
      {renderCount && <Box flex="0 0 16ch" textAlign="right">{renderCount(participant)}</Box>}
    </Box>
  ), [renderCount]);

  const onChange = useCallback((_: SyntheticEvent<Element, Event>, value: readonly Participant[]) => {
    setSelectedParticipantIds({
      organisationIds: value.map(s => ('organisationId' in s ? s.organisationId : undefined)).filter(isDefined),
      groupIds: value.map(s => ('groupId' in s ? s.groupId : undefined)).filter(isDefined),
    });
  }, [setSelectedParticipantIds]);

  return (
    <Autocomplete
      options={participants}
      loadingText={t('loading')}
      ListboxComponent={VirtualizedListbox}
      renderInput={renderInput}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionLabel={item => item.name}
      value={selectedParticipants}
      onChange={onChange}
      multiple
      fullWidth
    />
  );
};

export default SelectParticipants;
