import React from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  SvgIcon
} from '@mui/material';
import { useTranslations } from 'use-intl';

const FilterView = ({
  onClick
}) => {
  const t = useTranslations('contextbox.asset.activity.actionButtons');

  return (
    <Box>
      <Tooltip title={t('filterTooltip')}>
        <IconButton onClick={onClick} size="small">
          <SvgIcon viewBox="-4 -3 16 16" fontSize="small">
            <path d="M11.941.264A.5.5,0,0,0,11.5,0H.5A.5.5,0,0,0,.084.777L4,6.651V11.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V6.651L11.916.777A.5.5,0,0,0,11.941.264Z" />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default FilterView;
