import {
  useMutation,
  useQuery,
  useQueryClient, UseQueryOptions,
} from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import {
  getPeople,
  addPerson,
  updatePerson,
  deletePerson,
  addContactToPerson,
  updateContact,
  deleteContact,
  AddContactToPersonRequestBody,
  UpdatePersonBody,
} from './requests';
import { iceContactGroupsQueryKeys } from '../iceContactGroups/queryKeys';
import { peopleQueryKeys } from './queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetPeople = <T = Person[]>(options?: Options<Person[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = peopleQueryKeys.lists(organisationId);
  const query = useQuery<Person[], HttpResponseError, T>({ queryKey, queryFn: () => getPeople(organisationId), ...options });
  return { query, queryKey };
};

export const useMutateNewPerson = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<Person, HttpResponseError, Pick<Person, 'name' | 'role' | 'languageCode'>, { previousPeople?: Person[] }>({
    mutationKey: ['createPerson'],
    mutationFn: value => addPerson(organisationId, value),
    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries({ queryKey });
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        queryClient.setQueryData<Person[]>(queryKey, [...previousPeople, { ...value, id: -1, temporary: true, contacts: [], version: -1 }]);
      }
      return { previousPeople };
    },
    onError: (err, value, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: peopleQueryKeys.all(organisationId) }),
  });
};

export const useMutatePerson = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, UpdatePersonBody, { previousPeople?: Person[] }>({
    mutationKey: ['updatePerson'],
    mutationFn: value => updatePerson(organisationId, value),
    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries({ queryKey });
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        queryClient.setQueryData<Person[]>(queryKey, () => previousPeople.map(p => (
          p.id === value.id ? { ...p, ...value, temporary: true } : p
        )));
      }
      return { previousPeople };
    },
    onError: (_, __, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },
    onSettled: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: peopleQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: iceContactGroupsQueryKeys.health(organisationId) }),
    ]),
  });
};

export const useMutateDeletePerson = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<Person, 'id'>, { previousPeople?: Person[] }>({
    mutationKey: ['deletePerson'],
    mutationFn: value => deletePerson(organisationId, value),
    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries({ queryKey });
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        queryClient.setQueryData<Person[]>(queryKey, () => previousPeople.filter(p => p.id !== value.id));
      }
      return { previousPeople };
    },
    onError: (_, __, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },
    onSettled: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: peopleQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: iceContactGroupsQueryKeys.health(organisationId) }),
    ]),
  });
};

export const useMutateNewContact = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, AddContactToPersonRequestBody, { previousPeople?: Person[] }>({
    mutationKey: ['createContact'],
    mutationFn: value => addContactToPerson(organisationId, value),
    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries({ queryKey });
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        const person = previousPeople.find(p => p.id === value.peopleId);

        if (!person) return { previousPeople };

        const replacement = previousPeople.map(p => {
          if (p.id !== value.peopleId) return p;
          return {
            ...p,
            temporary: true,
            contacts: [
              ...p.contacts.map(c => {
                if (value.isDefault && c.isDefault && c.contactType === value.contactType) {
                  return { ...c, isDefault: false, temporary: true };
                }
                return c;
              }),
              { ...value, id: -1, temporary: true, version: -1 },
            ],
          };
        });
        queryClient.setQueryData<Person[]>(queryKey, () => replacement);
      }
      return { previousPeople };
    },
    onError: (_, __, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },
    onSettled: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: peopleQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: iceContactGroupsQueryKeys.health(organisationId) }),
    ]),
  });
};

export const useMutateUpdateContact = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Contact, { previousPeople?: Person[] }>({
    mutationFn: value => updateContact(organisationId, value),

    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries(queryKey);
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        const person = previousPeople.find(p => p.id === value.peopleId);

        if (!person?.contacts.some(c => c.id === value.id)) return { previousPeople };

        const replacement = previousPeople.map(p => {
          if (p.id !== value.peopleId) return p;
          return {
            ...p,
            temporary: true,
            contacts: p.contacts.map(c => {
              if (c.id === value.id) return { ...value, temporary: true };
              if (value.isDefault && c.isDefault && c.contactType === value.contactType) {
                return { ...c, isDefault: false, temporary: true };
              }
              return c;
            }),
          };
        });
        queryClient.setQueryData<Person[]>(queryKey, () => replacement);
      }
      return { previousPeople };
    },

    onError: (err, value, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },

    onSettled: () => Promise.all([
      queryClient.invalidateQueries(peopleQueryKeys.all(organisationId)),
      queryClient.invalidateQueries(iceContactGroupsQueryKeys.health(organisationId)),
    ])
  });
};

export const useMutateDeleteContact = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation<void, HttpResponseError, Pick<Contact, 'id' | 'peopleId'>, { previousPeople?: Person[] }>({
    mutationFn: value => deleteContact(organisationId, value),

    onMutate: async value => {
      const queryKey = peopleQueryKeys.lists(organisationId);
      await queryClient.cancelQueries(queryKey);
      const previousPeople = queryClient.getQueryData<Person[]>(queryKey);
      if (previousPeople) {
        const person = previousPeople.find(p => p.id === value.peopleId);

        if (!person?.contacts.some(c => c.id === value.id)) return { previousPeople };

        const replacement = previousPeople.map(p => {
          if (p.id !== value.peopleId) return p;
          return {
            ...p,
            temporary: true,
            contacts: p.contacts.filter(c => c.id !== value.id),
          };
        });
        queryClient.setQueryData<Person[]>(queryKey, () => replacement);
      }
      return { previousPeople };
    },

    onError: (err, value, context) => {
      if (context?.previousPeople) {
        queryClient.setQueryData(peopleQueryKeys.lists(organisationId), context.previousPeople);
      }
    },

    onSettled: () => Promise.all([
      queryClient.invalidateQueries(peopleQueryKeys.all(organisationId)),
      queryClient.invalidateQueries(iceContactGroupsQueryKeys.health(organisationId)),
    ])
  });
};
