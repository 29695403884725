import { DateTime } from 'luxon';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DropGroupV1, DropSummary } from './types';

export const getDropsForAssetsV1 = async (organisationId: string, assetIds: number[], from: number, until: number): Promise<DropGroupV1[]> => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/firefighting/groups?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as { dropGroups: DropGroupV1[] };

  return data.dropGroups;
};

export const getDropsForAssets = async (organisationId: string, assetIds: number[], from: number, until: number) => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/v2/organisations/${organisationId}/firefighting/groups?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as DropSummary;

  return data;
};
