/* eslint-disable no-underscore-dangle */
import { IControl, MapInstance } from 'react-map-gl';
import { throttle } from 'lodash/fp';

export class FramerateControl<MapT extends MapInstance = MapInstance> implements IControl<MapT> {
  private _map: MapT | undefined;
  private _container: HTMLDivElement | undefined;
  private _lastTime: number | undefined;
  private _updateContent: ((fps: number) => void) | undefined;
  private _handler = () => this._handleRender();

  onAdd(map: MapT) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl';
    this._container.textContent = 'Hello, world';
    this._map.on('render', this._handler);
    this._updateContent = throttle(1000, (fps: number) => {
      if (this._container) {
        this._container.textContent = `${fps.toFixed(1)} fps`;
      }
    });
    return this._container;
  }

  _handleRender() {
    if (this._lastTime) {
      const timeDifference = performance.now() - this._lastTime;
      const fps = 1000 / timeDifference;
      this._updateContent?.(fps);
    }
    this._lastTime = performance.now();
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this._map?.off('render', this._handler);
    this._map = undefined;
  }
}
