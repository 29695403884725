import React from 'react';

const AT802 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 29.308783,16.290748 c -0.328524,2.477648 -0.370198,3.535898 -0.688333,5.891112 -1.819223,0.203312 -2.256874,0.277691 -5.388377,0.826822 0,0 -13.9969596,0.177975 -21.8090096,0.194821 -0.83422011,1.676744 -0.73936667,8.761819 -0.3395067,8.848038 28.3017603,0.04105 27.1995363,-0.141711 27.2376063,0.605647 0.18861,3.702509 1.25103,10.36984 1.05833,10.86859 -0.10759,0.27848 -7.17762,0.0619 -7.36905,0.1788 -0.19144,0.11685 -0.18394,0.12238 -0.2558,0.76688 -0.0719,0.64449 -0.07,5.753233 0.30695,5.712823 9.082626,-0.04173 10.110981,-0.07366 10.110981,-0.07366 1.108303,0.0051 1.227831,0.01019 9.821659,0.07366 0.37699,0.0404 0.3783,-5.068333 0.30644,-5.712823 -0.0719,-0.6445 -0.0639,-0.65003 -0.25528,-0.76688 -0.19143,-0.11685 -7.26147,0.0997 -7.36906,-0.1788 -0.1927,-0.49876 0.86921,-7.166081 1.05782,-10.86859 0.0381,-0.747359 -1.5928,-0.564597 26.70896,-0.605647 0.39985,-0.08622 1.02388,-7.171294 0.18965,-8.848038 -7.81205,-0.01685 -21.809,-0.194821 -21.809,-0.194821 -3.629386,-0.625801 -3.975621,-0.687465 -5.388907,-0.826822 -0.337338,-1.954586 -0.372719,-3.079011 -0.688323,-5.891112 -0.131511,-0.7459 -1.640412,-5.800594 -2.71611,-6.9060383 -0.984463,1.2346723 -2.693178,6.0535863 -2.72164,6.9060383 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default AT802;
