import airac from 'utils/airac';
import { IntermediaryMapTemplate } from './types';
import { getAttribution, getName } from '../helpers';

const SkyVectorIFRHi = (id: string, lang: string): IntermediaryMapTemplate => ({
  version: 8,
  name: getName(id, lang),
  providerId: 'skyVector',
  sources: {
    'skyVector-ifr-hi': {
      type: 'raster',
      tiles: [
        `${import.meta.env.VITE_SERENITY_TILE_PROXY}/skyvector/hi/${airac}/{z}/{x}/{y}.jpg`
      ],
      // mapbox uses this one :(
      maxzoom: 9,
      minzoom: 3,
      tileSize: 256,
      attribution: getAttribution('skyVector', lang),
    }
  },
  layers: [{
    id: 'ifr-hi',
    type: 'raster',
    source: 'skyVector-ifr-hi'
  }],
  thumbnailUrl: '/img/base-layer-thumbnails/ifr-map-thumbnail.jpg',
  dark: false,
});

export default SkyVectorIFRHi;
