/* eslint-disable */
import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography
} from '@mui/material';
import TracPlusIcon from 'components/shared/icons/tracplusIcon';
import TableOfContents from 'components/shared/tableOfContents';
import useStyles from './terms-styles';

const LegalPage = () => {
  const classes = useStyles();
  const terms = [
    {
      id: '1',
      type: 'section',
      title: 'General Terms, Conditions, and Agreements'
    },
    {
      id: '2',
      type: 'subSection',
      title: 'Agreement'
    },
    {
      id: '3',
      type: 'subSection',
      title: 'Definitions'
    },
    {
      id: '4',
      type: 'subSection',
      title: 'Access and use of TracPlus'
    },
    {
      id: '5',
      type: 'subSection',
      title: 'Software licence; Support'
    },
    {
      id: '6',
      type: 'subSection',
      title: 'Service; Warranties'
    },
    {
      id: '7',
      type: 'subSection',
      title: 'Commencement Date, Term and Termination'
    },
    {
      id: '8',
      type: 'subSection',
      title: 'Ownership and Restrictions; Confidential Information'
    },
    {
      id: '9',
      type: 'subSection',
      title: 'Data'
    },
    {
      id: '10',
      type: 'subSection',
      title: 'Limitation of Liability'
    },
    {
      id: '11',
      type: 'subSection',
      title: 'Charges and Payments'
    },
    {
      id: '12',
      type: 'subSection',
      title: 'Disputes'
    },
    {
      id: '13',
      type: 'subSection',
      title: 'General'
    },
    {
      id: '14',
      type: 'section',
      title: 'Privacy Policy'
    },
    {
      id: '15',
      type: 'section',
      title: 'Anti-Spam Policy'
    },
    {
      id: '16',
      type: 'section',
      title: 'Registered Trademarks'
    },
    {
      id: '17',
      type: 'section',
      title: 'Acknowledgements'
    }
  ];
  return (
    <Box id="scrollSpyWrapper" className={classes.pageWrapper}>
      <Box className={classes.header}>
        <Box className={classes.iconWrapper}>
          <TracPlusIcon />
        </Box>
        <Box className={classes.headerText}>
          <Typography className={classes.h1} variant="h1">TracPlus Global Limited</Typography>
          <Typography variant="body1">Terms and Conditions</Typography>
        </Box>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={9}>
            <Container maxWidth="md">
              <Box className={classes.content}>
                <h2 id="1" className={classes.h2}>A. General Terms, Conditions, and Agreements</h2>
                <ol className={classes.ol}>
                  <li id="2">
                    <h3 className={classes.h3}>Agreement</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>This Agreement and our privacy policy applies when we, TracPlus Global Limited, agree to provide access to you for use of our TracPlus tracking and communications solution, an internet based service that allows you to manage and monitor certain assets and store that data centrally. As part of TracPlus, we may also sell you tracking and communications equipment and arrange for airtime for you (to allow the equipment to operate correctly), and we may provide you with our TracPlus application software. If you do not agree to this Agreement or to our privacy policy, you are not authorised to access and use TracPlus, and you must immediately stop doing so.</p>
                      </li>
                      <li>
                        <p>If the processing of Operational Data is governed by the GDPR, the additional terms in our Data Processing Addendum also form part of this Agreement.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="3">
                    <h3 className={classes.h3}>Definitions</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>References in this Agreement to "we" or "us" or “our” are to TracPlus Global Limited. In registering for TracPlus, you have told us your legal name and your contact details. You confirm these are correct and that, if these change you will advise us as soon as possible. References to "you" refers to you, the customer who has registered to use TracPlus.</p>
                      </li>
                      <li>
                        <p>“Agreement” means this document, our Terms and Conditions, including any updates to these Terms and Conditions that are notified to you from time to time.</p>
                      </li>
                      <li>
                        <p>In this Agreement, the following words are defined as:</p>
                        <ul className={classes.ul}>
                          <li><p><strong>“Data”</strong> means all data, content, and information (including Personal Information) owned, held, used or created by you, or your personnel that is stored using, or inputted into, TracPlus.</p></li>
                          <li><p><strong>“Data Processing Addendum”</strong> means the TracPlus Data Processing Addendum (Operational PI) available at www.tracplus.com/termsandconditions.</p></li>
                          <li><p><strong>"Equipment"</strong> means all devices, hardware, connections, and software involved in the supply of TracPlus or related services that are supplied by or on behalf of TracPlus.</p></li>
                          <li><p><strong>"Fees"</strong> means the sums we charge for set up of TracPlus, including fees for provision of Equipment, and fees we charge for your use of TracPlus or associated support of it. Our fees may include airtime usage fees, fees for messaging, alerts, tracking, voice communications, data, support, user codes, software modification, device fees, late payment fees, implementation fees, third party charges, fees for the use of third party maps, and training fees.</p></li>
                          <li><p><strong>“GDPR”</strong> means the General Data Protection Regulation of the European Union.</p></li>
                          <li><p><strong>"Intellectual Property Rights"</strong> means all rights in TracPlus, and in our website through which you use TracPlus and in the application software and Equipment we provide, together with all intellectual property relating to third party maps, including rights to patents, trademarks, service marks, inventions, copyright, know how whether or not registered, and any related enhancement or modifications.</p></li>
                          <li><p><strong>“NZ”</strong> means New Zealand.</p></li>
                          <li><p><strong>“Objectionable”</strong> includes being objectionable, defamatory, obscene, harassing, threatening, harmful, or unlawful in any way.</p></li>
                          <li><p><strong>“Personnel”</strong> includes officers, employees, contractors and agents, but a reference to your personnel does not include us.</p></li>
                          <li><p><strong>“Personal Information”</strong> means information about an identifiable individual.</p></li>
                          <li><p><strong>"We, our or us"</strong> means TracPlus Global Limited and where the context allows, includes its associated and related companies (as defined in the Companies Act 1993 (NZ)).</p></li>
                          <li><p><strong>"TracPlus"</strong> means the TracPlus tracking and communications solution, an internet based service that allows you to manage and monitor certain assets and store that data centrally. TracPlus includes third party tracking and communications equipment, maps and airtime services (to allow tracking and communications equipment to operate and communicate), and TracPlus application software and Equipment.</p></li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li id="4">
                    <h3 className={classes.h3}>Access and use of TracPlus</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>We agree to provide, and you agree to accept, setup and ongoing access to TracPlus in accordance with the terms of this Agreement.</p>
                      </li>
                      <li>
                        <p>You acknowledge that:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. You are responsible for all computer equipment, hardware, communications channels and links, and internet or other access you require to access and use TracPlus (other than where we agree to provide Equipment to you, or to arrange for airtime for you), and for all charges in relation to those things;</p>
                          </li>
                          <li>
                            <p>b. Your selection of hardware, other equipment and software, communication channels, plans and links, and the quality of the installation and environment that you arrange, will affect your access to TracPlus, the performance of TracPlus, and the quality of the data that you receive from TracPlus. For example, if transmission of data is affected by location of your assets, the data you receive will only be the data that we have received from your assets. Suitability of your selections are your sole responsibility and risk, and we shall not be liable for any loss or damage suffered by you as a result of your selections and quality of installation arranged;</p>
                          </li>
                          <li>
                            <p>c. You must follow all the guidelines, user guides, instructions and manuals we give you for use of TracPlus and the Equipment and services that are used to provide TracPlus to you. We are not responsible for any loss or damage as a direct or indirect result of a failure to follow any such guidelines.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>You must always use TracPlus, associated Equipment and services and the data that TracPlus provides you with in compliance with all relevant laws and regulations in yourareas of use including without limitation applicable laws and regulations relating to the use of any of them for aeronautical, marine, military or other purposes that you may use TracPlus in relation to specifically. For the avoidance of doubt, if you elect to install or otherwise use Equipment that is not certified or approved for the relevant area of use, you are responsible for ascertaining whether the Equipment is suitable for that purpose and that it is installed and used in compliance with all relevant laws and regulations of all relevant authorities in the relevant area of use.</p>
                      </li>
                      <li>
                        <p>You confirm that you understand that TracPlus is intended for business use and that you will be using TracPlus for the purposes of a business. To the maximum extent permissible by law, the provisions of the New Zealand Consumer Guarantees Act 1993 (NZ) therefore do not apply to your use of TracPlus and you agree it is fair and reasonable that we and you are bound by this clause.</p>
                      </li>
                      <li>
                        <p>If TracPlus (including any Equipment) is being offered to you on a trial basis (where we have agreed in writing that Equipment and services are provided to you for an agreed trial period), you agree:</p>
                        <ul className={classes.ul}>
                          <li><p>a. that the terms of this Agreement apply to your use of all such Equipment and services during the trial period;</p></li>
                          <li><p>b. to pay for tracking and communications services supplied during the course of the trial period as invoiced to you;</p></li>
                          <li><p>c. that if you wish to continue using the Equipment and services, you will notify us in writing at least 5 days before the end of the agreed trial period that you wish to continue to use TracPlus beyond the agreed trial period, in which case we will invoice you for the Equipment supplied to you in addition to tracking and voice services already supplied. You agree that the terms of this Agreement will apply to your continued use of all Equipment and services provided by us;</p></li>
                          <li><p>d. that Equipment and services will only be supplied on the return of duly completed and authorised paperwork;</p></li>
                          <li><p>e. that if you do not wish to continue using the Equipment and services, you will notify us in writing at least 5 days before the end of the agreed trial period, and you shall return all Equipment supplied to you by us in the same condition as it was supplied to you (fair wear and tear excepted) by shipping all Equipment back to us (at your expense) within 5 working days of the end of the agreed trial period;</p></li>
                          <li><p>f. that if any Equipment is not returned, or is returned to us at the end of any agreed trial period damaged other than fair wear and tear, that you agree to pay for the reasonable costs of repair or replacement at our discretion, together with any interest and other costs incurred by us as invoiced to you;</p></li>
                          <li><p>g. that if you fail to notify us of whether or not you wish to proceed, we may, at our discretion, withdraw the tracking and communications services or continue to invoice you for further tracking and communication services supplied after the end of the agreed trial period (in addition to the Equipment costs set out in this clause); and</p></li>
                          <li><p>h. that title to all Equipment supplied to you remains our sole property until such time as we have received payment in full in cleared funds for all such Equipment.</p></li>
                        </ul>
                      </li>
                      <li>
                        <p>If TracPlus (including any Equipment) is being offered to you on a loan or support basis (where we have agreed in writing that Equipment and services are provided to you for an agreed loan or support period), you agree:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. that the terms of this Agreement apply to your use of all such Equipment and Services during the loan or support period;</p>
                          </li>
                          <li>
                            <p>b. to pay for tracking and communication services supplied during the course of the loan or support period as invoiced to you;</p>
                          </li>
                          <li>
                            <p>c. that if you wish to continue using the Equipment and services, you will notify us in writing at least 5 days before the end of the agreed loan or support period that you wish to continue to use TracPlus beyond the agreed loan or support period, in which case we will invoice you for the Equipment supplied to you in addition to tracking and communications services already supplied. You agree that the terms of this Agreement will apply to your continued use of all Equipment and services provided by us;</p>
                          </li>
                          <li>
                            <p>d. that Equipment and services will only be supplied on the return of duly completed and authorised paperwork;</p>
                          </li>
                          <li>
                            <p>e. that if you do not wish to continue using the Equipment and Services, you will notify us in writing at least 5 days before the end of the agreed loan or support period, and you shall return all Equipment supplied to you by us in the same condition as it was supplied to you (fair wear and tear excepted) by shipping all Equipment back to us (at your expense) within 5 working days of the end of the agreed support period;</p>
                          </li>
                          <li>
                            <p>f. that if any Equipment is not returned, or is returned to us at the end of any agreed loan or support period damaged other than fair wear and tear, then you agree to pay for the reasonable costs of repair or replacement at our discretion, together with any interest and other costs incurred by us as invoiced to you;</p>
                          </li>
                          <li>
                            <p>g. that if you fail to notify us of whether or not you wish to proceed, we may, at our discretion, withdraw the tracking and communication services or continue to invoice you for further tracking and communication services supplied after the end of the agreed loan or support period (in addition to the equipment costs set out in this clause); and</p>
                          </li>
                          <li>
                            <p>h. that title to all Equipment supplied to you remains our sole property until such time as we have received payment in full in cleared funds for all such Equipment.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>If TracPlus (including any Equipment) is being offered to you on a sponsorship basis (where we have agreed in writing that Equipment and/or services are provided to you for an agreed sponsorship period and agreed sponsorship purpose), you agree:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. that the terms of this Agreement apply to your use of all such Equipment and services during the sponsorship period (except as to payment for tracking and communication services);</p>
                          </li>
                          <li>
                            <p>b. that Equipment and services will only be supplied on the return of duly completed and authorised paperwork;</p>
                          </li>
                          <li>
                            <p>c. to return all Equipment supplied to you by us in the same state of repair as it was supplied to you (fair wear and tear excepted) by shipping all Equipment back to us within 5 working days of the end of the agreed sponsorship period;</p>
                          </li>
                          <li>
                            <p>d. that if any Equipment is not returned, or is returned to us at the end of any agreed sponsorship period damaged other than fair wear and tear, that you agree to pay for the reasonable costs of repair or replacement at our discretion, together with any other costs incurred by us, as invoiced to you; and</p>
                          </li>
                          <li>
                            <p>e. that title to all Equipment supplied to you remains our sole property until such time as we have received payment in full in cleared funds for all such Equipment.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Without limiting any other provisions of this Agreement, maps, images and supporting files used in or relating to TracPlus may not be copied, modified or used for any purpose at all other than for the purposes of using TracPlus. The dataset used for labels for the Geographx maps is a set of delimited text tables that have been derived from the New Zealand Geographic Place Names database, sourced from Land Information New Zealand. Users of this data are advised that New Zealand Place Names were captured for the labelling of text on the 1:50 000 Map Series. The co-ordinates were digitised at the bottom left corner of the text labels that appear on the printed map. The coordinates are not a GPS position for the geographic location of the feature. Neither we nor Land Information New Zealand accepts any responsibility for problems that users encounter due to using this data or the accuracy of its contents.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="5">
                    <h3 className={classes.h3}>Software licence; Support</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>As part of TracPlus, we may provide you with the TracPlus application software. We retain ownership of that software. We licence you to use it only while you use TracPlus pursuant to and during the term of this Agreement and pay the Fees. You must not:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. Sub-licence or otherwise transfer in whole or in part the software to anyone else;</p>
                          </li>
                          <li>
                            <p>b. Copy, modify, alter, translate, vary, decompile, disassemble or reassemble it in any way; or</p>
                          </li>
                          <li>
                            <p>c. Use it for any other purpose other than in conjunction with your use of TracPlus, or use it to resell or otherwise provide TracPlus in any way to any third party.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>We will provide you with support for your use of TracPlus as follows:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. We will provide you with reasonable telephone or email advice (at our discretion) to assist in your use of TracPlus, and may charge fees for such support.</p>
                          </li>
                          <li>
                            <p>b. We may update or upgrade TracPlus (including the application software) with new versions or updates at our discretion. If we require you to, you must install new updates or upgrades and you acknowledge that if you do not do so, your use of TracPlus may be impaired or suspended.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>We may charge you additional fees at our discretion with advance notice to you, at our then current hourly rates for support that is required to be provided as a result of:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. You using new equipment (including any Equipment supplied by or on our behalf);</p>
                          </li>
                          <li>
                            <p>b. You requiring additional configuration or additional access to TracPlus;</p>
                          </li>
                          <li>
                            <p>c. Any unauthorised access, attempted modification, or alteration you make in any way to TracPlus; or</p>
                          </li>
                          <li>
                            <p>d. You failing to install any update.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>We may also charge you at our discretion with advance notice to you, for our reasonable travel and accommodation expenses we incur in visiting any location nominated by you for the purpose of providing support, and where we are making a charge under clause 4.3 above, this may include our travelling time.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="6">
                    <h3 className={classes.h3}>Service; Warranties</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>We warrant that:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. We have the power and authority to allow you to use TracPlus;</p>
                          </li>
                          <li>
                            <p>b. To the best of our knowledge, TracPlus and our database configuration does not infringe any patent, copyright, trade secret or other proprietary right of any third party;</p>
                          </li>
                          <li>
                            <p>c. When we provide services relating to your use of TracPlus to you, we will do so to the standard of care and skill reasonably expected in the provision of such services;</p>
                          </li>
                          <li>
                            <p>d. We will use reasonable commercial endeavours to ensure that:</p>
                            <ul className={classes.ul}>
                              <li>
                                <p>i. TracPlus is available 24 hours a day, 7 days a week, excepting outages for planned updates, new versions, updates and upgrades and routine maintenance; but we do not guarantee this; and</p>
                              </li>
                              <li>
                                <p>ii. the TracPlus server(s) will have an uptime availability of 99.999% excluding scheduled maintenance. You acknowledge that the availability of the Internet, your communication links and your equipment are integral to the availability of TracPlus and will affect your use of it, including your access to the server(s); provided that we shall under no circumstances be responsible for any loss or damage incurred by you or any other person as a result of outages or failures of any aspect of the tracking and communication services which are beyond our reasonable control (including, without limitation, an ISP fault, hardware manufacturing fault, inadequate or inaccurate installation or maintenance, or inadequate, inaccurate or faulty data provided by a third party tracking service provider).</p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>e. If we have agreed to provide a distress monitoring and notification service to you, we will use reasonable endeavours to contact you in the way we have agreed, but we do not guarantee that we will be able to do so. You must notify us immediately if any of the contact numbers you have provided us with change or are unavailable or unattended by English speaking personnel for any reason. We are not responsible for any loss or damage incurred by you as a result of any failure to provide an alert which results from your failure to notify us as required by this clause, or if we are otherwise unable to provide an alert after using reasonable endeavours to do so.</p>
                          </li>
                          <li>
                            <p>f. If we have agreed to provide an automatic monitoring and notification service to you, we will use reasonable endeavours to contact you in the way we have agreed, but we do not guarantee that we will be able to do so. You must notify us immediately if any of the contact numbers you have provided us with change or are unavailable or unattended by English speaking personnel for any reason. We are not responsible for any loss or damage incurred by you as a result of any failure to provide an alert which results from your failure to notify us as required by this clause, or if we are otherwise unable to provide an alert after using reasonable endeavours to do so.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>If you have elected to use the “Notify Only” option for any emergency monitoring and notification service, you are responsible for ensuring that cell phone or page numbers nominated for notification purposes are valid and monitored at all times; you acknowledge that we will take no further action beyond the attempted delivery of notification messages to your nominated contacts.</p>
                      </li>
                      <li>
                        <p>Where we provide devices or other third party Equipment or services (including airtime) to you:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. We will endeavour to give you the benefit of any manufacturers or providers warranty that we receive, to the extent that such warranty is available at the time of sale of the Equipment to you. You must follow any specific instructions we give you in relation to that warranty. This may include returning Equipment to us at your cost (including shipping costs) if it fails to perform in accordance with that warranty;</p>
                          </li>
                          <li>
                            <p>b. We will do our best to meet the timeframes that we indicate to you, but we do not guarantee that we can meet those timeframes and we have no liability should we be unable to do so;</p>
                          </li>
                          <li>
                            <p>c. Title in any Equipment does not pass to you until we have received payment in full for all Equipment;</p>
                          </li>
                          <li>
                            <p>d. In relation to all third party Equipment or services you are given access to pursuant to this Agreement, you agree that you will not</p>
                            <ul className={classes.ul}>
                              <li>
                                <p>i. transfer lease or otherwise dispose of any third party owned property to anyone else (subject to clause 6.2);</p>
                              </li>
                              <li>
                                <p>ii. copy, modify, alter, translate, vary, decompile, disassemble or re-assemble it in any way;</p>
                              </li>
                              <li>
                                <p>iii. use it for any other purpose other than in conjunction with your use of TracPlus, or use it to resell or otherwise provide TracPlus in any way to any third party; and</p>
                              </li>
                              <li>
                                <p>iv. you agree to indemnify us against any claims, damages, and losses (including costs) incurred by us as a result of your breach of any third party property rights.</p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>e. You acknowledge that in relation to third party Equipment, the tampering, removal or damaging of any “warranty void if removed” shall render all warranties relating to that Equipment void.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>In order to receive the benefit of any warranties, you must follow all storage, installation, maintenance and user guides related to installation and use of TracPlus.</p>
                      </li>
                      <li>
                        <p>TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR THE WARRANTIES EXPLICITLY SET OUT IN THESE TERMS, ALL OTHER WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ARE EXPRESSLY EXCLUDED. WE MAKE NO REPRESENTATION CONCERNING THE QUALITY OF THE SERVICE AND DO NOT PROMISE THAT TRACPLUS WILL BE SECURE, FREE OF VIRUSES OR OTHER HARMFUL CODE, UNINTERRUPTED OR ERROR FREE. TO THE EXTENT WARRANTIES CANNOT BE EXCLUDED, LIABILITY FOR THEM IS LIMITED AS SET OUT IN CLAUSE 9.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="7">
                    <h3 className={classes.h3}>Commencement Date, Term and Termination</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>This Agreement commences on the earlier of:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. the date we agree to provide access to TracPlus to you; or</p>
                          </li>
                          <li>
                            <p>b. the date that you order any Equipment from us, and, subject to clause 6.3, continues in force until terminated in accordance with the remainder of this clause.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Either of us may terminate this Agreement as follows:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. By giving the other 30 days’ notice in writing at any time;</p>
                          </li>
                          <li>
                            <p>b. Immediately by notice in writing if the other party breaches any of its obligations under this Agreement and fails to remedy the breach within 14 days after receiving notice requiring it to be remedied including where you fail to pay amounts due within the time prescribed in a reminder notice given by us; or</p>
                          </li>
                          <li>
                            <p>c. Immediately by notice in writing if the other party becomes bankrupt, is put into liquidation or has a receiver, voluntary administrator or statutory manager appointed over any of its assets (or similar), or becomes insolvent or ceases to carry on its business or otherwise makes any composition or arrangement with its creditors (or similar).</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Subject to clauses 10.3 and 12.5, if you sign up for TracPlus on a minimum or fixed term plan:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. the earliest date we may terminate this Agreement under clause 6.2 will be at the expiry of the minimum or fixed term; and</p>
                          </li>
                          <li>
                            <p>b. if you terminate before the expiry of the minimum or fixed term, you must pay us an early termination fee equal to 50% of all Fees that would have otherwise been payable until the expiry of the minimum or fixed term. You acknowledge that this early termination fee represents a genuine pre-estimate of the loss that we will suffer should you terminate this Agreement without cause prior to the end of the expiry of the minimum or fixed term, and is not a penalty.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>If any amounts due and payable by you to us or to anyone else involved in providing TracPlus to you remains unpaid we may immediately and without further notice disable or suspend your ability to use TracPlus pending payment or remedy.</p>
                      </li>
                      <li>
                        <p>Termination or expiry of this Agreement does not affect either party’s rights and obligations that accrued before that termination or expiry.</p>
                      </li>
                      <li>
                        <p>On termination or expiry of this Agreement:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. You must either return or destroy all documentation related to TracPlus; and</p>
                          </li>
                          <li>
                            <p>b. You must immediately cease using the application software we provided you and you must delete it and all copies of it from any system or machine or other media that you use or have installed it on or stored any copies of it on;</p>
                          </li>
                          <li>
                            <p>c. pay all Fees for your use of TracPlus or Equipment provided, prior to that termination, plus any early termination fee payable under clause 6.3a;</p>
                          </li>
                          <li>
                            <p>d. We will transfer copies of your data from our server(s) to you in the standard format at the time which we usually transfer such data provided that:</p>
                            <ul className={classes.ul}>
                              <li>
                                <p>i. You pay our reasonable costs and expenses on a time and materials basis; and</p>
                              </li>
                              <li>
                                <p>ii. You have paid all sums due to us under this Agreement.</p>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>We do not warrant that the format of the Data will be compatible with any software.</p>
                      </li>
                      <li>
                        <p>Without limiting any other right or remedy available to us, we may restrict or suspend your access to TracPlus and/or delete, edit or remove the relevant Data if we consider that you (including any of your personnel) has:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. undermined, or attempted to undermine, the security or integrity of TracPlus or any Equipment;</p>
                          </li>
                          <li>
                            <p>b. used, or attempted to use, TracPlus:</p>
                            <ul className={classes.ul}>
                              <li>
                                <p>i. for improper purposes; or</p>
                              </li>
                              <li>
                                <p>ii. in a manner, other than for normal operational purposes, that materially reduces the operational performance of TracPlus;</p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>c. transmitted, inputted or stored any Data that breaches or may breach this Agreement or any third party right (including Intellectual Property Rights and privacy rights), or that is or may be Objectionable, incorrect or misleading; or</p>
                          </li>
                          <li>
                            <p>d. otherwise materially breaches this Agreement.</p>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li id="8">
                    <h3 className={classes.h3}>Ownership and Restrictions; Confidential Information</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>You understand that the Intellectual Property Rights in TracPlus, the software, maps and services we use to provide TracPlus, the website and servers associated with TracPlus (other than relating to Data or information which is input from your assets as a result of your use of the solution) belong to us or to third parties who provide equipment or services to us, and you will not assert or dispute ownership in them.</p>
                      </li>
                      <li>
                        <p>You must use TracPlus as directed by us from time to time. Your right to use it is nonexclusive, and non-transferable. You must not:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. Attempt to bypass any security mechanism in place on any system we use to make TracPlus available to you or in TracPlus itself;</p>
                          </li>
                          <li>
                            <p>b. Gain or attempt to gain unauthorised access to TracPlus, our systems, or any other customers' systems, information or data;</p>
                          </li>
                          <li>
                            <p>c. Resell or sublicence TracPlus or any part thereof. For the avoidance of doubt, the provisions of this Agreement do not prohibit you from leasing to or allowing a third party to use a vehicle of any kind that includes or has affixed to it any Equipment for your use of TracPlus. You agree that you will remain personally bound by this Agreement in the event you lease such a vehicle including any Equipment to a third party.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>Access to TracPlus is by way of usercode and/or password. You are responsible for the use and safekeeping of user codes allocated to you and all associated passwords. You are responsible for all use or access of TracPlus by anyone using your user codes, and for payment of Fees arising from that use. You must change your password if we request you to do so, and we may change any of your user codes at any time if we elect to do so. You must contact us immediately if you suspect any unauthorised use or disclosure of any passwords or if you would like any user codes changed. We may charge you a reasonable fee for change of user codes.</p>
                      </li>
                      <li>
                        <p>You are responsible for all use of the Equipment associated with your access to TracPlus and for payment of all charges associated with use of the Equipment and Data generated by them.</p>
                      </li>
                      <li>
                        <p>You must not attempt to make any copy of TracPlus or any part of it except as we explicitly authorise as part of this Agreement or elsewhere in writing. You must not share your access (including without limitation the passwords you use to access) to TracPlus with any third party, except with our prior consent. You must tell us immediately if you become aware of any circumstances that cause you to believe any person may have unauthorised knowledge, possession or use of TracPlus. </p>
                      </li>
                      <li>
                        <p>We acknowledge that the Data or information uploaded from your use of TracPlus is your confidential information. We will not knowingly disclose such information unless the information is already in the public domain, we reasonably believe we are required to do so by law (this may include for example if anyone has a warrant to access any of the Data or other information) or such disclosure is to persons that are otherwise bound by obligations of confidentiality. If you have given consent or permission for your Data to be made available to a third party website or gateway service, you acknowledge that we have no responsibility for the use of your Data via such a website or service by visitors or users thereof. However, if you are using TracPlus as part of a publicly hosted site, you acknowledge that your Data is publicly available, and that we have no responsibility for the use the public make of the Data. In addition, you authorise us to disclose your Data or information to the police, government agencies in NZ or overseas where we reasonably believe that the disclosure will assist us to comply with any law, rules or regulations in NZ or overseas or will assist in the investigation, detection and/or prevention of fraud, money laundering or other criminal offences.</p>
                      </li>
                      <li>
                        <p>You will not make media or other public statements about your use of TracPlus without our prior written consent.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="9">
                    <h3 className={classes.h3}>Data</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>You acknowledge that:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. we may require access to the Data to exercise our rights and perform our obligations under this Agreement; and</p>
                          </li>
                          <li>
                            <p>b. to the extent that is necessary but subject to clause 7.6, we may authorised a member or members of our personnel to access the Data for this purpose.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>You must arrange all consents and approvals that are necessary for us to access the Data as described in clause 8.1a. and 8.1b.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="10">
                    <h3 className={classes.h3}>Limitation of Liability</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>We will not be liable to you, whether in tort, contract or otherwise, for any indirect loss of any kind including loss of profits, special, incidental, consequential or punitive damages (including without limitation, loss of use, loss of data, loss of anticipated savings, loss of goodwill) however caused, arising out of or in connection with your use of TracPlus or this Agreement.</p>
                      </li>
                      <li>
                        <p>To the fullest extent permitted by law, our liability to you arising out of all claims for damages under this Agreement:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. In respect of Equipment under warranty is limited to replacement of the Equipment or refund of the price paid;</p>
                          </li>
                          <li>
                            <p>b. In all other cases, will not exceed 50% of the Fees paid by you and attributable to TracPlus services (excluding device fees, airtime and other third party charges) during the year preceding the date of your claim, but limited in aggregate to 100% of the Fees in any one year.</p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>We are not in default or liable to you by reason of any failure or delay in performing our obligations under this Agreement caused by any act or event beyond our reasonable control. In the event of such an event, we will use reasonable commercial endeavours to resume our obligations under this Agreement as soon as reasonably possible. Without limitation, the technology that is used to provide TracPlus may be subject to intermittent outages outside our reasonable control; such as weather conditions or failure of third party networks or equipment.</p>
                      </li>
                      <li>
                        <p>All claims by you under this Agreement must be made within 12 months of the cause of action arising.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="11">
                    <h3 className={classes.h3}>Charges and Payments</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>You agree to pay our Fees plus GST or other applicable State and/or Federal taxes and/or fees (if any) for use of TracPlus and for any ancillary services. You agree to keep us informed of your correct country of tax residency and to advise us immediately of any changes to your tax residency status</p>
                      </li>
                      <li>
                        <p>Our Fees are calculated in US dollars and converted to your nominated billing currency. If you wish to change your nominated billing currency you must inform us in writing no later than 7 days before the end of the current billing period, otherwise such notification will be effective from the next billing period. We will clearly identify the currency used in all calculations and the exchange rate used, including the source of the exchange rate.</p>
                      </li>
                      <li>
                        <p>Except where you are currently signed up to a minimum or fixed term, we reserve the right to change our Fees from time to time. Any change to Fees takes effect 30 days from the date we notify you of them. If you do not wish to pay the increased Fees, you may terminate this Agreement on no less than 10 days’ notice, provided the notice is received by us before the effective date of the Fee increase. If you do not terminate this Agreement in accordance with this clause, you are deemed to have accepted the increased Fees.</p>
                      </li>
                      <li>
                        <p>We will send you invoices setting out our Fees. You must pay us in full and without deduction or set off within 14 days of the date of invoice.</p>
                      </li>
                      <li>
                        <p>If we provide services such as implementation or training for you, we will tell you how much those services will cost, and you must pay us our charges for them, plus GST and/or other applicable State and/or Federal taxes (if any).</p>
                      </li>
                      <li>
                        <p>If any duties including shipping, import duties, taxes or other levies ("Duties") are imposed in relation to the provision of TracPlus or on any Equipment or services we provide to you, you must either pay those Duties or, where such Duties have met by us, pay us those Duties in addition to the Fees. We will send you a bill for any such Duties.</p>
                      </li>
                      <li>
                        <p>If you elect to pay our charges by bank draft, transfer, credit card, debit card or by such means where fees or commissions may be charged by any third party or parties, we reserve the right to recover all such fees or commissions from you.</p>
                      </li>
                      <li>
                        <p>If you do not pay an invoice or bill by the due date for payment, we may charge you interest on the unpaid amount from the due date for payment until the date you pay it; the interest rate will be 2% per month.</p>
                      </li>
                      <li>
                        <p>If your invoice remains unpaid after the due date for payment, we will send a reminder to your last notified contact address. If your invoice still remains unpaid 30 days after the reminder notice is sent, then without prejudice to our ability to suspend provision of services, we may withdraw the provision of tracking and communication services at any time thereafter by giving final written notice not less than 24 hours prior to the time of withdrawal of tracking and communication services.</p>
                      </li>
                      <li>
                        <p>If we agree, at our absolute discretion, to re-supply or continue to supply tracking and communication services, we may, in addition to any other rights or remedies we may have, require either full payment of all outstanding monies owing to us, or agreement on a payment arrangement satisfactory to us (which may include prepayment or security), prior to any such re-supply or continuation of supply.</p>
                      </li>
                      <li>
                        <p>We may use personal information you provide to us for the purposes of collecting any money owed by you and to obtain a credit report about you from time to time, or to appoint a debt collection agency if required. The credit Information gathered by the credit reporter (as defined in the Credit Reporting Privacy Code 2004 (NZ)) may be used by that credit reporter to generate subsequent credit reports and be given to their other customers for the purposes of procuring credit reporting services. If you default on any obligation then information about the default may (without prejudice to any other remedies) be provided to credit reporters.</p>
                      </li>
                      <li>
                        <p>If the Equipment is located in New Zealand:</p>
                        <ul className={classes.ul}>
                          <li>
                            <p>a. You grant a Security Interest (as defined in the Personal Property Securities Act 1999 (NZ), the "PPSA") to us in all Equipment supplied to you by us to secure all monies owed to us by you, and to secure all other obligations you owe us under this Agreement and any other agreement we may have entered into with you.</p>
                          </li>
                          <li>
                            <p>b. You agree that we may register our Security Interest under the Personal Property Securities Register (or any other chattel register of any kind in the jurisdiction where the assets may be located) at our discretion, and you agree to take all steps reasonably required by us and to promptly execute any documents and provide any information necessary to facilitate registration.</p>
                          </li>
                          <li>
                            <p>c. You agree that nothing in sections 133 and 134 of the PPSA will apply to this Agreement, and you waive your rights under sections 114(1)(a), 116, 120(2), 121 and 148 of the PPSA, and you waive your right to receive a copy of any verification statement as provided by the PPSA in all circumstances. If the Equipment is located out of NZ you agree to supply us with any additional details reasonably necessary for us to register our security interest in the Equipment in that jurisdiction.</p>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li id="12">
                    <h3 className={classes.h3}>Disputes</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>If a dispute arises out of this Agreement, a party may not commence court or arbitration proceedings unless it has complied with the remainder of this clause.</p>
                      </li>
                      <li>
                        <p>A party claiming a dispute has arisen must give written notice to the other party specifying the nature of the dispute. On receipt of that notice, the parties will use all reasonable endeavours to resolve the dispute by discussion, consultation, negotiation or other informal means.</p>
                      </li>
                      <li>
                        <p>If the dispute is not resolved within 15 business days of notice being given, either party may commence proceedings against the other party.</p>
                      </li>
                      <li>
                        <p>Nothing in this clause prevents a party seeking urgent interlocutory relief.</p>
                      </li>
                    </ol>
                  </li>
                  <li id="13">
                    <h3 className={classes.h3}>General</h3>
                    <ol className={classes.ol}>
                      <li>
                        <p>We may give you notice by email, post or by fax to the address you gave us when you registered. Any communication by email or fax is deemed to be received when transmitted to the correct email or fax address of the recipient. Communication in writing is deemed to be received 3 days after posting, or when left at the specified address.</p>
                      </li>
                      <li>
                        <p>NEW ZEALAND LAW GOVERNS THIS AGREEMENT. NEW ZEALAND COURTS HAVE NON- EXCLUSIVE JURISDICTION OVER THIS AGREEMENT. IF YOU WISH TO BRING A CLAIM AGAINST US, YOU MUST DO SO IN A NEW ZEALAND COURT.</p>
                      </li>
                      <li>
                        <p>This Agreement constitutes the entire agreement between us in relation to TracPlus.</p>
                      </li>
                      <li>
                        <p>We may change this Agreement from time to time by giving you notice of the change by email or by posting a notice on our website. Changes are effective from the date we notify you that they apply from. You are responsible for ensuring you are familiar with the latest Agreement. By continuing to access and use TracPlus from the date on which this Agreement is changed, you agree to be bound by the changed Agreement.</p>
                      </li>
                      <li>
                        <p>If a change to this Agreement has a materially adverse effect on you, you may terminate this Agreement on no less than 10 days’ notice, provided you give such notice within 30 days of the date we notify you of the change. If you do not terminate this Agreement in accordance with this clause, you are deemed to have accepted the changed Agreement. If you terminate this Agreement in accordance with this clause 12.5, you will not be required to pay the applicable early termination fee (if any). In any other case, if you terminate during any minimum or fixed term, you must pay the applicable early termination fee (if any). This clause 12.5 does not apply where, in our reasonable opinion, the change to this Agreement is required to give effect to, or as a result of, a change in law.</p>
                      </li>
                      <li>
                        <p>You may not assign or transfer this Agreement, or your access or use of TracPlus, or any of your other rights and obligations under this Agreement without our prior written consent. We may transfer our rights and obligations under this Agreement on giving notice in writing to you.</p>
                      </li>
                      <li>
                        <p>The provisions of clauses 5.4, 6, 7, 9 and this clause 12 will survive termination or expiry of this Agreement.</p>
                      </li>
                      <li>
                        <p>If any provision of this Agreement is invalid or unenforceable the remaining provisions of this Agreement are not affected and continue in full force.</p>
                      </li>
                      <li>
                        <p>Any unlawful provision in this contract may be severed, and the remaining provisions remain enforceable.</p>
                      </li>
                      <li>
                        <p>The word "including" or "for example" or any examples given in this agreement do not have a limiting effect.</p>
                      </li>
                      <li>
                        <p>No delay or failure to act is a waiver of any other or any subsequent breach. The failure of a party to enforce a provision of this Agreement is not to be interpreted as a waiver of that provision. No waiver is effective unless it is in writing.</p>
                      </li>
                      <li>
                        <p>The singular includes the plural and vice versa.</p>
                      </li>
                      <li>
                        <p>If the customer comprises more than one person, the liability of such persons shall be joint and several.</p>
                      </li>
                      <li>
                        <p>We will not be liable for any act, omission, or failure to fulfil our obligations under this Agreement if such act, omission or failure arises from any cause reasonably beyond our reasonable control including strikes, lockouts, riots, acts of war or terrorism, epidemics, government action superimposed after the date of this Agreement, fire, communication line failures, power failures, earthquakes or other disasters (“Force Majeure”). If we are unable to fulfil our obligations due to a Force Majeure we shall immediately notify you in writing and provide full information concerning the Force Majeure event including an estimate of the time likely to be required to overcome the event, use feasible commercial endeavours to overcome the event and minimise the loss to you and continue to perform our obligations as far as practicable.</p>
                      </li>
                    </ol>
                  </li>
                </ol>
                <Box id="14">
                  <h2 className={classes.h2}>B. Privacy Policy</h2>
                  <p>Your Data belongs to you. We are the authorised custodian of that Data, and we make every reasonable effort to protect and ensure the safety and security of that Data, and to make sure that the only people who see your Data do so with your explicit consent (subject to where we reasonably believe that the disclosure will assist us to comply with any law, rules or regulations in NZ or overseas or will assist in the investigation, detection and/or prevention of fraud, money laundering or other criminal offences).</p>
                  <p>Our privacy policy at www.tracplus.com/termsandconditions governs information that we hold about you for marketing and account management purposes.</p>
                  <h3 className={classes.h3}>The Privacy Act 1993 (NZ)</h3>
                  <p>The Privacy Act applies to us in our role as a service provider. In compliance with the Privacy Act we hold certain information about our customers and users. This information is required for our lawful purposes and is used in the functioning of our business which may also include data analysis to identify particular products and services that may be of interest to you.</p>
                  <h3 className={classes.h3}>Acess Requests</h3>
                  <p>You can make a request orally or in writing for access to personal information held by us and request correction of your personal information in accordance with the Privacy Act. A fee may be charged. You can request a copy of your information by emailing us at support@tracplus.com.</p>
                  <h2 className={classes.h2}>C. Anti-Spam Policy</h2>
                  <p>We comply with the Unsolicited Electronic Messages Act 2007 (NZ). We will not send you unsolicited information without your consent nor shall we assist anyone else to. You may choose whether to receive promotional messages from us by notifying us in writing or by email, or by any other electronic means that we may choose to offer you. Emails regarding service availability or issues relating to your ability to use our services will be sent to the email address advised by you during the registration process. You agree that such emails are not considered to be unsolicited under the terms of the above Act.</p>
                </Box>
                <Box id="15">
                  <h2 className={classes.h2}>D. Registered Trademarks, Copyright Notices and Credits</h2>
                  <ul className={classes.ul}>
                    <li><p>TracPlus™ and the TracPlus logo are registered trademarks of TracPlus Global Limited, NZ.</p></li>
                    <li><p>GoogleEarth™ and GoogleMaps™ are trademarks of Google™, Inc, United States of America.</p></li>
                    <li><p>TerraColor® is a registered trademark of Earthstar Geographics LLC, United States of America.</p></li>
                    <li><p>Geographx maps are Copyright © Geographx Limited, NZ, and are distributed under licence.</p></li>
                    <li><p>Airways VNC maps are Copyright © Airways Corporation Limited, NZ, and are distributed under licence.</p></li>
                    <li><p>LINZ 202 maps are Copyright © Land Information New Zealand, NZ, and are distributed under licence.</p></li>
                    <li><p>Corax maps are Copyright © Ollivier & Co. Limited, and are distributed under licence.</p></li>
                    <li><p>TerraColor imagery is Copyright © Earthstar Geographics LLC, and is used under licence.</p></li>
                    <li><p>Blue Marble maps are courtesy of NASA's Earth Observatory.</p></li>
                    <li><p>LIMA Maps are courtesy of the Landsat Image Mosaic of Antarctica (LIMA) Project.</p></li>
                  </ul>
                </Box>
                <Box id="16">
                  <h2 className={classes.h2}>E. Acknowledgements</h2>
                  <p>Acknowledgement is given to the following people and organisations:</p>
                  <ul className={classes.ul}>
                    <li><p>Land Information New Zealand</p></li>
                    <li><p>The United States Geological Survey</p></li>
                    <li><p>The Intergovernmental Committee on Surveying and Mapping</p></li>
                    <li><p>The National Geophysical Data Centre</p></li>
                    <li><p>David Graves</p></li>
                    <li><p>The Consultative Group on International Agricultural Research</p></li>
                    <li><p>geonames.org</p></li>
                    <li><p>SkyVector.com</p></li>
                    <li><p>GeoBase</p></li>
                    <li><p>Openstreetmap.org</p></li>
                  </ul>
                  <p>These terms and conditions are intended to convey a benefit to and be enforceable by each of them and any related parties of ours (where relevant) under the Contracts (Privity) Act 1982 (NZ).</p>
                </Box>
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TableOfContents
              rootElId="#scrollSpyWrapper"
              categories={terms}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LegalPage;
