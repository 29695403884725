import { HttpResponseError, serenityFetch } from 'helpers/api';

export const getInvites = async (organisationId: string): Promise<Invite[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/invites?status=Pending`, null);
  HttpResponseError.detect(response);
  return (await response.json()).invites;
};

export const getInvite = async (inviteId: string): Promise<Invite> => {
  const response = await serenityFetch('GET', `/invites/${inviteId}`, null, false);
  HttpResponseError.detect(response);
  return response.json();
};

export const inviteUser = async (organisationId: string, userName: string, userEmail: string, role: MembershipRole): Promise<Invite | null> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/invites`, {
    name: userName,
    email: userEmail,
    role
  });
  HttpResponseError.detect(response);

  if (response.status === 201) {
    return response.json();
  }

  return null;
};

export const acceptInvite = async (inviteId: string, newUserId: string): Promise<void> => {
  const response = await serenityFetch('PATCH', `/invites/${inviteId}`, {
    status: 'Accepted',
    newUserId
  }, false);
  HttpResponseError.detect(response);
};

export const cancelInvite = async (inviteId: string): Promise<void> => {
  const response = await serenityFetch('PATCH', `/invites/${inviteId}`, {
    status: 'Cancelled'
  });
  HttpResponseError.detect(response);
};
