import React, { ReactNode, useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { Settings, updateSetting } from 'slices/settings.slice';
import { useTranslations } from 'use-intl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  variant: 'menu',
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  }
} as const;

interface Column {
  id: string
  label: ReactNode
  always?: boolean
}

interface TableColumnsPickerProps {
  columns: Column[]
  settingsCategory: keyof Settings
}

type ColumnsState = Record<string, boolean>;

const TableColumnsPicker = ({ columns, settingsCategory }: TableColumnsPickerProps) => {
  const t = useTranslations('components.TableColumnsPicker');
  const dispatch = useAppDispatch();
  const selectedColumns = useSelector<ReduxState, ColumnsState>(state => state.settings[settingsCategory]?.columns ?? {});
  const setColumns = useCallback((value: ColumnsState) => dispatch(updateSetting({ category: settingsCategory, field: 'columns', value })), [dispatch, settingsCategory]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    // On autofill we get a stringified value.
    const ids = typeof value === 'string' ? value.split(',') : value;
    const state = columns.reduce<ColumnsState>((acc, c) => ({ ...acc, [c.id]: c.always || ids.includes(c.id) }), {});
    setColumns(state);
  };

  return (
    <FormControl sx={{ width: 150 }}>
      <Select
        multiple
        displayEmpty
        value={columns.filter(column => column.always || selectedColumns[column.id]).map(c => c.id)}
        onChange={handleChange}
        inputProps={{ 'aria-label': t('columns') }}
        renderValue={value => t('columnCount', { n: value.length })}
        MenuProps={MenuProps}
      >
        {columns.map(column => (
          <MenuItem key={column.id} value={column.id} disabled={column.always}>
            <Checkbox checked={column.always || selectedColumns[column.id]} />
            <ListItemText primary={column.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TableColumnsPicker;
