import React, { ReactNode, useCallback, useMemo } from 'react';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Group } from '@mui/icons-material';
import { sortBy } from 'lodash/fp';
import { GroupFriend, OrganisationFriend } from 'apis/rest/friends/types';
import { useGetFriendGroupsList, useGetFriendOrganisationsList } from 'apis/rest/friends/hooks';
import useOrganisationId from 'hooks/session/useOrganisationId';

const getFriendName = (friend: OrganisationFriend | GroupFriend) => ('groupName' in friend ? friend.groupName : friend.organisationName);

export type FriendValue = { groupId: string } | { organisationId: string } | undefined;

interface SelectFriendProps {
  label: ReactNode
  value: FriendValue
  setValue: (value: OrganisationFriend | GroupFriend | undefined) => void
  disabled?: boolean
}

const filterOptions = createFilterOptions<OrganisationFriend | GroupFriend>({ stringify: getFriendName });

const SelectFriend = ({ label, value, setValue, disabled = false }: SelectFriendProps) => {
  const organisationId = useOrganisationId();
  const friendsQuery = useGetFriendOrganisationsList({
    select: useCallback((friends: OrganisationFriend[]) => friends.filter(friend => friend.organisationId !== organisationId), [organisationId]),
  });
  const groupsQuery = useGetFriendGroupsList();

  const friends = useMemo(
    () => sortBy(friend => getFriendName(friend).toLowerCase(), [...(friendsQuery.data ?? []), ...(groupsQuery.data ?? [])]),
    [friendsQuery.data, groupsQuery.data],
  );

  const selectedFriend = friends.find(friend => {
    if (!value) return false;
    if ('groupId' in value) return 'groupId' in friend && value.groupId === friend.groupId;
    return 'organisationId' in friend && value.organisationId === friend.organisationId;
  }) ?? null;

  return (
    <Autocomplete
      loading={friendsQuery.isLoading || groupsQuery.isLoading}
      loadingText="Loading organisations and groups"
      options={friends}
      value={selectedFriend}
      getOptionLabel={getFriendName}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
      renderOption={(props, friend) => (
        <Box {...props} component="li" key={friend.pubKey}>
          {'groupName' in friend ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Group />
              <Typography>{friend.groupName}</Typography>
            </Stack>
          ) : (
            <Typography>{friend.organisationName}</Typography>
          )}
        </Box>
      )}
      filterOptions={filterOptions}
      onChange={(_, friend) => {
        if (friend === null) return setValue(undefined);
        return setValue(friend);
      }}
      disabled={disabled}
      fullWidth
    />
  );
};

export default SelectFriend;
