import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(3),
    '& > div': {
      borderRadius: 3,
      backgroundColor: theme.palette.background.inputBackground
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  helperText: {
    fontWeight: '500',
    fontSize: '.8rem',
    textAlign: 'left',
    marginTop: theme.spacing(1),
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  errorMessage: {
    color: 'red',
    textAlign: 'left'
  }
}));
