import React from 'react';
import clsx from 'clsx';

import { Box, Typography } from '@mui/material';
import useStyles from './module-styles';

const CBModule = ({
  children,
  headerText = '',
  customHeader = false,
  className = '',
  contentClassname = '',
  noHeader = false
}) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      <>
        {noHeader || customHeader || (
          <Box className={classes.moduleHeader}>
            <Typography className={classes.moduleHeaderText}>{headerText}</Typography>
          </Box>
        )}
        <Box className={clsx(classes.moduleContent, contentClassname)}>
          {children}
        </Box>
      </>
    </Box>
  );
};

export default CBModule;
