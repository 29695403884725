import { useContext, useEffect, useMemo, useState } from 'react';
import { filter } from 'rxjs';
import { uniqBy } from 'lodash/fp';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { InferredEventId, ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import { useCurrentInferredEventsForReport, useCurrentInferredEventsForReports } from 'apis/rest/inferredEvents/hooks';
import { InferredEventsEvent } from './index';
import { InferredEventsRepositoryContext } from './context';

export const useInferredEventsRepository = () => {
  const repo = useContext(InferredEventsRepositoryContext);
  if (repo === undefined) throw new Error('useInferredEventsRepository must be used within a InferredEventsRepositoryContext.Provider');
  return repo;
};

export const useAssetsInferredEventsByReportId = (assets: { id: number }[], enabled: boolean | undefined) => {
  const eventsRepo = useInferredEventsRepository();
  const assetIds = useMemo(() => assets.map(a => a.id), [assets]);
  const [events, setEvents] = useState<Record<number, Record<number, InferredEventId[]>> | undefined>([]);

  useEffect(() => {
    if (!enabled || assetIds.length === 0) {
      setEvents(undefined);
      return () => undefined;
    }

    setEvents(eventsRepo.getEventsForAssetsByReportId(assetIds));

    const subscription = eventsRepo.subject
      .pipe(filter<InferredEventsEvent>(e => assetIds.some(assetId => e.assetIds.includes(assetId))))
      .subscribe(() => setEvents(eventsRepo.getEventsForAssetsByReportId(assetIds)));

    return () => subscription.unsubscribe();
  }, [assetIds, enabled, eventsRepo]);

  return events;
};

export const useAssetInferredEventsByReportId = (assetId: number | undefined, enabled: boolean | undefined) => {
  const eventsRepo = useInferredEventsRepository();
  const [events, setEvents] = useState<Record<number, InferredEventId[]> | undefined>([]);

  useEffect(() => {
    if (!enabled || assetId === undefined) {
      setEvents(undefined);
      return () => undefined;
    }

    setEvents(eventsRepo.getEventsForAssetByReportId(assetId));

    const subscription = eventsRepo.subject
      .pipe(filter<InferredEventsEvent>(e => e.assetIds.includes(assetId)))
      .subscribe(() => setEvents(eventsRepo.getEventsForAssetByReportId(assetId)));

    return () => subscription.unsubscribe();
  }, [assetId, enabled, eventsRepo]);

  return events;
};

export const useInferredEventsForReports = (reports: Report[], enabled: boolean | undefined): ReportWithInferredEvents[] | undefined => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const enabledLive = useFeatureFlag('frontendInferredEventsLiveUpdates');

  const assetId = useMemo(() => {
    const first = reports.at(0)?.assetId;
    if (reports.some(r => r.assetId !== first)) {
      console.error(`attempted to get inferred events for asset ${first}, but provided reports for ${uniqBy('assetId', reports)}`);
      return undefined;
    }

    if (!first || !featureAssets.hasAssetId(first)) {
      return undefined;
    }

    return first;
  }, [featureAssets, reports]);

  // TODO: remove when live update FF is retired
  const reportsFromQuery = useCurrentInferredEventsForReports(reports, { enabled: enabled && enabledLive === false });

  const events = useAssetInferredEventsByReportId(assetId, enabled && enabledLive);
  return useMemo(() => {
    if (!enabledLive) {
      return reportsFromQuery;
    }

    if (!enabled || assetId === undefined) {
      return reports.map(report => ({
        ...report,
        inferredEvents: null,
      }));
    }

    return reports.map(report => ({
      ...report,
      inferredEvents: events?.[report.id] ?? null,
    }));
  }, [assetId, enabled, enabledLive, events, reports, reportsFromQuery]);
};

export const useInferredEventsForReport = (report: Report, enabled: boolean | undefined): ReportWithInferredEvents => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const enabledLive = useFeatureFlag('frontendInferredEventsLiveUpdates');

  // TODO: remove when live update FF is retired
  const reportFromQuery = useCurrentInferredEventsForReport(report, { enabled: enabled && enabledLive === false });

  const events = useAssetInferredEventsByReportId(report.assetId, enabled);
  return useMemo(() => {
    if (!enabledLive) {
      return reportFromQuery;
    }

    if (!enabled || !featureAssets.hasAssetId(report.assetId)) {
      return ({
        ...report,
        inferredEvents: null,
      });
    }

    return ({
      ...report,
      inferredEvents: events?.[report.id] ?? null,
    });
  }, [enabled, enabledLive, events, featureAssets, report, reportFromQuery]);
};
