import React, { Fragment, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Box, BoxProps, Button, ButtonGroup, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import orderBy from 'lodash/fp/orderBy';
import max from 'lodash/fp/max';
import { scaleLinear } from 'd3';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import { useTranslations } from 'use-intl';
import useVolume from 'hooks/units/useVolume';
import { useSelector } from 'react-redux';
import { selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import { DropGroup, DropCluster, Fill, FillCluster, DropSummary } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';
import GeocodeH3Index from './GeocodeH3Index';
import { FirefightingSelection } from './types';

const sumVolume = sumBy<DropGroup>('volumeDroppedLitres');
const sumFillVolume = sumBy<Fill>('volumeFilledLitres');

const defaultGuid = '00000000-0000-0000-0000-000000000000';

interface DropsByAssetScorecardProps extends PaperOwnProps {
  assetIds: number[]
  data: DropSummary,
  grouping: 'asset' | 'incident' | 'source'
  trips: TripBasic[]
  visibleSuppressants: Record<Suppressant, boolean>
  setDropFilter: React.Dispatch<React.SetStateAction<Partial<DropGroup>>>
  setSelection: React.Dispatch<SetStateAction<FirefightingSelection | undefined>>
}

interface SuppressantBarProps {
  events: DropGroup[] | Fill[]
  visibleSuppressants: Record<Suppressant, boolean>
  getWidth: (drops: DropGroup[]) => string
}

const SuppressantBar = ({ events, visibleSuppressants, getWidth }: SuppressantBarProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const suppressantColor = useSuppressantColors();

  const bySuppressant = events.reduce<Record<string, DropGroup[] | Fill[]>>((acc, drop) => {
    if (!acc[drop.suppressant]?.push(drop)) acc[drop.suppressant] = [drop];
    return acc;
  }, {});

  const items = (Object.keys(visibleSuppressants) as Suppressant[])
    .map(suppressant => ({ suppressant, drops: bySuppressant[suppressant] ?? [] }));

  const itemsWithDrops = items.filter(item => item.drops.length);

  return (
    <Tooltip hidden={!itemsWithDrops.length} title={(
      <Stack display="grid" gridTemplateColumns="max-content max-content max-content max-content" columnGap={1} rowGap={2} my={1}>
        {itemsWithDrops.filter(item => item.drops.length).map(item => (
          <Fragment key={item.suppressant}>
            <Box bgcolor={suppressantColor[item.suppressant]} width="1rem" height="1rem" borderRadius="50%" />
            <Box>{t(`suppressant.${item.suppressant}`)}</Box>
            {'flight' in item.drops[0] ? (
              <>
                <Box>{t('nDrops', { n: item.drops.length })}</Box>
                <Box>{sumVolume(item.drops)?.toFixed(0) ?? 0} L</Box>
              </>
            ) : (
              <>
                <Box>{t('nFills', { n: item.drops.length })}</Box>
                <Box>{sumFillVolume(item.drops)?.toFixed(0) ?? 0} L</Box>
              </>
            )}
          </Fragment>
        ))}
      </Stack>
    )}>
      <Stack
        direction="row"
        bgcolor="common.lightGrey"
        borderRadius="4px"
        border={theme => theme.border.default}
        overflow="hidden"
        mb={0.5}
        sx={{ transition: 'opacity 300ms', '&:hover': { opacity: 0.7 } }}
      >
        {items.map(item => (
          <Box
            key={item.suppressant}
            height="1.5rem"
            width={getWidth(item.drops)}
            sx={{ transition: 'width 300ms' }}
            bgcolor={suppressantColor[item.suppressant]}
          />
        ))}
      </Stack>
    </Tooltip>
  );
};

const isDropGroups = (evts: DropGroup[] | Fill[]): evts is DropGroup[] => 'flight' in evts[0]

const GroupedDropsScorecard = ({ assetIds, data, grouping, sx, visibleSuppressants, setDropFilter, setSelection, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();
  const [metric, setMetric] = useState<'volume' | 'count'>('count');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;

  const { dropGroups } = data;

  const allDropsByAssetId = useMemo(() => dropGroups.reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups]);

  const dropsByAssetId = useMemo(() => dropGroups.filter(dg => visibleSuppressants[dg.suppressant]).reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups, visibleSuppressants]);

  const assetsWithNoDrops = useMemo(() => assetIds.filter(assetId => !allDropsByAssetId[assetId]), [allDropsByAssetId, assetIds]);

  const getWidth = useMemo(() => {
    if (metric === 'volume') {
      const maxVolume = grouping === 'asset'
        ? (max(Object.values(dropsByAssetId).map(sumVolume)) ?? 1)
        : (grouping === 'source'
          ? max(data.fillClusters.map(f => f.volumeFilledLitres))
          : max(data.dropClusters.map(f => f.volumeDroppedLitres))
        );
      const scale = scaleLinear([0, maxVolume ?? 1], [0, 100]);
      return (evts: DropGroup[] | Fill[]) => {
        if (evts.length === 0 || !visibleSuppressants[evts[0].suppressant]) { return '0%'; }
        if (isDropGroups(evts)) {
          return `${scale(sumVolume(evts) ?? 0)}%`;
        }
        return `${scale(sumFillVolume(evts) ?? 0)}%`;
      };
    }

    const maxCount = grouping === 'asset'
      ? (max(Object.values(dropsByAssetId).map(dgs => dgs.flatMap(dg => dg.drops).length)) ?? 1)
      : (grouping === 'source'
        ? max(data.fillClusters.map(f => f.fillCount))
        : max(data.dropClusters.map(f => f.dropCount))
      );
    const scale = scaleLinear([0, maxCount ?? 1], [0, 100]);
    return (evts: DropGroup[] | Fill[]) => {
      if (evts.length === 0 || !visibleSuppressants[evts[0].suppressant]) { return '0%'; }
      if (isDropGroups(evts)) {
        return `${scale(evts.flatMap(dg => dg.drops).length)}%`;
      }
      return `${scale(evts.length)}%`;
    };
  }, [metric, dropsByAssetId, grouping, data.fillClusters, data.dropClusters, visibleSuppressants]);

  const assetNoDropsDisabled = useSelector(selectAssetsNoDrops);
  const relevantAssets = useMemo(() => assetsQuery.data?.filter(asset => {
    if (!assetNoDropsDisabled) {
      return assetIds.includes(asset.id);
    }
    return assetIds.includes(asset.id) && !assetsWithNoDrops.includes(asset.id);
  }), [assetsWithNoDrops, assetIds, assetNoDropsDisabled, assetsQuery.data]);

  const [hoveredId, setHoveredId] = useState<number | string>();

  const onHoverAsset = useCallback((id: number) => () => {
    setDropFilter({ assetId: id });
    setHoveredId(id);
  }, [setDropFilter, setHoveredId]);

  const onHoverIncident = useCallback((id: string) => () => {
    setDropFilter({ clusterId: id });
    setHoveredId(id);
  }, [setDropFilter, setHoveredId]);

  const onHoverSource = useCallback((id: string) => () => {
    // TODO: Fix this!
    setDropFilter({ fills: { clusterId: id } });
    setHoveredId(id);
  }, [setDropFilter, setHoveredId]);

  const onUnHover = useMemo(() => () => {
    setDropFilter({});
    setHoveredId(undefined);
  }, [setDropFilter, setHoveredId]);

  const onSelectIncident = useCallback((id: string) => () => {
    setSelection(['incident', id]);
  }, [setSelection]);

  const onSelectSource = useCallback((id: string) => () => {
    setSelection(['source', id]);
  }, [setSelection]);

  const onSelectAsset = useCallback((id: number) => () => {
    setSelection(['asset', id]);
  }, [setSelection]);

  const lastAsset = relevantAssets?.at(-1);

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" justifyContent="space-between" p={3} alignItems="center">
        <Typography fontSize="1.5rem" variant="h2">Drops by {grouping}</Typography>
        <ButtonGroup variant="outlined">
          <Button onClick={() => setMetric('count')} variant={metric === 'count' ? 'contained' : undefined}>{t('dropCount')}</Button>
          <Button onClick={() => setMetric('volume')} variant={metric === 'volume' ? 'contained' : undefined}>{t('volume')}</Button>
        </ButtonGroup>
      </Stack>
      <Divider />
      <Box sx={{ maxHeight: 'inherit', overflowY: 'scroll', overflowX: 'hidden', position: 'relative', mr: -2 }}>
        <Box display="grid" gridTemplateColumns="max-content 1fr">
          {grouping === 'asset'
            ? ((relevantAssets?.length ?? 0) > 0 ? relevantAssets?.map(asset => {
              const assetDrops = dropsByAssetId[asset.id] ?? [];

              const boxProps: Partial<BoxProps> = {
                onMouseLeave: onUnHover,
                onMouseEnter: onHoverAsset(asset.id),
                onClick: onSelectAsset(asset.id),
                sx: theme => ({
                  '&:hover': {
                    cursor: 'pointer'
                  },
                  transition: 'background-color 100ms',
                  backgroundColor: asset.id === hoveredId ? theme.palette.common.lightGrey : 'transparent',
                }),
              };

              return (
                <Fragment key={asset.id}>
                  <Box gridColumn="span 2" {...boxProps} py={1} px={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AssetColourMarker assetId={asset.id} />
                      <Typography fontWeight="bold" fontSize="1.2rem" lineHeight="1.5rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"><AssetLabel asset={asset} /></Typography>
                      <Typography fontSize="1rem" flex="1" textAlign="end">{asset.make} {asset.model}</Typography>
                    </Stack>
                  </Box>
                  <Box {...boxProps} pl={2}>
                    <Typography fontSize="1rem" minWidth="10ch" textAlign="left">{metric === 'volume' ? volume.create(sumVolume(assetDrops)).format() : t('nDrops', { n: assetDrops.length })}</Typography>
                  </Box>
                  <Box {...boxProps} pr={2} pb={1}>
                    <SuppressantBar events={assetDrops} getWidth={getWidth} visibleSuppressants={visibleSuppressants} />
                  </Box>
                  {asset !== lastAsset && <Divider sx={{ gridColumn: '1 / -1' }} />}
                </Fragment>
              );
            }) : <p>No assets</p>)
            : (grouping === 'source'
              ? (orderBy((f: FillCluster) => f.fillCount, 'desc')(data.fillClusters).filter(c => c.id !== defaultGuid).map(cluster => {
                const fills = data.dropGroups.flatMap(dg => dg.fills.filter(f => f.clusterId === cluster.id));

                const boxProps: Partial<BoxProps> = {
                  onMouseLeave: onUnHover,
                  onMouseEnter: onHoverSource(cluster.id),
                  onClick: onSelectSource(cluster.id),
                  sx: theme => ({
                    '&:hover': {
                      cursor: 'pointer'
                    },
                    transition: 'background-color 100ms',
                    backgroundColor: cluster.id === hoveredId ? theme.palette.common.lightGrey : 'transparent',
                  }),
                };

                return (
                  <Fragment key={cluster.id}>
                    <Box gridColumn="span 2" py={1} px={2} {...boxProps}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight="bold" fontSize="1.2rem" lineHeight="1.5rem" overflow="hidden" textAlign="start">
                          {cluster.bodyOfWater ?? <GeocodeH3Index index={cluster.h3Index} />}
                        </Typography>
                        <Typography fontSize="1rem" flex="1" whiteSpace="nowrap" textAlign="end">{cluster.assetIds.length} asset(s)</Typography>
                      </Stack>
                    </Box>
                    <Box pl={2} {...boxProps}>
                      <Typography fontSize="1rem" minWidth="10ch" textAlign="left">{metric === 'volume' ? volume.create(cluster.volumeFilledLitres ?? 0).format() : t('nFills', { n: cluster.fillCount })}</Typography>
                    </Box>
                    <Box pr={2} pb={1} {...boxProps}>
                      <SuppressantBar events={fills} getWidth={getWidth} visibleSuppressants={visibleSuppressants} />
                    </Box>
                    <Divider sx={{ gridColumn: '1 / -1' }} />
                  </Fragment>
                );
              }))
              : (orderBy((f: DropCluster) => f.dropCount, 'desc')(data.dropClusters).filter(c => c.id !== defaultGuid).map(cluster => {
                const drops = data.dropGroups.filter(dg => dg.clusterId === cluster.id);

                const boxProps: Partial<BoxProps> = {
                  onMouseLeave: onUnHover,
                  onMouseEnter: onHoverIncident(cluster.id),
                  onClick: onSelectIncident(cluster.id),
                  sx: theme => ({
                    '&:hover': {
                      cursor: 'pointer'
                    },
                    transition: 'background-color 100ms',
                    backgroundColor: cluster.id === hoveredId ? theme.palette.common.lightGrey : 'transparent',
                  }),
                };

                return (
                  <Fragment key={cluster.id}>
                    <Box gridColumn="span 2" {...boxProps} py={1} px={2}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight="bold" fontSize="1.2rem" lineHeight="1.5rem" overflow="hidden" textAlign="start">
                          {cluster.placeName ?? <GeocodeH3Index index={cluster.h3Index} />}
                        </Typography>
                        <Typography fontSize="1rem" flex="1" whiteSpace="nowrap" textAlign="end">{cluster.assetIds.length} asset(s)</Typography>
                      </Stack>
                    </Box>
                    <Box pl={2} {...boxProps}>
                      <Typography fontSize="1rem" minWidth="10ch" textAlign="left">{metric === 'volume' ? volume.create(cluster.volumeDroppedLitres ?? 0).format() : t('nDrops', { n: cluster.dropCount })}</Typography>
                    </Box>
                    <Box pr={2} pb={1} {...boxProps}>
                      <SuppressantBar events={drops} getWidth={getWidth} visibleSuppressants={visibleSuppressants} />
                    </Box>
                    <Divider sx={{ gridColumn: '1 / -1' }} />
                  </Fragment>
                );
              }))
            )}
        </Box>
      </Box>
    </Paper>
  );
};

export default GroupedDropsScorecard;
