import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  chip: {
    textTransform: 'uppercase',
    fontWeight: '500',
    marginRight: '6px',
    '&.active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.omnibox.highlight,
      '&:hover': {
        backgroundColor: theme.palette.omnibox.highlight
      },
      '&:focus': {
        backgroundColor: theme.palette.omnibox.highlight
      }
    }
  }
}));
