import React from 'react';
import { Box, Fade, Paper, Popper, PopperProps, Stack, Typography } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';
import { formatDateTime } from 'utils/time';
import { EngineUsage } from 'apis/rest/engineUsage/types';
import useDuration from 'hooks/units/useDuration';

interface TripPopperProps {
  engineUsage: EngineUsage | undefined
  anchorEl: PopperProps['anchorEl']
  timezone: string
}

const TripPopper = ({ engineUsage, anchorEl, timezone }: TripPopperProps) => {
  const duration = useDuration();
  return (
    <Popper
      open={!!engineUsage && !!anchorEl}
      anchorEl={anchorEl}
      transition
    >
      {({ TransitionProps }) => (engineUsage ? (
        <Fade {...TransitionProps} timeout={300}>
          <Paper elevation={8} sx={{ m: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="max-content 1fr"
              gridTemplateRows="max-content 1fr 1fr"
              gridTemplateAreas="'header header' 'line from' 'line duration' 'line to'"
              rowGap={1}
              columnGap={1}
              p={2}
            >
              <Typography variant="h4" gridArea="header">Engine cycle</Typography>
              <Stack gridArea="line">
                <PlayArrow />
                <Box alignSelf="center" flex="1" borderLeft="2px dashed #999" />
                <Stop />
              </Stack>
              <Typography alignSelf="start" gridArea="from">{formatDateTime(engineUsage.startTime, timezone, true)}</Typography>
              <Typography alignSelf="center" gridArea="duration">Duration: {duration.fromMillis(engineUsage.duration)}</Typography>
              <Typography alignSelf="start" gridArea="to">{formatDateTime(engineUsage.endTime, timezone, true)}</Typography>
            </Box>
          </Paper>
        </Fade>
      ) : null)}
    </Popper>
  );
};

export default TripPopper;
