import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Stack,
  Paper,
  Typography,
  SvgIcon,
  Link,
} from '@mui/material';
import { excludeForwardProps } from 'utils/style';

export const SettingsMenuPageWrapper = styled(Stack)(({ theme }) => `
  background-color: ${theme.palette.common.lightGrey};
  flex: 1;
  overflow-y: auto;
  text-align: left;
`);
SettingsMenuPageWrapper.defaultProps = {
  pt: 8,
  pb: 8,
};

export const SettingsMenuPanel = styled(Paper)(({ theme }) => `
  padding: ${theme.spacing(3, 4)};
  margin: ${theme.spacing(2, 0, 6)};
  box-shadow: none;
  border: ${theme.border.default};
`);

export const SettingsMenuLink = styled(Link)(({ theme }) => `
  position: relative;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  text-decoration: none;
  padding: ${theme.spacing(2, 3)};
  display: flex;
  flex: 1;
  cursor: pointer;
  align-items: center;
  transition: all 0.15s;
  
  :focus, :hover {
    background-color: ${theme.palette.common.lightGrey};
  }
`);

export const SettingsMenuIconWrapper = styled(Box)(({ theme }) => `
  padding: ${theme.spacing(2)};
  border-radius: 30px;
  transition: all 0.15s;
  color: ${theme.palette.common.text};
  background-color: ${theme.palette.common.white};
`);

export const SettingsMenuSvgIcon = styled(SvgIcon)(({ theme }) => `
  display: block;
  font-size: 1.8rem;
  line-height: 1;
  transition: all 0.15s;
`);

export const SettingsMenuDetails = styled(Box)(({ theme }) => `
  margin-left: ${theme.spacing(2)};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`);

export const SettingsMenuDetailsTitle = styled(Typography)(({ theme }) => `
  color: ${theme.palette.primary.main};
  font-size: 1rem;
  line-height: 2;
  font-weight: 500;
  textDecoration: none;
`);

export const SettingsMenuDetailsDescription = styled(Typography)(({ theme }) => `
  font-size: 0.9rem;
`);

export const SettingsMenuContainerStyled = styled(Container, {
  shouldForwardProp: excludeForwardProps(['labs']),
})<{ labs: boolean }>(({ theme, labs }) => (labs ? `
  & ${SettingsMenuPanel} {
    border-color: ${theme.palette.labs.main};
  }
  & ${SettingsMenuDetailsTitle} {
    color: ${theme.palette.labs.main};
  }
` : ''));

export const SettingsMenuDisabledLink = styled(Box)(({ theme }) => `
  position: relative;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  text-decoration: none;
  padding: ${theme.spacing(2, 3)};
  display: flex;
  flex: 1;
  align-items: center;
  & ${SettingsMenuDetailsTitle} {
    color: ${theme.palette.common.disabled} !important;
  }
  & ${SettingsMenuDetailsDescription} {
    color: ${theme.palette.common.disabled} !important;
  }
  & ${SettingsMenuIconWrapper} {
    color: ${theme.palette.common.disabled} !important;
  }
`);
