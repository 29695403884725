import React, { useMemo } from 'react';
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import AssetColourMarker from 'components/shared/assetColourMarker';
import SelectSerialType from 'components/shared/selectSerialType';
import AssetLabel from 'components/shared/assetLabel';
import useSerialType from 'hooks/settings/useSerialType';
import useGroupAssetsAndDevices from 'hooks/contactGroup/useGroupAssetsAndDevices';

interface ContactGroupAssetsTableProps {
  group: ContactGroup
  noEndBorder?: boolean
}

export const ContactGroupAssetsTable = ({ group, noEndBorder }: ContactGroupAssetsTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupAssetsTable');
  const items = useGroupAssetsAndDevices(group);
  const serialType = useSerialType();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('columns.asset')}</TableCell>
            <TableCell>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {t('columns.device')}
                <SelectSerialType
                  size="small"
                  InputProps={{ sx: { width: '15rem' } }}
                />
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items ? items.map(({ assetId, asset, device }, index) => {
            const border = noEndBorder && index === items.length - 1 ? 0 : undefined;
            return (
              <TableRow key={assetId}>
                <TableCell sx={{ border }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {asset && (
                      <>
                        <AssetColourMarker assetId={asset.id} />
                        <Stack>
                          <Typography fontStyle={asset.archived ? 'italic' : 'normal'}><AssetLabel asset={asset} /></Typography>
                          <Typography variant="body3">{asset.make} {asset.model} {asset.variant}</Typography>
                        </Stack>
                      </>
                    )}
                    {!asset && <Typography fontStyle="italic">{t('unknownAsset')}</Typography>}
                  </Stack>
                </TableCell>
                <TableCell sx={{ border }}>
                  {device && (
                    <Stack>
                      <Typography>{device.make} {device.model}</Typography>
                      <Typography>{device[serialType]}</Typography>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            );
          }) : group.deviceAndAssetIds.map(({ assetId }, index) => {
            const border = noEndBorder && index === group.deviceAndAssetIds.length - 1 ? 0 : undefined;
            return (
              // Render skeleton rows while loading assets
              <TableRow key={assetId}>
                <TableCell sx={{ border }}>
                  <Skeleton>
                    <Typography>Placeholder for asset name</Typography>
                    <Typography variant="body3">Placeholder for asset make/model/variant</Typography>
                  </Skeleton>
                </TableCell>
                <TableCell sx={{ border }}>
                  <Skeleton>
                    <Typography>Placeholder for device make/model</Typography>
                    <Typography>Placeholder for device serial</Typography>
                  </Skeleton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
