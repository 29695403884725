import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslations } from 'use-intl';
import { MaterialTableProps } from '@material-table/core';
import { Typography, Box, Stack, Chip, Tooltip } from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import LimitedList from 'components/shared/LimitedList';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useAssetsNotificationsStatus } from './helpers';

export interface AssetGroupTableProps {
  allAssetGroups?: AssetGroup[],
  selectedAssetGroupIds: number[],
  isLoading: boolean,
  readonly: boolean,
  OnChange?: (assetGroupIds: number[]) => void,
  onRowClick?: (assetGroupId: number) => void,
}

export const AssetGroupTable = ({
  allAssetGroups,
  selectedAssetGroupIds,
  isLoading,
  onRowClick
}: AssetGroupTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.assetsSection');
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);
  const assetLabel = useAssetLabel();
  const getAssetsStatus = useAssetsNotificationsStatus();

  const rowData: AssetGroup[] = useMemo(
    () => allAssetGroups?.filter(ag => selectedAssetGroupIds.some(x => x === ag.id)) ?? [],
    [allAssetGroups, selectedAssetGroupIds]
  );

  const columns = useMemo<MaterialTableProps<AssetGroup>['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (
        <Typography>{row.name}</Typography>
      )
    },
    {
      title: t('columns.assets'),
      field: 'assets',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      render: row => {
        const assetsStatus = getAssetsStatus(row.assets);

        if (assetsStatus.loading) return null;

        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            {assetsStatus.enabled.length > 0 && (
              <LimitedList<string>
                items={assetsStatus.enabled.map(a => assetLabel(a)).filter((s): s is string => !!s)}
                limit={1}
                renderMore={({ items, children }) => (
                  <Tooltip title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}>
                    <Chip label={children} variant="outlined" />
                  </Tooltip>
                )}
              />
            )}
            {!assetsStatus.enabled.length && (
              <Chip
                icon={<ErrorOutlined />}
                label={t('columns.noAssetsEnabled', { n: assetsStatus.disabled.length })}
                variant="outlined"
                color="error"
              />
            )}
            {assetsStatus.disabled.length > 0 && (
              <Tooltip title={(
                <>
                  <div>{t('tooltips.assetsDisabled')}:</div>
                  {assetsStatus.disabled.map(a => assetLabel(a)).filter((s): s is string => !!s).map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}
                </>
              )}>
                <Chip
                  icon={<ErrorOutlined />}
                  label={t('columns.nAssetsDisabled', { n: assetsStatus.disabled.length })}
                  variant="outlined"
                  color="warning"
                />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ]), [t, assetLabel, getAssetsStatus]);

  return (
    <>
      <PersistentTable<AssetGroup>
        settingsCategory="eventNotitficationGroupsTable"
        data={rowData}
        isLoading={isLoading}
        columns={columns}
        components={{
          Pagination,
          Container: Box,
          Toolbar: () => null
        }}
        options={{
          search: false,
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        onRowClick={onRowClick ? (_, row) => onRowClick?.(row?.id ?? 0) : undefined}
        sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }} />
      <Box ref={footerRef} bottom={0} sx={{
        '& .MuiToolbar-regular': { padding: 10 / 3 },
        margin: '0 !important'
      }} />
    </>
  );
};
