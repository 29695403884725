// Custom fragment shader for PulseIconLayer
export default `\
#version 300 es
#define SHADER_NAME pulse-fragment-shader

precision highp float;

uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;
uniform float time;
uniform float speedModifier;

in float vColorMode;
in vec4 vColor;
in vec2 vTextureCoords;
in vec2 uv;

out vec4 fragColor;

void main(void) {
  geometry.uv = uv;
  vec4 texColor = texture(iconsTexture, vTextureCoords);
  // Take the global opacity and the alpha from vColor into account for the alpha component
  float a = texColor.r;// * opacity * vColor.a;

  float t = mod(time / (1000.0*speedModifier), 3.1415926535897932384626433832795); // To keep Nvidia drivers happy, keep t in the range of PI
  float w = pow(sin(t), (16.0*speedModifier));
  float z = pow(sin(t+0.01), (16.0*speedModifier));

  if (z < w) {
    if (a < alphaCutoff) {
      discard;
    }
    float o = ((1.0-a)*pow(z,2.0)) * opacity;
    if ((a-0.02) < alphaCutoff)
      o *= 0.5;
    fragColor = vec4(vColor.rgb, o);
  } else {
    a -= (0.8-(w*0.8));

    if (a < alphaCutoff) {
      discard;
    }
    float o = (1.0-texColor.r) * opacity;
    if ((a-0.02) < alphaCutoff)
      o *= 0.5;
    fragColor = vec4(vColor.rgb, o);
  }
}

`;
