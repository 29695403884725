import { styled } from '@mui/material/styles';
import { DialogTitle } from '@mui/material';

export const TPDialogTitle = styled(DialogTitle)(({ theme }) => `
  padding: ${theme.spacing(3)};
  border-bottom: 1px solid;
  border-color: ${theme.palette.common.midGrey};
`);

export default TPDialogTitle;
