import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { PathStyleExtension, PathStyleExtensionProps } from '@deck.gl/extensions';
import { IconLayer, LineLayer } from '@deck.gl/layers';
import PointOfInterest from 'components/shared/icons/eventIcons/pointOfInterest';
import { useCursor } from 'contexts/cursor/useCursor';
import { Coord } from 'repositories/reports/spline';
import AnimatedDashPathLayer from './animatedPathLayer';

const ICONSIZE = 128;
const ICON = {
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<PointOfInterest size={64} />))}`,
  height: ICONSIZE,
  width: ICONSIZE,
  id: 0,
  anchorX: ICONSIZE / 2,
  anchorY: ICONSIZE / 2,
  mask: false
};

const WHITE: ColorAlpha = [255, 255, 255, 255];
const BLACK: ColorAlpha = [0, 0, 0, 255];
const HALF_WHITE: ColorAlpha = [255, 255, 255, 153];
const HALF_BLACK: ColorAlpha = [0, 0, 0, 153];

interface LineDataItem {
  path: Coord[],
  color: ColorAlpha,
  width: number
}

const useMeasureToolLayers = (
  visible: boolean,
  selected: boolean,
  report: Pick<Report, 'longitude' | 'latitude' | 'altitude'> | undefined,
  measurementMarker: { lng: number, lat: number } | undefined,
  use3d = false
) => {
  const [cursorPosition] = useCursor();

  const markerIcons = useMemo(() => [{
    id: 0,
    size: 5,
    angle: 0,
    icon: ICON,
    position: measurementMarker ? [measurementMarker.lng, measurementMarker.lat, use3d ? report?.altitude ?? 0 : 0] : undefined,
  }], [measurementMarker, report?.altitude, use3d]);

  const lineData = useMemo<LineDataItem[]>(() => {
    const path: Coord[] = [];
    if (report && measurementMarker) {
      path.push(
        [report.longitude, report.latitude, use3d ? report.altitude : 0],
        [measurementMarker.lng, measurementMarker.lat, use3d ? report.altitude : 0],
      );
    }

    const cursorPath: Coord[] = [];
    if (report && selected && cursorPosition) {
      cursorPath.push(
        [report.longitude, report.latitude, use3d ? report.altitude : 0],
        [cursorPosition.longitude, cursorPosition.latitude, use3d ? report.altitude : 0]
      );
    }

    return [
      { path: cursorPath, color: HALF_BLACK, width: 4 },
      { path: cursorPath, color: HALF_WHITE, width: 2 },
      { path, color: BLACK, width: 4 },
      { path, color: WHITE, width: 2 },
    ];
  }, [report, measurementMarker, selected, cursorPosition, use3d]);

  const verticalLineData = useMemo(() => {
    if (!use3d) {
      return [];
    }
    const paths: LineDataItem[] = [];
    if (report && selected && cursorPosition) {
      const path: Coord[] = [
        [cursorPosition.longitude, cursorPosition.latitude, report.altitude],
        [cursorPosition.longitude, cursorPosition.latitude, 0],
      ];
      paths.push(
        { path, color: HALF_BLACK, width: 4 },
        { path, color: HALF_WHITE, width: 2 }
      );
    }

    if (report && measurementMarker) {
      const path: Coord[] = [
        [measurementMarker.lng, measurementMarker.lat, report.altitude],
        [measurementMarker.lng, measurementMarker.lat, 0],
      ];
      paths.push(
        { path, color: BLACK, width: 4 },
        { path, color: WHITE, width: 2 }
      );
    }
    return paths;
  }, [use3d, report, selected, cursorPosition, measurementMarker]);

  const pathLayer = useMemo(() => new AnimatedDashPathLayer<LineDataItem, PathStyleExtensionProps<LineDataItem>>({
    id: `measure-line-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: lineData,
    pickable: false,
    getColor: d => d.color,
    getWidth: d => d.width,
    capRounded: true,
    widthUnits: 'pixels',
    getDashArray: d => [30 / d.width, 18 / d.width],
    extensions: [new PathStyleExtension({ dash: true })],
    parameters: { depthTest: false },
  }), [use3d, visible, lineData]);

  const verticalLineLayer = useMemo(() => new LineLayer<LineDataItem>({
    id: `measure-vertical-line-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: verticalLineData,
    getSourcePosition: d => d.path[0],
    getTargetPosition: d => d.path[1],
    pickable: false,
    getColor: d => d.color,
    getWidth: d => d.width,
    widthUnits: 'pixels',
  }), [use3d, visible, verticalLineData]);

  const iconLayer = useMemo(() => new IconLayer({
    id: `measure-marker-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: markerIcons,
    pickable: false,
    sizeScale: 1,
    getSize: 20,
    getAngle: 0,
    parameters: { depthTest: false },
  }), [use3d, visible, markerIcons]);

  return [pathLayer, verticalLineLayer, iconLayer];
};

export default useMeasureToolLayers;
