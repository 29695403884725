import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  mapWrapper: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.background.default
  }
}));
