import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  moduleHeader: {
    padding: theme.spacing(3, 1, 1, 3),
    '& .MuiTouchRipple-root': {
      display: 'none',
    }
  },
  iconWrapper: {
    textAlign: 'right',
  },
  icon: {
    cursor: 'pointer',
    fontSize: '1rem',
    '& path': {
      fill: theme.palette.common.text
    },
    '&.active': {
      '& path': {
        fill: theme.palette.primary.main
      }
    }
  },
  moduleHeaderText: {
    textAlign: 'left',
    color: theme.palette.common.text,
    fontSize: '.9rem',
    fontWeight: '500'
  },
  moduleContent: {
    borderBottom: theme.border.default,
    padding: theme.spacing(0, 3, 3, 3)
  },
  actionIconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& *:not(:last-child)': {
      marginRight: '4px'
    }
  }
}));
