import { altitude } from 'helpers/unitsOfMeasure';
import { useCallback } from 'react';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

const defaultFormatter = (d: Altitude, precision: number) => `${Number.parseFloat(d.unitValue.toFixed(precision))} ${d.unitLabel}`;

type AltitudeUnit = ReduxState['unitSettings']['units']['altitude'];

class Altitude {
  private meters: number;
  readonly unit: AltitudeUnit;
  readonly unitValue: number;
  readonly unitLabel: string;

  constructor(meters: number, unit: AltitudeUnit) {
    this.meters = meters;
    this.unit = unit;
    this.unitValue = altitude.fromSI(meters, unit);
    this.unitLabel = altitude.label(unit);
  }

  format<T = string>(fn?: (d: Altitude, precision: number) => T, precision = 0) {
    return (fn ?? defaultFormatter)(this, precision);
  }
}

export const useAltitude = () => {
  const unit = useUnitSettings().altitude;
  const unitLabel = altitude.label(unit);
  const create = useCallback((meters: number) => new Altitude(meters, unit), [unit]);
  return { create, unit, unitLabel };
};
