import Smart from 'hocs/smart';
import { eventFilterSelector } from 'selectors/events';
import View from './significantReport-view';

export default Smart(
  View,
  state => ({
    reportFilter: eventFilterSelector(state),
    selectedMapId: state.map.selectedMapId
  }),
  _ => undefined
);
