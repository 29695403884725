import { Box, FormControlLabel, Stack, Switch } from '@mui/material';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { DateTime } from 'luxon';
import { EngineUsage } from 'apis/rest/engineUsage/types';
import useTimezone from 'hooks/session/useTimezone';
import DownloadMenu from 'components/shared/downloadMenu';
import mixpanel from 'mixpanel-browser';
import { FlightTable } from './flightTable';
import { FlightMap } from './flightMap';
import { DailyDetail } from './dailyDetail';
import { reducer } from '../../tripAnalysis/timeline/tripTimelineView';
import DownloadEngineMenuItem from '../../tripAnalysis/download/downloadEngineUsageMenuItem';
import DownloadFlightSummaryMenuItem from './download/downloadFlightSummaryMenuItem';

interface AssetFlightSummaryProps {
  asset: AssetWithDevice
  trips: Trip[]
  isEngineUsageLoading: boolean
  engineUsage: EngineUsage[] | undefined
  day: DateTime
}

export const AssetFlightSummary = ({ asset, trips, isEngineUsageLoading, engineUsage, day }: AssetFlightSummaryProps) => {
  const [tripsState, dispatch] = useReducer(reducer, { trips });
  const mapTrips = useMemo(() => (tripsState.selectedTrip ? [tripsState.selectedTrip] : trips), [tripsState.selectedTrip, trips]);
  const [displayEngineUsage, setDisplayEngineUsage] = useState(false);

  useEffect(() => {
    if (displayEngineUsage) {
      mixpanel.track('Daily Flight Summary Display Engine Usage');
    }
  }, [displayEngineUsage]);

  useEffect(() => {
    dispatch({ type: 'SET_TRIPS', trips });
  }, [trips, dispatch]);

  const timezone = useTimezone();

  return (
    <Box
      display="grid"
      gridTemplateColumns="2fr 1fr"
      gridTemplateRows="auto 1fr"
      gridTemplateAreas={{ xs: '"detail detail" "settings settings" "table map"' }}
      gap={3}
    >
      <Box gridArea="settings">
        <Stack direction="row" spacing={3} alignItems="center">
          {!!engineUsage?.length && (
            <FormControlLabel
              control={<Switch checked={displayEngineUsage} onChange={event => setDisplayEngineUsage(event.target.checked)} />}
              label="Display engine cycles"
              labelPlacement="start"
              sx={{ mr: 1, ml: 0 }}
            />
          )}
          {(!!trips.length || !!engineUsage?.length) && (
            <>
              <Box flex={1} />
              <DownloadMenu>
                {close => [
                  <DownloadFlightSummaryMenuItem
                    key="summary"
                    asset={asset}
                    from={day.startOf('day').toMillis()}
                    until={day.endOf('day').toMillis()}
                    timezone={timezone}
                    close={close}
                    disabled={!trips.length}
                  />,
                  <DownloadEngineMenuItem
                    key="engineCycles"
                    assets={[asset]}
                    from={day.startOf('day').toMillis()}
                    until={day.endOf('day').toMillis()}
                    timezone={timezone}
                    close={close}
                    disabled={!engineUsage?.length}
                  />,
                ]}
              </DownloadMenu>
            </>
          )}
        </Stack>
      </Box>
      {trips.length > 0 && (
        <Box gridArea="map">
          <FlightMap
            asset={asset}
            trips={mapTrips}
            selectedReport={tripsState.selectedReport}
          />
        </Box>
      )}
      {(trips.length > 0 || (displayEngineUsage && !!engineUsage?.length)) && (
        <Box gridArea="table">
          <FlightTable
            tripState={tripsState}
            asset={asset}
            trips={tripsState.trips}
            displayEngineUsage={displayEngineUsage}
            engineUsage={engineUsage}
            dispatch={dispatch}
            day={day}
          />
        </Box>
      )}
      <Box gridArea="detail">
        <DailyDetail
          trips={trips}
          isEngineUsageLoading={isEngineUsageLoading}
          engineUsage={engineUsage}
        />
      </Box>
    </Box>
  );
};
