import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '1fr',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  leftHalf: {
    gridArea: '1 / 1 / 2 / 2',
    backgroundColor: '#0d5189',
    color: '#fff',
    padding: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      height: '100%',
    },
  },
  leftWrapper: {
    height: 'auto'
  },
  titleWrapper: {
    textAlign: 'left',
    marginBottom: theme.spacing(6)
  },
  titleText: {
    color: '#fff',
    fontSize: '42px',
    marginBottom: theme.spacing(4)
  },
  subtitleText: {
    color: '#fff',
  },

  continueWrapper: {
    display: 'flex',
    textAlign: 'left',
    borderTop: '1px solid #ccc',
    paddingTop: theme.spacing(4)
  },
  continueText: {
    color: '#fff',
    marginRight: theme.spacing(6)
  },
  continueButton: {
    minWidth: '150px'
  },

  rightHalf: {
    gridArea: '1 / 2 / 2 / 3',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: 'url(img/south-pacific-helicopters.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },

  languageSelectorWrapper: {
    position: 'absolute',
    bottom: theme.spacing(16)
  },
  storeIconWrapper: {
    minWidth: '150px',
    minHeight: '40px'
  }
}));
