import { useCallback } from 'react';
import { NumberFormatOptions, useFormatter } from 'use-intl';
import { volume } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

type VolumeUnit = ReduxState['unitSettings']['units']['volume'];
type TimePeriod = 's' | 'h';

class Volume {
  private litres: number;
  readonly unit: VolumeUnit;
  readonly unitValue: number;
  readonly unitLabel: string;
  readonly formatter: ReturnType<typeof useFormatter>;

  constructor(litres: number, unit: VolumeUnit, formatter: ReturnType<typeof useFormatter>) {
    this.litres = litres;
    this.unit = unit;
    this.unitValue = volume.toUnit(litres, unit);
    this.unitLabel = volume.label(unit);
    this.formatter = formatter;
  }

  format<T = string>(fn?: (d: Volume, options?: NumberFormatOptions) => T, options: NumberFormatOptions = { maximumFractionDigits: 0 }) {
    if (fn) return fn(this, options);
    return this.unitValue > 0 ? `${this.formatter.number(this.unitValue, options)} ${this.unitLabel}` : '—';
  }
}

class VolumePerPeriod {
  private litres: number;
  readonly unit: VolumeUnit;
  readonly unitValue: number;
  readonly unitLabel: string;
  readonly formatter: ReturnType<typeof useFormatter>;

  constructor(litres: number, unit: VolumeUnit, period: TimePeriod, formatter: ReturnType<typeof useFormatter>) {
    this.litres = litres;
    this.unit = unit;
    this.unitValue = volume.toUnit(litres, unit);
    this.unitLabel = `${volume.label(unit)}/${period}`;
    this.formatter = formatter;
  }

  format<T = string>(fn?: (d: VolumePerPeriod, options?: NumberFormatOptions) => T, options: NumberFormatOptions = { maximumFractionDigits: 0 }) {
    if (fn) return fn(this, options);
    return this.unitValue > 0 ? `${this.formatter.number(this.unitValue, options)} ${this.unitLabel}` : '—';
  }
}

const useVolume = () => {
  const formatter = useFormatter();
  const units = useUnitSettings();
  const unit = units.volume;
  const unitLabel = volume.label(unit);
  const create = useCallback((litres = 0) => new Volume(litres, unit, formatter), [unit, formatter]);
  return { create, unit, unitLabel };
};

export const useVolumePerPeriod = () => {
  const formatter = useFormatter();
  const units = useUnitSettings();
  const unit = units.volume;
  const unitLabel = `${volume.label(unit)}/h`;
  const create = useCallback((litres = 0, period: TimePeriod = 'h') => new VolumePerPeriod(litres, unit, period, formatter), [unit, formatter]);
  return { create, unit, unitLabel };
};

export default useVolume;
