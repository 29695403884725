export enum DeviceStatusDescription {
  Error = 'Error',
  Activated = 'Activated',
  Suspended = 'Suspended',
  Deactivated = 'Deactivated',
  Archived = 'Archived',
  Unknown = 'Unknown',
}

export const deviceStatus = [
  { id: -1, description: DeviceStatusDescription.Error },
  { id: 14, description: DeviceStatusDescription.Activated },
  { id: 15, description: DeviceStatusDescription.Suspended },
  { id: 16, description: DeviceStatusDescription.Deactivated },
  { id: 17, description: DeviceStatusDescription.Archived },
  { id: 19, description: DeviceStatusDescription.Unknown },
] as const;
