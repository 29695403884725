export type DropAdditive = 'none' | 'retardant' | 'foam' | 'gel';

export const extractAdditive = (report: Report): DropAdditive | undefined => {
  const dzmxMetadata = report.metadata?.add?.toString().toLowerCase();
  if (dzmxMetadata) {
    return dzmxMetadata as DropAdditive;
  }
  const trotterMetadata = report.metadata?.RetardantType?.toString().toLowerCase();
  if (trotterMetadata) {
    switch (trotterMetadata) {
      case 'fm': return 'foam';
      case 'na':
      case 'wt':
      default: return 'none';
    }
  }
  const atuMetadata = report.metadata?.ProductType?.toString().toLowerCase();
  if (atuMetadata) {
    switch (atuMetadata) {
      case 'foam': return 'foam';
      case 'water - no additives':
      default: return 'none';
    }
  }
  const affMetadata = report?.metadata?.suppressant?.toString().toLowerCase();
  if (affMetadata) {
    switch (affMetadata) {
      case 'unknown':
      default: return 'none';
    }
  }
  return undefined;
};
