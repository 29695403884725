import React, { FC } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export interface SelectedItemProps {
  removeText: string;
  isReadOnly?: boolean;
  onRemoveClick: () => void;
}

export const SelectedItem: FC<SelectedItemProps> = ({ removeText, isReadOnly, onRemoveClick, children }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      borderTop={1}
      borderBottom={1}
      mt="-1px"
      borderColor="common.midGrey"
      p={2}
      alignItems="center"
      sx={{ ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) } }}
    >
      <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
        <Stack direction="row" spacing={1} alignItems="center" flex={1} minWidth={0}>
          {children}
        </Stack>
      </Stack>
      {!isReadOnly && (
        <Stack direction="row" alignItems="center">
          <Tooltip title={removeText}>
            <IconButton onClick={onRemoveClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
