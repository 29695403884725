import React from 'react';
import { useTranslations } from 'use-intl';
import { Alert, Chip, Collapse, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import useSnackbar from 'hooks/useSnackbar';
import useLastDefined from 'hooks/useLastDefined';
import usePersonCanBeImportantIce from 'hooks/people/usePersonCanBeImportantIce';
import {
  AssignPeopleDialog,
  AssignPeopleDialogProps,
  AssignPeopleDialogStatus,
} from '../../contactGroups/assignPeople';
import { PeopleTableComponents } from '../../contactGroups/assignPeople/peopleTable-view';
import { useGetPeopleGroups, useMutateAddPeopleToPeopleGroup } from 'apis/rest/peopleGroups/hooks';

const components: PeopleTableComponents = {
  Person: ({ person, order }) => {
    const t = useTranslations('dialogs.peopleGroups.assignPeople');
    const importantPerson = usePersonCanBeImportantIce();

    return (
      <>
        <Stack>
          <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{person.name}</Typography>
          <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body3">{person.role}</Typography>
        </Stack>
        {!importantPerson(person.id) && (
          <Tooltip title={t('tooltips.missingRequiredContacts')}>
            <ErrorOutline color="error" fontSize="large" />
          </Tooltip>
        )}
        <Chip label={person.languageCode} />
      </>
    );
  },
};

interface AssignPeopleIceDialogProps extends Pick<AssignPeopleDialogProps, 'open' | 'onClose' | 'title' | 'ariaLabel' | 'skip'> {
  groupId: number | undefined
}

export const AssignPeopleToPeopleGroupDialog = ({ onClose, groupId, ...rest }: AssignPeopleIceDialogProps): JSX.Element | null => {
  const t = useTranslations('dialogs.peopleGroups.assignPeople');
  const { query: groupsQuery } = useGetPeopleGroups();// {enabled: groupId !== undefined }}
  const mutation = useMutateAddPeopleToPeopleGroup();
  const snackbar = useSnackbar();
  const error = useLastDefined(mutation.error ?? undefined, [groupId]);

  const group = groupsQuery.data?.find(g => g.id === groupId);
  if (!group) return null;

  return (
    <AssignPeopleDialog
      {...rest}
      onClose={(status, id) => {
        onClose(status, id);
        if (status === AssignPeopleDialogStatus.Assigned) {
          snackbar.display({
            id: `groupPeopleAdded.${id}`,
            text: t('snackbar.success', { group: group.name }),
            type: 'success',
          });
        }
      }}
      group={group}
      mutation={mutation}
      validate={() => true}
      components={components}
      renderErrors={() => (
        <Collapse in={mutation.isError} unmountOnExit>
          <Alert severity="error">
            {error?.response.status === 409 ? t('error409') : t('error')}
          </Alert>
        </Collapse>
      )}
    />
  );
};
