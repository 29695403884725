import { subsolarPoint } from 'utils/subsolarPoint';
import { DateTime } from 'luxon';
import { Layer, Source } from 'react-map-gl';
import React, { useEffect, useMemo, useState } from 'react';
import { distanceRing } from 'utils/distanceRing';
import { useStartOfDay } from 'hooks/useStartOfDay';
import useTimezone from 'hooks/session/useTimezone';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useUserSettings } from 'contexts/userSettings/useUserSettings';

export const SunLayer = ({ beforeId, dark } : { beforeId?: string, dark?: boolean }) => {
  const [date, setDate] = useState(DateTime.now());
  const selectedDate = useStartOfDay();
  const timezone = useTimezone();

  const isToday = useMemo(() => selectedDate.hasSame(date.setZone(timezone), 'day'), [date, timezone, selectedDate]);
  const [mapSettings, _] = useUserSettings('map');

  useEffect(() => {
    const int = setInterval(() => setDate(DateTime.now()), 1000);
    return () => clearInterval(int);
  }, [setDate]);

  const circlePoly = useMemo(() => {
    const center = subsolarPoint(date);
    return distanceRing([180 + center[0], -center[1]], 90, 'degrees', 360, true);
  }, [date]);

  const circle = useMemo(() => {
    const center = subsolarPoint(date);
    return distanceRing([180 + center[0], -center[1]], 90, 'degrees', 360, false);
  }, [date]);

  if (!useFeatureFlag('frontendSunsetMap') || !mapSettings.layers.sun) {
    return null;
  }

  return (
    <>
      <Source
        id="sunPoly"
        type="geojson"
        data={circlePoly}>
        <Layer
          type="fill"
          beforeId={beforeId}
          layout={{
            visibility: isToday ? 'visible' : 'none'
          }}
          paint={{
            'fill-color': 'rgba(0, 0, 0, 1)',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, dark ? 0.4 : 0.1, 8, 0],
          }}
      />
      </Source>
      <Source
        id="sunPath"
        type="geojson"
        data={circle}>
        <Layer
          type="line"
          beforeId={beforeId}
          layout={{
            visibility: (isToday && dark) ? 'visible' : 'none'
          }}
          paint={{
            'line-color': 'rgba(255, 255, 255, 1)',
            'line-width': 1,
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 5, 1, 8, 0],
          }}
        />
      </Source>
    </>
  );
};
