import React, { useState, useCallback, useMemo } from 'react';
import useInterval from 'hooks/useInterval';
import { DateTime, Duration } from 'luxon';
import { useTranslations } from 'use-intl';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const TEN_MINUTES = 10 * ONE_MINUTE;
const ONE_HOUR = 60 * ONE_MINUTE;

const getUpdateInterval = (time: DateTime | undefined, now: DateTime) => {
  if (!time) return TEN_MINUTES;
  const msDifference = time.diff(now).toMillis();

  // For times less than 1 minute away, update every 5 seconds
  if (msDifference < ONE_MINUTE) return ONE_SECOND;
  // For times less than 1 hour away, update every minute
  if (msDifference < ONE_HOUR) return ONE_MINUTE;
  // Otherwise update every 10 minutes
  return TEN_MINUTES;
};

export interface TimeSinceViewProps {
  time?: DateTime
  relativeTo?: DateTime
  fallbackString?: string
  fewSeconds?: number
}

const useTimeAgoFormat = (fewSeconds: number) => {
  const t = useTranslations('components.TimeSince');

  return useCallback((duration: Duration) => {
    if (duration.as('seconds') < fewSeconds) return t('aFewSeconds');
    if (duration.as('seconds') < 60) return t('relativeTime', { unit: 'seconds', n: Math.round(duration.as('seconds')) });
    if (duration.as('minutes') < 60) return t('relativeTime', { unit: 'minutes', n: Math.round(duration.as('minutes')) });
    if (duration.as('hours') < 24) return t('relativeTime', { unit: 'hours', n: Math.round(duration.as('hours')) });
    if (duration.as('months') < 1) return t('relativeTime', { unit: 'days', n: Math.round(duration.as('days')) });
    if (duration.as('years') < 1) return t('relativeTime', { unit: 'months', n: Math.round(duration.as('months')) });
    return t('relativeTime', { unit: 'years', n: Math.round(duration.as('years')) });
  }, [t, fewSeconds]);
};

const TimeSinceView: React.FC<TimeSinceViewProps> = ({
  time,
  relativeTo,
  fallbackString = '',
  fewSeconds = 5,
}) => {
  const timezone = time?.zoneName ?? 'Etc/UTC';
  const timeAgoFormat = useTimeAgoFormat(fewSeconds);
  const [now, setNow] = useState(() => (relativeTo ?? DateTime.now()).setZone(timezone));
  const isToday = time?.hasSame(now, 'day') ?? false;

  const displayTime = useMemo((): string => {
    if (!time) return fallbackString;
    if (relativeTo) {
      // historic mode, show a timestamp, not a relative time
      return time.toFormat(isToday ? 'HH:mm' : 'HH:mm, d MMM yyyy');
    }
    // not historic mode, use relative time
    return timeAgoFormat(now.diff(time));
  }, [fallbackString, isToday, relativeTo, now, time, timeAgoFormat]);

  useInterval(() => {
    setNow((relativeTo ?? DateTime.now()).setZone(timezone));
  }, getUpdateInterval(time, now));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{displayTime}</>;
};

export default TimeSinceView;
