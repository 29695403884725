import is from 'is_js';

const checkWebGL = () => {
  const canvas = document.createElement('canvas');
  // Get WebGLRenderingContext from canvas element.
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) return true;
  return false;
};

// Generates a report that contains any compatibility issues in the current user environment,
// and always contains a single boolean value for incompatible.
export const createCompatibilityReport = () => {
  let incompatible = false;
  let mobile = false;
  let ios = false;
  let android = false;
  let wrongBrowser = false;
  let oldBrowser = false;
  let noWebgl = false;
  let screenWidthTooLow = false;

  // Mobile?
  if (is.mobile()) {
    incompatible = true;
    mobile = true;

    if (is.ios()) {
      ios = true;
    }

    if (is.android()) {
      android = true;
    }
  }

  // Browser?
  if (!is.chrome() && !is.firefox() && !is.safari() && !is.edge() && !is.opera()) {
    incompatible = true;
    wrongBrowser = true;
  }

  // Browser is ok, now we need to check versions:
  if (is.chrome() && is.chrome('<92')) {
    incompatible = true;
    oldBrowser = true;
  }
  if (is.firefox() && is.firefox('<90')) {
    incompatible = true;
    oldBrowser = true;
  }
  if (is.safari() && is.safari('<15.6')) {
    incompatible = true;
    oldBrowser = true;
  }
  if (is.edge() && is.edge('<92')) {
    incompatible = true;
    oldBrowser = true;
  }
  if (is.opera() && is.opera('<78')) {
    incompatible = true;
    oldBrowser = true;
  }

  // WebGL?
  if (!checkWebGL()) {
    incompatible = true;
    noWebgl = true;
  }

  // Screen width?
  if (window.screen.width < 1200) {
    incompatible = true;
    screenWidthTooLow = true;
  }

  return {
    incompatible,
    mobile,
    ios,
    android,
    wrongBrowser,
    oldBrowser,
    noWebgl,
    screenWidthTooLow
  };
};

// Wrapper function that just returns if the environment is compatible or not.
// For more detailed information use createCompatibilityReport instead.
export const compatibilityCheck = () => {
  const isResetPasswordPath = window.location.pathname === '/reset-password';
  const isSignupPath = window.location.pathname === '/signup';
  const isSetNewPasswordPath = window.location.pathname === '/set-new-password';
  const isMobileFriendlyPath = isResetPasswordPath || isSignupPath || isSetNewPasswordPath;
  const isCompatible = isMobileFriendlyPath || !createCompatibilityReport().incompatible;

  return isCompatible;
};
