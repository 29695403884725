import { useEffect } from 'react';
import { DateTime } from 'luxon';
import useInterval from 'hooks/useInterval';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { getInferredEventsForOrganisation } from 'apis/rest/inferredEvents/requests';
import { useInferredEventsRepository } from 'repositories/inferredEvents/hooks';

export const LiveInferredEventsDummy = () => {
  const eventsRepo = useInferredEventsRepository();
  const organisationId = useOrganisationId();

  useEffect(() => () => {
    console.info('clear inferred events');
    eventsRepo.clear();
  }, [eventsRepo]);

  useInterval(async () => {
    const until = DateTime.now().toUTC();
    const from = until.minus({ minutes: 3 }).toUTC();
    console.log(`getting current inferred events for ${organisationId} from ${from.toISO()} until ${until.toISO()}`);
    const events = await getInferredEventsForOrganisation(organisationId, from, until);
    eventsRepo.insertEvents(events);
  }, 60 * 1000);

  return null;
};
