import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  staffIndicator: {
    display: 'inline-block'
  },
  logo: {
    verticalAlign: 'middle',
    marginLeft: '5px'
  }
}));
