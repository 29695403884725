import { useContext, useEffect } from 'react';
import { filter } from 'rxjs';
import { NotificationContext } from './notificationContext';

export const useNotification = (id: string | string[], handler: (notification: TPCNotification) => void, enabled = false) => {
  const notificationValue = useContext(NotificationContext);

  useEffect(() => {
    if (!enabled) return undefined;
    const subscription = notificationValue.subject
      .pipe(filter(n => (id instanceof Array ? id.includes(n.notificationId) : n.notificationId === id)))
      .subscribe(handler);
    return () => subscription.unsubscribe();
  }, [handler, id, notificationValue.subject, enabled]);
};
