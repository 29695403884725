import { QueryClient } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 200,
      retry: (failureCount, error) => {
        if (error instanceof HttpResponseError) {
          if (error.response.status >= 400 && error.response.status < 500) {
            return false;
          }
        }
        return failureCount < 3;
      }
    }
  },
});
