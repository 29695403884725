import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const MapIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <path d="M22,0c-3.4,0-7,2.6-7,6.8c0,3.9,5.7,9.3,6.3,9.9c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.6-0.6,6.3-6,6.3-9.9C29,2.6,25.4,0,22,0z M22,9c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C24,8.1,23.1,9,22,9z" />
      <path d="M27.5,14.5c0.3,1.1,0.5,2.3,0.5,3.5c0,6.6-5.4,12-12,12c-3.4,0-6.5-1.5-8.7-3.8C8.1,24.4,8,22.6,7,21 c-0.3-0.4-0.7-0.9-1-1.3c-0.5-0.5-1.2-1.3-1.2-1.6c0.1-0.3,1.1-0.7,1.7-1c1-0.5,2.2-1.1,2.9-2c1.4-1.8,0.4-5-0.3-6.1 C8.9,8.8,8.8,8.6,8.7,8.5c1.3-1,2.7-1.7,4.3-2.1c0-0.8,0.2-1.5,0.3-2.1C6.9,5.5,2,11.2,2,18c0,7.7,6.3,14,14,14s14-6.3,14-14 c0-1.9-0.4-3.7-1.1-5.4C28.5,13.2,28,13.9,27.5,14.5z" />
      <path d="M24,18.2c-0.6,0.5-1.3,0.8-2,0.8s-1.5-0.3-2-0.8c-0.6-0.5-1.6-1.6-2.8-2.9c-0.9,0.9-1.4,2-1.2,3.2 c0.4,2.2,0.3,3.5,0.3,4.4c-0.1,1.2-0.1,2.3,1.3,3.4c0.3,0.2,0.8,0.5,1.5,0.5c0.5,0,1.1-0.1,1.9-0.5c2.6-1.3,5.4-5,5.3-8 c0-0.7-0.2-1.3-0.5-1.9C25.1,17.2,24.4,17.8,24,18.2z" />
    </SvgIcon>
  );
};

export default MapIcon;
