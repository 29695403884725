import { HttpResponseError, serenityFetch } from 'helpers/api';
import {
  EventNotificationGroup,
  EventNotificationGroupIds,
  EventNotificationRule,
  EventNotificationRuleWithGroupName,
  NotificationGroupIdListResponse,
  NotificationGroupRequest,
  NotificationGroupResponse,
  NotificationRuleRequest,
} from './types';

export const listEventNotificationGroups = async (organisationId: string): Promise<EventNotificationGroupIds[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/event-notifications`, null);
  HttpResponseError.detect(response);
  const parsed: NotificationGroupIdListResponse = await response.json();
  return parsed.notificationGroups.map(({ notificationGroup }) => ({ ...notificationGroup })) ?? [];
};

export const getEventNotification = async (organisationId: string, eventNotificationGroupId: number): Promise<EventNotificationGroup> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/event-notifications/${eventNotificationGroupId}`, null);
  HttpResponseError.detect(response);
  const parsed: NotificationGroupResponse = await response.json();
  const mapper = ({ notificationGroup, assetGroupIds }: NotificationGroupResponse) => ({ ...notificationGroup, assetGroupIds });
  return mapper(parsed);
};

export const getNotificationRulesByContact = async (organisationId: string, peopleGroupId: number): Promise<EventNotificationRule[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/event-notifications/people-group/${peopleGroupId}`, null);
  HttpResponseError.detect(response);
  const result = await response.json();
  return result.notificationRules ?? [];
};

export const getNotificationRulesByGeofenceId = async (organisationId: string, geofenceId: number): Promise<EventNotificationRuleWithGroupName[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/event-notifications/geofence/${geofenceId}`, null);
  HttpResponseError.detect(response);
  const result = await response.json();
  return result.notificationRules ?? [];
};

export const createEventNotification = async (organisationId: string, name: string): Promise<EventNotificationGroup> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/event-notifications`, name);
  HttpResponseError.detect(response);
  const parsed = await response.json();
  const mapper = ({ notificationGroup, assetGroupIds }: NotificationGroupResponse) => ({ ...notificationGroup, assetGroupIds });
  return mapper(parsed);
};

export const createEventNotificationRule = async (organisationId: string, groupId: number, rule: NotificationRuleRequest): Promise<EventNotificationRule> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/event-notifications/${groupId}/rules`, rule);
  HttpResponseError.detect(response);
  const result = await response.json();
  return result.notificationRules;
};

export const updateEventNotification = async (organisationId: string, notificationGroup: NotificationGroupRequest): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/event-notifications/${notificationGroup.notificationGroup.id}`, notificationGroup);
  HttpResponseError.detect(response);
};

export const updateEventNotificationRule = async (organisationId: string, groupId: number, ruleRequest: NotificationRuleRequest): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/event-notifications/${groupId}/rules/${ruleRequest.notificationRule.id}`, ruleRequest);
  HttpResponseError.detect(response);
};

export const updateAssetGroupsForEventNotificationGroup = async (organisationId: string, groupId: number, assetGroupIds: number[]): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/event-notifications/${groupId}/asset-groups`, assetGroupIds);
  HttpResponseError.detect(response);
};

export const updatePeopleGroupsForEventNotificationRule = async (organisationId: string, groupId: number, ruleId: number, peopleGroupIds: number[]): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/event-notifications/${groupId}/rules/${ruleId}/people-groups`, peopleGroupIds);
  HttpResponseError.detect(response);
};

export const deleteEventNotificationGroup = async (organisationId: string, eventNotificationGroupId: number, rowVersion: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/event-notifications/${eventNotificationGroupId}?rowVersion=${rowVersion}`, null);
  HttpResponseError.detect(response);
};

export const deleteEventNotificationRule = async (organisationId: string, groupId: number, ruleId: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/event-notifications/${groupId}/rules/${ruleId}`, null);
  HttpResponseError.detect(response);
};

export const getEventNotificationWarnings = async (organisationId: string, groupId: number): Promise<EventNotificationWarnings> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/event-notifications/${groupId}/warnings`, null);
  HttpResponseError.detect(response);
  return (await response.json()).warnings;
};
