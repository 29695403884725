import { useMemo } from 'react';
import { useGetPeople } from 'apis/rest/people/hooks';
import { personCanBeImportantIce } from 'hooks/people/usePersonCanBeImportantIce';
import usePeopleById from 'hooks/people/usePeopleById';

export enum CanSetDefault {
  Loading = 'loading',
  Valid = 'valid',
  Invalid = 'invalid',
}

const useMessagingWhitelistContactGroupCanSetDefault = (group?: ContactGroup): CanSetDefault => {
  const { query: peopleQuery } = useGetPeople();
  const peopleById = usePeopleById(peopleQuery.data) ?? {};

  const [primaryId, secondaryId] = useMemo(
    () => {
      if (!group) return [];
      return [...group.peopleWithOrder].sort((a, b) => a.order - b.order).map(x => x.personId);
    },
    [group],
  );

  if (peopleQuery.isLoading) return CanSetDefault.Loading;

  const primary = peopleById[primaryId];
  const secondary = peopleById[secondaryId];
  const isValid = primary && secondary && personCanBeImportantIce(primary) && personCanBeImportantIce(secondary);

  return isValid ? CanSetDefault.Valid : CanSetDefault.Invalid;
};

export default useMessagingWhitelistContactGroupCanSetDefault;
