import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { Tooltip, Box, Stack, Typography, IconButton } from '@mui/material';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { isAssetWithDevice } from 'helpers/assets';
import { getLegsQueryFn, getLegsQueryKey } from 'helpers/legs';
import { useAssetsReports, useLatestPositionsForAssets } from 'repositories/reports/hooks';
import { useQueries } from '@tanstack/react-query';
import { groupBy } from 'lodash/fp';
import { useSelector } from 'react-redux';
import useTimezone from 'hooks/session/useTimezone';
import { useSize } from 'hooks/useSize';
import { ArrowBack } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { getTimes } from 'suncalc';
import { useStartOfDay } from 'hooks/useStartOfDay';
import { Dictionary } from 'lodash';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel, { useAssetLabel } from 'components/shared/assetLabel';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { assignItemToMap, getSelectedLeg, getSelectedMapId, setTrailHighlight, unassignItemFromMap } from 'slices/map.slice';
import { clearSelection, getSelectedDay, selectItem, selectLeg } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import { useAssetSortBy } from 'hooks/settings/useAssetSortBy';
import { useAssetsInferredEventsByReportId } from 'repositories/inferredEvents/hooks';
import { AssetTimelineGraph, MARGIN_TOP } from './AssetTimelineGraph';
import { AssetTimelineGraphAxis } from './AssetTimelineGraphAxis';
import Analysisbox from '../analysisbox/analysisbox-view';
import LegPopper from './LegPopper';

interface AnalysisBoxTimelineProps {
  selectedAsset?: AssetWithDevice | AssetBasic
  drawerHeight: number
}

const useLegsForAssets = (assets: (AssetWithDevice | AssetBasic)[]): Record<number, Leg[]> => {
  const [legsByAsset, setLegsByAsset] = useState<Dictionary<Leg[]>>({});
  const startOfDay = useStartOfDay();
  const reportsForAssets = useAssetsReports(assets);
  const enableInferredEvents = useFeatureFlag('frontendInferredEventsLegs');
  const inferredEvents = useAssetsInferredEventsByReportId(assets, enableInferredEvents);

  const queries = useMemo(() => ({
    queries: assets
      .filter(a => (a.id in reportsForAssets))
      .map(a => {
        const numInferredEvent = inferredEvents?.[a.id] ? Object.values(inferredEvents?.[a.id]).flatMap(e => e).length : undefined;
        return ({
          queryKey: getLegsQueryKey(reportsForAssets[a.id], numInferredEvent),
          queryFn: getLegsQueryFn(a, reportsForAssets[a.id].map(r => ({
            ...r,
            inferredEvents: inferredEvents?.[a.id]?.[r.id] ?? null
          })), enableInferredEvents ?? false),
        });
      })
  }), [assets, enableInferredEvents, inferredEvents, reportsForAssets]);

  const legsQueries = useQueries(queries).flatMap(d => d.data);

  useEffect(() => {
    setLegsByAsset(groupBy(
      'assetId',
      legsQueries
        .filter((l): l is Leg => !!l && (l.start * 1000) > startOfDay.toMillis())
    ));
    // usequeries doesn't have a stable response - something to address once we get to v5
    // https://github.com/TanStack/query/issues/5137
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legsQueries.length, reportsForAssets, startOfDay]);

  return legsByAsset;
};

export const AnalysisBoxTimeline = ({ drawerHeight, selectedAsset }: AnalysisBoxTimelineProps) => {
  const t = useTranslations('analysisbox.timeline');
  const assets = useGetAssetsList({ select: a => a.filter(isAssetWithDevice) }).query.data;
  const selectedDay = useSelector(getSelectedDay);
  const selectedLeg = useSelector(getSelectedLeg);
  const selectedReport = useSelector<ReduxState, Report | null>(state => state.reports.selectedReportPerMap[state.map.selectedMapId]);
  const selectedMapId = useSelector(getSelectedMapId);
  const { assets: sortBy } = useAssetSortBy();
  const assetLabel = useAssetLabel();
  const timezone = useTimezone();
  const startOfDay = useStartOfDay();
  const dispatch = useAppDispatch();

  const assetsToUse = useMemo(
    () => (selectedAsset ? [selectedAsset] : (assets ?? [])),
    [selectedAsset, assets]
  );

  const reportsForAssets = useAssetsReports(assetsToUse);

  const legsByAsset = useLegsForAssets(assetsToUse);
  const assetsWithLegs = useMemo(() => assetsToUse.filter(a => a.id in legsByAsset), [legsByAsset, assetsToUse]);

  const positions = useLatestPositionsForAssets(assetsWithLegs);

  const assetsAndSunsetTimes = useMemo(() => assetsWithLegs.flatMap(a => {
    const position = positions[a.id];
    if (!position) {
      return [];
    }
    const times = getTimes(new Date(position.received * 1000), position.latitude, position.longitude, position.altitude);
    return { ...a, sunset: times.sunset.getTime(), sunrise: times.sunrise.getTime() };
  }).sort((assetA, assetB) => {
    switch (sortBy) {
      case 'activity':
        return (positions[assetB.id]?.received || 0) - (positions[assetA.id]?.received || 0);
      case 'name':
      default:
        return assetLabel(assetA, '').localeCompare(assetLabel(assetB, ''));
    }
  }), [assetsWithLegs, positions, sortBy, assetLabel]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [legPopper, setLegPopper] = useState<{ leg?: Leg, element?: SVGElement }>({});

  const dispatchSelectAsset = useCallback((a?: AssetWithDevice) => {
    dispatch(assignItemToMap({ mapId: selectedMapId, item: a ?? null }));
    dispatch(selectItem(a ?? null));
  }, [dispatch, selectedMapId]);

  const dispatchClearSelection = useCallback(() => {
    dispatch(clearSelection());
    dispatch(unassignItemFromMap());
  }, [dispatch]);

  const { height: assetBoxHeight } = useSize(containerRef);

  const dispatchSelectLeg = useCallback((l: Leg) => {
    const legToSelect = (l === null || selectedLeg?.id === l?.id) ? null : l;
    if (selectedAsset?.id !== l.assetId) {
      const legAsset = assets?.find(a => a.id === l?.assetId);
      if (legAsset) {
        dispatchSelectAsset(legAsset);
      }
    }
    dispatch(selectLeg({ leg: legToSelect }));
  }, [selectedLeg, selectedAsset, dispatch, assets, dispatchSelectAsset]);

  const dispatchTrailHighlight = useCallback((l?: Leg | null, e?: SVGSVGElement) => {
    setLegPopper({ leg: l ?? undefined, element: e });
    if (!l) {
      dispatch(setTrailHighlight({ highlightTrail: null }));
      return;
    }
    const reports = reportsForAssets[l.assetId].filter(r => r.received >= l.start && r.received <= l.end);
    if (reports.length >= 0) {
      dispatch(setTrailHighlight({ highlightTrail: { assetId: l.assetId, reports } }));
    }
  }, [dispatch, reportsForAssets]);

  return (
    <Box sx={theme => ({ backgroundColor: theme.palette.common.white })}>
      <Box sx={{
        overflow: 'scroll',
        overflowX: 'visible',
        scrollbarGutter: 'stable',
        pr: 3
      }}>
        {selectedAsset && (
          <Tooltip title={t('allAssets')} placement="top">
            <IconButton
              sx={{ position: 'absolute', top: 0, left: 0, mt: 3, ml: 2, zIndex: 1 }}
              size="small"
              onClick={dispatchClearSelection}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white'
        }}>
          <AssetTimelineGraphAxis startTimeIso={selectedDay} width={chartWidth} />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'top',
            maxHeight: drawerHeight - 30 - MARGIN_TOP
          }}
        >
          <Box
            display="grid"
            gridAutoRows="1fr"
            height="min-content"
            ref={containerRef}
          >
            {assetsAndSunsetTimes.map((a, i) => (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                py={1}
                pr={2}
                borderBottom={i === assetsAndSunsetTimes.length - 1 ? undefined : 'border.default'}
              >
                <AssetColourMarker assetId={a.id} />
                <Typography variant="h3"><AssetLabel asset={a} /></Typography>
              </Stack>
            ))}
          </Box>
          <LegPopper leg={legPopper.leg} anchorEl={legPopper.element} timezone={timezone} />
          <AssetTimelineGraph
            assets={assetsAndSunsetTimes}
            legsByAsset={legsByAsset}
            height={assetBoxHeight}
            startTime={startOfDay}
            setWidth={setChartWidth}
            onLegHover={dispatchTrailHighlight}
            onLegClick={dispatchSelectLeg}
            selectedLeg={selectedLeg}
            selectedReport={selectedReport}
          />
        </Box>
      </Box>
      {selectedAsset && (
        <Box mt={2} pt={2} borderTop={theme => theme.border.default}>
          { /* @ts-ignore */}
          <Analysisbox drawerHeight={drawerHeight - 90} selectedAsset={selectedAsset} timeline />
        </Box>
      )}
    </Box>
  );
};
