import { useCallback } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import validateName, { ValidateNameResult } from 'helpers/validation/validateName';

const useValidateName = (groupsQuery: UseQueryResult<ContactGroup[], unknown>) => (
  useCallback((groupId: number | undefined, name: string | undefined): ValidateNameResult | undefined => {
    if (groupsQuery.isLoading || !groupsQuery.data) return undefined;

    const group = groupId === undefined ? undefined : groupsQuery.data.find(g => g.id === groupId);

    let existingNames: string[] = [];
    existingNames = groupsQuery.data.filter(g => g.id !== groupId && !g.temporary).map(g => g.name);

    return validateName(name, group?.name ?? '', existingNames);
  }, [groupsQuery])
);
export default useValidateName;
