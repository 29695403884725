import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import {
  openDeleteSearchPatternDialog,
  selectSearchPatternOverlayState, setSelectedSearchPattern,
  updateSearchPatternOverlay
} from 'slices/searchPatterns.slice';
import { DistanceInput } from 'components/shared/DistanceInput';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import { SearchPattern } from 'helpers/searchPatterns';
import { TPButton } from 'components/shared/button/TPButton';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

const fieldTypes = [
  'name',
  'origin',
  'orientation',
  'firstTurnDirection',
  'turnDirection',
  'trackSpacingMetres',
  'legCount',
  'legLengthMetres',
  'orientationArc',
  'legLengthRangeMetres',
  'notes',
  'isLocked',
];

interface NumberInputProps extends Omit<TextFieldProps, 'type' | 'onChange'> {
  value: number;
  adornment?: string;
  allowNegative?: boolean;
  onChangeValue?: (val: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({ value, adornment, allowNegative, onChangeValue, ...rest }) => {
  const [intermediaryValue, setIntermediaryValue] = useState<string>(() => value.toString());

  useEffect(() => {
    setIntermediaryValue(value.toString());
  }, [value]);

  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const potentialNumber = evt.target.value.trim();
    setIntermediaryValue(potentialNumber);

    if (potentialNumber === '' || (allowNegative && potentialNumber === '-')) {
      return;
    }

    const num = Number(potentialNumber);
    if (!Number.isNaN(num)) {
      onChangeValue?.(num);
    }
  }, [allowNegative, onChangeValue]);

  return (
    <TextField
      {...rest}
      value={intermediaryValue}
      onChange={onChange}
      type="number"
      InputProps={{
        endAdornment: adornment ? (<InputAdornment position="end">{adornment}</InputAdornment>) : undefined,
      }}
      sx={{
        flex: 1,
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' }
      }}
    />
  );
};

interface FieldInputProps {
  type: string,
  invalid: boolean,
  selected: SearchPattern;
  onChange: (sp: Partial<SearchPattern>) => void;
}

const FieldInput: React.FC<FieldInputProps> = ({ type, invalid, selected, onChange }) => {
  const t = useTranslations('pages.map.searchPatterns.overlay');
  const { coordinate } = useUnitSettings();

  const coordFormat = () => {
    switch (coordinate) {
      case 'coordinatesDMS':
        return 'dms';
      case 'coordinatesDDM':
        return 'dm';
      default:
        return 'n';
    }
  };

  switch (type) {
    case 'name':
      return (
        <TextField
          label={t('fields.name')}
          value={selected.name}
          onChange={e => onChange({ name: e.target.value })}
          error={invalid}
          helperText={invalid ? t('fieldErrors.name') : undefined}
          variant="outlined"
          size="small"
          fullWidth
        />
      );
    case 'notes':
      return (
        <TextField
          label={t('fields.notes')}
          value={selected.notes}
          onChange={e => onChange({ notes: e.target.value })}
          error={invalid}
          helperText={invalid ? t('fieldErrors.notes') : undefined}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          maxRows={5}
        />
      );
    case 'origin':
      return (
        <>
          <LatitudeInput
            label={t('fields.latitude')}
            value={selected.origin.lat}
            onChangeValue={lat => {
              if (lat) onChange({ origin: { lng: selected.origin.lng, lat } });
            }}
            error={invalid}
            helperText={invalid ? t('fieldErrors.latitude') : undefined}
            displayFormat={coordFormat()}
            size="small"
            fullWidth
          />
          <LongitudeInput
            label={t('fields.longitude')}
            value={selected.origin.lng}
            onChangeValue={lng => {
              if (lng) onChange({ origin: { lng, lat: selected.origin.lat } });
            }}
            error={invalid}
            helperText={invalid ? t('fieldErrors.longitude') : undefined}
            displayFormat={coordFormat()}
            size="small"
            fullWidth
          />
        </>
      );
    case 'firstTurnDirection':
    case 'turnDirection':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      if (invalid) throw new Error(`${type} should always be valid`);
      return (
        <TextField
          label={t(`fields.${type}`)}
          value={selected[(type as keyof SearchPattern)]}
          onChange={e => {
            if (e.target.value === 'LEFT' || e.target.value === 'RIGHT') {
              onChange({ [type]: e.target.value });
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          select
        >
          <MenuItem value="LEFT">{t('fieldValues.direction.left')}</MenuItem>
          <MenuItem value="RIGHT">{t('fieldValues.direction.right')}</MenuItem>
        </TextField>
      );
    case 'orientation':
    case 'orientationArc':
    case 'legCount':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      return (
        <NumberInput
          label={t(`fields.${type}`)}
          value={selected[(type as keyof SearchPattern)] as number}
          onChangeValue={val => onChange({ [type]: val })}
          error={invalid}
          helperText={invalid ? t(`fieldErrors.${type}`, { type: selected.type }) : undefined}
          size="small"
          fullWidth
        />
      );
    case 'trackSpacingMetres':
    case 'legLengthMetres':
    case 'legLengthRangeMetres':
      if (!(type in selected)) throw new Error(`${type} should exist on object`);
      return (
        <DistanceInput
          label={t(`fields.${type}`)}
          value={selected[(type as keyof SearchPattern)] as number}
          onChangeValue={val => onChange({ [type]: val })}
          error={invalid}
          helperText={invalid ? t(`fieldErrors.${type}`, { type: selected.type }) : undefined}
          size="small"
          fullWidth
        />
      );
    case 'isLocked':
      if (invalid) throw new Error(`${type} should always be valid`);
      return (
        <FormControlLabel
          label={t('fields.locked')}
          control={(
            <Checkbox
              checked={selected.isLocked}
              onChange={e => onChange({ isLocked: e.target.checked })}
            />
          )}
        />
      );
    default:
      throw new Error(`unsupported field type: ${type}`);
  }
};

export const SearchPatternsOverlay: React.FC = () => {
  const t = useTranslations('pages.map.searchPatterns');
  const dispatch = useDispatch();
  const { searchPattern: selected, invalidFields } = useSelector(selectSearchPatternOverlayState);

  const fields = useMemo(() => {
    if (!selected) return null;
    return fieldTypes
      .filter(f => Object.keys(selected).includes(f))
      .map(f => ({
        type: f,
        invalid: invalidFields.includes(f),
      }));
  }, [invalidFields, selected]);

  const onChange = (sp: Partial<SearchPattern>) => {
    dispatch(updateSearchPatternOverlay(sp));
  };

  if (!selected) return null;

  return (
    <Paper elevation={0} sx={{ p: 3, pointerEvents: 'initial', textAlign: 'left' }}>
      <Stack gap={3}>
        <Typography variant="h4" component="div">{t('overlay.title', { name: selected.name })}</Typography>
        {fields?.map(({ type, invalid }) => (
          <FieldInput
            key={type}
            type={type}
            invalid={invalid}
            selected={selected}
            onChange={onChange}
          />
        ))}
        <Stack direction="row" gap={3}>
          <TPButton
            variant="destructive"
            onClick={() => dispatch(openDeleteSearchPatternDialog(selected))}
            fullWidth
          >
            {t('overlay.delete')}
          </TPButton>
          <TPButton
            variant="outlined"
            onClick={() => dispatch(setSelectedSearchPattern({ id: undefined }))}
            fullWidth
          >
            {t('overlay.close')}
          </TPButton>
        </Stack>
      </Stack>
    </Paper>
  );
};
