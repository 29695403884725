import React from 'react';
import { Divider, Box, Grid, Stack, Typography } from '@mui/material';
import { useDeviceConfigChanges } from 'apis/rest/devices/hooks';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { DateTime } from 'luxon';
import DatetimeView from 'components/shared/datetime';
import { useStaff } from 'hooks/session/useStaff';
import { displayKey } from 'helpers/config';

const Item = ({ title, description }: { title: string, description: string | boolean | number }): JSX.Element | null => {
  if (title.endsWith('OnOff') && typeof description === 'boolean') {
    return (
      <>
        <Grid item xs={4} component="dt">{displayKey(title)}:</Grid>
        <Grid item xs={8} component="dd">{description ? 'On' : 'Off'}</Grid>
      </>
    );
  }
  return (
    <>
      <Grid item xs={4} component="dt">{displayKey(title)}:</Grid>
      <Grid item xs={8} component="dd">{description === '' ? '-' : description}</Grid>
    </>
  );
};

const ViewPartialConfiguration = ({ partialConfig }: { partialConfig: Partial<Rock7Configuration> }) => (
  <>{
    Object.entries(partialConfig).map(e => {
      if (typeof e[1] !== 'object') return null;

      const elementKeys = Object.entries(e[1]);
      return (
        <Box key={`entry-${e[0]}`}>
          <Typography variant="h6">{displayKey(e[0])}</Typography>
          <Grid container component="dl">
            {elementKeys.map(kv => <Item key={kv[0]} title={kv[0]} description={kv[1]} />)}
          </Grid>
        </Box>
      );
    })
  }
  </>
);

export const DeviceConfigurationHistory = ({ device }: { device: DeviceBasic }): JSX.Element | null => {
  const configChangesQuery = useDeviceConfigChanges(device.id);
  const isStaff = useStaff();

  if (!isStaff) { return null; }

  if (configChangesQuery.isLoading || !configChangesQuery.data) {
    return (
      <SettingsSection title="Configuration History (staff only)" description="History of all changes made to the configuration">
        <DetailPanel p={3}>
          <Box py={2}><LoadingIcon size={50} /></Box>
        </DetailPanel>
      </SettingsSection>
    );
  }

  if (configChangesQuery.data.length === 0) {
    return null;
  }

  return (
    <SettingsSection title="Configuration History (staff only)" description="History of all changes made to the configuration">
      <DetailPanel p={3}>
        <Stack spacing={2} divider={<Divider />}>
          {configChangesQuery.data.map(c => (
            <Stack spacing={1}>
              <Typography>Config at <DatetimeView value={DateTime.fromISO(c.loggedTime).toJSDate()} /></Typography>
              <Box>
                {c.added && (<ViewPartialConfiguration partialConfig={c.added} />)}
                {c.changed && (<ViewPartialConfiguration partialConfig={c.changed} />)}
              </Box>
            </Stack>
          ))}
        </Stack>
      </DetailPanel>
    </SettingsSection>
  );
};
