import React from 'react';
import 'components/shared/errorBoundary/errorStyles.css';
import TracplusIcon from 'components/shared/icons/tracplusIcon';
import getStyles from './error-styles';

const ErrorPage = () => {
  const styles = getStyles();

  const resetEverything = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };

  // TODO: this page isn't translated
  return (
    <section style={styles.wrapper}>
      <div style={styles.message}>
        <div style={styles.logoWrapper}>
          <TracplusIcon overrideStyle={styles.logo} />
        </div>
        <div style={styles.textWrapper}>
          <div>
            <h3 style={styles.h3}>Oops, you have encountered an error</h3>
            <p style={styles.text}>We apologise for the disruption, and appreciate your patience.</p>
            <div style={styles.buttonsWrapper}>
              <a href="/" style={styles.linkButton}>
                <button type="button" style={styles.refreshButton}>Home</button>
              </a>
              <button type="button" style={styles.resetButton} onClick={resetEverything}>Reset Application</button>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.imageWrapper}>
        <div style={styles.image} />
      </div>
    </section>
  );
};

export default ErrorPage;
