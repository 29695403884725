import { DateTime } from 'luxon';

const { PI, sin, cos, tan, asin, atan2: atan } = Math;
const rad = PI / 180;

const dayMs = 1000 * 60 * 60 * 24;
const J1970 = 2440588;
const J2000 = 2451545;

function toJulian(date: number) { return date / dayMs - 0.5 + J1970; }
function toDays(date: number) { return toJulian(date) - J2000; }

// general calculations for position

const e = rad * 23.4397; // obliquity of the Earth

function rightAscension(l: number, b: number) { return atan(sin(l) * cos(e) - tan(b) * sin(e), cos(l)); }
function declination(l: number, b: number) { return asin(sin(b) * cos(e) + cos(b) * sin(e) * sin(l)); }

function solarMeanAnomaly(d: number) { return rad * (357.5291 + 0.98560028 * d); }

function eclipticLongitude(M: number) {
  const C = rad * (1.9148 * sin(M) + 0.02 * sin(2 * M) + 0.0003 * sin(3 * M)); // equation of center
  const P = rad * 102.9372; // perihelion of the Earth

  return M + C + P + PI;
}

function sunCoords(d: number) {
  const M = solarMeanAnomaly(d);
  const L = eclipticLongitude(M);

  return {
    dec: declination(L, 0),
    ra: rightAscension(L, 0)
  };
}

const equationOfTime = (time: DateTime) => {
  const D = 6.24004077 + 0.01720197 * (365.25 * (time.year - 2000) + time.ordinal);
  return -7.659 * sin(D) + 9.863 * sin(2 * D + 3.5932);
};

export const subsolarPoint = (time: DateTime): [number, number] => {
  const { dec: lat } = sunCoords(toDays(time.toMillis()));
  const utcTime = time.setZone('UTC');
  const lon = -15 * ((utcTime.hour + (utcTime.minute / 60) + (utcTime.second / 3600)) - 12 + (equationOfTime(time) / 60));
  return [lon, lat / rad];
};
