import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import type { GeofenceResponseItem } from 'apis/rest/geofence/types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useAltitude } from 'hooks/units/useAltitude';
import { DetailsGrid, OverlayWrapper, ScoreCard } from './common';

interface GeofenceOverlayProps {
  hoveredGeofence?: GeofenceResponseItem
  highContrast: boolean
}

export const HoveredGeofenceOverlay = ({ hoveredGeofence, highContrast }: GeofenceOverlayProps) => {
  const t = useTranslations('pages.map.geofences');
  const altitudeControlsEnabled = useFeatureFlag('altitudeControlsNewGeofenceUi');
  const altitude = useAltitude();

  const hasAltitudeRestriction = useMemo(() => {
    const min = hoveredGeofence?.altitudeRestriction?.minimumAltitude.altitude;
    const max = hoveredGeofence?.altitudeRestriction?.maximumAltitude.altitude;
    return min !== undefined || max !== undefined;
  }, [hoveredGeofence]);

  return (
    (hoveredGeofence) ? (
      <OverlayWrapper highContrast={highContrast}>
        <DetailsGrid>
          <ScoreCard label={t('geofence')} wide>
            <Typography variant="h3" sx={{ overflowWrap: 'break-word' }}>{hoveredGeofence.name}</Typography>
          </ScoreCard>
          {hoveredGeofence.description && <ScoreCard label={t('description')} value={hoveredGeofence.description} wide maxValueLines={5} />}
        </DetailsGrid>
        <DetailsGrid>
          <ScoreCard label={t('category')} value={t('categoryValue', { category: hoveredGeofence.category })} wide />
          {altitudeControlsEnabled && hasAltitudeRestriction && (
            <>
              <ScoreCard
                label={t('minAltitude')}
                value={altitude.create(hoveredGeofence.altitudeRestriction?.minimumAltitude.altitude ?? 0).format()}
              />
              <ScoreCard
                label={t('maxAltitude')}
                value={altitude.create(hoveredGeofence.altitudeRestriction?.maximumAltitude.altitude ?? 0).format()}
              />
            </>
          )}
        </DetailsGrid>
      </OverlayWrapper>
    ) : null
  );
};
