import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  gridWrapper: {
    height: '100%'
  },
  formWrapper: {
    flex: 1,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '285px',
    color: theme.palette.common.text,
    textAlign: 'center'
  },
  iconWrapper: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.darkBlue,
    padding: '12px',
    borderRadius: '50px',
    width: '65px',
    height: '65px',
    margin: '0 auto',
    marginBottom: '15px',
    '& svg': {
      display: 'block',
      fontSize: '2.5rem',
      width: '40px',
      height: '40px',
      lineHeight: '1',
      marginRight: '0px'
    }
  },
  title: {
    color: theme.palette.common.offBlack,
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '-.5px',
    marginBottom: '10px'
  },
  subtitle: {
    fontSize: '.9rem',
    fontWeight: '500',
    marginBottom: '5px'
  },
  registerMessage: {
    fontSize: '.9rem',
    fontWeight: '500',
    marginBottom: '10px',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  errorMessage: {
    color: 'red',
    fontSize: '.8rem',
    margin: theme.spacing(1)
  },
  imageOfTheWeek: {
    position: 'relative',
    minHeight: '200px',
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      height: '120px',
      background: 'linear-gradient(to top, rgba(0, 0, 0, .75) 0%, transparent 100%)'
    }
  },
  imageOfTheWeekText: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '0px',
    color: theme.palette.common.white,
    fontWeight: '500',
    textAlign: 'right',
    padding: theme.spacing(2, 3),
    zIndex: 1
  }
}));
