import React from 'react';
import {
  Grid,
  Link,
  Typography
} from '@mui/material';

const OrganisationContact = ({
  organisation,
  mode,
}) => {
  const org = {
    contactName: mode === 'primary' ? organisation.contactName : organisation.billingContactName,
    phone: mode === 'primary' ? organisation.contactNumberPrimary : organisation.billingNumberPrimary,
    phoneAlternate: mode === 'primary' ? organisation.contactNumberAlternate : organisation.billingNumberAlternate,
    email: mode === 'primary' ? organisation.email : organisation.billingEmail,
    address: mode === 'primary'
      ? [organisation.contactAddressLine1, organisation.contactAddressLine2, organisation.contactAddressLine3].filter(s => s !== '').join(', ')
      : [organisation.billingAddressLine1, organisation.billingAddressLine2, organisation.billingAddressLine3].filter(s => s !== '').join(', '),
    city: mode === 'primary' ? organisation.contactCity : organisation.billingCity,
    state: mode === 'primary' ? organisation.contactState : organisation.billingState,
    postcode: mode === 'primary' ? organisation.contactZip : organisation.billingZip,
    country: mode === 'primary' ? organisation.contactCountry : organisation.billingCountry,
  };
  if (!organisation) {
    return <Typography variant="body1">No contact set</Typography>;
  }
  return (
    <Grid container spacing={3}>
      {mode === 'billing' && (
        <Grid item xs={12}>
          <Typography variant="body2" size="large">{organisation.billingCompanyName}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2" size="large">{org.contactName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <Link href={`tel:${org.phone}`} underline="hover">
            {org.phone}
          </Link>
        </Typography>
        {org.phoneAlternate && (
          <Typography variant="body1">
            <Link href={`tel:${org.phoneAlternate}`} underline="hover">
              {org.phoneAlternate}
            </Link>
          </Typography>
        )}
        {org.email.split(';').map(email => (
          <Typography key={email} variant="body1" style={{ overflowWrap: 'break-word' }}><Link href={`mailto:${email}`} underline="hover">{email}</Link></Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{org.address}</Typography>
        <Typography variant="body1">{org.city}</Typography>
        <Typography variant="body1">{`${org.state} ${org.postcode}`}</Typography>
        <Typography variant="body1">{org.country}</Typography>
      </Grid>
    </Grid>
  );
};

export default OrganisationContact;
