import { useMemo } from 'react';
import { minBy, maxBy } from 'lodash/fp';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { GeocodedLocation, reverseGeocodeBatch } from 'apis/rest/geocoding/requests';
import { AllInferredEventIds, InferredEventId, ReportWithInferredEvents } from 'apis/rest/inferredEvents/types';
import { useInferredEventsForReports } from 'repositories/inferredEvents/hooks';
import { eventDoesntMakeSense, getMostSignificantEvent } from './events';

export const eventMap = {
  EVT_STARTUP: 'S',
  EVT_SHUTDOWN: 's',
  EVT_TAKEOFF: 'T',
  EVT_LANDING: 'L',
  EVT_ENGINEON: 'S',
  EVT_ENGINEOFF: 's',
  INFERRED_MOVEMENT_START: 'S',
  INFERRED_MOVEMENT_END: 's',
  INFERRED_TAKEOFF: 'T',
  INFERRED_LANDING: 'L',
};
export const legEventTypes = Object.keys(eventMap).join(',');
export const stopEvents = [
  eventMap.EVT_ENGINEOFF,
  eventMap.EVT_SHUTDOWN,
  eventMap.EVT_LANDING,
  eventMap.INFERRED_MOVEMENT_END,
  eventMap.INFERRED_LANDING,
];

// Disables inferred events if there is any corresponding real event
const calcInferredEventsToDisable = (reports: ReportWithInferredEvents[], enableInferredEvents: boolean): readonly (InferredEventId | string)[] => {
  if (!enableInferredEvents) {
    return AllInferredEventIds;
  }

  const disabled: InferredEventId[] = [];
  const events = reports.flatMap(r => r.events);
  if (events.some(e => ['EVT_STARTUP', 'EVT_ENGINEON'].includes(e))) disabled.push('INFERRED_MOVEMENT_START');
  if (events.some(e => ['EVT_SHUTDOWN', 'EVT_ENGINEOFF'].includes(e))) disabled.push('INFERRED_MOVEMENT_END');
  if (events.some(e => ['INFERRED_TAKEOFF'].includes(e))) disabled.push('INFERRED_TAKEOFF');
  if (events.some(e => ['INFERRED_LANDING'].includes(e))) disabled.push('INFERRED_LANDING');
  return disabled as readonly InferredEventId[];
};

const reducePrecision = (coord?: number): number => +parseFloat(String(coord)).toFixed(5);
const reduceReportPrecision = (reports: Report[], leg: LegRaw): { startLat: number; startLon: number; endLat: number, endLon: number } => {
  const validReports = reports.filter(r => r.isValid && r.longitude !== 0 && r.latitude !== 0);
  const firstValidReport = minBy(r => r.received, validReports);
  const lastValidReport = maxBy(r => r.received, validReports);

  return ({
    startLat: reducePrecision(firstValidReport?.latitude),
    startLon: reducePrecision(firstValidReport?.longitude),
    endLat: reducePrecision(leg.end !== undefined ? lastValidReport?.latitude : undefined),
    endLon: reducePrecision(leg.end !== undefined ? lastValidReport?.longitude : undefined)
  });
};
// Regex that define legs, once mapped using eventMap
const legRegex = /S?TL(?!s)|TS*Ls?|Ss|STLs/g;

interface LegRaw {
  start: number;
  end?: number;
  takeoff?: number;
  landing?: number
}

const findEvents = (reports: ReportWithInferredEvents[], eventId: string, inferredEventId: InferredEventId, reversed = false) => {
  const reportsOrdered = reversed ? [...reports].reverse() : reports;
  const takeoff = reportsOrdered.find(r => r.events.includes(eventId));
  if (takeoff !== undefined) {
    return takeoff;
  }
  return reportsOrdered.find(r => r.inferredEvents?.includes(inferredEventId));
};

const processLegs = async (legsStringMapped: string, legIndices: number[], reports: ReportWithInferredEvents[], existingLegs: Leg[], assetCategory: string): Promise<Leg[]> => {
  let lastEvent = 0;
  let firstEvent = legIndices.length;
  const geocodingCache: GeocodedLocation[] = localStorage?.geocodingCache ? JSON.parse(localStorage?.geocodingCache).filter((g: GeocodedLocation) => g.category) : [];

  const legMatches: LegRaw[] = [...legsStringMapped.matchAll(legRegex)].map(match => {
    const legStart = match.index || 0;
    const legEnd = legStart + match[0].length - 1;
    lastEvent = Math.max(lastEvent, legEnd);
    firstEvent = Math.min(firstEvent, legStart);

    const indices: LegRaw = {
      start: legIndices[legStart],
      end: legIndices[legEnd],
    };

    return indices;
  });

  const lastIndex = legMatches.at(-1)?.end ?? -1;
  if (lastIndex !== reports.length - 1) {
    // find start event after last complete leg
    const startAfter = legIndices.filter((ri, si) => ri > lastIndex && !stopEvents.includes(legsStringMapped[si])).at(0);
    if (startAfter !== undefined) {
      legMatches.push({ start: startAfter });
    }
  }

  if (legMatches.length === 0 && reports.length > 1) {
    const firstReportIsShutdown = legIndices.at(0) === 0 && stopEvents.includes(legsStringMapped.at(0) ?? '');
    if (!firstReportIsShutdown) {
      legMatches.push({ start: 0, end: legIndices.at(0) });
    }
  }

  // 1. make list of leg lat/lons that aren't in geocodingCache
  const locationsToGeocode: { latitude: number, longitude: number }[] = [];
  // TODO: this is horrifically inefficient, needs an implementation that doesn't have 4 nested loops
  legMatches.forEach(leg => {
    const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(legReports, leg);
    const cachedStartLocation = geocodingCache.find(g => g.lat === startLat && g.lon === startLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === startLat && g.longitude === startLon);
    const cachedEndLocation = geocodingCache.find(g => g.lat === endLat && g.lon === endLon && g.category === assetCategory) || locationsToGeocode.find(g => g.latitude === endLat && g.longitude === endLon);
    if (!cachedStartLocation) locationsToGeocode.push({ latitude: startLat, longitude: startLon });
    if (!cachedEndLocation) locationsToGeocode.push({ latitude: endLat, longitude: endLon });
  });

  // 2. fetch locations for above list and add it to cache
  const validLocationsToGeocode = locationsToGeocode.filter(l => !Number.isNaN(l.latitude) && !Number.isNaN(l.longitude));
  const geocodedLocations = validLocationsToGeocode.length > 0 ? await reverseGeocodeBatch(assetCategory, validLocationsToGeocode) : [];
  const updatedCache = geocodingCache.concat(geocodedLocations);
  localStorage.setItem('geocodingCache', JSON.stringify(updatedCache));

  // 3. return legs with locations from geocodingCache
  return legMatches.flatMap(leg => {
    // return existing leg if it exists instead of requesting geocoding again
    const existingLeg = existingLegs?.length && existingLegs?.find(l => l.id === reports[leg.start].id);
    if (existingLeg) return [];

    const legReports = reports.slice(leg.start, leg.end === undefined ? undefined : leg.end + 1);

    // reject if no reports in the leg have a valid position
    if (legReports.every(r => !r.isValid)) return [];

    const takeoff = findEvents(legReports, 'EVT_TAKEOFF', 'INFERRED_TAKEOFF');
    const landing = findEvents(legReports, 'EVT_LANDING', 'INFERRED_LANDING', true);

    // get from/to from cache and return
    const {
      startLat, startLon, endLat, endLon
    } = reduceReportPrecision(legReports, leg);
    const from = updatedCache.find(g => g.lat === startLat && g.lon === startLon)?.location;
    const to = updatedCache.find(g => g.lat === endLat && g.lon === endLon)?.location;

    // If this leg doesn't have an end, use the time of the most recent report as the end for accurate elapsed time
    const endReport = legReports.at(-1);

    return [{
      id: reports[leg.start].id,
      deviceId: reports[leg.start].deviceId,
      assetId: reports[leg.start].assetId,
      start: reports[leg.start].received,
      end: endReport!.received,
      from,
      to,
      complete: leg.end !== undefined && leg.end !== leg.start,
      takeoff: takeoff?.received || null,
      landing: landing?.received || null,
      reports: {
        start: reports[leg.start],
        end: endReport!,
        takeoff,
        landing,
      },
    }];
  });
};

const preProcessLegs = (reports: ReportWithInferredEvents[], existingLegs: Leg[], assetCategory: string, deviceMake: string | null, enableInferredEvents = false): Promise<Leg[]> => {
  if (reports.length === 0) return Promise.resolve([]);
  // Create string representation of leg starts/ends
  // Create list of indices of reports relating to string representation
  let legStringMapped = '';
  const legIndices: number[] = [];
  const reportsAsc = reports.slice(0).sort((a, b) => a.received - b.received);
  const disabledInferredEvents = calcInferredEventsToDisable(reportsAsc, enableInferredEvents);
  reportsAsc.forEach((report, index) => {
    const { eventId } = getMostSignificantEvent(report, enableInferredEvents, undefined);
    if (disabledInferredEvents.includes(eventId)) {
      // do not include inferred events if they are disabled, `getMostSignificantEvent` will prioritise real events
      // over inferred events so we don't need to check if `eventId` is an inferred event first
      return;
    }
    if (eventId && Object.keys(eventMap).includes(eventId) && !eventDoesntMakeSense(report, assetCategory, deviceMake)) {
      // @ts-ignore
      const mapped = eventMap[eventId];
      // compress SSss to Ss, with the last S and the first s
      if (legStringMapped.at(-1) !== mapped) {
        legStringMapped += mapped;
        legIndices.push(index);
      } else {
        legIndices[legIndices.length - 1] = index;
      }
    }
  });
  return processLegs(legStringMapped, legIndices, reportsAsc, existingLegs, assetCategory);
};

export const getLegsQueryKey = (reportsForAsset: Report[], numInferredEvents: number | undefined) => ['legs', reportsForAsset[0]?.deviceId, reportsForAsset.length, numInferredEvents, maxBy('received', reportsForAsset)?.received];
export const getLegsQueryFn = (selectedAsset: AssetWithDevice | AssetBasic, reportsForAsset: ReportWithInferredEvents[], enableInferredEvents: boolean) => (() => preProcessLegs(reportsForAsset, [], selectedAsset.category, selectedAsset?.deviceMake, enableInferredEvents));

export const useQueryLegs = <T = Leg[]>(selectedAsset: AssetWithDevice | AssetBasic, reportsForAsset: Report[], options: Omit<UseQueryOptions<Leg[], unknown, T>, 'queryFn' | 'queryKey'>) => {
  const enableInferredEvents = useFeatureFlag('frontendInferredEventsLegs');
  const reports = useInferredEventsForReports(reportsForAsset, enableInferredEvents);
  const numInferredEvents = useMemo(() => reports?.flatMap(r => r.inferredEvents ?? []).length, [reports]);
  return useQuery({
    queryFn: getLegsQueryFn(selectedAsset, reports ?? [], enableInferredEvents ?? false),
    queryKey: getLegsQueryKey(reports ?? [], numInferredEvents),
    gcTime: 30_000,
    ...options,
  });
};
export default preProcessLegs;
