import React from 'react';

const UpArrow = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z" />
    <g>
      <rect className="icon-detail" fill="#fff" x="9.2" y="3.7" transform="matrix(0.7098 -0.7044 0.7044 0.7098 -2.1559 9.1018)" width="1.6" height="6.9" />
      <rect className="icon-detail" fill="#fff" x="2.8" y="6.4" transform="matrix(0.7098 -0.7044 0.7044 0.7098 -3.2294 6.4746)" width="6.9" height="1.6" />
    </g>
  </svg>
);

export default UpArrow;
