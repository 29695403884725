import React from 'react';
import { useTranslations } from 'use-intl';
import { Alert, Typography } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import DeleteContactGroupDialog, { DeleteContactGroupDialogStatus } from 'components/dialogs/contactGroups/deleteGroup';
import { DeleteContactGroupDialogProps } from '../../contactGroups/deleteGroup/deleteGroup-dialog';

export const DeleteIceContactGroupDialog = ({ group, mutation, onClose, ...props }: Omit<DeleteContactGroupDialogProps, 'title' | 'ariaLabel' | 'children'>): JSX.Element => {
  const t = useTranslations('dialogs.ice.delete');
  const snackbar = useSnackbar();
  const assetCount = group.deviceAndAssetIds.length;

  return (
    <DeleteContactGroupDialog
      {...props}
      group={group}
      mutation={mutation}
      title={t('title')}
      ariaLabel={t('title')}
      onClose={(status, id) => {
        if (status === DeleteContactGroupDialogStatus.Removed) {
          snackbar.display({
            id: `iceContactGroupDeleted.${group.id}`,
            text: t('snackbar.success', { name: group.name }),
            type: 'success',
          });
        }
        onClose(status, id);
      }}
    >
      <Typography>
        {t.rich('message', { name: group.name, strong: chunks => <strong>{chunks}</strong> })}
      </Typography>
      <Typography>
        {t(assetCount ? 'assetsWarning' : 'noAssets', { count: assetCount })}
      </Typography>
      {group.isDefault && <Alert severity="error">{t('defaultGroup')}</Alert>}
    </DeleteContactGroupDialog>
  );
};
