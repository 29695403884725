import React, { useRef, useState } from 'react';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { CloudUpload, PlayCircle, Refresh } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { ProvisioningProcessorRow } from '@tracplus/serenity-client';
import LinkAbove from 'components/shared/linkAbove';
import tableIcons from 'components/shared/icons/tableIcons';
import useSnackbar from 'hooks/useSnackbar';
import { useActivateProvisioningForAll, useGetAllProvisioningRows } from 'apis/rest/provisioning/hooks';
import Page from '../../page/page-view';
import useStyles from './provisioningList-styles';
import { DetailPanel } from './detailPanel-view';
import { UploadModal } from './uploadModal-view';
import { ConfirmDialog } from '../assetSearch/components/ConfirmDialog';

const ProvisioningListPage = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [csv, setCsv] = useState<File>();


  const provisioningRows = useGetAllProvisioningRows();

  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const activateMutation = useActivateProvisioningForAll({
    onSettled: () => setActivateDialogOpen(false),
    onSuccess: () => snackbar.display({
      id: 'activate',
      text: 'Activating all waiting devices',
      type: 'success',
    }),
    onError: () => snackbar.display({
      id: 'activate',
      text: 'Error while activating waiting devices',
      type: 'error',
    }),
  });

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = async event => {
    if (!event.target.files?.length) return;
    setCsv(event.target.files[0]);

    // reset it so that repeated uploads of the same file still trigger changehandler.
    event.target.value = '';

    setUploadModalOpen(true);
  };

  const openUpload = () => {
    fileUploadRef.current?.click();
  };

  const refresh = () => {
    provisioningRows.refetch();
  };

  return (
    <Page title="Provisioning">
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container>
            <input ref={fileUploadRef} type="file" id="provisioning-csv" name="provisioning-csv" className={classes.hidden} onChange={changeHandler} />
            <LinkAbove />
            <Box>
              <Typography variant="h1" gutterBottom>Provisioning</Typography>
              <Typography variant="body1" paragraph>Create and provision devices and assets</Typography>
            </Box>
            <Container className={classes.tableContainer}>
              <Box className={classes.materialTable}>
                {/* @ts-ignore */}
                <MaterialTable
                  isLoading={provisioningRows.isLoading || provisioningRows.isFetching}
                  title="Provisioning Rows"
                  icons={tableIcons}
                  columns={[
                    {
                      title: 'Processing ID',
                      field: 'provisioningProcessingId',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      defaultSort: 'asc',
                    },
                    {
                      title: 'Attempts',
                      field: 'attempts',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Make',
                      field: 'deviceMake',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Model',
                      field: 'deviceModel',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Manufacturer Serial',
                      field: 'deviceManufacturerSerial',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'TP Serial',
                      field: 'deviceTpSerial',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                    },
                    {
                      title: 'Owner ID',
                      field: 'deviceOwnerId',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left', fontFamily: 'monospace' },
                    },
                    {
                      title: 'Last Updated',
                      field: 'auditUpdated',
                      headerStyle: { textAlign: 'left' },
                      cellStyle: { textAlign: 'left' },
                      // @ts-ignore row.auditUpdated is an ISO string
                      render: (row: ProvisioningProcessorRow) => DateTime.fromISO(row.auditUpdated).toFormat('D TT'),
                      // @ts-ignore row.auditUpdated is an ISO string
                      customSort: (a, b) => DateTime.fromISO(b.auditUpdated).toUnixInteger() - DateTime.fromISO(a.auditUpdated).toUnixInteger()
                    },
                  ]}
                  data={provisioningRows.isSuccess ? provisioningRows.data : []}
                  actions={[
                    {
                      icon: () => <CloudUpload />,
                      tooltip: 'Upload CSV',
                      isFreeAction: true,
                      onClick: openUpload
                    },
                    {
                      icon: () => <Refresh />,
                      isFreeAction: true,
                      onClick: () => refresh(),
                      tooltip: 'Refresh'
                    },
                    {
                      icon: () => <PlayCircle />,
                      isFreeAction: true,
                      onClick: () => setActivateDialogOpen(true),
                      tooltip: 'Activate All'
                    }]}
                  options={{
                    filtering: true,
                    draggable: false,
                    showTitle: false,
                    search: true,
                    actionsColumnIndex: -1,
                    searchFieldStyle: {
                      borderRadius: '4px',
                      paddingLeft: '18px',
                      paddingRight: '10px'
                    },
                    searchFieldVariant: 'outlined',
                    thirdSortClick: false,
                    pageSize: 100,
                    pageSizeOptions: [10, 25, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: { position: 'sticky', top: 0, background: '#fff' },
                    maxBodyHeight: '650px',
                  }}
                  components={{
                    // eslint-disable-next-line react/destructuring-assignment
                    Action: props => {
                      const { action, data } = props;
                      if (action.position === 'toolbar') {
                        return (
                          <Button
                            onClick={event => action.onClick(event, data)}
                            className={classes.newButton}
                            variant="contained"
                            startIcon={action.icon()}
                          >
                            {action.tooltip}
                          </Button>
                        );
                      }
                      return (<MTableAction {...props} />);
                    }
                  }}
                  detailPanel={[{
                    tooltip: 'More details',
                    render: row => <DetailPanel row={row.rowData} />
                  }]}
                />
              </Box>
            </Container>
          </Container>
        </Grid>
      </Grid>
      <UploadModal
        file={csv}
        close={() => setUploadModalOpen(false)}
        isOpen={uploadModalOpen}
        onDone={refresh}
      />
      <ConfirmDialog
        open={activateDialogOpen}
        onClose={() => setActivateDialogOpen(false)}
        onConfirm={() => activateMutation.mutate()}
        title="Activate All Devices"
        description="This will advance the provisioning progress for all devices that are awaiting activation. This includes activating the Iridium contracts."
        disabled={activateMutation.isPending}
      />
    </Page>
  );
};

export default ProvisioningListPage;
