import React from 'react';

const Sirius = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 32.965922,54.075949 0.202531,0.218111 c 0,0 9.206079,-0.67007 9.238559,-0.934761 0.644654,-5.253658 -2.866602,-5.141188 -2.866602,-5.141188 0,0 -5.271833,-0.348566 -5.250243,-0.467381 1.414767,-7.785274 2.040895,-13.55404 2.040895,-13.55404 l 12.307693,-0.01558 11.653359,-1.168455 c 0,0 2.031582,0.0929 2.056475,-0.23369 0.519072,-6.809985 -1.666991,-7.182084 -1.666991,-7.182084 L 48.903604,24.16358 36.518015,24.24148 c -1.216945,-9.266578 -0.610563,-9.705325 -2.773125,-9.814996 -0.457025,-1.720051 -1.511199,-2.492696 -1.511199,-2.492696 0,0 -1.054174,0.772645 -1.511199,2.492696 -2.162562,0.109671 -1.55618,0.548418 -2.773125,9.814996 L 15.563778,24.16358 3.7857839,25.596881 c 0,0 -2.1860632,0.372099 -1.6669913,7.182084 0.024893,0.32659 2.0564752,0.23369 2.0564752,0.23369 L 15.828627,34.18111 28.13632,34.19669 c 0,0 0.626128,5.768766 2.040895,13.55404 0.02159,0.118815 -5.250243,0.467381 -5.250243,0.467381 0,0 -3.511256,-0.11247 -2.866602,5.141188 0.03248,0.264691 9.238559,0.934761 9.238559,0.934761 l 0.202531,-0.218111 0.732231,3.084713 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Sirius;
