import React, { ReactNode } from 'react';
import usePermissions from 'hooks/session/usePermissions';
import type { Permissions } from 'selectors/permissions';

interface RequirePermissionProps {
  permission: keyof Permissions;
  permitted: ReactNode;
  forbidden: ReactNode;
}

export const RequirePermission = ({
  permission,
  permitted,
  forbidden,
}: RequirePermissionProps) => {
  const permissions = usePermissions();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {permissions[permission] ? permitted : forbidden}
    </>
  );
};
