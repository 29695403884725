import React, { useState, useEffect } from 'react';
import moment from 'utils/moment';
import {
  Divider, Box, Button, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import { useTranslations } from 'use-intl';
import DisplayModal from 'components/shared/displayModal';
import EventIcon from 'components/shared/icons/eventIcons';
import useTimezone from 'hooks/session/useTimezone';
import { distance, speed, bearing } from 'helpers/unitsOfMeasure';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import clsx from 'clsx';
import useStyles from '../distress-styles';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

interface DistressDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  reports: Report[]
}

type Incident = (Report & { asset?: AssetBasic, eventName: string, eventIcon: string });

const DistressDialog = ({
  open,
  setOpen,
  reports,
}: DistressDialogProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.distress');
  const timezone = useTimezone();
  const { bearing: bearingUnit, altitude: altitudeUnit } = useUnitSettings();

  const assetsQuery = useGetAssetsList().query;

  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null);
  const [incidents, setIncidents] = useState<Incident[]>();
  useEffect(() => {
    if (assetsQuery.isSuccess) {
      const incidentsList = reports.map(r => {
        const asset = assetsQuery.data?.find(a => a.deviceId === r.deviceId);
        const distressEvent = r.events.find(e => e.includes('EVT_DISTRESS'));
        if (!distressEvent) throw new Error('Could not find distress event in distress dialog.');
        return { ...r, asset, eventName: distressEvent, eventIcon: distressEvent };
      });
      setIncidents(incidentsList.sort((a, b) => b.received - a.received));
      setSelectedIncident(incidentsList[0]);
    }
  }, [reports, assetsQuery.isSuccess, assetsQuery.data]);

  const speedUnit = useSpeedByAsset(selectedIncident?.asset);
  const assetLabel = useAssetLabel();

  return (
    <DisplayModal
      open={open}
      setOpen={setOpen}
      modalHeader={t('distressIncidentDetails')}
      shouldCloseOnOverlayClick={false}
    >
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Asset</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents?.map(row => (
              <TableRow className={clsx({ [classes.selected]: selectedIncident?.id === row.id })} onClick={() => setSelectedIncident(row)} key={row.id}>
                <TableCell className={classes.eventIconContainer}><EventIcon type={row.eventIcon} size={24} /></TableCell>
                <TableCell>{assetLabel(row.asset)}</TableCell>
                <TableCell>{row.eventName}</TableCell>
                <TableCell>{moment.unix(row.received).tz(timezone).format('D MMM YYYY H:mm:ss z')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider className={classes.divider} />

      <Grid container className={classes.list}>
        <Grid item xs={3}>{`${t('asset')}:`}</Grid>
        <Grid item xs={9}>
          {reports.length ? ` ${assetLabel(selectedIncident?.asset)}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('time')}:`}</Grid>
        <Grid item xs={9}>
          {(reports.length && selectedIncident) ? ` ${moment.unix(selectedIncident?.received).tz(timezone).format('HH:mm:ss, dddd, D MMM YYYY')}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('altitude')}:`}</Grid>
        <Grid item xs={9}>
          {reports.length ? ` ${distance.fromSI(selectedIncident?.altitude ?? 0, altitudeUnit).toFixed(2)}${distance.label(altitudeUnit)}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('latitude')}:`}</Grid>
        <Grid item xs={9}>
          {reports.length ? ` ${selectedIncident?.latitude.toFixed(2)}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('longitude')}:`}</Grid>
        <Grid item xs={9}>
          {reports.length ? ` ${selectedIncident?.longitude.toFixed(2)}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('speed')}:`}</Grid>
        <Grid item xs={9}>
          {reports.length ? ` ${speed.fromKmh(selectedIncident?.speed ?? 0, speedUnit).toFixed(2)}${speed.label(speedUnit)}` : ` ${t('notAvailable')}`}
        </Grid>
        <Grid item xs={3}>{`${t('courseBearing')}:`}</Grid>
        <Grid item xs={9}>
          {(reports.length && selectedIncident) ? ` ${selectedIncident?.course.toFixed(2)}${bearing.label(bearingUnit, moment.unix(selectedIncident?.received))}` : ` ${t('notAvailable')}`}
        </Grid>
      </Grid>
      <Box className={classes.buttonsGroup}>
        <Button className={classes.backButton} onClick={() => setOpen(false)}>{t('close')}</Button>
      </Box>
    </DisplayModal>
  );
};

export default DistressDialog;
