import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  disabled?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  disabled,
  children,
}) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {children}
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose} disabled={disabled}>
        Cancel
      </Button>
      <Button variant="contained" onClick={onConfirm} color="primary" disabled={disabled}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
