import React from 'react';

const Person = ({ fillColor, outlineOpacity, outlineColor }) => (
    <svg
        version="1.1"
        x="0px"
        y="0px"
        width="64px"
        height="64px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M 31.242948,14.569415 21.025059,29.84465 c 0,0 4.723739,-4.237439 10.023465,-4.197882 5.299731,0.03955 10.162361,4.028465 10.162361,4.028465 z"
            fill={fillColor}
            fillOpacity="1"
            strokeOpacity={outlineOpacity}
            stroke={outlineColor}
            strokeWidth="5"
            style={{
                paintOrder: 'markers stroke fill'
            }}
        />
        <path
            d="m 31.051693,34.34284 c -4.960452,0.08848 -10.147481,3.544768 -10.169002,10.414853 -0.02153,6.870084 4.151239,11.245789 10.699558,11.2593 6.54832,0.01351 9.834813,-4.816871 9.815298,-11.165472 C 41.378028,38.50292 36.012144,34.254354 31.051693,34.34284 Z"
            fill={fillColor}
            fillOpacity="1"
            strokeOpacity={outlineOpacity}
            stroke={outlineColor}
            strokeWidth="5"
            style={{
                paintOrder: 'markers stroke fill'
            }}
        />
    </svg>
);

export default Person;
