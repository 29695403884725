import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  groupsIcon: {
    width: '28px !important',
  },
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  friendsTab: {
    '& .MuiSvgIcon-root': {
      margin: '0 7px 0 0',
      position: 'relative',
      top: '5px',
      fontSize: 20
    }
  },
  Tabs: {
    '& .MuiTab-root': {
      fontSize: '1.3rem',
      fontWeight: '500',
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      minHeight: '55px',
      flexDirection: 'row',
      minWidth: 160
    },
    '& .MuiTab-iconWrapper': {
      marginBottom: theme.spacing(1),
      alignSelf: 'baseline'
    },
    '& .MuiTab-wrapper': {
      display: 'inline'
    }
  },
  contentWrapper: {
    textAlign: 'left'
  },
  buttonGroup: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3)
  },
  button: {
    borderColor: theme.border.color,
    textTransform: 'capitalize',
    fontWeight: '500',
    padding: theme.spacing(2, 5),
    transition: 'all 0.15s',
    '&:hover, &:focus': {
      textShadow: '0 0 0.01px rgba(0, 0, 0, 0.87)',
      backgroundColor: theme.palette.common.white,
      borderColor: theme.border.color
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main
    }
  },
  publicKeyDesc: {
    lineHeight: '38px',
  },
  publicKey: {
    marginLeft: 10,
    '& input': {
      height: 20,
      padding: 10,
      width: 120,
      cursor: 'pointer',
      pointerEvents: 'none',
    }
  },
  copyIcon: {
    flexDirection: 'row',
    '&.MuiSvgIcon-root': {
      zIndex: 100,
      marginLeft: -35,
      marginTop: 9,
      alignSelf: 'center',
      fontSize: '1.6rem',
      color: theme.palette.common.black,
      fill: theme.palette.common.black
    }
  },
}));
