import React, { useCallback } from 'react';
import languages from 'constants/languages';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { updateLocaleSettingField, updateLocalLocaleField } from 'slices/settings/localeSettings.slice';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useAppDispatch } from 'store/types';
import { useUser } from 'hooks/session/useUser';
import useStyles from './languageSelector-styles';
import LanguageOptionButton from './languageOptionButton';

const LanguageSelector = () => {
  const classes = useStyles();
  const language = useLocaleLanguage();
  const isUsercodeUser = useIsUsercodeLogin();
  const uid = useUser()?.id;
  const dispatch = useAppDispatch();

  const handleLanguageChange = useCallback(newLanguage => {
    if (newLanguage === null) return;
    if (uid === undefined || isUsercodeUser) {
      dispatch(updateLocalLocaleField({ field: 'language', value: newLanguage }));
    } else {
      dispatch(updateLocaleSettingField({ field: 'language', value: newLanguage }));
    }
  }, [dispatch, isUsercodeUser, uid]);

  return (
    <div className={classes.container}>
      {languages.map(l => (
        <LanguageOptionButton
          key={l}
          language={l}
          isActive={language === l}
          onClick={lang => handleLanguageChange(lang)}
        />
      ))}
    </div>
  );
};

export default LanguageSelector;
