import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  microsoftLoginButton: {
    boxShadow: 'none',
    marginTop: 0,
    marginBottom: '10px',
    width: 285,
    height: 49,
    backgroundColor: theme.palette.button.microsoftLoginButtonBg,
    border: '1px #8c8c8c solid',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.common.lightGrey,
    }
  }
}));
