import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { InputBase } from '@mui/material';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.text,
    border: theme.border.default,
    width: '100%',
    padding: '6px 26px 6px 12px',
    fontSize: '1rem',
    lineHeight: '2rem',
    height: '2rem',
    marginBottom: '0px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: '4px',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white
    }
  }
}))(InputBase);

export default BootstrapInput;
