import React from 'react';

const BatteryIndicatorView = ({ level }: {level: number}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" style={{ display: 'block' }} aria-hidden="true">
    <path d="M0 6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm2-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2zm14 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
    <rect width={level / 10} height={4} x={2} y={6} rx={1} ry={1} />
  </svg>
);

export default BatteryIndicatorView;
