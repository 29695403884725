import React from 'react';

const AT802F = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 32.030603,9.3849569 c -0.984462,1.2346711 -2.693336,6.0535871 -2.721798,6.9060381 -0.328523,2.477646 -0.370195,4.065069 -0.68833,6.420281 -1.20608,-0.04596 -1.386907,-0.05232 -3.03599,-0.108005 v -2.359029 c 0,0 -0.703783,-7.100609 -2.351795,-7.054866 -1.752124,0.04863 -2.351277,6.953064 -2.351277,6.953064 l -0.02429,2.893363 c -3.166826,0.03871 -12.7851391,0.153611 -19.4339561,0.167949 -0.83421923,1.676742 -0.73937368,8.761819 -0.3395141,8.848038 9.6310992,0.01397 15.6729322,0.0032 19.6975052,0.01085 l -0.0021,0.246497 c 0,0 1.294102,7.130005 2.658236,6.953064 1.171787,-0.151992 2.147156,-6.953064 2.147156,-6.953064 v -0.213424 c 2.936003,0.05692 2.71838,0.20044 2.736784,0.561723 0.18861,3.702505 1.251033,10.369838 1.058333,10.868587 -0.10759,0.27848 -7.177629,0.0619 -7.369059,0.178801 -0.19144,0.11685 -0.183939,0.122379 -0.255799,0.766878 -0.0719,0.644489 -0.06999,5.75324 0.306958,5.71283 9.082617,-0.04173 10.111011,-0.0739 10.111011,-0.0739 1.108302,0.0051 1.227804,0.01043 9.821623,0.0739 0.37699,0.0404 0.378301,-5.068341 0.306441,-5.71283 -0.0719,-0.644499 -0.0639,-0.650028 -0.255281,-0.766878 -0.19143,-0.11685 -7.26147,0.0997 -7.36906,-0.178801 -0.192699,-0.498759 0.869207,-7.166082 1.057817,-10.868587 0.01901,-0.372849 -0.37484,-0.514272 2.737301,-0.567407 v 0.148828 c 0,0 0.975368,6.800555 2.147155,6.952547 1.364134,0.176941 2.658236,-6.952547 2.658236,-6.952547 l -0.0015,-0.176733 c 3.955753,-0.0063 9.823207,0.0032 19.167822,-0.01034 0.39985,-0.08622 1.023882,-7.171296 0.189652,-8.848038 C 55.984381,23.189411 46.366884,23.07452 43.19943,23.035803 l -0.0248,-2.96416 c 0,0 -0.599671,-6.903917 -2.351795,-6.952547 -1.648011,-0.04574 -2.351277,7.054866 -2.351277,7.054866 v 2.431893 C 36.842933,22.710644 36.846143,22.73891 35.435046,22.711276 35.097709,20.756692 35.06232,19.103093 34.746716,16.290995 34.615205,15.545096 33.1063,10.4904 32.030603,9.3849569 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default AT802F;
