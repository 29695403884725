import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  loaderWrapper: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mapLayoutButtonWrapper: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDireaction: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    zIndex: 1051,
    width: 30,
    height: 30,
    border: 'none',
    borderRadius: 8,
    outline: 'none',
    backgroundColor: theme.palette.mapControls.bg,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fg
      }
    },
    cursor: 'pointer',
    transition: 'all 0.15s',
    '&:hover': {
      backgroundColor: theme.palette.mapControls.bgHover
    },
    '&.highContrastClass': {
      backgroundColor: theme.palette.mapControls.bgHighContrast,
      '& svg path': {
        fill: theme.palette.mapControls.fgHighContrast
      }
    }
  },
  speedDial: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    '&.MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
      paddingBottom: '35px'
    },
    '& > button': {
      opacity: 0,
      minWidth: 30,
      minHeight: 30,
      width: 30,
      height: 30,
      margin: 0,
      borderRadius: 8,
      backgroundColor: theme.palette.mapControls.bg,
      color: theme.palette.mapControls.fg,
      boxShadow: 'none',
      transition: 'all 0.15s',
      '&:hover, &:focus': {
        boxShadow: 'none',
        backgroundColor: theme.palette.mapControls.bgHover
      }
    },
    '&.highContrastClass': {
      '& button': {
        backgroundColor: theme.palette.mapControls.bgHighContrast,
        '& svg': {
          '& path, polygon, circle, rect': {
            fill: theme.palette.mapControls.fgHighContrast
          }
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.mapControls.bgDisabledHighContrast,
          '& svg': {
            '& path, polygon, circle': {
              fill: theme.palette.mapControls.fgDisabledHighContrast
            }
          }
        }
      }
    }
  },
  speedDialAction: {
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
    margin: 0,
    '&.MuiSpeedDialAction-fab': {
      margin: 0,
      backgroundColor: theme.palette.mapControls.bg,
      color: theme.palette.mapControls.fg,
      '&:hover': {
        backgroundColor: theme.palette.mapControls.bgHover
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.mapControls.bgActive
      }
    },
    borderRadius: 0,
    '& svg': {
      '& path, polygon, circle, rect': {
        fill: theme.palette.mapControls.fgActive
      }
    },
    boxShadow: 'none',
    transition: 'all 0.15s',
    '&:first-child': {
      borderRadius: '0 0 8px 8px'
    },
    '&:last-child': {
      borderRadius: '8px 8px 0 0 '
    },
    '&.speedDialActionActive': {
      backgroundColor: theme.palette.mapControls.bgActive,
      '&.highContrastClass': {
        backgroundColor: theme.palette.mapControls.bgActive,
        '& svg': {
          '& path, polygon, circle, rect': {
            fill: theme.palette.mapControls.fgActive
          }
        }
      }
    }
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flex: 1
  }
}));
