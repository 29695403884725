import {
  useSupplementaryDataFields,
  useSupplementaryDataForTrip,
  useUpdateSupplementaryData
} from 'apis/rest/trips/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Stack, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Add } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useNotification } from 'contexts/notification/useNotification';
import { tripQueryKeys } from 'apis/rest/trips/queryKeys';
import useSnackbar from 'hooks/useSnackbar';
import { useQueryClient } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import usePermissions from 'hooks/session/usePermissions';
import useFeature from 'hooks/features/useFeature';
import { EditFieldInput, SupplementaryDataFieldView } from './supplementaryDataFieldView';
import useFeatureAssets from "contexts/featureAssets/useFeatureAssets";

export const TripSupplementaryData = ({ trip }: { trip: Trip }) => {
  const supplementaryDataFeatureAssets = useFeatureAssets('reporting.supplementaryData');
  const supplementaryDataEnabled = supplementaryDataFeatureAssets.hasAssetId(trip.assetId);
  const [selectedField, setSelectedField] = useState<SupplementaryDataFieldType | null>(null);
  const [selectedFieldValue, setSelectedFieldValue] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const supplementaryDataQuery = useSupplementaryDataForTrip(trip.id, { enabled: supplementaryDataEnabled });
  const supplementaryDataFieldsQuery = useSupplementaryDataFields({ enabled: supplementaryDataEnabled });
  const updateSuppData = useUpdateSupplementaryData(trip.id);
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  const permissions = usePermissions();

  const supplementaryData = supplementaryDataQuery.data;
  const supplementaryDataFields = supplementaryDataFieldsQuery.data;

  const onSubmit = useCallback(() => {
    if (!selectedField || !selectedFieldValue) { return; }
    const event: SupplementaryDataEvent = {
      dataTypeId: selectedField?.id,
      eventTime: DateTime.fromMillis(trip.startTime).toISO(),
      enteredTime: DateTime.now().toISO(),
      value: selectedFieldValue,
      id: crypto.randomUUID()
    };
    setSubmitting(true);
    updateSuppData.mutate([event], {
      onError: () => {
        setSubmitting(false);
        snackbar.display({ type: 'error', text: 'Failed to add supplementary data.', id: 'serverAddFailedSupplementaryData' });
      },
      onSuccess: () => { setSubmitting(false); }
    });
  }, [snackbar, selectedField, selectedFieldValue, trip.startTime, updateSuppData]);

  const onWebSocketsServerSaveSuccessHandler = useCallback(() => {
    queryClient.invalidateQueries(tripQueryKeys.supplementaryDataForTrip(organisationId, trip.id));
    setSelectedField(null);
    setSelectedFieldValue('');
  }, [queryClient, organisationId, trip.id]);

  const onWebSocketsServerSaveFailedHandler = useCallback(() => {
    snackbar.display({ type: 'error', text: 'Failed to change supplementary data.', id: 'serverAddFailedSupplementaryData' });
    queryClient.invalidateQueries(tripQueryKeys.supplementaryDataForTrip(organisationId, trip.id));
    setSelectedField(null);
    setSelectedFieldValue('');
  }, [snackbar, queryClient, organisationId, trip.id]);

  useNotification('supplementaryDataEventFinished', onWebSocketsServerSaveSuccessHandler, supplementaryDataEnabled);
  useNotification('supplementaryDataEventFailed', onWebSocketsServerSaveFailedHandler, supplementaryDataEnabled);

  const cannotEnterData = trip.status.startsWith('Approved') || !permissions.canEditAssets;

  if (!supplementaryData || !supplementaryDataFields) {
    return (<LoadingIcon size={64} />);
  }
  const unusedFields = supplementaryDataFields.filter(f => !supplementaryData.fields.some(s => s.name === f.fieldName));

  return (
    <Stack p={2} spacing={2}>
      <Box display="grid" gridTemplateColumns="4fr 8fr max-content" gridAutoRows="1fr max-content" gap={2} alignItems="center">
        {supplementaryData.fields.map(f => (
          <>
            <SupplementaryDataFieldView field={f} fields={supplementaryDataFields} update={updateSuppData} trip={trip} />
            <Divider sx={{ gridColumn: '1 / span 3' }} />
          </>
        ))}
        <Autocomplete<SupplementaryDataFieldType>
          fullWidth
          options={unusedFields}
          renderInput={params => <TextField {...params} label="Data field" variant="outlined" />}
          getOptionLabel={f => f.displayName}
          value={selectedField}
          disabled={cannotEnterData}
          onChange={(e, v) => setSelectedField(v)}
        />
        <EditFieldInput
          disabled={cannotEnterData}
          field={selectedField}
          fieldValue={selectedFieldValue}
          setFieldValue={setSelectedFieldValue}
        />
        {submitting
          ? <Box sx={{ display: 'flex' }}><CircularProgress /></Box>
          : (
            <Button
              startIcon={<Add />}
              variant="outlined"
              disabled={!selectedField || !selectedFieldValue || submitting || trip.status.startsWith('Approved')}
              onClick={() => onSubmit()}
              sx={{ alignSelf: 'stretch' }}
            >
              Add
            </Button>
          )}
      </Box>
    </Stack>
  );
};
