import React, { useEffect } from 'react';

function useOnClickOutside(ref: React.RefObject<HTMLDivElement>, handler: () => void) {
  useEffect(
    () => {
      const listener = (event): void => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) return;
        handler();
      };
      document.addEventListener('mousedown', listener);
      return (): void => {
        document.removeEventListener('mousedown', listener);
      };
    },
    [ref, handler]
  );
}

export default useOnClickOutside;
