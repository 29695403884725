import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  contextboxWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'var(--contextbox-width)',
    flex: '1 1 0%',
    zIndex: 3,
    transition: 'all 0.05s'
  },
  contextbox: {
    height: '100%',
    backgroundColor: theme.palette.contextbox.background,
    boxShadow: 'rgba(0, 0, 0, 0.1) -12px 0px 12px -12px',
    zIndex: 3,
  },
  collapsed: {
    maxWidth: '0px!important',
    minWidth: '0px!important'
  },
  contentWrapper: {
    height: '100%',
    overflowY: 'auto'
  },
  loaderWrapper: {
    backgroundColor: theme.palette.contextbox.background,
    height: '100%',
    width: '300px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -12px 0px 12px -12px',
    zIndex: 3,
  },
  noResults: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.text,
    '& p:first-child': {
      fontSize: '1rem',
      fontWeight: '500'
    },
    '& p:last-child': {
      fontWeight: '400',
      fontSize: '.9rem'
    }
  }
}));
