import { persistStore, type Persistor } from 'redux-persist';
import { loggedOut } from 'slices/session/session.slice';
import { di } from '../di';

const storeDep = di.depend('reduxStore');
const firebaseDep = di.depend('firebaseService');

export function persistorFactory(): Persistor {
  const store = storeDep.inject();
  const { auth } = firebaseDep.inject();
  const persistor = persistStore(store, null, () => auth.onAuthStateChanged(user => {
    if (!user) store.dispatch(loggedOut());
  }));

  (window as any).purge = () => persistor.purge();

  return persistor;
}
