import React from 'react';
import { useCoordinates } from 'hooks/settings';
import { styled } from '@mui/system';

const P = styled.p`
  font-size: 0.9rem;
`;

const Coordinate = ({
  latitude,
  longitude,
  Component = P
}) => {
  const latLng = useCoordinates(latitude, longitude);
  return <Component>{latLng}</Component>;
};

export default Coordinate;
