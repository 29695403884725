import React, { useState, useEffect, ReactNode } from 'react';
import { DurationLike, Duration } from 'luxon';
import { MomentInput } from 'moment-timezone';
import moment from 'utils/moment';
import useDuration from 'hooks/units/useDuration';

const parseTimeSpan = (format: string, value?: DurationLike, start?: MomentInput, end?: MomentInput, humanize?: boolean, hideLargeZeros?: boolean, duration: any) => {
  if (!value && value !== 0 && !start) {
    // No data whatsoever
    return '—';
  }

  const actualValue = Duration.fromDurationLike(value || moment(end).diff(moment(start))).shiftTo('hours', 'minutes', 'seconds');

  if (humanize) {
    return actualValue.toHuman({ useGrouping: true });
  }

  if (hideLargeZeros && actualValue.hours === 0) {
    return duration.create(actualValue, 2, actualValue.minutes === 0 ? 'ss\'s\'' : 'mm\'m\' ss\'s\'') as string;
  }

  return duration.create(actualValue, 2, format) as string;
};

interface TimeSpanProps {
  value?: DurationLike
  startDateTime?: MomentInput
  endDateTime?: MomentInput
  humanize?: boolean
  format?: string
  hideLargeZeros?: boolean
  Component?: ReactNode
  [x: string]: unknown
}

// Expects one of:
// `value`: an optional number of ms, or
// `startDateTime`, `endDateTime`: bookend dates, their diff will be used as `value`
// Formatting options:
// `humanize` will return a human-friendly string, or
// `format` is a standard moment format string for formatting the output (w/ sensible default)
const TimeSpan = ({
  value,
  startDateTime,
  endDateTime,
  humanize,
  format = 'hh\'h\' mm\'m\' ss\'s\'',
  Component = 'span',
  hideLargeZeros,
  ...passthroughProps
}: TimeSpanProps) => {
  const duration = useDuration();
  const [actualValue, setActualValue] = useState(parseTimeSpan(format, value, startDateTime, endDateTime, humanize, hideLargeZeros, duration));

  useEffect(() => {
    setActualValue(parseTimeSpan(format, value, startDateTime, endDateTime, humanize, hideLargeZeros, duration));
  }, [value, startDateTime, endDateTime, humanize, format, hideLargeZeros, duration]);

  return (
    // @ts-ignore eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...passthroughProps}>{actualValue}</Component>
  );
};

export default TimeSpan;
