import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DateTime } from 'luxon';
import { FirmwareVersion, Rock7ConfigurationChange } from './types';

export type ApiDevice = Omit<DeviceBasic, 'tpSerial'>;

// TODO: consider removal
const parseDevice = (device: ApiDevice): DeviceBasic => ({
  ...device,
  tpSerial: device.tracPlusSerial,
});

export const getDevicesList = async (organisationId: string, owned: boolean = false): Promise<DeviceBasic[]> => {
  const url = `/organisations/${organisationId}/devices${owned ? '?owned=true' : ''}`;
  const response = await serenityFetch('GET', url, null);
  HttpResponseError.detect(response);
  return (await response.json()).devices.map(parseDevice);
};

export const getDevice = async (organisationId: string, deviceId: number): Promise<DeviceBasic> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/${deviceId}`, null);
  HttpResponseError.detect(response);
  return parseDevice(await response.json());
};

export const assignDeviceToAsset = async (organisationId: string, assetId: number, deviceId: number): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/assets/${assetId}/device`, { deviceId });
  HttpResponseError.detect(response);
};

export const removeDeviceFromAsset = async (organisationId: string, assetId: number, deviceId: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/assets/${assetId}/device/${deviceId}`, null);
  HttpResponseError.detect(response);
};

export const getLatestConfigurations = async (organisationId: string): Promise<DeviceConfiguration[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/config`, null);
  HttpResponseError.detect(response);
  return (await response.json()).configs;
};

export const getConfigChanges = async (organisationId: string, deviceId: number, since = 0): Promise<Rock7ConfigurationChange[]> => {
  const params = [
    ['since', DateTime.fromMillis(since).toUTC().toISO()],
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/${deviceId}/config/changes?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);
  return (await response.json()).configChanges;
};

export enum ConfigurationProfile {
  Aviation = 'Aviation',
  AviationFastTrac = 'AviationFastTrac',
  Maritime = 'Maritime',
  MaritimeFastTrac = 'MaritimeFastTrac',
  Land = 'Land',
  LandFastTrac = 'LandFastTrac',
  FactoryReset = 'FactoryReset',
}

export const setConfigurationProfile = async (organisationId: string, deviceId: number, profile: ConfigurationProfile): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/devices/${deviceId}/config`, { profile });
  HttpResponseError.detect(response);
};

export const getCapabilitiesList = async (organisationId: string): Promise<Capability[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/capabilities`, null);
  HttpResponseError.detect(response);
  return (await response.json()).capabilities || [];
};

export const sendCustomConfig = async (organisationId: string, deviceId: number, req: ConfigRequest): Promise<void> => {
  const response = await serenityFetch('PATCH', `/organisations/${organisationId}/devices/${deviceId}/config`, req);
  HttpResponseError.detect(response);
};

export const requestConfig = async (organisationId: string, deviceId: number): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/devices/${deviceId}/config/request`, {});
  HttpResponseError.detect(response);
};

export const fetchLatestFirmwareVersions = async (organisationId: string): Promise<FirmwareVersion[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/firmware/latest`, null);
  HttpResponseError.detect(response);
  return response.json();
};
