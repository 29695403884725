import {
  Button,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import DateRangePicker from 'components/shared/dateRangePicker';
import SelectAssets from 'components/pages/sharing/selectAssets';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import useTimezone from 'hooks/session/useTimezone';
import useStyles from '../../reporting-styles';

export interface Query {
  assets: number[]
  from: number
  until: number
}

interface FormViewProps {
  assets: AssetWithDevice[]
  isLoading: boolean
  setQuery: (query: Query | null) => void
  query: Query | null
  devicesById?: Record<number, DeviceBasic>
  assetGroups?: AssetGroup[]
}

const Form = ({
  assets,
  isLoading,
  setQuery,
  query,
  devicesById,
  assetGroups
}: FormViewProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('pages.reporting');
  const timezone = useTimezone();

  const [selectedAssetIds, setSelectedAssetIds] = useState(query?.assets ?? []);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>([]);

  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [dateRange, _setDateRange] = React.useState(() => {
    if (query?.from && query?.until) {
      return {
        start: DateTime.fromSeconds(query.from).setZone(timezone).toISO(),
        end: DateTime.fromSeconds(query.until).setZone(timezone).toISO(),
      };
    }
    return {
      start: DateTime.now().setZone(timezone).startOf('day').minus({ days: 6 })
        .toISO(),
      end: DateTime.now().setZone(timezone).startOf('day').toISO(),
    };
  });

  const setDateRange = (value: { start: string; end: string }) => {
    _setDateRange({
      start: DateTime.fromISO(value.start, { zone: timezone }).toISO(),
      end: DateTime.fromISO(value.end, { zone: timezone }).toISO(),
    });
  };

  const allowedDateRange = useMemo(() => ({
    end: DateTime.now().setZone(timezone).startOf('day').toISO()
  }), []);

  useEffect(() => {
    setSelectedAssetIds(assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id));
  }, [selectedDeviceIds]);

  // if selected asset is no longer in assets (e.g. user changed orgs) reset the page
  useEffect(() => {
    if (!selectedAssetIds.length) return;
    if (isLoading) return;
    if (assets.every(a => !selectedAssetIds.includes(a.id))) {
      setQuery(null);
      setSelectedAssetIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetIds, assets, isLoading]);

  useEffect(() => {
    if (!selectedDeviceIds.length) return;
    if (isLoading) return;
  }, [selectedDeviceIds, assets]);

  const getLegs = () => {
    if (!dateRange.start || !dateRange.end) return;

    const from = DateTime.fromISO(dateRange.start).setZone(timezone).startOf('day');
    const until = DateTime.fromISO(dateRange.end).setZone(timezone).endOf('day');

    setQuery({
      assets: selectedAssetIds,
      from: from.toSeconds(),
      until: until.toSeconds(),
    });
  };

  let dateRangeText = DateTime.fromISO(dateRange.start).setZone(timezone).toFormat('d LLL y');
  if (dateRange.start !== dateRange.end) {
    dateRangeText = t('dateRange', {
      from: dateRangeText,
      until: DateTime.fromISO(dateRange.end).setZone(timezone).toFormat('d LLL y'),
    });
  }

  const options = useMemo(() => {
    const list: (AssetBasic | { isAll: boolean })[] = [];
    list.push({ isAll: true });
    return list.concat(assets as (AssetBasic | { isAll: boolean })[]);
  }, [assets]);

  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        getLegs();
      }}
    >
      <Grid container>
        <Grid item xs>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            sx={theme => ({ marginRight: theme.spacing(2) })}
          >
            <SelectAssets
              label={t('selectAssets.label')}
              deviceIds={deviceIds}
              selectedDeviceIds={selectedDeviceIds}
              assetsByDeviceId={assetsByDeviceId}
              devicesById={devicesById}
              setSelectedDeviceIds={value => {
                setSelectedDeviceIds(value);
              }}
              disabled={false}
              assetGroups={assetGroups}
              isLoading={isLoading}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl} sx={theme => ({ marginRight: theme.spacing(2) })}>
            <TextField
              label={t('date')}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onClick={() => setIsDatePickerOpen(true)}
              value={dateRangeText}
              inputProps={{ readOnly: true }}
              InputProps={{ sx: { height: '56px' } }}
              sx={{ width: 300 }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <DateRangePicker
            open={isDatePickerOpen}
            setOpen={setIsDatePickerOpen}
            value={dateRange}
            onChange={setDateRange}
            allowedRange={allowedDateRange}
            maxRangeLength={31}
            renderTitle={({ pendingValue }) => {
              if (pendingValue.end && pendingValue.end !== pendingValue.start) {
                return t('dateRange', {
                  from: DateTime.fromISO(pendingValue.start).toFormat('d LLL y'),
                  until: DateTime.fromISO(pendingValue.end).toFormat('d LLL y'),
                });
              }
              if (pendingValue.start) {
                return DateTime.fromISO(pendingValue.start).toFormat('d LLL y');
              }
              return null;
            }}
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <Button
              type="submit"
              disabled={!selectedAssetIds.length || !dateRange.start || !dateRange.end}
              className={classes.goButton}
              variant="contained"
            >
              {t('getReports')}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
