import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { friendGroupsQueryKeys, friendOrganisationsQueryKeys } from './queryKeys';
import { GroupFriend, OrganisationFriend } from './types';
import {
  addFriendGroup,
  addFriendOrganisation,
  deleteFriendGroup,
  deleteFriendOrganisation,
  fetchFriendGroups,
  fetchFriendOrganisations,
} from './requests';
import { groupsQueryKeys } from '../groups/queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetFriendOrganisationsList = <T = OrganisationFriend[]>(options?: Options<OrganisationFriend[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = friendOrganisationsQueryKeys.lists(organisationId);
  return useQuery({
    queryKey,
    queryFn: () => fetchFriendOrganisations(organisationId),
    ...options,
  });
};

export const useGetFriendGroupsList = <T = GroupFriend[]>(options?: Options<GroupFriend[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = friendGroupsQueryKeys.lists(organisationId);

  return useQuery({
    queryKey,
    queryFn: () => fetchFriendGroups(organisationId),
    ...options,
  });
};

export const useMutateNewFriendOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<OrganisationFriend, 'pubKey'>) => addFriendOrganisation(organisationId, value),
    onSettled: () => queryClient.invalidateQueries({ queryKey: friendOrganisationsQueryKeys.lists(organisationId) }),
    mutationKey: ['addFriendOrganisation'],
  });
};

export const useMutateNewFriendGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<GroupFriend, 'pubKey'>) => addFriendGroup(organisationId, value),
    onSettled: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: friendGroupsQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: groupsQueryKeys.all(organisationId) }),
    ]),
    mutationKey: ['addFriendGroup'],
  });
};

export const useMutateDeleteFriendOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<OrganisationFriend, 'pubKey'>) => deleteFriendOrganisation(organisationId, value),
    onSettled: () => queryClient.invalidateQueries({ queryKey: friendOrganisationsQueryKeys.lists(organisationId) }),
    mutationKey: ['deleteFriendOrganisation'],
  });
};

export const useMutateDeleteFriendGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<GroupFriend, 'pubKey'>) => deleteFriendGroup(organisationId, value),
    onSettled: () => Promise.all([
      queryClient.invalidateQueries({ queryKey: friendGroupsQueryKeys.all(organisationId) }),
      queryClient.invalidateQueries({ queryKey: groupsQueryKeys.all(organisationId) }),
    ]),
    mutationKey: ['deleteFriendGroup'],
  });
};
