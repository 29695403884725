import React from 'react';
import PageStaffAccessAlert from '.';
import { useStaff } from 'hooks/session/useStaff';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeature from 'hooks/features/useFeature';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

interface Props {
  feature: string
  render?: (content: React.ReactElement) => React.ReactElement
}

const useStaffOnlyAccess = (feature: string) => {
  const isStaff = useStaff();
  const featureFlag = useFeatureFlag('featureModules');
  const featureToggle = useFeature(feature);
  const featureAssets = useFeatureAssets(feature);

  if (!isStaff) return false;
  if (featureFlag === undefined) return false;
  if (featureFlag) {
    if (featureAssets.isLoading) return false;
    return !featureAssets.some;
  }
  if (featureToggle === undefined) return false;
  return !featureToggle;
};

const FeaturePageStaffAccessAlert: React.FC<Props> = ({ feature, render = content => content }) => {
  const staffOnly = useStaffOnlyAccess(feature);
  if (!staffOnly) return null;
  return render(<PageStaffAccessAlert />);
};

export default FeaturePageStaffAccessAlert;
