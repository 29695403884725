import { DateTime } from 'luxon';

export interface ApiInferredEventsResponse {
  inferredEvents: ApiInferredEvent[];
}

export interface ApiInferredEvent {
  id: number;
  assetId: number;
  reportId: number;
  reportTime: string;
  eventId: string;
}

export type InferredEventId =
  'INFERRED_MOVEMENT_START'
  | 'INFERRED_MOVEMENT_END'
  | 'INFERRED_TAKEOFF'
  | 'INFERRED_LANDING';

export const AllInferredEventIds: readonly InferredEventId[] = [
  'INFERRED_MOVEMENT_START',
  'INFERRED_MOVEMENT_END',
  'INFERRED_TAKEOFF',
  'INFERRED_LANDING'
];

export interface InferredEvent {
  id: number;
  assetId: number;
  reportId: number;
  reportTime: DateTime;
  eventId: InferredEventId;
}

export interface ReportWithInferredEvents extends Report {
  inferredEvents?: InferredEventId[] | null,
}
