import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { useSelector } from 'react-redux';

import useOrganisationId from 'hooks/session/useOrganisationId';
import useSnackbar from 'hooks/useSnackbar';
import { getRefreshedToken } from 'apis/auth';
import { NotificationContext, NotificationContextValue } from './notificationContext';

const NotificationContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const loggedIn = useSelector<ReduxState, boolean>(state => state.session.loggedIn);
  const organisationId = useOrganisationId();
  const snackbar = useSnackbar();

  const [notificationState, setNotificationState] = useState<NotificationContextValue>(
    () => ({ subject: new Subject<TPCNotification>(), connected: undefined })
  );

  const handleNotification = useCallback((notification: TPCNotification) => {
    if (notification.priority || notification.targetOrganisation === organisationId || notification.targetOrganisation === null) {
      notificationState.subject.next(notification);
    }
  }, [notificationState.subject, organisationId]);

  useEffect(() => {
    if (!loggedIn || !organisationId) return () => undefined;
    let isActive = true;

    const connection = new HubConnectionBuilder()
      .withUrl(`${import.meta.env.VITE_WEBSOCKET_BASE_URL}/organisations/${organisationId}`, {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: getRefreshedToken,
        skipNegotiation: true
      })
      .withAutomaticReconnect()
      .build();

    connection.on('notification', (notification: TPCNotification) => {
      if (isActive) handleNotification(notification);
    });
    connection.onclose(() => {
      if (isActive) setNotificationState(o => ({ ...o, connected: false }));
    });
    connection.start().then(() => {
      if (isActive) setNotificationState(o => ({ ...o, connected: true }));
    });
    connection.onreconnected(() => {
      if (isActive) setNotificationState(o => ({ ...o, connected: true }));
    });

    return () => {
      isActive = false;
      connection.stop();
    };
  }, [loggedIn, organisationId, snackbar, handleNotification]);

  return (
    <NotificationContext.Provider value={notificationState}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
