import React, {
  useCallback, useMemo, useRef, useState
} from 'react';
import {
  Box,
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip
} from '@mui/material';
import Papa from 'papaparse';
import MaterialTable from '@material-table/core';
import { useTheme } from '@mui/material/styles';
import { useUploadProvisioningCSV } from 'apis/rest/provisioning/hooks';
import { useAppDispatch } from 'store/useAppDispatch';
import { displaySnackbar } from 'slices/app.slice';

interface UploadModalProps {
  file: File | undefined
  onDone: () => void
  close: () => void
  isOpen: boolean
}

const expectedNames = [
  'IMEI',
  'TP Serial',
  'SIM ICCID',
  'Owner ID',
  'Operator ID',
  'Make',
  'Model',
  'Script/Firmware',
  'Manufacturer Serial',
  'Gateway',
  'Iridium Plan',
  'Uses Cellular'
];

export const UploadModal = ({
  file,
  onDone,
  isOpen,
  close,
}: UploadModalProps): JSX.Element => {
  const theme = useTheme();
  const [csv, setCsv] = useState<unknown[]>([]);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const tableRef = useRef(null);
  const [errorRows, setErrorRows] = useState<ProvisioningUploadRowError[]>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const dispatch = useAppDispatch();


  const uploadMutation = useUploadProvisioningCSV();

  const upload = async (): Promise<void> => {
    setIsUploading(true);
    if (!file) return;
    const resp = await uploadMutation.mutateAsync({ file });
    setIsUploading(false);
    setUploaded(true);
    if (resp.result?.errors?.length) {
      dispatch(displaySnackbar({
        id: 'provisioning-upload-error',
        type: 'error',
        text: `Encountered validation errors with rows ${resp.result.errors.map(e => e.row).join(', ')}. Check console for details.`
      }));
      setErrorRows(resp.result.errors);
    } else {
      close();
      onDone();
      dispatch(displaySnackbar({
        id: 'provisioning-upload-success',
        type: 'success',
        text: 'Successfully uploaded csv.'
      }));
      setUploaded(false);
    }
  };

  useMemo(() => {
    if (!file) return;
    setErrorRows([]);
    setUploaded(false);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        setCsv(results.data);
      }
    });
  }, [file]);

  // Ensure that the height of the table matches the data
  // @ts-ignore
  useCallback(() => tableRef.current?.dataManager.changePageSize(csv.length), [csv])();

  // Wrap the text with a tooltip for the error (I couldn't find a way to put the tooltip on the cell)
  const renderCell = (rowData: Record<string, string> & { tableData: { index: number } }, column: string): JSX.Element => {
    const rowError = errorRows?.find(r => r.row === rowData.tableData.index);
    if (!rowError) {
      return <span>{rowData[column]}</span>;
    }
    return <Tooltip title={rowError.reason}><span>{rowData[column]}</span></Tooltip>;
  };

  // Change the background color of the cell depending on whether there's an error.
  const renderCellStyle = (rowData: Record<string, string> & { tableData: { index: number } }): React.CSSProperties => {
    const rowError = errorRows?.find(r => r.row === rowData.tableData.index);
    return rowError ? { backgroundColor: theme.palette.error.main } : (uploaded ? { backgroundColor: theme.palette.success.main } : {});
  };

  return (
    <Dialog maxWidth="xl" open={isOpen && !!csv[0]}>
      <DialogTitle>Upload CSV</DialogTitle>
      <DialogContent>
        {csv[0]
          && (
            <MaterialTable
              ref={tableRef}
              title=""
              columns={Object.keys(csv[0] || {}).map((c, idx) => ({
                title: expectedNames[idx],
                field: c,
                // @ts-ignore
                cellStyle: (data, rowData) => renderCellStyle(rowData),
                // @ts-ignore
                render: rowData => renderCell(rowData, c)
              }))}
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/ban-types
              data={csv as object[]}
              options={{
                search: false,
                toolbar: false,
                header: true,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: csv.length
              }}
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => close()}>
          Cancel
        </Button>
        <Button variant="contained" onClick={upload} color="primary" disabled={!!errorRows?.length}>
          {isUploading
            ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <CircularProgress size={30} />
              </Box>
            ) : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
