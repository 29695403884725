import { getRefreshedToken } from 'apis/auth';
import { objToXml, parseSOAP } from './soap-utils';

const TRACSTAR_SOAP_URL = import.meta.env.VITE_TRACSTAR_SOAP_URL;

/**
 * @deprecated
 */
const getSoapHeaders = (endpoint: string): Headers => {
  const headers = new Headers();
  headers.append('SOAPAction', `http://pathfindertech.net/services/${endpoint}`);
  headers.append('Content-Type', 'text/xml');
  return headers;
};

/**
 * @deprecated
 */
const getXMLBody = async (api: string, extraXMLOptions?: object): Promise<string> => (
  `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://pathfindertech.net/services/">
     <soapenv:Header/>
     <soapenv:Body>
        <ser:${api}>
           <ser:userId>${localStorage.getItem('organisationId')}</ser:userId>
           <ser:user>${localStorage.getItem('organisationId')}</ser:user>
           <ser:password>${await getRefreshedToken()}</ser:password>
           ${objToXml(extraXMLOptions)}
        </ser:${api}>
     </soapenv:Body>
  </soapenv:Envelope>`
);

/**
 * Wraps a fetch and parsing call to tracstar.
 *
 * @deprecated
 * @param endpoint The SOAP api to call on /trackstar.asmx
 * @param extraXMLOptions Extra xml options to provide aside from usercode/password
 * @returns parsed SOAP result, 4 levels down from root
 */
const fetchTracstar = async (endpoint: string, extraXMLOptions?: object): Promise<any> => {
  const response = await fetch(`${TRACSTAR_SOAP_URL}?${endpoint}`, {
    method: 'POST',
    headers: getSoapHeaders(endpoint),
    body: await getXMLBody(endpoint, extraXMLOptions),
    redirect: 'follow',
    mode: 'cors',
  });
  if (!response.ok) throw new Error(`Failed to request tracstar/${endpoint}.`);
  return parseSOAP(await response.text());
};

// #---------------------------#
// # CONVERSATIONS & MESSAGING #
// #---------------------------#
/**
 * @deprecated
 */
export const sendMessage = async (deviceId: number, message: string, transport: string): Promise<Result> => {
  // TODO: may want to allow choosing transport method (iridium vs cellular) in the future
  // hardcode to send text message capability and iridium SBD transport (always message over iridium for now)
  const extraXMLOptions = {
    terminalID: deviceId,
    capabilityCode: 'CP_SND_TEXT',
    transportCode: transport,
    args: {
      paramPair: { key: 'text', value: message }
    }
  };
  return fetchTracstar('sendMessage', extraXMLOptions);
};
