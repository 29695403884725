export type MinimalUserTransition = Pick<UserTransition, 'toState' | 'reportTime'>;

export const sortUserTransitions = <T extends MinimalUserTransition>(transitions: T[]): T[] => {
  const byTimeAndState = transitions.reduce<Record<number, { ACTIVE?: T, INACTIVE?: T }>>((acc, transition) => {
    if (!acc[transition.reportTime]) acc[transition.reportTime] = {};
    acc[transition.reportTime][transition.toState] = transition;
    return acc;
  }, {});

  const times = Object.keys(byTimeAndState).map(key => parseInt(key, 10)).sort();

  return times.reduce<T[]>((acc, time) => {
    const items = byTimeAndState[time];
    const previous = acc[acc.length - 1];

    if (previous?.toState === 'ACTIVE') {
      if (items.INACTIVE) acc.push(items.INACTIVE);
      if (items.ACTIVE) acc.push(items.ACTIVE);
    } else {
      if (items.ACTIVE) acc.push(items.ACTIVE);
      if (items.INACTIVE) acc.push(items.INACTIVE);
    }

    return acc;
  }, []);
};

export const validateUserTransitions = <T extends MinimalUserTransition>(transitions: T[]): boolean => {
  if (transitions.length === 0) return false;
  if (transitions.length % 2 === 1) return false;
  return transitions.every((transition, index) => (index % 2 === 0 ? transition.toState === 'ACTIVE' : transition.toState === 'INACTIVE'));
};
