import React from 'react';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { Box } from '@mui/material';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { Inventory2Outlined } from '@mui/icons-material';

export interface AssetColourMarkerProps {
  assetId?: number;
  deviceId?: number;
}

const AssetColourMarker = ({
  assetId,
  deviceId,
}: AssetColourMarkerProps): JSX.Element | null => {
  const assetQuery = useGetAssetsList({
    select: data => {
      if (assetId) return data.find(a => a.id === assetId);
      if (deviceId) return data.find(a => a.deviceId === deviceId);
      return undefined;
    },
    staleTime: Infinity, // never trigger re-query
  }).query;

  const asset = assetQuery.data;
  if (!asset) return null;

  const assetColour = asset.colour ?? SUGGESTED_ASSET_COLORS[asset.id % 16];
  return (
    asset.archived
      ? <Inventory2Outlined sx={{ ml: -0.3 }} fontSize="small" />
      : (
        <Box
          sx={theme => ({
            backgroundColor: assetColour,
            width: '1rem',
            height: '1rem',
            borderRadius: theme.spacing(2 / 3),
            flexShrink: 0,
          })}
        />
      )
  );
};

export default AssetColourMarker;
