import React from 'react';

const DropEnd = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path className="icon-detail" fill="#fff" d="M6.04,9.56C6.04,8.44,8,6.53,8,6.53s1.96,1.91,1.96,3.03c0,1.17-1.03,1.86-1.96,1.86S6.04,10.74,6.04,9.56z" />
    <path className="icon-detail" fill="#fff" d="M4.09,6.39c0-0.68,1.08-1.81,1.08-1.81S6.24,5.7,6.24,6.39C6.29,7.12,5.7,7.51,5.16,7.51S4.09,7.12,4.09,6.39z" />
    <path className="icon-detail" fill="#fff" d="M11.91,6.39c0-0.68-1.08-1.81-1.08-1.81S9.76,5.7,9.76,6.39c0,0.73,0.59,1.12,1.08,1.12S11.91,7.12,11.91,6.39z" />
  </svg>
);

export default DropEnd;
