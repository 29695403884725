import React from 'react';

const Alert = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M11.31,0H4.69L0,4.69v6.63L4.69,16h6.63L16,11.31V4.69L11.31,0z" />
    <rect className="icon-detail" fill="#fff" x="7" y="4" width="2" height="5" />
    <circle className="icon-detail" fill="#fff" cx="8.01" cy="11.21" r="0.99" />
  </svg>
);

export default Alert;
