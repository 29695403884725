import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useFeature from 'hooks/features/useFeature';
import { useStaff } from 'hooks/session/useStaff';
import { assetGroupsQueryKeys } from './queryKeys';
import { AssetGroup } from './types';
import {
  createAssetGroup,
  deleteAssetGroup,
  getAssetGroupsForOrganisation,
  updateAssetGroup,
  updateAssetGroupAssets
} from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetAssetGroupsForOrganisation = <T = AssetGroup[]>(options?: Options<AssetGroup[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = assetGroupsQueryKeys.all(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => getAssetGroupsForOrganisation(organisationId),
    enabled: options?.enabled,
    ...options,
  });
  return query;
};

export const useCreateAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<AssetGroup, 'name'>) => createAssetGroup(organisationId, value.name),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetGroupsQueryKeys.all(organisationId) }),
    mutationKey: ['createAssetGroup'],
  });
};

export const useUpdateAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { assetGroupId: number, assetGroupName: string }) => updateAssetGroup(organisationId, value.assetGroupId, value.assetGroupName),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetGroupsQueryKeys.all(organisationId) }),
    mutationKey: ['updateAssetGroup'],
  });
};

export const useUpdateAssetGroupAssets = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { assetGroupId: number, assetIds: number[] }) => updateAssetGroupAssets(organisationId, value.assetGroupId, value.assetIds),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetGroupsQueryKeys.all(organisationId) }),
    mutationKey: ['updateAssetGroupAssets'],
  });
};

export const useDeleteAssetGroup = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<AssetGroup, 'id'>) => deleteAssetGroup(organisationId, value.id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetGroupsQueryKeys.all(organisationId) }),
    mutationKey: ['deleteAssetGroup'],
  });
};
