import React from 'react';
import { Box, Button, ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import { Close, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Duration } from 'luxon';
import TimeSpan from 'components/shared/timespan';
import Distance from 'components/shared/distance';
import { useTranslations } from 'use-intl';
import { formatDateTime } from 'utils/time';

const TripStartEnd = ({ timezone, trip }: { timezone: string, trip: Trip }) => {
  const t = useTranslations('pages.reporting');
  return (
    <Stack direction="row" alignItems="center" spacing={3} flex={{ xs: 1, xl: '0 0 auto' }}>
      <Stack>
        <Typography variant="h4">{trip.start}</Typography>
        <Typography>{t('departure')}: {formatDateTime(trip.startTime, timezone, true)}</Typography>
      </Stack>
      <Stack alignItems="center" flex={1}>
        <Stack direction="row" alignItems="center" spacing={1} px={6}>
          <Typography display={{ xs: 'none', xl: 'block' }}>{t('trips.duration')}:</Typography>
          <Typography><TimeSpan value={Duration.fromMillis(trip.duration || 1)} /></Typography>
        </Stack>
        <Box height="1px" sx={theme => ({ width: '100%', flex: 1, position: 'relative', border: theme.border.default, borderColor: 'common.text' })}>
          <ChevronRight sx={{ position: 'absolute', right: '-0.5em', top: '-0.5em', color: 'common.text' }} fontSize="large" />
        </Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography display={{ xs: 'none', xl: 'block' }}>{t('trips.distance')}:</Typography>
          <Distance distanceInMetres={(trip.distance ?? 0) * 1000} precision={1} />
        </Stack>
      </Stack>
      {trip.endTime && trip.end && (
      <Stack>
        <Typography variant="h4">{trip.end}</Typography>
        <Typography>{t('arrival')}: {formatDateTime(trip.endTime, timezone, true)}</Typography>
      </Stack>
      )}
    </Stack>
  );
};

interface TripHeaderProps {
  trip: Trip
  onClose: () => void
  onNextTrip: () => void
  onPreviousTrip: () => void,
  timezone: string
}

const TripHeader = React.memo(({ timezone, trip, onClose, onNextTrip, onPreviousTrip }: TripHeaderProps) => {
  const t = useTranslations('pages.reporting');
  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr max-content max-content', xl: 'max-content 1fr max-content' }}
      gridTemplateRows={{ xs: 'max-content max-content', xl: 'max-content max-content' }}
      gridTemplateAreas={{ xs: '"title paging close" "trip trip trip"', xl: '"title title close" "trip trip paging"' }}
      columnGap={3}
      rowGap={3}
      alignItems="center"
      mb={3}
  >
      <Box gridArea="title">
        <Typography variant="h1">{t('trips.details')}</Typography>
      </Box>
      <IconButton sx={{ gridArea: 'close', justifySelf: 'end' }} onClick={onClose}>
        <Close sx={{ fontSize: '3rem' }} />
      </IconButton>
      <Box gridArea="trip">
        <TripStartEnd trip={trip} timezone={timezone} />
      </Box>
      <ButtonGroup sx={{ gridArea: 'paging', alignSelf: 'center' }} variant="outlined" size="large">
        <Button onClick={onPreviousTrip} startIcon={<ChevronLeft />}>{t('previous')}</Button>
        <Button onClick={onNextTrip} endIcon={<ChevronRight />}>{t('next')}</Button>
      </ButtonGroup>
    </Box>
  );
});

export default TripHeader;
