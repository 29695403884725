import { createContext } from 'react';

export interface Viewport {
  latitude: number,
  longitude: number,
  zoom: number,
  bearing: number,
  pitch: number,
  width: number,
  height: number,
  transitionDuration?: number
}

export const ViewportContext = createContext<Record<string, Viewport>>({});
