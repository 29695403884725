import React from 'react';
import { useTranslations } from 'use-intl';
import { Box, Typography } from '@mui/material';
import { createCompatibilityReport } from 'components/compatibilityGuard/utils';
import LanguageSelector from 'components/shared/languageSelector';

import UserGuideMessageAndDownloadIcon from './downloadStoreIcon/UserGuideMessageAndDownloadIcon-view';

import useStyles from './compatibility-styles';

const CompatibilityView = ({ ignore }) => {
  const classes = useStyles();
  const t = useTranslations('app.compatability');
  const report = createCompatibilityReport();

  let errorMessage = '';
  if (report.wrongBrowser) {
    errorMessage = t('unsupportedBrowser');
  } else if (report.oldBrowser) {
    errorMessage = t('outdatedBrowser');
  } else if (report.ios) {
    errorMessage = t('onIos');
  } else if (report.android) {
    errorMessage = t('onAndroid');
  } else if (report.mobile) {
    errorMessage = t('onMobile');
  } else if (report.screenWidthTooLow) {
    errorMessage = t('lowRes');
  } else if (report.noWebgl) {
    errorMessage = t('noWebGl');
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.leftHalf}>
        <Box className={classes.leftWrapper}>
          <Box className={classes.titleWrapper}>
            <Box className={classes.iconWrapper}>
              <svg className="text-[2.5rem] w-[80px] h-[80px] leading-none mr-0" viewBox="0 0 24 24">
                <g transform="translate(1846.336 -21.274)">
                  <circle fill="#FFFFFF" cx="-1834.44" cy="33.2" r="4.84" />
                  <path fill="#3DD9D7" d="M-1843.98,37.25c-1.26-1.08-2.07-2.59-2.27-4.23c-0.23-1.66,0.02-3.36,0.73-4.88 c0.7-1.53,1.82-2.83,3.23-3.74c1.38-0.92,3.05-1.29,4.68-1.04l0.07,0.01c0.06,0.01,0.1,0.07,0.09,0.13c0,0,0,0,0,0 c-0.01,0.04-0.03,0.07-0.07,0.08c-1.26,0.55-2.42,1.31-3.41,2.26c-0.98,0.95-1.78,2.08-2.33,3.33c-0.58,1.24-0.92,2.57-1,3.94 c-0.07,1.37,0.11,2.75,0.52,4.06c0.02,0.06-0.02,0.13-0.08,0.14c-0.04,0.01-0.08,0-0.11-0.02L-1843.98,37.25z" />
                  <path fill="#3DD9D7" d="M-1824.69,29.3c1.26,1.08,2.07,2.59,2.27,4.23c0.23,1.66-0.02,3.36-0.73,4.88 c-0.7,1.53-1.82,2.83-3.23,3.74c-1.38,0.92-3.05,1.29-4.68,1.04l-0.07-0.01c-0.06-0.01-0.1-0.07-0.09-0.13 c0.01-0.04,0.03-0.07,0.07-0.08c1.26-0.55,2.42-1.31,3.41-2.26c0.98-0.95,1.78-2.08,2.33-3.33c0.58-1.24,0.92-2.57,1-3.94 c0.07-1.37-0.11-2.74-0.52-4.06c-0.02-0.06,0.01-0.13,0.07-0.14c0,0,0,0,0,0c0.04-0.01,0.08,0,0.11,0.02L-1824.69,29.3z" />
                </g>
              </svg>
            </Box>

            <Typography variant="h3" className={classes.titleText}>{t('title')}</Typography>
            <Typography className={classes.subtitleText}>{errorMessage}</Typography>
          </Box>
          <Box className={classes.continueWrapper}>
            <UserGuideMessageAndDownloadIcon
              report={report}
              t={t}
              classes={classes}
              ignore={ignore}
            />
          </Box>
        </Box>

        <Box className={classes.languageSelectorWrapper}>
          <LanguageSelector />
        </Box>
      </Box>

      <Box className={classes.rightHalf}>
        <Box className={classes.image} />
      </Box>
    </Box>
  );
};

export default CompatibilityView;
