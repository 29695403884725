import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import AssetLabel from 'components/shared/assetLabel';

interface AssignAssetsSelectionsProps {
  selectedAssetIds: number[]
  setSelectedAssetIds: Dispatch<SetStateAction<number[]>>
  assets: AssetBasic[]
  onClickAssign: () => void
}

const AssignAssetsSelections = ({
  selectedAssetIds,
  setSelectedAssetIds,
  assets,
  onClickAssign,
}: AssignAssetsSelectionsProps) => {
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupsAssetAssignmentTable');

  const selectedAssets = useMemo(() => (
    selectedAssetIds.map(id => assets.find(a => a.id === id)).filter((a): a is AssetBasic => !!a)
  ), [assets, selectedAssetIds]);

  return (
    <Stack
      bgcolor="common.white"
      bottom={0}
      p={3}
      borderTop={1}
      borderColor="common.midGrey"
      direction="row"
      spacing={3}
      alignItems="center"
    >
      {selectedAssetIds.length ? (
        <>
          <Box flexShrink={0}>
            <Typography variant="h5" flexShrink={0}>{t('selectedAssets')}:</Typography>
          </Box>
          <Box flex={1} maxHeight="8.5rem" sx={{ overflowY: 'scroll' }}>
            <Grid container flex={1} spacing={1} minHeight={38} flexWrap="wrap" alignItems="center">
              {selectedAssets.map(asset => (
                <Grid key={asset.id} item>
                  <Chip
                    label={<AssetLabel asset={asset} />}
                    onDelete={() => {
                      setSelectedAssetIds(ids => ids.filter(x => x !== asset.id));
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Box flex={1} flexShrink={0}>
          <Typography variant="h5">{t('noSelectedAssets')}</Typography>
        </Box>
      )}
      <Stack direction="row" height="4em" flexShrink={0}>
        <Button
          variant="contained"
          size="large"
          disabled={!selectedAssetIds.length}
          onClick={onClickAssign}
        >
          {t('assign')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AssignAssetsSelections;
