import { Dialog, Stack, DialogContent, DialogActions, Button } from '@mui/material';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { MultiSelect } from 'components/shared/multiselect';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { AssetGroupLabel } from './assetGroupLabel';
import { useAssetsNotificationsStatus } from './helpers';

export interface ManageAssetGroupsDialogProps {
  open: boolean;
  isLoaded: boolean;
  isSaving: boolean;
  isReadOnly: boolean;
  assetGroups: AssetGroup[]
  selectedAssetGroups: number[];
  title: ReactNode;
  ariaLabel: string;
  onClose: () => void;
  onSave: (assetGroupIds: number[]) => void
}

export const ManageAssetGroupsDialog = ({
  open,
  isLoaded,
  isSaving,
  isReadOnly,
  assetGroups,
  selectedAssetGroups,
  title,
  ariaLabel,
  onClose,
  onSave
}: ManageAssetGroupsDialogProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.assetGroupsDialog');

  const [selectedItems, setSelectedItems] = useState<number[]>(selectedAssetGroups);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const handleCancel = useCallback(() => onClose(), [onClose]);
  const handleSave = useCallback(() => onSave(selectedItems), [onSave, selectedItems]);

  const getNotificationsStatus = useAssetsNotificationsStatus();

  const items = useMemo(() => (
    assetGroups.map(a => {
      const status = getNotificationsStatus(a.assets);
      return { id: a.id, item: a, count: status.enabled.length };
    }).sort((a, b) => b.count - a.count)
  ), [assetGroups, getNotificationsStatus]);

  return (
    <Dialog
      open={open}
      onClose={(evt, reason) => { if (reason === 'backdropClick') { return; } if (isLoaded) onClose(); }}
      aria-labelledby={ariaLabel}
      fullWidth maxWidth="lg">
      <TPDialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title}
        </Stack>
      </TPDialogTitle>
      <DialogContent sx={{ p: 0, mb: '-1px' }}>
        <MultiSelect
          items={items}
          preselected={assetGroups.filter(a => selectedAssetGroups.some(s => s === a.id)).map(a => ({ id: a.id, item: a }))}
          selectedLabel={t('selectedHeader')}
          unselectedLabel={t('availableHeader')}
          isReadOnly={isReadOnly}
          onChange={selected => {
            setSelectedItems(selected.map(i => i.id));
            setFormChanged(true);
          }}
          filterOperation={({ item }, filterText) => item.name?.toLowerCase().includes(filterText.toLowerCase()) ?? false}
          renderItemElement={({ item }) => (<AssetGroupLabel assetGroup={item} />)} />
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Stack spacing={3} flex={1}>
          <Stack my={1} spacing={3} direction="row" height="4em" alignSelf="flex-end">
            <Button variant="outlined" size="large" disabled={!isLoaded || isSaving}
              onClick={handleCancel} sx={{ minWidth: '10rem' }}>{t('cancelButton')}
            </Button>
            <Button variant="contained" size="large" disabled={!isLoaded || isSaving || !formChanged}
              onClick={handleSave} sx={{ minWidth: '10rem' }}>{t('saveButton')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
