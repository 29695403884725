import { useMemo } from 'react';
import { GroupId as AssetCategoryGroup } from 'components/pages/manage/assets/create/categories';
import { useGetAssetBasic } from 'apis/rest/assets/hooks';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

export type SpeedCategory = AssetCategoryGroup | 'Default';

export const useSpeedByCategory = (category?: SpeedCategory) => {
  const units = useUnitSettings();
  return useMemo(() => {
    switch (category) {
      case 'Air':
        return units.speedAir;
      case 'Land':
        return units.speedLand;
      case 'Sea':
        return units.speedSea;
      case 'Person':
        return units.speedPerson;
      case 'Object':
      default:
        return units.speed;
    }
  }, [category, units.speed, units.speedAir, units.speedLand, units.speedPerson, units.speedSea]);
};

export const useSpeedByAsset = (asset?: AssetBasic) => {
  const units = useUnitSettings();
  return useMemo(() => {
    switch (asset?.category) {
      case 'Aircraft':
      case 'Helicopter':
      case 'Gyrocopter':
      case 'Drone':
      case 'Airship':
      case 'Balloon':
      case 'Rocket':
        return units.speedAir;
      case 'Truck':
      case 'Fire Truck':
      case 'Car':
      case 'Police Car':
      case 'Ambulance':
      case 'Bicycle':
      case 'Tractor':
      case 'Bus':
      case 'Minibus':
      case 'Motorcycle':
      case 'Motor Home':
        return units.speedLand;
      case 'Powerboat':
      case 'Yacht':
      case 'Container Ship':
      case 'Tug':
        return units.speedSea;
      case 'Person':
        return units.speedPerson;
      default:
        return units.speed;
    }
  }, [asset?.category, units.speed, units.speedAir, units.speedLand, units.speedSea, units.speedPerson]);
};

export const useSpeedByAssetId = (assetId: number) => {
  const asset = useGetAssetBasic(assetId, assetId >= 0);
  return useSpeedByAsset(asset.query.data);
};
