import { IconLayer } from '@deck.gl/layers';

import pulseFragmentShader from './shaders/pulse-fragment-shader.glsl';

interface PulseIconLayerProps {
  speedModifier: number | undefined
}

export default class PulseIconLayer<T> extends IconLayer<T, PulseIconLayerProps> {
  static layerName = 'PulseIconLayer';

  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        speedModifier: this.props.speedModifier ?? 1
      }
    });
  }

  serialize() {
    return this;
  }

  getShaders() {
    return { ...super.getShaders(), fs: pulseFragmentShader };
  }
}
