import { createContext, useContext } from 'react';

export interface LabsContextValue {
  isLoading: boolean
  value: boolean | undefined
}

export const LabsContext = createContext<LabsContextValue | undefined>(undefined);

export const useLabs = () => useContext(LabsContext);
