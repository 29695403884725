const connect = () => new Promise<IDBDatabase>((resolve, reject) => {
  // @ts-ignore
  const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
  if (typeof window !== 'undefined' && !indexedDB) {
    console.error('indexedDB not supported');
    reject(new Error('indexedDB not supported'));
  }

  const request = indexedDB.open('ldb', 1);
  request.onupgradeneeded = event => {
    // @ts-ignore
    event.target.result.createObjectStore('s', {
      keyPath: 'k'
    });
  };
  request.onsuccess = () => resolve(request.result);
  request.onerror = () => reject(request.error);
});

const ldb = ({
  get: (key: string, callback: (value: string | null) => void) => {
    connect().then(db => {
      db.transaction('s').objectStore('s').get(key).onsuccess = event => {
        // @ts-ignore
        const result = (event.target.result && event.target.result.v) || null;
        callback(result);
        db.close();
      };
    });
  },
  set: (key: string, value: string, callback?: () => void) => {
    connect().then(db => {
      const txn = db.transaction('s', 'readwrite');
      txn.oncomplete = () => {
        if (callback) callback();
        db.close();
      };
      txn.objectStore('s').put({
        k: key,
        v: value,
      });
      txn.commit();
    });
  },
  delete: (key: string, callback?: () => void) => {
    connect().then(db => {
      db.transaction('s', 'readwrite').objectStore('s').delete(key).onsuccess = () => {
        if (callback) callback();
        db.close();
      };
    });
  },
  list: (callback: (result: string[]) => void) => {
    connect().then(db => {
      db.transaction('s').objectStore('s').getAllKeys().onsuccess = event => {
        // @ts-ignore
        callback((event.target.result) ?? []);
        db.close();
      };
    });
  },
  clear: (callback?: () => void) => {
    connect().then(db => {
      db.transaction('s', 'readwrite').objectStore('s').clear().onsuccess = () => {
        if (callback) callback();
        db.close();
      };
    });
  },
});

export default ldb;
