import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: theme.border.default
  },
  cardContainer: {
    margin: theme.spacing(4, 2),
    backgroundColor: theme.palette.primary.distressRed,
    background: 'repeating-linear-gradient(135deg, #CE2525, #CE2525 25px, #dc4c44 25px, #dc4c44 50px)',
    height: 'auto',
    width: '80%',
    boxShadow: 'none'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24
  },
  cardTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.common.whiteConstant
  },
  cardAction: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: 30,
    justifyContent: 'space-evenly'
  },
  buttonsGroup: {
    paddingTop: 8,
    textAlign: 'right'
  },
  distressModalButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 30,
    width: '60%',
    textAlign: 'center',
    textTransform: 'capitalize',
    font: theme.typography.h6,
    backgroundColor: theme.palette.common.whiteConstant,
    color: theme.palette.primary.distressRed,
    border: theme.border.default,
    borderColor: theme.palette.common.whiteConstant,
    borderRadius: 20,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.distressRed
    }
  },
  backButton: {
    alignSelf: 'center',
    height: 30
  },
  table: {
    '& .MuiTableBody-root .MuiTableRow-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.mapControls.bg,
      },
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableCell-stickyHeader': {
      top: -1,
      backgroundColor: theme.palette.background.paper,
      fontWeight: 600,
    }
  },
  eventIconContainer: {
    padding: '6px 0 6px 0',
    display: 'inline-flex',
    flexShrink: '0',
    minWidth: '19px',
    marginLeft: theme.spacing(1),
    '& svg': {
      width: '24px',
      height: '24px',
      '& .icon-base': {
        fill: theme.palette.primary.distressRed,
      },
      '& .icon-detail': {
        fill: theme.palette.common.whiteConstant,
        opacity: 0.9
      }
    }
  },
  list: {
    fontSize: '1rem',
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderColor: theme.border.color
  },
  selected: {
    backgroundColor: theme.palette.mapControls.bgLight,
  },
  tableContainer: {
    maxHeight: 240,
  },
}));
