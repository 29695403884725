import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StatsFilterState {
  enabled: boolean,
  hideAssetsNoDrops: boolean,
  exceptions: string[],
  inclusions: string[],
}

const initialState: StatsFilterState = {
  enabled: true,
  hideAssetsNoDrops: true,
  exceptions: [],
  inclusions: [],
};

export const statsFilterSlice = createSlice({
  name: 'statsFilter',
  initialState,
  reducers: {
    toggleStatsFilterEnabled(state) {
      state.enabled = !state.enabled;
    },
    toggleAssetsNoDrops(state) {
      state.hideAssetsNoDrops = !state.hideAssetsNoDrops;
    },
    addStatsFilterException(state, action: PayloadAction<string>) {
      state.exceptions.push(action.payload);
    },
    removeStatsFilterException(state, action: PayloadAction<string>) {
      const index = state.exceptions.indexOf(action.payload);
      if (index >= 0) {
        state.exceptions.splice(index, 1);
      }
    },
    addStatsFilterInclusion(state, action: PayloadAction<string>) {
      state.inclusions.push(action.payload);
    },
    removeStatsFilterInclusion(state, action: PayloadAction<string>) {
      const index = state.inclusions.indexOf(action.payload);
      if (index >= 0) {
        state.inclusions.splice(index, 1);
      }
    },
  },
  selectors: {
    selectStatsFilterEnabled: sliceState => sliceState.enabled,
    selectAssetsNoDrops: sliceState => sliceState.hideAssetsNoDrops,
    selectStatsFilterExceptions: sliceState => sliceState.exceptions,
    selectStatsFilterInclusions: sliceState => sliceState.inclusions,

  },
});

export const {
  toggleStatsFilterEnabled,
  toggleAssetsNoDrops,
  addStatsFilterException,
  removeStatsFilterException,
  addStatsFilterInclusion,
  removeStatsFilterInclusion,
} = statsFilterSlice.actions;
export const {
  selectStatsFilterEnabled,
  selectAssetsNoDrops,
  selectStatsFilterExceptions,
  selectStatsFilterInclusions,
} = statsFilterSlice.selectors;

export default {
  key: 'statsFilter',
  reducer: statsFilterSlice.reducer,
  version: 0,
  whitelist: ['enabled', 'exceptions', 'inclusions'],
  migrations: {
    0: () => initialState,
  }
};
