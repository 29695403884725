import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
// import { serenityApiClient } from 'apis/client/serenityApiClient';
// import { MarketSector } from '@tracplus/serenity-client';
import {
  createMarketSector,
  deleteMarketSector,
  getAllMarketSectorOrganisations,
  getMarketSector,
  getMarketSectors,
  getMarketSectorsForOrganisation,
  updateMarketSector,
  updateMarketSectorsForOrganisation,
} from './requests';
import * as types from './types';
import { marketSectorQueryKeys } from './queryKeys';

export const useGetAllMarketSectors = () => {
  const queryKeys = marketSectorQueryKeys.all();
  const query = useQuery({
    queryKey: queryKeys,
    queryFn: () => getMarketSectors(),
  });
  return query;
};

export const useGetMarketSector = (id: string) => {
  const queryKeys = marketSectorQueryKeys.forMarketSector(id);
  const query = useQuery({
    queryKey: queryKeys,
    queryFn: () => getMarketSector(id),
  });
  return query;
};

export const useGetAllMarketSectorOrganisations = () => {
  const queryKeys = marketSectorQueryKeys.allOrgs();
  const query = useQuery({
    queryKey: queryKeys,
    queryFn: () => getAllMarketSectorOrganisations(),
  });
  return query;
};

export const useGetMarketSectorsForOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryKeys = marketSectorQueryKeys.forOrg(organisationId);
  const query = useQuery({
    queryKey: queryKeys,
    queryFn: () => getMarketSectorsForOrganisation(organisationId),
  });
  return query;
};

export const useMutateUpdateMarketSectorsForOrganisation = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: types.OrganisationMarketSector) => updateMarketSectorsForOrganisation(organisationId, value),
    mutationKey: ['updateMarketSectorsForOrganisation'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: marketSectorQueryKeys.forOrg(organisationId) }),
  });
};

export const useMutateDeleteMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteMarketSector(id),
    mutationKey: ['deleteMarketSectors'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: marketSectorQueryKeys.all() }),
  });
};

export const useMutateCreateMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: types.MarketSector) => createMarketSector(body),
    mutationKey: ['createMarketSectors'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: marketSectorQueryKeys.all() }),
  });
};

export const useMutateUpdateMarketSector = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: types.MarketSector) => updateMarketSector(body),
    mutationKey: ['updateMarketSectors'],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: marketSectorQueryKeys.all() }),
  });
};

