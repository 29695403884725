import React from 'react';

const AS350 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 22.716797 1.7695312 C 21.976751 1.7784723 19.203835 4.3735119 19.341797 4.7988281 C 21.263578 10.314324 23.783242 15.237311 26.070312 20.109375 C 26.294322 22.871896 26.735658 25.570278 27.179688 27.810547 C 23.696117 32.506097 14.908899 44.355003 14.113281 45.550781 C 13.730554 46.12601 18.10203 47.502424 18.431641 47.136719 C 22.291095 42.663913 25.319251 37.960406 28.431641 33.498047 L 28.740234 46.222656 L 23.775391 46.222656 L 23.816406 50.332031 L 28.798828 50.357422 C 28.798828 50.357422 28.468909 62.358292 30.908203 62.431641 C 33.47087 62.508701 33.134766 50.357422 33.134766 50.357422 L 38.117188 50.332031 L 38.158203 46.222656 L 33.191406 46.222656 L 33.509766 33.169922 C 33.509766 33.169922 33.996432 31.440699 34.541016 28.958984 C 39.807726 29.566068 55.770424 31.401776 57.253906 31.496094 C 57.943432 31.539914 56.948295 27.069398 56.466797 26.966797 C 49.119096 25.566482 42.113587 25.503721 35.361328 24.691406 C 35.830737 21.805369 36.166454 18.627375 36.007812 15.892578 C 35.548557 7.9755745 30.935547 8.3984375 30.935547 8.3984375 C 30.935547 8.3984375 28.419894 8.2432686 26.953125 11.101562 C 24.994905 6.6088031 23.194656 2.5024754 22.873047 1.8535156 C 22.863347 1.8341756 22.851327 1.8169665 22.835938 1.8046875 C 22.806135 1.7808923 22.766133 1.7689352 22.716797 1.7695312 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default AS350;
