import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslations } from 'use-intl';
import { Link } from 'react-router-dom';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import AssignAssetsDialog from 'components/dialogs/messagingWhitelistContactGroups/assignAssets';
import AddGroupDialog from 'components/dialogs/contactGroups/addGroup';
import {
  useGetMessagingWhitelistContactGroups,
  useMutateNewMessagingWhitelistContactGroup,
  useMutateMessagingWhitelistContactGroupAssignDevices,
} from 'apis/rest/messagingWhitelistContactGroups/hooks';
import useValidateName from 'hooks/contactGroup/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import AssetContactGroup from './assetContactGroup-view';

interface AssetMessagingWhitelistContactGroupProps {
  asset: AssetWithDevice
  readOnly: boolean
}

const AssetMessagingWhitelistContactGroup = ({ asset, readOnly }: AssetMessagingWhitelistContactGroupProps): JSX.Element | null => {
  const t = useTranslations('pages.assetView.messagingWhitelistContactGroup');
  const [open, setOpen] = useState(false);
  const [addGroup, setAddGroup] = useState(false);

  const { query: groupsQuery } = useGetMessagingWhitelistContactGroups();
  const createMutation = useMutateNewMessagingWhitelistContactGroup();
  const assignMutation = useMutateMessagingWhitelistContactGroupAssignDevices();
  const validateName = useValidateName(groupsQuery);
  const snackbar = useSnackbar();

  return (
    <SettingsSection title={t('title')} description={t('description')}>
      <DetailPanel>
        <Stack spacing={3}>
          <AssetContactGroup
            readOnly={readOnly}
            assetId={asset.id}
            groupsQuery={groupsQuery}
            messages={(group, defaultGroup) => ({
              noGroups: t('noGroups'),
              loadingGroups: t('loading'),
              noGroupAssigned: t('noGroupAssigned'),
              noGroupAssignedWithDefault: t('noGroupAssignedWithDefault', { name: defaultGroup?.name }),
              noPeople: t('noPeople'),
            })}
            actions={group => ({
              navigate: group ? (
                <Button size="large" variant="contained" component={Link} to={`/manage/device-messaging/groups/${group.id}`}>
                  {t(readOnly ? 'view' : 'edit')}
                </Button>
              ) : null,
              assign: (
                <Button size="large" variant="contained" onClick={() => setOpen(true)}>
                  {t('assign')}
                </Button>
              ),
              create: (
                <Button size="large" variant="contained" onClick={() => setAddGroup(true)}>
                  {t('create.title')}
                </Button>
              )
            })}
          />
        </Stack>
      </DetailPanel>
      <AssignAssetsDialog open={open} onClose={() => setOpen(false)} assetIds={[asset.id]} />
      <AddGroupDialog
        title={t('create.title')}
        ariaLabel={t('create.title')}
        open={addGroup}
        mutation={createMutation}
        validateName={name => validateName(createMutation.data?.id, name)}
        created={group => {
          setAddGroup(false);
          assignMutation.mutate(
            {
              id: group.id,
              deviceVersion: group.deviceVersion,
              deviceAndAssetIds: [{ deviceId: asset.deviceId, assetId: asset.id }],
            },
            {
              onSuccess: () => {
                snackbar.display({
                  id: `messagingWhitelistCreated.${group.id}`,
                  text: t('create.snackbar.success', { name: group.name }),
                  type: 'success',
                });
              },
            },
          );
        }}
        cancel={() => setAddGroup(false)}
      />
    </SettingsSection>
  );
};

export default AssetMessagingWhitelistContactGroup;
