import { XMLParser } from 'fast-xml-parser';

/**
 * This method parses the SOAP response from our API, and throws an error based on the error the server throws.
 *
 * @deprecated
 * @param xml Soap response text to be parsed
 * @returns The result of the request (4 levels down from root), or an error.
 */
export const parseSOAP = (xml: string): any => {
  // When a tag only has one entry, it can't decide whether it's an array or not
  // This rectifies this. Update this when a new entry is added that relies on an element
  // containing a list of other elements.
  const alwaysInArray = [
    'terminal',
    'report',
    'Friend',
    'Friends',
    'usercode',
    'Share',
    'conversation',
    'message',
    'groupMember',
    'contactDetails',
    'asset',
    'device',
    'pendingMember',
    'member',
    'role',
    'organisation',
    'messagingWhitelist',
    'capability',
  ];
  const parser = new XMLParser({
    isArray: name => alwaysInArray.includes(name)
  });
  const parsedXML = parser.parse(xml);
  const soapNamespace = 's';
  if (Object.keys(parsedXML).includes(`${soapNamespace}:Envelope`)) {
    const body = parsedXML[`${soapNamespace}:Envelope`][`${soapNamespace}:Body`];
    const endpoint = Object.keys(body)[0].replace('Response', '');
    const result = body[`${endpoint}Response`][`${endpoint}Result`];
    // TODO: remove getAssets exception once we can work out why it returns result.success=false even when successful
    if (result.success || endpoint === 'getAssets') {
      return result;
    }
    throw new Error(`${endpoint}: ${result.error}: ${result.description}`);
  }
  throw new Error('Server did not return anything.');
};

/**
 * @deprecated
 */
export const escapeXml = (unsafe: string): string => unsafe.replace(/[<>&'"]/g, (c: string): string => {
  switch (c) {
    case '<':
      return '&lt;';
    case '>':
      return '&gt;';
    case '&':
      return '&amp;';
    case '\'':
      return '&apos;';
    case '"':
      return '&quot;';
    default:
      return c;
  }
});

// eslint-disable-next-line @typescript-eslint/ban-types
/**
 * @deprecated
 */
export const objToXml = (obj?: object): string => {
  if (!obj) return '';
  let out = '';
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      Object.entries(value).forEach(([_, value2]) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const tmp: Record<string, object> = {};
        tmp[key] = value2;
        out += objToXml(tmp);
      });
    } else {
      out += `<ser:${key}>`;
      if (typeof (value) === 'object') {
        out += objToXml(value);
      } else if (value && typeof (value) === 'string') {
        out += escapeXml(value);
      } else {
        out += value;
      }
      out += `</ser:${key}>`;
    }
  });
  return out;
};
