import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const MessagingIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <path d="M30.544,15.601C28.824,21.553,22.292,26,14.5,26c-0.923,0-1.856-0.08-2.787-0.211 C13.859,27.778,17.057,29,20.5,29c1.098,0,2.194-0.126,3.266-0.374L29,31.767v-5.698c1.939-1.659,3-3.798,3-6.068 C32,18.427,31.494,16.925,30.544,15.601z" />
      <path d="M3,27.618v-8.11c-1.938-2.001-3-4.474-3-7.008C0,6.159,6.505,1,14.5,1S29,6.159,29,12.5S22.495,24,14.5,24 c-1.18,0-2.373-0.12-3.552-0.355L3,27.618z" />
    </SvgIcon>
  );
};

export default MessagingIcon;
