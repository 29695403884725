import React, { useEffect, useMemo, useState } from 'react';
import { EventType } from 'repositories/reports';
import { useReportsDataRepository } from 'repositories/reports/hooks';
import { Polyline } from 'repositories/reports/spline';
import { Layer, Source } from 'react-map-gl';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectFirefightingMode } from 'slices/settings/mapSettings.slice';
import { LineLayout, LinePaint } from 'mapbox-gl';

interface GeoJsonPathFeature {
  type: 'Feature',
  properties: {
    assetId: number
    color: string
  }
  geometry: {
    type: 'MultiLineString',
    coordinates: Polyline[]
  }
}

const handleNewSplines = (currentFeatures: GeoJsonPathFeature[], newAssets: AssetBasic[], splines: Record<number, Polyline[]>) => {
  const assetIds = newAssets.map(a => a.id);
  const newTrails = currentFeatures.filter(t => !assetIds.includes(t.properties.assetId));

  const newAssetTrails = newAssets.map(asset => ({
    type: 'Feature',
    properties: {
      color: asset.colour,
      assetId: asset.id
    },
    geometry: {
      type: 'MultiLineString',
      coordinates: splines[asset.id]?.flatMap(spline => _.chunk(spline, 6553)) ?? [],
    }
  } as GeoJsonPathFeature));

  return newTrails.concat(...newAssetTrails);
};

export const useMapboxAssetTrailData = (assets: AssetBasic[], leg: Leg | null, use3d: boolean): GeoJsonPathFeature[] => {
  const [assetTrailData, setAssetTrailData] = useState<GeoJsonPathFeature[]>([]);
  const reports = useReportsDataRepository();

  useEffect(() => {
    const allTrailData = handleNewSplines([], assets, reports.getSplinesForAssets(assets.map(a => a.id), leg, use3d));
    setAssetTrailData(allTrailData);

    const subscription = reports.subject
      .subscribe(r => {
        if (r.type === EventType.SET_ALL) {
          setAssetTrailData(handleNewSplines([], assets, reports.getSplinesForAssets(assets.map(a => a.id), leg, use3d)));
        } else {
          const relatedAssets = assets.filter(a => r.assetIds?.includes(a.id) || r.assetId === a.id);
          if (relatedAssets.length > 0) {
            setAssetTrailData(oldData => (
              handleNewSplines(oldData, relatedAssets, reports.getSplinesForAssets(relatedAssets.map(a => a.id), leg, use3d))
            ));
          }
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [assets, leg, reports, use3d]);

  return assetTrailData;
};

export const MapboxTrailLayers = ({ data, beforeId, selectedAsset, visible = false }: {
  data: GeoJsonPathFeature[],
  selectedAsset: boolean,
  beforeId: string,
  visible?: boolean
}) => {
  const firefightingMode = useSelector(selectFirefightingMode);
  const trailOpacity = firefightingMode ? 0.2 : 1;
  const selectedTrailOpacity = firefightingMode ? 0.2 : 0.7;
  const trailInnerWidth = firefightingMode ? 1 : 2;
  const trailOuterWidth = firefightingMode ? 2 : 5;

  const bgLayerPaint = useMemo((): LinePaint => ({
    'line-color': '#ffffff',
    'line-width': trailOuterWidth,
    'line-opacity': ['case', selectedAsset, selectedTrailOpacity, trailOpacity]
  }), [selectedAsset, selectedTrailOpacity, trailOpacity, trailOuterWidth]);

  const bgLayerLayout = useMemo((): LineLayout => ({
    visibility: visible ? 'visible' : 'none',
    'line-join': 'round',
    'line-cap': 'round'
  }), [visible]);

  const fgLayerPaint = useMemo((): LinePaint => ({
    'line-color': ['get', 'color'],
    'line-width': trailInnerWidth,
    'line-opacity': ['case', selectedAsset, selectedTrailOpacity, trailOpacity]
  }), [selectedAsset, selectedTrailOpacity, trailInnerWidth, trailOpacity]);

  const fgLayerLayout = useMemo((): LineLayout => ({
    visibility: visible ? 'visible' : 'none',
    'line-join': 'round',
    'line-cap': 'round'
  }), [visible]);

  return (
    <Source id="mb-asset-layers" type="geojson" data={{
      type: 'FeatureCollection',
      features: data
    }}>
      <Layer
        id="mb-asset-layer-bg"
        type="line"
        beforeId={beforeId}
        paint={bgLayerPaint}
        layout={bgLayerLayout} />
      <Layer
        id="mb-asset-layer-fg"
        type="line"
        beforeId={beforeId}
        paint={{ ...fgLayerPaint }}
        layout={fgLayerLayout} />
    </Source>
  );
};
