import React from 'react';

const KMAX = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 15.048828 9.8828125 C 15.000853 9.8375492 14.547445 10.241942 14.03125 10.789062 C 13.515054 11.336183 13.137567 11.814105 13.185547 11.859375 L 29.285156 27.046875 C 29.294754 27.461191 29.302576 27.854264 29.314453 28.3125 L 4.6308594 19.191406 C 4.5689906 19.168545 4.30954 19.718258 4.0488281 20.423828 C 3.7881166 21.129397 3.6275822 21.715421 3.6894531 21.738281 L 29.398438 31.238281 C 29.548785 36.36469 29.746094 42.064453 29.746094 42.064453 L 27.738281 42.037109 C 27.738281 42.037109 27.724937 40.914475 27.455078 40.945312 C 27.18522 40.976152 25.602565 40.907336 25.335938 40.916016 C 25.06931 40.924716 25.51048 45.148941 25.884766 46.025391 C 25.954886 46.189584 30.021484 46.285156 30.021484 46.285156 C 30.021484 46.285156 30.978459 61.904633 32.1875 61.859375 C 33.396541 61.814125 33.904297 46.285156 33.904297 46.285156 C 33.904297 46.285156 37.97285 46.189592 38.042969 46.025391 C 38.417254 45.148941 38.856471 40.924746 38.589844 40.916016 C 38.323216 40.907416 36.742514 40.976162 36.472656 40.945312 C 36.202802 40.914473 36.189453 42.037109 36.189453 42.037109 L 34.181641 42.064453 C 34.181641 42.064453 34.458432 37.715057 34.716797 33.203125 L 36.511719 33.867188 L 53.503906 49.898438 C 53.551881 49.9437 54.005289 49.539308 54.521484 48.992188 C 55.037681 48.445066 55.415167 47.967145 55.367188 47.921875 L 43.017578 36.271484 L 55.683594 40.951172 C 55.745465 40.974033 56.004914 40.42432 56.265625 39.71875 C 56.526337 39.01318 56.68687 38.427157 56.625 38.404297 L 37.974609 31.511719 L 34.960938 28.669922 C 35.067744 26.534266 35.14607 24.704919 35.138672 23.894531 C 35.136272 23.630642 33.898374 15.301823 33.755859 14.609375 C 33.51082 13.418787 32.1875 13.257812 32.1875 13.257812 C 32.1875 13.257812 30.86418 13.418787 30.619141 14.609375 C 30.490162 15.236052 29.506363 21.840749 29.310547 23.337891 L 15.048828 9.8828125 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default KMAX;
