import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSize } from 'hooks/useSize';
import { Box } from '@mui/material';
import { Circle } from '@mui/icons-material';

interface StepTimelineProps<T> {
  steps: T[];
  stepView: React.FC<TimelineElementProps<T>>;
  colorFn: (item: T) => string;
}

export interface TimelineElementProps<T> {
  item: T;
  index: number;
}

interface StepItem {
  id: number;
  skip: boolean;
}

const StepTimeline = <T extends StepItem>({
  steps,
  stepView,
  colorFn,
}: StepTimelineProps<T>) => {
  const [rowHeights, setRowHeights] = useState<number[]>([]);

  const boxRef = useRef<HTMLDivElement>(null);
  const boxSize = useSize(boxRef);

  useEffect(() => {
    if (boxSize && boxRef?.current) {
      const rows = [...boxRef.current.querySelectorAll('.stepTimelineElement')];
      const heights = rows.map(c => parseFloat(getComputedStyle(c).height));
      const averagedHeights = heights.map((h, idx) => (h + (heights.at(idx + 1) ?? 0)) / 2);
      setRowHeights(averagedHeights);
    }
  }, [boxSize, setRowHeights]);

  return (
    <Box ref={boxRef} display="grid" gridTemplateColumns="5% [detail]1fr" columnGap={2} rowGap={3}>
      {steps.map((item, index) => {
        const isLast = index === steps.length - 1;
        const color = colorFn(item);

        const itemProps: TimelineElementProps<T> = {
          item,
          index,
        };

        return (
          <Fragment key={item.id}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {item.skip ? (
                <Box sx={{ width: 24 }} />
              ) : (
                <Circle sx={{
                  fill: color,
                  zIndex: 10,
                  width: 24,
                }} fontSize="large" />
              )}
              {!isLast && (
                <Box height={0} width={0}>
                  <Box width={8} height={rowHeights[index] + 18} sx={{
                    bgcolor: color,
                    translate: '-16px',
                  }} />
                </Box>
              )}
            </Box>
            <Box className="stepTimelineElement" sx={{ gridColumnStart: 'detail' }}>
              {stepView(itemProps)}
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};

export default StepTimeline;
