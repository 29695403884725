import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useGetTemporalSharesToList } from 'apis/rest/temporalShares/hooks';
import { TemporalShare, TemporalShareBase, TemporalSharePermissions, TemporalSharesResult } from 'apis/rest/temporalShares/types';
import { isInPeriod, Period } from 'components/pages/sharing/helpers';
import usePermissions from 'hooks/session/usePermissions';
import { config } from 'chai';
import { useGetDevicesList } from 'apis/rest/devices/hooks';

interface DeviceAccessResult {
  isLoading: boolean
  check(deviceId: number, date?: Date): TemporalSharePermissions & { date: Date }
}

interface NewDeviceAccessResult extends TemporalSharePermissions {
  date: Date
}

const DEFAULT: DeviceAccessResult = {
  isLoading: true,
  check: () => ({
    date: new Date(),
    canViewCurrent: false,
    canViewHistory: false,
    canViewForms: false,
    canSendTextMessages: false,
    canSendConfiguration: false,
    canEditCallSign: false,
  }),
};

export const useCheckDeviceAccess = () => {
  const sharesQuery = useGetTemporalSharesToList({
    select: (result: TemporalSharesResult): [TemporalShare[], TemporalShare[]] => [[...result.shares, ...result.groupShares], [...result.selfShares]],
  });

  const { canEditShare } = usePermissions();

  const result = useCallback((deviceId: number, date?: Date) => {
    const defaultDate = new Date(sharesQuery.dataUpdatedAt);
    const trueDate = date ?? defaultDate;
    const [standardShares, _] = sharesQuery.data ?? [[], []];
    if (standardShares.some(s => s.deviceId === deviceId)) {
      const share = standardShares.find(s => s.deviceId === deviceId);
      if (share && isInPeriod(share, Period.Present, DateTime.fromJSDate(date ?? trueDate))) {
        return {
          date: trueDate,
          canViewCurrent: share.canViewCurrent,
          canViewHistory: share.canViewHistory,
          canViewForms: share.canViewForms,
          canSendTextMessages: share.canSendTextMessages,
          canSendConfiguration: share.canSendConfiguration,
          canEditCallSign: share.canEditCallSign,
        };
      }
    }

    // if user is staff they can see all the shares anyway
    // If they're not staff but admin then all their self shares need to be visible
    if (canEditShare) {
      return {
        date: trueDate,
        canViewCurrent: true,
        canViewHistory: true,
        canViewForms: true,
        canSendTextMessages: true,
        canSendConfiguration: true,
        canEditCallSign: true,
      };
    }

    return null;
  }, [canEditShare, sharesQuery.data, sharesQuery.dataUpdatedAt]);

  return result;
};
