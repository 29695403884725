import React from 'react';
import { Typography, Box, Tooltip } from '@mui/material';
import TimeSince from 'components/shared/timeSince';
import clsx from 'clsx';
import moment from 'moment';
import StaffIndicator from 'components/shared/staffIndicator';
import useTranslation from 'hooks/useTranslation';
import { AccessTimeRounded, CheckCircle, Error } from '@mui/icons-material';
import useTimezone from 'hooks/session/useTimezone';
import useStyles from './message-styles';

interface MessageProps {
  message: Message;
  sender: string;
  conversation: Conversation;
  isMyMessage: boolean;
  showSender: boolean;
  showTimestamp: boolean;
  timezone: string;
  isDelivering: boolean;
  isAssetConvo: boolean;
  sentMessages: Message[];
}

const MessageView = ({
  message,
  sender,
  conversation,
  isMyMessage,
  showSender,
  showTimestamp,
  isDelivering,
  isAssetConvo,
  sentMessages,
  ...rest
}: MessageProps): JSX.Element => {
  const classes = useStyles();
  const timezone = useTimezone();
  const time = moment.unix(message.timestamp).tz(timezone);
  const t = useTranslation('pages.messaging');
  const sendStatus = message.status || (isDelivering && 'delivering') || null;

  return (
    <div className={clsx(classes.messageWrapper, { [classes.myMessageWrapper]: isMyMessage })} {...rest} key={message.id} id={message.id}>
      {showTimestamp && (
        <div className={classes.timestampWrapper}>
          <TimeSince time={message.timestamp * 1000} Component={Box} className={classes.timestamp} tooltip tooltipFormat="HH:mm:ss, dddd D MMM YYYY z" />
        </div>
      )}
      {showSender && (
        <Typography className={classes.senderName}>
          {sender}
          <StaffIndicator user={message.sender} overrideStyle={{ width: '1.6rem', height: '1.6rem' }} />
        </Typography>
      )}
      <div className={clsx(classes.messageBubble, { [classes.myMessageBubble]: isMyMessage })}>
        <Tooltip title={time.format('HH:mm:ss, dddd D MMM YYYY z')} placement="bottom" arrow>
          <Typography className={clsx(classes.messageText, { [classes.myMessageText]: isMyMessage })}>
            {message.content}
          </Typography>
        </Tooltip>
      </div>
      {isMyMessage && isAssetConvo && sendStatus && (
        <Tooltip title={t(sendStatus)}>
          {sendStatus === 'sent'
            ? <CheckCircle className={classes.statusIcon} />
            : sendStatus === 'failed'
              ? <Error className={classes.statusIcon} />
              : <AccessTimeRounded className={classes.statusIcon} />}
        </Tooltip>
      )}
    </div>
  );
};

export default MessageView;
