export const fsDecl = `\
uniform float time;
uniform float animationSpeed;
uniform bool animate;
`;

export const fsMainEnd = `\
float opacity = 1.0 - smoothstep(0.8, 1.0, abs(vPathPosition.x));

if (animate) {
  float t = mod(time / (1000.0*animationSpeed), 3.1415926535897932384626433832795); // To keep Nvidia drivers happy, keep t in the range of PI
  float w = abs(sin(t));
  fragColor = vec4(vColor.rgb * 1.3, w * opacity);
  return;
}
fragColor = vColor;
fragColor.a *= opacity;
`;
