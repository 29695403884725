import React from 'react';
import { useTranslations } from 'use-intl';
import { Button, Typography } from '@mui/material';
import { Feature } from 'geojson';
import { useFitMapToFeatures } from './hooks';

const GeofencesMapFitToAll = ({ features }: { features: Feature[] }) => {
  const t = useTranslations('pages.manage.geofencing');
  const fit = useFitMapToFeatures();

  return (
    <Button
      color="secondary"
      sx={{ minWidth: '10rem', height: '3rem', boxShadow: 'rgba(51, 51, 51, 0.3) 0px 0px 0px 1.5px' }}
      style={{ border: '2px solid' }}
      variant="outlined"
      onClick={() => fit(features)}
      disabled={!features.length}
    >
      <Typography
        color="secondary"
        sx={{
          textShadow: '-0.5px -0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '0   -0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '0.5px -0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '0.5px  0   0 rgba(51, 51, 51, 0.3),\n'
            + '0.5px  0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '0    0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '-0.5px  0.5px 0 rgba(51, 51, 51, 0.3),\n'
            + '-0.5px  0   0 rgba(51, 51, 51, 0.3)'
        }}
      >
        {t('list.fitMapToAll')}
      </Typography>
    </Button>
  );
};

export default GeofencesMapFitToAll;
