import React, { Reducer, useCallback, useReducer } from 'react';
import { Action } from 'redux';
import { DateTime } from 'luxon';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  AllTime,
  OpenEnded,
  ShareRangeType,
  SpecificRange,
  TemporalShare,
  TemporalSharePermissions,
  TemporalSharesResult,
  UpdateGroupShareBody,
  UpdateShareBody,
} from 'apis/rest/temporalShares/types';
import {
  useGetTemporalSharesFromList,
  useUpdateShare,
} from 'apis/rest/temporalShares/hooks';
import useLastDefined from 'hooks/useLastDefined';
import useSnackbar from 'hooks/useSnackbar';
import useTimezone from 'hooks/session/useTimezone';
import { GroupFriend } from 'apis/rest/friends/types';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { useGetFriendGroupsList } from 'apis/rest/friends/hooks';
import { useStaff } from 'hooks/session/useStaff';
import ShareDateRangePicker from './shareDateRangePicker';
import { isTemporalGroupShare } from '../helpers';
import PermissionsPicker from './permissionsPicker';
import NoteField from './noteField';
import ShareDevice from './shareDevice';

interface SetRangeTypeAction extends Action<'SET_RANGE_TYPE'> {
  payload: ShareRangeType
}
interface SetDateRangeAction extends Action<'SET_DATE_RANGE'> {
  payload: {
    shareStart: string | null | undefined
    shareEnd: string | null | undefined
  }
}
interface SetPermissionsAction extends Action<'SET_PERMISSIONS'> {
  payload: Partial<TemporalSharePermissions>
}

interface SetNotesAction extends Action<'SET_NOTES'> {
  payload: string | null
}

export type State = Partial<TemporalSharePermissions & {
  shareRangeType: ShareRangeType
  shareStart: string | null
  shareEnd: string | null
  notes: string | null
}>;

type ValidState = State & ({
  shareRangeType: ShareRangeType.OpenEnded,
  shareStart: string
} | {
  shareRangeType: ShareRangeType.SpecificRange,
  shareStart: string
  shareEnd: string
});

const INITIAL_STATE: State = {
  shareRangeType: undefined,
  shareStart: undefined,
  shareEnd: undefined,
  notes: undefined,
  canViewCurrent: undefined,
  canViewHistory: undefined,
  canViewForms: undefined,
  canSendTextMessages: undefined,
  canSendConfiguration: undefined,
  canEditCallSign: undefined,
};

const permissionKeys: (keyof TemporalSharePermissions)[] = [
  'canViewCurrent',
  'canViewHistory',
  'canViewForms',
  'canSendTextMessages',
  'canSendConfiguration',
  'canEditCallSign',
];

const reducer: Reducer<State, Action<'RESET'> | SetRangeTypeAction | SetDateRangeAction | SetPermissionsAction | SetNotesAction> = (state, action) => {
  if (action.type === 'RESET') return INITIAL_STATE;
  if (action.type === 'SET_RANGE_TYPE') return { ...state, shareRangeType: action.payload };
  if (action.type === 'SET_DATE_RANGE') return { ...state, shareStart: action.payload.shareStart, shareEnd: action.payload.shareEnd };
  if (action.type === 'SET_PERMISSIONS') return { ...state, ...action.payload };
  if (action.type === 'SET_NOTES') return { ...state, notes: action.payload };
  return state;
};

export const isStateValid = (share: TemporalShare, state: State): state is ValidState => {
  const shareRangeType = state.shareRangeType ?? share.shareRangeType;
  if (shareRangeType === ShareRangeType.AllTime) return true;

  const shareStart = state.shareStart === undefined ? share.shareStart : state.shareStart;
  const start = shareStart ? DateTime.fromISO(shareStart) : undefined;
  if (!start?.isValid) return false;
  if (shareRangeType === ShareRangeType.OpenEnded) return true;

  const shareEnd = state.shareEnd === undefined ? share.shareEnd : state.shareEnd;
  const end = shareEnd ? DateTime.fromISO(shareEnd) : undefined;
  return shareRangeType === ShareRangeType.SpecificRange && !!end?.isValid && start < end;
};

export const hasChange = (share: TemporalShare, state: State) => {
  const keys = Object.keys(state) as (keyof State)[];
  return keys.some(key => state[key] !== undefined && share[key] !== state[key]);
};

const getDateRange = (share: TemporalShare, state: State): AllTime | OpenEnded | SpecificRange => {
  const shareRangeType = state.shareRangeType ?? share.shareRangeType;
  if (shareRangeType === ShareRangeType.AllTime) return { shareRangeType, shareStart: null, shareEnd: null };

  const shareStart = state.shareStart ?? share.shareStart;
  if (shareStart === null) throw new Error(`Start date must be present for ${shareRangeType} share`);
  if (shareRangeType === ShareRangeType.OpenEnded) return { shareRangeType, shareStart, shareEnd: null };

  const shareEnd = state.shareEnd ?? share.shareEnd;
  if (shareEnd === null) throw new Error(`End date must be present for ${shareRangeType} share`);
  return { shareRangeType, shareStart, shareEnd };
};

interface EditDialogProps {
  shareId: number | undefined
  isGroupShare?: boolean
  isSelfShare?: boolean
  onClose: () => void
  onClone: (share: TemporalShare) => void
}
const EditDialog = ({ shareId, isGroupShare = false, isSelfShare = false, onClose, onClone }: EditDialogProps) => {
  const t = useTranslations('pages.sharing.edit');
  const tz = useTimezone();

  const isStaff = useStaff();

  const sharesQuery = useGetTemporalSharesFromList({
    select: useCallback(
      (data: TemporalSharesResult) => {
        if (isSelfShare) return data.selfShares.find(s => s.id === shareId);
        if (isGroupShare) return data.groupShares.find(s => s.id === shareId);
        return data.shares.find(s => s.id === shareId);
      },
      [shareId, isGroupShare, isSelfShare],
    ),
    enabled: shareId !== undefined,
  });

  const share = useLastDefined(sharesQuery.data, []);

  const groupFriendQuery = useGetFriendGroupsList({
    select: useCallback(
      (data: GroupFriend[]) => (share && isTemporalGroupShare(share) ? data.find(friend => friend.groupId === share.groupId) : undefined),
      [share]
    ),
    enabled: shareId !== undefined && isGroupShare,
  });
  const groupFriend = groupFriendQuery.data;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const mutation = useUpdateShare();

  const canSave = !!share
    && !mutation.isPending
    && isStateValid(share, state)
    && hasChange(share, state)
    && (isGroupShare ? !!groupFriend : true);

  const snackbar = useSnackbar();

  const onSave = () => {
    if (!canSave) return;

    const recipient = isTemporalGroupShare(share) ? { groupId: share.groupId } : { organisationId: share?.organisationId };

    const value: UpdateShareBody | UpdateGroupShareBody = {
      id: share.id,
      ...recipient,
      version: share.version,
      notes: (state.notes === undefined ? share.notes : state.notes) ?? null,
      ...getDateRange(share, state),
      canViewCurrent: state.canViewCurrent ?? share.canViewCurrent,
      canViewHistory: state.canViewHistory ?? share.canViewHistory,
      canViewForms: state.canViewForms ?? share.canViewForms,
      canSendTextMessages: state.canSendTextMessages ?? share.canSendTextMessages,
      canSendConfiguration: state.canSendConfiguration ?? share.canSendConfiguration,
      canEditCallSign: state.canEditCallSign ?? share.canEditCallSign,
    };

    // set required permissions
    if (isGroupShare) {
      if (!groupFriend) return;
      permissionKeys.forEach(key => {
        if (groupFriend[key]) value[key] = true;
      });
    }

    mutation.mutate(value, {
      onSuccess: () => {
        snackbar.display({ id: 'editShareSuccess', type: 'success', text: t('notifications.success') });
        onClose();
      },
    });
  };

  const onExited = () => {
    dispatch({ type: 'RESET' });
    mutation.reset();
  };

  const permissions = {
    canViewCurrent: state.canViewCurrent ?? share?.canViewCurrent,
    canViewHistory: state.canViewHistory ?? share?.canViewHistory,
    canSendConfiguration: state.canSendConfiguration ?? share?.canSendConfiguration,
    canSendTextMessages: state.canSendTextMessages ?? share?.canSendTextMessages,
    canViewForms: state.canViewForms ?? share?.canViewForms,
    canEditCallSign: state.canEditCallSign ?? share?.canEditCallSign,
  };

  return (
    <Dialog
      open={shareId !== undefined}
      TransitionProps={{ onExited }}
      fullWidth
      maxWidth="md"
    >
      <TPDialogTitle>{t(isSelfShare ? 'titleSelf' : 'title')}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} mt={3}>
          <ShareDevice share={share} />

          {!isSelfShare && (
            <Box>
              <Typography variant="h5" mb={1}>{t('sharedWith')}</Typography>
              {share && <Typography>{isTemporalGroupShare(share) ? share.groupName : share.organisationName}</Typography>}
            </Box>
          )}

          <ShareDateRangePicker
            tz={tz}
            rangeType={state.shareRangeType ?? share?.shareRangeType ?? ShareRangeType.AllTime}
            range={{ start: state.shareStart ?? share?.shareStart ?? undefined, end: state.shareEnd ?? share?.shareEnd ?? undefined }}
            onChangeRangeType={value => dispatch({ type: 'SET_RANGE_TYPE', payload: value })}
            onChangeRange={value => dispatch({ type: 'SET_DATE_RANGE', payload: { shareStart: value.start, shareEnd: value.end } })}
            disabled={mutation.isPending}
          />

          <PermissionsPicker
            permissions={permissions}
            requiredPermissions={groupFriend ?? {}}
            setPermissions={value => dispatch({ type: 'SET_PERMISSIONS', payload: value })}
            disabled={mutation.isPending}
          />

          <NoteField
            value={(state.notes === undefined ? share?.notes : state.notes) ?? ''}
            setValue={value => dispatch({ type: 'SET_NOTES', payload: value || null })}
            disabled={mutation.isPending}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Box flex={1}>
          <Collapse in={mutation.isError}>
            <Alert sx={{ mb: 3 }} severity="error">{t('notifications.error')}</Alert>
          </Collapse>
          <Stack spacing={3} direction="row" height="4em" justifyContent="space-between">
            {isStaff && (
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                disabled={canSave || mutation.isPending}
                onClick={() => share && onClone(share)}
              >
                {t('actions.clone')}
              </Button>
            )}
            <Stack spacing={3} direction="row">
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: '10rem' }}
                disabled={mutation.isPending}
                onClick={onClose}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{ minWidth: '10rem' }}
                onClick={onSave}
                disabled={!canSave}
              >
                {t(mutation.isPending ? 'actions.saving' : 'actions.save')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
