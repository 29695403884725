import React, { useState } from 'react';
import { HelpOutline } from '@mui/icons-material';
import { Button, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { AlertSmall } from 'components/shared/alertSmall';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';

export const GprsNeverAlert = () => {
  const t = useTranslations('contextbox.asset.details');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <AlertSmall
        severity="warning"
        variant="filled"
        icon={false}
        action={(
          <Tooltip title={t('gprsNeverHelp')}>
            <IconButton
              color="inherit"
              size="small"
              onClick={e => {
                e.preventDefault();
                setDialogOpen(true);
              }}
            >
              <HelpOutline fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      >
        {t('gprsNever')}
      </AlertSmall>
      <Dialog
        open={dialogOpen}
        onClose={e => {
          // @ts-ignore
          e.preventDefault();
          setDialogOpen(false);
        }}
        onClick={e => e.preventDefault()}
      >
        <TPDialogTitle>{t('gprsNeverDialog.title')}</TPDialogTitle>
        <DialogContent>
          <Typography sx={{ mt: 2 }}>{t('gprsNeverDialog.description')}</Typography>
        </DialogContent>
        <TPDialogActions>
          <Button onClick={e => {
            e.preventDefault();
            setDialogOpen(false);
          }}>
            {t('gprsNeverDialog.close')}
          </Button>
        </TPDialogActions>
      </Dialog>
    </>
  );
};
