import React, { useState, useEffect } from 'react';
import useTranslation from 'hooks/useTranslation';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import GroupedSelectInput from 'components/shared/forms/inputs/grouped-select';
import TextInput from 'components/shared/forms/inputs/text';
import { useAssetLabel, useAssetLabelKey } from 'components/shared/assetLabel';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import { FeatureFlag } from 'components/shared/featureFlag';
import clsx from 'clsx';
import { useArchiveAsset, useGetAssetsList } from 'apis/rest/assets/hooks';
import Autocomplete from '@mui/material/Autocomplete';
import useSnackbar from 'hooks/useSnackbar';
import Alert from '@mui/material/Alert';
import useStyles from './asset-styles';
import AssetEditCallsign from './assetEditCallsign-view';

const AssetDetails = ({
  localAsset,
  readOnly,
  categories,
  saveAssetDetails,
  isSaving,
}) => {
  const t = useTranslation('pages.assetView');
  const classes = useStyles();
  const assetLabel = useAssetLabel();
  const assetLabelKey = useAssetLabelKey();

  const assetsQuery = useGetAssetsList().query;
  const assets = assetsQuery.isSuccess ? assetsQuery.data : [];
  const archiveAsset = useArchiveAsset();
  const snackbar = useSnackbar();

  const groupedCategories = () => categories.filter(c => c?.id !== 'System').reduce((acc, obj) => {
    const key = obj.group;
    if (!acc[key]) {
      acc[key] = {
        name: key.charAt(0).toUpperCase() + key.slice(1), // capitalize group name
        children: []
      };
    }
    acc[key].children.push(obj);
    acc[key].children.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return acc;
  }, {});

  const [category, setCategory] = useState(localAsset.category);
  const [assetDetails, setAssetDetails] = useState({
    name: localAsset.name || '',
    make: localAsset.make || '',
    model: localAsset.model || '',
    variant: localAsset.variant || '',
    messagingHandle: localAsset.messagingHandle || '',
    watchlistGroup: localAsset.watchlistGroup || '',
    callSign: localAsset.callSign || '',
    tailNumber: localAsset.tailNumber || '',
  });
  useEffect(() => {
    setAssetDetails({
      name: localAsset.name || '',
      make: localAsset.make || '',
      model: localAsset.model || '',
      variant: localAsset.variant || '',
      messagingHandle: localAsset.messagingHandle || '',
      watchlistGroup: localAsset.watchlistGroup || '',
      callSign: localAsset.callSign || '',
      tailNumber: localAsset.tailNumber || '',
    });
  }, [localAsset]);

  const save = e => {
    e.preventDefault();
    saveAssetDetails({
      ...assetDetails,
      category: categories.find(c => c.label === category),
    });
  };

  const toggleArchive = () => {
    archiveAsset.mutate({ assetId: localAsset.id, archived: !localAsset.archived }, {
      onSuccess: () => {
        snackbar.display({
          id: 'archiving',
          text: t('archivalUpdated', { name: assetLabel(localAsset) }),
          type: 'success',
        });
      }
    });
  };

  return (
    <SettingsSection title={t('generalTitle')} description={t('generalDescription')}>
      <DetailPanel p={3}>
        <Alert severity="info" sx={{ mb: 3 }}>
          {t.rich('assetLabelMessage', {
            assetLabelKey,
            link: chunks => <Link to="/settings/general">{chunks}</Link>,
            break: () => <br />,
          })}
        </Alert>
        <Grid container spacing={3}>
          <Grid item sm={12} md={12}>
            <TextInput
              id="name"
              label={t('name')}
              value={assetDetails.name}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
              disabled={readOnly || isSaving}
              maxLength="50"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <GroupedSelectInput
              id="category"
              label={t('category')}
              options={groupedCategories()}
              value={category}
              onChange={(key, value) => setCategory(value)}
              readOnly={readOnly}
              disabled={isSaving}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              variant="outlined"
              options={assets
                .map(a => a.make?.toString() || '') // get makes of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              id="make"
              label={t('make')}
              value={assetDetails.make}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, make: value })}
              onInputChange={(key, value) => setAssetDetails({ ...assetDetails, make: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('make')} fullWidth />}
              disabled={readOnly || isSaving}
              maxLength="50"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              variant="outlined"
              options={assets
                .map(a => a.model?.toString() || '') // get models of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              id="model"
              label={t('model')}
              value={assetDetails.model}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, model: value })}
              onInputChange={(key, value) => setAssetDetails({ ...assetDetails, model: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('model')} fullWidth />}
              disabled={readOnly || isSaving}
              maxLength="50"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              variant="outlined"
              options={assets
                .map(a => a.variant?.toString() || '') // get variants of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              id="variant"
              value={assetDetails.variant}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, variant: value })}
              onInputChange={(key, value) => setAssetDetails({ ...assetDetails, variant: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('variant')} fullWidth />}
              disabled={readOnly || isSaving}
              maxLength="50"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput
              id="tailNumber"
              label={t('tailNumber')}
              value={assetDetails.tailNumber}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
              disabled={readOnly || isSaving}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput
              id="callSign"
              label={t('callSign')}
              value={assetDetails.callSign}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
              disabled={readOnly || isSaving}
              inputProps={{
                endAdornment: readOnly ? <AssetEditCallsign asset={localAsset} /> : undefined,
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput
              id="messagingHandle"
              label={t('messagingHandle')}
              value={assetDetails.messagingHandle}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, [key]: value })}
              disabled={readOnly || isSaving}
              className={clsx([classes.readOnly, classes.helperText])}
              helperText="@msg.tracplus.com"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              id="watchlistGroup-select"
              variant="outlined"
              options={assets
                .map(a => a.watchlistGroup?.toString() ?? '') // get watchlist groups of all assets
                .filter((watchlistGroup, index, array) => watchlistGroup && array.indexOf(watchlistGroup) === index) // filter undefined & duplicates
                .sort((a, b) => a.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              value={assetDetails.watchlistGroup}
              disabled={readOnly || isSaving}
              onChange={(key, value) => setAssetDetails({ ...assetDetails, watchlistGroup: value })}
              onInputChange={(key, value) => setAssetDetails({ ...assetDetails, watchlistGroup: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('watchlistGroup')} fullWidth />}
            />
          </Grid>
          {!readOnly && (
          <Grid item sm={12} md={12} className={classes.inputButtonWrapper}>
            <Button className={classes.inputButton} variant="contained" onClick={save} disabled={isSaving} sx={{ minWidth: '10rem' }}>
              {t(isSaving ? 'saving' : 'save')}
            </Button>
          </Grid>
          )}
        </Grid>
      </DetailPanel>
      {!readOnly
      && (
      <DetailPanel p={3} mt={3}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" height="4rem">
            <Stack justifyContent="center" spacing={1}>
              <Typography variant="h2">{t('archival')}</Typography>
              <Typography>{localAsset.archived ? t('archivedDescription') : t('archiveDescription')}</Typography>
            </Stack>
            <Button
              variant="outlined"
              size="large"
              color={localAsset.archived ? 'success' : 'error'}
              sx={{ minWidth: '10rem', height: '100%s' }}
              onClick={() => toggleArchive()}
            >
              {archiveAsset.isLoading
                ? <CircularProgress size="2rem" color={localAsset.archived ? 'success' : 'error'} />
                : (localAsset.archived ? t('unarchive') : t('archive'))}
            </Button>
          </Stack>
        </Stack>
      </DetailPanel>
      )}
    </SettingsSection>
  );
};

export default AssetDetails;
