import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(8, 0),
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  tableContainer: {
    padding: 0
  },
  highlight: {
    fontWeight: 500,
    color: theme.palette.primary.distressRed
  },
  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& .MuiPaper-root': {
      marginTop: 0
    },
    '& > div': {
      marginTop: '15px',
      boxShadow: 'none',
      border: theme.border.default
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3)
    },
    '& .MuiTableCell-root:first-child': {
      padding: theme.spacing(0, 0, 0, 0.5)
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    },
    '& .MuiTableCell-paddingNone': {
      width: 42,
    }
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: '300px',
    fontSize: theme.typography.subtitle1.fontSize,
    border: theme.border.default,
    boxShadow: theme.boxShadow.default
  },
  actionButtonColor: {
    color: theme.palette.common.actionIcon
  },
  disabledActionButtonColor: {
    display: 'none'
  },
  newButton: {
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    marginLeft: 8,
    padding: '19.5px 14px 19.5px 10px',
    borderRadius: 24,
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    }
  },
  selected: {
    '& .MuiFormControlLabel-label h6, .MuiFormControlLabel-label p': {
      color: theme.palette.primary.main
    }
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      padding: 10
    }
  },
  iceContactAlert: {
    backgroundColor: theme.palette.primary.distressRedLight
  },
  userHasNotJoinedTheOrg: {
    '&.MuiTableRow-root.MuiTableRow-hover': {
      '&:hover': {
        cursor: 'default !important',
        backgroundColor: theme.palette.primary.distressRedLight
      }
    }
  },
  notJoinedOrgAndHasContacts: {
    '&:hover': {
      cursor: 'default !important'
    }
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
}));
