import React from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslations } from 'use-intl';

interface GeofenceMapControlsViewProps {
  onAdd: () => void
  onRemove: () => void
  onImport: () => void
  allowRemove: boolean
  allowImport: boolean
  isViewOnly: boolean
  draw: boolean
}

const buttonStyle = {
  minWidth: '10rem',
  height: '3rem'
};

const GeofencesMapControlsView: React.FC<GeofenceMapControlsViewProps> = ({
  onAdd,
  onRemove,
  onImport,
  allowRemove,
  allowImport,
  isViewOnly,
  draw,
}) => {
  const t = useTranslations('pages.manage.geofencing.map.controls');
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={2}>
        <Button size="large" variant="outlined" sx={buttonStyle} onClick={() => onImport()} disabled={!allowImport || isViewOnly}>{t('importShapes')}</Button>
        <Button size="large" variant="outlined" sx={buttonStyle} onClick={() => onAdd()} disabled={draw || isViewOnly}>{t('addShape')}</Button>
        <Button size="large" variant="outlined" sx={buttonStyle} onClick={() => onRemove()} disabled={!allowRemove || isViewOnly} color="error">{t('removeShape')}</Button>
      </Stack>
    </Stack>
  );
};

export default GeofencesMapControlsView;
