import React from 'react';
import { useTranslations } from 'use-intl';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import { Box, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUser } from 'hooks/session/useUser';
import useStyles from './organisationSelector-styles';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { getSelectedMapId, setAssetFollow } from 'slices/map.slice';
import { setOrganisationIdAction } from 'slices/session/thunks';
import { setOmniboxGroupBy } from 'slices/app.slice';

interface HeaderOrganisationSelectorProps {

}

const HeaderOrganisationSelector: React.FC<HeaderOrganisationSelectorProps> = ({

}) => {
  const t = useTranslations('header.modules.organisationSelector');
  const classes = useStyles();
  const usecodeLogin = useIsUsercodeLogin();
  const username = useUser()?.name;
  const dispatch = useAppDispatch();
  const organisationId = useOrganisationId();
  const organisations = useSelector((state: ReduxState) => state.session.user?.memberOf) ?? [];
  const selectedMapId = useSelector(getSelectedMapId);


  const organisationsSortedByName = [...organisations].sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)) ?? [];

  const onChange = async (e: SelectChangeEvent<string>) => {
    dispatch(setOrganisationIdAction(e.target.value));
    dispatch(setAssetFollow({ mapId: selectedMapId, isFollowed: false }));
    dispatch(setOmniboxGroupBy({ assets: 'latestActivity', devices: 'none', missions: 'none' }));
  };

  if (usecodeLogin) return <Box role="heading" px={2} fontWeight={500} ml={2} mr="auto">{username}</Box>;

  if (organisations.length === 1) return <Box role="heading" px={2} fontWeight={500} ml={2} mr="auto">{organisations[0]?.name ?? organisationId}</Box>;

  return (
    <FormControl variant="standard" className={classes.wrapper}>
      <Select
        variant="standard"
        labelId="organisationSelectLabel"
        value={organisationId}
        renderValue={id => <Box role="heading">{organisations.find(org => org.id === id)?.name ?? id}</Box>}
        onChange={onChange}
        input={<BootstrapInput className={classes.input} />}
        IconComponent={() => (<Arrow className={classes.icon} />)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
      >
        {organisationsSortedByName.map(o => (
          <MenuItem className={classes.menuItem} key={o.id} value={o.id}>{o.name}</MenuItem>
        ))}
      </Select>
      <InputLabel id="organisationSelectLabel" sx={{ display: 'none' }}>{t('selectOrganisation')}</InputLabel>
    </FormControl>
  );
};

export default HeaderOrganisationSelector;
