import React from 'react';

const DC3 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 31.378113,54.014061 c -0.0021,-0.262585 -0.8321,0.374932 -4.939168,0.627698 -3.085447,0.189891 -3.667958,-2.507575 -2.723516,-4.122554 0.805742,-1.3778 6.572527,-3.03663 6.639237,-3.425074 0.121067,-0.70492 -1.238775,-4.702445 -1.110327,-9.28426 0.128448,-4.581815 -0.03424,-3.294067 -1.245382,-3.343995 C 5.7848317,33.550034 2.7890911,36.008738 2.3336599,31.300484 2.1176271,29.067135 23.173403,25.076073 23.165314,24.401933 c -0.04024,-3.355159 0.335889,-4.45616 2.562536,-4.489221 2.226647,-0.03306 2.495796,0.794557 2.477045,3.994069 l 1.323366,-0.05068 c 0,0 0.08813,7.75e-4 0.03567,-4.05383 -0.05246,-4.054606 2.056563,-6.432002 3.041042,-6.487844 0.984481,-0.05584 3.095827,2.436738 3.038784,6.491282 -0.05705,4.054543 0.03108,4.053867 0.03108,4.053867 l 1.323307,0.05218 c -0.01513,-3.199531 0.254952,-4.026844 2.48156,-3.991264 2.226608,0.03558 2.601493,1.137004 2.557459,4.492115 -0.0088,0.674131 21.042396,4.689003 20.823838,6.922106 -0.460756,4.707735 -3.453713,2.245646 -25.66886,3.136364 -1.211197,0.04856 -1.37243,-1.239373 -1.249164,3.342585 0.123267,4.581958 -1.241095,8.577942 -1.120825,9.282998 0.06627,0.38852 5.831175,2.05387 6.635358,3.43258 0.942616,1.616046 0.357054,4.312853 -2.728176,4.119472 -4.106779,-0.25741 -4.936039,-0.895866 -4.938455,-0.633283 -0.01282,1.393402 -1.207232,1.500333 -1.207232,1.500333 0,0 -1.194296,-0.108282 -1.205536,-1.501698 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default DC3;
