import messagesEN from 'locales/en';
import messagesES from 'locales/es';

export const getName = (id: string, lang: string): string => {
  const messages = lang === 'es' ? messagesES : messagesEN;
  // @ts-ignore
  return messages.pages.map.template.name[id];
};

export const getAttribution = (id: string, lang: string): string => {
  const messages = lang === 'es' ? messagesES : messagesEN;
  // @ts-ignore
  return messages.pages.map.template.attribution[id] ?? '';
};
