import React, { ReactNode } from 'react';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { useDemoMode, useStaff } from 'hooks/session/useStaff';
import { useLabs } from 'contexts/labs/labs-context';

interface FeatureGateProps {
  feature: string
  enabled?: ReactNode
  disabled?: ReactNode
  loading?: ReactNode
  allowStaff?: boolean
  onlyLabs?: boolean
}

const FeatureGate: React.FC<FeatureGateProps> = ({ feature, enabled, disabled, loading, allowStaff = false, onlyLabs = false }) => {
  const featureAssets = useFeatureAssets(feature, !allowStaff);
  const isStaff = useStaff();
  const isDemoMode = useDemoMode();
  const labs = useLabs();

  /* eslint-disable react/jsx-no-useless-fragment */
  if (onlyLabs) {
    if (labs?.isLoading) return <>{loading}</>;
    if (!labs?.value) return <>{disabled}</>;
  }
  if (allowStaff && isStaff && !isDemoMode) return <>{enabled}</>;
  if (featureAssets.isLoading) return <>{loading}</>;
  if (featureAssets.some) return <>{enabled}</>;
  return <>{disabled}</>;
  /* eslint-enable react/jsx-no-useless-fragment */
};

export default FeatureGate;
