import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const StaffPageIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <circle data-color="color-2" cx="6" cy="6" r="3" />
      <path data-color="color-2" d="M7,25V15c0-1.479,0.464-2.85,1.25-3.982C8.167,11.013,8.084,11,8,11H4c-2.206,0-4,1.794-4,4 v6h2v7h7v-3H7z" />
      <circle data-color="color-2" cx="26" cy="6" r="3" />
      <path data-color="color-2" d="M25,25V15c0-1.479-0.464-2.85-1.25-3.982C23.833,11.013,23.916,11,24,11h4 c2.206,0,4,1.794,4,4v6h-2v7h-7v-3H25z" />
      <path fill="#111111" d="M18,10h-4c-2.757,0-5,2.243-5,5v8h3v8h8v-8h3v-8C23,12.243,20.757,10,18,10z" />
      <circle fill="#111111" cx="16" cy="4" r="4" />
    </SvgIcon>
  );
};

export default StaffPageIcon;
