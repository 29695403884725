import React, { ReactNode } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Breakpoint
} from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export enum DeleteContactGroupDialogStatus {
  Cancelled = 'cancelled',
  Removed = 'removed',
}

export interface DeleteContactGroupDialogProps {
  open: boolean
  onClose: (status: DeleteContactGroupDialogStatus, groupId: number) => void
  group: ContactGroup
  mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id'>>
  title: ReactNode
  ariaLabel: string
  children: ReactNode
  dialogSize?: Breakpoint | false,
  canDelete?: boolean,
}

const DeleteContactGroupDialog = ({
  open,
  onClose,
  group,
  mutation,
  title,
  ariaLabel,
  children,
  dialogSize,
  canDelete = true,
}: DeleteContactGroupDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.contactGroups.delete');

  const handleClose = (status: DeleteContactGroupDialogStatus): void => {
    onClose(status, group.id);
  };

  const handleCancel = (): void => {
    handleClose(DeleteContactGroupDialogStatus.Cancelled);
  };

  const handleRemove = (): void => {
    mutation.mutate(group, {
      onSuccess: () => handleClose(DeleteContactGroupDialogStatus.Removed),
    });
  };

  const onExited = (): void => {
    mutation.reset();
  };

  const canConfirm = !group.isDefault && !mutation.isPending && canDelete;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!mutation.isPending) handleCancel();
      }}
      aria-label={ariaLabel}
      fullWidth
      maxWidth={dialogSize ?? 'sm'}
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3, pb: 0 }}>
        <Stack spacing={3} my={3}>
          {children}
          {mutation.isError && <Alert severity="error">{t('errorDeleting')}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey' }}>
        <Stack spacing={3} flex={1} direction="row" justifyContent="flex-end" height="4em">
          <Button
            variant="outlined"
            size="large"
            sx={{ minWidth: '10rem' }}
            disabled={mutation.isPending}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="error"
            sx={{ minWidth: '10rem' }}
            disabled={!canConfirm}
            onClick={handleRemove}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContactGroupDialog;
