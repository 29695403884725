import React from 'react';

const S2Tracker = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 30.330985,17.044101 c -0.772105,1.543045 -0.642966,3.11037 -0.614818,4.884381 0.02547,1.605195 -0.03416,4.816069 -0.03416,4.816069 l -3.03993,0.341565 -0.102469,-3.005773 c 0,0 -0.165529,-0.635542 -2.151861,-0.683131 -2.117043,-0.05072 -2.15186,0.683131 -2.15186,0.683131 l -0.06831,3.244868 -19.2642743,1.810296 c 0,0 -1.5983331,0.149433 -1.7078257,0.990539 -0.1094926,0.841106 -0.072214,0.930709 -0.034157,1.741982 0.038057,0.811273 1.7419822,0.956382 1.7419822,0.956382 l 19.5716828,1.67367 1.195478,4.576972 h 1.161321 l 1.093009,-4.337877 3.859687,0.273252 0.751443,10.58852 -9.495512,2.459269 c 0,0 -0.26456,2.774348 0.102473,2.800834 1.070215,0.07723 10.281111,-3e-6 10.281111,-3e-6 0,0 -0.03668,1.170439 0.06832,1.741983 0.107207,0.58358 0.655371,0.58066 0.655371,0.58066 0,0 0.535355,0.0029 0.642562,-0.58066 0.105,-0.571544 0.06832,-1.741983 0.06832,-1.741983 0,0 9.210896,0.07723 10.281111,3e-6 0.367033,-0.02649 0.102473,-2.800834 0.102473,-2.800834 l -9.495512,-2.459269 0.751443,-10.58852 3.859687,-0.273252 1.093009,4.337877 H 40.6121 l 1.195478,-4.576972 19.571683,-1.67367 c 0,0 1.703922,-0.145109 1.741982,-0.956382 0.03805,-0.811273 0.07533,-0.900876 -0.03416,-1.741982 -0.109493,-0.841106 -1.707826,-0.990539 -1.707826,-0.990539 l -19.264274,-1.810296 -0.06831,-3.244868 c 0,0 -0.03482,-0.733851 -2.15186,-0.683131 -1.986332,0.04759 -2.151861,0.683131 -2.151861,0.683131 l -0.102469,3.005773 -3.03993,-0.341565 c 0,0 -0.05963,-3.210874 -0.03416,-4.816069 0.02815,-1.774011 0.161436,-3.34342 -0.614818,-4.884381 -0.584769,-1.160838 -1.810295,-1.15846 -1.810295,-1.15846 0,0 -1.225526,-0.01019 -1.810295,1.15846 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default S2Tracker;
