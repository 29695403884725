import { useQuery } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';

const queryKey = ['aircraftTypes'];

export interface AircraftType {
  type: 'LandPlane' | 'Amphibian' | 'Helicopter' | 'Gyrocopter' | 'Drone',
  designator: string,
  manufacturer: string,
  model: string | number
}

const queryFn = async () => (await import('./data.json')).default as AircraftType[];

export const useGetAircraftTypes = () => {
  const query = useQuery<AircraftType[], HttpResponseError>({
    queryKey,
    queryFn,
  });

  return { query, queryKey };
};
