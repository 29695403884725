/* eslint-disable max-classes-per-file */

export interface BackLinkState {
  type: string
  id?: number
}

export class IceContactGroupBackLinkState implements BackLinkState {
  type = 'iceContactGroup';
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  static isValid(state: BackLinkState | undefined): state is IceContactGroupBackLinkState {
    const s = state as IceContactGroupBackLinkState | undefined;
    return s?.type === 'iceContactGroup' && typeof s.id === 'number';
  }

  static pathname(state: IceContactGroupBackLinkState): string {
    return `/manage/ice/groups/${state.id}`;
  }
}

export class MessagingWhitelistContactGroupBackLinkState implements BackLinkState {
  type = 'messagingWhitelistContactGroup';
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  static isValid(state: BackLinkState | undefined): state is MessagingWhitelistContactGroupBackLinkState {
    const s = state as MessagingWhitelistContactGroupBackLinkState | undefined;
    return s?.type === 'messagingWhitelistContactGroup' && typeof s.id === 'number';
  }

  static pathname(state: MessagingWhitelistContactGroupBackLinkState): string {
    return `/manage/device-messaging/groups/${state.id}`;
  }
}

export class PersonBackLinkState implements BackLinkState {
  type = 'person';
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  static isValid(state: BackLinkState | undefined): state is PersonBackLinkState {
    const s = state as PersonBackLinkState | undefined;
    return s?.type === 'person' && typeof s.id === 'number';
  }

  static pathname(state: PersonBackLinkState): string {
    return `/manage/people/${state.id}`;
  }
}

export class PeopleGroupBackLinkState implements BackLinkState {
  type = 'peopleGroup';
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  static isValid(state: BackLinkState | undefined): state is MessagingWhitelistContactGroupBackLinkState {
    const s = state as MessagingWhitelistContactGroupBackLinkState | undefined;
    return s?.type === 'peopleGroup' && typeof s.id === 'number';
  }

  static pathname(state: MessagingWhitelistContactGroupBackLinkState): string {
    return `/manage/people-groups/${state.id}`;
  }
}
