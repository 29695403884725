import React from 'react';
import { Search as SearchIcon, Clear } from '@mui/icons-material';
import {
  Box,
  Paper,
  InputBase,
  IconButton
} from '@mui/material';

import useTranslation from 'hooks/useTranslation';
import useStyles from './search-styles';

const OBSearch = ({
  value,
  setValue
}) => {
  const classes = useStyles();
  const t = useTranslation('omnibox.modules.search');

  return (
    <Box id="omniboxSearch" className={classes.container} display="flex" flexDirection="row" role="search">
      <Paper className={classes.omniSearch}>
        <IconButton className={classes.searchIcon} aria-label="Search" size="large">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          type="search"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={t('inputPlaceholder')}
          endAdornment={value && (
            <IconButton size="small" onClick={() => setValue('')}>
              <Clear sx={theme => ({ color: theme.palette.common.text })} />
            </IconButton>
          )}
          autoFocus
        />
      </Paper>
    </Box>
  );
};

export default OBSearch;
