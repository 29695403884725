import React from 'react';

const Kodiak = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 30.533307,12.881997 c -0.181676,0.694486 -1.657118,1.314077 -1.914361,1.621403 -0.830305,0.991953 0.314581,2.483738 0.314581,2.483738 0,0 -0.493266,8.289949 -0.926404,8.303154 -1.253684,0.03822 -10.201479,0.492677 -13.893746,0.386067 -0.531978,-0.01536 -10.8057476,-0.3491 -11.0332973,0.372432 -2.14265777,6.794106 -0.4482559,6.314022 0.030579,6.410416 2.3047192,0.463956 6.0521202,1.735368 10.5837323,1.603489 3.320027,-0.09662 14.617957,-0.139662 14.617957,-0.139662 0.0669,1.807866 0.183512,6.24229 0.279325,6.836821 0.780555,4.570827 1.405457,7.419758 1.405457,7.419758 0,0 -11.509981,-0.04871 -11.60962,0.296998 -0.173037,0.600373 -0.397363,5.282263 0.09957,5.466863 0.668014,0.248152 13.402995,0.177028 13.642683,0.121987 0.239688,0.05504 12.974669,0.126165 13.642683,-0.121987 0.496935,-0.1846 0.272609,-4.86649 0.09957,-5.466863 -0.09964,-0.345708 -11.60962,-0.296998 -11.60962,-0.296998 0,0 0.624901,-2.848931 1.405457,-7.419758 0.09581,-0.594531 0.212423,-5.028955 0.279325,-6.836821 0,0 11.29793,0.04305 14.617956,0.139662 4.531613,0.131879 8.279014,-1.139533 10.583733,-1.603489 0.478833,-0.09639 2.173235,0.38369 0.03058,-6.410416 C 60.951897,25.327259 50.678128,25.661 50.14615,25.676359 46.453883,25.782969 37.506088,25.328515 36.252404,25.290292 35.819266,25.277087 35.326,16.987138 35.326,16.987138 c 0,0 1.144886,-1.491785 0.314581,-2.483738 -0.257243,-0.307326 -1.732685,-0.926917 -1.914361,-1.621403 -0.222864,-0.851936 -1.372989,-3.8578063 -1.626251,-3.8692975 -0.22517,-0.010217 -1.343798,3.0173615 -1.566662,3.8692975 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default Kodiak;
