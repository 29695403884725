import React, { ReactNode } from 'react';
import { Box, BoxProps, Stack, StackProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { excludeForwardProps } from 'utils/style';

const ImageWrapper = styled(Box, {
  shouldForwardProp: excludeForwardProps(['active', 'imageSize']),
})<{ active: boolean, imageSize: string | undefined, disabled: boolean }>(({ theme, active, imageSize = '50px', disabled }) => `
  box-sizing: content-box;
  width: ${imageSize};
  height: ${imageSize};
  cursor: ${disabled ? 'default' : 'pointer'};
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  border-color: ${disabled || !active ? '#00000000' : theme.palette.primary.main};
  opacity: ${disabled ? '30%' : '100%'};
  transition: all 0.25s;
  overflow: hidden;
  
  &:hover {
    border-color: ${disabled ? undefined : (active ? theme.palette.primary.main : theme.palette.common.blueConstant)}
  }

  & svg,img {
    display: block;
    width: 100%;
    height: auto;
  }
`);

export interface CirclePickerOption<T> {
  value: T
  image: ReactNode
  label: string
  tooltip?: ReactNode
  disabled?: boolean
}

interface CirclePickerProps<T> {
  value: T | undefined
  options: CirclePickerOption<T>[]
  onChange: (value: T) => void
  imageSize?: string
  stackProps?: StackProps
  boxProps?: BoxProps
  disabled?: boolean
}

const CirclePicker = <T extends string | number>({
  value,
  options,
  onChange,
  imageSize,
  stackProps,
  boxProps,
  disabled = false,
}: CirclePickerProps<T>) => (
  <Stack direction="row" role="radiogroup" spacing={1} py={1} flexWrap="wrap" {...stackProps}>
    {options.map(option => (
      <Box
        key={option.value}
        role="radio"
        aria-checked={option.value === value}
        aria-label={option.label}
        aria-disabled={disabled || (option?.disabled ?? false)}
        tabIndex={0}
        onClick={() => ((disabled || (option?.disabled ?? false)) ? undefined : onChange(option.value))}
        {...boxProps}
      >
        <Tooltip title={option.tooltip ?? option.label}>
          <ImageWrapper active={option.value === value} imageSize={imageSize} disabled={disabled || (option?.disabled ?? false)}>
            {option.image}
          </ImageWrapper>
        </Tooltip>
      </Box>
    ))}
  </Stack>
  );

export default CirclePicker;
