import React from 'react';

const Oooioff = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path className="icon-detail" fill="#fff" d="M4.8,7l0.7,0.7l2-2V10h1V5.7l2,2L11.2,7L8.35,4.15c-0.2-0.2-0.5-0.2-0.7,0L4.8,7z" />
    <rect className="icon-detail" fill="#fff" x="4.5" y="11" width="7" height="1" />
  </svg>
);

export default Oooioff;
