import { Skeleton } from '@mui/material';
import { useGetGeocodedLocationByH3Index } from 'apis/rest/geocoding/hooks';
import React from 'react';

const GeocodeH3Index = ({ index }: { index: string }) => {
  const { data } = useGetGeocodedLocationByH3Index(index);

  if (data?.location) {
    return <span>{data.location}</span>;
  }
  return <Skeleton sx={{ flex: '1' }} />;
};

export default GeocodeH3Index;
