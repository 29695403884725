import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Paper,
  Stack,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { useGetGeofences } from 'apis/rest/geofence/hooks';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import GeofencesListListView from './GeofencesListList.view';
import GeofencesListFiltersView from './GeofencesListFilters.view';

const GeofencesList: React.FC = () => {
  const t = useTranslations('pages.manage.geofencing');
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('geofenceCategory') ?? 'All';
  const filterText = searchParams.get('filter') ?? '';

  const geofenceQuery = useGetGeofences();

  const hasData = (geofenceQuery.data ?? []).length > 0;

  const filteredGeofences = useMemo(() => (
    (geofenceQuery.data ?? [])
      .filter(g => (category === 'All' ? true : g.category === category))
      .filter(g => [g.name.toLowerCase()].some(s => (filterText ? s.includes(filterText.toLowerCase()) : true)))
  ), [geofenceQuery.data, filterText, category]);

  const onChangeCategory = (value: string) => {
    // setSelectedItem(undefined);
    setSearchParams(params => {
      params.set('geofenceCategory', value);
      return params;
    }, { replace: true });
  };
  const onChangeFilter = (value: string) => {
    setSearchParams(params => {
      params.set('filter', value);
      return params;
    }, { replace: true });
  };

  const highlightedGeofenceIds = useSelector(slice.selectors.selectHighlightedGeofenceIds);

  return (
    <Stack spacing={3} maxHeight="100%">
      <Paper elevation={0} sx={{
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
      }}>
        <GeofencesListFiltersView
          filterText={filterText}
          category={category}
          onChangeFilter={onChangeFilter}
          onChangeCategory={onChangeCategory}
        />
        <Box overflow="auto">
          {filteredGeofences.length > 0 && (
            <GeofencesListListView
              geofences={filteredGeofences}
              onRowHover={ids => dispatch(slice.actions.highlightGeofences(ids))}
              hoveredIds={highlightedGeofenceIds}
            />
          )}
          {!filteredGeofences.length && hasData && (
            <Alert severity="warning" sx={{ m: 3, mt: 0 }}>{t('errorsAndWarnings.noMatchFilter')}</Alert>
          )}
          {!filteredGeofences.length && !hasData && (
            <Alert severity="info" sx={{ m: 3, mt: 0 }}>{t('errorsAndWarnings.noGeofencesFound')}</Alert>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default GeofencesList;
