import { DateTime } from 'luxon';

export const inferredEventsQueryKeys = {
  all: (org: string) => [org, 'inferredEvents'] as const,
  range: (org: string, from: DateTime, until: DateTime) => [...inferredEventsQueryKeys.all(org), {
    from: from.toISO({ suppressMilliseconds: true }),
    until: until.toISO({ suppressMilliseconds: true }),
  }] as const,
  asset: (org: string, assetId: number | undefined, from: DateTime, until: DateTime) => [...inferredEventsQueryKeys.all(org), assetId, {
    from: from.toISO({ suppressMilliseconds: true }),
    until: until.toISO({ suppressMilliseconds: true }),
  }] as const,
};
