import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import useSerialType from 'hooks/settings/useSerialType';

interface DeviceDetailsProps {
  device: DeviceBasic | undefined
  isLoading?: boolean
}

export const DeviceDetails = ({ device, isLoading = false }: DeviceDetailsProps) => {
  const deviceSerialType = useSerialType();

  if (isLoading) return <Skeleton />;
  if (!device) return null;

  return (
    <Stack>
      <Typography>{device ? <>{device.make} {device.model}</> : <Skeleton width="30ch" />}</Typography>
      <Typography variant="subtitle1">{device ? device[deviceSerialType] : <Skeleton width="30ch" />}</Typography>
    </Stack>
  );
};

export const GroupDeviceDetails = ({ device, isLoading }: DeviceDetailsProps) => {
  const deviceSerialType = useSerialType();

  if (isLoading) return <Skeleton />;
  if (!device) return null;

  return (
    <Stack>
      <Typography variant="h3">{device ? <>{device.make} {device.model}</> : <Skeleton width="30ch" />}</Typography>
      <Typography variant="subtitle1">{device ? device[deviceSerialType] : <Skeleton width="30ch" />}</Typography>
    </Stack>
  );
};
