import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import SelectSerialType from 'components/shared/selectSerialType';
import { AssetDevicePair } from 'hooks/assetGroup/useAssetAndDevices';
import { MultiSelect } from 'components/shared/multiselect';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById, selectGroupAssetsByDeviceId } from 'components/pages/sharing/helpers';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import { AssetDeviceLabel } from './AssetLabel';

export type AssignGroupDialogStatus = 'CANCELLED' | 'ASSIGNED';

export interface AssignAssetGroupDialogProps {
  open: boolean;
  assetDevicesPairs: AssetDevicePair[];
  title: ReactNode;
  ariaLabel: string;
  isReady: boolean;
  onClose: (status: AssignGroupDialogStatus) => void;
  onSubmit: (assetIds: number[]) => void;
}

const hasChange = (current: Pick<AssetDevicePair, 'assetId'>[], next: Pick<AssetDevicePair, 'assetId'>[] | undefined) => {
  if (!next) return false;
  return current.map(x => x.assetId).sort().join(',') !== next.map(x => x.assetId).sort().join(',');
};

export const AssignAssetGroupDialog = ({
  open,
  onClose,
  assetDevicesPairs,
  onSubmit,
  title,
  ariaLabel,
  isReady
}: AssignAssetGroupDialogProps): JSX.Element => {
  const t = useTranslations('pages.manage.assetGroups.edit.assetDialog');
  const [selectedItems, setSelectedItems] = useState<Pick<AssetDevicePair, 'assetId' | 'deviceId'>[] | undefined>(assetDevicesPairs);
  const assetsQuery = useGetAssetsList({ select: selectGroupAssetsByDeviceId }).query;
  const devicesQuery = useGetDevicesList({ select: selectDevicesById }).query;

  const handleClose = (status: AssignGroupDialogStatus) => onClose(status);
  const handleCancel = () => handleClose('CANCELLED');
  const handleSave = () => {
    // if the selected items haven't changed from the assetdevicepairs passed in then we don't need to do anything
    if (!hasChange(assetDevicesPairs, selectedItems)) {
      handleClose('ASSIGNED');
      return;
    }
    onSubmit(selectedItems?.map(m => m.assetId) ?? []);
  };
  const onExited = () => {
    setSelectedItems(assetDevicesPairs);
  };

  const canSave = hasChange(assetDevicesPairs, selectedItems) && isReady;
  const assetsByDeviceId = assetsQuery.data;
  const devicesById = devicesQuery.data ?? {};
  const itemElements: AssetDevicePair[] = Object.values(devicesById).map(device => ({
    asset: assetsByDeviceId?.[device.id],
    device,
    assetId: device.assetId,
    deviceId: device.id,
  }));

  useEffect(() => {
    setSelectedItems(assetDevicesPairs);
  }, [assetDevicesPairs]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
      }}
      aria-labelledby={ariaLabel}
      fullWidth
      maxWidth="lg"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {title}
          <SelectSerialType size="small" InputProps={{ sx: { width: '15rem' } }} />
        </Stack>
      </TPDialogTitle>
      <DialogContent sx={{ p: 0, mb: '-1px' }}>
        <MultiSelect
          preselected={assetDevicesPairs?.map(m => ({ id: m.assetId, item: m }))}
          items={itemElements.map(m => ({ id: m.assetId, item: m }))}
          unselectedLabel={t('unselectedLabel')}
          selectedLabel={t('selectedLabel')}
          onChange={items => setSelectedItems(items.map(m => m.item) as Pick<AssetDevicePair, 'assetId' | 'deviceId'>[])}
          renderItemElement={({ item }) => (
            <AssetDeviceLabel asset={item.asset} device={item.device} />
          )}
          filterOperation={({ item }, filterText) => item.asset?.name.toLowerCase().includes(filterText.toLowerCase()) ?? false}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Stack spacing={3} flex={1}>
          <Stack my={1} spacing={3} direction="row" height="4em" alignSelf="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              disabled={!isReady}
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ minWidth: '10rem' }}
              color="primary"
              disabled={!canSave}
              onClick={handleSave}
            >
              {t(!isReady ? 'saving' : 'save')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
