import React from 'react';

const RotorBrakeOn = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M12.54,8.23c0.13-0.09,0.16-0.26,0.08-0.39c-0.02-0.03-0.05-0.06-0.08-0.08l-3.25-1.9
        c-0.13-0.07-0.3-0.03-0.37,0.1C8.89,6.01,8.88,6.06,8.88,6.1V9.9c0,0.15,0.12,0.27,0.27,0.27c0.05,0,0.09-0.01,0.13-0.04
        L12.54,8.23z"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M8.12,8.15C8.2,8.1,8.22,7.98,8.17,7.9C8.16,7.88,8.14,7.86,8.12,7.85L6.01,6.62
        C5.92,6.57,5.81,6.6,5.77,6.68C5.75,6.71,5.74,6.74,5.74,6.77v2.46c0,0.1,0.08,0.18,0.18,0.18c0.03,0,0.06-0.01,0.09-0.02
        L8.12,8.15z"
    />
    <path
      className="icon-detail"
      fill="#fff"
      d="M4.93,8.1C4.99,8.06,5,7.99,4.97,7.93C4.96,7.92,4.94,7.91,4.93,7.9L3.52,7.07C3.46,7.04,3.39,7.06,3.35,7.12
        C3.34,7.13,3.34,7.15,3.34,7.17v1.65c0,0.07,0.05,0.12,0.12,0.12c0.02,0,0.04-0.01,0.06-0.02L4.93,8.1z"
    />
  </svg>
);

export default RotorBrakeOn;
