import React, { useCallback } from 'react';
import { useTranslations } from 'use-intl';
import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  Box,
  Typography,
  Stack, Alert,
} from '@mui/material';
import timezones from 'constants/timezones';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import {
  selectLocaleSettings,
  updateLocaleSettingField,
  updateLocalLocaleField
} from 'slices/settings/localeSettings.slice';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import LanguageSelector from 'components/shared/languageSelector/languageSelector-view';

export const RegionalFirestoreSettingsDialog = () => {
  const t = useTranslations('statusbar.regional');
  const tSettings = useTranslations('pages.generalSettings');
  const { timezone } = useSelector(selectLocaleSettings);
  const dispatch = useAppDispatch();

  const isUsercodeUser = useIsUsercodeLogin();

  const handleTimezoneChange = useCallback((_, tz) => {
    if (isUsercodeUser) {
      dispatch(updateLocalLocaleField({ field: 'timezone', value: tz.id }));
    } else {
      dispatch(updateLocaleSettingField({ field: 'timezone', value: tz.id }));
    }
  }, [dispatch, isUsercodeUser]);

  return (
    <Stack spacing={3} sx={{ p: 3 }}>
      <Box>
        <Typography paragraph>{t('description')}</Typography>
      </Box>
      {isUsercodeUser && (<Alert severity="warning" variant="outlined" sx={{ alignItems: 'center' }}>{tSettings('warnings.usercodeBrowserStorageOnly')}</Alert>)}
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>{t('languageLabel')}</Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="right">
          <FormControl variant="standard">
            <LanguageSelector />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>{t('timezoneLabel')}</Typography>
        </Grid>
        <Grid item xs={8} container justifyContent="right">
          <Autocomplete
            sx={{ minWidth: '100%', pb: 1 }}
            id="dialog-select"
            options={timezones.map(({ id, label }) => ({ id, label }))}
            disableClearable
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
            value={timezones.find(tz => tz.id === timezone)}
            onChange={handleTimezoneChange}
            renderInput={params => <TextField variant="outlined" {...params} fullWidth />}
            placeholder={t('timezonePlaceholder')}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
