import { getRefreshedToken } from 'apis/auth';

const BASE_URL = `${import.meta.env.VITE_SERENITY_REST_URL}`;

export const getIncidents = async (): Promise<Incident[]> => {
  const token = await getRefreshedToken();
  if (!token) {
    throw new Error('Credentials are invalid');
  }
  const headers = {
    authorization: `Bearer ${token}`,
    'X-TPC-Version': import.meta.env.PACKAGE_VERSION,
  };
  const params = [
    ['includeOpen', 'true'],
  ];
  const response = await fetch(`${BASE_URL}/incidents?${new URLSearchParams(params)}`, { headers });
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response.json();
};
