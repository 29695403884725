import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslations } from 'use-intl';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { Localization, MaterialTableProps } from '@material-table/core';
import insensitiveSort from 'utils/insensitiveSort';
import { Typography, Stack, Box, Tooltip, Chip } from '@mui/material';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import LimitedList from 'components/shared/LimitedList';

import { Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { RowData } from './types';

export interface AssetGroupTableProps {
  data?: AssetGroup[];
  isLoading: boolean;
  readOnly: boolean;
  navigateToAssetGroup: (assetGroupId: number) => void;
  onRowDelete: (assetGroupId: number) => void;
}

export const AssetGroupTable = ({
  data,
  isLoading,
  navigateToAssetGroup,
  readOnly,
  onRowDelete
}: AssetGroupTableProps): JSX.Element => {
  const t = useTranslations('pages.manage.assetGroups.tableView');
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);
  const localization: Localization = { header: { actions: '' } };

  const rowData: RowData[] = useMemo(
    () => data?.map(assetGroup => ({
      id: assetGroup.id,
      name: assetGroup.name,
      assets: assetGroup.assets,
    })) ?? [],
    [data]
  );

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      customSort: (a, b) => insensitiveSort(a.name, b.name),
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{row.name}</Typography>
        </Stack>
      )
    },
    {
      title: t('columns.assets'),
      field: 'assets',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      defaultSort: 'asc',
      render: row => (
        <Stack direction="row" spacing={1} alignItems="center">
          <LimitedList<[string, string]>
            items={row.assets.map(asset => [asset?.name ?? '', String(asset.id)])}
            limit={3}
            renderMore={({ items, children }) => (
              <Tooltip
                title={items.map(([name, id]) => <div key={id}>{name}</div>)}
              >
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Stack>
      )
    }
  ]), [t]);

  const actions = useMemo<MaterialTableProps<RowData>['actions']>(() => {
    if (!readOnly) {
      return [
        row => ({
          icon: () => <Edit sx={{ color: 'common.text' }} />,
          tooltip: t('tooltips.edit'),
          onClick: () => navigateToRow(row.id),

        }),
        row => ({
          icon: () => <Delete sx={{ color: 'common.text' }} />,
          tooltip: t('tooltips.delete'),
          onClick: () => onRowDelete(row.id),
        })
      ];
    }

    return [
      row => ({
        icon: () => <InfoOutlined sx={{ color: 'common.text' }} />,
        tooltip: t('tooltips.view'),
        onClick: () => navigateToAssetGroup(row.id),
      })
    ];
  }, [readOnly, t, navigateToAssetGroup, onRowDelete]);

  return (
    <>
      <PersistentTable<RowData>
        settingsCategory="assetGroupTable"
        data={rowData}
        isLoading={isLoading}
        columns={columns}
        onRowClick={(_, row) => navigateToAssetGroup(row?.id ?? 0)}
        components={{
          Pagination,
          Container: Box,
          Toolbar: () => null
        }}
        options={{
          search: false,
          draggable: false,
          showTitle: false,
          actionsColumnIndex: -1,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
        }}
        actions={actions}
        localization={localization}
        sx={{
          'tbody tr:last-child td, tbody tr:last-child th': { border: 0 },
          'tbody tr': {height: 65}
        }}
      />
      <Box
        ref={footerRef}
        position="sticky"
        bottom={0}
        sx={{ '& .MuiToolbar-regular': { padding: 10 / 3 } }} />
    </>
  );
};
