import React from 'react';

const Oooion = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path className="icon-detail" fill="#fff" d="M11.2,7l-0.7-0.7l-2,2V4h-1v4.3l-2-2L4.8,7l2.85,2.85c0.2,0.2,0.5,0.2,0.7,0L11.2,7z" />
    <rect className="icon-detail" fill="#fff" x="4.5" y="11" width="7" height="1" />
  </svg>
);

export default Oooion;
