import React, { ReactNode } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import LinkTabs from 'components/shared/linkTabs';

interface RoutedTabsProps {
  label: string
  tabs: {
    value: string
    to: string
    label: ReactNode
    content: ReactNode
  }[]
}

const RoutedTabs = ({ label, tabs }: RoutedTabsProps): JSX.Element => {
  const location = useLocation();
  const pathMatch = useMatch(location.pathname);
  const tab = tabs.find(t => pathMatch?.pathname === t.to);

  return (
    <>
      <LinkTabs
        label={label}
        value={tab?.value ?? ''}
        items={tabs}
      />
      {tab?.content}
    </>
  );
};

export default RoutedTabs;
