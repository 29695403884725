import { createContext } from 'react';

export interface CursorPosition {
  latitude: number
  longitude: number
  x: number
  y: number
}

export interface CursorContext {
  cursorPosition?: CursorPosition
  setCursorPosition: (position: CursorPosition | undefined) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CursorContext = createContext<CursorContext>({ setCursorPosition: () => {} });
