import React from 'react';

const DHC6 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 29.325309,20.622456 c -0.171031,1.960795 -0.04407,5.5522 -0.04407,5.5522 l -2.842196,-0.02203 -0.04406,-3.348946 c 0,0 -0.06214,-2.498133 -1.674473,-2.489677 -1.612333,0.0085 -1.56431,2.59984 -1.56431,2.59984 l -0.08813,3.21675 c 0,0 -16.324853,-0.273079 -18.1107469,0.0661 -1.9678346,0.373732 -0.1762602,7.623259 -0.1762602,7.623259 L 29.325311,34.06231 c 0,0 0.514678,8.955889 1.696505,14.365215 0.04746,0.21724 -7.7659,0.184478 -8.218137,0.176258 -0.535688,-0.0097 -0.347801,5.947887 0.220326,6.080981 0.498052,0.116677 8.50456,0.04406 8.50456,0.04406 0,0 0.524567,3.415044 1.233819,3.503174 0.709252,0.08813 1.233819,-3.503174 1.233819,-3.503174 0,0 8.006508,0.07262 8.50456,-0.04406 0.568127,-0.133094 0.756014,-6.090681 0.220326,-6.080981 -0.452237,0.0082 -8.265597,0.04098 -8.218137,-0.176258 1.181827,-5.409326 1.696505,-14.365215 1.696505,-14.365215 l 24.544248,-0.242358 c 0,0 1.791575,-7.249527 -0.17626,-7.623259 -1.785894,-0.339179 -18.110747,-0.0661 -18.110747,-0.0661 l -0.08813,-3.21675 c 0,0 0.04802,-2.59134 -1.56431,-2.59984 -1.612333,-0.0085 -1.674473,2.489677 -1.674473,2.489677 l -0.04406,3.348946 -2.842196,0.02203 c 0,0 0.126961,-3.591405 -0.04407,-5.5522 -0.83361,-9.557003 -3.437075,-10.068869 -3.437075,-10.068869 0,0 -2.603465,0.511866 -3.437075,10.068869 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default DHC6;
