import React from 'react';

const Plus = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z" />
    <g>
      <rect className="icon-detail" fill="#fff" x="7.2" y="3.4" width="1.8" height="9.2" />
      <rect className="icon-detail" fill="#fff" x="3.4" y="7.1" width="9.2" height="1.8" />
    </g>
  </svg>
);

export default Plus;
