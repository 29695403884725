import React, { useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import LinkAbove from 'components/shared/linkAbove';
import LoadingPage from 'components/pages/loading';
import Page from 'components/pages/page';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { beaconQueryKeys } from 'apis/rest/beacon/queryKeys';
import { useGetAssignedSeats, useGetNumberOfSeats, useUpdateNumberOfSeats } from 'apis/rest/beacon/hooks';
import { useGetMemberships } from 'apis/rest/memberships/hook';
import { useStaff } from 'hooks/session/useStaff';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import { BeaconSeatsTable } from './BeaconSeatsTable';
import { SetNumberOfSeatsDialog } from './dialogs/SetNumberOfSeatsDialog';
import { selectNonUsercodeUsers } from './helpers';

export const BeaconView = () => {
  const t = useTranslations('pages.beacon');
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  const isStaff = useStaff();
  const usersQuery = useGetMemberships({
    select: selectNonUsercodeUsers,
  }).query;

  const numSeatsQuery = useGetNumberOfSeats();
  const assignedSeatsQuery = useGetAssignedSeats();

  const assignedUsers = useMemo(() => {
    if (!usersQuery.data || !assignedSeatsQuery.data) {
      return [];
    }

    const users = assignedSeatsQuery.data
      .map(seat => usersQuery.data.find(user => user.userId === seat.userId));

    if (users.some(u => u === undefined)) {
      console.warn('Assigned seats for users not in org');
      return users.filter(u => u !== undefined) as Membership[];
    }

    return users as Membership[];
  }, [usersQuery.data, assignedSeatsQuery.data]);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const updateNumberOfSeatsMutation = useUpdateNumberOfSeats({
    onSuccess: () => {
      setDialogOpen(false);
      queryClient.invalidateQueries(beaconQueryKeys.numSeats(organisationId));
    },
  });

  if (usersQuery.isLoading || numSeatsQuery.isLoading || assignedSeatsQuery.isLoading) {
    return <LoadingPage />;
  }

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="end" justifyContent="space-between" spacing={2}>
            <Box>
              <LinkAbove />
              <Typography variant="h1" gutterBottom>{t('title')}</Typography>
              <Typography paragraph>{t('description')}</Typography>
            </Box>
            {isStaff && (
              <Box pb={2}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setDialogOpen(true)}
                  sx={{ width: '16rem', height: '4rem' }}
                >
                  {t('setNumSeats')}
                </Button>
              </Box>
            )}
          </Stack>
          <Stack spacing={2}>
            <FeaturePageStaffAccessAlert feature="beacon.ios" />
            <BeaconSeatsTable
              users={usersQuery.data ?? []}
              assignedUsers={assignedUsers}
              numSeats={numSeatsQuery.data ?? 0}
            />
          </Stack>
          {isStaff && (
            <SetNumberOfSeatsDialog
              open={dialogOpen}
              actionsDisabled={updateNumberOfSeatsMutation.isPending}
              onClose={() => setDialogOpen(false)}
              onSave={(numSeats: number) => updateNumberOfSeatsMutation.mutate(numSeats)}
              currentNumber={numSeatsQuery.data ?? 0}
            />
          )}
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};
