import React from 'react';

const EC130 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 38.154297 2.5507812 C 38.146597 2.5489913 38.13708 2.559037 38.125 2.578125 C 38.040512 2.7116697 37.855594 3.3318802 37.679688 4.0898438 L 35.910156 11.716797 C 34.843908 11.399163 33.958984 11.455078 33.958984 11.455078 C 33.958984 11.455078 28.131619 11.047858 28.34375 17.712891 C 28.422821 20.197291 28.14031 22.457028 29.216797 27.351562 L 11.042969 32.884766 C 10.192262 33.143786 9.5225949 33.398486 9.5410156 33.458984 L 10.402344 36.291016 C 10.420764 36.351516 11.12195 36.192617 11.972656 35.933594 L 29.992188 30.445312 C 30.352515 31.750809 30.781871 33.188632 31.320312 34.826172 C 31.819273 36.343638 31.966797 48.035156 31.966797 48.035156 L 25.892578 48.035156 L 25.892578 51.908203 L 32.105469 51.880859 C 32.105469 51.880859 31.215163 59.415585 32.492188 61.470703 C 32.83993 62.030328 33.958984 61.785156 33.958984 61.785156 L 35.261719 61.591797 L 35.486328 51.921875 L 42.023438 51.908203 L 42.023438 48.035156 L 35.949219 48.035156 C 35.949219 48.035156 36.096742 36.343638 36.595703 34.826172 C 36.871368 33.987804 37.113146 33.214333 37.339844 32.466797 L 50.126953 44.417969 C 50.776625 45.02519 51.33377 45.475889 51.376953 45.429688 L 53.398438 43.267578 C 53.441617 43.221378 52.952406 42.697066 52.302734 42.089844 L 38.306641 29.007812 C 39.840767 22.933082 39.484784 20.461542 39.572266 17.712891 C 39.628062 15.959812 39.265059 14.697556 38.701172 13.787109 L 40.785156 4.8085938 C 40.986189 3.9423501 41.096759 3.2349996 41.035156 3.2207031 L 38.154297 2.5507812 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default EC130;
