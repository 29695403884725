import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useDistance from 'hooks/units/useDistance';
import { distance } from 'helpers/unitsOfMeasure';

interface DistanceInputProps extends Omit<TextFieldProps, 'type' | 'onChange' | 'onBlur'> {
  value: number;
  onChangeValue?: (val: number) => void;
}

export const DistanceInput: React.FC<DistanceInputProps> = ({ value, onChangeValue, ...rest }) => {
  const [previousValue, setPreviousValue] = useState<number | null>(null);
  const [pendingValue, setPendingValue] = useState<string>('');
  const distanceUnit = useDistance();

  useEffect(() => {
    const newValue = distance.fromSI(value, distanceUnit.unit);

    if (previousValue === null) {
      setPendingValue(newValue.toFixed(2));
      setPreviousValue(newValue);
      return;
    }

    if (pendingValue !== '' && Math.abs(previousValue - newValue) > 0.01) {
      setPendingValue(newValue.toFixed(2));
      setPreviousValue(newValue);
    }
  }, [distanceUnit.unit, pendingValue, previousValue, value]);

  const handleOnChange = useCallback((evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const potentialNumber = evt.target.value.trim();
    setPendingValue(potentialNumber);

    if (potentialNumber === '') {
      return;
    }

    const num = Number(potentialNumber);
    if (!Number.isNaN(num)) {
      onChangeValue?.(distance.toSI(num, distanceUnit.unit));
      setPreviousValue(num);
    }
  }, [distanceUnit.unit, onChangeValue]);

  const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const potentialNumber = evt.target.value.trim();
    if (potentialNumber === '') {
      setPendingValue(distance.fromSI(value, distanceUnit.unit).toFixed(2).toString());
      return;
    }

    const num = Number(potentialNumber);
    if (!Number.isNaN(num)) {
      setPendingValue(num.toFixed(2));
    }
  };

  return (
    <TextField
      {...rest}
      value={pendingValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">{distanceUnit.unitLabel}</InputAdornment>,
      }}
      sx={{
        flex: 1,
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' }
      }}
    />
  );
};
