import { useCallback } from 'react';
import type { Feature } from 'geojson';
import { useMap } from 'react-map-gl';
import { safeBounds } from 'helpers/geo';

export const useFitMapToFeatures = () => {
  const { geofencesMap: mapRef } = useMap();

  return useCallback((features: Feature[]) => {
    const map = mapRef?.getMap();
    if (!map) return;
    if (!features.length) return;

    const bounds = features.length > 0 ? safeBounds({ type: 'FeatureCollection', features }) : undefined;
    if (!bounds || Number.isNaN(bounds[0][0])) return;

    try {
      map.fitBounds(bounds, { padding: 20 });
    } catch (error) {
      // do nothing
    }
  }, [mapRef]);
};
