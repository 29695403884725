import { useState, useEffect } from 'react';
import { distance, speed as uomSpeed } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';

// This hook takes a position and returns the altitude and speed as a string
// formatted using the units.altitude and units.speed settings from the Settings
// reducer. Updates if the position, units.altitude or units.speed settings change.

const formatAltitudeAtSpeed = (position, altitudeUnits, speedUnits) => {
  if (!position) return '--';

  const {
    altitude = 0,
    speed = 0
  } = position;

  const formattedAltitude = distance.fromSI(altitude, altitudeUnits);
  const altitudeLabel = distance.label(altitudeUnits);

  const speedInLocale = uomSpeed.fromKmh(speed, speedUnits);
  const speedUnitsLabel = uomSpeed.label(speedUnits);

  return `${formattedAltitude.toFixed(0)} ${altitudeLabel} @ ${speedInLocale.toFixed(0)} ${speedUnitsLabel}`;
};

export default (position, speedUnit) => {
  const altitudeUnit = useUnitSettings().altitude;

  const [formattedString, setFormattedString] = useState(formatAltitudeAtSpeed(position, altitudeUnit, speedUnit));

  useEffect(() => {
    setFormattedString(formatAltitudeAtSpeed(position, altitudeUnit, speedUnit));
  }, [position, altitudeUnit, speedUnit]);

  return formattedString;
};
