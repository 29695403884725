import { useMemo } from 'react';
import bearing from '@turf/bearing';
import { Segment } from 'repositories/reports/reports';
import { ContainmentLinesOption } from 'slices/map.slice';
import { Coord, Polyline } from 'repositories/reports/spline';
import { IconLayer, PathLayer } from '@deck.gl/layers';
import { useAssetSegments } from 'repositories/reports/hooks';
import { hexToRGBArray } from 'helpers/color';

const WHITE = hexToRGBArray('#ffffff');
const LINE_COLOUR = hexToRGBArray('#03a9f4');

interface PathFeature {
  path: Polyline
}

const triplicate = <T extends PathFeature,>(trails: T[]): T[] => trails.flatMap(feature => [
  {
    ...feature,
    path: feature.path.map(([lon, lat]) => ([lon - 360, lat]))
  },
  feature,
  {
    ...feature,
    path: feature.path.map(([lon, lat]) => ([lon + 360, lat]))
  }
]);

const startIconCreator = (strokeColor: string) => `<?xml version="1.0" encoding="UTF-8"?><svg width="64" height="64" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path transform="matrix(5.6368 0 0 5.6368 17.613 8.3791)" d="m6.2059 4.1905-7.307 4.2187v-8.4374z" fill="#FFF" stroke="${strokeColor}" stroke-linejoin="round" stroke-width="3" style="paint-order:stroke markers fill"/></svg>`;
const stopIconCreator = (strokeColor: string) => `<?xml version="1.0" encoding="UTF-8"?><svg width="64" height="64" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><rect x="25.993" y="7.0524" width="12.013" height="49.895" fill="#FFF" stroke="${strokeColor}" stroke-linejoin="round" stroke-width="15" style="paint-order:stroke markers fill"/></svg>`;

const startIcon = btoa(startIconCreator('#00000000'));
const stopIcon = btoa(stopIconCreator('#00000000'));
const startOutlineIcon = btoa(startIconCreator('#fff'));
const stopOutlineIcon = btoa(stopIconCreator('#fff'));

const ICONSIZE = 20;

const iconBase = {
  width: ICONSIZE,
  height: ICONSIZE,
  anchorX: ICONSIZE / 2,
  anchorY: ICONSIZE / 2,
  mask: true
};

interface ValidContainmentLine extends Segment<object> {
  splines: [Polyline, ...Polyline[]]
}

const isValidLine = (line: Segment<object>): line is ValidContainmentLine => (line.splines ? line.splines.length > 0 : false);

const lineToIcon = (line: Segment<object>, use3d: boolean) => {
  if (!isValidLine(line)) return [];

  const firstSpline = line.splines[0];
  const lastSpline = line.splines.at(-1);
  if (!lastSpline || lastSpline.length < 2) return [];

  const endPosition = firstSpline[0];
  const startPosition = lastSpline.at(-1) as Coord;

  return [
    {
      icon: {
        url: `data:image/svg+xml;base64,${stopIcon}`,
        ...iconBase
      },
      outlineIcon: {
        url: `data:image/svg+xml;base64,${stopOutlineIcon}`,
        ...iconBase
      },
      position: use3d ? endPosition : [endPosition[0], endPosition[1], 0],
      angle: 90 - bearing(firstSpline[1], firstSpline[0]),
    },
    {
      icon: {
        url: `data:image/svg+xml;base64,${startIcon}`,
        ...iconBase
      },
      outlineIcon: {
        url: `data:image/svg+xml;base64,${startOutlineIcon}`,
        ...iconBase
      },
      position: use3d ? startPosition : [startPosition[0], startPosition[1], 0],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      angle: 90 - bearing(lastSpline.at(-1)!, lastSpline.at(-2)!),
    },
  ];
};

const useContainmentLineLayers = (
  visible: boolean,
  linesOption: ContainmentLinesOption,
  assets: AssetBasic[],
  selectedAssetId: number | undefined,
  selectedLeg: Leg | null,
  trailWidth: number,
  use3d = false,
) => {
  const assetsWithTrails = useMemo(() => {
    switch (linesOption) {
      case 'none':
        return [];
      case 'selectedAsset':
        return assets.filter(asset => asset.id === selectedAssetId);
      case 'allAssets':
      default:
        return assets;
    }
  }, [assets, selectedAssetId, linesOption]);

  const leg = visible && linesOption !== 'none' ? selectedLeg : undefined;
  const assetContainmentLines = useAssetSegments(assetsWithTrails, 'CONTAINMENT_LINE', leg);

  const data = useMemo(() => (
    Object.values(assetContainmentLines)
      .flat()
      .filter(isValidLine)
  ), [assetContainmentLines]);

  const icons = useMemo(() => (
    data.flatMap(line => lineToIcon(line, use3d))
  ), [data, use3d]);

  const trails = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    triplicate(data.flatMap(line => line.splines!.map(path => ({
      path: use3d ? path : path.map(c => [c[0], c[1], 0]),
    }))))
  ), [data, use3d]);

  return useMemo(() => [
    new IconLayer({
      id: `containment-icon-outline-layer-${use3d ? '3d' : '2d'}`,
      data: icons,
      getIcon: d => d.outlineIcon,
      getAngle: d => d.angle,
      pickable: false,
      getColor: WHITE,
      opacity: 1,
      getSize: ICONSIZE,
      billboard: false,
      parameters: { depthTest: false },
      visible,
    }),
    new PathLayer({
      id: `containment-line-outline-layer-${use3d ? '3d' : '2d'}`,
      data: trails,
      pickable: false,
      getColor: WHITE,
      getWidth: trailWidth + 6,
      opacity: 1,
      widthUnits: 'pixels',
      widthScale: 1,
      jointRounded: true,
      capRounded: true,
      parameters: { depthTest: false },
      visible,
    }),
    new PathLayer({
      id: `containment-line-layer-${use3d ? '3d' : '2d'}`,
      data: trails,
      pickable: false,
      getColor: LINE_COLOUR,
      getWidth: trailWidth + 2,
      opacity: 1,
      widthUnits: 'pixels',
      widthScale: 1,
      jointRounded: true,
      capRounded: true,
      parameters: { depthTest: false },
      visible,
    }),
    new IconLayer({
      id: `containment-icon-layer-${use3d ? '3d' : '2d'}`,
      data: icons,
      getAngle: d => d.angle,
      pickable: false,
      getColor: LINE_COLOUR,
      opacity: 1,
      getSize: ICONSIZE,
      billboard: false,
      parameters: { depthTest: false },
      visible,
    }),
  ] as const, [use3d, visible, icons, trails, trailWidth]);
};

export default useContainmentLineLayers;
