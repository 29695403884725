import {
  Table, TableHead, TableRow, TableCell, TableBody, Stack,
  Paper, Typography, TableFooter, Collapse, Box, Tooltip,
} from '@mui/material';
import { HelpOutline, PlayArrow, Stop } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { formatDateTime } from 'utils/time';
import useTimezone from 'hooks/session/useTimezone';
import useDistance from 'hooks/units/useDistance';
import { alpha } from '@mui/material/styles';
import { EngineUsage } from 'apis/rest/engineUsage/types';
import useDuration from 'hooks/units/useDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useGetInferredEventsByReportIdForAsset } from 'apis/rest/inferredEvents/hooks';
import { InferredEventId } from 'apis/rest/inferredEvents/types';
import { TripDetail } from '../../tripAnalysis/tripDetails/tripDetail';
import { Action, TripViewState } from '../../tripAnalysis/types';
import { ApprovalIcon } from '../approvalIcon';

interface FlightRowProps {
  trip: Trip;
  inferredEvents: Record<number, InferredEventId[]> | undefined;
  isSelected: boolean;
  dispatch: React.Dispatch<Action>;
  timezone: string;
  dummyCell: boolean;
  isLast: boolean;
}

const FlightRow = ({ trip, inferredEvents, isSelected, dispatch, timezone, dummyCell, isLast }: FlightRowProps) => {
  const distance = useDistance();
  const duration = useDuration();
  const borderWidth = isLast ? 4 : undefined;

  return (
    <>
      <TableRow
        onClick={() => dispatch({ type: 'SET_SELECTED_TRIP', tripId: isSelected ? undefined : trip.id })}
        sx={theme => ({
          tableLayout: 'fixed',
          cursor: 'pointer',
          bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.05) : undefined,
          ':hover': { bgcolor: alpha(theme.palette.primary.main, 0.05) },
          '& > .MuiTableCell-root': { border: 0 },
        })}>
        {dummyCell && <TableCell sx={{ p: 0 }} />}
        <TableCell sx={{ wordWrap: 'break-word' }}>
          <Stack>
            <p>{trip.start ?? '--'}</p>
            <p>{formatDateTime(trip.startTime, timezone)}</p>
          </Stack>
        </TableCell>
        <TableCell>
          {trip.endTime && (
            <Stack>
              <p>{trip.end ?? '--'}</p>
              <p>{formatDateTime(trip.endTime, timezone)}</p>
            </Stack>
          )}
        </TableCell>
        <TableCell><Typography whiteSpace="nowrap">{distance.create((trip.distance ?? 0) * 1000).format()}</Typography></TableCell>
        <TableCell><Typography whiteSpace="nowrap">{duration.fromMillis(trip.duration)}</Typography></TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          <ApprovalIcon trips={[trip]} />
        </TableCell>
      </TableRow>
      <TableRow sx={{
        '& > .MuiTableCell-root': { p: '0 !important' },
      }}>
        {dummyCell && <TableCell sx={{ p: 0, borderWidth }} />}
        <TableCell colSpan={100} sx={{ borderWidth }}>
          <Collapse in={isSelected} unmountOnExit>
            <TripDetail
              trip={trip}
              inferredEvents={inferredEvents}
              dispatch={dispatch}
              hideHeader
              hideMap
              timezone={timezone}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

interface FlightTableProps {
  asset: AssetWithDevice,
  tripState: TripViewState,
  trips: Trip[],
  dispatch: React.Dispatch<Action>
  displayEngineUsage: boolean
  engineUsage: EngineUsage[] | undefined
  day: DateTime
}

interface FlightGroup {
  cycle: EngineUsage | undefined
  trips: Trip[]
}

export const FlightTable = ({ asset, tripState, trips, dispatch, displayEngineUsage, engineUsage, day }: FlightTableProps) => {
  const timezone = useTimezone();
  const duration = useDuration();

  const sortedTrips = useMemo(() => sortBy(trips, t => t.startTime), [trips]);

  const groups = useMemo(() => {
    if (!displayEngineUsage) return [{ cycle: undefined, trips: sortedTrips }];

    const cycleForTrip = sortedTrips.reduce<Map<Trip, EngineUsage>>((map, trip) => {
      const cycle = sortBy(engineUsage, t => -t.startTime).find(item => item.startTime <= trip.startTime);
      if (cycle) map.set(trip, cycle);
      return map;
    }, new Map<Trip, EngineUsage>());

    const result = sortedTrips.reduce<FlightGroup[]>((acc, trip) => {
      const cycle = cycleForTrip.get(trip);
      const group = acc.at(-1);

      if (group && group.cycle === cycle) {
        group.trips.push(trip);
      } else {
        acc.push({ cycle, trips: [trip] });
      }

      return acc;
    }, []);

    const cyclesWithTrips = new Set([...cycleForTrip.values()]);
    const cyclesWithoutTrips = engineUsage?.filter(cycle => !cyclesWithTrips.has(cycle));
    if (cyclesWithoutTrips) result.push(...cyclesWithoutTrips.map(cycle => ({ cycle, trips: [] })));

    return sortBy(result, r => r.cycle?.startTime ?? r.trips[0].startTime);
  }, [displayEngineUsage, sortedTrips, engineUsage]);

  const hasEngineCycles = groups.some(g => g.cycle);

  const inferredEventsEnabled = useFeatureFlag('frontendInferredEventsTripAnalysis');
  const inferredEventsQuery = useGetInferredEventsByReportIdForAsset(
    asset.id,
    day.startOf('day'),
    day.endOf('day'),
    { enabled: inferredEventsEnabled === true },
  );

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {hasEngineCycles && <TableCell sx={{ p: 0 }} />}
            <TableCell width="40%">From</TableCell>
            <TableCell width="40%">To</TableCell>
            <TableCell width="8%">Distance</TableCell>
            <TableCell width="12%">Duration</TableCell>
            <TableCell width="12%">
              <Tooltip title={(
                <>
                  <p>Coming soon</p>
                  <p>An approval workflow to amend / finalise trip details</p>
                </>
              )}>
                <Stack direction="row" spacing={0.5} justifyContent="center" color="common.disabled">
                  <span>Approval</span>
                  <HelpOutline />
                </Stack>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        {groups.map((group, index) => (
          <TableBody key={`group-${index}`}>
            {group.cycle && (
              <TableRow>
                <TableCell rowSpan={1000} sx={{ height: '1px', borderWidth: 4, px: 2 }}>
                  <Stack height="100%" alignItems="center" spacing={2} py={1.5}>
                    <PlayArrow />
                    <Box sx={{ flex: 1, borderLeft: '2px dashed #999' }} />
                    <Stop />
                  </Stack>
                </TableCell>
                <TableCell colSpan={100} sx={{ borderWidth: group.trips.length ? undefined : 0, pb: group.trips.length ? undefined : 0 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Engine start<br />{formatDateTime(group.cycle.startTime, timezone, +day.startOf('day') > group.cycle.startTime)}</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {group.trips.map(t => {
              const isSelected = tripState.selectedTrip?.id === t.id;
              return (
                <FlightRow
                  key={t.id}
                  trip={t}
                  inferredEvents={inferredEventsQuery.data}
                  isSelected={isSelected}
                  dispatch={dispatch}
                  timezone={timezone}
                  dummyCell={hasEngineCycles && !group.cycle}
                  isLast={hasEngineCycles && !group.cycle && group.trips.at(-1) === t}
                />
              );
            })}
            {group.cycle && (
              <TableRow>
                <TableCell colSpan={100} sx={{ borderWidth: 4 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>Engine stop<br />{formatDateTime(group.cycle.endTime, timezone, +day.endOf('day') < (group.cycle.endTime ?? Infinity))}</Typography>
                    </Stack>
                    <Typography textAlign="right">Engine duration<br />{duration.fromMillis(group.cycle.duration)}</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        ))}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={hasEngineCycles ? 5 : 4} sx={{ textAlign: 'right' }} />
            <TableCell sx={{ textAlign: 'center' }}><ApprovalIcon trips={trips} /></TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};
