import { useCallback, useContext, useMemo } from 'react';
import { Viewport, ViewportContext } from './viewportContext';
import * as persistence from './persistence';
import { SetViewportContext } from './setViewportContext';

const defaultViewport: Viewport = {
  bearing: 0,
  height: 0,
  longitude: 0,
  latitude: 0,
  zoom: 0,
  pitch: 0,
  width: 0
};

export const useViewport = (mapId: string) => {
  const viewports = useContext(ViewportContext);
  return useMemo(() => viewports[mapId] ?? persistence.get().default ?? defaultViewport, [mapId, viewports]);
};

export const useSetViewport = (mapId: string) => {
  const setViewport = useContext(SetViewportContext);
  return useCallback((viewport: undefined | Partial<Viewport> | ((previous: Viewport | undefined) => Viewport)) => setViewport(mapId, viewport), [mapId, setViewport]);
};
