import React from 'react';
import { useTranslations } from 'use-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeInferredEventsHelpDialog,
  selectInferredEventsHelpOpen
} from 'slices/map/inferredEvents.slice';

export const InferredEventHelpDialog = () => {
  // TODO: allow customers to mark an inferred event as a false positive
  const t = useTranslations('dialogs.inferredEvents.help');
  const dispatch = useDispatch();

  const open = useSelector(selectInferredEventsHelpOpen);
  const close = () => dispatch(closeInferredEventsHelpDialog());

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="xs"
    >
      <DialogTitle sx={{ p: 3, pb: 0, mb: 1 }}>{t('title')}</DialogTitle>

      <DialogContent sx={{ px: 3 }}>
        <Typography>{t('description')}</Typography>
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 0, justifyContent: 'stretch' }}>
        <Stack spacing={3} direction="row" height="4em" flex={1}>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={close}
          >
            {t('continue')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
