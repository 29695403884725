import { useGetFeatureFlag } from 'apis/rest/featureFlags/hooks';

const getFeatureFlagOverride = (feature: string | undefined): boolean | undefined => {
  try {
    const override = sessionStorage.getItem(`featureFlag.${feature}`);
    if (override) return !!JSON.parse(override);
  } catch (error) {
    console.error(`Feature flag override value could not be parsed for ${feature}`, error);
  }
  return undefined;
};

const useFeatureFlag = (feature: string | undefined): boolean | undefined => {
  const { query } = useGetFeatureFlag(feature ?? '', !!feature);
  if (query.isError) throw query.error;
  if (query.isLoading) return undefined;

  return getFeatureFlagOverride(feature) ?? query.data ?? false;
};

export default useFeatureFlag;
