import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Stack
} from '@mui/material';
import ColorInput from 'components/shared/forms/inputs/color';
import AssetIcon from 'components/shared/icons/assetIcons';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';

const AssetTrailColour = ({
  classes,
  t,
  localAsset,
  saveAssetTrailColour,
  readOnly,
}) => {
  const [color, setColor] = React.useState(localAsset.colour);
  const noChange = localAsset.colour === color;

  const defaultColor = SUGGESTED_ASSET_COLORS[localAsset.id % 16];

  return (
    <SettingsSection title={t('displayTitle')} description={t('displayDescription')}>
      <DetailPanel p={3}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="h3">Trail color</Typography>
            <Typography>{t('trailColorDescription')}</Typography>
            <Typography>{t('trailColorNote')}</Typography>
            <Stack spacing={3}>
              <ColorInput
                palette={SUGGESTED_ASSET_COLORS}
                value={color ?? defaultColor}
                onChange={setColor}
                readOnly={readOnly}
                preview={(
                  <>
                    <AssetIcon type={localAsset.assetIconId} fillColor={color ?? defaultColor} opacity={1} />
                    {!localAsset.colour && noChange && (
                      <Typography sx={theme => ({ color: theme.palette.common.whiteConstant })}>{t('noColor')}</Typography>
                    )}
                  </>
                )}
              />
              {!readOnly && (
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button
                    variant="contained"
                    className={classes.inputButton}
                    onClick={() => setColor(localAsset.colour)}
                    disabled={noChange}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.inputButton}
                    onClick={() => saveAssetTrailColour(color)}
                    disabled={noChange}
                  >
                    {t('save')}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DetailPanel>
    </SettingsSection>
  );
};

export default AssetTrailColour;
