/* eslint-disable func-names,class-methods-use-this,@typescript-eslint/no-non-null-assertion,prefer-destructuring */
import {
  DrawCustomMode,
  DrawCustomModeThis,
  DrawFeature,
  MapMouseEvent,
  MapTouchEvent,
  constants,
  lib,
} from '@mapbox/mapbox-gl-draw';
import { Feature, GeoJSON } from 'geojson';
import { LngLat } from 'mapbox-gl';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import distance from '@turf/distance';
import { SearchPatternType } from 'helpers/searchPatterns';
import { normaliseBearing } from 'helpers/geo';

export enum Mode {
  Select,
  Rotate,
  Scale,
}

export enum Handles {
  None,
  All,
  Opposite,
  Custom,
}

interface LngLatLike {
  lng: number;
  lat: number;
}

export const Events = {
  MOVE: 'sp.move',
  SCALE: 'sp.scale',
  ROTATE: 'sp.rotate',
  SELECT: 'sp.select',
};

export interface MoveEvent {
  searchPatternId: string | undefined,
  delta: LngLatLike,
}

export interface RotateEvent {
  searchPatternId: string | undefined,
  newOrientation: number,
}

export interface ScaleEvent {
  searchPatternId: string | undefined,
  axis: 'x' | 'y',
  deltaMetres: number,
}

export interface SelectEvent {
  searchPatternId: string | undefined,
}

export interface SearchPatternsDrawModeOptions {
  featureId: string | undefined;
  isLocked: boolean;
  canInteract: boolean;
}

interface SearchPatternsDrawModeState {
  featureId: string | undefined;
  feature: DrawFeature | null;
  origin: LngLatLike | null;
  orientation: number | null;
  isLocked: boolean;

  mode: Mode;
  handles: Handles;

  canInteract: boolean;
  dragMoving: boolean;
  canDragMove: boolean;
  dragMoveLocation: LngLat | null;
  dragFeature: DrawFeature | null;
}

const DEFAULT_STATE: SearchPatternsDrawModeState = {
  featureId: undefined,
  feature: null,
  origin: null,
  orientation: null,
  isLocked: true,

  mode: Mode.Select,
  handles: Handles.None,

  canInteract: false,
  dragMoving: false,
  canDragMove: false,
  dragMoveLocation: null,
  dragFeature: null,
};

// @ts-ignore
const SearchPatternsDrawMode: DrawCustomMode<SearchPatternsDrawModeState, SearchPatternsDrawModeOptions> = {};

const getHandleMode = (type: SearchPatternType) => {
  switch (type) {
    case 'parallelTrack':
    case 'creepingLine':
      return Handles.Opposite;
    case 'expandingBox':
      return Handles.All;
    case 'sectorSearch':
    case 'rangeBearingLine':
      return Handles.Custom;
    default:
      throw new Error('unsupported search pattern');
  }
};
const createHandles = (geojson: Feature) => {
  if (!geojson.properties) throw new Error('invalid geojson');

  const firstAxis: 'x' | 'y' = geojson.properties.user_firstAxis;
  const secondAxis = firstAxis === 'x' ? 'y' : 'x';

  const suppPoints = lib.createSupplementaryPoints(geojson, { midpoints: true });
  suppPoints[1].properties!.axis = firstAxis;
  suppPoints[3].properties!.axis = secondAxis;
  suppPoints[5].properties!.axis = firstAxis;
  suppPoints[7].properties!.axis = secondAxis;

  const rotationOffset: number = geojson.properties.user_rotationOffset;
  const offset = firstAxis === 'x' ? rotationOffset : rotationOffset - 90;
  suppPoints[1].properties!.orientation = normaliseBearing(-90 + offset);
  suppPoints[3].properties!.orientation = normaliseBearing(offset);
  suppPoints[5].properties!.orientation = normaliseBearing(90 + offset);
  suppPoints[7].properties!.orientation = normaliseBearing(180 + offset);

  const handleBearings: number[][] = geojson.properties.user_handleBearings;
  suppPoints[0].properties!.rotationOffset = handleBearings[0];
  suppPoints[2].properties!.rotationOffset = handleBearings[1];
  suppPoints[4].properties!.rotationOffset = handleBearings[2];
  suppPoints[6].properties!.rotationOffset = handleBearings[3];

  return suppPoints;
};

const createCustomHandles = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, geojson: Feature): Feature[] => {
  if (!geojson.properties) throw new Error('invalid geojson');

  const type: SearchPatternType | undefined = geojson.properties.user_searchPatternType;
  if (!type) throw new Error('search pattern type was not provided');

  switch (type) {
    case 'sectorSearch': {
      // const originFeature = self.getFeature(`${geojson.properties.id}_origin`);
      // const orientation: number | undefined = geojson.properties.user_orientation;
      // const radius: number | undefined = geojson.properties.user_radius;
      //
      // if (orientation === undefined || radius === undefined || originFeature.type !== 'Point') {
      //   throw new Error('invalid state for sector search pattern');
      // }
      //
      // const point = destination(originFeature.getCoordinates(), radius, orientation, {
      //   units: 'meters',
      //   properties: {
      //     active: 'false',
      //     coord_path: '0.0',
      //     meta: 'vertex',
      //     parent: geojson.properties.id,
      //     rotationOffset: 0,
      //   },
      // });
      //
      // return [point];
      console.error('not implemented yet');
      return [];
    }
    case 'rangeBearingLine':
      console.error('not implemented yet');
      return [];
    default:
      throw new Error(`unsupported search pattern ${type}`);
  }
};

const fireUpdate = (self: DrawCustomModeThis) => {
  self.map.fire(constants.events.UPDATE, {
    action: constants.updateActions.CHANGE_COORDINATES,
    features: self.getSelected().map(f => f.toGeoJSON())
  });
};

const startDragging = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, location: LngLat, feature: DrawFeature) => {
  if (state.isLocked || !state.canInteract) return;
  self.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = location;
  state.dragFeature = feature;
};
const stopDragging = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState) => {
  if (state.isLocked || !state.canInteract) return;
  if (state.dragMoving) {
    fireUpdate(self);
  }

  self.map.dragPan.enable();
  state.dragMoving = false;
  state.canDragMove = false;
  state.dragMoveLocation = null;
  state.dragFeature = null;
  state.mode = Mode.Select;
};

const onMidpoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  if (!e.featureTarget || !state.feature) return;
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.Scale;
};
const onVertex = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  if (!e.featureTarget) return;
  startDragging(self, state, e.lngLat, e.featureTarget);
  state.mode = Mode.Rotate;
};
const onFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent | MapTouchEvent) => {
  startDragging(self, state, e.lngLat, e.featureTarget);
};

const dragRotatePoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  let newOrientation = 0;
  if (state.origin !== null && state.dragFeature !== null) {
    const s = [state.origin.lng, state.origin.lat];
    const mousePosition = [e.lngLat.lng, e.lngLat.lat];
    const mouseBearing = bearing(s, mousePosition);

    const rotationOffset = state.dragFeature.properties?.rotationOffset ?? 0;

    newOrientation = mouseBearing - rotationOffset;
  }

  self.map.fire(Events.ROTATE, {
    searchPatternId: state.featureId,
    newOrientation,
  } satisfies RotateEvent);
};
const dragScalePoint = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.dragFeature?.properties || !('axis' in state.dragFeature.properties) || !('orientation' in state.dragFeature.properties) || !state.dragMoveLocation) throw new Error('invalid state');

  const { axis, orientation } = state.dragFeature.properties;

  const p1 = [state.dragMoveLocation.lng, state.dragMoveLocation.lat];
  const p2 = [e.lngLat.lng, e.lngLat.lat];
  const b = bearing(p1, p2) + orientation - (state.orientation ?? 0);
  const t = Math.cos(b * (Math.PI / 180));
  const dist = distance(p1, p2, { units: 'meters' });
  const deltaMetres = dist * t;

  self.map.fire(Events.SCALE, {
    searchPatternId: state.featureId,
    axis,
    deltaMetres,
  } satisfies ScaleEvent);
};
const dragFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  const delta = {
    lng: state.dragMoveLocation ? e.lngLat.lng - state.dragMoveLocation.lng : 0,
    lat: state.dragMoveLocation ? e.lngLat.lat - state.dragMoveLocation.lat : 0,
  };

  self.map.fire(Events.MOVE, {
    searchPatternId: state.featureId,
    delta,
  } satisfies MoveEvent);
};

const clickNoTarget = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  self.map.fire(Events.SELECT, {
    searchPatternId: undefined,
  } satisfies SelectEvent);
};
const clickActiveFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  self.map.fire(Events.SELECT, {
    searchPatternId: undefined,
  } satisfies SelectEvent);
};
const clickInactiveFeature = (self: DrawCustomModeThis, state: SearchPatternsDrawModeState, e: MapMouseEvent) => {
  if (!state.canInteract) return;
  const props = e.featureTarget?.properties;
  if (!props) return;

  self.map.fire(Events.SELECT, {
    searchPatternId: props.user_searchPatternId,
  } satisfies SelectEvent);
};

SearchPatternsDrawMode.onSetup = function (options: SearchPatternsDrawModeOptions): SearchPatternsDrawModeState {
  if (!options.featureId) {
    this.setSelected(undefined);
    return { ...DEFAULT_STATE, ...options };
  }

  const featureId = options.featureId.toString();
  const feature = this.getFeature(featureId);
  const origin = this.getFeature(`${featureId}_origin`).getCoordinates();
  const type = feature.properties?.searchPatternType;

  const state = {
    ...DEFAULT_STATE,
    ...options,
    featureId,
    feature,
    origin: {
      lng: origin[0] as number,
      lat: origin[1] as number,
    },
    orientation: feature.properties?.orientation ?? 0,
    handles: type ? getHandleMode(type) : Handles.None,
  };

  this.setSelected(featureId);
  this.map.doubleClickZoom.disable();

  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: false,
  });

  return state;
};

SearchPatternsDrawMode.toDisplayFeatures = function (state: SearchPatternsDrawModeState, geojson: GeoJSON, display: (geojson: GeoJSON) => void): void {
  if (!('properties' in geojson) || !geojson.properties) return;

  const isHidden: boolean | undefined = geojson.properties.user_isHidden;
  if (isHidden) return;

  if (state.featureId && state.featureId === geojson.properties.id) {
    geojson.properties.active = constants.activeStates.ACTIVE;
    display(geojson);

    if (state.isLocked) return;

    state.orientation = geojson.properties.user_orientation ?? state.orientation;

    switch (state.handles) {
      case Handles.All:
        createHandles(geojson).forEach(display);
        break;
      case Handles.Opposite:
        createHandles(geojson).slice(3, 6).forEach(display);
        break;
      case Handles.Custom:
        createCustomHandles(this, state, geojson).forEach(display);
        break;
      case Handles.None:
      default:
        break;
    }
  } else if (state.featureId === geojson.properties.user_searchPatternId) {
    geojson.properties.active = constants.activeStates.ACTIVE;
    display(geojson);
  } else {
    geojson.properties.active = constants.activeStates.INACTIVE;
  }

  this.setActionableState({
    combineFeatures: false,
    uncombineFeatures: false,
    trash: false,
  });
};

SearchPatternsDrawMode.onDrag = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  if (!state.canDragMove) return;
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  switch (state.mode) {
    case Mode.Rotate:
      dragRotatePoint(this, state, e);
      break;
    case Mode.Scale:
      dragScalePoint(this, state, e);
      break;
    case Mode.Select:
    default:
      dragFeature(this, state, e);
      break;
  }

  state.dragMoveLocation = e.lngLat;
};

SearchPatternsDrawMode.onClick = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  if (lib.CommonSelectors.noTarget(e)) clickNoTarget(this, state, e);
  if (lib.CommonSelectors.isActiveFeature(e)) clickActiveFeature(this, state, e);
  if (lib.CommonSelectors.isInactiveFeature(e)) clickInactiveFeature(this, state, e);
  stopDragging(this, state);
};

SearchPatternsDrawMode.onMouseOut = function (state: SearchPatternsDrawModeState): void {
  if (state.dragMoving) {
    fireUpdate(this);
  }
};

SearchPatternsDrawMode.onMouseDown = function (state: SearchPatternsDrawModeState, e: MapMouseEvent): void {
  if (lib.CommonSelectors.isOfMetaType(constants.meta.VERTEX)(e)) onVertex(this, state, e);
  if (lib.CommonSelectors.isOfMetaType(constants.meta.MIDPOINT)(e)) onMidpoint(this, state, e);
  if (lib.CommonSelectors.isActiveFeature(e)) onFeature(this, state, e);
};
SearchPatternsDrawMode.onTouchStart = function (state: SearchPatternsDrawModeState, e: MapTouchEvent): void {
  if (lib.CommonSelectors.isOfMetaType(constants.meta.VERTEX)(e)) onVertex(this, state, e);
  if (lib.CommonSelectors.isOfMetaType(constants.meta.MIDPOINT)(e)) onMidpoint(this, state, e);
  if (lib.CommonSelectors.isActiveFeature(e)) onFeature(this, state, e);
};

SearchPatternsDrawMode.onMouseUp = function (state: SearchPatternsDrawModeState): void {
  stopDragging(this, state);
};
SearchPatternsDrawMode.onTouchEnd = function (state: SearchPatternsDrawModeState): void {
  stopDragging(this, state);
};

SearchPatternsDrawMode.onStop = function (): void {
  this.map.doubleClickZoom.enable();
  this.clearSelectedCoordinates();
};

export default SearchPatternsDrawMode;
