import {
  IconButton,
  TableRow,
  Stack,
  Typography,
  TableCell
} from '@mui/material';
import React from 'react';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import { ChevronRight } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useGetTripsForAssets } from 'apis/rest/trips/hooks';
import { sumBy } from 'lodash';
import { alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import { LoadingIcon } from '../../loading/loadingIcon';
import { ApprovalIcon } from './approvalIcon';

export const AssetFlightSummary = ({ asset, day }: { asset: AssetWithDevice, day: DateTime }) => {
  const tripsQuery = useGetTripsForAssets([asset.id], day.startOf('day').toMillis(), day.endOf('day').toMillis());
  const trips = tripsQuery.data ?? [];

  const theme = useTheme();
  const selectedBgColor = alpha(theme.palette.primary.main, 0.05);
  const navigate = useNavigate();

  const totalDistance = sumBy(trips, t => t.distance ?? 0);
  const totalDuration = sumBy(trips, t => t.duration ?? 0);

  const distance = useDistance();
  const duration = useDuration();

  return (
    <TableRow
      onClick={() => {
        navigate(`${asset.id}/${day.toFormat('yyyy-MM-dd')}`);
      }}
      sx={{ cursor: 'pointer', '&:hover': { backgroundColor: selectedBgColor } }}
    >
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AssetColourMarker assetId={asset.id} />
          <Typography variant="h3"><AssetLabel asset={asset} /></Typography>
        </Stack>
        <Typography variant="h6">{asset.make} {asset.model}</Typography>
      </TableCell>
      {tripsQuery.isLoading ? (<TableCell colSpan={5}><LoadingIcon size={32} /></TableCell>) : (
        <>
          <TableCell sx={{ textAlign: 'center' }}>
            <Typography>{trips.length}</Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <Typography>{duration.fromMillis(totalDuration)}</Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <Typography whiteSpace="nowrap">{distance.create(totalDistance * 1000).format()}</Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <ApprovalIcon trips={trips} />
          </TableCell>
          <TableCell>
            <IconButton>
              <ChevronRight />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
