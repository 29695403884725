import React from 'react';

const Cessna206 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M 32.128906 5.7226562 C 32.128906 5.7226562 30.851529 6.8799029 30.748047 7.5761719 C 30.683617 8.0096557 29.671913 7.734303 29.375 8.0566406 C 28.455776 9.0545782 28.435547 10.369141 28.435547 10.369141 C 28.435547 10.369141 28.307812 18.575993 27.853516 18.589844 C 26.538592 18.629934 20.863792 18.607084 16.992188 18.75 C 12.137352 18.929202 2.750495 18.849231 2.4414062 19.580078 C -1.0266184 27.780281 1.579806 27.275851 2.0820312 27.376953 C 4.4993317 27.863573 12.825152 28.710586 17.578125 28.572266 C 21.060329 28.470927 28.320312 28.425781 28.320312 28.425781 C 28.390482 30.321958 28.805757 35.167442 28.90625 35.791016 C 29.724934 40.585119 29.980469 46.289062 29.980469 46.289062 C 29.980469 46.289062 24.33715 47.15062 21.484375 47.753906 C 20.844835 47.889152 20.801425 52.720402 21.337891 52.832031 C 22.840851 53.144771 31.020727 54.208082 31.25 54.150391 C 31.896247 54.787536 31.900299 56.450724 32.128906 57.568359 C 32.357513 56.450724 32.361565 54.787536 33.007812 54.150391 C 33.237085 54.208082 41.416961 53.144771 42.919922 52.832031 C 43.456387 52.720402 43.412978 47.889152 42.773438 47.753906 C 39.920662 47.15062 34.277344 46.289062 34.277344 46.289062 C 34.277344 46.289062 34.532878 40.585119 35.351562 35.791016 C 35.452055 35.167442 35.86733 30.321958 35.9375 28.425781 C 35.9375 28.425781 43.197484 28.470927 46.679688 28.572266 C 51.432661 28.710586 59.758481 27.863573 62.175781 27.376953 C 62.678007 27.275851 65.284431 27.780281 61.816406 19.580078 C 61.507318 18.849231 52.12046 18.929202 47.265625 18.75 C 43.394021 18.607084 37.719221 18.629934 36.404297 18.589844 C 35.95 18.575993 35.822266 10.369141 35.822266 10.369141 C 35.822266 10.369141 35.802037 9.0545782 34.882812 8.0566406 C 34.5859 7.734303 33.574196 8.0096557 33.509766 7.5761719 C 33.406284 6.8799029 32.128906 5.7226562 32.128906 5.7226562 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default Cessna206;
