/**
  * File for string utilities. Add any others that might be useful here.
  */

/**
  * Uses a simple regex to validate email addresses. This is the prefered method for validating email addresses in TracPlus.
  * The regex is puroposely loose to avoid rejecting unusual email addresses.
  * @param {String} email Email address to validate.
  */
export const isValidEmail = email => Boolean(email && /^.+@.+$/.test(email));
