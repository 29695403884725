import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useTranslations } from 'use-intl';
import { DropSummary } from 'apis/rest/firefighting/types';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useVolume from 'hooks/units/useVolume';
import DateTime from 'components/shared/datetime';
import Coordinate from 'components/shared/coordinate';
import { DetailsGrid, ScoreCard, OverlayHeader, OverlayWrapper } from './mapCommon';

interface DropsByAssetScorecardProps extends PaperOwnProps {
  data: DropSummary
  hoveredFillClusterId?: string
}

const HoveredFillClusterOverlay = ({ data, hoveredFillClusterId, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;
  const hoveredFillCluster = useMemo(() => data.fillClusters.find(d => d.id === hoveredFillClusterId), [hoveredFillClusterId, data.fillClusters]);
  const assets = useMemo(() => assetsQuery.data?.filter(a => hoveredFillCluster?.assetIds.includes(a.id)) ?? [], [hoveredFillCluster, assetsQuery.data]);
  // const hoveredAsset = useMemo(() => assetsQuery.data?.find(a => a.id === hoveredDropGroup?.assetId), [assetsQuery.data, hoveredDropCluster]);

  const distance = useDistance();
  const duration = useDuration();
  const volume = useVolume();
  const assetLabel = useAssetLabel();

  if (!hoveredFillCluster) {
    return null;
  }

  return (
    <OverlayWrapper highContrast {...props}>
      <OverlayHeader>
        <Typography variant="h3">Source</Typography>
      </OverlayHeader>
      <DetailsGrid>
        <ScoreCard label="Place" wide>
          <Typography>{hoveredFillCluster.bodyOfWater ?? <Coordinate latitude={hoveredFillCluster.center[1]} longitude={hoveredFillCluster.center[0]} />}</Typography>
        </ScoreCard>
        <ScoreCard label="Volume">
          <Typography>{volume.create(hoveredFillCluster.volumeFilledLitres).format()}</Typography>
        </ScoreCard>
        <ScoreCard label="Assets" wide>
          <Typography>{assets.map(assetLabel)}</Typography>
        </ScoreCard>
      </DetailsGrid>
    </OverlayWrapper>
  );
};

export default HoveredFillClusterOverlay;
