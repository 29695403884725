import React from 'react';

const C130 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 30.117922,13.573595 c -0.821917,0.30875 -1.715873,3.543163 -1.811423,5.555029 -0.04798,1.010193 -5e-6,7.946107 -5e-6,7.946107 l -4.613089,1e-6 c 0,0 0.123831,-4.390002 -1.231768,-4.323262 -1.477644,0.07275 -1.400834,4.395718 -1.400834,4.395718 l -5.24105,0.169067 c 0,0 0.05211,-4.808189 -1.400834,-4.709699 -1.575525,0.106795 -1.37668,4.782156 -1.37668,4.782156 0,0 -8.5520443,0.06795 -11.4723443,0.120761 -1.5985812,0.02891 -0.1604283,4.912962 0.3622846,5.120288 0.5227128,0.207326 21.1815697,2.874124 21.1815697,2.874124 l 4.516481,-0.144914 0.579654,0.94194 -0.120761,9.926597 -9.081266,1.594051 0.19322,3.091496 10.892688,1.569899 c 0,0 1.148031,1.27691 1.91622,1.280324 0.768189,0.0034 1.927524,-1.263241 1.927524,-1.263241 l 10.906212,-1.473015 0.220692,-3.089656 -9.066738,-1.674709 -0.03252,-9.927278 0.588004,-0.936751 4.515014,0.185054 c 0,0 20.681745,-2.483062 21.20628,-2.685734 0.524535,-0.202671 2.006044,-5.073747 0.407783,-5.116865 -2.919715,-0.07877 -11.470817,-0.222731 -11.470817,-0.222731 0,0 0.240395,-4.673408 -1.334119,-4.794204 -1.452011,-0.1114 -1.442642,4.697062 -1.442642,4.697062 l -5.23934,-0.215647 c 0,0 0.115233,-4.322114 -1.361706,-4.407996 -1.354952,-0.07879 -1.270148,4.312143 -1.270148,4.312143 l -4.612906,-0.041 c 0,0 0.109629,-6.935214 0.07062,-7.945794 -0.07766,-2.012635 -0.942834,-5.254867 -1.761974,-5.57091 -0.543229,-0.209591 -0.910322,-2.526298 -2.066688,-2.538285 -1.166527,-0.01209 -1.529524,2.31512 -2.074594,2.519874 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default C130;
