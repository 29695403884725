import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    overflowY: 'hidden',
    flex: 1
  },
  listWrapper: {
    paddingTop: 0,
    height: '100%'
  },
  expansionPanel: {
    borderRadius: '0!important',
    boxShadow: 'none',
    margin: '0!important'
  },
  summaryWrapper: {
    minHeight: '20px!important',
    padding: '5px 20px',
    backgroundColor: theme.palette.common.lightGrey,
    '& > div': {
      margin: '2px!important',
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden'
    }
  },
  summary: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: '500',
    fontSize: '.8rem',
    color: theme.palette.common.text
  },
  detailsWrapper: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  groupHeaderRow: {
    padding: theme.spacing(0, 3),
    color: theme.palette.common.text,
    display: 'flex',
    justifyContent: 'space-between',
    width: 'var(--omnibox-width)!important',
  },
  assetRow: {
    padding: theme.spacing(0, 3),
    width: 'var(--omnibox-width)!important',
  },
  resultsGroup: {
    marginBottom: theme.spacing(3)
  },
  groupHeaderTitle: {
    color: theme.palette.common.text,
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '.9rem',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(1)
  },
  visibilityIcon: {
    color: theme.palette.common.text,
    cursor: 'pointer'
  },
  visibilityIconDisabled: {
    color: theme.palette.common.lightGrey,
    cursor: 'not-allowed'
  },
  resultsWrapper: {
    padding: theme.spacing(3)
  }
}));
