import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import { isAssetWithDevice, isAviationAsset } from 'helpers/assets';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import useSnackbar from 'hooks/useSnackbar';
import { DateTime } from 'luxon';
import {
  Paper,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Tooltip,
} from '@mui/material';
import { Close, HelpOutline } from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import mixpanel from 'mixpanel-browser';
import LabsAlert from 'components/shared/labs/Alert';
import useOrganisationId from 'hooks/session/useOrganisationId';
import SelectAssets from 'components/pages/sharing/selectAssets';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import useTimezone from 'hooks/session/useTimezone';
import { useLabs } from 'contexts/labs/labs-context';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import { DateSelection } from './dateSelection';
import { AssetFlightSummary } from './assetFlightSummary';
import { updateSetting } from 'slices/settings.slice';

const NO_ASSETS: never[] = [];

export const selectAviationAssetsWithDevices = (assets: AssetBasic[]): AssetWithDevice[] => assets.filter(a => (isAssetWithDevice(a) && isAviationAsset(a))) as AssetWithDevice[];

type DFRQuery = ReduxState['settings']['dailyFlightRecords']['query'];

const DailyFlightSummaryReport = (): JSX.Element => {
  const organisationId = useOrganisationId();
  const timezone = useTimezone();
  const dfsQuery = useSelector<ReduxState, DFRQuery>(state => state.settings.dailyFlightRecords.query);
  const dispatch = useAppDispatch();
  const setQuery = useCallback((newQuery: DFRQuery) => dispatch(updateSetting({ category: 'dailyFlightRecords', field: 'query', value: newQuery })), [dispatch]);

  const t = useTranslations('pages.reporting.dailyFlightSummary');

  const snackbar = useSnackbar();

  const assetListQuery = useGetAssetsList<AssetWithDevice[]>({ select: selectAviationAssetsWithDevices }).query;
  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const intercom = useIntercom();

  useEffect(() => {
    intercom.trackEvent('dailyFlightSummaryView', {
      organisation_id: organisationId,
      time_at: Date.now(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercom]);

  useEffect(() => {
    if (assetListQuery.isError) {
      snackbar.display({
        id: 'getAssetListFailedSnackbar',
        text: t('getAssetListFailed'),
        type: 'error',
      });
    }
  }, [assetListQuery, snackbar, t]);

  const onDateUpdate = useCallback((d: DateTime) => {
    setQuery({ ...dfsQuery, day: d.setZone(timezone).toISODate() });
  }, [setQuery, dfsQuery, timezone]);

  const queryDay = useMemo(() => (dfsQuery.day ? DateTime.fromISO(dfsQuery.day) : DateTime.now()), [dfsQuery.day]);
  const queryAssets = useMemo(() => (dfsQuery.assets ?? []), [dfsQuery.assets]);

  const assets = assetListQuery.data ?? NO_ASSETS;
  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  useEffect(() => {
    mixpanel.track('Daily Flight Summary Query Change', { query: dfsQuery });
  }, [dfsQuery]);

  useEffect(() => {
    mixpanel.track('Daily Flight Summary Page View');
  }, []);

  const [displayMessage, setDisplayMessage] = useState(() => {
    try {
      return !JSON.parse(sessionStorage.getItem('dailyFlightSummary.betaMessageDismissed') ?? '');
    } catch (error) {
      return true;
    }
  });
  useEffect(() => {
    sessionStorage.setItem('dailyFlightSummary.betaMessageDismissed', (!displayMessage).toString());
  }, [displayMessage]);

  const labs = useLabs();

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(() => assets.filter(a => dfsQuery.assets?.includes(a.id)).map(a => a.deviceId));

  useEffect(() => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id);
    setQuery({ ...dfsQuery, assets: selectedAssets });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceIds, assets]);

  const devicesById = devicesByIdQuery.data ?? [];
  const assetGroups = assetGroupsQuery.data;

  const isLoading = devicesByIdQuery.isFetching || assetGroupsQuery.isFetching || assetListQuery.isLoading;

  if (devicesByIdQuery.isLoading) {
    return (<LoadingIcon size={5} />);
  }

  return (
    <>
      <Collapse in={displayMessage && labs?.value}>
        <LabsAlert
          sx={{ mb: 3, p: 3 }}
          action={(
            <IconButton
              aria-label={t('infoMessage.dismiss')}
              color="inherit"
              onClick={() => setDisplayMessage(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )}
        >
          {t.rich('infoMessage.content', { break: () => <br /> })}
        </LabsAlert>
      </Collapse>
      <Stack spacing={3}>
        <FeaturePageStaffAccessAlert feature="reporting.dailyFlightSummary" />
        <Paper elevation={0}>
          <Stack direction="row" spacing={2} p={2}>
            <SelectAssets
              label={t('selectAssets.label')}
              deviceIds={deviceIds}
              selectedDeviceIds={selectedDeviceIds}
              assetsByDeviceId={assetsByDeviceId}
              devicesById={devicesById}
              setSelectedDeviceIds={value => {
                setSelectedDeviceIds(value);
              }}
              assetGroups={assetGroups}
              isLoading={isLoading}
              featureName="reporting.dailyFlightSummary"
            />
            <DateSelection
              assets={assets}
              queryDate={queryDay}
              updateDate={onDateUpdate}
              selectedAssetIds={queryAssets}
            />
          </Stack>
        </Paper>
        <Paper elevation={0}>
          <Table width="100%">
            <TableHead>
              <TableRow>
                <TableCell width="55%">Asset</TableCell>
                <TableCell width="10%" sx={{ textAlign: 'center' }}>Flights</TableCell>
                <TableCell width="10%" sx={{ textAlign: 'center' }}>Flight time</TableCell>
                <TableCell width="10%" sx={{ textAlign: 'center' }}>Total distance</TableCell>
                <TableCell width="10%" sx={{ textAlign: 'center' }}>
                  <Tooltip title={(
                    <>
                      <p>Coming soon</p>
                      <p>An approval workflow to amend / finalise trip details</p>
                    </>
                  )}>
                    <Stack direction="row" spacing={0.5} justifyContent="center" color="common.disabled">
                      <span>Approval</span>
                      <HelpOutline />
                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell width="5%" />
              </TableRow>
            </TableHead>
            {queryAssets
              .map(assetId => assets.find(a => a.id === assetId))
              .filter((a): a is AssetWithDevice => !!a)
              .map(asset => (
                <AssetFlightSummary
                  key={asset.id}
                  asset={asset}
                  day={queryDay}
                />
              ))}
          </Table>
        </Paper>
      </Stack>
    </>
  );
};

export default DailyFlightSummaryReport;
