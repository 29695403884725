import React from 'react';

const DC10 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 33.86331,60.031929 c 0,0 11.030717,2.095751 11.04883,1.824607 0.117333,-1.756474 0.188442,-4.755462 -0.05536,-4.99926 -0.243799,-0.243799 -9.16646,-5.835657 -9.196936,-6.719428 -0.03048,-0.883771 0.133693,-0.220579 0.373853,-3.463003 0.240166,-3.242423 0.137544,-6.836042 0.137544,-6.836042 l 5.726418,0.855532 c 0,0 17.051155,7.27558 17.065395,6.882965 0.123913,-3.415261 -0.238835,-5.929181 -0.238835,-5.929181 C 57.13654,40.84353 43.629326,31.216887 43.467396,31.087737 c -0.161929,-0.12915 0.421831,-2.12331 0.394231,-3.608312 -0.03619,-1.947024 -0.51528,-3.218594 -0.538596,-3.342046 -0.04332,-0.229411 -3.862916,-0.471814 -4.297338,0.01081 -0.33439,0.371492 -0.436126,3.727269 -0.436126,3.727269 0,0 -2.199018,-0.942396 -2.381868,-1.308095 C 36.024849,26.201665 36.020966,14.136031 35.929538,9.8390751 35.838108,5.5421194 33.391798,2.160956 31.995951,2.160956 c -1.395847,0 -3.842162,3.3811634 -3.933587,7.6781191 -0.09143,4.2969559 -0.09531,16.3625899 -0.278161,16.7282879 -0.18285,0.365699 -2.381868,1.308095 -2.381868,1.308095 0,0 -0.101736,-3.355777 -0.436126,-3.727269 -0.434422,-0.482621 -4.254016,-0.240218 -4.297338,-0.01081 -0.02332,0.123452 -0.502412,1.395022 -0.538596,3.342046 -0.0276,1.485002 0.55616,3.479162 0.394231,3.608312 -0.16193,0.12915 -13.6691445,9.755793 -15.2568247,10.560382 0,0 -0.3627483,2.51392 -0.238836,5.929181 0.014244,0.392615 17.0653957,-6.882965 17.0653957,-6.882965 l 5.726418,-0.855532 c 0,0 -0.102622,3.593619 0.137544,6.836042 0.24016,3.242424 0.404329,2.579232 0.373853,3.463003 -0.03048,0.883771 -8.953137,6.475629 -9.196936,6.719428 -0.2438,0.243798 -0.172691,3.242786 -0.05536,4.99926 0.01811,0.271144 11.04883,-1.824607 11.04883,-1.824607 0,0 0.41117,2.576908 1.867359,2.651785 1.456189,-0.07488 1.867359,-2.651785 1.867359,-2.651785 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default DC10;
