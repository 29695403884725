import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const FriendsIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <circle cx="25" cy="18" r="4" />
      <circle cx="7" cy="18" r="4" />
      <path d="M12.525,25.433C11.421,24.779,9.565,24,7,24c-2.599,0-4.446,0.776-5.539,1.429 C0.56,25.967,0,26.955,0,28.007V31h14v-2.982C14,26.96,13.435,25.97,12.525,25.433z" />
      <path d="M30.525,25.433C29.421,24.779,27.565,24,25,24c-2.599,0-4.446,0.776-5.539,1.429 C18.56,25.967,18,26.955,18,28.007V31h14v-2.982C32,26.96,31.435,25.97,30.525,25.433z" />
      <path d="M16,1c-3.309,0-6,2.691-6,6c0,3.383,4.755,8.176,5.297,8.712L16,16.405l0.703-0.693 C17.245,15.176,22,10.383,22,7C22,3.691,19.309,1,16,1z M16,9c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2 C18,8.105,17.105,9,16,9z" />
    </SvgIcon>
  );
};

export default FriendsIcon;
