import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslations } from 'use-intl';
import { kml as kmlToGeoJson } from '@tmcw/togeojson';
import { FeatureCollection } from 'geojson';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import JSZip from 'jszip';

export interface ImportFileAreaProps {
  onFileAdd: (features: FeatureCollection, fileName: string) => void;
}

export const GeofenceImportFileArea = ({ onFileAdd }: ImportFileAreaProps): JSX.Element => {
  const t = useTranslations('pages.manage.geofencing.import');
  const [pendingFileName, setPendingFileName] = useState('');

  const readKmlFile = useCallback((fileName: string, fileContents: string) => {
    const xml = (new DOMParser()).parseFromString(fileContents, 'text/xml');
    const collection = kmlToGeoJson(xml) as FeatureCollection;
    setPendingFileName(fileName);
    onFileAdd(collection, fileName);
  }, []);

  const handleFile = (file: any) => {
    const extension = file.name.split('.').pop().toLowerCase();

    if (extension === 'kml') {
      console.log('Reading KML file...');
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target && typeof e.target.result === 'string') {
          readKmlFile(file.name, e.target.result);
        }
      };
      reader.readAsText(file);
    } else if (extension === 'kmz') {
      const zip = new JSZip();
      zip.loadAsync(file)
        .then(zf => {
          // For now just extract the root doc.kml document.
          zf.file('doc.kml')?.async('string').then(data => {
            readKmlFile(file.name, data);
          }, () => console.warn('Failed to extract KML from KMZ'));
        });
    }
    console.log(extension);
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    {
      accept: [NativeTypes.FILE],
      drop(item, monitor) {
        console.log('file drop item', item);
        console.log('file drop monitor', monitor);
        if (monitor) {
          const { files } = monitor.getItem();
          handleFile(files[0]);
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }
  );

  return (
    <Box>
      <label htmlFor="browseFiles">
        <Paper
          elevation={0}
          sx={{
            m: 1,
            textAlign: 'center',
            bgcolor: isOver ? '#4A525C' : undefined,
          }}
          ref={drop}
          variant="outlined"
        >
          <Typography sx={{ userSelect: 'none', mt: 2, whiteSpace: 'pre-wrap' }}>{(canDrop && isOver) ? t('dropFile') : t('dropFilesHere')}</Typography>
          {pendingFileName.length > 0 ? (
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', my: 2 }}>
              <InsertDriveFileIcon />
              <Typography sx={{ fontSize: '16px', mt: 1, marginBottom: '8px', whiteSpace: 'pre-wrap' }}>{pendingFileName}</Typography>
            </Stack>
          ) : <Typography sx={{ fontSize: '64px', opacity: '0.4', userSelect: 'none', mb: 1 }}>+</Typography>}
          <Typography sx={{ userSelect: 'none', mb: 2, whiteSpace: 'pre-wrap' }}>{t('clickToBrowse')}</Typography>
        </Paper>
        <input
          style={{ display: 'none' }}
          id="browseFiles"
          name="browseFiles"
          type="file"
          onChange={e => handleFile(e.target.files[0])}
        />
      </label>
    </Box>
  );
};
