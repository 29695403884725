import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import LinkAbove from 'components/shared/linkAbove';
import { PeopleGroupTable } from './components/table';

const PeopleGroupPage = (): JSX.Element => {
  const t = useTranslations('pages.manage.peopleGroups');

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box mb={3}>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
            <FeaturePageStaffAccessAlert feature="manage.notifications" />
          </Box>

          <PeopleGroupTable />
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default PeopleGroupPage;
