export type SearchPatternType =
  'parallelTrack'
  | 'creepingLine'
  | 'expandingBox'
  | 'sectorSearch'
  | 'rangeBearingLine';

// export const ALL_SEARCH_PATTERNS: SearchPatternType[] = ['parallelTrack', 'creepingLine', 'expandingBox', 'sectorSearch', 'rangeBearingLine'];
export const ALL_SEARCH_PATTERNS: SearchPatternType[] = ['parallelTrack', 'creepingLine', 'expandingBox'];

type Direction = 'LEFT' | 'RIGHT';

export type SearchPattern =
  ParallelTrackSearchPattern
  | CreepingLineSearchPattern
  | ExpandingBoxSearchPattern
  | SectorSearchPattern
  | RangeBearingLineSearchPattern;

interface BaseSearchPattern {
  id: string;
  name: string;
  notes: string;
  type: SearchPatternType;
  origin: {
    lng: number,
    lat: number,
  },
  // in degrees
  orientation: number;
  isLocked: boolean;
  isHidden: boolean;
}

export interface ParallelTrackSearchPattern extends BaseSearchPattern {
  firstTurnDirection: Direction,
  trackSpacingMetres: number;
  legCount: number;
  legLengthMetres: number;
}

export interface CreepingLineSearchPattern extends BaseSearchPattern {
  firstTurnDirection: Direction,
  trackSpacingMetres: number;
  legCount: number;
  legLengthMetres: number;
}

export interface ExpandingBoxSearchPattern extends BaseSearchPattern {
  turnDirection: Direction,
  trackSpacingMetres: number;
  legCount: number;
}

export interface SectorSearchPattern extends BaseSearchPattern {
  turnDirection: Direction,
  trackSpacingMetres: number;
}

export interface RangeBearingLineSearchPattern extends BaseSearchPattern {
  // ± degrees
  orientationArc: number;
  legLengthMetres: number;
  // ± metres
  legLengthRangeMetres: number;
}

const DEFAULT_BASE: Omit<BaseSearchPattern, 'type'> = {
  id: 'sp_default',
  name: '',
  notes: '',
  origin: { lng: 0, lat: 0 },
  orientation: 0,
  isLocked: false,
  isHidden: false,
};
const DEFAULT_PARALLEL_TRACK: ParallelTrackSearchPattern = {
  ...DEFAULT_BASE,
  type: 'parallelTrack',
  firstTurnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 8,
  legLengthMetres: 3500,
};
const DEFAULT_CREEPING_LINE: CreepingLineSearchPattern = {
  ...DEFAULT_BASE,
  type: 'creepingLine',
  firstTurnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 8,
  legLengthMetres: 3500,
};
const DEFAULT_EXPANDING_BOX: ExpandingBoxSearchPattern = {
  ...DEFAULT_BASE,
  type: 'expandingBox',
  turnDirection: 'LEFT',
  trackSpacingMetres: 1000,
  legCount: 11,
};
const DEFAULT_SECTOR_SEARCH: SectorSearchPattern = {
  ...DEFAULT_BASE,
  type: 'sectorSearch',
  turnDirection: 'LEFT',
  trackSpacingMetres: 1000,
};
const DEFAULT_RANGE_BEARING_LINE: RangeBearingLineSearchPattern = {
  ...DEFAULT_BASE,
  type: 'rangeBearingLine',
  orientationArc: 30,
  legLengthMetres: 3500,
  legLengthRangeMetres: 500,
};

export const isSearchPattern = (value: string): value is SearchPatternType => (ALL_SEARCH_PATTERNS as string[]).includes(value);

export const isParallelTrack = (sp: SearchPattern): sp is ParallelTrackSearchPattern => sp.type === 'parallelTrack';
export const isCreepingLine = (sp: SearchPattern): sp is CreepingLineSearchPattern => sp.type === 'creepingLine';
export const isExpandingBox = (sp: SearchPattern): sp is ExpandingBoxSearchPattern => sp.type === 'expandingBox';
export const isSectorSearch = (sp: SearchPattern): sp is SectorSearchPattern => sp.type === 'sectorSearch';
export const isRangeBearingLine = (sp: SearchPattern): sp is RangeBearingLineSearchPattern => sp.type === 'rangeBearingLine';

export const defaultSearchPattern = (type: SearchPatternType, name: string): SearchPattern => {
  let sp;
  switch (type) {
    case 'parallelTrack':
      sp = DEFAULT_PARALLEL_TRACK;
      break;
    case 'creepingLine':
      sp = DEFAULT_CREEPING_LINE;
      break;
    case 'expandingBox':
      sp = DEFAULT_EXPANDING_BOX;
      break;
    case 'sectorSearch':
      sp = DEFAULT_SECTOR_SEARCH;
      break;
    case 'rangeBearingLine':
      sp = DEFAULT_RANGE_BEARING_LINE;
      break;
    default:
      throw new Error('unsupported search pattern type');
  }

  return {
    ...sp,
    name,
  };
};

const LIMITS = {
  minNameLength: 1,
  maxNameLength: 30,
  notesLength: 200,

  parallelTrack: {
    minLegCount: 2,
    maxLegCount: 100,
  },
  expandingBox: {
    minLegCount: 3,
    maxLegCount: 99,
  },

  minSpacing: 0,
  maxSpacing: 10_000,
  minLegLength: 0,
  maxLegLength: 100_000,
};

export const getInvalidFields = (sp: SearchPattern) => {
  const invalidFields = [];

  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    if (sp.legCount < LIMITS.parallelTrack.minLegCount || sp.legCount > LIMITS.parallelTrack.maxLegCount) invalidFields.push('legCount');
    if (sp.legLengthMetres <= LIMITS.minLegLength || sp.legLengthMetres > LIMITS.maxLegLength) invalidFields.push('legLengthMetres');
    if (sp.trackSpacingMetres <= LIMITS.minSpacing || sp.trackSpacingMetres > LIMITS.maxSpacing) invalidFields.push('trackSpacingMetres');
  }

  if (isExpandingBox(sp)) {
    if (sp.legCount < LIMITS.expandingBox.minLegCount || sp.legCount > LIMITS.expandingBox.maxLegCount || sp.legCount % 2 === 0) invalidFields.push('legCount');
    if (sp.trackSpacingMetres <= LIMITS.minSpacing || sp.trackSpacingMetres > LIMITS.maxSpacing) invalidFields.push('trackSpacingMetres');
  }

  if (sp.name.length < LIMITS.minNameLength || sp.name.length > LIMITS.maxNameLength) invalidFields.push('name');
  if (sp.notes.length > LIMITS.notesLength) invalidFields.push('notes');

  return invalidFields;
};

export const getLegCount = (sp: SearchPattern) => {
  if (isParallelTrack(sp) || isCreepingLine(sp)) {
    return Math.round(sp.legCount);
  }

  if (isExpandingBox(sp)) {
    return (2 * Math.ceil(sp.legCount / 2)) - 1;
  }

  throw new Error(`${sp.type} is not supported`);
};
