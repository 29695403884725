import React, { useCallback, useState } from 'react';
import Page from 'components/pages/page';
import { useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { Box, Container, Typography } from '@mui/material';
import LinkAbove from 'components/shared/linkAbove';
import DetailPanel from 'components/shared/DetailPanel';
import { useMutateCreateEventNotificationGroup, useMutateDeleteEventNotificationGroup, useListEventNotificationGroups } from 'apis/rest/eventNotifications/hooks';
import usePermissions from 'hooks/session/usePermissions';
import useSnackbar from 'hooks/useSnackbar';
import { DeleteDialog } from 'components/shared/deleteDialog';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import * as EnTypes from 'apis/rest/eventNotifications/types';
import { CreateButton } from '../groups/components/createButton';
import { EventNotificationTable } from './components/notificationTable/event-notification-table';
import { AddNewNotificationGroupDialog } from './components/createDialog/add-new-notification-group';

const EventNotificationList = (): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications');
  const permissions = usePermissions();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [addGroup, setAddGroup] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState<EnTypes.EventNotificationGroupRequest | null>(null);

  const { query } = useListEventNotificationGroups();
  const deleteMutation = useMutateDeleteEventNotificationGroup();
  const createMutation = useMutateCreateEventNotificationGroup();

  const getGroupName = (groupId: number | null): string => {
    if (groupId === undefined) return '';
    const group = query.data?.find(({ id }) => groupId === id);
    return group?.name ?? '';
  };

  const handleDelete = (id: number, version: number) => {
    setDeleteGroup({ id, rowVersion: version, name: getGroupName(id), isActive: true });
  };

  const handleDeleteCancel = () => setDeleteGroup(null);

  const handleDeleteConfirm = useCallback(() => {
    if (deleteGroup) {
      deleteMutation.mutate(deleteGroup, {
        onSuccess: () => {
          snackbar.display({
            id: `eventNotificationGroup.${deleteGroup.id}.delete`,
            text: t('delete.successText', { name: deleteGroup?.name ?? '' }),
            type: 'success'
          });
        },
        onError: () => {
          snackbar.display({
            id: `eventNotificationGroup.${deleteGroup.id}.delete.error`,
            text: t('delete.errorText', { name: deleteGroup?.name ?? '' }),
            type: 'error'
          });
        },
        onSettled: () => {
          setDeleteGroup(null);
        }
      });
    }
  }, [deleteGroup, deleteMutation, snackbar, t]);

  const handleCreateConfirm = useCallback((name: string) => {
    if (addGroup) {
      createMutation.mutate({ name }, {
        onSuccess: () => {
          snackbar.display({
            id: 'eventNotificationGroup.create',
            text: t('create.success', { name }),
            type: 'success'
          });
          setAddGroup(false);
        },
        onError: () => {
          snackbar.display({
            id: 'eventNotificationGroup.create.error',
            text: t('create.error'),
            type: 'error'
          });
        },
      });
    }
  }, [createMutation, snackbar, t, setAddGroup, addGroup]);

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box mb={3}>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>{t('title')}</Typography>
            <Typography paragraph>{t('description')}</Typography>
            <FeaturePageStaffAccessAlert feature="manage.notifications" />
          </Box>
          <DetailPanel pb={0} mb={8}>
            {permissions.canEditEventNotifications
              && <CreateButton buttonText={t('create.text')} onClick={() => setAddGroup(true)} />}
            <EventNotificationTable
              data={query.data}
              isLoading={query.isLoading}
              readonly={!permissions.canEditEventNotifications}
              onRowOpen={id => navigate(`/manage/event-notifications/${id}`)}
              onRowDelete={handleDelete}
            />
          </DetailPanel>
        </Container>
      </SettingsMenuPageWrapper>

      <DeleteDialog
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title={t('delete.dialogTitle')}
        open={!!deleteGroup}
        confirmText={deleteMutation.isPending ? t('delete.loadingText') : t('delete.confirmText')}
        snackText=""
        isLoading={deleteMutation.isPending}>
        {t('delete.dialogText', { name: getGroupName(deleteGroup?.id ?? null) })}
      </DeleteDialog>

      <AddNewNotificationGroupDialog
        open={addGroup}
        isDisabled={createMutation.isPending}
        onCancel={() => setAddGroup(false)}
        onSubmit={handleCreateConfirm}
        title={t('create.title')}
        ariaLabel="" />
    </Page>
  );
};

export default EventNotificationList;
