import { IconLayer } from '@deck.gl/layers';
import colouredFragmentShader from './shaders/coloured-fragment-shader.glsl';

export default class ColouredIconLayer<T> extends IconLayer<T> {
  getShaders() {
    return { ...super.getShaders(), fs: colouredFragmentShader };
  }
}

ColouredIconLayer.layerName = 'ColouredIconLayer';
