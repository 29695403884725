import React, { useState } from 'react';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import useSnackbar from 'hooks/useSnackbar';
import { useMutateMessagingWhitelistContactGroup } from 'apis/rest/messagingWhitelistContactGroups/hooks';
import {
  SetDefaultContactGroupDialog,
  SetDefaultContactGroupDialogStatus,
} from 'components/dialogs/contactGroups/setDefault';

interface SetDefaultProps {
  group: ContactGroup
}

const SetDefault = ({ group }: SetDefaultProps): JSX.Element | null => {
  const t = useTranslations('pages.manage.messagingWhitelists.edit.default');
  const [open, setOpen] = useState(false);
  const mutation = useMutateMessagingWhitelistContactGroup();
  const snackbar = useSnackbar();

  if (group.isDefault && !mutation.isPending) return null;

  return (
    <>
      <Stack spacing={3} px={3} direction="row" justifyContent="space-between" height="4em">
        <Stack justifyContent="center">
          <Typography>{t('canSet')}</Typography>
        </Stack>
        <Button
          size="large"
          variant="outlined"
          sx={{ minWidth: '10rem' }}
          onClick={() => setOpen(true)}
        >
          {t('set')}
        </Button>
      </Stack>

      <SetDefaultContactGroupDialog
        open={open}
        onClose={status => {
          setOpen(false);
          if (status === SetDefaultContactGroupDialogStatus.Saved) {
            snackbar.display({
              id: `messagingWhitelistContactGroupSetDefault.${group.id}`,
              text: t('dialog.snackbar.success', { name: group.name }),
              type: 'success',
            });
          }
        }}
        group={group}
        mutation={mutation}
        valid
        ariaLabel={t('dialog.title')}
        title={t('dialog.title')}
      >
        <Typography>
          {t.rich('dialog.message', { name: group.name, strong: chunks => <strong>{chunks}</strong> })}
        </Typography>
        <Typography>{t('dialog.explanation')}</Typography>
        {mutation.isError && <Alert severity="error">{t('dialog.unexpectedError')}</Alert>}
      </SetDefaultContactGroupDialog>
    </>
  );
};

export default SetDefault;
