import React from 'react';

const MD369 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 17.078223,1.8085938 c 0.410121,-0.024445 0.823959,0.1495462 1.09375,0.5019531 L 26.88291,13.689453 c 1.648939,-3.252982 4.6875,-3.386719 4.6875,-3.386719 0,0 4.369083,0.246883 5.400391,5.78125 L 49.611426,6.40625 c 0.281926,-0.2158329 0.622465,-0.29508 0.947266,-0.2519531 0.324801,0.043127 0.633776,0.2102618 0.849609,0.4921875 L 52.529395,8.109375 C 52.96106,8.6732264 52.853012,9.4745834 52.28916,9.90625 L 37.082129,21.550781 c -0.105871,1.73971 -0.286337,3.29757 -0.515625,4.679688 l 11.984375,15.654297 c 0.431667,0.56385 0.32557,1.365209 -0.238281,1.796875 l -1.464844,1.11914 C 46.283903,45.232447 45.484499,45.12635 45.052832,44.5625 L 35.19541,31.6875 c -0.989612,2.754852 -1.976562,3.925781 -1.976562,3.925781 0,0 -0.328451,14.799719 -0.152344,15.09961 0.176107,0.29989 9.936538,-0.397456 9.923828,-0.103516 -0.01271,0.293939 0.05105,3.850172 -0.03125,4.224609 -0.0823,0.374437 -10.442146,-0.129536 -10.535156,0.148438 -0.09301,0.277974 -0.05957,2.080751 -0.0332,2.144531 0.02637,0.06377 -2.300475,0.172755 -2.642578,0.02344 -0.342103,-0.149318 -0.06704,-3.924944 -0.113281,-4.589844 -0.04624,-0.6649 -0.0625,-16.970703 -0.0625,-16.970703 0,0 -1.427575,-1.743772 -2.515625,-5.949219 L 13.82627,39.769531 c -0.563851,0.431667 -1.367162,0.323616 -1.798828,-0.240234 l -1.119141,-1.462891 c -0.431665,-0.563852 -0.32557,-1.363256 0.238281,-1.794922 L 26.168067,24.769531 C 25.990397,23.241752 25.88506,21.511929 25.861426,19.605469 L 14.673926,4.9882812 C 14.242261,4.4244305 14.348357,3.6230726 14.912207,3.1914062 L 16.375098,2.0703125 C 16.586542,1.9084378 16.83215,1.8232606 17.078223,1.8085938 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default MD369;
