import React, { useState } from 'react';
import usePermissions from 'hooks/session/usePermissions';
import { useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import { ContactGroupsTable, ContactGroupsTableComponents } from 'components/pages/manage/contactGroups/common/contactGroupsTable';
import DetailPanel from 'components/shared/DetailPanel';
import { Alert, Button, Stack } from '@mui/material';
import useValidateName from 'hooks/contactGroup/useValidateName';
import useSnackbar from 'hooks/useSnackbar';
import AddGroupDialog from 'components/dialogs/contactGroups/addGroup';
import { useGetPeopleGroups, useMutateCreatePeopleGroup, useMutateDeletePeopleGroup } from 'apis/rest/peopleGroups/hooks';
import DeletePeopleGroupDialog from 'components/dialogs/peopleGroups/deleteGroup';
import { AssignPeopleToPeopleGroupDialog } from 'components/dialogs/peopleGroups/assignPeople';

const components: ContactGroupsTableComponents = {
  DeleteDialog: DeletePeopleGroupDialog,
  NoGroups: () => {
    const t = useTranslations('pages.manage.peopleGroups');
    return <Alert severity="warning">{t('all.noGroupsAlert', { groupType: t('groupTypes.notification') })}</Alert>;
  }
};

const Create = ({ created }: { created: (group: ContactGroup) => void }) => {
  const t = useTranslations('pages.manage.peopleGroups');
  const { query: groupsQuery } = useGetPeopleGroups();
  const createMutation = useMutateCreatePeopleGroup();
  const validateName = useValidateName(groupsQuery);
  const snackbar = useSnackbar();
  const [addGroup, setAddGroup] = useState(false);
  const [createdGroup, setCreatedGroup] = useState<ContactGroup>();
  const groupType = t('groupTypes.notification'); // TODO: make dynamic

  const done = (group: ContactGroup) => {
    snackbar.display({
      id: `peopleGroup.Created.${group.id}`,
      text: t('create.snackbar.success', { groupType, name: group.name }),
      type: 'success',
    });
    setCreatedGroup(undefined);
    created(group);
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mx={3} height="4em">
        <Button onClick={() => setAddGroup(true)} size="large" variant="contained">{t('all.createGroup')}</Button>
      </Stack>
      <AddGroupDialog
        title={t('create.title', { groupType })}
        ariaLabel={t('create.title', { groupType })}
        open={addGroup}
        mutation={createMutation}
        validateName={name => validateName(createMutation.data?.id, name)}
        created={group => {
          setAddGroup(false);
          setCreatedGroup(group);
        }}
        cancel={() => setAddGroup(false)}
      />
      <AssignPeopleToPeopleGroupDialog
        groupId={createdGroup?.id}
        open={!!createdGroup}
        onClose={() => {
          if (createdGroup) done(createdGroup);
        }}
        title={t('edit.people.dialog.title', { groupType, group: createdGroup?.name })}
        ariaLabel={t('edit.people.dialog.title', { groupType, group: createdGroup?.name })}
        skip
      />
    </>
  );
};

export const PeopleGroupTable = (): JSX.Element => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { query: groupsQuery } = useGetPeopleGroups();
  const deleteMutation = useMutateDeletePeopleGroup();
  const [createdGroupId, setCreatedGroupId] = useState<number>();

  return (
    <DetailPanel pb={0} mb={8}>
      <Stack direction="row" justifyContent="flex-end" mx={3}>
        {permissions.canEditContactGroups && <Create created={group => setCreatedGroupId(group.id)} />}
      </Stack>
      <ContactGroupsTable
        settingsCategory="peopleGroupsTable"
        data={groupsQuery.data}
        isLoading={groupsQuery.isLoading}
        components={components}
        navigateToRow={row => navigate(`/manage/people-groups/${row.group.id}`)}
        deleteMutation={deleteMutation}
        createdGroupId={createdGroupId}
        hideAssetColumn
      />
    </DetailPanel>
  );
};
