import React from 'react';

const Dash8 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="m 32.747808,57.269717 -6.512171,0.218111 c 0,0 -0.268881,-3.952888 0.186952,-4.268744 0.371746,-0.257591 5.078871,-0.592016 5.078871,-0.592016 l -0.436222,-10.438169 -0.716651,-9.69036 -2.430379,-0.03116 c 0,0 0.07742,1.725919 -0.06232,2.087634 -0.139736,0.361715 -0.591505,1.665889 -1.308666,1.713729 -0.644854,0.04302 -1.164753,-0.758483 -1.370983,-1.370983 -0.12733,-0.378154 -0.06232,-3.365142 -0.06232,-3.365142 0,0 -14.320704,-0.16994 -14.76923,-0.280428 -0.4485269,-0.110488 0.217416,-3.384915 0.997078,-3.707888 0.532255,-0.220485 13.649913,-1.121713 13.649913,-1.121713 l 0.09348,-3.260437 c 0,0 0.07144,-2.993694 1.351946,-2.991894 1.263967,0.0018 1.386208,2.949507 1.386208,2.949507 l 0.24927,3.048627 2.345604,-0.01512 c 0,0 -0.01093,-15.696923 0.09974,-16.6236052 0.590878,-4.9475187 2.746438,-5.6993484 2.746438,-5.6993484 0,0 2.163484,0.7287096 2.807296,5.669619 0.120586,0.9254446 0.277703,16.6215846 0.277703,16.6215846 l 2.345631,-0.01 0.216618,-3.051121 c 0,0 0.09068,-2.948828 1.354552,-2.964179 1.280417,-0.01551 1.383899,2.977249 1.383899,2.977249 l 0.12838,3.25925 c 0,0 13.126555,0.760741 13.66114,0.975515 0.783075,0.314608 1.484035,3.581718 1.036716,3.697001 -0.447317,0.115284 -14.765381,0.438528 -14.765381,0.438528 0,0 0.09698,2.986121 -0.02629,3.365617 -0.199661,0.614673 -0.710949,1.421696 -1.356227,1.385582 -0.717632,-0.04016 -1.183337,-1.339423 -1.326938,-1.699621 -0.1436,-0.360198 -0.08467,-2.086847 -0.08467,-2.086847 l -2.429906,0.05718 -0.612867,9.697477 -0.324448,10.442241 c 0,0 4.710435,0.284013 5.084918,0.537609 0.459188,0.310958 0.232641,4.266498 0.232641,4.266498 l -6.514132,-0.148381 c 0,0 -0.255293,1.407846 -0.79507,1.410735 -0.539777,0.0029 -0.810127,-1.402142 -0.810127,-1.402142 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Dash8;
