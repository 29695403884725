import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface LatLng {
  latitude: number;
  longitude: number;
}

interface MarkersState {
  createDialog: {
    open: boolean;
    latLng: LatLng;
  }
  isPlacingMarker: boolean;
}

const initialState: MarkersState = {
  createDialog: {
    open: false,
    latLng: {
      latitude: 0,
      longitude: 0,
    },
  },
  isPlacingMarker: false,
};

export const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    openCreateMarkerDialog(state, action: PayloadAction<LatLng>) {
      state.createDialog.open = true;
      state.createDialog.latLng = action.payload;
    },
    closeCreateMarkerDialog(state) {
      state.createDialog.open = false;
      state.createDialog.latLng = {
        latitude: 0,
        longitude: 0,
      };
    },
    setIsPlacingMarker(state, action: PayloadAction<boolean>) {
      state.isPlacingMarker = action.payload;
    },
  },
  selectors: {
    selectCreateMarkerDialog: sliceState => sliceState.createDialog,
    selectIsPlacingMarker: sliceState => sliceState.isPlacingMarker,
  },
});

export const {
  openCreateMarkerDialog,
  closeCreateMarkerDialog,
  setIsPlacingMarker,
} = markersSlice.actions;
export const {
  selectCreateMarkerDialog,
  selectIsPlacingMarker,
} = markersSlice.selectors;

export default markersSlice.reducer;
