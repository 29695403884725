import { IntermediaryMapTemplate } from './types';
import { getName } from '../helpers';

const MapTemplate = (id: string, lang: string): IntermediaryMapTemplate => ({
  mapboxStyle: 'mapbox://styles/mapbox/outdoors-v12',
  thumbnailUrl: '/img/base-layer-thumbnails/outdoors-v12.jpg',
  name: getName(id, lang),
  providerId: 'mapbox',
  dark: false,
  maxzoom: 19,
});

export default MapTemplate;
