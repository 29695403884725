import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    width: '100%',
    maxHeight: '100vh',
    flex: 1,
    overflowY: 'auto'
  },
  page: {
    height: 'auto'
  },
  header: {
    display: 'flex',
    padding: theme.spacing(6, 8, 4),
    textAlign: 'left',
    borderBottom: theme.border.default,
    '& h1': {
      color: theme.palette.common.offBlack,
      fontWeight: '500',
      fontSize: '1.7rem',
      marginBottom: theme.spacing(1)
    },
    '& p': {
      fontWeight: '500'
    }
  },
  headerText: {
    width: '100%',
    display: 'flex',
    marginLeft: theme.spacing(4),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconWrapper: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.darkBlue,
    padding: '12px',
    borderRadius: '50px',
    width: '65px',
    height: '65px',
    '& svg': {
      display: 'block',
      fontSize: '2.5rem',
      width: '40px',
      height: '40px',
      lineHeight: '1',
      marginRight: '0px'
    }
  },
  body: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'left'
  },
  content: {
    padding: theme.spacing(8, 0),
    '&>h2': {
      marginTop: theme.spacing(0)
    }
  },
  contentSection: {
    paddingBottom: theme.spacing(4),
    '&:last-child': {
      paddingBottom: theme.spacing(0)
    }
  },
  h1: {
    fontSize: '1.6rem'
  },
  h2: {
    fontSize: '1.4rem',
    fontWeight: '500',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  h3: {
    fontSize: '1.2rem',
    fontWeight: '500',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  ol: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& ul': {
      marginTop: theme.spacing(2)
    },
    '& li': {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2)
      }
    }
  },
  ul: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& li': {
      listStyleType: 'disc'
    }
  },
}));
