import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  header: {
    position: 'relative',
    padding: theme.spacing(3, 4),
    borderBottom: theme.border.default
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0
  },
  list: {
    maxHeight: 500
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
    borderTop: theme.border.default
  },
  iconAlignment: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    '& span:hover': {
      backgroundColor: 'transparent!important'
    }
  },
  iconWrapper: {
    '& svg': {
      width: 28,
      height: 28,
    },
    '& .icon-base': {
      fill: theme.palette.common.lightGrey
    },
    '& .icon-detail': {
      fill: theme.palette.common.text
    }
  }
}));
