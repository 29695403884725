import React, { ReactNode } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Box, Collapse, Stack } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

interface VirtualizedScrollListProps {
  ids: number[]
  renderItem: (id: number, index: number) => ReactNode
  scrollElement: HTMLElement | null
}

const VirtualizedScrollList = ({ ids, renderItem, scrollElement }: VirtualizedScrollListProps): JSX.Element => {
  const rowVirtualizer = useVirtualizer({
    count: ids.length,
    getScrollElement: () => scrollElement,
    estimateSize: () => 60,
    overscan: 10,
  });

  return (
    <Box
      sx={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        width: '100%',
        position: 'relative',
      }}
    >
      {rowVirtualizer.getVirtualItems().map(virtualRow => (
        <Box
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={rowVirtualizer.measureElement}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualRow.start}px)`
          }}
        >
          {renderItem(ids[virtualRow.index], virtualRow.index)}
        </Box>
      ))}
    </Box>
  );
};

interface ScrollListProps {
  ids: number[]
  renderItem: (id: number, index: number) => ReactNode
  empty?: ReactNode
  height: string
  virtualizedThreshold?: number
}
const ScrollList = ({ ids, renderItem, empty, height, virtualizedThreshold = 50 }: ScrollListProps): JSX.Element => {
  const [scrollElement, setScrollElement] = React.useState<HTMLDivElement | null>(null);

  return (
    <Box
      ref={element => setScrollElement(element as HTMLDivElement)}
      sx={{
        height,
        overflowY: 'auto',
        contain: 'strict',
        '::-webkit-scrollbar-thumb': {
          bgcolor: 'common.scrollBar',
        },
      }}
    >
      {ids.length > virtualizedThreshold ? <VirtualizedScrollList ids={ids} renderItem={renderItem} scrollElement={scrollElement} /> : (
        <Stack>
          <TransitionGroup>
            {ids.map((id, index) => (
              <Collapse key={id}>
                {renderItem(id, index)}
              </Collapse>
            ))}
            {!ids.length && empty}
          </TransitionGroup>
        </Stack>
      )}
    </Box>
  );
};

export default ScrollList;
