import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import ColorInput from 'components/shared/forms/inputs/color';
import { SUGGESTED_ASSET_COLORS } from 'constants/colors';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { AnnotationPath, DEFAULT_PATH } from 'slices/map/annotations.slice';

interface EditPathAnnotationDialogProps {
  open: boolean
  title: string
  path?: AnnotationPath
  onClose: () => void
  onSubmit: (path: AnnotationPath) => void
  isLoading: boolean
}

export const EditPathAnnotationDialog = ({
  open,
  title,
  path,
  onClose,
  onSubmit,
  isLoading
}: EditPathAnnotationDialogProps) => {
  const t = useTranslations('dialogs.annotations');

  const [name, setName] = useState<string>(path?.name ?? DEFAULT_PATH.name);
  const [description, setDescription] = useState<string>(path?.description ?? DEFAULT_PATH.description);
  const [colour, setColour] = useState<string>(path?.colour ?? DEFAULT_PATH.colour);

  const validationResult = useMemo(() => {
    const value = {
      name: !name ? t('errors.name') : (name.length > 20 ? t('errors.nameLength') : undefined),
      description: description && description.length > 250 ? t('errors.descriptionLength') : undefined,
      any: false
    };
    value.any = !!value.name || !!value.description;
    return value;
  }, [name, description, t]);

  const handleSubmit = useCallback(() => {
    if (validationResult.any) {
      return;
    }

    onSubmit({
      ...DEFAULT_PATH,
      ...path,
      name,
      description,
      colour
    });
  }, [onSubmit, validationResult, path, name, description, colour]);

  const onEnter = () => {
    setName(path?.name ?? DEFAULT_PATH.name);
    setDescription(path?.description ?? DEFAULT_PATH.description);
    setColour(path?.colour ?? DEFAULT_PATH.colour);
  };

  const onExited = () => {
    setName('');
    setDescription('');
    setColour(DEFAULT_PATH.colour);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onEnter,
        onExited,
      }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('fields.name')}
            value={name}
            onChange={event => setName(event.target.value)}
            error={!!validationResult.name}
            helperText={validationResult.name}
            fullWidth
          />
          <TextField
            label={t('fields.description')}
            value={description}
            onChange={event => setDescription(event.target.value)}
            error={!!validationResult.description}
            helperText={validationResult.description}
            multiline
            fullWidth
          />
          <ColorInput
            palette={SUGGESTED_ASSET_COLORS}
            value={colour}
            onChange={setColour}
            preview={<></>}
            readOnly={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>{t('actions.cancel')}</Button>
        <Button onClick={handleSubmit} disabled={isLoading}>{t('actions.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPathAnnotationDialog;
