import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  assetWarning: {
    width: '100%',
    backgroundColor: theme.palette.common.midGrey,
    padding: '10px 0'
  },
  messages: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    overflowY: 'scroll'
  },
  newMessage: {
    width: '100%',
    padding: theme.spacing(3),
    borderTop: theme.border.default
  },
  newMessageText: {
    width: '100%',
    fontSize: '1rem'
  },
  charCountSendDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  charCount: {
    fontSize: '.8rem',
    color: theme.palette.common.text
  },
  sendButton: {
    fontSize: '.8rem',
    textTransform: 'capitalize',
    padding: theme.spacing(2, 5),
    boxShadow: 'none',
    marginTop: '0px',
    marginLeft: theme.spacing(1)
  },
  loadingWrapper: {
    padding: '3px',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: '30px',
    left: '10%'
  },
  loadingIndicator: {
    backgroundColor: 'black',
    color: 'white',
    width: '100px',
    padding: '5px',
    borderRadius: '10px',
    float: 'right'
  }
}));

export default useStyles;
