import { createUseDependency, DependencyInjectionContainer } from 'utils/dependency-injection-container';
import type { Store } from 'redux';
import type { Persistor } from 'redux-persist';
import type { FirebaseService } from 'firebaseApp/firebase-service';

interface Injectables {
  reduxStore: Store
  reduxStorePersistor: Persistor
  firebaseService: FirebaseService
}

export const di = new DependencyInjectionContainer<Injectables>();

export const useInjectedDependency = createUseDependency(di);
