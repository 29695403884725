import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@mui/material';
import Icon from 'components/shared/icons/eventIcons';
import useStyles from './filter-styles';

const EventListItem = ({
  iconType,
  title,
  selected,
  onSelect
}) => {
  const classes = useStyles();

  return (
    <ListItem dense button onClick={() => onSelect(!selected)}>
      <ListItemIcon className={classes.iconWrapper}>
        <Icon type={iconType} />
      </ListItemIcon>
      <ListItemText primary={title} />
      <ListItemIcon className={classes.iconAlignment}>
        <Checkbox
          edge="end"
          tabIndex={-1}
          checked={selected}
          disableRipple
        />
      </ListItemIcon>
    </ListItem>
  );
};

export default EventListItem;
