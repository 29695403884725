import { serenityFetch, HttpResponseError } from "helpers/api";
import { UserHistory } from "./userHistory-types";
import { dateRangeParams } from "workers/fetchReports/utils";

export const getUserHistories = async (organisationId: string, from: number, until: number, isStaff = false): Promise<UserHistory[]> => {
  const params = dateRangeParams(from, until);
  params.set('isStaff', isStaff.toString());

  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/users/history?${new URLSearchParams(params)}`,
    null,
    true
  );
  HttpResponseError.detect(response);
  return (await response.json());
};
