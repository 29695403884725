import React, { cloneElement, forwardRef, HTMLAttributes, ReactElement, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

export const VirtualizedListbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(({ children, className, onMouseDown, ...otherProps }, ref) => {
  const scrollParent = useRef<HTMLDivElement>();
  const items = (children as ReactElement[]) ?? [];

  const virtualizer = useVirtualizer({
    count: items.length,
    estimateSize: () => 32,
    getScrollElement: () => scrollParent.current ?? null,
    overscan: 10,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={element => {
        scrollParent.current = element ?? undefined;
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      }}
      className={className}
      onMouseDown={onMouseDown}
    >
      <div
        style={{
          position: 'relative',
          height: virtualizer.getTotalSize(),
        }}
      >
        <ul
          {...otherProps}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map(virtualItem => cloneElement(items[virtualItem.index], {
            key: virtualItem.key,
            'data-index': virtualItem.index,
            ref: virtualizer.measureElement,
          }))}
        </ul>
      </div>
    </div>
  );
});
