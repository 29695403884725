/**
 * Note: we cannot use the regular pattern for accessing the application theme
 * here, as the ErrorBoundary is higher up the food chain than the ThemeProvider.
 */

const white = '#fff';
const blue = '#2A93D5';
const darkBlue = '#0d5189';

const classes = () => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    height: '100vh',
    overflowY: 'auto'
  },
  message: {
    backgroundColor: darkBlue,
    color: white,
    padding: '100px'
  },
  logoWrapper: {
    position: 'absolute',
    top: '60px',
    left: '100px',
    width: '180px',
  },
  logo: {
    width: '4rem',
    height: '4rem'
  },
  imageWrapper: {
    position: 'relative',
    overflow: 'hidden'
  },
  image: {
    backgroundImage: 'url(/img/landscape-illustration.jpg)',
    backgroundSize: 'cover',
    height: '100%'
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    height: '100%',
    fontSize: '16px',
    color: 'rgba(255,255,255,0.8)',
    textAlign: 'left'
  },
  h3: {
    textAlign: 'left',
    color: white,
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: '18px'
  },
  text: {
    textAlign: 'left',
    color: white,
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '14px'
  },
  buttonsWrapper: {
    marginTop: '20px',
    display: 'flex'
  },
  reportButton: {
    fontSize: '14px',
    fontFamily: [
      'objektiv-mk2,sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontWeight: '500',
    color: '#fff',
    textDecoration: 'none',
    padding: '12px 30px',
    marginRight: '15px',
    marginBottom: '5px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: blue
  },
  refreshButton: {
    fontSize: '14px',
    fontFamily: [
      'objektiv-mk2,sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontWeight: '500',
    color: darkBlue,
    textDecoration: 'none',
    padding: '12px 30px',
    marginRight: '15px',
    marginBottom: '5px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: white
  },
  resetButton: {
    fontSize: '14px',
    fontFamily: [
      'objektiv-mk2,sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontWeight: '500',
    color: darkBlue,
    textDecoration: 'none',
    padding: '12px 30px',
    marginBottom: '5px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: white
  },
  linkButton: {
    display: 'flex',
    textDecoration: 'none'
  }
});

export default classes;
