import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import LimitedList from 'components/shared/LimitedList';
import React from 'react';
import { useTranslations } from 'use-intl';
import { useAssetsNotificationsStatus } from './helpers';
import { ErrorOutlined } from '@mui/icons-material';
import { useAssetLabel } from 'components/shared/assetLabel';

interface AssetGroupLabelProps {
  assetGroup: AssetGroup
}

const Assets: React.FC<{ assets: AssetBasic[] }> = ({ assets }) => {
  const t = useTranslations('pages.manage.eventNotifications.edit.assetsSection');
  const assetLabel = useAssetLabel();
  const assetsStatus = useAssetsNotificationsStatus()(assets);

  if (!assets.length) return null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {assetsStatus.enabled.length > 0 && (
        <Typography variant="body3">
          <LimitedList<string>
            items={assetsStatus.enabled.map(a => assetLabel(a)).filter((s): s is string => !!s)}
            limit={2}
            renderMore={({ items, children }) => (
              <Tooltip title={items.map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}>
                <Chip label={children} variant="outlined" />
              </Tooltip>
            )}
          />
        </Typography>
      )}
      {!assetsStatus.enabled.length && (
        <Chip
          icon={<ErrorOutlined />}
          label={t('columns.noAssetsEnabled', { n: assetsStatus.disabled.length })}
          variant="outlined"
          color="error"
        />
      )}
      {assetsStatus.disabled.length > 0 && (
        <Tooltip title={(
          <>
            <div>{t('tooltips.assetsDisabled')}:</div>
            {assetsStatus.disabled.map(a => assetLabel(a)).filter((s): s is string => !!s).map((name, i) => <div key={`tooltip-${i}`}>{name}</div>)}
          </>
        )}>
          <Chip
            icon={<ErrorOutlined />}
            label={t('columns.nAssetsDisabled', { n: assetsStatus.disabled.length })}
            variant="outlined"
            color="warning"
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export const AssetGroupLabel = ({ assetGroup }: AssetGroupLabelProps): JSX.Element => {
  const t = useTranslations('pages.manage.eventNotifications.edit.assetGroupsDialog');

  return (
    <Stack direction="row" flex={1} alignItems="center">
      <Stack direction="row" spacing={2} flex={1} alignItems="center">
        <Stack>
          <Typography mb={1/2}>{assetGroup?.name}</Typography>
          {!!assetGroup?.assets.length && <Assets assets={assetGroup.assets} />}
          {assetGroup && !assetGroup.assets.length && <Typography variant="body3">{t('countLabel', { count: 0 })}</Typography>}
        </Stack>
      </Stack>
    </Stack>
  );
};
