import React, { ReactNode, useMemo } from 'react';
import { useTranslations } from 'use-intl';
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Link, LinkProps } from 'react-router-dom';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import { useGetMessagingWhitelistContactGroups } from 'apis/rest/messagingWhitelistContactGroups/hooks';
import { PersonBackLinkState } from 'helpers/location';

interface GroupProps<S> {
  group: ContactGroup
  type: ReactNode
  linkProps: Pick<LinkProps, 'to'> & { state: S }
}

const Group = <S, >({ type, group, linkProps }: GroupProps<S>) => {
  const t = useTranslations('pages.manage.person.groups');
  return (
    <TableRow>
      <TableCell><Typography>{type}</Typography></TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{group.name}</Typography>
          {group.isDefault && <Chip label={t('default')} color="primary" />}
        </Stack>
      </TableCell>
      <TableCell width={0} align="right">
        <IconButton component={Link} {...linkProps}>
          <Edit sx={{ color: 'common.text' }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface GroupsProps {
  person: Person
}

const Groups = ({ person }: GroupsProps) => {
  const t = useTranslations('pages.manage.person.groups');
  const { query: iceGroupsQuery } = useGetIceContactGroups();
  const { query: messagingWhitelistGroupsQuery } = useGetMessagingWhitelistContactGroups();

  const iceGroups = useMemo(
    () => iceGroupsQuery.data?.filter(group => group.peopleWithOrder.some(x => x.personId === person.id)) ?? [],
    [iceGroupsQuery.data, person.id],
  );
  const messagingWhitelistGroups = useMemo(
    () => messagingWhitelistGroupsQuery.data?.filter(group => group.peopleWithOrder.some(x => x.personId === person.id)) ?? [],
    [messagingWhitelistGroupsQuery.data, person.id],
  );

  let content: React.ReactNode;

  if (iceGroupsQuery.isLoading || messagingWhitelistGroupsQuery.isLoading) {
    content = (
      <DetailPanel>
        <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
          <Stack justifyContent="center">
            <Typography>{t('loading')}</Typography>
          </Stack>
        </Stack>
      </DetailPanel>
    );
  } else if (iceGroups.length || messagingWhitelistGroups.length) {
    content = (
      <DetailPanel pt={0} spacing={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('columns.groupType')}</TableCell>
                <TableCell>{t('columns.group')}</TableCell>
                <TableCell width={0} />
              </TableRow>
            </TableHead>
            <TableBody sx={{ 'tr:last-child td, tr:last-child th': { border: 0 } }}>
              {iceGroups.map(group => (
                <Group
                  key={group.id}
                  type={t('types.ice')}
                  group={group}
                  linkProps={{
                    to: `/manage/ice/groups/${group.id}`,
                    state: new PersonBackLinkState(person.id),
                  }}
                />
              ))}
              {messagingWhitelistGroups.map(group => (
                <Group
                  key={group.id}
                  type={t('types.messagingWhitelist')}
                  group={group}
                  linkProps={{
                    to: `/manage/device-messaging/groups/${group.id}`,
                    state: new PersonBackLinkState(person.id),
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DetailPanel>
    );
  } else {
    content = (
      <DetailPanel>
        <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
          <Stack justifyContent="center">
            <Typography>{t('noGroups', { person: person.name })}</Typography>
          </Stack>
        </Stack>
      </DetailPanel>
    );
  }

  return (
    <SettingsSection
      title={t('title')}
      description={t('description')}
    >
        {content}
    </SettingsSection>
  );
};

export default Groups;
