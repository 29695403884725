import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Stack,
  Typography,
} from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import usePermissions from 'hooks/session/usePermissions';
import DeleteContactGroupDialog, { DeleteContactGroupDialogStatus } from 'components/dialogs/contactGroups/deleteGroup';
import { DeleteContactGroupDialogProps } from 'components/dialogs/contactGroups/deleteGroup/deleteGroup-dialog';
import { NotificationRulesDialog } from 'components/pages/manage/eventNotifications/components/edit/rulesDialog';
import { EventNotificationRule } from 'apis/rest/eventNotifications/types';
import {
  useGetNotificationRulesByContact,
  useMutateUpdateEventNotificationRule,
} from 'apis/rest/eventNotifications/hooks';
import { useGetPeopleGroups } from 'apis/rest/peopleGroups/hooks';
import { ContactGroupWithPeople } from 'components/pages/manage/eventNotifications/components/edit/types';
import { useGetPeople } from 'apis/rest/people/hooks';
import { useGetEventCodeGroups, useGetEventCodes } from 'apis/rest/eventCodes/hooks';
import { Edit } from '@mui/icons-material';
import { Localization, MaterialTableProps } from '@material-table/core';
import { Box } from '@mui/system';
import PersistentTable from 'components/shared/persistentTable';
import StickyPagination from 'components/shared/stickyPagination';
import { NotificationRuleTableItem } from './types';

export const DeletePeopleGroupDialog = ({ group, mutation, onClose, ...props }: Omit<DeleteContactGroupDialogProps, 'title' | 'ariaLabel' | 'children'>): JSX.Element => {
  const t = useTranslations('dialogs.peopleGroups');
  const tRules = useTranslations('pages.manage.eventNotifications.edit.rulesSection');
  const snackbar = useSnackbar();
  const updateRuleMutation = useMutateUpdateEventNotificationRule();
  const permissions = usePermissions();
  const contactGroupsQuery = useGetPeopleGroups();
  const peopleQuery = useGetPeople();
  const eventCodes = useGetEventCodes();
  const eventCodeGroups = useGetEventCodeGroups();
  const rulesQuery = useGetNotificationRulesByContact(group.id);
  const footerRef = useRef<HTMLElement>();
  const Pagination = useCallback(p => <StickyPagination container={footerRef.current} {...p} />, []);

  const [selectOpen, setSelectOpen] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState<EventNotificationRule>();

  const contactTypeOptions: Record<string, string>[] = useMemo(() => [
    { key: 'email', value: tRules('rulesDialog.options.transport.email') },
    { key: 'sms', value: tRules('rulesDialog.options.transport.sms') },
    // { key: 'notification', value: t('rulesDialog.options.transport.notification') },
    // { key: 'mobile', value: t('rulesDialog.options.transport.mobile') }
  ], [tRules]);

  const daysOfWeekOptions: Record<string, string>[] = useMemo(() => [
    { key: 'MON', value: tRules('rulesDialog.options.dayOfWeek.mon') },
    { key: 'TUE', value: tRules('rulesDialog.options.dayOfWeek.tue') },
    { key: 'WED', value: tRules('rulesDialog.options.dayOfWeek.wed') },
    { key: 'THU', value: tRules('rulesDialog.options.dayOfWeek.thu') },
    { key: 'FRI', value: tRules('rulesDialog.options.dayOfWeek.fri') },
    { key: 'SAT', value: tRules('rulesDialog.options.dayOfWeek.sat') },
    { key: 'SUN', value: tRules('rulesDialog.options.dayOfWeek.sun') },
  ], [tRules]);

  const columns = useMemo<MaterialTableProps<NotificationRuleTableItem>['columns']>(() => ([
    {
      title: t('columns.name'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      defaultSort: 'asc',
      render: row => (<Typography>{row.name}</Typography>)
    },
    {
      title: t('columns.warning'),
      field: 'name',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      sorting: false,
      render: row => (<Typography>{row.contactGroupsCount > 1 ? '' : t('delete.lastGroupWarning')}</Typography>)
    }
  ]), [t]);

  const localization: Localization = { header: { actions: '' } };
  const rules = rulesQuery.query.data;
  const isLoading = rulesQuery.query.isLoading || rulesQuery.query.isRefetching;
  const groupType = t('groupTypes.contact');

  const rowData: NotificationRuleTableItem[] = useMemo(() => rules?.map(rule => {
    const item: NotificationRuleTableItem = {
      id: rule.id,
      name: rule.name,
      contactGroupsCount: rule.peopleGroupIds.length,
    };
    return item;
  }) ?? [], [rules]);

  const contactGroups: ContactGroupWithPeople[] = useMemo(() => contactGroupsQuery.query.data?.map(
    c => ({ ...c, people: peopleQuery.query.data?.filter(p => c.peopleWithOrder.some(po => po.personId === p.id)) ?? [] })
  ) ?? [], [contactGroupsQuery.query.data, peopleQuery.query.data]);

  const onRowOpen = useCallback((id: number | undefined) => {
    const rule = rules?.find(r => r.id === id);
    if (rule) {
      setRuleToEdit(rule);
      setSelectOpen(true);
    }
  }, [rules]);

  const actions = useMemo<MaterialTableProps<NotificationRuleTableItem>['actions']>(() => [
    row => ({
      icon: () => <Edit sx={{ color: 'common.text' }} />,
      tooltip: t('tooltips.edit'),
      onClick: () => onRowOpen(row.id),
    }),
  ], [t, onRowOpen]);

  const onEditDialogClose = useCallback(() => setSelectOpen(false), []);

  const onEditDialogSave = useCallback((rule: EventNotificationRule | undefined) => {
    if (!rule) { return; }
    if (rule.id) {
      updateRuleMutation.mutate({
        notificationRule: rule
      }, {
        onSuccess: () => {
          setSelectOpen(false);
        },
        onError: error => {
          snackbar.display({
            id: `notificationRule.update.error.${rule.id}`,
            text: tRules('snackbar.updateError'),
            type: 'error'
          });
        },
      });
    }
  }, [snackbar, tRules, updateRuleMutation]);

  return (
    <DeleteContactGroupDialog
      {...props}
      group={group}
      mutation={mutation}
      title={t('delete.title', { groupType })}
      ariaLabel={t('delete.title', { groupType })}
      onClose={(status, id) => {
        if (status === DeleteContactGroupDialogStatus.Removed) {
          snackbar.display({
            id: `peopleGroup.Deleted.${group.id}`,
            text: t('delete.snackbar.success', { groupType, name: group.name }),
            type: 'success',
          });
        }
        onClose(status, id);
      }}
      dialogSize="md"
      canDelete={!rules || rules?.length === 0}
    >
      <Typography>
        {t.rich('delete.message', { name: group.name, strong: chunks => <strong>{chunks}</strong> })}
      </Typography>
      {rules && rules?.length > 0 && (
      <Stack spacing={3}>
        <Alert severity="error">{t('delete.deleteAlert')}</Alert>
        <PersistentTable<NotificationRuleTableItem>
          settingsCategory="eventNotitficationGroupsTable"
          isLoading={isLoading}
          components={{
            Pagination, Container: Box, Toolbar: () => null
          }}
          data={rowData}
          columns={columns}
          actions={actions}
          localization={localization}
          onRowClick={(_, row) => onRowOpen(row?.id)}
          options={{
            search: false, draggable: false, showTitle: false, actionsColumnIndex: -1, paging: true, emptyRowsWhenPaging: false, headerStyle: { position: 'sticky', top: 0 }
          }}
          sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }} />
        <Box ref={footerRef} bottom={0} sx={{
          '& .MuiToolbar-regular': { padding: 10 / 3 },
          margin: '0 !important'
        }} />
      </Stack>
      )}
      <NotificationRulesDialog
        isReadOnly={!permissions.canEditEventNotifications}
        open={selectOpen ?? false}
        isSaving={updateRuleMutation.isPending || isLoading}
        rule={ruleToEdit}
        title={<Typography>{t('delete.manageRule', { name: ruleToEdit?.name })}</Typography>}
        ariaLabel="Manage contact groups in notification group"
        peopleGroups={contactGroups ?? []}
        eventCodes={eventCodes.data?.filter(e => e.types.some(ty => ty === 'NOTIFICATION')) ?? []}
        eventGroups={eventCodeGroups.data ?? []}
        contactTypeOptions={contactTypeOptions}
        daysOfWeekOptions={daysOfWeekOptions}
        onClose={onEditDialogClose}
        onSave={onEditDialogSave}
        displayType="minimal"
        />
    </DeleteContactGroupDialog>
  );
};
