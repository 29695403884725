export const searchFilter = (user: Membership, filterText: string): boolean => {
  const parts = user.name.toLowerCase().split(' ');
  return parts.some(part => part.startsWith(filterText.toLowerCase()))
    || (!!user.email && user.email.startsWith(filterText.toLowerCase()));
};

export const getFilteredUsers = (users: Membership[], assignedUsers: Membership[], filterText: string) => [...users]
  .sort((a, b) => a.name?.localeCompare(b.name))
  .filter(m => !assignedUsers.includes(m) && m.name && searchFilter(m, filterText));

export const selectNonUsercodeUsers = (users: Membership[]): Membership[] => users.filter(user => user.userId !== user.organisationId);
