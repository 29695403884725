import { useCallback } from 'react';
import { updateSetting } from 'slices/settings.slice';
import { useAppDispatch } from 'store/types';
import type { SerialType } from 'slices/settings.slice';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { updateLocalUiSettingsField, updateUiSettingField } from 'slices/settings/uiSettings.slice';

const useSetSerialType = () => {
  const dispatch = useAppDispatch();
  const isUsercodeUser = useIsUsercodeLogin();

  return useCallback((value: SerialType) => {
    if (isUsercodeUser) {
      dispatch(updateSetting({ category: 'ui', field: 'serialType', value }));
      dispatch(updateLocalUiSettingsField({ field: 'serialType', value }));
    } else {
      dispatch(updateUiSettingField({ field: 'serialType', value }));
    }
  }, [dispatch, isUsercodeUser]);
};

export default useSetSerialType;
export type { SerialType };
