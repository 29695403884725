import React from 'react';

const Shutdown = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.99,4.51H5.01c-0.3,0-0.5,0.2-0.5,0.5v5.98c0,0.3,0.2,0.5,0.5,0.5h5.98c0.3,0,0.5-0.2,0.5-0.5V5.01
C11.49,4.71,11.29,4.51,10.99,4.51z"
    />
  </svg>
);

export default Shutdown;
