import React from 'react';

const GeofenceSuspend = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.89,9.31l-0.53-2.63h0.26V4.94H8.88v0.44H7.12V4.94H5.37v1.75h0.26L5.11,9.31H4.5v1.75h1.75v-0.44h3.5v0.44
        h1.75V9.31H10.89z M6.25,9.75V9.31H5.99l0.53-2.63h0.61V6.25h1.75v0.44h0.61l0.53,2.63H9.75v0.44H6.25z"
    />
  </svg>
);

export default GeofenceSuspend;
