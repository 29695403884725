import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loggedOut, resetEverything } from 'slices/session/session.slice';

type LegsState = Record<string, Leg[]>;

const initialState: LegsState = {};

const legsSlice = createSlice({
  name: 'legs',
  initialState,
  reducers: {
    setAssetLegs: (state, action: PayloadAction<{ id: string, legs: Leg[] }>) => {
      const { id, legs: newLegs } = action.payload;
      const updatedLegs = state[id]?.length ? state[id].concat(newLegs) : newLegs;
      state[id] = updatedLegs;
    },
    deleteAsset: (state, action: PayloadAction<{ id: string }>) => {
      const { id: assetId } = action.payload;
      delete state[assetId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggedOut, () => initialState)
      .addCase(resetEverything, () => initialState);
  },
});

export const { setAssetLegs, deleteAsset } = legsSlice.actions;

export default legsSlice.reducer;
