import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { getSelectedDay } from 'slices/app.slice';
import useTimezone from './session/useTimezone';

export const useStartOfDay = () => {
  const selectedDay = useSelector(getSelectedDay);
  const timezone = useTimezone();
  return useMemo(
    () => (
      selectedDay
        ? DateTime.fromISO(selectedDay, { zone: timezone })
        : DateTime.now().setZone(timezone).startOf('day')
    ),
    [selectedDay, timezone]
  );
};
