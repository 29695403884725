import { HttpResponseError, serenityFetch } from 'helpers/api';
import { Rock7Config, Rock7ConfigBlock, Rock7ConfigSpec } from './types';

export const fetchRock7ConfigSpec = async (organisationId: string): Promise<Rock7ConfigSpec> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/devices/configuration/rock7`, null);
  HttpResponseError.detect(response);
  return response.json();
};

export const updateRock7Config = async (organisationId: string, deviceId: number, config: Rock7Config) => {
  const configObject = {
    configuration: Object.entries(config)
      .reduce<Rock7Config>((blockAcc, [blockKey, block]) => {
        blockAcc[blockKey] = Object.entries(block)
          .reduce<Rock7ConfigBlock>((acc, [configKey, value]) => {
            if (value === true) {
              acc[configKey] = 'true';
            } else if (value === false) {
              acc[configKey] = 'false';
            } else if (!Number.isNaN(Number(value)) && value !== '') {
              acc[configKey] = `${value}`;
            } else if (value && value !== '') {
              acc[configKey] = value;
            }
            return acc;
          }, {});
        return blockAcc;
      }, {})
  };

  const response = await serenityFetch('PATCH', `/organisations/${organisationId}/devices/${deviceId}/configuration`, configObject);
  HttpResponseError.detect(response);
};
