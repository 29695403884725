import React, { useState } from 'react';

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  LinearProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@mui/material';
import { Collections as CollectionsIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { uploadAssetImage, removeAssetImage } from 'apis/assetImages';
import clsx from 'clsx';
import { ClassNameMap } from '@mui/styles';
import { useTranslations } from 'use-intl';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.min.css'; // force import because react-cropper didn't like to?
import SettingsSection from 'components/shared/settingsSection';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';

const UploadImageButton = ({ text, classes }:{text:string; classes: ClassNameMap}):JSX.Element => (
  <Button
    variant="contained"
    startIcon={<CollectionsIcon />}
    disableRipple
    classes={{
      root: classes.uploadImageButton,
      contained: classes.uploadImageButton
    }}
    component="label"
    htmlFor="assetImageUpload"
  >
    {text}
  </Button>
);

const RemoveImageButton = ({ text, classes, onClick }:{text:string; classes: ClassNameMap, onClick: () => void}):JSX.Element => (
  <Button
    variant="contained"
    startIcon={<DeleteIcon />}
    disableRipple
    classes={{
      root: classes.removeImageButton,
      contained: classes.removeImageButton
    }}
    component="label"
    onClick={() => onClick()}
  >
    {text}
  </Button>
);

interface AssetImageProps {
  classes: ClassNameMap,
  imageUrl: string | undefined | null,
  assetId: number,
  isLoading: boolean,
  canEditAsset: boolean,
  onUpload: (success:boolean) => void,
  onRemove: (success: boolean) => Promise<void>
}

const AssetImage = ({
  classes,
  imageUrl,
  isLoading,
  assetId,
  canEditAsset,
  onUpload,
  onRemove
}:AssetImageProps): JSX.Element => {
  const [uploading, setUploading] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [cropper, setCropper] = useState<Cropper>();

  const ASPECT_RATIO = 300 / 160;

  const t = useTranslations('pages.assetView');

  const upload = (blob: Blob | null): void => {
    if (!canEditAsset) { return; }
    if (!blob) { onUpload(false); return; }
    setProgress(0);
    const uploadTask = uploadAssetImage(assetId, blob);
    uploadTask.on('state_changed', snapshot => {
      setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      if (snapshot.state === 'running' && !uploading) {
        setUploading(true);
      }
    },
    () => {
      onUpload(false);
    },
    () => {
      setUploading(false);
      onUpload(true);
    });
  };

  const cropAndUpload = (): void => {
    setCropping(false);
    cropper?.getCroppedCanvas().toBlob(upload, 'image/jpeg', 0.8);
  };

  const remove = async (): Promise<void> => {
    setRemoving(true);
    await removeAssetImage(assetId)
      .then(() => onRemove(true))
      .catch(() => onRemove(false));
    setRemoving(false);
  };

  const handleAssetFileChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files?.length) {
      // if (event.target.files[0].size > 10000000) {
      // onUpload(false);
      // return false;
      // }
      // upload(event.target.files[0]);
      const fr = new FileReader();
      fr.onload = () => {
        setImageDataUrl(fr.result?.toString());
      };
      fr.readAsDataURL(event.target.files[0]);
      setCropping(true);
    }
    return true;
  };

  return (
    <SettingsSection title={t('imageTitle')} description={t('imageDescription')}>
      <input type="file" accept={'image/*'} hidden id="assetImageUpload" onChange={handleAssetFileChange} disabled={!canEditAsset} />
      <Card variant="outlined">
        <Box className={clsx(classes.imagePanel, { [classes.imagePanelPopulated]: !!imageUrl && !isLoading })}>
          {(isLoading || removing)
            ? (
              <CardContent className={classes.imageIcon}>
                <LoadingIcon size={50} />
              </CardContent>
            )
            : (imageUrl ? (
              <CardMedia
                className={clsx(classes.media, classes.imageIcon)}
                image={imageUrl}
              >
                {canEditAsset && (
                <>
                  <UploadImageButton text={t('replaceImage')} classes={classes} />
                  <RemoveImageButton text={t('removeImage')} classes={classes} onClick={remove} />
                </>
                )}
              </CardMedia>
            ) : (
              <CardContent className={classes.imageIcon}>
                {canEditAsset ? <UploadImageButton text={t('uploadImage')} classes={classes} /> : <CollectionsIcon /> }
              </CardContent>
            )
            )}
        </Box>
        {uploading
          && <LinearProgress variant="determinate" color="primary" value={progress} />}
      </Card>
      <Dialog open={cropping}>
        <DialogTitle>{t('cropImageDialogTitle')}</DialogTitle>
        <DialogContent className={classes.cropDialog}>
          {imageDataUrl
            && (
            <ReactCropper
              aspectRatio={ASPECT_RATIO}
              src={imageDataUrl}
              viewMode={2}
              onInitialized={setCropper}
              autoCrop
              cropBoxResizable
              cropBoxMovable
              dragMode="move"
            />
            )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setCropping(false)}>
            {t('cancelButton')}
          </Button>
          <Button variant="contained" onClick={cropAndUpload} color="primary">
            {t('uploadImage')}
          </Button>
        </DialogActions>
      </Dialog>
    </SettingsSection>
  );
};

export default AssetImage;
