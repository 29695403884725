import React from 'react';

const B212 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 32.621094 3.59375 C 32.442572 3.590592 32.297476 3.5974824 32.201172 3.5996094 C 31.430743 3.6166195 27.631797 3.3200355 27.185547 9.4472656 C 27.076267 10.947752 26.775571 13.163873 26.800781 13.685547 C 26.825984 14.20722 25.705292 14.66718 25.53125 18.917969 C 25.493608 19.837324 25.677407 21.016398 25.962891 22.251953 L 3.7226562 34.570312 C 3.1309143 34.898034 2.8062337 35.615207 2.9140625 36.275391 C 2.9294665 36.369691 2.9527602 36.462119 2.9863281 36.552734 L 3.2441406 37.251953 C 3.5126842 37.976877 4.2748955 38.257353 4.9511719 37.882812 L 26.945312 25.701172 C 27.668915 27.89908 28.398438 29.658203 28.398438 29.658203 C 28.398438 29.658203 28.818668 32.98435 28.976562 33.0625 C 29.160768 33.153666 29.617188 33.162109 29.617188 33.162109 L 30.035156 43.082031 C 30.035156 43.082031 25.299058 42.948564 25.298828 43.150391 C 25.298608 43.352217 25.372187 47.604622 25.367188 47.880859 C 25.36218 48.157098 30.396484 48.017578 30.396484 48.017578 C 30.396484 48.017578 31.594086 60.840949 32.201172 60.792969 C 32.808256 60.744998 34.007812 48.017578 34.007812 48.017578 C 34.007812 48.017578 39.042109 48.157098 39.037109 47.880859 C 39.032109 47.604622 39.1057 43.352217 39.105469 43.150391 C 39.105238 42.948568 34.369141 43.082031 34.369141 43.082031 L 34.787109 33.162109 C 34.787109 33.162109 35.243528 33.15368 35.427734 33.0625 C 35.585628 32.984342 36.005859 29.658203 36.005859 29.658203 C 36.005859 29.658203 38.359701 23.993569 38.802734 20.261719 L 60.248047 8.3847656 C 60.924324 8.0102267 61.250967 7.1272667 60.982422 6.4023438 L 60.724609 5.703125 C 60.456067 4.978203 59.695808 4.6977266 59.019531 5.0722656 L 38.597656 16.380859 C 38.210242 14.432317 37.584603 14.076914 37.603516 13.685547 C 37.628718 13.163873 37.32803 10.947752 37.21875 9.4472656 C 36.828282 4.0859392 33.870749 3.615856 32.621094 3.59375 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default B212;
