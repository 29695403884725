import React from 'react';

const Cessna337 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 29.326872,12.820568 c -0.237647,3.479016 -0.581683,7.604493 -0.432618,11.093151 -1.875928,0.04835 -2.530118,0.01261 -5.013362,-0.0093 -5.227925,1.23993 -16.4049921,0.456257 -19.6913657,0.861126 -1.3712036,0.919871 -0.374216,5.911502 -0.374216,5.911502 l 19.4305277,2.49873 0.731253,11.373695 0.337408,8.216587 0.555069,-0.830762 14.132956,0.122707 -0.127762,-5.707253 -13.697576,0.08748 -0.206549,-12.134624 h 4.848528 c 0.02987,2.330962 1.013018,2.89872 2.251437,5.661026 1.084472,-2.745651 2.077292,-3.292448 2.230704,-5.661026 h 4.723527 l 0.105953,10.675644 -0.07343,1.395453 -13.667784,0.169462 -0.345963,5.401773 14.154777,-0.0077 0.707807,0.787119 0.424688,-8.238406 0.16876,-11.248695 19.993028,-2.37373 c 0,0 0.05949,-3.054132 -0.12421,-5.974001 -5.425728,-0.57478 -11.097848,-0.646402 -16.523406,-0.922843 -3.986597,0.304758 -5.118626,0.09276 -8.743833,0.133497 -0.400133,-3.920082 0.03344,-7.848117 -0.557616,-11.280651 -1.745573,-0.719994 -2.721078,-2.750453 -2.735297,-2.765773 -0.002,-0.01963 -0.735867,1.920785 -2.481432,2.765771 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Cessna337;
