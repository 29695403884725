import { combineReducers } from 'redux';
import { purgeStoredState, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import omniboxSettings from 'slices/settings/omniboxSettingsSlice';
import app from 'slices/app.slice';
import positions from './positions';
import map from '../slices/map.slice';
import settings from '../slices/settings.slice';
import ui from '../slices/ui.slice';
import legs from '../slices/legs.slice';
import incidents from '../slices/incidents.slice';
import assetGroups from '../slices/assetGroups.slice';
import labs from '../slices/labs.slice';
import statsFilter from '../slices/statsFilter.slice';
import markers from '../slices/markers.slice';
import searchPatterns from '../slices/searchPatterns.slice';
import inferredEvents from '../slices/map/inferredEvents.slice';
import session, { resetEverything } from '../slices/session/session.slice';
import manageGeofences from '../slices/manageGeofences.slice';
import mapSettings from '../slices/settings/mapSettings.slice';
import unitSettings from '../slices/settings/unitSettings.slice';
import uiSettings from '../slices/settings/uiSettings.slice';
import localeSettings from '../slices/settings/localeSettings.slice';
import multiPointMeasurement from '../slices/map/multiPointMeasurement.slice';
import reports from '../slices/report.slice';
import localMapObject from '../slices/map/annotations.slice';

const DEBUG = import.meta.env.DEV;

const purgeStore = () => {
  purgeStoredState({ key: 'app', storage });
  purgeStoredState({ key: 'map', storage });
  purgeStoredState({ key: session.key, storage });
  purgeStoredState({ key: 'settings', storage });
};

/**
 * @deprecated Just use persistReducer directly in relevant modules
 */
const wireUp = (config: any) => persistReducer({
  key: config.key,
  version: config.version,
  whitelist: config.whitelist,
  blacklist: config.blacklist,
  migrate: createMigrate(config.migrations, { debug: DEBUG }),
  stateReconciler: config.stateReconciler,
  storage,
}, config.reducer);

const rootReducer = combineReducers({
  app,
  positions,
  legs,
  map,
  session: wireUp(session),
  settings,
  ui,
  reports,
  incidents,
  assetGroups,
  labs,
  statsFilter: wireUp(statsFilter),
  markers,
  searchPatterns,
  inferredEvents,
  manageGeofences,
  unitSettings,
  mapSettings,
  uiSettings,
  localeSettings,
  omniboxSettings,
  multiPointMeasurement,
  localMapObject
});

export type TRootState = ReturnType<typeof rootReducer>;

export default () => {
  const allReducers = rootReducer;
  return (state: TRootState, action: any) => {
    if (action.type === resetEverything.type) {
      purgeStore();
    }
    return allReducers(state, action);
  };
};
