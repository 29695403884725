import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { ProvisioningProcessorRow } from '@tracplus/serenity-client';
import { useActivateProvisioningForDevice } from 'apis/rest/provisioning/hooks';
import useSnackbar from 'hooks/useSnackbar';
import useStyles from './provisioningList-styles';
import { ConfirmDialog } from '../assetSearch/components/ConfirmDialog';

export const DetailPanel = ({ row }: { row: ProvisioningProcessorRow }): JSX.Element => {
  const classes = useStyles();
  // @ts-ignore row.auditCreated is a ISO string
  const auditCreated = DateTime.fromISO(row.auditCreated).toFormat('yyyy/MM/dd hh:mm:ss');
  // @ts-ignore row.auditUpdated is a ISO string
  const auditUpdated = DateTime.fromISO(row.auditUpdated).toFormat('yyyy/MM/dd hh:mm:ss');
  const fields = [
    ['Asset ID', row.assetId],
    ['Device ID', row.deviceId],
    ['IMEI', row.deviceImei],
    ['Iridium Plan', row.deviceIridiumPlan],
    ['Gateway', row.deviceGateway],
    ['IMSI/ICCID', row.deviceSimIccid],
    ['Iridium Contract', row.iridiumContractId],
    ['Script', row.deviceScriptFirmware],
    ['Processing Row Created', auditCreated],
    ['Processing Row Updated', auditUpdated],
    ['Created by', row.auditUser],
  ];

  const snackbar = useSnackbar();
  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false);
  const activateMutation = useActivateProvisioningForDevice({
    onSettled: () => setActivateDialogOpen(false),
    onSuccess: () => snackbar.display({
      id: 'activate',
      text: 'Activating all waiting devices',
      type: 'success',
    }),
    onError: () => snackbar.display({
      id: 'activate',
      text: 'Error while activating waiting devices',
      type: 'error',
    }),
  });

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={12}>
          <Typography className={classes.detailPanelTitle}>More information</Typography>
          <List>
            {fields.map(([n, v]) => (
              <ListItem key={n} className={classes.listItem}>
                <Typography className={classes.label}>
                  {n}
                  :
                  {' '}
                </Typography>
                <Typography>{v}</Typography>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={() => setActivateDialogOpen(true)}
            disabled={row.status !== 'ACTIVATION_WAITING'}
          >
            Activate
          </Button>
        </Grid>
        <Grid item>
          <Typography className={classes.detailPanelTitle}>Errors/Warnings</Typography>
          {(row.errors?.length || row.warnings?.length)
            ? (
              <Box className={classes.errors}>
                <Typography>
                  {row.errors}
                  {row.warnings}
                </Typography>
              </Box>
            ) : ''}
        </Grid>
      </Grid>
      <ConfirmDialog
        open={activateDialogOpen}
        onClose={() => setActivateDialogOpen(false)}
        onConfirm={() => row.deviceId && activateMutation.mutate({ deviceId: row.deviceId })}
        title={`Activate Iridium for Device ${row.deviceTpSerial}`}
        description="This will advance the provisioning progress for this device. This includes activating the Iridium contract."
        disabled={activateMutation.isPending}
      />
    </>
  );
};
