import { HttpResponseError, serenityFetch } from 'helpers/api';
import { AssetGroup } from './types';

export const getAssetGroupsForOrganisation = async (organisationId: string): Promise<AssetGroup[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/assets/groups`, null);
  HttpResponseError.detect(response);

  return (await response.json()).assetGroups;
};

export const updateAssetGroup = async (organisationId: string, assetGroupId: number, groupName: string) => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/assets/groups/${assetGroupId}`, { name: groupName });
  HttpResponseError.detect(response);
};

export const deleteAssetGroup = async (organisationId: string, assetGroupId: number): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/assets/groups/${assetGroupId}`, null);
  HttpResponseError.detect(response);
};

export const createAssetGroup = async (organisationId: string, name: string): Promise<AssetGroup> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/assets/groups`, { name });
  HttpResponseError.detect(response);

  return response.json();
};

export const updateAssetGroupAssets = async (organisationId: string, assetGroupId: number, assetIds: number[]) => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/assets/groups/${assetGroupId}/assets`, { assetIds });
  HttpResponseError.detect(response);
};
