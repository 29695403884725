import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const DetailPanel = styled(Stack)(({ theme }) => `
  background: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.common.midGrey};
  border-radius: 4px;
`);

DetailPanel.defaultProps = {
  pt: 3,
  pb: 3,
};
