import { createSelector } from 'reselect';
import { isLoggedIn } from './session';

export interface Permissions {
  canChangeEmergencyContacts: boolean
  canEditAssets: boolean
  canEditFriends: boolean
  canEditOrganisation: boolean
  canEditPermissions: boolean
  canEditDevices: boolean
  canEditShare: boolean
  canEditContactGroups: boolean
  canEditPeople: boolean
  canEditGeofences: boolean
  canCreateNewConversation: boolean
  canChangeOrganisationAccess: boolean
  canEditAssetGroups: boolean
  canAdministerOrganisations: boolean
  canViewBilling: boolean
  canAdministerBilling: boolean
  canEditEventNotifications: boolean
  canEditMarkers: boolean
  canAdministerBeacon: boolean
}

export const permissionsForCurrentUserAndOrg = createSelector(
  [
    (state: ReduxState) => {
      const user = isLoggedIn(state);
      return user && user.memberOf.find((r: MemberOf) => r.id === state.session.organisationId)?.role === 'Administrator';
    },
    (state: ReduxState) => {
      const user = isLoggedIn(state);
      return user && (user.isStaff ?? false);
    },
  ],
  (isAdmin, isStaff): Permissions => ({
    canChangeEmergencyContacts: isAdmin,
    canEditAssets: isAdmin,
    canEditFriends: isAdmin,
    canEditOrganisation: isAdmin,
    canEditPermissions: isAdmin,
    canEditDevices: isAdmin,
    canEditShare: isAdmin,
    canEditContactGroups: isAdmin,
    canEditPeople: isAdmin,
    canEditGeofences: isAdmin,
    canCreateNewConversation: isAdmin,
    canChangeOrganisationAccess: isAdmin,
    canEditAssetGroups: isAdmin,
    canAdministerOrganisations: isStaff,
    canViewBilling: isAdmin || isStaff,
    canAdministerBilling: isStaff,
    canEditEventNotifications: isAdmin,
    canEditMarkers: isAdmin,
    canAdministerBeacon: isAdmin,
  }),
);
