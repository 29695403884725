import React from 'react';

const Powerboat = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 24.442131,61.443459 c -0.934463,-0.287965 -0.869483,-2.801668 -0.869483,-2.801668 0,0 -0.968938,-12.483289 -1.642357,-33.13696 C 21.256872,4.85116 32.895437,2.0287934 32.895437,2.0287934 c 0,0 11.541955,2.8223666 10.868536,23.4760376 -0.673419,20.653671 -1.642357,33.13696 -1.642357,33.13696 0,0 0.06498,2.513703 -0.869483,2.801668 -4.620606,1.423886 -8.356696,1.062701 -8.356696,1.062701 0,0 -3.8327,0.361185 -8.453306,-1.062701 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Powerboat;
