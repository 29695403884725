import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Page from 'components/pages/page';
import './loading.css';

const LoadingPage = () => (
  <Page title="">
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress sx={{ width: 100, height: 100 }} />
    </Box>
  </Page>
);

export default LoadingPage;
