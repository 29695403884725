import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  logo: {
    width: '2rem',
    height: '2rem'
  },
  circle: {
    fill: '#ffffff'
  },
  curve: {
    fill: '#3DD9D7'
  }
}));
