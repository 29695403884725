import React from 'react';
import clsx from 'clsx';
import {
  BaseTextFieldProps, MenuItem,
  OutlinedInputProps,
  TextField,
} from '@mui/material';

import useStyles from './input-styles';

interface TextInputProps extends Omit<BaseTextFieldProps, 'onChange' | 'onBlur' | 'inputProps'> {
  id: string,
  label?: string,
  type?: string,
  onChange?: (id: string, val: string) => void,
  onBlur?: (id: string, val: string) => void,
  multiline?: boolean,
  helperText?: string,
  hasError?: boolean,
  defaultValue?: string,
  value?: string,
  options?: { id: string, label: string }[],
  inputProps?: OutlinedInputProps
}

/**
 * @deprecated
 */
const TextInput = ({
  id,
  label,
  type = 'text',
  onChange,
  onBlur = undefined,
  multiline = false,
  helperText = '',
  hasError = false,
  defaultValue = undefined,
  value = '',
  inputProps = undefined,
  options = [],
  fullWidth = true,
  ...inputBaseProps
}: TextInputProps): JSX.Element => {
  const classes = useStyles();
  const isSelect = !!inputBaseProps.select;
  return (
    <TextField
      {...inputBaseProps}
      id={id}
      type={type}
      onChange={e => onChange?.(id, e.target?.value ?? '')}
      onBlur={e => onBlur?.(id, e.target?.value ?? '')}
      InputProps={inputProps}
      className={clsx([classes.textField, inputBaseProps.className])}
      label={label}
      error={hasError}
      defaultValue={defaultValue}
      value={value}
      variant="outlined"
      fullWidth={fullWidth}
      helperText={helperText}
      multiline={multiline}
    >
      {isSelect && options?.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TextInput;
