import React from 'react';
import {
  Container, Grid, Box, Typography
} from '@mui/material';
import Page from 'components/pages/page/page-view';
import LinkAbove from 'components/shared/linkAbove';
import IncidentsList from './incidentsList';
import useStyles from './distressIncidents-styles';

const DistressIncidents = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Page>
      <Grid container className={classes.pageWrapper}>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <LinkAbove />
            <Box>
              <Typography variant="h1" gutterBottom>Active Incidents</Typography>
              <Typography variant="body1" paragraph>All currently open distress and of concern AMS incidents</Typography>
            </Box>
            <IncidentsList />
          </Container>
        </Grid>
      </Grid>
    </Page>
  );
};

export default DistressIncidents;
