import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import './loading.css';

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const wrapperStyle = {
  height: '78vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const NoHeaderNoFooterLoadingPage = props => {
  const { extraClasses } = props;
  const allClasses = extraClasses ? { ...(wrapperStyle), ...(extraClasses) } : { ...(wrapperStyle) };

  return (
    <Box sx={contentStyle}>
      <Box sx={allClasses}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default NoHeaderNoFooterLoadingPage;
