import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  blueStaff: {
    color: theme.palette.primary.turquoise
  },
  noBreak: {
    whiteSpace: 'nowrap'
  },
  details: {
    fontWeight: 200,
    fontSize: '1rem',
    textAlign: 'left',
  }
}));

export default useStyles;
