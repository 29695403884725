import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { v4 as uuidV4 } from 'uuid';
import {
  getInvalidFields, getLegCount,
  isCreepingLine,
  isExpandingBox,
  isParallelTrack,
  SearchPattern,
} from 'helpers/searchPatterns';
import storage from 'redux-persist/lib/storage';
import {
  MoveEvent,
  RotateEvent,
  ScaleEvent
} from '../components/maps/reactmapgl/draw/SearchPatternsDrawMode';

interface SearchPatternsState {
  createDialogOpen: boolean;
  deleteDialog: {
    open: boolean;
    searchPattern: Pick<SearchPattern, 'id' | 'name'> | undefined;
  };

  overlay: {
    searchPattern: SearchPattern | undefined;
    invalidFields: string[];
  };

  nextOrigin: { lng: number, lat: number } | null;
  hiddenIds: string[];

  searchPatterns: SearchPattern[];
  selected: SearchPattern | undefined;
}

const initialState: SearchPatternsState = {
  createDialogOpen: false,
  deleteDialog: {
    open: false,
    searchPattern: undefined,
  },
  overlay: {
    searchPattern: undefined,
    invalidFields: [],
  },
  nextOrigin: null,
  hiddenIds: [],
  searchPatterns: [],
  selected: undefined,
};

const toDisplay = (input: SearchPattern) => {
  const sp = { ...input } as SearchPattern;
  sp.orientation = Math.round(sp.orientation);

  if ('legCount' in sp) {
    sp.legCount = getLegCount(sp);
  }

  return sp;
};

export const searchPatternsSlice = createSlice({
  name: 'searchPatterns',
  initialState,
  reducers: {
    openCreateSearchPatternDialog: (state, action: PayloadAction<{ latitude: number, longitude: number }>) => {
      state.createDialogOpen = true;
      state.nextOrigin = { lng: action.payload.longitude, lat: action.payload.latitude };
    },
    closeCreateSearchPatternDialog: state => {
      state.createDialogOpen = false;
    },
    openDeleteSearchPatternDialog: (state, action: PayloadAction<Pick<SearchPattern, 'id' | 'name'>>) => {
      state.deleteDialog.open = true;
      state.deleteDialog.searchPattern = action.payload;
    },
    closeDeleteSearchPatternDialog: state => {
      state.deleteDialog.open = false;
    },
    addSearchPattern: (state, action: PayloadAction<Omit<SearchPattern, 'id' | 'origin'>>) => {
      const sp = {
        ...action.payload,
        id: uuidV4(),
        origin: state.nextOrigin ?? { lng: 0, lat: 0 },
      } as SearchPattern;
      state.searchPatterns.push(sp);
      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
      state.overlay.invalidFields = getInvalidFields(sp);
    },
    removeSearchPattern: (state, action: PayloadAction<Pick<SearchPattern, 'id'>>) => {
      state.searchPatterns = state.searchPatterns.filter(sp => sp.id !== action.payload.id);
      if (state.selected?.id === action.payload.id) {
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
        state.overlay.invalidFields = [];
      }
    },
    setSelectedSearchPattern: (state, action: PayloadAction<Partial<Pick<SearchPattern, 'id'>>>) => {
      if (!action.payload?.id) {
        console.debug(`unselected search pattern: ${state.selected?.id}`);
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
        state.overlay.invalidFields = [];
        return;
      }

      const sp = state.searchPatterns.find(s => s.id === action.payload?.id);
      if (!sp) throw new Error(`failed to find search pattern with id ${action.payload.id}`);

      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
      state.overlay.invalidFields = getInvalidFields(state.overlay.searchPattern);
      console.debug(`selected search pattern: ${sp.id}`);
    },
    moveSearchPattern: (state, action: PayloadAction<MoveEvent>) => {
      if (!action.payload.searchPatternId) return;
      if (!state.selected || action.payload.searchPatternId !== state.selected.id) throw new Error('attempted to move unselected search pattern');

      const sp = state.searchPatterns.find(s => s.id === action.payload.searchPatternId);
      if (!sp) throw new Error(`failed to find search pattern with id ${action.payload.searchPatternId}`);

      sp.origin = {
        lng: sp.origin.lng + action.payload.delta.lng,
        lat: sp.origin.lat + action.payload.delta.lat,
      };

      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
    },
    scaleSearchPattern: (state, action: PayloadAction<ScaleEvent>) => {
      if (!action.payload.searchPatternId) return;
      if (!state.selected || action.payload.searchPatternId !== state.selected.id) throw new Error('attempted to move unselected search pattern');

      const idx = state.searchPatterns.findIndex(s => s.id === action.payload.searchPatternId);
      if (idx < 0) throw new Error(`failed to find search pattern with id ${action.payload.searchPatternId}`);
      const sp = { ...state.searchPatterns[idx] };

      const { axis, deltaMetres } = action.payload;

      if (isParallelTrack(sp) || isCreepingLine(sp)) {
        if (axis === 'x') {
          sp.legCount += deltaMetres / sp.trackSpacingMetres;
        } else {
          sp.legLengthMetres = Math.max(Math.round(sp.legLengthMetres + deltaMetres), 10);
        }
      }

      if (isExpandingBox(sp)) {
        sp.legCount += deltaMetres / sp.trackSpacingMetres;
      }

      if (getInvalidFields(sp).length === 0) {
        state.searchPatterns[idx] = sp;
        state.selected = sp;
        state.overlay.searchPattern = toDisplay(sp);
      }
    },
    rotateSearchPattern: (state, action: PayloadAction<RotateEvent>) => {
      if (!action.payload.searchPatternId) return;
      if (!state.selected || action.payload.searchPatternId !== state.selected.id) throw new Error('attempted to move unselected search pattern');

      const sp = state.searchPatterns.find(s => s.id === action.payload.searchPatternId);
      if (!sp) throw new Error(`failed to find search pattern with id ${action.payload.searchPatternId}`);

      sp.orientation = action.payload.newOrientation;
      if (sp.orientation > 180) sp.orientation -= 360;
      if (sp.orientation < -180) sp.orientation += 360;

      state.selected = sp;
      state.overlay.searchPattern = toDisplay(sp);
    },
    updateSearchPatternOverlay: (state, action: PayloadAction<Partial<SearchPattern>>) => {
      if (!state.selected || !state.overlay.searchPattern) throw new Error('no selected search pattern to update');
      Object.keys(action.payload).forEach(k => {
        // @ts-ignore
        state.overlay.searchPattern[k] = action.payload[k];
      });

      const invalidFields = getInvalidFields(state.overlay.searchPattern);
      state.overlay.invalidFields = invalidFields;

      const sp = state.searchPatterns.find(s => s.id === state.overlay.searchPattern?.id);
      if (!sp) throw new Error(`could not find search pattern with id ${state.overlay.searchPattern?.id}`);

      Object.keys(action.payload).forEach(k => {
        if (!invalidFields.includes(k)) {
          // @ts-ignore
          sp[k] = action.payload[k];
        }
      });

      state.selected = sp;
    },
    toggleSearchPatternVisibility: (state, action: PayloadAction<Pick<SearchPattern, 'id'>>) => {
      const sp = state.searchPatterns.find(s => s.id === action.payload.id);
      if (!sp) throw new Error(`could not find search pattern with id ${action.payload}`);

      // sp will be hidden so unselect it
      if (!sp.isHidden && state.selected?.id === sp.id) {
        state.selected = undefined;
        state.overlay.searchPattern = undefined;
      }

      sp.isHidden = !sp.isHidden;

      if (sp.isHidden) {
        state.hiddenIds.push(sp.id);
      } else {
        state.hiddenIds = state.hiddenIds.filter(id => id !== sp.id);
      }
    },
  },
  selectors: {
    selectCreateSearchPatternDialogOpen: state => state.createDialogOpen,
    selectDeleteSearchPatternDialogState: state => state.deleteDialog,
    selectSearchPatterns: state => state.searchPatterns,
    selectHiddenSearchPatternIds: state => state.hiddenIds,
    selectSelectedSearchPattern: state => state.selected,
    selectUnselectedSearchPatterns: state => {
      if (!state.selected?.id) return state.searchPatterns;
      return state.searchPatterns.filter(sp => sp.id !== state.selected?.id);
    },
    selectSearchPatternOverlayState: state => state.overlay,
  },
});

export const {
  openCreateSearchPatternDialog,
  closeCreateSearchPatternDialog,
  openDeleteSearchPatternDialog,
  closeDeleteSearchPatternDialog,
  addSearchPattern,
  removeSearchPattern,
  setSelectedSearchPattern,
  moveSearchPattern,
  scaleSearchPattern,
  rotateSearchPattern,
  updateSearchPatternOverlay,
  toggleSearchPatternVisibility,
} = searchPatternsSlice.actions;
export const {
  selectCreateSearchPatternDialogOpen,
  selectDeleteSearchPatternDialogState,
  selectSearchPatterns,
  selectHiddenSearchPatternIds,
  selectSelectedSearchPattern,
  selectUnselectedSearchPatterns,
  selectSearchPatternOverlayState,
} = searchPatternsSlice.selectors;

export default persistReducer({
  key: 'searchPatterns',
  whitelist: ['searchPatterns'],
  storage,
}, searchPatternsSlice.reducer);
