import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  icon: {
    width: 30,
    paddingRight: 5,
  },
  textInput: {
    '& > div': {
      backgroundColor: theme.palette.background.inputBackground
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

export default useStyles;
