// Custom fragment shader for ColouredIconLayer
export default `\
#version 300 es
#define SHADER_NAME coloured-fragment-shader

precision highp float;
uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;

in float vColorMode;
in vec4 vColor;
in vec2 vTextureCoords;
in vec2 uv;

out vec4 fragColor;

void main(void) {
  geometry.uv = uv;
  vec4 texColor = texture(iconsTexture, vTextureCoords);

  if (texColor.a < alphaCutoff) {
    discard;
  }

  float d = dot(vec4(1.0, 1.0, 1.0, 0.0), texColor);
  if (d > 1.5) {
    fragColor = vec4(1.0, 1.0, 1.0, 1.0);
  }
  else if (d > 1.0) {
    fragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.75);
  }
  else if (d > 0.5) {
    fragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.5);
  }
  else if (d > 0.1) {
    fragColor = mix(vColor, vec4(1.0, 1.0, 1.0, 1.0), 0.1);
  }
  else {
    fragColor = vec4(vColor.rgb,texColor.a);
  }
  fragColor = picking_filterPickingColor(fragColor);
}
`;
