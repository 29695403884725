import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Link, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useGetIceContactGroupsHealth } from 'apis/rest/iceContactGroups/hooks';
import usePermissions from 'hooks/session/usePermissions';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import Translate from 'components/shared/translate';

// NOTE: Because these display snackbar notifications immediately, we need to be extra careful
//       about unintended re-renders which may cause erratic behaviour.
//       For this reason, all content rendered inside a notification must handle its own translations
//       instead of having a translation function passed in through the surrounding effect.

const Dismiss = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <Translate
    render={t => (
      <IconButton
        aria-label={t('components.Nag.dismiss')}
        color="inherit"
        onClick={onClick}
        size="large"
      >
        <Close />
      </IconButton>
    )}
  />
);

const MissingDefaultIceContactGroup = (): null => {
  const permissions = usePermissions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isMissing = useGetIceContactGroupsHealth().query.data?.defaultIceGroupExists === false;

  useEffect(() => {
    const key = 'nag.missingDefaultIceContactGroup';

    if (isMissing) {
      const message = <Translate render={t => t('components.Nag.missingDefaultIceContactGroup')} />;
      enqueueSnackbar(message, {
        key,
        variant: 'error',
        persist: true,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        action: permissions.canEditContactGroups ? (
          <Button
            color="inherit"
            variant="outlined"
            component={RouterLink}
            size="large"
            to="/manage/ice/groups"
            onClick={() => closeSnackbar(key)}
          >
            <Translate render={t => t('components.Nag.createIceContactGroup')} />
          </Button>
        ) : (
          <Dismiss onClick={() => closeSnackbar(key)} />
        ),
      });
    } else {
      closeSnackbar(key);
    }

    // always close on unmount
    return () => closeSnackbar(key);
  }, [permissions, enqueueSnackbar, closeSnackbar, isMissing]);

  return null;
};

const InvalidDefaultIceContactGroup = (): null => {
  const permissions = usePermissions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const iceHealth = useGetIceContactGroupsHealth().query.data;
  const isInvalid = iceHealth?.defaultIceGroupExists === true && iceHealth?.defaultIceGroupIsHealthy === false;

  useEffect(() => {
    const key = 'nag.invalidDefaultIceContactGroup';

    if (isInvalid) {
      const message = <Translate render={t => t('components.Nag.invalidDefaultIceContactGroup')} />;
      enqueueSnackbar(message, {
        key,
        variant: 'error',
        persist: true,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        action: permissions.canEditContactGroups ? (
          <Button
            color="inherit"
            variant="outlined"
            component={RouterLink}
            size="large"
            to="/manage/ice/groups"
            onClick={() => closeSnackbar(key)}
          >
            <Translate render={t => t('components.Nag.editDefaultIceContactGroup')} />
          </Button>
        ) : (
          <Dismiss onClick={() => closeSnackbar(key)} />
        ),
      });
    } else {
      closeSnackbar(key);
    }

    // always close on unmount
    return () => closeSnackbar(key);
  }, [permissions, enqueueSnackbar, closeSnackbar, isInvalid]);

  return null;
};

const UsercodeWelcome = (): null => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const key = 'nag.usercodeWelcome';
    const message = (
      <Box>
        <Translate
          render={t => t.rich('components.Nag.usercodeWelcome', {
            br: () => <br />,
            link: chunks => <Link color="inherit" component={RouterLink} to="/settings/organisation#orgUsers">{chunks}</Link>,
          })}
        />
      </Box>
    );

    enqueueSnackbar(message, {
      key,
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      action: <Dismiss onClick={() => closeSnackbar(key)} />,
    });

    // always close on unmount
    return () => closeSnackbar(key);
  }, [enqueueSnackbar, closeSnackbar]);

  return null;
};

export const Nag = (): JSX.Element | null => {
  const isUsercodeLogin = useIsUsercodeLogin();

  return (
    <>
      {isUsercodeLogin && <UsercodeWelcome />}
      <MissingDefaultIceContactGroup />
      <InvalidDefaultIceContactGroup />
    </>
  );
};
