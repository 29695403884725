import { Stack } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useMap } from 'react-map-gl';
import type { FeatureCollection, MultiPolygon } from 'geojson';
import { Link } from 'react-router-dom';
import { safeBounds } from 'helpers/geo';
import type { Geofence } from '../types';

export interface GeofenceTableProps {
  geofences: Geofence[]
  onRowHover: (id: number[]) => void
  hoveredIds: number[]
}

const GeofencesListListView: React.FC<GeofenceTableProps> = ({ geofences, onRowHover, hoveredIds }) => {
  const t = useTranslations('pages.manage.geofencing');
  const hovering = useRef<boolean>(false);
  const { geofencesMap: mapRef } = useMap();

  useEffect(() => {
    const map = mapRef?.getMap();
    if (!map) return;

    const geoJson: FeatureCollection<MultiPolygon> = {
      type: 'FeatureCollection',
      features: geofences.map(f => ({
        type: 'Feature',
        properties: {
          id: f.id,
          name: f.name,
        },
        geometry: f.geometry,
      })),
    };
    const bounds = geoJson.features.length > 0 ? safeBounds(geoJson) : undefined;
    if (!bounds || Number.isNaN(bounds[0][0])) return;

    try {
      map.fitBounds(bounds, { padding: 20 });
    } catch (error) {
      // do nothing
    }
  }, [mapRef, geofences]);

  const getColor = (category: string) => {
    switch (category) {
      case 'AreaOfOperation': return 'green';
      case 'RestrictedArea': return 'red';
      default: return 'yellow';
    }
  };

  const breakTypeString = (breakType:string): string => {
    switch (breakType) {
      case 'RestrictedArea': {
        return t('list.category.enter');
      }
      case 'AreaOfOperation': {
        return t('list.category.exit');
      }
      case 'Generic': {
        return t('list.category.both');
      }
      default:
        return ' ';
    }
  };

  const [scrollToId] = hoveredIds;
  useEffect(() => {
    if (hovering.current || scrollToId === undefined) return;
    document.getElementById(`geofence-list-item-${scrollToId}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [scrollToId]);

  return (
    <List sx={{ p: 0 }}>
      {geofences.map(row => (
        <ListItem key={row.id} id={`geofence-list-item-${row.id}`} sx={{ width: '100%', borderTop: theme => theme.border.default }} disablePadding>
          <ListItemButton
            component={Link}
            to={row.id.toString()}
            sx={{ justifyContent: 'space-between', pt: 2, pb: 2 }}
            onClick={event => event.stopPropagation()}
            selected={hoveredIds.includes(row.id)}
            onMouseEnter={() => {
              hovering.current = true;
              onRowHover([row.id]);
            }}
            onMouseLeave={() => {
              hovering.current = false;
              onRowHover([]);
            }}
          >

            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', maxWidth: '90%' }}>
              <Box sx={{
                backgroundColor: getColor(row.category),
                width: '1rem',
                height: '1rem',
                borderRadius: 1,
                flexShrink: 0,
              }} />
              <ListItemText primary={row.name} primaryTypographyProps={{ sx: { overflowWrap: 'break-word' } }} secondary={breakTypeString(row.category)} />
            </Stack>
            <ArrowForwardIos sx={{ color: 'common.text' }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default GeofencesListListView;
