import { createSlice } from '@reduxjs/toolkit';
import { resetEverything } from "slices/session/session.slice";

type UiState = {
  omniboxOpen: boolean;
  contextboxOpen: boolean;
  analysisboxOpen: boolean;
  systemStatusOpen: boolean;
};

/**
 * This slice is for the current state of the application interface.
 * For example, which panels/popups are open.
 */
const initialState: UiState = {
  // Omnibox can be expanded
  omniboxOpen: true,
  contextboxOpen: false,
  analysisboxOpen: false,

  // Whether the System Status dialog is open (button at bottom-left of status bar)
  systemStatusOpen: false
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleOmnibox: (state) => {
      state.omniboxOpen = !state.omniboxOpen;
    },
    toggleContextbox: (state) => {
      state.contextboxOpen = !state.contextboxOpen;
    },
    toggleSystemStatus: (state) => {
      state.systemStatusOpen = !state.systemStatusOpen;
    },
    toggleAnalysisbox: (state) => {
      state.analysisboxOpen = !state.analysisboxOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetEverything, () => initialState);
  },
});

export const { toggleOmnibox, toggleContextbox, toggleSystemStatus, toggleAnalysisbox } = uiSlice.actions;

export default uiSlice.reducer;
