import { HttpResponseError, serenityFetch } from 'helpers/api';
import { OrganisationFriend, GroupFriend } from './types';

export const fetchFriendOrganisations = async (organisationId: string): Promise<OrganisationFriend[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/friends/organisations`, null);
  HttpResponseError.detect(response);
  return (await response.json()).organisationFriends;
};

export const fetchFriendGroups = async (organisationId: string): Promise<GroupFriend[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/friends/groups`, null);
  HttpResponseError.detect(response);
  return (await response.json()).groupFriends;
};

export const addFriendOrganisation = async (organisationId: string, body: Pick<OrganisationFriend, 'pubKey'>): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/friends/organisations`, body);
  HttpResponseError.detect(response);
};

export const addFriendGroup = async (organisationId: string, body: Pick<GroupFriend, 'pubKey'>): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/friends/groups`, body);
  HttpResponseError.detect(response);
};

export const deleteFriendOrganisation = async (organisationId: string, body: Pick<OrganisationFriend, 'pubKey'>): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/friends/organisations/${body.pubKey}`, null);
  HttpResponseError.detect(response);
};

export const deleteFriendGroup = async (organisationId: string, body: Pick<GroupFriend, 'pubKey'>): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/friends/groups/${body.pubKey}`, null);
  HttpResponseError.detect(response);
};
