import React from 'react';

const ManualLoad = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M12.09,5.54L12.09,5.54c0-0.03,0.01-0.07,0.01-0.1c0-1.5-3.68-1.54-4.1-1.54S3.9,3.94,3.9,5.44
c0,0.04,0.01,0.07,0.02,0.1l-0.01,0l1.02,5.09C4.98,11.64,6.55,12.1,8,12.1s3.02-0.46,3.07-1.48L12.09,5.54z M8,4.92
c1.61,0,2.64,0.3,2.99,0.51C10.64,5.65,9.61,5.95,8,5.95s-2.64-0.3-2.99-0.51C5.36,5.22,6.39,4.92,8,4.92z"
    />
  </svg>
);

export default ManualLoad;
