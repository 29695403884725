import React from 'react';

const Bronco = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 29.326872,12.820568 c -0.370243,3.993334 0.400192,9.66825 0.400192,9.66825 l -0.707807,0.799901 h -1.538082 l -0.154291,0.646094 -3.445989,-0.03037 c 0,0 -0.636865,-3.857383 -1.645607,-3.871736 -0.941534,-0.0134 -1.584857,3.810019 -1.584857,3.810019 0,0 -11.9875374,-0.0103 -15.41462,0.03085 -0.4582845,0.0055 -1.0175064,0.434581 -1.0462847,0.891993 -0.1837036,2.919871 -0.061716,9.661502 -0.061716,9.661502 l 16.4305277,0.06123 1.168753,16.061195 0.337408,10.091587 0.555069,-0.830762 18.445456,0.185207 -0.127762,-7.582253 -18.010076,0.02498 1.230951,-18.134624 h 5.661028 c 0,0 -0.473268,1.248279 -0.28592,1.839916 1.178387,3.721298 2.537357,3.82111 2.537357,3.82111 0,0 1.33834,-0.100142 2.516627,-3.82111 0.187348,-0.591637 -0.285923,-1.839916 -0.285923,-1.839916 h 5.661027 l 1.230953,16.738144 -0.07343,1.395453 -17.980284,0.106962 -0.345963,7.276773 18.467277,0.05482 0.707807,0.787119 0.424688,-10.113406 1.168755,-16.061195 16.430528,-0.06123 c 0,0 0.121986,-6.741632 -0.06171,-9.661501 -0.02878,-0.457412 -0.588002,-0.886489 -1.046285,-0.891993 -3.427081,-0.04117 -15.414621,-0.03085 -15.414621,-0.03085 0,0 -0.643323,-3.823417 -1.584855,-3.810019 -1.008744,0.01435 -1.64561,3.871737 -1.64561,3.871737 l -3.445988,0.03037 -0.15429,-0.646093 H 35.10122 l -0.70781,-0.7999 c 0,0 0.770438,-5.674916 0.400194,-9.668251 -0.495573,-5.3449944 -2.721078,-8.1254529 -2.735297,-8.1407727 -0.002,-0.019633 -2.235867,2.7957846 -2.731432,8.1407707 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default Bronco;
