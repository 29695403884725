import React, { ReactNode } from 'react';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import LabsContextProvider from './labs-context-provider';

const LabsFeatureContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const { isLoading, some } = useFeatureAssets('labs');

  return (
    <LabsContextProvider loading={isLoading} enabled={some}>
      {children}
    </LabsContextProvider>
  );
};

export default LabsFeatureContextProvider;
