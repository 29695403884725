import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  SvgIcon,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslations } from 'use-intl';
import insensitiveSort from 'utils/insensitiveSort';
import { useGetCapabilitiesList, useGetDevicesList } from 'apis/rest/devices/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { isAssetWithDevice } from 'helpers/assets';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useCheckDeviceAccess } from 'hooks/device/useDeviceAccess';
import useStyles from './newConversationBar-styles';
import { displaySnackbar } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';

const useParticipants = (): { participants: Participant[], isLoading: boolean, isError: boolean } => {
  const organisationId = useOrganisationId();
  const assetsQuery = useGetAssetsList().query;
  const devicesQuery = useGetDevicesList().query;
  const capabilitiesQuery = useGetCapabilitiesList().query;
  const assetLabel = useAssetLabel();
  const deviceAccess = useCheckDeviceAccess();

  const participants = useMemo<Participant[]>(() => {
    if (!assetsQuery.data || !devicesQuery.data || !capabilitiesQuery.data) return [];

    const assets = assetsQuery.data.filter(isAssetWithDevice);
    const devicesById = devicesQuery.data.reduce<Record<number, DeviceBasic>>((acc, device) => {
      acc[device.id] = device;
      return acc;
    }, {});

    const textCapabilityScriptIds = capabilitiesQuery.data.filter(c => c.capability === 'CP_SND_TEXT').map(c => c.scriptId);

    return assets.flatMap(asset => {
      const device = devicesById[asset.deviceId];
      if (!device) return [];

      const devicePermissions = deviceAccess.call(null, asset.deviceId);
      if (asset.ownerId.toLowerCase() !== organisationId.toLowerCase() && !devicePermissions.canSendTextMessages) return [];

      const canSendText = textCapabilityScriptIds.includes(device.scriptId);
      if (!canSendText) return [];

      return {
        id: asset.id?.toString(),
        name: assetLabel(asset) ?? 'Unassigned',
        type: 'asset' as 'asset' | 'user',
        ownerId: asset.ownerId,
        ownerName: asset.ownerName,
        deviceId: device.id,
        make: device.make,
        model: device.model,
        tpSerial: device.tpSerial,
        manufacturerSerial: device.manufacturerSerial,
        imei: device.imei?.toString() ?? '',
      };
    }).sort((a, b) => insensitiveSort(a.name, b.name));
  }, [assetsQuery.data, devicesQuery.data, capabilitiesQuery.data, organisationId, assetLabel, deviceAccess]);

  return {
    participants,
    isLoading: assetsQuery.isLoading || devicesQuery.isLoading || capabilitiesQuery.isLoading,
    isError: assetsQuery.isError || devicesQuery.isError || capabilitiesQuery.isError,
  };
};

interface NewConversationBarProps {
  createNewConversation: (participant: Participant) => void;
  sessionUser: string;
  conversations: Conversation[];
  selectConversationId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setCreatingConversation: React.Dispatch<React.SetStateAction<boolean>>;
  assetsByDeviceId: Record<number, AssetBasic> | undefined;
}
const NewConversationBar = ({
  createNewConversation,
  conversations,
  selectConversationId,
  setCreatingConversation,
  assetsByDeviceId,
}: NewConversationBarProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslations('pages.messaging.newConversationBar');
  const assetLabel = useAssetLabel();
  const dispatch = useAppDispatch();

  const { participants: messagableParticipants, isLoading, isError } = useParticipants();

  useEffect(() => {
    if (isError) {
      dispatch(displaySnackbar({
        id: 'participantsLoadError',
        text: t('error'),
        type: 'error',
      }));
    }
  }, [isError, t]);

  const [participant, setParticipant] = useState<Participant | null>(null);
  const [inputValue, setInputValue] = useState<string | null>(null);

  const handleOnChange = (e: React.ChangeEvent<any>, newParticipant: Participant | null): void => {
    if (!newParticipant) return;
    const existingConversation = conversations.find(c => c.deviceId === newParticipant?.deviceId);
    if (existingConversation) {
      selectConversationId(existingConversation.id);
      setCreatingConversation(false);
    } else {
      setParticipant(newParticipant);
      createNewConversation(newParticipant);
    }
  };

  const devicesIcon = (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" className={classes.icon}>
      <path d="M14.17,8.05H1.83C0.82,8.05,0,8.87,0,9.88l0,0c0,1.01,0.82,1.83,1.83,1.83h12.34c1.01,0,1.83-0.82,1.83-1.83l0,0
              C16,8.87,15.18,8.05,14.17,8.05z M2.58,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98
              S3.12,10.86,2.58,10.86z M5.02,10.86c-0.55,0-0.99-0.44-0.99-0.99s0.44-0.98,0.99-0.98s0.99,0.44,0.99,0.98S5.57,10.86,5.02,10.86z
              M10.98,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98C11.97,10.42,11.52,10.86,10.98,10.86z
              M13.42,10.86c-0.55,0-0.98-0.44-0.98-0.99s0.44-0.98,0.98-0.98s0.98,0.44,0.98,0.98S13.97,10.86,13.42,10.86z"
      />
      <path d="M1.83,7.78h12.34c0.3,0,0.57,0.07,0.82,0.17L11.82,4.8C11.49,4.47,11.06,4.3,10.6,4.3H5.41c-0.46,0-0.9,0.18-1.22,0.51
              L1,7.96C1.25,7.84,1.53,7.78,1.83,7.78z"
      />
    </SvgIcon>
  );
  const userIcon = (
    <SvgIcon className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
      <path d="M16,0c-2.31,0-4.17,1.87-4.17,4.17S13.69,8.35,16,8.35s4.17-1.87,4.17-4.17l0,0
            C20.17,1.87,18.3,0,16,0"
      />
      <path d="M13.22,9.74c-2.3,0-4.17,1.86-4.17,4.17c0,0,0,0.01,0,0.01v6.96c0,0.32,0.22,0.6,0.53,0.68
            l2.3,0.57l0.65,9.24c0.03,0.36,0.33,0.64,0.69,0.64h5.57c0.36,0,0.66-0.28,0.69-0.64l0.65-9.24l2.3-0.57
            c0.31-0.08,0.53-0.36,0.53-0.68v-6.96c0-2.3-1.86-4.17-4.17-4.18c0,0-0.01,0-0.01,0H13.22z"
      />
    </SvgIcon>
  );

  return (
    <Box className={classes.container}>
      <Autocomplete
        id="participants"
        options={messagableParticipants}
        getOptionLabel={option => assetLabel(assetsByDeviceId?.[option.deviceId], '')}
        // getOptionSelected={getOptionSelected}
        value={participant}
        onChange={handleOnChange}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t('label')}
            onBlur={() => {
              setParticipant(null);
            }}
            className={classes.textInput}
            autoFocus
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {option.type === 'user' ? userIcon : devicesIcon}
            {`${assetLabel(assetsByDeviceId?.[option.deviceId])} (${option.ownerName})`}
          </li>
        )}
        fullWidth
        onInputChange={(e, value) => setInputValue(value)}
        noOptionsText={isLoading ? 'Loading...' : t('noUserFound', { userName: inputValue })}
        clearText={t('clear')}
        autoHighlight
      />
    </Box>
  );
};

export default NewConversationBar;
