import { GeographicCoordinates } from 'components/pages/manage/markers/types';

const maxLatitudeValue = 90;
const maxLongitudeValue = 180;

export interface ValidateLatLongResult {
  valid: boolean
  missing?: boolean
  helperText?: 'latLongMissing' | 'latLongOutOfRange'
  field?: 'latitude' | 'longitude' | 'both'
}

const isNumber = (value?: string | number): boolean => ((value != null)
  && (value !== '')
  && !Number.isNaN(+value.toString()));

const validateLatLong = (coordinates: GeographicCoordinates): ValidateLatLongResult => {
  // check for undefined
  if (!isNumber(coordinates.latitude) || !isNumber(coordinates.longitude)) {
    return {
      valid: false,
      missing: true,
      helperText: 'latLongMissing',
      field: (!isNumber(coordinates.latitude) && !isNumber(coordinates.longitude)) ? 'both' :
        (!isNumber(coordinates.latitude) ? 'latitude' : 'longitude')
    };
  }
  // validate range
  if (Math.abs(coordinates.latitude ?? 0) > maxLatitudeValue && Math.abs(coordinates.longitude ?? 0) > maxLongitudeValue) {
    return {
      valid: false,
      missing: false,
      helperText: 'latLongOutOfRange',
      field: 'both'
    };
  }
  if (Math.abs(coordinates.latitude ?? 0) > maxLatitudeValue) {
    return {
      valid: false,
      missing: false,
      helperText: 'latLongOutOfRange',
      field: 'latitude'
    };
  }
  if (Math.abs(coordinates.longitude ?? 0) > maxLongitudeValue) {
    return {
      valid: false,
      missing: false,
      helperText: 'latLongOutOfRange',
      field: 'longitude'
    };
  }
  return {
    valid: true,
    missing: false,
  };
};

export default validateLatLong;
