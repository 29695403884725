import { PathLayer } from '@deck.gl/layers';

export default class AnimatedDashPathLayer<DataT = any, ExtraPropsT extends {} = {}> extends PathLayer<DataT, ExtraPropsT> {
  getShaders() {
    const shaders = super.getShaders();

    if (shaders.inject['vs:#decl'].includes('dashStart')) {
      return shaders;
    }

    // eslint-disable-next-line no-multi-str
    shaders.inject['vs:#decl'] += '\
  uniform float dashStart;';
    // eslint-disable-next-line no-multi-str
    shaders.inject['vs:#main-end'] += '\
  vDashOffset += dashStart / instanceStrokeWidths;';
    return shaders;
  }

  draw(opts) {
    opts.uniforms.dashStart = -((Date.now() / 50) % 1000) || 0;
    super.draw(opts);
  }
}

AnimatedDashPathLayer.layerName = 'AnimatedDashPathLayer';
