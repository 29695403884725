import React from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { useAssetLabel } from 'components/shared/assetLabel';
import AssetColourMarker from 'components/shared/assetColourMarker';

interface AssetDetailsProps {
  asset: AssetWithDevice | undefined
  isLoading?: boolean
}

export const AssetDetails = ({ asset, isLoading = false }: AssetDetailsProps) => {
  const t = useTranslations('pages.sharing.asset');
  const assetLabel = useAssetLabel();

  if (isLoading) return <Skeleton />;
  if (!asset) {
    return (
      <Box pl="1rem">
        <Typography pl={1}>{t('unknown')}</Typography>
      </Box>
    );
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <AssetColourMarker assetId={asset.id} />
      <Stack>
        <Typography>{assetLabel(asset) || t('unnamed')}</Typography>
        <Typography variant="subtitle1">{asset.make} {asset.model} {asset.variant}</Typography>
      </Stack>
    </Stack>
  );
};

export const GroupAssetDetails = ({ asset, isLoading }: AssetDetailsProps) => {
  const t = useTranslations('pages.sharing.asset');
  const assetLabel = useAssetLabel();

  if (isLoading) return <Skeleton />;
  if (!asset) return <Typography variant="h3">{t('unknown')}</Typography>;

  return (
    <Stack direction="row" spacing={1}>
      <Box pt={0.5}>
        <AssetColourMarker assetId={asset.id} />
      </Box>
      <Stack>
        <Typography variant="h3">{assetLabel(asset) || t('unnamed')}</Typography>
        <Typography variant="subtitle1">{asset.make} {asset.model} {asset.variant}</Typography>
      </Stack>
    </Stack>
  );
};
