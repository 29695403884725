import React, { useRef, useState, ReactNode } from 'react';
import { Box } from '@mui/material';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Container, Content } from './toggleablePanel-styles';

interface ToggleablePanelProps {
  // the content rendered whether the panel is open or not
  header: ReactNode,
  // the content of the panel which has visibility toggled
  children: ReactNode,
}

// A Toggleable Panel, this will render it's "header" at all times and, if clicked,
// will toggle a Panel containing any `children` at the location specified by `style`.
const ToggleablePanel = ({ header, children }: ToggleablePanelProps): JSX.Element => {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <Container ref={ref}>
      <Box onClick={() => setIsOpen(value => !value)}>{header}</Box>
      <Content open={isOpen}>{children}</Content>
    </Container>
  );
};

export default ToggleablePanel;
