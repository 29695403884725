import { Alert, Box, Container, TextField, Typography } from '@mui/material';
import { useGetAllMarketSectorOrganisations, useGetAllMarketSectors, useMutateCreateMarketSector, useMutateDeleteMarketSector } from 'apis/rest/marketSector/hooks';
import Page from 'components/pages/page';
import DetailPanel from 'components/shared/DetailPanel';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';
import { Stack } from '@mui/system';
import { CreateButton } from 'components/pages/manage/groups/components/createButton';
import MarketSectorTable from './marketSector-table';

const MarketSectorsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const marketSectorQuery = useGetAllMarketSectors();
  const marketSectorOrgQuery = useGetAllMarketSectorOrganisations();
  const mutateDeleteMarketSector = useMutateDeleteMarketSector();
  const mutateCreateMarketSector = useMutateCreateMarketSector();

  const [deleteId, setDeleteId] = useState<string>();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [newId, setNewId] = useState<string>();
  const [newName, setNewName] = useState<string>();

  const deleteMarketSector = useCallback(id => {
    mutateDeleteMarketSector.mutate(id, {
      onSuccess: () => {
        setDeleteId(undefined);
      },
    });
  }, [mutateDeleteMarketSector]);

  const addMarketSector = useCallback((id, name) => {
    mutateCreateMarketSector.mutate({ id, name, description: '' }, {
      onSuccess: () => {
        setShowAddDialog(false);
        setNewId(undefined);
        setNewName(undefined);
      }
    });
  }, [mutateCreateMarketSector]);

  return (
    <Page title="Market Sectors">
      <SettingsMenuPageWrapper p={8} pb={0}>
        <Container maxWidth={false}>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>Market Sectors</Typography>
            <Typography paragraph>Manage market sectors and their features</Typography>
          </Box>
        </Container>
        <DetailPanel pb={0} mb={8}>
          <CreateButton buttonText="Add new market sector" onClick={() => setShowAddDialog(true)} />
          <MarketSectorTable
            data={marketSectorQuery.data ?? []}
            orgData={marketSectorOrgQuery.data}
            isLoading={marketSectorQuery.isLoading || marketSectorOrgQuery.isLoading}
            onEditFeatures={id => navigate(`/staff/market-sectors/${id}/features`)}
            onEdit={id => navigate(`/staff/market-sectors/${id}`)}
            onDelete={id => setDeleteId(id)}
          />
        </DetailPanel>

        {deleteId && (
          <ConfirmationDialog
            confirmText="Delete"
            title="Delete Market Sector"
            confirmButtonProps={{ color: 'error', variant: 'contained', size: 'large', sx: { minWidth: '10rem' }, disabled: false }}
            cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' }, disabled: false }}
            cancelText="Cancel"
            onConfirm={() => deleteMarketSector(deleteId)}
            onCancel={() => setDeleteId(undefined)}
            dialogProps={{ open: true, fullWidth: true }}>
            <Stack spacing={1} sx={{ padding: '2em 1em' }}>
              <Typography paragraph>Are you sure you want to delete this market sector?</Typography>
              <Alert severity="warning">Delete will not work if there are organisations using it or any features are active for the market sector.</Alert>
            </Stack>
          </ConfirmationDialog>
        )}

        {showAddDialog && (
          <ConfirmationDialog
            confirmText="Save"
            title="Add Market Sector"
            confirmButtonProps={{
              color: 'primary',
              variant: 'contained',
              size: 'large',
              sx: { minWidth: '10rem' },
              disabled: !newId || !newName || mutateCreateMarketSector.isLoading
            }}
            cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' }, disabled: mutateCreateMarketSector.isLoading }}
            cancelText="Cancel"
            onConfirm={() => addMarketSector(newId, newName)}
            onCancel={() => { setShowAddDialog(false); setNewId(undefined); setNewName(undefined); }}
            dialogProps={{ open: true, fullWidth: true }}>
            <Stack spacing={1} sx={{ padding: '2em 1em' }}>
              <TextField
                label="ID"
                value={newId}
                onChange={event => setNewId(event.target.value)}
                disabled={mutateCreateMarketSector.isLoading}
                fullWidth
              />
              <TextField
                label="Name"
                value={newName}
                onChange={event => setNewName(event.target.value)}
                disabled={mutateCreateMarketSector.isLoading}
                fullWidth
              />
            </Stack>
          </ConfirmationDialog>
        )}

      </SettingsMenuPageWrapper>
    </Page>
  );
}

export default MarketSectorsPage;
