/* eslint-disable no-console */
import { getHeaders, fetchOptions } from 'helpers/api';

const BASE_URL = '/api/distresses/v1';

const getCurrentDistressIncidentForAsset = assetId => new Promise((res, rej) => {
  const headers = getHeaders();

  if (!headers.authorization) {
    rej(new Error('No Authorization Headers'));
    return;
  }

  const url = `${BASE_URL}?assetId=${assetId}`;

  fetch(url, {
    method: 'GET',
    headers,
    ...fetchOptions
  })
    .then(response => response.json())
    .then(incident => res(incident))
    .catch(e => {
      console.log('There was an error', e);
      rej(e);
    });
});

const getCurrentDistresses = () => new Promise((res, rej) => {
  const headers = getHeaders();

  if (!headers.authorization) {
    rej(new Error('No Authorization Headers'));
    return;
  }
  fetch(`${BASE_URL}/staffonly/currentincidents`, {
    method: 'GET',
    headers,
    ...fetchOptions
  })
    .then(response => response.json())
    .then(incident => res(incident))
    .catch(e => {
      console.log('There was an error', e);
      rej(e);
    });
});

export default {
  getCurrentDistressIncidentForAsset,
  getCurrentDistresses,
};
