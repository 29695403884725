import React, { useEffect, useMemo } from 'react';
import { useTranslations } from 'use-intl';
import { Box, Fade, Paper, Stack } from '@mui/material';
import { Outlet, Route, Routes, useMatches, useParams, useSearchParams } from 'react-router-dom';
import { MapProvider } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/types';
import type { Feature } from 'geojson';
import Page from 'components/pages/page';
import { MapboxLogoLink } from 'components/map/modules/controls/attribution/mapbox';
import { useGetGeofences } from 'apis/rest/geofence/hooks';
import usePermissions from 'hooks/session/usePermissions';
import { manageGeofencesSlice as slice } from 'slices/manageGeofences.slice';
import GeofencesMap from './map/GeofencesMap';
import GeofencesMapControls from './map/GeofencesMapControls';
import GeofencesListActions from './list/GeofencesListActions';
import GeofencesMapFitToAll from './map/GeofencesMapFitToAll';
import GeofencesLayoutView from './Geofences.layout.view';

const GeofencesLayout = () => {
  const dispatch = useAppDispatch();
  const t = useTranslations('pages.manage.geofencing');
  const permissions = usePermissions();

  const handle = useMatches().at(-1)?.handle as { pageName?: string } | undefined;
  const isListPage = handle?.pageName === 'list';
  const isEditPage = handle?.pageName === 'edit';

  const params = useParams<{ geofenceId: string }>();
  const geofenceQuery = useGetGeofences();
  const geofence = useMemo(() => {
    if (!params.geofenceId) return undefined;
    const id = parseInt(params.geofenceId, 10);
    return geofenceQuery.data?.find(g => g.id === id);
  }, [params.geofenceId, geofenceQuery.data]);

  const [searchParams] = useSearchParams();

  const geofences = geofenceQuery.data;

  const visibleGeofences = useMemo(() => {
    if (isListPage) {
      const category = searchParams.get('geofenceCategory') ?? 'All';
      const filterText = searchParams.get('filter') ?? '';
      return (geofences ?? [])
        .filter(g => (category === 'All' ? true : g.category === category))
        .filter(g => [g.name.toLowerCase()].some(s => (filterText ? s.includes(filterText.toLowerCase()) : true)));
    }
    if (isEditPage && geofence) return [geofence];
    return [];
  }, [geofences, geofence, isListPage, isEditPage, searchParams]);

  useEffect(() => {
    dispatch(slice.actions.stopDrawing());
  }, [handle?.pageName, dispatch]);

  useEffect(() => {
    if (isListPage) dispatch(slice.actions.createGeofence());
  }, [isListPage, dispatch]);

  const { features } = useSelector(slice.selectors.selectMapDraw);
  const featuresToFit = useMemo((): Feature[] => {
    if (!isListPage) return features;
    return visibleGeofences.map(f => ({
      type: 'Feature',
      properties: {
        id: f.id,
        name: f.name,
      },
      geometry: f.geometry
    }));
  }, [isListPage, visibleGeofences, features]);

  return (
    <MapProvider>
      <Page title={t('title')}>
        <GeofencesLayoutView
          pageHeading={(
            <Routes>
              <Route index element={t('title')} />
              <Route path="create" element={t('createTitle')} />
              <Route path=":id" element={t('editTitle', { name: geofence?.name ?? '' })} />
            </Routes>
          )}
          pageActions={(
            <Routes>
              <Route index element={<GeofencesListActions />} />
            </Routes>
          )}
          content={<Outlet />}
          map={(
            <>
              <GeofencesMap fences={visibleGeofences} list={isListPage} />
              <Fade in={!isListPage && permissions.canEditGeofences}>
                <Paper elevation={0} sx={{ position: 'absolute', top: 0, left: 0, right: 0, m: 3, p: 2 }}>
                  <GeofencesMapControls />
                </Paper>
              </Fade>
              <Stack direction="row" spacing={3} sx={{ position: 'absolute', alignItems: 'center', bottom: theme => theme.spacing(3), ml: 3, mr: 3, mb: 2 }}>
                <GeofencesMapFitToAll features={featuresToFit} />
                <Box>
                  <MapboxLogoLink />
                </Box>
              </Stack>
            </>
          )}
          limitHeight={isListPage}
        />
      </Page>
    </MapProvider>
  );
};

export default GeofencesLayout;
