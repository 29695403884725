import React from 'react';

const DescentExceedence = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path className="icon-base" d="M11.31,0H4.69L0,4.69v6.63L4.69,16h6.63L16,11.31V4.69L11.31,0z" />
    <polygon className="icon-detail" fill="#fff" points="11.21,8.62 10.54,7.95 8.48,10.01 8.48,4.17 7.52,4.17 7.52,10.01 5.46,7.95 4.79,8.62 8,11.83" />
  </svg>
);

export default DescentExceedence;
