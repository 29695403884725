import { SvgIcon } from '@mui/material';
import React from 'react';

const TransmissionIcon = ({ transport, fillColor, circle }: {transport: string, fillColor?: string, circle?: boolean}): JSX.Element | null => {
  switch (transport) {
    case 'GSM':
    case 'Cellular': return (
      <SvgIcon xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
        { circle && <circle fill="#fff" cx="12" cy="12" r="12" /> }
        <path fill={fillColor || 'rgba(0,0,0,1)'} d="M15,6H9C8.17,6,7.5,6.67,7.5,7.5v9C7.5,17.33,8.17,18,9,18h6c0.83,0,1.5-0.67,1.5-1.5v-9C16.5,6.67,15.83,6,15,6z M15.5,15c0,0.28-0.22,0.5-0.5,0.5H9c-0.28,0-0.5-0.22-0.5-0.5V8c0-0.28,0.22-0.5,0.5-0.5h6c0.28,0,0.5,0.22,0.5,0.5V15z" />
      </SvgIcon>
    );

    case 'Satellite': return (
      <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
        { circle && <circle fill="#fff" cx="12" cy="12" r="12" /> }
        <path fill={fillColor || 'rgba(0,0,0,1)'} d="M10.5,18H10c-2.21,0-4-1.79-4-4v-0.5h1V14c0,1.66,1.34,3,3,3h0.5V18z" />
        <path fill={fillColor || 'rgba(0,0,0,1)'} d="M10.5,16H10c-1.1,0-2-0.9-2-2v-0.5h1V14c0,0.55,0.45,1,1,1h0.5V16z" />
        <path fill={fillColor || 'rgba(0,0,0,1)'} d="M16,17.5L13.5,15v-1.29l-0.5-0.5l-1.5,1.5L9.29,12.5l1.5-1.5l-0.5-0.5H9L6.5,8l2-2L11,8.5v1.29l0.5,0.5l2.84-2.84c0.61-0.61,1.6-0.61,2.21,0s0.61,1.6,0,2.21l-0.09,0.09l-1.1-1.1l-0.71,0.71l1.1,1.1l-2.04,2.04l0.5,0.5h1.29l2.5,2.5L16,17.5z" />
      </SvgIcon>
    );

    default: return null;
  }
};

export default TransmissionIcon;
