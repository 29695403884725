import React from 'react';

const Landing = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <circle className="icon-base" cx="8" cy="8" r="8" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M8.87,7.3l-3.3-1.94l0.06-0.9L4.58,3.85L3.85,6.69l2.09,1.23L3.87,9.04l1.39,0.82l2.07-1.12l0.52,0.31
c0.49,0.29,1.09,0.13,1.38-0.36l0,0C9.51,8.2,9.35,7.59,8.87,7.3z M8.09,5.91l0.03-0.92L6.73,4.17l0.01,0.94L8.09,5.91z"
    />
    <rect className="icon-detail" fill="#fff" x="6.27" y="11.46" width="5.89" height="0.69" />
  </svg>
);

export default Landing;
