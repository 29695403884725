import React from 'react';
import Modal from 'react-modal';
import {
  Box,
  Button
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import useStyles from './formModal-styles';

interface FormProps {
  report: Report;
  onClose: () => void;
}

function FormModal({
  report,
  onClose
}: FormProps): JSX.Element {
  const classes = useStyles();

  const htmlQuery = useQuery({
    queryKey: ['form', report.form?.id || -1, report.form?.version || -1],
    queryFn: () => {
      if (report.form?.location) {
        // this hack is to avoid cors errors calling functions in iframes... :/
        return fetch(report.form.location).then(r => r.text());
      }
      return '';
    }
  });

  if (!report.form) {
    onClose();
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={classes.modal}
      overlayClassName={classes.modalOverlay}
    >
      <Box className={classes.modalContent}>
        {htmlQuery.data
          ? (
            <iframe
              title="form"
              className={classes.formIframe}
              srcDoc={htmlQuery.data}
              onLoad={e => {
                // @ts-ignore
                e.target?.contentWindow.setState(JSON.stringify(report.form.data));
              }}
              width={660}
              height={550}
            />
          ) : (
            <Box sx={{ width: 660, height: 550, display: 'flex' }}>
              <LoadingIcon size={100} />
            </Box>
          )}
      </Box>
      <Box className={classes.footer}>
        <Button variant="contained" onClick={onClose} tabIndex={-1}>Close</Button>
      </Box>
    </Modal>
  );
}

export default FormModal;
