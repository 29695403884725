import { useContext } from 'react';
import { FeaturesContext } from 'contexts/features/featuresContextValue';
import { OrganisationFeature } from 'apis/rest/features/types';

/**
 * @deprecated This is for feature toggles which will soon be removed
 */
const useFeatures = (): OrganisationFeature[] | undefined => {
  const { data } = useContext(FeaturesContext);

  return data;
};

export default useFeatures;
