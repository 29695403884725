import React from 'react';
import ScrollSpy from 'react-scrollspy';
import {
  Box,
  Typography,
  ListItem,
  ListItemText
} from '@mui/material';
import clsx from 'clsx';

import Badge from '@mui/material/Badge';
import useStyles from './tableOfContents-styles';

const TableOfContents = ({
  rootElId,
  categories
}) => {
  const classes = useStyles();

  const contentItem = text => (
    <ListItemText>
      <Typography variant="body1" className={classes.text}>{text}</Typography>
    </ListItemText>
  );

  // This can optionally show an error or warning indicator on any item by setting 'hasError' or 'hasWarning' respectively to true for that item.
  return (
    <Box className={classes.wrapper}>
      <ScrollSpy
        rootEl={rootElId}
        items={categories.map(c => c.id)}
        className={classes.list}
        currentClassName="is-current"
      >
        {categories.map(c => (
          <a className={classes.link} key={c.id} href={`#${c.id}`}>
            <ListItem className={clsx(classes.item, c.type)}>
              {c.visible
                ? (
                  <Badge
                    overlap="circular"
                    badgeContent="!"
                    color="error"
                    className={clsx([classes.TOCbadge, { warning: c.hasWarning }, { error: c.hasError }])}
                  >
                    {contentItem(c.title)}
                  </Badge>
                )
                : contentItem(c.title) }
            </ListItem>
          </a>
        ))}
      </ScrollSpy>
    </Box>
  );
};

export default TableOfContents;
