import { useMemo } from 'react';
import { PathLayer, IconLayer } from '@deck.gl/layers';
import bearing from '@turf/bearing';
import { Coord, Polyline } from 'repositories/reports/spline';
import { hexToRGBArray } from 'helpers/color';
import { DropAdditive } from 'helpers/drops';
import { Drop, Segment } from 'repositories/reports/reports';
import { useAssetSegments } from 'repositories/reports/hooks';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';

const WHITE: ColorAlpha = [255, 255, 255, 255];

interface PathFeature {
  path: Polyline
}

const triplicate = <T extends PathFeature,>(trails: T[]): T[] => trails.flatMap(feature => [
  {
    ...feature,
    path: feature.path.map(([lon, lat]) => ([lon - 360, lat]))
  },
  feature,
  {
    ...feature,
    path: feature.path.map(([lon, lat]) => ([lon + 360, lat]))
  }
]);

const ICONSIZE = 20;

const ADDITIVE_ORDER: (DropAdditive | undefined)[] = [undefined, 'none', 'retardant', 'foam'];
const ADDITIVE_COLORS: Record<DropAdditive, string> = {
  foam: '#b0b0b0',
  gel: '#6060e5',
  none: '#9d09ce',
  retardant: '#ff8000'
};

interface ValidDrop extends Segment<Drop> {
  splines: [Polyline, ...Polyline[]]
}

const startIconCreator = (fillColor: string, strokeColor: string) => `<?xml version="1.0" encoding="UTF-8"?><svg width="64" height="64" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path transform="matrix(5.6368 0 0 5.6368 17.613 8.3791)" d="m6.2059 4.1905-7.307 4.2187v-8.4374z" fill="${fillColor}" stroke="${strokeColor}" stroke-linejoin="round" stroke-width="3" style="paint-order:stroke markers fill"/></svg>`;
const stopIconCreator = (fillColor: string, strokeColor: string) => `<?xml version="1.0" encoding="UTF-8"?><svg width="64" height="64" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><rect x="25.993" y="7.0524" width="12.013" height="49.895" fill="${fillColor}" stroke="${strokeColor}" stroke-linejoin="round" stroke-width="15" style="paint-order:stroke markers fill"/></svg>`;
const transparent = '#00000000';
const white = '#fff';

const startOutlineIcon = btoa(startIconCreator(white, white));
const stopOutlineIcon = btoa(stopIconCreator(white, white));

const iconBase = {
  width: ICONSIZE,
  height: ICONSIZE,
  anchorX: ICONSIZE / 2,
  anchorY: ICONSIZE / 2,
  mask: true
};

const isValidDrop = (drop: Segment<Drop>): drop is ValidDrop => (drop.splines ? drop.splines.length > 0 : false);

const dropToIcons = (drop: Segment<Drop>, use3d: boolean) => {
  if (!isValidDrop(drop)) return [];

  const firstSpline = drop.splines[0];
  const lastSpline = drop.splines.at(-1);
  if (!lastSpline || lastSpline.length < 2) return [];

  const dropColor = ADDITIVE_COLORS[drop.meta.additive ?? 'none'];

  const endPosition = firstSpline[0];
  const startPosition = lastSpline.at(-1) as Coord;

  return [
    {
      icon: {
        url: `data:image/svg+xml;base64,${btoa(stopIconCreator(dropColor, transparent))}`,
        ...iconBase
      },
      outlineIcon: {
        url: `data:image/svg+xml;base64,${stopOutlineIcon}`,
        ...iconBase
      },
      position: use3d ? endPosition : [endPosition[0], endPosition[1], 0],
      angle: 90 - bearing(firstSpline[1], firstSpline[0]),
      color: hexToRGBArray(dropColor)
    },
    {
      icon: {
        url: `data:image/svg+xml;base64,${btoa(startIconCreator(dropColor, transparent))}`,
        ...iconBase
      },
      outlineIcon: {
        url: `data:image/svg+xml;base64,${startOutlineIcon}`,
        ...iconBase
      },
      position: use3d ? startPosition : [startPosition[0], startPosition[1], 0],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      angle: 90 - bearing(lastSpline.at(-1)!, lastSpline.at(-2)!),
      color: hexToRGBArray(dropColor)
    },
  ];
};

export const useDropLayers = (
  visible: boolean,
  assets: AssetBasic[],
  selectedAssetId: number | undefined,
  selectedLeg: Leg | null,
  trailsOption: number,
  showStartEnd: boolean,
  trailWidth: number,
  use3d = false
) => {
  const assetsWithTrails = useMemo(() => {
    switch (trailsOption) {
      case TRAILS_OPTIONS.noTrails: return [];
      case TRAILS_OPTIONS.selectedTrails:
      case TRAILS_OPTIONS.selectedTrailsIcons: return assets.filter(asset => asset.id === selectedAssetId);
      case TRAILS_OPTIONS.allTrailsIcons: return assets;
      default: return assets;
    }
  }, [assets, selectedAssetId, trailsOption]);

  const assetDrops = useAssetSegments(assetsWithTrails, 'DROP', selectedLeg);

  const icons = useMemo(() => (
    Object.values(assetDrops).flat().flatMap(d => dropToIcons(d, use3d))
  ), [assetDrops, use3d]);

  const trails = useMemo(() => (
    triplicate(Object.values(assetDrops)
      .flat()
      .filter(isValidDrop)
      .sort((a, b) => ADDITIVE_ORDER.indexOf(a.meta.additive) - ADDITIVE_ORDER.indexOf(b.meta.additive))
      .flatMap(d => d.splines.map(path => ({ color: hexToRGBArray(ADDITIVE_COLORS[d.meta.additive ?? 'none']), path: use3d ? path : path.map(c => [c[0], c[1], 0]) }))))
  ), [assetDrops, use3d]);

  const layers = useMemo(() => [
    new IconLayer({
      id: `drops-icon-outline-layer-${use3d ? '3d' : '2d'}`,
      data: icons,
      getIcon: d => d.outlineIcon,
      getAngle: d => d.angle,
      pickable: false,
      getColor: WHITE,
      opacity: 1,
      getSize: ICONSIZE,
      billboard: false,
      parameters: { depthTest: false },
      visible: visible && showStartEnd,
    }),
    new PathLayer({
      id: `drops-outline-layer-${use3d ? '3d' : '2d'}`,
      data: trails,
      pickable: false,
      getColor: WHITE,
      getWidth: trailWidth + 6,
      opacity: 1,
      widthUnits: 'pixels',
      widthScale: 1,
      jointRounded: true,
      capRounded: true,
      parameters: { depthTest: false },
      visible,
    }),
    new PathLayer({
      id: `drops-layer-${use3d ? '3d' : '2d'}`,
      data: trails,
      pickable: false,
      getColor: d => d.color,
      getWidth: trailWidth + 2,
      opacity: 1,
      widthUnits: 'pixels',
      widthScale: 1,
      jointRounded: true,
      capRounded: true,
      parameters: { depthTest: false },
      visible,
    }),
    new IconLayer({
      id: `drops-icon-layer-${use3d ? '3d' : '2d'}`,
      data: icons,
      getAngle: d => d.angle,
      pickable: false,
      getColor: d => d.color,
      opacity: 1,
      getSize: ICONSIZE,
      billboard: false,
      parameters: { depthTest: false },
      visible: visible && showStartEnd
    }),
  ] as const, [use3d, icons, visible, showStartEnd, trails, trailWidth]);

  return layers;
};

export default useDropLayers;
