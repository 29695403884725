import { IntermediaryMapTemplate } from './types';
import { getName } from '../helpers';

const MapTemplate = (id: string, lang: string): IntermediaryMapTemplate => ({
  mapboxStyle: 'mapbox://styles/mapbox/satellite-v9',
  thumbnailUrl: '/img/base-layer-thumbnails/satellite-v9.jpg',
  name: getName(id, lang),
  providerId: 'mapbox',
  dark: true,
  maxzoom: 19,
});

export default MapTemplate;
