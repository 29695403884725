import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { UseMutationResult } from '@tanstack/react-query';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { HttpResponseError } from 'helpers/api';
import React, { ReactNode, useState } from 'react';
import { useTranslations } from 'use-intl';
import { ValidateNameResult } from 'helpers/validation/validateName';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';

export interface AddAssetGroupProps {
  open: boolean;
  mutation: UseMutationResult<AssetGroup, HttpResponseError, Pick<AssetGroup, 'name'>>;
  cancel: () => void;
  created: (group: AssetGroup) => void;
  title: ReactNode;
  ariaLabel: string;
  validateName: (name: string) => ValidateNameResult | undefined;
}

export const AddAssetGroupDialog = ({
  open,
  mutation,
  cancel,
  created,
  title,
  ariaLabel,
  validateName,
}: AddAssetGroupProps): JSX.Element => {
  const t = useTranslations('pages.manage.assetGroups.createGroup');
  const [name, setName] = useState('');
  const validation = validateName(name);

  const createNewAssetGroup = () => mutation.mutate(
    { name: name.trim() },
    { onSuccess: group => created(group) }
  );

  const onExited = () => {
    setName('');
    mutation.reset();
  };

  const canSave = !mutation.isSuccess && !mutation.isPending && validation?.valid;

  return (
    <Dialog
      open={open && !mutation.isSuccess}
      aria-label={ariaLabel}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{title}</TPDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3} pt={3}>
          <TextField
            label={t('textFieldLabel')}
            value={name}
            onChange={event => setName(event.target.value)}
            error={!mutation.isSuccess && (mutation.isError || (!validation?.valid && !validation?.missing))}
            helperText={mutation.isError ? mutation.error?.message : (!mutation.isSuccess && !validation?.missing && validation?.helperText) ? t(`validation.${validation.helperText}`) : undefined}
            disabled={mutation.isPending}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3,
          borderTop: 1,
          borderColor: 'common.midGrey',
          justifyContent: 'space-between',
        }}
      >
        <Box flex={1}>
          <Stack
            spacing={3}
            direction="row"
            height="4em"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={cancel}
              disabled={mutation.isSuccess || mutation.isPending}
            >
              {t('cancelButtonText')}
            </Button>
            <Button
              size="large"
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={createNewAssetGroup}
              disabled={!canSave}>
              {t((mutation.isPending || mutation.isSuccess) ? 'savingButtonText' : 'saveButtonText')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
