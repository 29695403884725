import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import {
  archiveAsset,
  createAsset,
  CreateAssetBody,
  getAmsSettings,
  getAsset,
  getAssetAssignedToDevice,
  getAssets,
  getIncidentEscalationSettings,
  unregisterAms,
  updateAmsSettings,
  updateAsset,
  UpdateAssetBody,
  updateAssetCallsign,
  UpdateAssetCallsignBody,
  updateIncidentEscalationSettings,
} from './requests';
import { deviceQueryKeys } from '../devices/queryKeys';
import { assetsQueryKeys } from './queryKeys';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

// By default this filters out archived assets. Override the options.select parameter to change this behaviour.
export const useGetAssetsList = <T = AssetBasic[]>(options?: Options<AssetBasic[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = assetsQueryKeys.lists(organisationId);
  const filterArchivedAssets = useCallback((data: AssetBasic[]): T => data.filter(a => !a.archived) as T, []);

  const query = useQuery({
    queryKey,
    queryFn: () => getAssets(organisationId),
    select: filterArchivedAssets,
    ...options,
  });

  return { query, queryKey };
};

export const useGetAssetBasic = (assetId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.detail(organisationId, assetId), [organisationId, assetId]);
  const query = useQuery({
    queryKey,
    queryFn: () => getAsset(organisationId, assetId),
    enabled,
  });
  return { query, queryKey };
};

export const useGetAssetBasicAssignedToDevice = (deviceId: number, enabled = true) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => deviceQueryKeys.asset(organisationId, deviceId), [organisationId, deviceId]);
  const query = useQuery({
    queryKey,
    queryFn: () => getAssetAssignedToDevice(organisationId, deviceId),
    enabled,
  });
  return { query, queryKey };
};

export const useCreateAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Omit<CreateAssetBody, 'ownerId'>) => createAsset(organisationId, { ...value, ownerId: organisationId }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    mutationKey: ['createAsset'],
  });
};

export const useUpdateAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: UpdateAssetBody) => updateAsset(organisationId, value),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    mutationKey: ['updateAsset'],
  });
};

export const useUpdateAssetCallsign = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: UpdateAssetCallsignBody) => updateAssetCallsign(organisationId, value),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    mutationKey: ['updateAssetCallsign'],
  });
};

export const useArchiveAsset = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: { assetId: number, archived: boolean }) => archiveAsset(organisationId, value.assetId, value.archived),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.all(organisationId) }),
    mutationKey: ['archiveAsset'],
  });
};

export const useGetAmsSettings = <T = AMSSettings>(assetId: number, options?: Options<AMSSettings, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.ams(organisationId, assetId), [organisationId, assetId]);

  return useQuery({
    queryKey,
    queryFn: () => getAmsSettings(organisationId, assetId),
    ...options,
  });
};

export const useGetIncidentEscalationSettings = <T = IncidentEscalationSettings>(assetId: number, options?: Options<IncidentEscalationSettings, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = useMemo(() => assetsQueryKeys.incident(organisationId, assetId), [organisationId, assetId]);

  return useQuery({
    queryKey,
    queryFn: () => getIncidentEscalationSettings(organisationId, assetId),
    ...options
  });
};

export const useDeregisterAms = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ assetId }: { assetId: number }) => unregisterAms(organisationId, assetId),
    onSuccess: (_, { assetId }) => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.ams(organisationId, assetId) }),
    mutationKey: ['deregisterAms'],
  });
};

export const useSetAmsSettings = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: AMSSettings) => updateAmsSettings(organisationId, value.assetId, value),
    onSuccess: (_, { assetId }) => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.ams(organisationId, assetId) }),
    mutationKey: ['updateAms'],
  });
};

export const useSetIncidentEscalationSettings = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: IncidentEscalationSettings) => updateIncidentEscalationSettings(organisationId, value.assetId, value),
    onSuccess: (_, { assetId }) => queryClient.invalidateQueries({ queryKey: assetsQueryKeys.incident(organisationId, assetId) }),
    mutationKey: ['updateIncidentEscalation'],
  });
};
