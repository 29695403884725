import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import AssetQuery from './asset-query';

export default Smart(
  AssetQuery,
  state => ({
    permissions: permissionsForCurrentUserAndOrg(state),
  }),
  ac => ({
  })
);
