import React from 'react';
import { useTranslations } from 'use-intl';
import { area, bearing, distance, duration, speed, volume } from 'helpers/unitsOfMeasure';
import { Alert, Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { updateLocalUnits, updateUnitSettings } from 'slices/settings/unitSettings.slice';
import { generalSettingsSection } from 'components/statusbar/generalSettings/types';
import { Settings, updateSetting } from 'slices/settings.slice';
import { useAppDispatch } from 'store/types';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useUnitSettings } from "hooks/settings/useUnitSettings";

export const UnitFirestoreSettingsSelectorList = () => {
  const t = useTranslations('pages.generalSettings');
  const makeOption = (tag: string | number, units: string) => ({
    id: tag,
    label: units,
    tooltip: t(`tooltips.${tag}`),
  });

  const unitSettings = useUnitSettings();
  const appDispatch = useAppDispatch();
  const isUsercodeUser = useIsUsercodeLogin();

  const unitsSettingsTemplate: generalSettingsSection = {
    id: 'units',
    label: t('unitsLabel'),
    description: t('unitsDescription'),
    fields: [{
      id: 'distance',
      label: t('distanceLabel'),
      options: [
        makeOption('kilometres', distance.label('kilometres')),
        makeOption('statuteMiles', distance.label('statuteMiles')),
        makeOption('nauticalMiles', distance.label('nauticalMiles'))
      ]
    }, {
      id: 'speed',
      label: t('speedLabel'),
      options: [
        makeOption('kmh', speed.label('kmh')),
        makeOption('mph', speed.label('mph')),
        makeOption('knots', speed.label('knots'))
      ]
    }, {
      id: 'speedAir',
      label: t('speedLabelAir'),
      options: [
        makeOption('kmh', speed.label('kmh')),
        makeOption('mph', speed.label('mph')),
        makeOption('knots', speed.label('knots'))
      ]
    }, {
      id: 'speedLand',
      label: t('speedLabelLand'),
      options: [
        makeOption('kmh', speed.label('kmh')),
        makeOption('mph', speed.label('mph')),
      ]
    }, {
      id: 'speedSea',
      label: t('speedLabelSea'),
      options: [
        makeOption('kmh', speed.label('kmh')),
        makeOption('mph', speed.label('mph')),
        makeOption('knots', speed.label('knots'))
      ]
    }, {
      id: 'speedPerson',
      label: t('speedLabelPerson'),
      options: [
        makeOption('kmh', speed.label('kmh')),
        makeOption('mph', speed.label('mph')),
        makeOption('knots', speed.label('knots'))
      ]
    }, {
      id: 'altitude',
      label: t('altitudeLabel'),
      options: [
        makeOption('metres', distance.label('metres')),
        makeOption('feet', distance.label('feet'))
      ]
    }, {
      id: 'bearing',
      label: t('bearingLabel'),
      options: [
        makeOption('degreesTrue', bearing.label('degreesTrue')),
        makeOption('degreesMagnetic', bearing.label('degreesMagnetic'))
      ]
    }, {
      id: 'coordinate',
      label: t('coordinateLabel'),
      options: [
        makeOption('coordinatesDD', t('unitDescriptions.coordinatesDD')),
        makeOption('coordinatesDMS', t('unitDescriptions.coordinatesDMS')),
        makeOption('coordinatesDDM', t('unitDescriptions.coordinatesDDM'))
      ]
    }, {
      id: 'area',
      label: t('areaLabel'),
      options: [
        makeOption('squareKilometres', area.label('squareKilometres')),
        makeOption('acres', area.label('acres')),
        makeOption('hectares', area.label('hectares')),
        makeOption('squareMiles', area.label('squareMiles')),
        makeOption('squareNauticalMiles', area.label('squareNauticalMiles'))
      ]
    }, {
      id: 'volume',
      label: t('volume'),
      options: [
        makeOption('litres', volume.label('litres')),
        makeOption('gallons', volume.label('gallons')),
      ]
    }, {
      id: 'duration',
      label: t('duration'),
      options: [
        makeOption('hoursMinutes', duration.label('hoursMinutes')),
        makeOption('decimalTime', duration.label('decimalTime')),
      ]
    }]
  };

  const handleSettingChange = (event: React.MouseEvent<HTMLElement>, newValue: string, fieldId: keyof ReduxState['unitSettings']['units']) => {
    if (newValue !== null) {
      // create new payload
      const payload: ReduxState['unitSettings']['units'] = {
        ...unitSettings,
        [fieldId]: newValue
      };
      if (isUsercodeUser) {
        appDispatch(updateSetting({ category: 'units', field: fieldId as keyof ReduxState['settings']['units'], value: newValue }));
        appDispatch(updateLocalUnits(payload));
      } else {
        appDispatch(updateUnitSettings(payload));
      }
    }
  };

  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Box>
        <Typography paragraph>{unitsSettingsTemplate.description}</Typography>
      </Box>
      {isUsercodeUser && (<Alert severity="warning" variant="outlined" sx={{ alignItems: 'center' }}>{t('warnings.usercodeBrowserStorageOnly')}</Alert>)}
      {unitsSettingsTemplate.fields.map(field => (
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={7}>
            <Typography variant="h5" gutterBottom>{field.label}</Typography>
          </Grid>
          <Grid item xs={5} container justifyContent="right">
            <ToggleButtonGroup
              value={unitSettings[field.id as keyof Settings['units']]}
              exclusive
              onChange={(event, newValue) => handleSettingChange(event, newValue, field.id as keyof ReduxState['unitSettings']['units'])}
            >
              {field.options.map(option => (
                <Tooltip title={option.tooltip} placement="top">
                  <ToggleButton value={option.id} sx={{ textTransform: 'none' }}>
                    {option.label}
                  </ToggleButton>
                </Tooltip>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
};
