import Alert, { AlertProps } from '@mui/material/Alert';
import { ErrorOutline, CheckCircleOutlined, WarningOutlined, InfoOutlined } from '@mui/icons-material';
import React from 'react';
import useStyles from './alertSmall-styles';

export const AlertSmall = (props: AlertProps) => {
  const classes = useStyles();
  return (
    <Alert
      className={classes.alert}
      iconMapping={{
        error: <ErrorOutline fontSize="small" sx={{ mr: -1 }} />,
        success: <CheckCircleOutlined fontSize="small" sx={{ mr: -1 }} />,
        warning: <WarningOutlined fontSize="small" sx={{ mr: -1 }} />,
        info: <InfoOutlined fontSize="small" sx={{ mr: -1 }} />
      }}
      sx={{ fontSize: 'x-small', py: 0.5, px: 2 }}
      {...props}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </Alert>
  );
};
