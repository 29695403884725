import React from 'react';

const Learjet = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 32.997291,57.58453 c 0,0 6.246954,1.07948 6.414253,0.86407 0.167297,-0.21541 0.09239,-3.094953 -0.03727,-3.504262 -0.12966,-0.40931 -3.214097,-1.899428 -3.214097,-1.899428 l 3.72e-4,-1.588322 -1.883226,-5.003894 0.120836,-0.0013 0.843646,1.406704 0.720064,-0.473794 1.064604,0.75177 c 0,0 0.979826,-3.376621 1.165195,-3.744943 0.185369,-0.368323 -0.105018,-5.005538 -0.105018,-5.005538 l 10.843525,0.143664 2.897858,3.007707 0.06852,-3.298214 c 0,0 -1.532617,-4.591171 -1.708032,-4.764056 C 50.013106,34.301825 38.637807,31.144665 38.637807,31.144665 L 35.822763,29.4309 c 0,0 0.145483,-11.858864 0.03973,-12.499794 -1.7374,-10.5296828 -3.703357,-10.8018696 -3.703357,-10.8018696 0,0 -1.962951,0.2930746 -3.588295,10.8406356 -0.09893,0.642018 0.172634,12.498665 0.172634,12.498665 l -2.796663,1.743599 c 0,0 -11.341087,3.27793 -11.514654,3.452669 -0.173567,0.17474 -1.657281,4.781948 -1.657281,4.781948 l 0.10358,3.297299 2.865715,-3.038349 10.841384,-0.25895 c 0,0 -0.241065,4.64004 -0.05179,5.006371 0.189275,0.366331 1.204947,3.732343 1.204947,3.732343 l 1.056551,-0.763047 0.725061,0.466111 0.828641,-1.415595 h 0.120843 l -1.829915,5.023635 0.01726,1.588228 c 0,0 -3.068419,1.52283 -3.19372,1.933495 -0.125301,0.410664 -0.169582,3.290841 -4e-6,3.50446 0.169579,0.213619 6.404703,-0.932221 6.404703,-0.932221 0,0 0.361512,0.923994 0.569366,0.897696 0.207855,-0.0263 0.55979,-0.903699 0.55979,-0.903699 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Learjet;
