import React from 'react';

const PA750 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 30.513051,7.9473871 c -0.919225,0.9979386 -0.877083,2.2725349 -0.877083,2.2725349 0,0 -0.95386,7.85557 -1.298903,8.886685 -0.417466,1.24754 -0.805408,4.186119 -0.805408,4.186119 0,0 -21.4438919,0.187479 -23.1632209,0.09822 C 1.9787549,23.266888 2.9759944,34.076551 2.9759944,34.076551 l 25.0910386,-0.05653 c 0,0 0.911705,8.909268 1.849418,16.902049 0,0 -0.486599,0.02283 -8.931349,0.186233 -1.060497,0.02052 -0.415507,6.784011 -0.415507,6.784011 7.851233,-0.05114 15.702348,-0.02323 23.55359,0 0,0 0.64499,-6.763491 -0.415507,-6.784011 -8.44475,-0.163403 -8.931349,-0.186233 -8.931349,-0.186233 0.937713,-7.992781 1.849418,-16.902049 1.849418,-16.902049 l 25.091039,0.05653 c 0,0 0.997239,-10.809663 -1.392442,-10.685605 -1.719329,0.08926 -23.163221,-0.09822 -23.163221,-0.09822 0,0 -0.387942,-2.938579 -0.805408,-4.186119 -0.345043,-1.031115 -1.298903,-8.886685 -1.298903,-8.886685 0,0 0.04214,-1.2745963 -0.877083,-2.2725349 -0.980076,0.00294 -0.776032,-1.7659457 -1.833339,-2.2251024 -1.333916,0.3790073 -1.053499,2.275385 -1.833339,2.2251024 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default PA750;
