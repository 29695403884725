import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useMutateNewContact, useMutateUpdateContact } from 'apis/rest/people/hooks';
import { PhoneInput, useValidatePhone } from 'components/pages/manage/people/edit/phoneContacts';
import { EmailInput, useValidateEmail } from 'components/pages/manage/people/edit/emailContacts';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import useUndeletablePeopleIds from 'hooks/people/useUndeletablePeopleIds';
import { Validation } from '../editPersonContact/editPersonContact-dialog';

interface AddContactsDialogProps {
  person: Person | undefined
  done: () => void
  cancelLabel?: ReactNode
}

type ContactType = 'sms' | 'phone' | 'email';
interface ExistingDefaultContacts {
  sms?: Contact
  phone?: Contact
  email?: Contact
}

const initialContacts = { sms: '', phone: '', email: '' };
const initialContactsSaved = { sms: false, phone: false, email: false };

const checkSaveRequirements = (
  validations: Record<ContactType, Validation>,
  existingContacts: ExistingDefaultContacts,
  values: Record<ContactType, string>,
  requirePhoneNumbers: boolean | undefined,
) => {
  if (requirePhoneNumbers === undefined) return false;
  if (requirePhoneNumbers && (validations.sms.missing || validations.phone.missing)) return false;

  if (existingContacts.sms && validations.sms.missing) return false;
  if (existingContacts.phone && validations.phone.missing) return false;
  if (existingContacts.email && validations.email.missing) return false;

  if (!Object.values(validations).every(v => v.missing || v.valid)) return false;

  return (Object.keys(validations) as ContactType[])
    .some(contactType => validations[contactType].valid && existingContacts[contactType]?.contactValue !== values[contactType]);
};

const AddContactsDialog = ({ person, done, cancelLabel }: AddContactsDialogProps) => {
  const t = useTranslations('dialogs.people.addContacts');
  const tPhone = useTranslations('pages.manage.person.phone');
  const [contacts, setContacts] = useState<Record<ContactType, string>>(initialContacts);
  const [contactsSaved, setContactsSaved] = useState<Record<ContactType, boolean | Error>>(initialContactsSaved);
  const undeletablePeopleIds = useUndeletablePeopleIds();
  const requirePhoneNumbers = person && undeletablePeopleIds?.includes(person.id);

  const existingContacts = useMemo<ExistingDefaultContacts>(() => person?.contacts.reduce<ExistingDefaultContacts>((acc, c) => {
    if (c.isDefault) acc[c.contactType as ContactType] = c;
    return acc;
  }, {}) ?? {}, [person]);

  useEffect(() => {
    setContacts({
      sms: existingContacts.sms?.contactValue ?? '',
      phone: existingContacts.phone?.contactValue ?? '',
      email: existingContacts.email?.contactValue ?? '',
    });
  }, [existingContacts, setContacts, setContactsSaved]);

  const validations = {
    sms: useValidatePhone(person?.contacts.filter(c => c.contactType === 'sms').map(c => ({ value: c.contactValue })) ?? [], 'sms')(contacts.sms, existingContacts.sms?.contactValue ?? null),
    phone: useValidatePhone(person?.contacts.filter(c => c.contactType === 'phone').map(c => ({ value: c.contactValue })) ?? [], 'phone')(contacts.phone, existingContacts.phone?.contactValue ?? null),
    email: useValidateEmail(person?.contacts.filter(c => c.contactType === 'email').map(c => ({ value: c.contactValue })) ?? [])(contacts.email, existingContacts.email?.contactValue ?? null),
  };

  const newContactMutation = useMutateNewContact();
  const updateContactMutation = useMutateUpdateContact();

  const save = async (): Promise<void> => {
    if (!person) return;
    const peopleId = person.id;

    const tasks = (Object.entries(contacts) as [ContactType, string][]).map(async ([contactType, contactValue]) => {
      if (contactsSaved[contactType] === true || !contactValue) return;
      const existingContact = existingContacts[contactType];

      try {
        if (existingContact) {
          if (existingContact.contactValue === contactValue) return;
          await updateContactMutation.mutateAsync({ ...existingContact, contactValue });
        } else {
          await newContactMutation.mutateAsync({ peopleId, isDefault: true, contactType, contactValue });
        }
        setContactsSaved(c => ({ ...c, [contactType]: true }));
      } catch (error) {
        setContactsSaved(c => ({ ...c, [contactType]: error }));
        throw error;
      }
    });

    if (await Promise.all(tasks)) done();
  };

  const canSave = !newContactMutation.isPending && checkSaveRequirements(validations, existingContacts, contacts, requirePhoneNumbers);

  const savedAdornment = (
    <InputAdornment position="end">
      <Typography fontStyle="italic" color="success.main">{t('saved')}</Typography>
    </InputAdornment>
  );

  const onExited = () => {
    setContacts(initialContacts);
    setContactsSaved(initialContactsSaved);
    newContactMutation.reset();
  };

  return (
    <Dialog
      open={!!person}
      aria-label={t('title', { name: person?.name })}
      fullWidth
      maxWidth="sm"
      TransitionProps={{ onExited }}
    >
      <TPDialogTitle>{t('title', { name: person?.name })}</TPDialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Stack spacing={3} pt={3}>
          <Box>
            <PhoneInput
              label={t('sms')}
              value={contacts.sms}
              setValue={value => setContacts(c => ({ ...c, sms: value }))}
              validation={validations.sms}
              disabled={newContactMutation.isPending || contactsSaved.sms === true}
              InputProps={{
                endAdornment: contactsSaved.sms === true && savedAdornment,
              }}
              allowMissing={!existingContacts.sms && !requirePhoneNumbers}
              autoFocus
            />
            <Typography mt={1}>
              {tPhone.rich('smsDisclaimer', {
                privacyPolicy: chunks => <a href="https://tracplus.com/tracplus-privacy-policy" target="_blank" rel="noreferrer">{chunks}</a>,
                termsAndConditions: chunks => <a href="https://tracplus.com/terms-and-conditions" target="_blank" rel="noreferrer">{chunks}</a>,
              })}
            </Typography>
          </Box>
          <PhoneInput
            label={t('phone')}
            value={contacts.phone}
            setValue={value => setContacts(c => ({ ...c, phone: value }))}
            validation={validations.phone}
            disabled={newContactMutation.isPending || contactsSaved.phone === true}
            InputProps={{
              endAdornment: contactsSaved.phone === true ? savedAdornment : (
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    onClick={() => setContacts(c => ({ ...c, phone: c.sms }))}
                    disabled={!validations.sms.valid || contacts.phone === contacts.sms}
                  >
                    {t('copyFromSms')}
                  </Button>
                </InputAdornment>
              ),
            }}
            allowMissing={!existingContacts.phone && !requirePhoneNumbers}
          />
          <EmailInput
            label={t('email')}
            value={contacts.email}
            setValue={value => setContacts(c => ({ ...c, email: value }))}
            validation={validations.email}
            disabled={newContactMutation.isPending || contactsSaved.email === true}
            InputProps={{
              endAdornment: contactsSaved.email === true && savedAdornment,
            }}
            allowMissing={!existingContacts.email}
          />
          {requirePhoneNumbers ? (
            <Alert severity={(validations.sms.valid && validations.phone.valid) ? 'success' : 'error'}>
              {t('requirementsImportant')}
            </Alert>
          ) : (
            <Alert severity={(validations.sms.valid && validations.phone.valid) ? 'success' : 'warning'}>
              {t('requirements')}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Box flex={1}>
          <Collapse in={Object.values(contactsSaved).some(saved => saved instanceof Error)}>
            <Alert sx={{ mb: 3 }} severity="error">{t('alert.error')}</Alert>
          </Collapse>
          <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              onClick={() => done()}
              disabled={newContactMutation.isPending}
            >
              {cancelLabel ?? t('cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={() => save()}
              disabled={!canSave}
            >
              {t(newContactMutation.isPending ? 'saving' : 'save')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddContactsDialog;
