import { HTMLInputTypeAttribute } from 'react';

export const FieldTypesToInputTypes: Record<SupplementaryDataFieldType['dataType'], HTMLInputTypeAttribute> = {
  Int: 'number',
  Double: 'number',
  String: 'text',
  Boolean: 'boolean',
};

export const FieldAccessor: Record<SupplementaryDataFieldType['dataType'], 'doubleValue' | 'intValue' | 'stringValue' | 'booleanValue'> = {
  Int: 'intValue',
  Double: 'doubleValue',
  String: 'stringValue',
  Boolean: 'booleanValue'
};
