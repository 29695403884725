export const validateSingle = email => {
  const emailIsValid = /^.+@.+$/.test(email);
  if (!email) return 'emailRequired';
  if (!emailIsValid) return 'invalidEmail';
  return false;
};

export const validate = email => {
  const emailError = validateSingle(email);
  if (emailError) return emailError;
  return false;
};
