import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { PathStyleExtension, PathStyleExtensionProps } from '@deck.gl/extensions';
import { IconLayer, LineLayer } from '@deck.gl/layers';
import PointOfInterest from 'components/shared/icons/eventIcons/pointOfInterest';
import { useCursor } from 'contexts/cursor/useCursor';
import { Coord } from 'repositories/reports/spline';
import { useSelector } from 'react-redux';
import { selectMeasurementMarkers, selectMeasurementState } from 'slices/map/multiPointMeasurement.slice';
import AnimatedDashPathLayer from './animatedPathLayer';

const ICONSIZE = 128;
const ICON = {
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<PointOfInterest size={64} />))}`,
  height: ICONSIZE,
  width: ICONSIZE,
  id: 0,
  anchorX: ICONSIZE / 2,
  anchorY: ICONSIZE / 2,
  mask: false
};

const WHITE: ColorAlpha = [255, 255, 255, 255];
const BLACK: ColorAlpha = [0, 0, 0, 255];
const HALF_WHITE: ColorAlpha = [255, 255, 255, 153];
const HALF_BLACK: ColorAlpha = [0, 0, 0, 153];

interface LineDataItem {
  path: Coord[],
  color: ColorAlpha,
  width: number
}

const useMultiMeasureToolLayers = (
  visible: boolean,
  selected: boolean,
  report: Pick<Report, 'longitude' | 'latitude' | 'altitude'> | undefined,
  use3d = false
) => {
  const [cursorPosition] = useCursor();
  const measurementMarkers = useSelector(selectMeasurementMarkers);
  const latestMeasurementMarker = measurementMarkers ? measurementMarkers?.at(-1) : undefined;
  const measurementState = useSelector(selectMeasurementState);

  const markerIcons = useMemo(() => [{
    id: 0,
    size: 5,
    angle: 0,
    icon: ICON,
    position: latestMeasurementMarker ? [latestMeasurementMarker.longitude, latestMeasurementMarker.latitude, use3d ? report?.altitude ?? 0 : 0] : undefined,
  }], [latestMeasurementMarker, report?.altitude, use3d]);

  const cursorIcon = useMemo(() => [{
    id: 1,
    size: 5,
    angle: 0,
    icon: ICON,
    position: (cursorPosition && measurementState === 'create') ? [cursorPosition.longitude, cursorPosition.latitude, use3d ? report?.altitude ?? 0 : 0] : undefined,
  }], [cursorPosition, measurementState, report?.altitude, use3d]);

  const lineData = useMemo<LineDataItem[]>(() => {
    const path: Coord[] = [];
    if (report) {
      path.push(
        [report.longitude, report.latitude, use3d ? report.altitude : 0]
      );
    }
    if (measurementMarkers){
      measurementMarkers.forEach(marker => {
        path.push([marker.longitude, marker.latitude, (use3d && report) ? report.altitude : 0]);
      });
    }

    const cursorPath: Coord[] = [];

    // create mode with asset selected
    if (report && selected && cursorPosition && measurementState === 'create') {
      cursorPath.push(
        [report.longitude, report.latitude, use3d ? report.altitude : 0],
        [cursorPosition.longitude, cursorPosition.latitude, use3d ? report.altitude : 0]
      );
    }

    // append mode
    if (measurementMarkers && cursorPosition && measurementState === 'append') {
      cursorPath.push(
        [measurementMarkers.at(-1).longitude, measurementMarkers.at(-1).latitude, (use3d && report) ? report.altitude : 0],
        [cursorPosition.longitude, cursorPosition.latitude, (use3d && report) ? report.altitude : 0]
      );
    }
    return [
      { path: cursorPath, color: HALF_BLACK, width: 4 },
      { path: cursorPath, color: HALF_WHITE, width: 2 },
      { path, color: BLACK, width: 4 },
      { path, color: WHITE, width: 2 },
    ];
  }, [report, measurementMarkers, selected, cursorPosition, measurementState, use3d]);

  const verticalLineData = useMemo(() => {
    if (!use3d) {
      return [];
    }
    const paths: LineDataItem[] = [];
    if (report && selected && cursorPosition) {
      const path: Coord[] = [
        [cursorPosition.longitude, cursorPosition.latitude, report.altitude],
        [cursorPosition.longitude, cursorPosition.latitude, 0],
      ];
      paths.push(
        { path, color: HALF_BLACK, width: 4 },
        { path, color: HALF_WHITE, width: 2 }
      );
    }

    if (report && latestMeasurementMarker) {
      const path: Coord[] = [
        [latestMeasurementMarker.longitude, latestMeasurementMarker.latitude, report.altitude],
        [latestMeasurementMarker.longitude, latestMeasurementMarker.latitude, 0],
      ];
      paths.push(
        { path, color: BLACK, width: 4 },
        { path, color: WHITE, width: 2 }
      );
    }
    return paths;
  }, [use3d, report, selected, cursorPosition, latestMeasurementMarker]);

  const pathLayer = useMemo(() => new AnimatedDashPathLayer<LineDataItem, PathStyleExtensionProps<LineDataItem>>({
    id: `measure-line-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: lineData,
    pickable: false,
    getColor: d => d.color,
    getWidth: d => d.width,
    capRounded: true,
    widthUnits: 'pixels',
    getDashArray: d => [30 / d.width, 18 / d.width],
    extensions: [new PathStyleExtension({ dash: true })],
    parameters: { depthTest: false },
  }), [use3d, visible, lineData]);

  const verticalLineLayer = useMemo(() => new LineLayer<LineDataItem>({
    id: `measure-vertical-line-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: verticalLineData,
    getSourcePosition: d => d.path[0],
    getTargetPosition: d => d.path[1],
    pickable: false,
    getColor: d => d.color,
    getWidth: d => d.width,
    widthUnits: 'pixels',
  }), [use3d, visible, verticalLineData]);

  const iconLayer = useMemo(() => new IconLayer({
    id: `measure-marker-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: markerIcons,
    pickable: false,
    sizeScale: 1,
    getSize: 20,
    getAngle: 0,
    parameters: { depthTest: false },
  }), [use3d, visible, markerIcons]);

  const cursorIconLayer = useMemo(() => new IconLayer({
    id: `measure-cursor-layer-${use3d ? '3d' : '2d'}`,
    visible,
    data: cursorIcon,
    pickable: false,
    sizeScale: 1,
    getSize: 20,
    getAngle: 0,
    parameters: { depthTest: false },
  }), [use3d, visible, cursorIcon]);

  return [pathLayer, verticalLineLayer, iconLayer, cursorIconLayer];
};

export default useMultiMeasureToolLayers;
