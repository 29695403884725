import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '90%',
    color: 'pink',
    zIndex: 1,
  },
  mapView: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRow: 'span 1',
    gridColumn: 'span 1',
    /* we still fulfill the attribution terms by manually placing it by the map controls like the HERE logo */
    '& a.mapboxgl-ctrl-logo': {
      display: 'none',
    },
  },
}));
