import React from 'react';

const Cessna208 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="m 31.077366,48.004639 c -0.229273,0.05769 -9.911059,0.01292 -11.414021,-0.299822 -0.536466,-0.111629 -0.786534,-4.148765 -0.146993,-4.284011 2.852778,-0.603287 10.118849,-0.998733 10.118849,-0.998733 0,0 0.08973,-1.836949 -0.728952,-6.631057 -0.100493,-0.623574 -0.550296,-6.937313 -0.620466,-8.833492 0,0 -6.983767,0.02788 -10.414184,-0.181518 C 13.125443,26.486288 4.3620473,26.500671 1.9447444,26.014051 1.4425186,25.912949 1.1763436,23.657172 1.8371861,20.390947 1.8998804,20.08108 14.364317,19.878178 19.219157,19.698976 c 3.871608,-0.142916 7.840566,-0.277676 9.117908,-0.592369 0.126293,-0.03111 0.736403,-8.824185 0.736403,-8.824185 0,0 0.02036,-1.5558463 0.939583,-2.5537849 0.296913,-0.3223379 1.222364,-0.1154728 1.286794,-0.5489571 0.103482,-0.6962697 0.294631,-1.4672352 0.858336,-1.4573953 0.554089,0.00967 0.692831,0.7643792 0.793183,1.461107 0.06248,0.4337695 0.988852,0.2310663 1.284313,0.5547355 0.91473,1.0020603 0.928095,2.5579828 0.928095,2.5579828 0,0 0.57058,8.795723 0.696732,8.827406 1.275914,0.320431 5.244226,0.473029 9.115153,0.633346 4.853985,0.201022 17.317384,0.459948 17.378685,0.770094 0.646155,3.269162 0.369843,5.52372 -0.132832,5.622564 -2.419466,0.475749 -11.182709,0.421976 -15.930119,0.690358 -3.431324,0.19398 -10.414895,0.134706 -10.414895,0.134706 -0.07869,1.895844 -0.55687,8.207498 -0.660165,8.830614 -0.840225,4.790379 -0.75875,6.627713 -0.75875,6.627713 0,0 7.26422,0.428102 10.114257,1.044206 0.638927,0.138119 0.370715,4.17409 -0.166247,4.283307 -1.504353,0.305981 -11.186242,0.307235 -11.415254,0.248514 -0.649105,0.634235 -0.768559,0.841305 -0.953294,1.036003 -0.179714,-0.164527 -0.313429,-0.403156 -0.959677,-1.040302 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default Cessna208;
