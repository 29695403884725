import React from 'react';
import Linkify from 'react-linkify';
import { Link } from '@mui/material';

const Linky = ({ children }) => (
  <Linkify
    componentDecorator={(decoratedHref, decoratedText, linkkey) => (
      <Link
        target="_blank"
        rel="noreferrer"
        href={decoratedHref}
        key={linkkey}
        underline="hover"
      >
        {decoratedText}
      </Link>
    )}
  >
    {children}
  </Linkify>
);

export default Linky;
