// Custom fragment shader for OutlinedIconLayer
export default `\
#version 300 es
#define SHADER_NAME outlined-fragment-shader

precision highp float;
uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;
uniform vec4 outlineColor;
uniform float outlineOffset;
uniform float outlineCutoff;

in float vColorMode;
in vec4 vColor;
in vec2 vTextureCoords;
in vec2 uv;
in vec2 iconSize;
in vec2 texSize;

out vec4 fragColor;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main(void) {
  vec4 textureColor = texture(iconsTexture, vTextureCoords);
  fragColor = mix(vColor, outlineColor, textureColor.r);
  fragColor.a = map(textureColor.a, 0.0, 1.0, 0.0, vColor.a);

  fragColor = picking_filterPickingColor(fragColor);
}
`;
