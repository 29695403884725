import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useGetOrganisationFeaturesList } from 'apis/rest/features/hooks';
import { FeaturesContext, FeaturesContextValue } from './featuresContextValue';

const FeaturesContextProvider = ({ children }: { children: ReactNode | undefined }) => {
  const enabled = useSelector<ReduxState, boolean>(state => state.session.loggedIn);
  const organisationId = useOrganisationId();
  const { query } = useGetOrganisationFeaturesList(organisationId, { enabled });

  const value = useMemo<FeaturesContextValue>(() => ({
    isLoading: query.isLoading,
    data: query.data,
  }), [query.isLoading, query.data]);

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesContextProvider;
