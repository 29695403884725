import React from 'react';

const AS332 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 53.527344 2.71875 L 40.1875 16.386719 C 40.111442 14.650092 39.975779 12.94576 39.746094 11.498047 C 38.717857 5.0170405 36.363524 3.9165632 35.40625 3.8945312 C 34.448975 3.8724988 32.09269 5.0170405 31.064453 11.498047 C 30.790831 13.222699 30.660163 15.309818 30.595703 17.382812 L 18.208984 5.1152344 L 15.810547 4.3964844 L 13.851562 6.0527344 L 30.558594 22.357422 C 30.590582 24.481317 30.649048 25.846653 30.671875 26.302734 L 30.113281 26.869141 C 29.58224 27.046473 28.846729 27.447853 28.634766 28.353516 C 28.634373 28.355192 28.633203 28.35962 28.632812 28.361328 L 16.361328 40.753906 L 15.640625 43.150391 L 17.296875 45.109375 L 28.503906 33.626953 C 28.507043 33.908758 28.509766 34.617188 28.509766 34.617188 C 28.509766 34.617188 31.159391 35.054013 31.564453 35.490234 C 31.806194 35.75057 32.779297 39.883543 32.779297 40.132812 C 32.779297 40.382081 33.775391 55.275391 33.775391 55.275391 C 33.775391 55.275391 27.243564 55.494736 26.859375 55.710938 C 26.475186 55.927138 26.857872 60.53134 27.013672 60.5625 C 27.169466 60.59366 35.40625 61.386719 35.40625 61.386719 C 37.078541 60.598121 37.072778 61.327971 36.892578 56.640625 C 36.892578 56.640625 38.033203 40.382081 38.033203 40.132812 C 38.033203 39.883543 39.006306 35.75057 39.248047 35.490234 C 39.653109 35.054013 42.300781 34.617188 42.300781 34.617188 C 42.300781 34.617188 42.304036 33.735171 42.308594 33.216797 L 52.59375 43.402344 L 54.990234 44.121094 L 56.951172 42.464844 L 41.990234 27.865234 C 41.989523 27.863945 41.988995 27.862614 41.988281 27.861328 C 41.792996 27.509317 41.505427 27.273349 41.214844 27.105469 L 40.148438 26.066406 C 40.182505 25.347592 40.249881 23.773078 40.267578 21.408203 L 54.462891 7.0761719 L 55.183594 4.6777344 L 53.527344 2.71875 z "
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default AS332;
