import { Box, Stack, Tooltip } from '@mui/material';
import { useAssetLabel } from 'components/shared/assetLabel';
import React, { useMemo, useCallback, useState } from 'react';
import { useTranslations } from 'use-intl';
import { DatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useGetTripSummariesForAssets } from 'apis/rest/trips/hooks';
import { Moment } from 'moment/moment';
import useTimezone from 'hooks/session/useTimezone';

interface FormProps {
  assets: AssetWithDevice[]
  queryDate: DateTime
  updateDate: (d: DateTime) => void
  selectedAssetIds: number[]
}

export const DateSelection = ({ assets, queryDate, updateDate, selectedAssetIds }: FormProps) => {
  const t = useTranslations('pages.reporting.dailyFlightSummary');
  const assetLabel = useAssetLabel();

  const timezone = useTimezone();
  const [month, setMonth] = useState<string>(DateTime.now().startOf('month').toISODate());

  const selectedAssets = useMemo(() => assets.filter(a => selectedAssetIds.includes(a.id)), [selectedAssetIds, assets]);

  const tripSummaryQuery = useGetTripSummariesForAssets(
    assets.map(a => a.id),
    DateTime.fromISO(month).setZone(timezone).startOf('month').toMillis(),
    DateTime.fromISO(month).setZone(timezone).endOf('month').toMillis()
  );

  const getActivityIndicators = useCallback((day: string) => {
    if (!tripSummaryQuery.data) { return []; }
    const dayInTz = DateTime.fromISO(day).setZone(timezone);
    const start = dayInTz.startOf('day').toMillis();
    const end = dayInTz.endOf('day').toMillis();
    return selectedAssets.flatMap(a => {
      const wasActive = tripSummaryQuery.data.some(summary => a.id === summary.assetId && ((summary.day <= start && summary.lastEnd >= start) || (summary.day <= end && summary.day >= start && summary.firstStart <= end)));
      return wasActive ? [{ color: a.colour, id: a.id, name: assetLabel(a) }] : [];
    });
  }, [assetLabel, selectedAssets, timezone, tripSummaryQuery.data]);

  const Day = useCallback((props: PickersDayProps<Moment>) => {
    const activityIndicators = !props.outsideCurrentMonth ? getActivityIndicators(DateTime.fromJSDate(props.day.toDate()).toISODate()) : [];
    const tooManyActivityIndicators = activityIndicators.length > 3;

    return (
      <PickersDay {...props}>
        <Tooltip title={<>{activityIndicators.map(a => (<p>{a.name}</p>))}</>}>
          <span>{props.day.format('D')}</span>
        </Tooltip>
        {activityIndicators.length > 0
        && (
        <Stack
          direction="row"
          spacing="3px"
          sx={{
            position: 'absolute',
            left: '50%',
            top: '80%',
            transform: 'translate(-50%)',
          }}>
          {tooManyActivityIndicators
            ? <Box sx={theme => ({ width: '20px', height: '3px', backgroundColor: theme.palette.primary.activeGreen })} />
            : activityIndicators.slice(0, 3).map(a => (
              <div
                style={{
                  width: 5,
                  height: 5,
                  backgroundColor: a.color ?? 'green',
                  borderRadius: '50%'
                }}
            />
            ))}
        </Stack>
        )}
      </PickersDay>
    );
  }, [getActivityIndicators]);

  return (
    <DatePicker
      timezone={queryDate.zoneName}
      formatDensity="dense"
      format="MMM Do YYYY"
      disableFuture
      value={moment(queryDate.toJSDate())}
      slots={{
        day: Day,
      }}
      onChange={(v, e) => { if (!e.validationError && v) { updateDate(DateTime.fromJSDate(v.toDate())); } }}
      onMonthChange={m => setMonth(DateTime.fromJSDate(m.toDate()).toISODate())}
      slotProps={{ textField: { InputProps: { sx: { height: '56px' } } } }}
    />
  );
};
