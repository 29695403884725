import React, { useMemo, useState, useEffect, useCallback, CSSProperties } from 'react';
import { Box, Checkbox, Skeleton, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { difference, intersection, isEqual, uniq } from 'lodash';
import { MaterialTableProps } from '@material-table/core';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useOrganisationId from 'hooks/session/useOrganisationId';
import usePermissions from 'hooks/session/usePermissions';
import insensitiveSort from 'utils/insensitiveSort';
import { AssignAssetsDialogStatus } from 'components/dialogs/iceContactGroups/assignAssets';
import StickyPagination from 'components/shared/stickyPagination';
import PersistentTable from 'components/shared/persistentTable';
import AssetColourMarker from 'components/shared/assetColourMarker';
import SelectSerialType from 'components/shared/selectSerialType';
import { AssetBasicDeviceSerial } from 'components/shared/assetBasicDeviceSerial';
import { useAssetLabel } from 'components/shared/assetLabel';
import AssignAssetsSelections from './assignAssetsSelections-view';

const useRowStyle = (isRowSelected: (row: RowData) => boolean) => {
  const theme = useTheme();

  return useCallback((row: RowData): CSSProperties => ({
    backgroundColor: isRowSelected(row) ? alpha(theme.palette.primary.main, 0.05) : undefined
  }), [theme, isRowSelected]);
};

interface RowData {
  asset: AssetBasic
  contactGroup: ContactGroup | undefined
}

export interface ContactGroupsAssetAssignmentTableComponents {
  AssignAssetsDialog: (props: {
    open: boolean
    onClose: (status: AssignAssetsDialogStatus, assetIds: number[], contactGroupId?: number) => void
    assetIds: number[]
  }) => JSX.Element
}

interface ContactGroupsAssetAssignmentTableProps {
  settingsCategory: string
  groups: ContactGroup[] | undefined
  isLoading: boolean
  components: ContactGroupsAssetAssignmentTableComponents
}

export const ContactGroupsAssetAssignmentTable = ({
  settingsCategory,
  groups,
  isLoading,
  components: { AssignAssetsDialog },
}: ContactGroupsAssetAssignmentTableProps): JSX.Element => {
  const permissions = usePermissions();
  const assetLabel = useAssetLabel();
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupsAssetAssignmentTable');

  const organisationId = useOrganisationId();
  const assetsQuery = useGetAssetsList().query;

  const groupsByAssetId = useMemo(
    () => groups?.reduce<Record<number, ContactGroup>>((acc, group) => {
      group.deviceAndAssetIds.forEach(({ assetId }) => {
        acc[assetId] = group;
      });
      return acc;
    }, {}) ?? {},
    [groups],
  );

  const defaultGroup = groups?.find(g => g.isDefault);

  const assetsWithDevices = useMemo(
    () => assetsQuery.data?.filter(asset => (
      typeof asset.deviceId === 'number' && asset.ownerId.toLowerCase() === organisationId.toLowerCase()
    )) ?? [],
    [assetsQuery.data, organisationId],
  );

  const data: RowData[] = useMemo(() => (
    assetsWithDevices.map(asset => ({
      asset,
      contactGroup: groupsByAssetId[asset.id],
    }))
  ), [assetsWithDevices, groupsByAssetId]);

  const [selectedAssetIds, setSelectedAssetIds] = useState<number[]>([]);

  // clear selections on org change
  useEffect(() => {
    setSelectedAssetIds(state => {
      if (state.length) return [] as number[];
      return state;
    });
  }, [organisationId]);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const onCloseDialog = (status: AssignAssetsDialogStatus) => {
    setIsDialogOpen(false);
    if (status === AssignAssetsDialogStatus.Saved) setSelectedAssetIds([]);
  };
  const onClickAssign = () => setIsDialogOpen(true);

  const footerRef = React.useRef<HTMLElement>();
  const Pagination = React.useCallback(props => <StickyPagination container={footerRef.current} {...props} />, []);

  const [displayedAssetIds, setDisplayedAssetIds] = useState<number[]>([]);

  const isRowSelected = useCallback(
    (row: RowData) => selectedAssetIds.includes(row.asset.id),
    [selectedAssetIds],
  );

  const rowStyle = useRowStyle(isRowSelected);

  const selectAllState = useMemo(() => {
    if (selectedAssetIds.length > 0 && difference(displayedAssetIds, selectedAssetIds).length === 0) {
      return 'all';
    }
    if (intersection(displayedAssetIds, selectedAssetIds).length > 0) {
      return 'some';
    }
    return 'none';
  }, [displayedAssetIds, selectedAssetIds]);

  const onChangeSelectAll = useCallback((event, value) => {
    if (value) {
      setSelectedAssetIds(uniq([...selectedAssetIds, ...displayedAssetIds]));
    } else {
      setSelectedAssetIds(difference(selectedAssetIds, displayedAssetIds));
    }
  }, [setSelectedAssetIds, selectedAssetIds, displayedAssetIds]);

  const columns = useMemo<MaterialTableProps<RowData>['columns']>(() => {
    return [
      {
        hidden: !permissions.canEditContactGroups,
        title: (
          <Checkbox
            checked={selectAllState === 'all'}
            indeterminate={selectAllState === 'some'}
            onChange={onChangeSelectAll}
            inputProps={{ 'aria-label': t('columns.selectAll') }}
          />
        ),
        field: 'checked',
        headerStyle: { textAlign: 'left', whiteSpace: 'nowrap' },
        cellStyle: { textAlign: 'left' },
        sorting: false,
        filtering: false,
        width: 0,
        render: row => (
          <Checkbox
            checked={isRowSelected(row)}
            onClick={event => event.stopPropagation()}
            onChange={(event, value) => {
              setSelectedAssetIds(ids => {
                if (ids.includes(row.asset.id)) {
                  return ids.filter(id => id !== row.asset.id);
                }
                return [...ids, row.asset.id];
              });
            }}
          />
        ),
      },
      {
        title: t('columns.asset'),
        field: 'asset.name',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a, b) => insensitiveSort(assetLabel(a.asset), assetLabel(b.asset)),
        customFilterAndSearch: (filter, a) => (
          assetLabel(a.asset, '').toLowerCase().includes(filter.toLowerCase())
          || `${a.asset.make} ${a.asset.model} ${a.asset.variant}`.trim().toLowerCase().includes(filter.toLowerCase())
        ),
        render: row => (
          <Stack direction="row" spacing={2} alignItems="center">
            <AssetColourMarker assetId={row.asset.id} />
            <Stack>
              <Typography>{assetLabel(row.asset)}</Typography>
              <Typography variant="body3">{row.asset.make} {row.asset.model} {row.asset.variant}</Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        title: t('columns.device'),
        field: 'device',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a, b) => insensitiveSort(`${a.asset.deviceMake} ${a.asset.deviceModel}`, `${b.asset.deviceMake} ${b.asset.deviceModel}`),
        customFilterAndSearch: (filter, row) => (
          row.asset.deviceTracPlusSerial?.toLowerCase().includes(filter.toLowerCase())
          || `${row.asset.deviceMake} ${row.asset.deviceModel}`.trim().toLowerCase().includes(filter.toLowerCase())
        ),
        render: row => (
          <Stack>
            <Typography>{row.asset.deviceMake} {row.asset.deviceModel}</Typography>
            <Typography><AssetBasicDeviceSerial asset={row.asset} /></Typography>
          </Stack>
        ),
      },
      {
        title: t('columns.contactGroup'),
        field: 'contactGroup.name',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        defaultSort: 'asc',
        customSort: (a, b) => insensitiveSort(a.contactGroup?.name, b.contactGroup?.name),
        render: row => {
          if (isLoading) return <Skeleton width="16em" />;
          if (row.contactGroup) {
            if (row.contactGroup.peopleWithOrder.length) {
              return <Typography>{row.contactGroup.name}</Typography>;
            }
            return (
              <Stack>
                <Typography>{row.contactGroup.name}</Typography>
                <Typography variant="body3" fontStyle="italic" color="warning.main">{t('noPeople')}</Typography>
              </Stack>
            );
          }
          if (defaultGroup) {
            return (
              <Typography variant="body3" fontStyle="italic">
                {t('noGroupAssignedWithDefault', { name: defaultGroup.name })}
              </Typography>
            );
          }
          return <Typography variant="body3" fontStyle="italic" color="error">{t('noGroupAssigned')}</Typography>;
        }
      },
    ];
  }, [permissions, t, setSelectedAssetIds, isLoading, selectAllState, onChangeSelectAll, isRowSelected, defaultGroup, assetLabel]);

  const onRowClick: MaterialTableProps<RowData>['onRowClick'] = useCallback((event, row): void => {
    if (!row) return;
    setSelectedAssetIds(ids => {
      if (ids.includes(row.asset.id)) {
        return ids.filter(id => id !== row.asset.id);
      }
      return [...ids, row.asset.id];
    });
  }, [setSelectedAssetIds]);

  const onTableChange = useCallback((state: { data?: RowData[] }) => {
    const nextIds = state.data?.map(a => a.asset.id) ?? [];
    setDisplayedAssetIds(ids => {
      if (isEqual(ids, nextIds)) return ids;
      return nextIds;
    });
  }, [setDisplayedAssetIds]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mx={3}>
        <SelectSerialType
          size="small"
          InputProps={{ sx: { width: '15rem' } }}
        />
      </Stack>
      <PersistentTable<RowData>
        settingsCategory={settingsCategory}
        data={data}
        isLoading={isLoading}
        columns={columns}
        localization={{
          header: {
            actions: ''
          }
        }}
        components={{
          Toolbar: () => null,
          Container: Box,
          Pagination,
        }}
        options={{
          filtering: true,
          draggable: false,
          showTitle: false,
          search: false,
          header: true,
          paging: true,
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
          rowStyle,
        }}
        onRowClick={permissions.canEditContactGroups ? onRowClick : undefined}
        onRender={onTableChange}
        sx={{ 'tbody tr:last-child td, tbody tr:last-child th': { border: 0 } }}
      />
      <Box
        position="sticky"
        bottom={0}
        sx={permissions.canEditContactGroups ? undefined : { '& .MuiToolbar-regular': { padding: 10 / 3 } }}
      >
        <Box ref={footerRef} />
        {permissions.canEditContactGroups && (
          <AssignAssetsSelections
            selectedAssetIds={selectedAssetIds}
            setSelectedAssetIds={setSelectedAssetIds}
            assets={assetsWithDevices}
            onClickAssign={onClickAssign}
          />
        )}
      </Box>
      {permissions.canEditContactGroups && (
        <AssignAssetsDialog
          open={isDialogOpen}
          onClose={onCloseDialog}
          assetIds={selectedAssetIds}
        />
      )}
    </>
  );
};
