import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

const BetweenLegReports = ({ reports, atTheEnd = false }: { reports: Report[], atTheEnd?: boolean }) => ((reports.length > 0 || !atTheEnd)
  ? (
    <Stack>
      <Box borderLeft={theme => theme.border.default} height={theme => theme.spacing(1)} ml={4} />
      {reports.length > 0 && <Typography textAlign="start" variant="caption" ml={2}>{reports.length} reports</Typography>}
      <Box borderLeft={theme => (!atTheEnd ? theme.border.default : undefined)} height={theme => theme.spacing(1)} ml={4} />
    </Stack>
  ) : null);

export default BetweenLegReports;
