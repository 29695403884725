import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import ldb from 'helpers/ldb';

export type KmlStatus = 'visible' | 'hidden';
export type KmlStatuses = Record<string, KmlStatus>
export type KmlOrder = string[]

export const useAllKMLs = () => {
  const [kmlFilenames, setKmlFilenames] = useState<string[]>([]);

  const refetch = useCallback(() => {
    ldb.list(keys => {
      setKmlFilenames(keys.filter(k => !k.startsWith('json-')));
    });
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return [kmlFilenames, refetch];
};

export const useKmlManager = (id: string, kmlFilenames: string[]) => {
  const [kmlOrder, setKmlOrder] = useState<KmlOrder>([]);
  const [kmlStatus, setKmlStatus] = useState<KmlStatuses>({});
  const [isLoaded, setIsLoaded] = useState(false);

  const setKmlOrderLS: React.Dispatch<SetStateAction<KmlOrder>> = useCallback(order => {
    if (order instanceof Function) {
      setKmlOrder(oldOrder => {
        const val = order(oldOrder);
        localStorage.setItem(`${id}-kmlOrder`, JSON.stringify(val));
        return val;
      });
    } else {
      setKmlOrder(order);
      localStorage.setItem(`${id}-kmlOrder`, JSON.stringify(order));
    }
  }, [id]);

  const setKmlStatusLS: React.Dispatch<SetStateAction<KmlStatuses>> = useCallback(status => {
    if (status instanceof Function) {
      setKmlStatus(oldStatus => {
        const val = status(oldStatus);
        localStorage.setItem(`${id}-kmlStatus`, JSON.stringify(val));
        return val;
      });
    } else {
      setKmlStatus(status);
      localStorage.setItem(`${id}-kmlStatus`, JSON.stringify(status));
    }
  }, [id]);

  const fetchFromPersistence = useCallback(() => {
    ldb.list(keys => {
      const kmlKeys = keys.filter(k => !k.startsWith('json-'));
      let persistedOrder: KmlOrder;
      let persistedStatus: KmlStatuses;
      try {
        persistedOrder = JSON.parse(localStorage.getItem(`${id}-kmlOrder`) ?? 'null') || kmlFilenames;
        if (!Array.isArray(persistedOrder)) throw new Error();
        persistedStatus = JSON.parse(localStorage.getItem(`${id}-kmlStatus`) ?? 'null') || Object.fromEntries(kmlFilenames.map(f => [f, 'visible']));
        if (typeof persistedStatus !== 'object') throw new Error();
      } catch {
        ldb.clear();
        setIsLoaded(true);
        return;
      }

      setKmlOrder([...persistedOrder.filter(k => keys.includes(k)), ...kmlKeys.filter(k => !persistedOrder.includes(k))]); // filter out files that aren't in ldb
      Object.keys(persistedStatus).filter(k => !kmlKeys.includes(k)).forEach(k => delete persistedStatus[k]);
      keys.filter(k => !persistedOrder.includes(k)).forEach(k => { persistedStatus[k] = 'visible'; });
      setKmlStatus(persistedStatus);
      setIsLoaded(true);
    });
  }, [id, kmlFilenames]);

  useEffect(() => {
    const oldKml = localStorage.getItem(`${id}-kml`);
    const oldKmlFilename = localStorage.getItem(`${id}-kmlFilename`);
    if (oldKml && oldKmlFilename) {
      ldb.set(oldKmlFilename, oldKml, () => {
        localStorage.setItem(`${id}-kmlOrder`, JSON.stringify([oldKmlFilename]));
        localStorage.setItem(`${id}-kmlStatus`, JSON.stringify({ [oldKmlFilename]: 'visible' }));
        localStorage.removeItem(`${id}-kml`);
        localStorage.removeItem(`${id}-kmlFilename`);
        fetchFromPersistence();
      });
    } else {
      fetchFromPersistence();
    }
  }, [id, setKmlOrderLS, setKmlStatusLS, fetchFromPersistence]);

  const visibleKmls = useMemo(() => kmlOrder.filter(k => kmlStatus[k] === 'visible'), [kmlOrder, kmlStatus]);

  return [visibleKmls, kmlOrder, kmlStatus, setKmlOrderLS, setKmlStatusLS, isLoaded] as const;
};
