import React from 'react';

export interface IconProps {
  fill: string,
  outline: string,
  outlineOpacity: number,
}

const Water = ({
  fill,
  outline,
  outlineOpacity,
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="64px" width="64px" viewBox="0 0 24 24">
    <path
      d="M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M7.83,14 c0.37,0,0.67,0.26,0.74,0.62c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75 c-2.13,0.13-4.62-1.09-5.19-4.12C7.01,14.42,7.37,14,7.83,14z"
      fill={fill}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outline}
      strokeWidth="3"
      style={{
        paintOrder: 'markers stroke fill',
      }}
    />
  </svg>
);

export default Water;
