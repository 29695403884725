import React, { useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { Alert, Box, Button, Collapse, IconButton, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ArrowForward, Clear, Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ScrollList from 'components/shared/scrollList';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { SaveSeatsDialog } from './dialogs/SaveSeatsDialog';
import { getFilteredUsers } from './helpers';
import { BeaconUser } from './BeaconUser';

interface BeaconSeatsTableProps {
  users: Membership[];
  assignedUsers: Membership[];
  numSeats: number;
}

export const BeaconSeatsTable = ({
  users,
  assignedUsers,
  numSeats,
}: BeaconSeatsTableProps) => {
  const theme = useTheme();
  const t = useTranslations('pages.beacon');

  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);

  const [filterText, setFilterText] = useState<string>('');
  const [newAssignedUsers, setNewAssignedUsers] = useState<Membership[]>(assignedUsers);

  useEffect(() => {
    setNewAssignedUsers(assignedUsers);
  }, [assignedUsers]);

  const seatsFull = useMemo(() => newAssignedUsers.length >= numSeats, [newAssignedUsers, numSeats]);
  const tooManyAssigned = useMemo(() => newAssignedUsers.length > numSeats, [newAssignedUsers, numSeats]);
  const hasMadeChanges = useMemo(() => {
    const anyAdditions = newAssignedUsers.some(m => !assignedUsers.includes(m));
    const anyDeletions = assignedUsers.some(m => !newAssignedUsers.includes(m));
    return anyAdditions || anyDeletions;
  }, [newAssignedUsers, assignedUsers]);

  const filteredUsers = useMemo(() => getFilteredUsers(users, newAssignedUsers, filterText), [users, newAssignedUsers, filterText]);

  const assetsQuery = useGetAssetsList().query;
  const assetByUserId = useMemo(() => users.reduce<Record<string, AssetBasic | undefined>>((acc, user) => {
    acc[user.userId] = assetsQuery.data?.find(asset => asset.externalId === user.userId);
    return acc;
  }, {}) ?? {}, [assetsQuery.data, users]);

  const assignUser = (user: Membership) => {
    if (seatsFull) {
      return;
    }

    setNewAssignedUsers([...newAssignedUsers, user]);
  };

  const unassignUser = (user: Membership) => {
    setNewAssignedUsers(newAssignedUsers.filter(u => u.userId !== user.userId));
  };

  const cancelChanges = () => {
    setNewAssignedUsers(assignedUsers);
  };

  const saveChanges = () => {
    setSaveDialogOpen(true);
  };

  return (
    <Paper sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} mb={0} height="4rem">
          <Typography variant="h5">{t('unassignedLabel')}</Typography>
          <TextField
            type="search"
            size="small"
            value={filterText}
            onChange={event => setFilterText(event.target.value)}
            InputProps={{
              placeholder: t('search.placeholder'),
              endAdornment: filterText && (
                <IconButton size="small" onClick={() => setFilterText('')}>
                  <Clear sx={{ color: theme.palette.common.text }} />
                </IconButton>
              ),
              sx: { pr: 1, width: '15rem' },
              'aria-label': t('search.label'),
            }}
          />
        </Stack>
        <Stack p={2}>
          <ScrollList
            height="50rem"
            ids={filteredUsers.map((_, idx) => idx)}
            renderItem={idx => {
              const user = filteredUsers[idx];
              const asset = assetByUserId[user.userId];

              return (
                <BeaconUser
                  user={user}
                  asset={asset}
                  actions={(
                    <Tooltip title={!seatsFull ? t('tooltips.assignUser') : undefined}>
                      <Box>
                        <IconButton disabled={seatsFull}>
                          <ArrowForward />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                  onClick={u => assignUser(u)}
                  isLast={idx === filteredUsers.length - 1}
                />
              );
            }}
            empty={(
              <Collapse>
                <Box mt={2}>
                  {users.length !== newAssignedUsers.length ? (
                    <Alert severity="info">{t('alerts.noFilteredUsers')}</Alert>
                  ) : (
                    <Alert severity="warning">{t('alerts.noUsers')}</Alert>
                  )}
                </Box>
              </Collapse>
            )}
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} m={2} mb={0} height="4rem">
          <Typography variant="h5">
            {t('assignedLabel', {
              total: numSeats,
              assigned: newAssignedUsers.length,
            })}
          </Typography>
          <Stack direction="row" spacing={3} height="4rem">
            <Button
              size="large"
              variant="outlined"
              onClick={() => cancelChanges()}
              disabled={!hasMadeChanges}
              sx={{ minWidth: '10rem' }}
            >
              {t('cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => saveChanges()}
              disabled={!hasMadeChanges || tooManyAssigned}
              sx={{ minWidth: '10rem' }}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
        <Stack p={2}>
          <ScrollList
            height="50rem"
            ids={newAssignedUsers.map((_, idx) => idx)}
            renderItem={idx => {
              const user = newAssignedUsers[idx];
              const asset = assetByUserId[user.userId];

              return (
                <BeaconUser
                  user={user}
                  asset={asset}
                  actions={(
                    <Tooltip title={t('tooltips.unassignUser')}>
                      <IconButton>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                  onClick={u => unassignUser(u)}
                  isLast={idx === newAssignedUsers.length - 1}
                />
              );
            }}
            empty={(
              <Collapse>
                <Box mt={2}>
                  {numSeats > 0 ? (
                    <Alert severity="info">{t('alerts.noAssignedUsers')}</Alert>
                  ) : (
                    <Alert severity="info">{t('alerts.noSeats')}</Alert>
                  )}
                </Box>
              </Collapse>
            )}
          />
        </Stack>
      </Stack>
      <SaveSeatsDialog
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        assignedUsers={assignedUsers}
        newAssignedUsers={newAssignedUsers}
      />
    </Paper>
  );
};
