// noinspection CssInvalidHtmlTagReference

import JSZip from 'jszip';
import { chunk, uniq, escape } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import LatLon from 'geodesy/latlon-spherical';
import ReactDOMServer from 'react-dom/server';
import { isSignificantEvent } from 'helpers/events';
import EventIcon from 'components/shared/icons/eventIcons';
import DirectionIcon from 'components/shared/icons/directionIcon';
import { distance } from 'helpers/unitsOfMeasure';
import { gatewayToTransport } from 'helpers/transport';

interface ColoredEvent {
  color: string,
  event: string,
}

const reportToCoordStr = (r: Report) => [r.longitude, r.latitude, r.altitude].join(',');

const reportToPlacemark = (asset: AssetBasic, r: Report, index: number, length: number) => `<Placemark>
  <name>${escape(r.events.join(', '))}</name>
  <description><![CDATA[<table class="geTooltipTable">
  <tr><td colspan="2"><b>${asset.name}</b><br/>message ${index + 1} of ${length}</td></tr>
  <tr><td>Event:</td><td>${escape(r.events.join(', '))}</td></tr>
  <tr><td>Timestamp:</td><td>${escape(DateTime.fromSeconds(r.received).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS))}</td></tr>
  <tr><td>Position:</td><td>${escape(new LatLon(r.latitude, r.longitude).toString('dms').split(', ')[0])}</td></tr>
  <tr><td></td><td>${escape(new LatLon(r.latitude, r.longitude).toString('dms').split(', ')[1])}</td></tr>
  <tr><td>Altitude:</td><td>${escape(distance.fromSI(r.altitude, 'feet').toFixed(0))}ft</td></tr>
  <tr><td>Track:</td><td>${r.course}° T</td></tr>
  <tr><td>Network:</td><td>${escape(gatewayToTransport(r.gateway))}</td></tr>
</table>]]></description>
  <Point>
    <altitudeMode>absolute</altitudeMode>
    <coordinates>${escape(reportToCoordStr(r))}</coordinates>
  </Point>  
  <Style>
   <IconStyle>
     <scale>0.5625</scale>
     <heading>${isSignificantEvent(r.events[0]) ? 0 : r.course}</heading>
     <hotspot x="0.5" y="0.5" xunits="fraction" yunits="fraction" />
     <Icon>
       <href>files/${escape(r.events[0])}_${escape(asset.colour.substring(1))}.png</href>
     </Icon>
   </IconStyle>
   <LabelStyle>
     <scale>0</scale>
   </LabelStyle>
   <BalloonStyle>
     <text>$[description]</text>
   </BalloonStyle>
  </Style>
</Placemark>`;

const formatAssetName = (a: AssetBasic) => (a.callSign !== null && a.callSign ? `${a.callSign} (${a.name})` : a.name);

const svgToPng = async (svgUrl: string): Promise<Blob> => new Promise((resolve, reject) => {
  const image = new Image();

  image.addEventListener('load', () => {
    const canvas = document.createElement('canvas');

    canvas.setAttribute('width', '64px');
    canvas.setAttribute('height', '64px');

    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, 64, 64);

    canvas.toBlob(blob => {
      if (blob === null) {
        reject();
        image.remove();
        canvas.remove();
        return;
      }
      resolve(blob);
      image.remove();
      canvas.remove();
    });
  });

  image.src = svgUrl;
});

export const reportsToKmz = async (asset: AssetBasic, reports: Report[]): Promise<Blob> => {
  const xml = `<?xml version="1.0" encoding="utf-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
<Folder>
  <name>${escape(formatAssetName(asset))}</name>
  <Folder>
    <name>Messages</name>
    ${reports.filter(r => r.isValid && r.longitude && r.latitude).map((r, i, a) => reportToPlacemark(asset, r, i, a.length))}
  </Folder>
  <Placemark>
    <name>Trail</name>
    <LineString>
      <extrude>true</extrude>
      <tessellate>false</tessellate>
      <altitudeMode>absolute</altitudeMode>
      <coordinates>${escape(reports.filter(r => r.isValid && r.longitude && r.latitude).map(reportToCoordStr).join('\n'))}</coordinates>
    </LineString>
    <Style>
      <LineStyle>
        <color>ff${escape(chunk((asset.colour ?? '#000000').slice(1).split(''), 2).reverse().map(c => c.join('')).join(''))}</color>
        <width>2</width>
      </LineStyle>
    </Style>
  </Placemark>
</Folder>
</kml>
`;
  const distinctEvents = uniq(reports.flatMap(r => r.events));
  const zip = new JSZip();
  const iconFolder = zip.folder('files');
  for (let i = 0; i < distinctEvents.length; i++) {
    const e = distinctEvents[i];
    console.log(e);
    const svgString = ReactDOMServer
      .renderToString(isSignificantEvent(e) ? <EventIcon type={e} size={64} /> : (
        <DirectionIcon
          fillColor="#fff"
          circle
          circleFill={asset.colour ?? undefined}
        />
      ))
      .replace('icon-base"', `icon-base" fill="${asset.colour}"`);
    const iconUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
    // eslint-disable-next-line no-await-in-loop
    const blob = await svgToPng(iconUrl);
    iconFolder?.file(`${e}_${asset.colour.substring(1)}.png`, blob);
  }
  zip.file('doc.kml', xml);

  return zip.generateAsync({ type: 'blob' });
};

const getKmlSnippetForAsset = (asset: AssetBasic, reports: Report[]): string => (
  `      <Folder>
        <name>${escape(formatAssetName(asset))}</name>
        <Folder>
          <name>Messages</name>
          ${reports.filter(r => r.isValid && r.longitude && r.latitude).map((r, i, a) => reportToPlacemark(asset, r, i, a.length))}
        </Folder>
        <Placemark>
          <name>Trail</name>
          <LineString>
            <extrude>true</extrude>
            <tessellate>false</tessellate>
            <altitudeMode>absolute</altitudeMode>
            <coordinates>${escape(reports.filter(r => r.isValid && r.longitude && r.latitude).map(reportToCoordStr).join('\n'))}</coordinates>
          </LineString>
          <Style>
            <LineStyle>
              <color>ff${escape(chunk((asset.colour ?? '#000000').slice(1).split(''), 2).reverse().map(c => c.join('')).join(''))}</color>
              <width>2</width>
            </LineStyle>
          </Style>
        </Placemark>
      </Folder>`
);

// function to process the reports from multiple assets into a KML with a folder for each day
export const batchReportsToKmz = async (assets: AssetBasic[], reports: Report[], timezone: string): Promise<Blob> => {
  // group by date
  const groupedReports: Record<string, Record<number, Report[]>> = {};
  reports.forEach((report: Report) => {
    const date = DateTime.fromMillis(report.received * 1000).setZone(timezone).toFormat('yyyy-MM-dd');
    const { assetId } = report;
    if (!groupedReports[date]) {
      groupedReports[date] = {};
    }
    if (!groupedReports[date][assetId]) {
      groupedReports[date][assetId] = [];
    }
    groupedReports[date][assetId].push(report);
  });
  const xml = `<?xml version="1.0" encoding="utf-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>
    <name>TracPlus Service</name>
${Object.values(groupedReports).map((dateGroup, index) => {
    const date = Object.keys(groupedReports)[index];
    const reportsByAsset = Object.values(dateGroup);
    return (
      `    <Folder>
      <name>${escape(date)}</name>
${reportsByAsset.map(r => {
        const asset = assets.find(a => a.id === r[0].assetId);
        if (!asset) return '';
        return getKmlSnippetForAsset(asset, r);
      })}
    </Folder>`
    );
  })}
  </Document>
</kml>
`;
  const distinctColoredEvents: ColoredEvent[] = uniq(reports.flatMap(r => {
    const asset = assets.find(a => a.id === r.assetId);
    return (r.events.map(e => ({
      color: asset?.colour ?? '#000000',
      event: e
    })));
  }));
  const zip = new JSZip();
  const iconFolder = zip.folder('files');
  for (let i = 0; i < distinctColoredEvents.length; i++) {
    const { event, color } = distinctColoredEvents[i];
    const svgString = ReactDOMServer
      .renderToString(isSignificantEvent(event) ? <EventIcon type={event} size={64} /> : (
        <DirectionIcon
          fillColor="#fff"
          circle
          circleFill={color}
        />
      ))
      .replace('icon-base"', `icon-base" fill="${color}"`);
    const iconUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
    // eslint-disable-next-line no-await-in-loop
    const blob = await svgToPng(iconUrl);
    iconFolder?.file(`${event}_${color.substring(1)}.png`, blob);
  }
  zip.file('doc.kml', xml);

  return zip.generateAsync({ type: 'blob' });
};

export const downloadBatchReportsToKmz = async (assets: AssetBasic[], reports: Report[], timezone: string, fileName: string) => {
  const element = document.createElement('a'); // element
  const blob = await batchReportsToKmz(assets, reports, timezone); // , {type: 'text/plain'}); // process into kmz
  element.href = URL.createObjectURL(blob); // assign element blob url
  element.download = `${fileName}.kmz`; //
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
