import useTotalSupplementaryDataForTrips from 'hooks/useTotalSupplementaryDataForTrips';
import { CancelOutlined, CheckCircle, Edit, WarningAmber } from '@mui/icons-material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import {
  Stack,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { sumBy, throttle } from 'lodash';
import Distance from 'components/shared/distance';
import { useApproveTrips } from 'apis/rest/trips/hooks';
import { tripQueryKeys } from 'apis/rest/trips/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useNotification } from 'contexts/notification/useNotification';
import usePermissions from 'hooks/session/usePermissions';
import useDuration from 'hooks/units/useDuration';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

type ApprovalState = 'all' | 'some' | 'none' | 'noTrips';

const ApprovalIcons: Record<ApprovalState, JSX.Element> = {
  all: <CheckCircle color="success" />,
  some: <Edit color="warning" />,
  none: <CancelOutlined color="error" />,
  noTrips: <Typography>—</Typography>,
};

const ApprovalNames: Record<ApprovalState, string> = {
  all: 'Approved',
  some: 'Partially approved',
  none: 'Not approved',
  noTrips: 'No trips'
};

export const ApprovalIcon = ({ trips }: { trips: Trip[] }) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  const permissions = usePermissions();
  const duration = useDuration();

  const totalDistance = useMemo(() => sumBy(trips, t => t.distance ?? 0), [trips]);
  const totalDuration = useMemo(() => sumBy(trips, t => t.duration ?? 0), [trips]);

  const supplementaryDataFeatureAssets = useFeatureAssets('reporting.supplementaryData');
  // filter in place for feature asset restrictions - ideally the api will only return permitted data
  const totalSuppData = useTotalSupplementaryDataForTrips(trips
    .filter(t => t.id)
    .filter(t => (supplementaryDataFeatureAssets.hasAssetId(t.assetId)))
    .map(t => t.id));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const approvedTrips = trips.filter(t => t.status.startsWith('Approved'));
  const someModified = approvedTrips.some(t => t.status === 'ApprovedButModified');

  const t = useTranslations('pages.reporting');
  const approvalState = approvedTrips.length === trips.length
    ? (trips.length === 0 ? 'noTrips' : 'all')
    : (approvedTrips.length ? 'some' : 'none');

  const approveTrips = useApproveTrips();

  const onSubmit = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setSubmitting(true);
    approveTrips.mutate(
      trips.filter(tr => !tr.status.startsWith('Approved')).map(tr => tr.id),
      {
        onSuccess: () => {
          setSubmitting(false);
          setDialogOpen(false);
        }
      }
    );
    e.stopPropagation();
  }, [trips, approveTrips]);

  const successHandler = useCallback(() => {
    snackbar.display({ type: 'success', text: 'Trip approved', id: 'tripApprovalCompleted' });
    queryClient.invalidateQueries(tripQueryKeys.trips(organisationId));
  }, [snackbar, queryClient, organisationId]);

  const debouncedSuccessHandler = useMemo(() => throttle(successHandler, 100), [successHandler]);

  useNotification('tripApprovalRequestFinished', debouncedSuccessHandler);

  const approvalDisabled = true;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Tooltip title={t('approvalState', { state: approvalState })}>
          <Button size="small" variant="outlined" color="warning" sx={{ whiteSpace: 'nowrap' }} fullWidth onClick={e => {
            setDialogOpen(true);
            e.stopPropagation();
          }} disabled={approvalDisabled || ['all', 'noTrips'].includes(approvalState) || !permissions.canEditAssets}>
            {t('approvalState', { state: approvalState })}
          </Button>
        </Tooltip>
        {someModified && (
          <Tooltip title={`${trips.length > 1 ? 'A' : 'This'} trip has changed since approval`}>
            <WarningAmber color="warning" />
          </Tooltip>
        )}
      </Stack>
      <Dialog open={dialogOpen}>
        <DialogTitle>Approve trip{trips.length !== 1 && 's'}</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography>
              You are approving {trips.length} trip{trips.length !== 1 && 's'}{trips.length !== 1 && ` - ${approvedTrips.length ? approvedTrips.length : 'none'} of which ${approvedTrips.length === 1 ? 'is' : 'are'} already approved`}.
            </Typography>
            <Typography>
              This cannot be undone, and it is recorded who approved each trip.
            </Typography>
            <Typography>
              {trips.length === 1 ? 'Y' : 'Collectively, y'}ou are approving the following about {trips.length === 1 ? 'this trip' : `these ${trips.length} trips`}:
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr" p={2} border={theme => theme.border.default}>
              {Object.entries(totalSuppData).map(([_, val]) => (<><Typography>{val.displayName}</Typography><Typography>{val.total}</Typography></>))}
              <Typography>Flight time</Typography>
              <Typography>{duration.fromMillis(totalDuration)}</Typography>
              <Typography>Distance</Typography>
              <Distance distanceInMetres={totalDistance * 1000} />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} height="4rem">
            <Button sx={{ minWidth: '10rem' }} variant="outlined" onClick={e => {
              setDialogOpen(false);
              e.stopPropagation();
            }}>
              {t('trips.cancel')}
            </Button>
            <Button sx={{ minWidth: '10rem' }} variant="contained" disabled={submitting} onClick={onSubmit}>
              {submitting ? <CircularProgress size="2rem" color="secondary" /> : 'Approve'}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
