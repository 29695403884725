/* eslint-disable no-param-reassign */
import { PathLayer, PathLayerProps } from '@deck.gl/layers';
import {
  fsDecl, fsMainEnd
} from './shaders/highlight-trail-shader.glsl';

interface HighlightedTrailLayerProps<DataT> extends PathLayerProps<DataT> {
  animationSpeed: number
  animate: boolean
}

const defaultProps: Partial<HighlightedTrailLayerProps<never>> = {
  animationSpeed: 0.5,
  animate: false
};

export default class HighlightedTrailLayer<DataT> extends PathLayer<DataT, HighlightedTrailLayerProps<DataT>> {
  // @ts-ignore
  draw({ uniforms }) {
    super.draw({
      uniforms:
      {
        ...uniforms,
        time: this.context.timeline.time,
        animationSpeed: this.props.animationSpeed,
        animate: this.props.animate
      }
    });
  }

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'fs:#decl': fsDecl,
      'fs:#main-end': fsMainEnd
    };

    return shaders;
  }
}

HighlightedTrailLayer.layerName = 'HighlightedTrailLayer';
// @ts-ignore
HighlightedTrailLayer.defaultProps = defaultProps;
