const reduceObj = obj => {
  if (!obj) return '';
  return Object.values(obj).reduce((result, value) => {
    if (typeof value === 'object') return result + reduceObj(value);
    return result + value;
  }, '');
};

// Returns a new object containing only those values which, when serialised,
// contain {textFilter} somewhere inside them.  Searches deeply.
export default (data, textFilter) => {
  const lowercaseFilter = textFilter?.trim().toLowerCase();
  if (!data || !lowercaseFilter) return data;

  return Object.keys(data).reduce((acc, key) => ({
    ...acc,
    [key]: data[key].filter(
      o => reduceObj(o).toLowerCase().indexOf(lowercaseFilter) > -1
    )
  }), {});
};
