import { useEffect, useMemo, useState } from 'react';
import { useAssetReports } from 'repositories/reports/hooks';
import { useGetLatestDeviceConfiguration } from 'apis/rest/devices/hooks';
import { tryGetTrackingActive } from 'helpers/config';

export const useTrackingSuspended = (assetId: number, deviceId: number): boolean => {
  const [suspendedFromReports, setSuspended] = useState<boolean | undefined>();

  const latestPositions = useAssetReports(assetId);
  const configQuery = useGetLatestDeviceConfiguration(deviceId).query;
  const suspendedFromConfig = useMemo(() => (configQuery.data ? tryGetTrackingActive(configQuery.data) : null) === false, [configQuery.data]);

  useEffect(() => {
    const suspendIndex = latestPositions.findIndex(r => r.events.includes('EVT_TRACKING_SUSPEND'));
    const resumeIndex = latestPositions.findIndex(r => r.events.includes('EVT_TRACKING_RESUME'));
    if (suspendIndex < 0 && resumeIndex < 0) return;

    if (resumeIndex < 0 && suspendIndex >= 0) {
      setSuspended(true);
    } else if (resumeIndex >= 0 && suspendIndex < 0) {
      setSuspended(false);
    } else {
      setSuspended(suspendIndex < resumeIndex);
    }
  }, [latestPositions]);

  if (suspendedFromReports !== undefined) {
    return suspendedFromReports;
  }
  return suspendedFromConfig;
};
