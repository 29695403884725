import React from 'react';

const MD520N = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 26.703125,1.5742188 c -0.118978,-0.00279 -0.24018,0.01225 -0.361328,0.046875 L 24.585938,2.1230469 C 23.939814,2.307727 23.557169,2.9825278 23.728516,3.6367188 l 3.294922,12.5878902 c -0.483467,1.201385 -0.795361,2.731283 -0.773438,4.685547 0.01099,0.980117 0.04768,1.892409 0.09375,2.775391 L 7.8789062,22.949219 C 7.211457,22.922709 6.6441273,23.447712 6.6074219,24.126953 l -0.099609,1.847656 C 6.4711065,26.65385 6.9790352,27.223487 7.6464844,27.25 l 19.0839846,0.757812 c 0.15292,1.169648 0.330739,2.222271 0.52539,3.160157 l -6.228515,16.46875 c -0.241164,0.637801 0.06602,1.34106 0.689453,1.576172 l 1.697265,0.638671 c 0.623437,0.235118 1.319388,-0.08876 1.560547,-0.726562 l 4.404297,-11.650391 c 0.09516,0.164559 -0.110093,0.253907 -0.110093,0.253907 l -0.0039,13.375 c 0,0 -4.079443,-0.02153 -4.095703,0.302734 -0.01625,0.324269 -0.237588,5.544519 0.24438,5.505859 0.544284,-0.0075 2.799417,-0.02914 3.887954,-0.04297 0.726104,-0.0092 -0.972796,4.715424 2.831514,4.654297 4.248314,-0.06826 2.080463,-4.695619 2.984475,-4.699219 1.268796,-0.0051 2.957941,0.0024 3.592416,0.0078 0.634479,0.0054 0.205056,-5.245978 0.222656,-5.570312 0.0176,-0.324335 -4.148678,-0.208985 -4.148678,-0.208985 V 37.705078 c 0,0 0.563829,-1.233438 1.36061,-4.105469 l 11.736328,9.523438 c 0.518407,0.420694 1.275257,0.328854 1.697266,-0.205078 l 1.146484,-1.451172 c 0.422013,-0.533931 0.346528,-1.303912 -0.171875,-1.724609 L 37.101562,28.828125 c 0.133302,-0.975362 0.242415,-2.050323 0.326172,-3.210937 L 52.775391,15.292969 c 0.561553,-0.377795 0.721996,-1.138023 0.359375,-1.703125 l -0.986328,-1.535156 c -0.362617,-0.565103 -1.10642,-0.717643 -1.667969,-0.339844 l -12.900391,8.679687 c -0.226086,-7.75322 -5.722656,-8.460937 -5.722656,-8.460937 0,0 -0.613965,0.09161 -1.431641,0.49414 L 27.818359,2.46875 C 27.711266,2.0598801 27.414438,1.7579085 27.048828,1.6347656 26.939144,1.5978262 26.822103,1.5770117 26.703125,1.5742188 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default MD520N;
