import React from 'react';

const CessnaCitation = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 34.113933,53.340122 10.780823,2.265571 c 0,0 0.376702,-3.704166 0.183821,-4.047344 -0.192881,-0.343178 -9.590149,-4.34419 -9.590149,-4.34419 l 0.17626,-2.187216 0.634247,-0.3988 c 0,0 0.463787,0.420953 0.702827,0.458903 0.23904,0.03795 1.397159,0.0854 1.654005,0.106388 0.256846,0.02099 1.134249,-2.319443 1.112319,-2.540654 -0.02193,-0.221211 0.02581,-4.698926 0.02581,-4.698926 l 19.783005,0.429959 c 0,0 0.308402,-4.215551 0.117075,-4.636227 C 59.502649,33.32691 36.365297,27.62254 36.365297,27.62254 c 0,0 0.06002,-12.217734 -0.113942,-12.897244 -1.672163,-6.5315826 -3.39706,-7.43058 -3.39706,-7.43058 0,0 -1.724897,0.8989974 -3.39706,7.43058 -0.173962,0.67951 -0.113942,12.897244 -0.113942,12.897244 0,0 -23.1373516,5.70437 -23.3286786,6.125046 -0.1913271,0.420676 0.1170744,4.636227 0.1170744,4.636227 L 25.914694,37.953854 c 0,0 0.04774,4.477715 0.02581,4.698926 -0.02193,0.221211 0.855473,2.561645 1.112319,2.540654 0.256846,-0.02099 1.414965,-0.06844 1.654005,-0.106388 0.23904,-0.03795 0.702827,-0.458903 0.702827,-0.458903 l 0.634247,0.3988 0.17626,2.187216 c 0,0 -9.397268,4.001012 -9.590149,4.34419 -0.192881,0.343178 0.183821,4.047344 0.183821,4.047344 l 10.780823,-2.265571 c 0,0 0.609856,2.65681 1.259638,2.594493 0.649782,-0.06232 1.259638,-2.594493 1.259638,-2.594493 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default CessnaCitation;
