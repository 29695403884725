import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  tableContainer: {
    padding: 0
  },
  highlight: {
    fontWeight: 500,
    color: theme.palette.primary.distressRed
  },
  materialTable: {
    '& .MuiPaper-root': {
      marginTop: 0
    },
    '& > div': {
      marginTop: '15px',
      boxShadow: 'none',
      border: theme.border.default
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type > td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    maxWidth: '300px',
    fontSize: theme.typography.subtitle1.fontSize,
    border: theme.border.default,
    boxShadow: theme.boxShadow.default
  },
  actionButtonColor: {
    color: theme.palette.common.actionIcon
  },
  disabledActionButtonColor: {
    display: 'none'
  },
  newButton: {
    backgroundColor: theme.palette.primary.main,
    height: '3rem',
    marginLeft: 8,
    padding: '19.5px 14px 19.5px 10px',
    borderRadius: 24,
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    }
  },
  selected: {
    '& .MuiFormControlLabel-label h6, .MuiFormControlLabel-label p': {
      color: theme.palette.primary.main
    }
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      padding: 10
    }
  },
  iceContactAlert: {
    backgroundColor: theme.palette.primary.distressRedLight
  },
  userHasNotJoinedTheOrg: {
    '&.MuiTableRow-root.MuiTableRow-hover': {
      '&:hover': {
        cursor: 'default !important',
        backgroundColor: theme.palette.primary.distressRedLight
      }
    }
  },
  notJoinedOrgAndHasContacts: {
    '&:hover': {
      cursor: 'default !important'
    }
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
