import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGl, {
  AttributionControl,
  MapLayerMouseEvent,
  MapRef,
  useControl,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { Box } from '@mui/material';
import { FeatureCollection, Point } from 'geojson';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { LayersList, PickingInfo } from '@deck.gl/core';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import useMarkerIconLayers, { useSingleMarkerIconLayer } from 'components/maps/reactmapgl/layers/useMarkerIconLayers';
import { Marker } from 'apis/rest/markers/types';
import { safeBounds } from 'helpers/geo';
import { useCursor } from 'contexts/cursor/useCursor';
import { useSize } from 'hooks/useSize';
import { GeographicCoordinates } from 'components/pages/manage/markers/types';

const getCursor = () => 'default';

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

interface MarkersMapProps {
  markers: Marker[];
  focusedMarker?: Marker;
  hiddenMarkers: number[];
  isCreatingNew: boolean;
  onCreateNewMarker: (coordinates: GeographicCoordinates) => void;
}

const MarkersMap = ({
  markers,
  focusedMarker,
  hiddenMarkers,
  isCreatingNew,
  onCreateNewMarker,
}: MarkersMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const mapTemplate = useMapTemplate();

  const {
    width,
    height,
  } = useSize(ref);

  const filteredMarkers = useMemo(() => markers.filter(marker => !hiddenMarkers.includes(marker.id)), [hiddenMarkers, markers]);

  const unselectedGeoJson: FeatureCollection<Point, Marker> = useMemo(() => ({
    type: 'FeatureCollection',
    features: filteredMarkers.map(marker => ({
      type: 'Feature',
      properties: marker,
      geometry: {
        type: 'Point',
        coordinates: [marker.longitude, marker.latitude],
      },
    })),
  }), [filteredMarkers]);

  const selectedGeoJson: FeatureCollection<Point, Marker> | null = useMemo(() => {
    if (!focusedMarker) return null;
    return ({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: focusedMarker,
        geometry: {
          type: 'Point',
          coordinates: [focusedMarker.longitude, focusedMarker.latitude],
        },
      }],
    });
  }, [focusedMarker]);

  const [viewport, setViewport] = useState<WebMercatorViewport>(() => new WebMercatorViewport({
    width,
    height,
  }));

  const onViewStateChange = useCallback((params: ViewStateChangeEvent) => {
    setViewport(new WebMercatorViewport({
      width,
      height,
      ...params.viewState,
    }));
  }, [width, height]);

  useEffect(() => {

    const validGeoJson = (geoJson: FeatureCollection<Point, Marker>) => {
      const validFeatures = geoJson.features.filter(f =>
        f.geometry.coordinates[1] >= -90 &&
        f.geometry.coordinates[1] <= 90 &&
        f.geometry.coordinates[0] >= -180 &&
        f.geometry.coordinates[0] <= 180
      );

      if (validFeatures.length < geoJson.features.length) {
        console.warn(`Found ${geoJson.features.length - validFeatures.length} invalid coordinates in GeoJSON features`);
      }

      return {
        ...geoJson,
        features: validFeatures
      };
    };
    const bounds = safeBounds(selectedGeoJson ? validGeoJson(selectedGeoJson) : validGeoJson(unselectedGeoJson));
    if (bounds && !Number.isNaN(bounds[0][0])) {
      mapRef.current?.getMap()
        .fitBounds(bounds, {
          padding: Math.min(width, height, 150),
          maxZoom: 18,
        });
    }
  }, [width, height, unselectedGeoJson, selectedGeoJson]);

  const [cursorPosition, setCursorPosition] = useCursor();
  const onHover = useCallback((info: PickingInfo, event) => {
    if (event.type === 'pointermove' && info.coordinate) {
      setCursorPosition({
        latitude: info.coordinate[1],
        longitude: info.coordinate[0],
        x: info.x,
        y: info.y,
      });
    } else {
      setCursorPosition(undefined);
    }
  }, [setCursorPosition]);

  const wmViewport = useMemo(() => new WebMercatorViewport(viewport), [viewport]);
  const markerIconLayers = useMarkerIconLayers(
    filteredMarkers,
    wmViewport,
    undefined,
    undefined,
    true,
    false,
  );

  const newMarker: Marker = useMemo(() => ({
    id: 0,
    name: '',
    type: 'POI',
    latitude: cursorPosition?.latitude ?? 0,
    longitude: cursorPosition?.longitude ?? 0,
    altitude: 0,
    icon: 'generic',
    colour: '#000',
    clusterId: 'New',
  }), [cursorPosition]);
  const newMarkerIconLayer = useSingleMarkerIconLayer(
    newMarker,
    isCreatingNew,
  );

  const handleOnClick = (evt: MapLayerMouseEvent) => {
    if (isCreatingNew) {
      onCreateNewMarker({ latitude: evt.lngLat.lat, longitude: evt.lngLat.lng });
    }
  };

  return (
    <Box
      ref={ref}
      sx={theme => ({
        width: '100%',
        height,
        minHeight: 800,
        borderLeft: theme.border.default,
        position: 'sticky',
      })}
    >
      <ReactMapGl
        ref={mapRef}
        mapStyle={mapTemplate.template}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        initialViewState={viewport}
        onClick={handleOnClick}
        onMove={onViewStateChange}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        doubleClickZoom={false}
        attributionControl
      >
        <DeckGLOverlay
          _animate
          style={{
            position: 'relative',
            zIndex: '0',
          }}
          getCursor={getCursor}
          onHover={onHover}
          pickingRadius={32}
          layers={[
            ...markerIconLayers,
            newMarkerIconLayer,
          ] as LayersList}
        />
      </ReactMapGl>
    </Box>
  );
};

export default MarkersMap;
