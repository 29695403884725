import React from 'react';
import { useTranslations } from 'use-intl';
import { Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Stack, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { TemporalSharePermissions } from 'apis/rest/temporalShares/types';

interface PermissionsPickerProps {
  permissions: Partial<TemporalSharePermissions>
  requiredPermissions: Partial<TemporalSharePermissions>
  setPermissions: (permissions: Partial<TemporalSharePermissions>) => void
  disabled?: boolean
}

const PermissionsPicker = ({ permissions, requiredPermissions, setPermissions, disabled = false }: PermissionsPickerProps) => {
  const t = useTranslations('pages.sharing.permissionsPicker');
  const state: Partial<TemporalSharePermissions> = { ...permissions };

  Object.entries(requiredPermissions).forEach(([key, value]) => {
    if (value) state[key as keyof TemporalSharePermissions] = true;
  });

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend"><Typography variant="h5" mb={1}>{t('trails.label')}</Typography></FormLabel>
        <Stack direction="row" spacing={1} mt={1}>
          <Chip
            label={t('trails.options.all')}
            icon={state.canViewCurrent && state.canViewHistory ? <Check /> : undefined}
            color={state.canViewCurrent && state.canViewHistory ? 'primary' : 'default'}
            onClick={requiredPermissions.canViewHistory ? undefined : () => setPermissions({ canViewCurrent: true, canViewHistory: true })}
            disabled={disabled}
          />
          <Chip
            label={t('trails.options.live')}
            icon={state.canViewCurrent && !state.canViewHistory ? <Check /> : undefined}
            color={state.canViewCurrent && !state.canViewHistory ? 'primary' : 'default'}
            onClick={requiredPermissions.canViewHistory ? undefined : () => setPermissions({ canViewCurrent: true, canViewHistory: false })}
            disabled={disabled || requiredPermissions.canViewHistory}
          />
        </Stack>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend"><Typography variant="h5" mb={1}>{t('permissions.label')}</Typography></FormLabel>
        <Stack spacing={2} mt={1}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.canSendConfiguration}
                onChange={event => setPermissions({ canSendConfiguration: event.target.checked })}
              />
            )}
            label={(
              <>
                <Typography>{t('permissions.options.canSendConfiguration')} {requiredPermissions.canSendConfiguration && <Typography color="error" component="span">({t('required')})</Typography>}</Typography>
                <Typography variant="subtitle1">{t('permissions.descriptions.canSendConfiguration')}</Typography>
              </>
            )}
            disabled={disabled || requiredPermissions.canSendConfiguration}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.canSendTextMessages}
                onChange={event => setPermissions({ canSendTextMessages: event.target.checked })}
              />
            )}
            label={(
              <>
                <Typography>{t('permissions.options.canSendTextMessages')} {requiredPermissions.canSendTextMessages && <Typography color="error" component="span">({t('required')})</Typography>}</Typography>
                <Typography variant="subtitle1">{t('permissions.descriptions.canSendTextMessages')}</Typography>
              </>
            )}
            disabled={disabled || requiredPermissions.canSendTextMessages}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.canViewForms}
                onChange={event => setPermissions({ canViewForms: event.target.checked })}
              />
            )}
            label={(
              <>
                <Typography>{t('permissions.options.canViewForms')} {requiredPermissions.canViewForms && <Typography color="error" component="span">({t('required')})</Typography>}</Typography>
                <Typography variant="subtitle1">{t('permissions.descriptions.canViewForms')}</Typography>
              </>
            )}
            disabled={disabled || requiredPermissions.canViewForms}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.canEditCallSign}
                onChange={event => setPermissions({ canEditCallSign: event.target.checked })}
              />
            )}
            label={(
              <>
                <Typography>{t('permissions.options.canEditCallSign')} {requiredPermissions.canEditCallSign && <Typography color="error" component="span">({t('required')})</Typography>}</Typography>
                <Typography variant="subtitle1">{t('permissions.descriptions.canEditCallSign')}</Typography>
              </>
            )}
            disabled={disabled || requiredPermissions.canEditCallSign}
          />
        </Stack>
      </FormControl>
    </>
  );
};

export default PermissionsPicker;
