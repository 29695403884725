import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Stack, TextField, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import { useGetAssignedSeats } from 'apis/rest/beacon/hooks';

interface SetNumberOfSeatsDialogProps {
  open: boolean;
  actionsDisabled: boolean;
  onClose: () => void;
  onSave: (numSeats: number) => void;
  currentNumber: number;
}

export const SetNumberOfSeatsDialog = ({
  open,
  actionsDisabled,
  onClose,
  onSave,
  currentNumber,
}: SetNumberOfSeatsDialogProps) => {
  const t = useTranslations('pages.beacon');

  const [numSeats, setNumSeats] = useState<number>(currentNumber);
  const assignedSeatsQuery = useGetAssignedSeats();

  useEffect(() => {
    setNumSeats(Math.max(numSeats, assignedSeatsQuery.data?.length ?? 1));
  }, [numSeats, assignedSeatsQuery.data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <TPDialogTitle>{t('dialogs.setNumberOfSeats.title')}</TPDialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
          <Typography variant="h5">{t('dialogs.setNumberOfSeats.label')}</Typography>
          <TextField
            variant="outlined"
            value={numSeats}
            onChange={e => setNumSeats(Math.max(Number(e.target.value), assignedSeatsQuery.data?.length ?? 1))}
            type="number"
            size="small"
          />
        </Stack>
      </DialogContent>
      <TPDialogActions sx={{ p: 3 }}>
        <Stack direction="row" spacing={3} height="4rem" justifyContent="end">
          <Button
            onClick={onClose}
            variant="outlined"
            size="large"
            disabled={actionsDisabled}
            sx={{ minWidth: '10rem' }}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={() => onSave(numSeats)}
            variant="contained"
            size="large"
            disabled={actionsDisabled}
            sx={{ minWidth: '10rem' }}
          >
            {t('save')}
          </Button>
        </Stack>
      </TPDialogActions>
    </Dialog>
  );
};
