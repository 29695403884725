import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { OptionsObject, SnackbarKey, SnackbarMessage, SnackbarProvider, withSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon, Warning } from '@mui/icons-material';
import useStyles from './snackbarWrapper-styles';
import { useAppDispatch } from 'store/useAppDispatch';
import { destroySnackbar } from 'slices/app.slice';
import { useSelector } from 'react-redux';

type SnackbarProps = {
  snackbar: Snack
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey | undefined) => void;
}

const Snackbar = ({
  snackbar,
  enqueueSnackbar,
  closeSnackbar,
}: SnackbarProps) => {
  const idRef = useRef();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const {
      id,
      type: variant,
      toDelete,
      persist,
      text
    } = snackbar;

    // Destroy any snackbars pending destruction
    if (toDelete) {
      closeSnackbar(id);
      dispatch(destroySnackbar(id));
    }

    // Display any snackbars pending creation
    if (idRef.current !== id && !snackbar.deleted) {
      // only show the notification once
      idRef.current = id;

      enqueueSnackbar(text, {
        id,
        key: id,
        persist,
        autoHideDuration: persist && 10000,
        variant,

        /* Note that this only filters on duplicate keys, not duplicate text.  We could remove the
         * keys and then it would filter on text, but then we could not add close buttons to the
         * snackbars.  To avoid duplicate notifications, we use a 'hardcoded' key.
        */
        preventDuplicate: true,

        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },

        action: (sbKey: string) => (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => {
              closeSnackbar(sbKey);
              dispatch(destroySnackbar({ id: sbKey }));
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )
      });
    }
  }, [snackbar]);

  return null;
};

const SnackbarRenderer = withSnackbar(({
  snackbars,
  children,
  enqueueSnackbar,
  closeSnackbar,
}) => (
  <>
    {children}
    {snackbars?.map(sb => (
      <Snackbar
        key={sb.id}
        snackbar={sb}
        enqueueSnackbar={enqueueSnackbar}
        closeSnackbar={closeSnackbar}
      />
    ))}
  </>
));

const SnackbarWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const classes = useStyles();
  const snackbars = useSelector((state: ReduxState) => state.app.snackbars);

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        root: classes.root,
        variantInfo: classes.infoSnackbar
      }}
      // hideIconVariant
      iconVariant={{
        error: <Warning sx={{ mr: 1 }} />,
        warning: <Warning sx={{ mr: 1 }} />,
      }}
    >
      <SnackbarRenderer snackbars={Object.values(snackbars)}>
        {children}
      </SnackbarRenderer>
    </SnackbarProvider>
  );
};

export default SnackbarWrapper;
