import React from 'react';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { IconButton, List, ListItem, ListItemAvatar } from '@mui/material';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material/styles';

interface SortableItemProps {
  id: string;
  item: string;
  itemMap: (item: string) => JSX.Element;
  secondaryActionMap?: (item: string) => JSX.Element;
}

export const SortableItem = ({
  id, item, itemMap, secondaryActionMap
}: SortableItemProps): JSX.Element => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform)
  };

  const secondaryAction = secondaryActionMap ? secondaryActionMap(item) : undefined;

  let padding = 48;
  if (secondaryAction?.type === React.Fragment) {
    padding = 48 * secondaryAction.props.children.length;
  }

  const ListItemWithPadding = styled(ListItem)(() => ({
    '&.MuiListItem-root': {
      paddingRight: padding
    }
  }));

  return (
    <div ref={setNodeRef} style={style}>
      <ListItemWithPadding secondaryAction={secondaryAction}>
        <ListItemAvatar>
          <IconButton {...attributes} {...listeners}>
            <DragIndicator />
          </IconButton>
        </ListItemAvatar>
        {itemMap(item)}
      </ListItemWithPadding>
    </div>
  );
};

interface DragAndDropListProps {
  items: string[]
  itemMap: (item: string) => JSX.Element;
  onReorder: (callback: (items: string[]) => string[]) => void;
  secondaryActionMap?: (item: string) => JSX.Element;
}

const DragAndDropList = ({
  items,
  itemMap,
  onReorder,
  secondaryActionMap
}: DragAndDropListProps): JSX.Element => {
  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (active.id !== over?.id && over) {
      onReorder(newItems => {
        const oldIndex = newItems.indexOf(active.id.toString());
        const newIndex = newItems.indexOf(over.id.toString());

        return arrayMove(newItems, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <List>
        <SortableContext items={items}>
          {items.map(item => (
            <SortableItem
              key={item}
              id={item}
              item={item}
              itemMap={itemMap}
              secondaryActionMap={secondaryActionMap}
            />
          ))}
        </SortableContext>
      </List>
    </DndContext>
  );
};

export default DragAndDropList;
