export const vsDecl = `\
in vec2 totalPositions;
out float tpp;
`;

export const vsMain = `\
tpp = totalPositions.x + (totalPositions.y - totalPositions.x) * vPathPosition.y / vPathLength;
`;

export const fsDecl = `\
in float tpp;

uniform float time;
uniform float dashScale;
uniform float animationSpeed;
uniform bool animate;
`;

export const fsMainEnd = `\
vec4 color = vColor;
float opacity = 1.0 - smoothstep(0.5, 1.0, abs(vPathPosition.x));
color.a *= opacity;
if( animate ) {
  color.rgb *= 0.7;
  if( mod( tpp - (animationSpeed * (time / 40.0)), dashScale ) < (dashScale/2.) ) {
    fragColor = vec4(color.rgb, color.a * 0.5);
    return;
  }
}
fragColor = color;
`;
