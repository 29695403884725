import { HttpResponseError, serenityFetch } from 'helpers/api';

export const getPeople = async (organisationId: string): Promise<Person[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/people`, null);
  HttpResponseError.detect(response);
  return (await response.json()).people;
};

export type AddPersonRequestBody = Pick<Person, 'name' | 'role' | 'languageCode'>;
export const addPerson = async (organisationId: string, body: AddPersonRequestBody): Promise<Person> => {
  // this only creates a person with name and language ID
  const response = await serenityFetch('POST', `/organisations/${organisationId}/people`, body);
  HttpResponseError.detect(response);
  return (await response.json()).person;
};

export type UpdatePersonBody = Pick<Person, 'id' | 'name' | 'role' | 'languageCode' | 'version'>;
export const updatePerson = async (organisationId: string, body: UpdatePersonBody): Promise<void> => {
  // this only updates name and language ID
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/people/${body.id}`, body);
  HttpResponseError.detect(response);
};

export const deletePerson = async (organisationId: string, body: Pick<Person, 'id'>): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/people/${body.id}`, null);
  HttpResponseError.detect(response);
};

export type AddContactToPersonRequestBody = Pick<Contact, 'peopleId' | 'contactType' | 'contactValue' | 'isDefault'>;
export const addContactToPerson = async (organisationId: string, body: AddContactToPersonRequestBody): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/people/${body.peopleId}/contacts`, body);
  HttpResponseError.detect(response);
};

export const updateContact = async (organisationId: string, body: Contact): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/people/${body.peopleId}/contacts/${body.id}`, body);
  HttpResponseError.detect(response);
};

export const deleteContact = async (organisationId: string, body: Pick<Contact, 'id' | 'peopleId'>): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/people/${body.peopleId}/contacts/${body.id}`, null);
  HttpResponseError.detect(response);
};
