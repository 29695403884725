import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  newMessage: {
    width: '100%',
    padding: theme.spacing(3),
    borderTop: theme.border.default
  },
  newMessageText: {
    width: '100%',
    fontSize: '1rem',
    '& textarea': {
      height: '100%',
    }
  },
  charCountSendDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    position: 'fixed',
    bottom: 50,
    right: 20
  },
  charCount: {
    fontSize: '.8rem',
    color: theme.palette.common.text
  },
  sendButton: {
    fontSize: '.8rem',
    textTransform: 'capitalize',
    padding: theme.spacing(2, 5),
    boxShadow: 'none',
    marginTop: '0px',
    marginLeft: theme.spacing(1)
  }
}));

export default useStyles;
