import React from 'react';
import { useTranslations } from 'use-intl';
import {
  useGetMessagingWhitelistContactGroups,
  useMutateMessagingWhitelistContactGroupAssignDevices,
} from 'apis/rest/messagingWhitelistContactGroups/hooks';
import {
  AssignAssetsDialog,
  AssignAssetsDialogComponents,
  AssignAssetsDialogStatus,
} from 'components/dialogs/contactGroups/assignAssets';
import { ContactGroupPeopleTable } from 'components/pages/manage/contactGroups/common/contactGroupPeopleTable';

interface AssignAssetsDialogProps {
  open: boolean
  onClose: (status: AssignAssetsDialogStatus, assetIds: number[], contactGroupId?: number) => void
  assetIds: number[]
}

const components: AssignAssetsDialogComponents = {
  PeopleTable: ContactGroupPeopleTable
};

const MessagingWhitelistAssignAssetsDialog = ({ open, onClose, assetIds }: AssignAssetsDialogProps): JSX.Element => {
  const t = useTranslations('dialogs.messagingWhitelist.assignAssets');
  const { query: groupsQuery } = useGetMessagingWhitelistContactGroups();
  const mutation = useMutateMessagingWhitelistContactGroupAssignDevices();

  const title = t('title', { count: assetIds.length });

  return (
    <AssignAssetsDialog
      open={open}
      onClose={onClose}
      assetIds={assetIds}
      groupsQuery={groupsQuery}
      mutation={mutation}
      components={components}
      title={title}
      ariaLabel={title}
    />
  );
};

export default MessagingWhitelistAssignAssetsDialog;

export { AssignAssetsDialogStatus };
