import React from 'react';

const R44 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 14.016439,2.1588517 C 13.598055,2.17029 13.185071,2.3860153 12.875066,2.8027032 l -0.05853,0.079436 c -0.62001,0.8333765 -0.585598,2.1457693 0.07735,2.9454116 L 27.817438,23.826127 c 0.335064,5.116685 1.268088,12.147216 1.425671,12.404594 0.157101,0.256587 0.892503,0.368078 0.871709,0.754644 -0.03603,0.669557 0.515413,23.089362 0.731649,23.508941 0.172006,0.333757 0.542379,0.05763 0.811085,0.06063 0,0 -0.102983,1.40562 0.03345,1.442395 0.136427,0.03678 7.903903,-0.771368 7.903903,-0.771368 L 39.47366,57.663876 33.492949,57.450653 c 0.2729,-6.187579 0.655417,-19.934443 0.629218,-20.459009 -0.0193,-0.386641 0.717802,-0.494484 0.87589,-0.750463 0.06435,-0.104201 0.266954,-1.383638 0.503793,-3.144003 L 49.284035,49.72025 c 0.662947,0.799643 1.694094,0.77484 2.314102,-0.05853 l 0.05853,-0.07944 c 0.620008,-0.833375 0.587692,-2.147859 -0.07525,-2.947501 L 36.112252,27.975624 c 0.278021,-2.664925 0.499389,-5.394279 0.501702,-7.222425 0.01209,-9.555777 -4.446338,-8.648096 -4.446338,-8.648096 0,0 -0.157107,-0.03106 -0.411813,-0.02091 -0.956945,0.03815 -3.294617,0.686301 -3.913279,5.915908 L 15.19126,2.7399903 C 14.859785,2.3401693 14.434823,2.1474135 14.016439,2.1588517 Z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default R44;
