import React from 'react';

import { altitude } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import Quantity from '../quantity';

export interface DistanceProps {
  altitudeInMetres: number,
  precision?: number,
  smallUnits?: boolean
}

const Altitude = ({
  altitudeInMetres,
  precision,
  smallUnits = true
}: DistanceProps): JSX.Element => {
  const units = useUnitSettings().altitude;
  const altitudeInLocale = altitude.fromSI(altitudeInMetres, units);
  const unitsLabel = altitude.label(units);
  return (
    <Quantity value={altitudeInLocale} units={unitsLabel} precision={precision} smallUnits={smallUnits} />
  );
};

export default Altitude;
