import { Theme, useTheme } from '@mui/material';
import { useMemo } from 'react';

export const getSuppressantColors = (theme: Theme): Record<Suppressant, string> => ({
  Water: theme.palette.firefightingDrops.water,
  FreshWater: theme.palette.firefightingDrops.water,
  SaltWater: theme.palette.firefightingDrops.water,
  Unknown: theme.palette.firefightingDrops.unknown,
  Retardant: theme.palette.firefightingDrops.retardant,
  Foam: theme.palette.firefightingDrops.foam,
  Gel: theme.palette.firefightingDrops.gel,
});

export const useSuppressantColors = (): Record<Suppressant, string> => {
  const theme = useTheme();
  return useMemo(() => getSuppressantColors(theme), [theme]);
};
