import React from 'react';
import { useTranslations } from 'use-intl';
import { Typography } from '@mui/material';

export default () => {
  const t = useTranslations('components.OpenStreetMapCredit');

  return (
    <Typography variant="caption">
      {t.rich('osmAttribution', {
        osmLink: <a target="_blank" rel="noreferrer" href="https://openstreetmap.org/copyright">OpenStreetMap</a>
      })}
    </Typography>
  );
};
