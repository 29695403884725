import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useStartOfDay } from 'hooks/useStartOfDay';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { InferredEvent, InferredEventId, ReportWithInferredEvents } from './types';
import { inferredEventsQueryKeys } from './queryKeys';
import { getInferredEventsForAsset, getInferredEventsForOrganisation } from './requests';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetInferredEventsForAsset = <T = InferredEvent[]>(assetId: number | undefined, from: DateTime, until: DateTime, options: Options<InferredEvent[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = inferredEventsQueryKeys.asset(organisationId, assetId, from, until);
  return useQuery<InferredEvent[], HttpResponseError, T>({
    queryKey,
    queryFn: () => getInferredEventsForAsset(organisationId, assetId, from, until),
    ...options,
  });
};

export const useGetInferredEventsByReportIdForAsset = (assetId: number | undefined, from: DateTime, until: DateTime, options: Options<InferredEvent[], Record<number, InferredEventId[]>> = {}) => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const selectInferredEventsByReportId = useCallback((data: InferredEvent[]) => data
    .filter(e => featureAssets.hasAssetId(e.assetId))
    .reduce<Record<number, InferredEventId[]>>((acc, evt) => {
      if (!acc[evt.reportId]?.push(evt.eventId)) acc[evt.reportId] = [evt.eventId];
      return acc;
    }, {}), [featureAssets]);

  return useGetInferredEventsForAsset(assetId, from, until, {
    select: selectInferredEventsByReportId,
    ...options,
  });
};

// NOTE: This is for one-off on-demand fetching of inferred events
export const useFetchInferredEvents = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();
  const featureAssets = useFeatureAssets('events.inferred_events');

  return useCallback(async (assetIds: number[], from: number, until: number, staleTime = 0) => {
    const fromDt = DateTime.fromMillis(from);
    const untilDt = DateTime.fromMillis(until);

    const results = await Promise.allSettled(assetIds.map(assetId => queryClient.fetchQuery({
      queryKey: inferredEventsQueryKeys.asset(organisationId, assetId, fromDt, untilDt),
      queryFn: () => getInferredEventsForAsset(organisationId, assetId, fromDt, untilDt),
      staleTime,
    })));

    return results
      .filter(r => r.status === 'fulfilled')
      .flatMap(r => r.value)
      .filter(evt => featureAssets.hasAssetId(evt.assetId))
      .reduce<Record<number, InferredEventId[]>>((acc, evt) => {
        if (!acc[evt.reportId]?.push(evt.eventId)) acc[evt.reportId] = [evt.eventId];
        return acc;
      }, {});
  }, [queryClient, organisationId, featureAssets]);
};

/**
 * @deprecated
 */
const useCurrentInferredEvents = <T = InferredEvent[]>(options: Options<InferredEvent[], T> = {}) => {
  const organisationId = useOrganisationId();
  const from = useStartOfDay();
  const until = from.endOf('day'); // TODO: replace this with "now" and update over time
  const queryKey = inferredEventsQueryKeys.range(organisationId, from, until);
  return useQuery<InferredEvent[], HttpResponseError, T>(
    {
      queryKey,
      queryFn: () => getInferredEventsForOrganisation(organisationId, from, until),
      ...options,
    }
  );
};

/**
 * @deprecated Use `useInferredEventsForReports`
 */
export const useCurrentInferredEventsForReports = (reports: Report[], options: Options<InferredEvent[], InferredEvent[]> = {}): ReportWithInferredEvents[] => {
  const featureAssets = useFeatureAssets('events.inferred_events');
  const inferredEvents = useCurrentInferredEvents({ ...options, enabled: options.enabled && featureAssets.some });
  const inferredEventsByReportId = useMemo(() => inferredEvents.data?.reduce<Record<number, InferredEventId[]>>((acc, key) => {
    if (!acc[key.reportId]?.push(key.eventId)) acc[key.reportId] = [key.eventId];
    return acc;
  }, []), [inferredEvents]);
  return useMemo(() => {
    if (!inferredEvents.data || inferredEvents.data.length === 0 || (!featureAssets.all && !featureAssets.some)) {
      return reports.map(r => ({
        ...r,
        inferredEvents: null,
      }));
    }

    return reports.map(r => ({
      ...r,
      inferredEvents: featureAssets.hasAssetId(r.assetId) ? inferredEventsByReportId?.[r.id] ?? null : null,
    }));
  }, [featureAssets, inferredEvents.data, inferredEventsByReportId, reports]);
};

/**
 * @deprecated Use `useInferredEventsForReport`
 */
export const useCurrentInferredEventsForReport = (report: Report, options: Options<InferredEvent[], InferredEvent[]> = {}): ReportWithInferredEvents => {
  const arr = useCurrentInferredEventsForReports([report], options);
  return useCallback(() => arr[0], [arr])();
};
