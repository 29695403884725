import React from 'react';
import { useBlocker } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { Box } from '@mui/material';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';

const GeofenceBlocker: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  const t = useTranslations('pages.manage.geofencing');
  const blocker = useBlocker(({ currentLocation, nextLocation }) => (
    enabled && currentLocation.pathname !== nextLocation.pathname
  ));

  return (
    <ConfirmationDialog
      confirmText={t('dialogs.discard.confirmText')}
      title={t('dialogs.discard.title')}
      confirmButtonProps={{ color: 'error', variant: 'contained', size: 'large', sx: { minWidth: '10rem' } }}
      cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' } }}
      cancelText={t('dialogs.cancelText')}
      onConfirm={blocker.proceed}
      onCancel={blocker.reset}
      dialogProps={{ open: blocker.state === 'blocked' }}
    >
      <Box my={3}>{t('dialogs.discard.text')}</Box>
    </ConfirmationDialog>
  );
};

export default GeofenceBlocker;
