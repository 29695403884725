import { useQueryClient } from '@tanstack/react-query';
import { assetsQueryKeys } from 'apis/rest/assets/queryKeys';
import { deviceQueryKeys } from 'apis/rest/devices/queryKeys';
import { markersQueryKeys } from 'apis/rest/markers/queryKeys';
import { temporalShareQueryKeys } from 'apis/rest/temporalShares/queryKeys';
import { useNotification } from 'contexts/notification/useNotification';

enum EventType {
  AssetCreated = 'AssetCreated',
  AssetUpdated = 'AssetUpdated',
  ShareCreated = 'ShareCreated',
  ShareUpdated = 'ShareUpdated',
  RemoveGroupShares = 'RemoveGroupShares',
  RemoveOrgShares = 'RemoveOrgShares',
  GroupShareUpdated = 'GroupShareUpdated',
  OrgShareUpdated = 'OrgShareUpdated',
  GroupShareCreated = 'GroupShareCreated',
  OrgShareCreated = 'OrgShareCreated',
  ShareDeleted = 'ShareDeleted',
  DeviceCreated = 'DeviceCreated',
  DeviceUpdated = 'DeviceUpdated',
  MarkerCreated = 'MarkerCreated',
  MarkerUpdated = 'MarkerUpdated',
  MarkerDeleted = 'MarkerDeleted',
}

export const QueryInvalidator = () => {
  const queryClient = useQueryClient();
  const handleAssetEvent = (message: TPCNotification) => {
    if (!message.targetOrganisation) return;
    switch (message.notificationId) {
      case EventType.AssetCreated:
      case EventType.AssetUpdated:
        queryClient.invalidateQueries(
          assetsQueryKeys.all(message.targetOrganisation)
        );
        break;
      default:
        break;
    }
  };

  const handleDeviceEvent = (message: TPCNotification) => {
    if (!message.targetOrganisation) return;
    switch (message.notificationId) {
      case EventType.DeviceCreated:
      case EventType.DeviceUpdated:
        queryClient.invalidateQueries(
          deviceQueryKeys.all(message.targetOrganisation)
        );
        break;
      default:
        break;
    }
  };

  const handleMarkerEvent = (message: TPCNotification) => {
    if (!message.targetOrganisation) return;
    switch (message.notificationId) {
      case EventType.MarkerCreated:
      case EventType.MarkerUpdated:
      case EventType.MarkerDeleted:
        queryClient.invalidateQueries(
          markersQueryKeys.allMarkers(message.targetOrganisation)
        );
        break;
      default:
        break;
    }
  };

  const handleShareEvent = (message: TPCNotification) => {
    if (!message.targetOrganisation) return;
    switch (message.notificationId) {
      case EventType.ShareCreated:
      case EventType.ShareUpdated:
      case EventType.ShareDeleted:
      case EventType.RemoveGroupShares:
      case EventType.RemoveOrgShares:
      case EventType.GroupShareUpdated:
      case EventType.OrgShareUpdated:
      case EventType.GroupShareCreated:
      case EventType.OrgShareCreated:
        queryClient.invalidateQueries(
          temporalShareQueryKeys.all(message.targetOrganisation, 'to')
        );
        break;
      default:
        break;
    }
  };

  useNotification([EventType.AssetCreated, EventType.AssetUpdated], handleAssetEvent, true);
  useNotification([EventType.DeviceCreated, EventType.DeviceUpdated], handleDeviceEvent, true);
  useNotification(
    [EventType.MarkerCreated, EventType.MarkerUpdated, EventType.MarkerDeleted],
    handleMarkerEvent,
    true
  );
  useNotification(
    [
      EventType.ShareCreated,
      EventType.ShareUpdated,
      EventType.ShareDeleted,
      EventType.RemoveGroupShares,
      EventType.RemoveOrgShares,
      EventType.GroupShareUpdated,
      EventType.OrgShareUpdated,
      EventType.GroupShareCreated,
      EventType.OrgShareCreated,
    ],
    handleShareEvent,
    true
  );

  return null;
};
