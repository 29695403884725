import React from 'react';
import clsx from 'clsx';
import {
  LinearProgress,
  Typography
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import * as passwordHelper from 'helpers/password';
import { useTheme } from '@mui/material/styles';
import useStyles from './passwordStrength-styles';

const StrengthIndicator = ({
  value = ''
}) => {
  const theme = useTheme();
  const strength = passwordHelper.getPasswordStrength(value);
  const classes = useStyles();
  const t = useTranslation('passwordStrength');

  const messageColor = {
    color: theme.palette.primary[strength.color]
  }

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={strength.meterLevel}
        className={clsx(
          classes.root,
          { [classes.veryWeakColor]: strength.strength === 'veryWeak' },
          { [classes.weakColor]: strength.strength === 'weak' },
          { [classes.moderateColor]: strength.strength === 'moderate' },
          { [classes.strongColor]: strength.strength === 'strong' },
          { [classes.veryStrongColor]: strength.strength === 'veryStrong' },
          classes.bar, strength.strength
          )}
      />
      <Typography className={classes.passwordStrengthMessage}>
        {t('passwordStrength')}: <span style={messageColor}>{t(strength.strength)}</span>
      </Typography>
    </>
  );
};

export default StrengthIndicator;
