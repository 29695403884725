import React from 'react';

const B727 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 34.764446,58.000555 c 0,0 7.226997,2.901298 7.336736,2.904266 0.10974,0.0031 0.392535,-4.018334 0.359043,-4.178637 -0.03349,-0.160303 -7.16832,-6.022404 -7.16832,-6.022404 0,0 0.658579,-3.138793 1.074678,-4.534934 0.131475,-0.441142 0.823953,-0.461502 0.932774,-0.448665 0.214014,0.02524 0.84752,2.215363 1.565843,2.261033 0.231807,0.01474 1.15423,-2.52538 1.481903,-3.505615 0.670543,-2.005929 0.525003,-6.145921 0.52836,-6.706945 0.0012,-0.200768 -2.599064,-0.224536 -2.866861,-0.20808 -0.07869,0.0048 -0.05278,1.177869 -0.121217,1.352522 -0.08433,0.215179 -0.384491,-0.0088 -0.384491,-0.0088 l 0.249025,-3.45656 4.770309,0.748866 c 0,0 13.595887,6.238262 14.350328,6.179842 1.87788,-0.145406 0.298646,-4.936035 0.116729,-5.169268 C 56.80737,36.973984 44.196905,28.28426 44.196905,28.28426 l -6.418654,-5.359544 c 0,0 0.363545,-6.673156 0.109154,-10.512315 C 37.242818,2.6845423 34.225516,2.3278768 34.225516,2.3278768 c 0,0 -3.024585,0.288457 -3.888623,9.9993152 -0.340997,3.832437 -0.128196,10.5121 -0.128196,10.5121 l -6.538014,5.213317 c 0,0 -12.803427,8.402822 -12.990562,8.631887 -0.187135,0.229067 -1.8741198,4.982825 0,5.170587 0.752929,0.07543 14.486185,-5.8543 14.486185,-5.8543 l 4.785998,-0.640982 0.170929,3.461302 c 0,0 -0.305143,0.217028 -0.384589,0 -0.06448,-0.176153 -0.01209,-1.348302 -0.09066,-1.354913 -0.267356,-0.0225 -2.86748,-0.05744 -2.870827,0.143305 -0.0093,0.560955 -0.248276,4.696608 0.376811,6.717164 0.30546,0.987382 1.170302,3.547676 1.402384,3.538175 0.71917,-0.02944 1.401959,-2.204706 1.616489,-2.225106 0.109083,-0.01037 0.800923,0.02561 0.922406,0.469608 0.384476,1.405179 0.972026,4.558041 0.972026,4.558041 0,0 -7.265353,5.699532 -7.302453,5.859038 -0.0371,0.159507 0.154836,4.186166 0.264615,4.185678 0.109778,-4.89e-4 7.400432,-2.737894 7.400432,-2.737894 0,0 0.628916,4.397367 1.11772,4.403779 0.488802,0.0064 1.216852,-4.377423 1.216852,-4.377423 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default B727;
