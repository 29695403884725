import { useMemo } from "react";

export function useVisibilityChange() {
  const [isVisible, subscribe] = useMemo(() => {
    return [
      () => document.visibilityState === 'visible',
      (notify: () => void) => {
        window.addEventListener('visibilitychange', notify);

        return () => {
          window.removeEventListener('visibilitychange', notify);
        };
      },
    ];
  }, []);

  return { isVisible, subscribe };
}
