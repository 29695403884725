import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, Chip, CircularProgress, Dialog, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from '@mui/material';
import ClickToEditField from 'components/shared/clickToEditField/clickToEditField-view';
import DetailPanel from 'components/shared/DetailPanel';
import useTranslation from 'hooks/useTranslation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveUsercode } from 'apis/trackstar/serenity';
import AddIcon from '@mui/icons-material/Add';
import { useGetAllMarketSectors, useGetMarketSectorsForOrganisation, useMutateUpdateMarketSectorsForOrganisation } from 'apis/rest/marketSector/hooks';
import { ConfirmationDialog } from 'components/dialogs/shared/confirmation-dialog';
import useSnackbar from 'hooks/useSnackbar';
import { useStaff } from 'hooks/session/useStaff';
import { displaySnackbar } from 'slices/app.slice';
import { useAppDispatch } from 'store/useAppDispatch';

const OrganisationDetails = ({
  organisation,
  permissions,
}) => {
  const t = useTranslation('pages.organisationSettings');
  const [name, setName] = useState(organisation.name);
  const queryClient = useQueryClient();
  const [dialog, setDialog] = useState<{ open: boolean } | null>(null);
  const [secondarySector, setSecondarySector] = useState(null);

  const marketSectors = useGetAllMarketSectors();
  const orgMarketSectors = useGetMarketSectorsForOrganisation();
  const mutateUpdateMarketSector = useMutateUpdateMarketSectorsForOrganisation();
  const snackbar = useSnackbar();
  const isStaff = useStaff();
  const dispatch = useAppDispatch();

  const marketSectorData = useMemo(() => {
    if (marketSectors.isSuccess) {
      return organisation.name?.startsWith('TracPlus') ? marketSectors.data : marketSectors.data.filter(x => x.id !== 'internal');
    }
    return [];
  }, [marketSectors, organisation.name]);

  const orgMarketSectorData = useMemo(() => (orgMarketSectors.isSuccess ? orgMarketSectors.data : { secondaryMarketSectorIds: [] }), [orgMarketSectors]);

  const loading = marketSectors.isLoading || orgMarketSectors.isLoading || mutateUpdateMarketSector.isLoading;

  const sector = useMemo(() => {
    if (marketSectorData?.length > 0 && orgMarketSectorData.primaryMarketSectorId) {
      return marketSectorData.find(s => s.id === orgMarketSectorData.primaryMarketSectorId);
    }
    return null;
  }, [marketSectorData, orgMarketSectorData]);

  useEffect(() => {
    setName(organisation.name);
  }, [organisation]);

  const updateOrganisationName = useMutation({
    mutationFn: updatedOrg => saveUsercode(updatedOrg),
    onError: () => {
      dispatch(displaySnackbar({ id: 'orgNameUpdateFailed', text: t('orgNameUpdateFailed'), type: 'error' }));
    },
    onSuccess: () => {
      dispatch(displaySnackbar({ id: 'orgNameUpdated', text: t('orgNameUpdated'), type: 'success' }));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['organisations'] });
    },
    onMutate: newUsercode => newUsercode,
    mutationKey: ['updateOrganisationName'],
  });

  const updateMarketSector = useCallback((primaryId, secondaryIds, isStrategic) => {
    mutateUpdateMarketSector.mutate({ primaryMarketSectorId: primaryId, secondaryMarketSectorIds: secondaryIds, isStrategic }, {
      onSuccess: () => { displaySnackbar({ id: 'orgMarketSectorUpdate', text: t('marketSector.snackbar.success'), type: 'success' }); },
      onError: () => { displaySnackbar({ id: 'orgMarketSectorUpdateFailed', text: t('marketSector.snackbar.error'), type: 'error' }); }
    });
  }, [mutateUpdateMarketSector, displaySnackbar, t]);

  const updatePrimaryMarketSector = useCallback(primaryId => {
    updateMarketSector(primaryId, orgMarketSectorData.secondaryMarketSectorIds.filter(x => x !== primaryId), null);
  }, [updateMarketSector, orgMarketSectorData]);

  const deleteSecondaryMarketSector = useCallback(marketSectorId => {
    updateMarketSector(
      orgMarketSectorData?.primaryMarketSectorId,
      orgMarketSectorData.secondaryMarketSectorIds.filter(x => x !== marketSectorId),
      null
    );
  }, [updateMarketSector, orgMarketSectorData]);

  const updateSecondaryMarketSector = useCallback(marketSectorId => {
    if (orgMarketSectorData.primaryMarketSectorId === marketSectorId
      || orgMarketSectorData.secondaryMarketSectorIds.find(x => x === marketSectorId)) {
      snackbar.display({
        id: `marketsector.warning.${marketSectorId}`,
        text: t('marketSector.snackbar.exists'),
        type: 'warning',
      });
    } else {
      updateMarketSector(
        orgMarketSectorData?.primaryMarketSectorId,
        [...orgMarketSectorData.secondaryMarketSectorIds, marketSectorId],
        null
      );
    }
  }, [updateMarketSector, orgMarketSectorData, snackbar, t]);

  const dialogOnConfirm = useCallback(() => {
    if (secondarySector) {
      updateSecondaryMarketSector(secondarySector.id);
      setDialog(null);
      setSecondarySector(null);
    }
  }, [secondarySector, updateSecondaryMarketSector]);

  const addSecondaryMarketSector = useCallback(() => {
    setDialog({ open: true });
  }, []);

  const toggleIsStrategic = useCallback(value => {
    updateMarketSector(orgMarketSectorData.primaryMarketSectorId, orgMarketSectorData.secondaryMarketSectorIds, value);
  }, [orgMarketSectorData.primaryMarketSectorId, orgMarketSectorData.secondaryMarketSectorIds, updateMarketSector]);

  return (
    <DetailPanel px={3} pb={4} pt={4} mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h5" gutterBottom>{t('orgName')}</Typography>
            <ClickToEditField
              value={name}
              width="100%"
              canEdit={permissions.canEditOrganisation}
              onComplete={v => updateOrganisationName.mutate({ ...organisation, name: v })}
              canbeblank={false}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h5">{t('marketSector.primaryLabel')}</Typography>
            <Autocomplete
              loading={loading}
              options={marketSectorData} fullWidth
              onChange={(ev, value) => updatePrimaryMarketSector(value?.id)}
              value={sector}
              isOptionEqualToValue={(o, v) => o.id === v?.id}
              getOptionLabel={x => x?.name ?? ''} getOptionKey={x => x?.id ?? ''}
              disabled={!permissions.canEditOrganisation || loading}
              renderInput={params => (
                <TextField {...params}
                  helperText={sector?.description}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>)
                  }}
                />
              )}
              ListboxProps={{ style: { border: '1px solid lightgray' } }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h5">{t('marketSector.secondaryLabel')}</Typography>
            <Stack direction="row" spacing={2}>
              {marketSectorData.filter(x => orgMarketSectorData.secondaryMarketSectorIds.some(y => y === x.id)).map(x => (
                <Chip disabled={!permissions.canEditOrganisation || loading} key={x.id} label={x.name} onDelete={() => deleteSecondaryMarketSector(x.id)} />
              ))}
              <Chip disabled={!permissions.canEditOrganisation || loading} label={t('marketSector.add')} deleteIcon={<AddIcon />}
                onDelete={() => addSecondaryMarketSector()}
                onClick={() => addSecondaryMarketSector()} />
            </Stack>
          </Stack>
        </Grid>
        {isStaff && orgMarketSectorData?.primaryMarketSectorId && (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={orgMarketSectorData?.isStrategic ?? false} />}
                label="Strategic Customer (STAFF ONLY)"
                onChange={(_, checked) => toggleIsStrategic(checked)}
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
      {
        dialog && (
          <ConfirmationDialog
            confirmText={t('marketSector.dialog.saveButton')}
            title={t('marketSector.dialog.title')}
            confirmButtonProps={{ color: 'primary', variant: 'contained', size: 'large', sx: { minWidth: '10rem' }, disabled: !secondarySector }}
            cancelButtonProps={{ color: 'primary', variant: 'outlined', size: 'large', sx: { minWidth: '10rem' }, disabled: false }}
            cancelText={t('marketSector.dialog.cancelButton')}
            onConfirm={() => dialogOnConfirm()}
            onCancel={() => { setDialog(null); setSecondarySector(null); }}
            dialogProps={{ open: dialog.open, fullWidth: true }}>
            <Stack direction="column" spacing={1} sx={{ paddingBottom: '2em', paddingTop: '2em' }}>
              <Typography variant="h5">{t('marketSector.primaryLabel')}</Typography>
              <Autocomplete
                options={marketSectorData} fullWidth
                onChange={(ev, value) => setSecondarySector(value)}
                value={secondarySector}
                isOptionEqualToValue={(o, v) => o.id === v?.id}
                getOptionLabel={x => x?.name ?? ''} getOptionKey={x => x?.id ?? ''}
                renderInput={params => (
                  <TextField {...params}
                    helperText={secondarySector && secondarySector.description} />
                )}
                ListboxProps={{ style: { border: '1px solid lightgray' } }} />
            </Stack>
          </ConfirmationDialog>
        )
      }
    </DetailPanel>
  );
};

export default OrganisationDetails;
