import React, { useState, useEffect, ReactNode } from 'react';
import { useTranslations } from 'use-intl';
import { UseMutationResult } from '@tanstack/react-query';
import { Button, Stack, Typography } from '@mui/material';

interface RemovePanelComponents {
  DeleteDialog: (props: {
    open: boolean
    group: ContactGroup
    onClose: (status: string, groupId: number) => void
    mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id'>>
  }) => JSX.Element | null
}

interface RemovePanelProps {
  group: ContactGroup
  mutation: UseMutationResult<void, Error, Pick<ContactGroup, 'id'>>
  children: ReactNode
  components: RemovePanelComponents
}

export const Remove = ({
  group,
  mutation,
  children,
  components: { DeleteDialog },
}: RemovePanelProps): JSX.Element => {
  const t = useTranslations('pages.manage.contactGroups.common.contactGroupEdit.remove');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const canRemove = !group.isDefault;

  useEffect(() => {
    if (mutation.isSuccess) setIsDeleteDialogOpen(false);
  }, [mutation.isSuccess]);

  return (
    <>
      <Stack spacing={3} px={3} direction="row" justifyContent="space-between" height="4em">
        <Stack justifyContent="center">
          {children ?? <Typography>{t('description')}</Typography>}
        </Stack>
        <Button
          size="large"
          variant="outlined"
          color="error"
          sx={{ minWidth: '10rem' }}
          disabled={!canRemove}
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          {t('remove')}
        </Button>
      </Stack>
      <DeleteDialog
        open={isDeleteDialogOpen}
        group={group}
        mutation={mutation}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};
