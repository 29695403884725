import React from 'react';
import Generic from './generic';
import Airport from './airport';
import Water from './water';
import Fire from './fire';
import Fuel from './fuel';

export type MarkerIconType = 'generic' | 'airport' | 'fire' | 'fuel' | 'water';

interface MarkerIconProps {
  type: MarkerIconType,
  fill?: string,
}

const MarkerIcon = ({ type, fill }: MarkerIconProps) => {
  const iconProps = {
    outline: '#fff',
    fill: fill ?? '#000',
    outlineOpacity: 1,
  };
  switch (type) {
    case 'airport':
      return <Airport {...iconProps} />;
    case 'fire':
      return <Fire {...iconProps} />;
    case 'fuel':
      return <Fuel {...iconProps} />;
    case 'water':
      return <Water {...iconProps} />;
    default:
      return <Generic {...iconProps} />;
  }
};

export default MarkerIcon;
