import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: theme.border.default,
    backgroundColor: theme.palette.common.whiteConstant,
    overflowY: 'auto',
    borderRadius: '6px',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    maxHeight: '90%',
    maxWidth: '700px',
    boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
  },
  modalContent: {
    marginTop: 20,
    padding: 0
  },
  footer: {
    padding: theme.spacing(3, 6),
    textAlign: 'right'
  },
  formIframe: {
    border: 'none',
    overflow: 'hidden'
  }
}));
