import { ScatterplotLayer, IconLayer } from '@deck.gl/layers';
import ReactDOMServer from 'react-dom/server';
import React, { useMemo } from 'react';
import { hexToRGBArray } from 'helpers/color';
import DirectionIcon from 'components/shared/icons/directionIcon';

const WHITE: Color = [255, 255, 255];
const ICONSIZE = 20;
const ICON = {
  id: 'directionIndicator',
  url: `data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToString(<DirectionIcon fillColor="rgba(1,1,1,1)" />))}`,
  height: ICONSIZE,
  width: ICONSIZE,
  anchorX: ICONSIZE / 2,
  anchorY: ICONSIZE / 2,
  mask: true
};

interface Data {
  position: [number, number, number]
}

const useHighlightedReportLayers = (
  report: Pick<Report, 'longitude' | 'latitude' | 'altitude' | 'course'> | undefined,
  radius: number,
  color = '#fff',
  use3d = false
): [ScatterplotLayer, IconLayer] => {
  const { longitude, latitude, altitude = 0, course = 0 } = report ?? {};

  const data = useMemo<Data[]>(() => {
    if (longitude === undefined || latitude === undefined) return [];
    return [
      { position: [longitude, latitude, use3d ? altitude : 0] },
      { position: [longitude - 360, latitude, use3d ? altitude : 0] },
      { position: [longitude + 360, latitude, use3d ? altitude : 0] },
    ];
  }, [longitude, latitude, use3d, altitude]);

  const scatterPlotLayer = useMemo(() => new ScatterplotLayer<Data>({
    id: `highlighted-report-dot-layer-${use3d ? '3d' : '2d'}`,
    data,
    pickable: false,
    stroked: true,
    filled: true,
    opacity: 1,
    getFillColor: hexToRGBArray(color),
    getLineColor: WHITE,
    lineWidthUnits: 'pixels',
    radiusUnits: 'pixels',
    getRadius: radius,
    getLineWidth: 1,
    billboard: false,
    lineWidthScale: 3,
    parameters: { depthTest: false },
  }), [use3d, data, color, radius]);

  const iconLayer = useMemo(() => new IconLayer<Data>({
    id: `highlighted-report-icon-layer-${use3d ? '3d' : '2d'}`,
    data,
    getAngle: -course,
    getIcon: () => ICON,
    pickable: false,
    getColor: WHITE,
    opacity: 1,
    sizeScale: 0.75,
    getSize: 26,
    billboard: false,
    parameters: { depthTest: false },
  }), [use3d, data, course]);

  return [scatterPlotLayer, iconLayer];
};

export default useHighlightedReportLayers;
