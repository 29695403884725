import React from 'react';
import Page from 'components/pages/page';
import useTranslation from 'hooks/useTranslation';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import {
  SettingsMenuContainer,
  SettingsMenuPanelGrid,
  SettingsMenuPanelItem,
  SettingsMenuPageWrapper,
  SettingsMenuSvgIcon,
} from 'components/shared/settingsMenu';
import TuneIcon from '@mui/icons-material/Tune';
import { Alert, AlertTitle, Grid } from '@mui/material';

const OrganisationIcon = () => (
  <SettingsMenuSvgIcon width="16" height="16" viewBox="0 0 16 16">
    <path d="M8,6L8,6C6.3,6,5,4.7,5,3v0c0-1.7,1.3-3,3-3h0c1.7,0,3,1.3,3,3v0C11,4.7,9.7,6,8,6z" />
    <polygon points="10,16 6,16 6,12 4,10 4,7 12,7 12,10 10,12 " />
    <path data-color="color-2" d="M2,5L2,5C0.9,5,0,4.1,0,3v0c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v0C4,4.1,3.1,5,2,5z" />
    <polygon data-color="color-2" points="3,10.4 3,6 0,6 0,9 1,10 1,14 4,14 4,11.4 " />
    <path data-color="color-2" d="M14,5L14,5c1.1,0,2-0.9,2-2v0c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v0C12,4.1,12.9,5,14,5z" />
    <polygon data-color="color-2" points="13,10.4 13,6 16,6 16,9 15,10 15,14 12,14 12,11.4 " />
  </SettingsMenuSvgIcon>
);

const AccountIcon = () => (
  <SettingsMenuSvgIcon width="16" height="16" viewBox="0 0 16 16">
    <path d="M8,0C3.58,0,0,3.58,0,8s3.58,8,8,8s8-3.58,8-8S12.42,0,8,0z M8,2.4c1.32,0,2.4,1.08,2.4,2.4c0,1.33-1.08,2.4-2.4,2.4S5.6,6.13,5.6,4.8C5.6,3.48,6.68,2.4,8,2.4z M8,13.76c-2,0-3.76-1.02-4.8-2.58C3.22,9.6,6.4,8.72,8,8.72s4.78,0.88,4.8,2.46C11.76,12.74,10,13.76,8,13.76z" />
  </SettingsMenuSvgIcon>
);

const SettingsPage = () => {
  const t = useTranslation('pages.settings');
  const usercodeLogin = useIsUsercodeLogin();

  return (
    <Page>
      <SettingsMenuPageWrapper>
        <SettingsMenuContainer title={t('legacySettings.title')}>
          <SettingsMenuPanelGrid>
            <Grid item container xs={12} sm={6} xl={4} sx={{ height: '100%', alignItems: 'center', justifyItems: 'stretch' }}>
              <Alert severity="warning" icon={<TuneIcon fontSize="inherit" />} variant="outlined" sx={{ py: 2, ml: 3, alignItems: 'center' }}>
                <AlertTitle>{t('userSettings.movedTitle')}</AlertTitle>
                {t('userSettings.movedDescription')}
              </Alert>
            </Grid>
            <SettingsMenuPanelItem
              to="/settings/organisation"
              icon={<OrganisationIcon />}
              title={t('legacySettings.organisationSettingsButton.title')}
              description={t('legacySettings.organisationSettingsButton.description')}
            />
            {!usercodeLogin && (
              <SettingsMenuPanelItem
                to="/settings/account"
                icon={<AccountIcon />}
                title={t('userSettings.accountSettingsButton.title')}
                description={t('userSettings.accountSettingsButton.description')}
              />
            )}
          </SettingsMenuPanelGrid>
        </SettingsMenuContainer>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default SettingsPage;
