import { lineString, Units } from '@turf/helpers';
import destination from '@turf/destination';
import truncate from '@turf/truncate';
import lineToPolygon from '@turf/line-to-polygon';

export const distanceRing = (center: [number, number], radius: number, units: Units, steps = 64, polygon = false) => {
  const origin = [0, center[1]];
  const bearingOffset = center[1] >= 0 ? 0 : -180;
  const bearings = Array.from({ length: steps }, (v, i) => ((360 / steps) * i + bearingOffset));
  const points = bearings.map(b => destination(origin, radius, b, { units }).geometry.coordinates);

  // add closing point
  points.push([...points[0]]);

  // wrap longitude into -180 to 180 range
  let offset = center[0] % 360;
  if (offset > 180) offset -= 360;
  if (offset <= -180) offset += 360;

  points.forEach(p => {
    p[0] += offset;
  });

  // make points continuous so as not to jump more than 180 degrees in either direction
  const continuousPoints = points.reduce<number[][]>((acc, p) => {
    const prev = acc[acc.length - 1];
    if (prev) {
      if (-prev[0] + p[0] > 180) {
        acc.push([p[0] - 360, p[1]]);
        return acc;
      }
      if (prev[0] - p[0] > 180) {
        acc.push([p[0] + 360, p[1]]);
        return acc;
      }
    }
    acc.push(p);
    return acc;
  }, []);

  if (polygon) {
    const polePoint = center[1] > 0 ? 90 : -90;

    const withPointsAtThePoles = continuousPoints.flatMap((curr, i, a) => {
      const prev = i > 0 ? a[i - 1] : a[a.length - 1];
      // first points go to the polePoint to make sure we fill in the nighttime zone
      if (i === 0) {
        return [[prev[0], polePoint], [curr[0], polePoint], curr];
      }
      return [curr];
    });

    return truncate(lineToPolygon(lineString(withPointsAtThePoles)), { precision: 6, coordinates: 2 });
  }

  const line = lineString(continuousPoints);

  // Round to 6 decimal places
  truncate(line, { precision: 6, coordinates: 2, mutate: true });

  return line;
};
