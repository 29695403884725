import React, { ReactNode } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Box, Fab, Stack } from '@mui/material';
import { Link, useLocation, useMatches, UIMatch } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import {
  BackLinkState,
  IceContactGroupBackLinkState,
  MessagingWhitelistContactGroupBackLinkState,
  PersonBackLinkState,
} from 'helpers/location';
import { useGetIceContactGroups } from 'apis/rest/iceContactGroups/hooks';
import { useGetMessagingWhitelistContactGroups } from 'apis/rest/messagingWhitelistContactGroups/hooks';
import { useGetPeople } from 'apis/rest/people/hooks';
import { useTranslations } from 'use-intl';

const IceContactGroupName = ({ id }: { id: number }): JSX.Element | null => {
  const { query } = useGetIceContactGroups();
  const name = query.data?.find(g => g.id === id)?.name;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return name ? <>{name}</> : null;
};

const MessagingWhitelistContactGroupName = ({ id }: { id: number }): JSX.Element | null => {
  const { query } = useGetMessagingWhitelistContactGroups();
  const name = query.data?.find(g => g.id === id)?.name;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return name ? <>{name}</> : null;
};

const PersonName = ({ id }: { id: number }): JSX.Element | null => {
  const { query } = useGetPeople();
  const name = query.data?.find(p => p.id === id)?.name;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return name ? <>{name}</> : null;
};

const Breadcrumbs = (): JSX.Element | null => {
  const routeMatches = useMatches() as UIMatch<undefined, { breadcrumb: ReactNode } | undefined>[];
  const breadcrumbRoutes = routeMatches.filter(r => r.handle?.breadcrumb && `${r.pathname}/` !== routeMatches.at(-1)!.pathname);

  return (
    <Stack direction="row" spacing={-0.5}>
      {breadcrumbRoutes.map((item, index) => (
        <Fab key={item.id} size="small" variant="extended" color="primary" sx={{ zIndex: 1, outline: '4px solid', outlineColor: 'common.lightGrey' }} component={Link} to={item.pathname}>
          {index === 0 && <ArrowBack />}
          <Box sx={{ mx: 1 }}>{item.handle?.breadcrumb}</Box>
        </Fab>
      ))}
    </Stack>
  )
};

const BackLink = (): JSX.Element | null => {
  const t = useTranslations('components.LinkAbove');
  const location = useLocation();
  const backLink = match<BackLinkState | undefined, { to: string, label: ReactNode } | null>(location.state)
    .with(P.when(IceContactGroupBackLinkState.isValid), state => ({
      to: IceContactGroupBackLinkState.pathname(state),
      label: <IceContactGroupName id={state.id} />,
    }))
    .with(P.when(MessagingWhitelistContactGroupBackLinkState.isValid), state => ({
      to: MessagingWhitelistContactGroupBackLinkState.pathname(state),
      label: <MessagingWhitelistContactGroupName id={state.id} />,
    }))
    .with(P.when(PersonBackLinkState.isValid), state => ({
      to: PersonBackLinkState.pathname(state),
      label: <PersonName id={state.id} />,
    }))
    .otherwise(() => null);

  if (!backLink) return null;

  return (
    <Stack direction="row">
      <Fab size="small" variant="extended" color="primary" component={Link} to={backLink.to} sx={{ zIndex: 1 }}>
        <ArrowBack />
        <Box sx={{ mx: 1 }}>{t.rich('returnTo', backLink)}</Box>
      </Fab>
    </Stack>
  );
}

const LinkAbove = (): JSX.Element | null => {
  return (
    <Stack direction="column" mb={4} spacing={2}>
      <Breadcrumbs />
      <BackLink />
    </Stack>
  );
};

export default LinkAbove;
