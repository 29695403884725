import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  pageWrapper: {
    backgroundColor: theme.palette.common.lightGrey,
    padding: theme.spacing(8, 0),
    flex: 1,
    overflowY: 'auto',
    textAlign: 'left'
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
}));
