import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {
  DeleteGroupShareBody,
  DeleteShareBody,
  TemporalSharesResult,
} from 'apis/rest/temporalShares/types';
import {
  useDeleteShare,
  useGetTemporalSharesFromList,
} from 'apis/rest/temporalShares/hooks';
import useLastDefined from 'hooks/useLastDefined';
import useSnackbar from 'hooks/useSnackbar';
import useTimezone from 'hooks/session/useTimezone';
import TPDialogTitle from 'components/dialogs/shared/TPDialogTitle';
import { isTemporalGroupShare } from '../helpers';
import ShareDevice from './shareDevice';
import ShareTime from '../shareTime';

interface DeleteDialogProps {
  shareId: number | undefined
  isGroupShare?: boolean
  isSelfShare?: boolean
  onClose: () => void
}

const DeleteDialog = ({ onClose, shareId, isGroupShare = false, isSelfShare = false }: DeleteDialogProps) => {
  const t = useTranslations('pages.sharing');
  const tz = useTimezone();

  const sharesQuery = useGetTemporalSharesFromList({
    select: useCallback(
      (data: TemporalSharesResult) => {
        if (isSelfShare) return data.selfShares.find(s => s.id === shareId);
        if (isGroupShare) return data.groupShares.find(s => s.id === shareId);
        return data.shares.find(s => s.id === shareId);
      },
      [shareId, isGroupShare, isSelfShare],
    ),
    enabled: shareId !== undefined,
  });

  const share = useLastDefined(sharesQuery.data, []);

  const mutation = useDeleteShare();

  const snackbar = useSnackbar();

  const canDelete = !!share && !mutation.isPending;

  const onSave = () => {
    if (!canDelete) return;

    const value: DeleteShareBody | DeleteGroupShareBody = isTemporalGroupShare(share)
      ? { id: share.id, groupId: share.groupId }
      : { id: share.id, organisationId: share.organisationId };

    mutation.mutate(value, {
      onSuccess: () => {
        snackbar.display({ id: 'deleteShareSuccess', type: 'success', text: 'Share deleted successfully' });
        onClose();
      },
    });
  };

  const onExited = () => {
    mutation.reset();
  };

  return (
    <Dialog
      open={shareId !== undefined}
      TransitionProps={{ onExited }}
      fullWidth
      maxWidth="md"
    >
      <TPDialogTitle>{t(isSelfShare ? 'delete.titleSelf' : 'delete.title')}</TPDialogTitle>
      <DialogContent>
        <Stack spacing={3} mt={3}>
          <Typography>{t('delete.prompt')}</Typography>

          <ShareDevice share={share} />

          {!isSelfShare && (
            <Box>
              <Typography variant="h5" mb={1}>{t('delete.sharedWith')}</Typography>
              {share && <Typography>{isTemporalGroupShare(share) ? share.groupName : share.organisationName}</Typography>}
            </Box>
          )}

          <Box>
            <Typography variant="h5" mb={1}>{t('dateRangePicker.label')}</Typography>
            {share && <ShareTime share={share} timezone={tz} />}
          </Box>

          <Box>
            <Typography variant="h5" mb={1}>{t('permissionsPicker.trails.label')}</Typography>
            {share?.canViewCurrent && share.canViewHistory && t('permissionsPicker.trails.options.all')}
            {share?.canViewCurrent && !share.canViewHistory && t('permissionsPicker.trails.options.live')}
            {!share?.canViewCurrent && share?.canViewHistory && t('permissionsPicker.trails.options.historic')}
            {!share?.canViewCurrent && !share?.canViewHistory && t('permissionsPicker.trails.options.none')}
          </Box>

          <Box>
            <Typography variant="h5" mb={1}>{t('permissionsPicker.permissions.label')}</Typography>
            {share && (share.canSendConfiguration || share.canSendTextMessages || share.canViewForms || share.canEditCallSign) ? (
              <Stack spacing={2} mt={1}>
                {share.canSendConfiguration && (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={(
                      <>
                        <Typography>{t('permissionsPicker.permissions.options.canSendConfiguration')}</Typography>
                        <Typography variant="subtitle1">{t('permissionsPicker.permissions.descriptions.canSendConfiguration')}</Typography>
                      </>
                    )}
                    disabled
                  />
                )}
                {share.canSendTextMessages && (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={(
                      <>
                        <Typography>{t('permissionsPicker.permissions.options.canSendTextMessages')}</Typography>
                        <Typography variant="subtitle1">{t('permissionsPicker.permissions.descriptions.canSendTextMessages')}</Typography>
                      </>
                    )}
                    disabled
                  />
                )}
                {share.canViewForms && (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={(
                      <>
                        <Typography>{t('permissionsPicker.permissions.options.canViewForms')}</Typography>
                        <Typography variant="subtitle1">{t('permissionsPicker.permissions.descriptions.canViewForms')}</Typography>
                      </>
                    )}
                    disabled
                  />
                )}
                {share.canEditCallSign && (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={(
                      <>
                        <Typography>{t('permissionsPicker.permissions.options.canEditCallSign')}</Typography>
                        <Typography variant="subtitle1">A{t('permissionsPicker.permissions.descriptions.canEditCallSign')}sset</Typography>
                      </>
                    )}
                    disabled
                  />
                )}
              </Stack>
            ) : (
              <Typography>{t('delete.noPermissions')}</Typography>
            )}
          </Box>

          {share?.notes && (
            <Box>
              <Typography variant="h5" mb={1}>{t('note.label')}</Typography>
              <Typography>{share.notes}</Typography>
            </Box>
          )}

          {!isSelfShare && share?.canViewHistory && (
            <Alert severity="warning">
              {t.rich('delete.warning', {
                recipient: isTemporalGroupShare(share) ? share.groupName : share.organisationName,
                br: () => <br />,
              })}
            </Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, borderTop: 1, borderColor: 'common.midGrey', justifyContent: 'stretch' }}>
        <Box flex={1}>
          <Collapse in={mutation.isError}>
            <Alert sx={{ mb: 3 }} severity="error">{mutation.error?.message}</Alert>
          </Collapse>
          <Stack spacing={3} direction="row" height="4em" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              sx={{ minWidth: '10rem' }}
              disabled={mutation.isPending}
              onClick={onClose}
            >
              {t('delete.actions.cancel')}
            </Button>
            <Button
              size="large"
              variant="contained"
              color="error"
              sx={{ minWidth: '10rem' }}
              onClick={onSave}
              disabled={!canDelete}
            >
              {t(mutation.isPending ? 'delete.actions.deleting' : 'delete.actions.delete')}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
