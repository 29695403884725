import React, { useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { useTranslations } from 'use-intl';
import { Edit as EditIcon } from '@mui/icons-material';
import HashScroll from 'components/shared/hashScroll';
import SettingsSection from 'components/shared/settingsSection';
import DetailPanel from 'components/shared/DetailPanel';
import usePermissions from 'hooks/session/usePermissions';
import OrganisationMembers from './members';
import UserInvitation from './userInvitation/index';
import OrganisationContactModal from './organisationContactModal';
import OrganisationContact from './organisationContact';
import OrganisationDetails from './organisationDetails';

interface OrganisationProps {
  user: ReduxState['session']['user'];
  organisation: Organisation;
}

const OrganisationSettings: React.FC<OrganisationProps> = ({
  user,
  organisation,
}) => {
  const t = useTranslations('pages.organisationSettings');
  const permissions = usePermissions();
  const [editingPrimaryContact, setEditingPrimaryContact] = useState(false);

  return (
    <>
      <HashScroll />
      <SettingsSection title={t('tableDetails')} description={t('tableDetailsSubtext')}>
        <OrganisationDetails organisation={organisation} />

        <Grid container direction="row" spacing={3} alignItems="stretch">
          { /* # Primary Contact */ }
          <Grid item sm={12}>
            <DetailPanel p={3} height="100%">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5">{t('primaryOrgContactTitle')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {permissions.canEditOrganisation && (
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                      <IconButton size="small" onClick={() => setEditingPrimaryContact(true)} aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      { editingPrimaryContact ? (
                        <OrganisationContactModal
                          departmentName={t('primaryOrgContactTitle')}
                          organisation={organisation}
                          mode="primary"
                          onClose={() => setEditingPrimaryContact(false)}
                        />
                      ) : undefined }
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <OrganisationContact organisation={organisation} mode="primary" />
                </Grid>
              </Grid>
            </DetailPanel>
          </Grid>
        </Grid>
      </SettingsSection>

      <SettingsSection title={t('users')} description={t('userListDescription')}>
        <OrganisationMembers organisation={organisation} user={user} permissions={permissions} />
      </SettingsSection>

      { /* # Invite User */ }
      {permissions.canEditOrganisation && (
        <SettingsSection title={t('newUserTitle')} description={t('newUserDescription')}>
          <UserInvitation />
        </SettingsSection>
      )}
    </>
  );
};

export default OrganisationSettings;
