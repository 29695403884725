import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { resetEverything } from 'slices/session/session.slice';

/**
 * This reducer is for all application configuration, UI settings etc.
 */

export interface TableSettings {
  searchQuery: string;
  selectedPage: number;
  sortOrder: { field: string, order: 'asc' | 'desc' } | null;
  filters?: { columnId: string, value: string }[];
  columns?: Record<string, boolean>;
}

export type SerialType = 'tpSerial' | 'imei' | 'manufacturerSerial';
type SpeedUnit = 'kmh' | 'km/h' | 'mph' | 'knots';
interface ViewportSettings {
  center: [number, number]
  zoom: number
}

export interface Settings {
  ui: {
    darkMode: boolean | null,
    assetLabel: 'name' | 'tailNumber' | 'callSign',
    assetDetailSelected: string,
    serialType: SerialType,
    rowsPerPage: number
  },
  map: {
    highContrastControls: boolean,
    previousColors: string[],
  },
  assetsTable: TableSettings & {
    showArchived: boolean
  },
  devicesTable: TableSettings,
  usersHistoryTable: TableSettings,
  staffAssetSearch: TableSettings & {
    assetSearchQuery: string | undefined
  },
  tripReportsTable: TableSettings & {
    query: {
      assets: number[],
      // Timestamp in seconds
      from: number,
      // Timestamp in seconds
      until: number
    } | null,
    displayUTC: boolean,
  },
  missionReportsTable: TableSettings,
  geofencingListTable: TableSettings,
  friendsTable: TableSettings,
  groupsTable: TableSettings,
  sharedByMeTable: TableSettings,
  sharedToMeTable: TableSettings,
  peopleTable: TableSettings,
  iceTable: TableSettings,
  assetGroupTable: TableSettings,
  iceAssetsTable: TableSettings,
  messagingWhitelistTable: TableSettings,
  messagingWhitelistAssetsTable: TableSettings,
  eventNotitficationGroupsTable: TableSettings,
  peopleGroupsTable: TableSettings,
  marketSectorsTable: TableSettings,
  marketSectorsOrgTable: TableSettings,
  dailyFlightRecords: {
    query: { assets?: number[], day?: string },
  },
  tripAnalysis: {
    query: { assets?: number[], from?: number, until?: number },
  },
  units: {
    distance: 'kilometres' | 'statuteMiles' | 'nauticalMiles' | 'metres' | 'feet',
    speed: SpeedUnit,
    speedAir: SpeedUnit,
    speedLand: SpeedUnit,
    speedSea: SpeedUnit,
    speedPerson: SpeedUnit,
    altitude: 'metres' | 'feet',
    bearing: 'degreesTrue' | 'degreesGeographic' | 'degreesMagnetic',
    coordinate: 'coordinatesDD' | 'coordinatesDMS' | 'coordinatesDDM',
    area: 'squareKilometres' | 'acres' | 'hectares' | 'squareMiles' | 'squareNauticalMiles',
    volume: 'litres' | 'gallons',
    duration: 'hoursMinutes' | 'decimalTime',
  }
}

const initialTableSettings: TableSettings = {
  searchQuery: '',
  selectedPage: 0,
  sortOrder: null,
};

const initialState: Settings = {
  ui: {
    darkMode: null,
    assetLabel: 'name',
    assetDetailSelected: 'altitudeAtSpeed',
    serialType: 'tpSerial',
    rowsPerPage: 10
  },
  map: {
    highContrastControls: true,
    previousColors: [], // this is a history of colors selected in the custom color picker plugin
  },
  assetsTable: {
    filters: [],
    ...initialTableSettings,
    columns: {
      name: true,
      makeModelVariant: true,
      device: true,
      deviceSerialNumber: true,
      watchlistGroup: true,
      owner: true,
    },
    showArchived: false,
  },
  usersHistoryTable: {
    ...initialTableSettings,
    columns: {
      name: true,
      email: true,
      lastLogin: true,
    },
    sortOrder: { field: 'lastLogin', order: 'desc' },
  },
  staffAssetSearch: {
    filters: [],
    assetSearchQuery: undefined,
    ...initialTableSettings
  },
  devicesTable: {
    ...initialTableSettings,
    columns: {
      makeModel: true,
      tpSerial: true,
      status: true,
      asset: true,
    },
  },
  tripReportsTable: {
    query: null,
    displayUTC: false,
    ...initialTableSettings
  },
  dailyFlightRecords: {
    query: {}
  },
  tripAnalysis: {
    query: {},
  },
  missionReportsTable: initialTableSettings,
  geofencingListTable: initialTableSettings,
  friendsTable: initialTableSettings,
  groupsTable: initialTableSettings,
  sharedByMeTable: initialTableSettings,
  sharedToMeTable: initialTableSettings,
  peopleTable: initialTableSettings,
  iceTable: initialTableSettings,
  assetGroupTable: initialTableSettings,
  iceAssetsTable: initialTableSettings,
  messagingWhitelistTable: initialTableSettings,
  messagingWhitelistAssetsTable: initialTableSettings,
  eventNotitficationGroupsTable: initialTableSettings,
  peopleGroupsTable: initialTableSettings,
  marketSectorsTable: initialTableSettings,
  marketSectorsOrgTable: initialTableSettings,
  // Units can be assumed to be stored metric, displayed as per these settings
  units: {
    distance: 'kilometres',
    speed: 'kmh',
    speedAir: 'kmh',
    speedLand: 'kmh',
    speedSea: 'kmh',
    speedPerson: 'kmh',
    altitude: 'metres',
    bearing: 'degreesTrue',
    coordinate: 'coordinatesDD',
    area: 'squareKilometres',
    volume: 'litres',
    duration: 'hoursMinutes',
  }
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSetting: (state, action: PayloadAction<{
      category: keyof Settings,
      field: string,
      value: any
    }>) => {
      const { category, field, value } = action.payload;
      (state[category] as any)[field] = value;
    },
    toggleControlContrast: (state, action: PayloadAction<boolean>) => {
      state.map.highContrastControls = action.payload;
    },
    setPreviousColors: (state, action: PayloadAction<string>) => {
      if (state.map.previousColors) {
        const preColorsLen = state.map.previousColors.length;
        if (preColorsLen < 5) {
          state.map.previousColors.push(action.payload);
        } else {
          state.map.previousColors = [...state.map.previousColors.slice(1), action.payload];
        }
      } else {
        state.map.previousColors = [action.payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetEverything, () => initialState);
  },
});

export const { updateSetting, toggleControlContrast, setPreviousColors } = settingsSlice.actions;

const migrations: Record<number, (state: any) => any> = {
  0: (): Settings => initialState,
  1: (state: Settings): Settings => ({
    ...state,
    map: {
      ...state.map,
      highlightSelectedObject: true,
      unselectedItemOpacity: 0.5
    }
  }),
  2: (state: Settings): Settings => ({
    ...state,
    map: {
      ...state.map,
      showZoomControls: true,
      animateToSelection: false
    }
  }),
  3: (state: Settings): Settings => ({
    ...state,
    map: {
      ...state.map,
    }
  }),
  4: (state: Settings): Settings => ({
    ...state,
    locale: {
      ...state.locale,
      organisationTimezone: {}
    }
  }),
  5: (state: any): any => ({
    ...state,
    map: {
      ...state.map,
      previousColors: []
    }
  }),
  6: (state: any): any => ({
    ...state,
    map: {
      ...state.map,
      lastNumberOfDaysReported: 1
    }
  }),
  7: (state: Settings): Settings => ({
    ...state,
    ui: {
      ...state.ui,
      assetDetailSelected: 'altitudeAtSpeed',
      defaultSerialType: 'tpSerial'
    }
  }),
  8: (state: any): any => state,
  9: (state: any): any => {
    const newState = { ...state };
    delete newState.settings.locale.availableTimezones;
    delete newState.settings.locale.availableLanguages;
    delete newState.settings.availableUnits;
    return newState;
  },
  10: (state: Settings): Settings => ({
    ...state,
    panOnSelect: false,
    units: {
      ...state.units,
      bearing: 'degreestrue',
      coordinate: 'coordinatesdd',
      area: 'squarekilometres'
    },
  }),
  11: (state: Settings): Settings => ({
    ...state,
    units: {
      ...state.units,
      bearing: 'degreesTrue',
      coordinate: 'coordinatesDD',
      area: 'squareKilometres'
    },
  }),
  12: (state: Settings): Settings => ({
    ...state,
    ui: {
      ...state.ui,
      serialType: state.ui.defaultSerialType,
      rowsPerPage: 10,
    },
    assetsTable: {
      ...state.assetsTable,
      ...initialTableSettings,
      filters: []
    },
    devicesTable: {
      ...state.devicesTable,
      ...initialTableSettings,
      selectedPage: 0,
    },
    tripReportsTable: {
      ...state.tripReportsTable,
      ...initialTableSettings,
      query: null
    },
    missionReportsTable: {
      ...state.missionReportsTable,
      ...initialTableSettings,
    },
    geofencingListTable: {
      ...state.geofencingListTable,
      ...initialTableSettings,
    },
    friendsTable: {
      ...state.friendsTable,
      ...initialTableSettings,
    },
    groupsTable: {
      ...state.groupsTable,
      ...initialTableSettings,
    },
    sharedByMeTable: {
      ...state.sharedByMeTable,
      ...initialTableSettings,
    },
    sharedToMeTable: {
      ...state.sharedToMeTable,
      ...initialTableSettings,
    },
  }),
  13: (state: Settings): Settings => ({
    ...state,
    tripReportsTable: {
      ...state.tripReportsTable,
      ...initialState.tripReportsTable,
    },
  }),
  14: (state: Settings): Settings => ({
    ...state,
    peopleTable: {
      ...state.peopleTable,
      ...initialState.peopleTable,
    },
  }),
  15: (state: Settings): Settings => ({
    ...state,
    iceTable: {
      ...state.iceTable,
      ...initialState.iceTable,
    },
  }),
  16: (state: Settings): Settings => ({
    ...state,
    iceAssetsTable: {
      ...state.iceAssetsTable,
      ...initialState.iceAssetsTable,
    },
  }),
  17: (state: Settings): Settings => ({
    ...state,
    messagingWhitelistTable: {
      ...state.messagingWhitelistTable,
      ...initialState.messagingWhitelistTable,
    },
  }),
  18: (state: Settings): Settings => ({
    ...state,
    messagingWhitelistAssetsTable: {
      ...state.messagingWhitelistAssetsTable,
      ...initialState.messagingWhitelistAssetsTable,
    },
  }),
  19: (state: Settings): Settings => ({
    ...state,
    assetsTable: {
      ...state.assetsTable,
      columns: initialState.assetsTable.columns,
    },
  }),
  20: (state: Settings): Settings => ({
    ...state,
    assetsTable: {
      ...state.assetsTable,
      showArchived: false,
    },
  }),
  21: (state: Settings): Settings => ({
    ...state,
    devicesTable: {
      ...state.devicesTable,
      columns: {
        ...initialState.devicesTable.columns,
        tpSerial: state.ui.serialType === 'tpSerial',
        imei: state.ui.serialType === 'imei',
        manufacturerSerial: state.ui.serialType === 'manufacturerSerial',
      },
    },
  }),
  22: (state: Settings): Settings => ({
    ...state,
    ui: {
      ...state.ui,
      assetLabel: initialState.ui.assetLabel,
    },
  }),
  23: (state: Settings): Settings => ({
    ...state,
    dailyFlightRecords: { query: {} }
  }),
  24: (state: Settings): Settings => ({
    ...state,
    tripAnalysis: { query: {} }
  }),
  25: (state: Settings): Settings => ({
    ...state,
    assetGroupTable: {
      ...state.assetGroupTable,
      ...initialState.assetGroupTable,
    },
  }),
  26: (state: Settings): Settings => {
    const newState = { ...state };
    delete (newState as any).locale.organisationTimezone;
    return newState;
  },
  27: (state: Settings): Settings => ({
    ...state,
    units: {
      ...state.units,
      volume: 'litres',
    },
  }),
  28: (state: Settings): Settings => ({
    ...state,
    units: {
      ...state.units,
      duration: 'hoursMinutes',
    },
  }),
  29: (state: Settings): Settings => ({
    ...state,
    units: {
      ...state.units,
      speedAir: state.units.speed,
      speedLand: state.units.speed === 'knots' ? 'kmh' : state.units.speed,
      speedSea: state.units.speed,
      speedPerson: state.units.speed,
    },
  }),
  30: (state: Settings): Settings => ({
    ...state,
    map: {
      ...state.map,
      highContrastControls: true,
    }
  }),
  31: (state: Settings) => {
    const newState = { ...state };
    delete (newState as any).locale;
    return newState;
  },
};

const persistConfig = {
  key: 'settings',
  version: 31,
  storage,
  migrate: createMigrate(migrations, { debug: import.meta.env.DEV }),
};

export default persistReducer(persistConfig, settingsSlice.reducer);
