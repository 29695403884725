import React from 'react';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useTranslations } from 'use-intl';
import { PersonLanguageCode } from 'constants/person';
import { UseValidateNameResult } from 'hooks/people/useValidateName';
import { PersonLanguagePicker } from './personLanguagePicker-view';

interface FieldsProps {
  name: string
  setName: (name: string) => void
  role: string
  setRole: (role: string) => void
  languageCode: PersonLanguageCode
  setLanguageCode: (code: PersonLanguageCode) => void
  disabled: boolean
  validation: UseValidateNameResult
  autoFocus?: boolean
  allowMissingName?: boolean
}

const Fields = ({
  name,
  setName,
  role,
  setRole,
  languageCode,
  setLanguageCode,
  disabled,
  validation,
  autoFocus,
  allowMissingName = false,
}: FieldsProps) => {
  const t = useTranslations('pages.manage.person');

  const ignoreMissingName = allowMissingName && validation.missing;

  let helperText = '';
  if (validation.helperText) {
    helperText = validation.missing && allowMissingName ? '' : t(`basicDetails.validation.${validation.helperText}`);
  }

  return (
    <>
      <TextField
        label={t('basicDetails.name')}
        value={name}
        onChange={event => setName(event.target.value)}
        error={!validation.valid && (allowMissingName ? !validation.missing : true)}
        helperText={helperText}
        disabled={disabled}
        inputProps={{ maxLength: 300 }}
        fullWidth
        autoFocus={autoFocus}
      />
      <TextField
        label={t('basicDetails.role')}
        value={role}
        onChange={event => setRole(event.target.value)}
        disabled={disabled}
        inputProps={{ maxLength: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t('basicDetails.roleHelp')}>
                <HelpOutline />
              </Tooltip>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <PersonLanguagePicker
        value={languageCode}
        setValue={setLanguageCode}
        disabled={disabled}
      />
    </>
  );
};

export default Fields;
