export const goToTrackingSuspendedHelpArticle = () => {
  window.open('https://tracplus.freshdesk.com/en/support/solutions/articles/44002363604-rockair-quickstart-guide#GREEN-Button', '_blank');
};
export const goToFirmwareVersionOutdatedHelpArticle = (model: string) => {
  if (model.startsWith('RockSTAR')) {
    window.open('https://tracplus.freshdesk.com/support/solutions/articles/44001752992-updating-firmware-on-a-rockstar', '_blank');
  } else if (model.startsWith('RockFLEET')) {
    window.open('https://tracplus.freshdesk.com/support/solutions/articles/44002387968-updating-firmware-on-rockfleet', '_blank');
  } else if (model.startsWith('RockAIR') || model.startsWith('RockDASH')) {
    window.open('https://tracplus.freshdesk.com/support/solutions/articles/44001752993-updating-firmware-on-a-rockair', '_blank');
  }
};
