import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { useMemo } from 'react';

export interface AssetDevicePair {
  assetId: number
  deviceId: number
  asset: AssetBasic | undefined
  device: DeviceBasic | undefined
}

const select = <T = unknown>(x: T) => x;

export const useAssetsAndDevices = (assetAndDeviceId: { assetId: number, deviceId: number }[]): AssetDevicePair[] => {
  const assetsQuery = useGetAssetsList({ select }).query;
  const devicesQuery = useGetDevicesList().query;

  return useMemo(
    () => {
      if (!assetsQuery.data || !devicesQuery.data) return [];
      return assetAndDeviceId.map(({ assetId, deviceId }) => {
        const asset = assetsQuery.data.find(a => a.id === assetId);
        const device = devicesQuery.data.find(d => d.id === deviceId);
        return { assetId, deviceId, asset, device };
      });
    },
    [assetAndDeviceId, assetsQuery.data, devicesQuery.data],
  );
};
