import { useEffect, useMemo, useState } from 'react';
import { GeoJsonLayer } from '@deck.gl/layers';
import { Feature, FeatureCollection } from '@turf/helpers';
import { kml as kmlToGeoJson } from '@tmcw/togeojson';
import { memoize } from 'lodash';
import { hexToRGBArray } from 'helpers/color';
import ldb from 'helpers/ldb';

const hexToRgb = memoize(hexToRGBArray);

const getFillColor = memoize((data): ColorAlpha => {
  const rgb = hexToRgb(data.properties.fill ?? '#009650');
  const alpha = data.properties['fill-opacity'] === undefined ? 80 : data.properties['fill-opacity'] * 255;
  return [rgb[0], rgb[1], rgb[2], alpha];
});

const getStrokeColor = memoize((data): ColorAlpha => {
  const rgb = hexToRgb(data.properties.stroke ?? '#fff');
  const alpha = data.properties['stroke-opacity'] === undefined ? 150 : data.properties['stroke-opacity'] * 255;
  return [rgb[0], rgb[1], rgb[2], alpha];
});

const getStrokeWidth = memoize((data): number => (data.properties['stroke-width'] ?? 1) * 2);

const LABEL_COLOR: ColorAlpha = [255, 255, 255, 255];
const LABEL_BACKGROUND: ColorAlpha = [0, 0, 0, 128];
const LABEL_PADDING = [5, 3, 5, 5];

const useKmlLayers = (
  kmlFileNames: string[],
  showLabels: boolean,
): GeoJsonLayer[] => {
  const [data, setData] = useState<Record<string, FeatureCollection>>({});

  useEffect(() => {
    let kmlsToConsider = kmlFileNames;
    setData(oldData => {
      Object.keys(oldData).filter(ok => !kmlFileNames.includes(ok)).forEach(ok => {
        delete oldData[ok];
      });
      kmlsToConsider = kmlFileNames.filter(k => !Object.keys(oldData).includes(k));
      return oldData ?? {};
    });
    ldb.list(kmlsAndJsons => {
      const jsons = kmlsAndJsons.filter(k => k.startsWith('json-'));
      kmlsToConsider.forEach(filename => {
        if (jsons.includes(`json-${filename}`)) {
          console.log(`using cached representation for ${filename}`);
          ldb.get(`json-${filename}`, json => {
            setData(d => ({ ...d, [filename]: JSON.parse(json) as FeatureCollection }));
          });
        } else {
          console.log(`fetching kml for ${filename}`);
          ldb.get(filename, kml => {
            const xml = (new DOMParser()).parseFromString(kml, 'text/xml');
            const json = kmlToGeoJson(xml) as FeatureCollection;
            setData(d => ({ ...d, [filename]: json }));
            ldb.set(`json-${filename}`, JSON.stringify(json));
          });
        }
      });
    });
  }, [kmlFileNames]);

  const kmlLayers = useMemo(() => kmlFileNames.map(fn => new GeoJsonLayer({
    id: `kml-layer-${fn}`,
    data: data[fn],
    stroked: true,
    filled: true,
    pointType: 'circle',
    lineWidthUnits: 'pixels',
    lineWidthMinPixels: 2,
    getFillColor,
    getLineColor: getStrokeColor,
    getLineWidth: getStrokeWidth,
    getPointRadius: 10,
    pointRadiusMinPixels: 6,
    visible: true,
    pickable: true,
    parameters: { depthTest: false }
  })), [data, kmlFileNames]);

  const kmlLabelLayers = useMemo(() => kmlFileNames.map(fn => new GeoJsonLayer({
    id: `kml-label-layer-${fn}`,
    data: data[fn],
    pointType: 'text',
    getText: (f: Feature) => f.properties?.name,
    pickable: false,
    filled: false,
    stroked: false,
    textFontWeight: 550,
    getTextSize: 12,
    getTextColor: LABEL_COLOR,
    getTextAnchor: 'middle',
    getTextAlignmentBaseline: 'bottom',
    getTextPixelOffset: [0, 22],
    parameters: { depthTest: false },
    textFontSettings: { sdf: true, fontSize: 30, radius: 12 },
    textFontFamily: 'objektiv-mk2,sans-serif',
    textBackground: true,
    getTextBackgroundColor: LABEL_BACKGROUND,
    textBackgroundPadding: LABEL_PADDING,
    visible: showLabels,
  })), [data, kmlFileNames, showLabels]);

  return [...kmlLayers, ...kmlLabelLayers];
};

export default useKmlLayers;
