import React from 'react';
import Modal from 'react-modal';
import {
  Box,
  Typography
} from '@mui/material';

import useStyles from './displayModal-styles';

const DistressDialog = ({
  open,
  setOpen,
  modalHeader,
  shouldCloseOnOverlayClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      className={classes.modalContainer}
      overlayClassName={classes.modalOverlay}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Box className={classes.modalHeader}>
        <Typography variant="h2">{modalHeader}</Typography>
      </Box>
      <Box className={classes.modalContent}>{children}</Box>
    </Modal>
  );
};

export default DistressDialog;
