import { ProvisioningUploadResponse } from '@tracplus/serenity-client';
import { BASE_URL, HttpResponseError } from 'helpers/api';
import { getRefreshedToken } from 'apis/auth';

export const uploadProvisioningCSV = async (file: File): Promise<ProvisioningUploadResponse> => {
  const data = new FormData();
  data.append('file', file);
  const headers = new Headers({ 'X-TPC-Version': import.meta.env.PACKAGE_VERSION });

  const token = await getRefreshedToken();
  if (!token) throw new Error('Credentials are invalid');
  headers.set('Authorization', `Bearer ${token}`);

  const response = await fetch(`${BASE_URL}/provisioning/upload`, {
    method: 'POST',
    body: data,
    headers,
  } as RequestInit);

  HttpResponseError.detect(response);
  return response.json();
};
