import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Root = styled(Box)(({ theme }) => `
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
`);

export const globalStyles = theme => `
  html {
    --header-bar-height: 50px;
    --footer-bar-height: 35px;
    --drawer-width: 324px;
    --omnibox-width: 280px;
    --contextbox-width: 280px;
    --geofenceListHeader-height: 140px;
    --sidebar-closed-width: 42px;
    
    ${theme.breakpoints.up('xl')} {
      --header-bar-height: 56px;
      --drawer-width: 343px;
      --omnibox-width: 300px;
      --contextbox-width: 300px;
    }
  }
`;
