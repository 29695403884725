import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  alert: {
    alignItems: 'center',
    '& .MuiAlert-action': {
      padding: '0',
    }
  }
}));
