import React from 'react';
import { useTranslations } from 'use-intl';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useGetAssetBasic } from 'apis/rest/assets/hooks';
import { useGetDeviceBasic } from 'apis/rest/devices/hooks';
import { TemporalShare } from 'apis/rest/temporalShares/types';
import { AssetDetails } from '../assetDetails';
import { DeviceDetails } from '../deviceDetails';

interface DeviceProps {
  share: TemporalShare | undefined
}

const ShareDevice = ({ share }: DeviceProps) => {
  const t = useTranslations('pages.sharing');
  const assetQuery = useGetAssetBasic(share?.assetId ?? -1, share?.assetId !== undefined).query;
  const asset = assetQuery.data as AssetWithDevice | undefined;

  const deviceQuery = useGetDeviceBasic(share?.deviceId ?? -1, share?.deviceId !== undefined).query;
  const device = deviceQuery.data;

  return (
    <Stack direction="row" spacing={3}>
      <Box sx={{ minWidth: '30ch' }}>
        <Typography variant="h5" mb={1}>{t('asset.label')}</Typography>
        {asset ? <AssetDetails asset={asset} /> : (
          <Box>
            <Typography><Skeleton /></Typography>
            <Typography variant="body3"><Skeleton /></Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ minWidth: '30ch' }}>
        <Typography variant="h5" mb={1}>{t('device.label')}</Typography>
        {device ? <DeviceDetails device={device} /> : (
          <Box>
            <Typography><Skeleton /></Typography>
            <Typography variant="body3"><Skeleton /></Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ShareDevice;
