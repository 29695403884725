import React from 'react';
import { Grid } from '@mui/material';
import { SettingsMenuPanel } from './settingsMenu-styles';

interface SettingsMenuPanelGridProps {
  children: React.ReactNode
}

const SettingsMenuPanelGrid = ({ children }: SettingsMenuPanelGridProps): JSX.Element => (
  <SettingsMenuPanel>
    <Grid container spacing={3}>
      {children}
    </Grid>
  </SettingsMenuPanel>
);

export default SettingsMenuPanelGrid;
