import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslations } from 'use-intl';
import useSerialType, { SerialType } from 'hooks/settings/useSerialType';
import useSetSerialType from 'hooks/settings/useSetSerialType';

const items: SerialType[] = ['tpSerial', 'imei', 'manufacturerSerial'];

type SelectSerialTypeProps = Omit<TextFieldProps, 'value' | 'onChange' | 'select' | 'children'>

const SelectSerialType = forwardRef<HTMLDivElement, SelectSerialTypeProps>(({ inputProps, SelectProps, ...props }, ref): JSX.Element => {
  const serialType = useSerialType();
  const setSerialType = useSetSerialType();
  const t = useTranslations('components.SelectSerialType');

  return (
    <TextField
      {...props}
      ref={ref}
      select
      value={serialType}
      onChange={event => setSerialType(event.target.value as SerialType)}
      inputProps={{ 'aria-label': t('label'), ...inputProps }}
      SelectProps={{ onClick: event => event.stopPropagation(), ...SelectProps }}
    >
      {items.map(s => <MenuItem key={s} value={s}>{t(`serialType.${s}`)}</MenuItem>)}
    </TextField>
  );
});

export default SelectSerialType;
