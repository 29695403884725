import { HttpResponseError, serenityFetch } from 'helpers/api';
import { MarketSector, MarketSectorOrganisation, OrganisationMarketSector } from './types';

export const getMarketSectors = async (): Promise<MarketSector[]> => {
  const response = await serenityFetch('GET', '/market-sector', null);
  HttpResponseError.detect(response);
  return (await response.json()).marketSectors;
};

export const getMarketSector = async (id: string): Promise<MarketSector> => {
  const response = await serenityFetch('GET', `/market-sector/${id}`, null);
  HttpResponseError.detect(response);
  return (await response.json()).marketSector;
};

export const getMarketSectorsForOrganisation = async (organisationId: string): Promise<OrganisationMarketSector> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/market-sector`, null);
  HttpResponseError.detect(response);
  return response.json();
};

export const updateMarketSectorsForOrganisation = async (organisationId: string, body: OrganisationMarketSector): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/market-sector`, body);
  HttpResponseError.detect(response);
};

export const getAllMarketSectorOrganisations = async (): Promise<MarketSectorOrganisation[]> => {
  const response = await serenityFetch('GET', '/market-sector/organisation-details', null);
  HttpResponseError.detect(response);
  return (await response.json()).data;
};

export const createMarketSector = async (body: MarketSector): Promise<MarketSector> => {
  const response = await serenityFetch('POST', '/market-sector', { marketSector: body });
  HttpResponseError.detect(response);
  return (await response.json()).marketSector;
};

export const updateMarketSector = async (body: MarketSector): Promise<void> => {
  const response = await serenityFetch('PUT', `/market-sector/${body.id}`, { marketSector: body });
  HttpResponseError.detect(response);
};

export const deleteMarketSector = async (id: string): Promise<void> => {
  const response = await serenityFetch('DELETE', `/market-sector/${id}`, null);
  HttpResponseError.detect(response);
};
