export interface ValidateNameResult {
  valid: boolean
  changed: boolean
  missing?: boolean
  helperText?: 'nameMissing' | 'nameExists'
}

const validateName = (name: string | undefined, currentName: string, existingNames: string[]): ValidateNameResult => {
  const changed = name !== undefined && name.trim() !== currentName.trim();

  if (name !== undefined && !name.trim()) {
    return {
      valid: false,
      missing: true,
      helperText: 'nameMissing',
      changed,
    };
  }
  if (!changed) {
    return {
      valid: true,
      missing: false,
      changed,
    };
  }
  if (existingNames.map(s => s.trim()).filter(s => s.length > 0).includes(name.trim())) {
    return {
      valid: false,
      missing: false,
      helperText: 'nameExists',
      changed,
    };
  }
  return {
    valid: true,
    missing: false,
    changed,
  };
};

export default validateName;
