import React from 'react';

const Heartbeat = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 18.525882,28.942724 c -1.615511,-7.390949 2.947053,-9.751211 6.270536,-10.147129 4.658128,-0.554909 6.98597,4.206086 6.98597,4.206086 0,0 2.327842,-4.760995 6.985969,-4.206086 3.323485,0.395918 7.886048,2.75618 6.270538,10.147129 -1.35655,6.206208 -13.256507,14.808271 -13.256507,14.808271 0,0 -11.899955,-8.602063 -13.256506,-14.808271 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="6"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Heartbeat;
