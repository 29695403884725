import React from 'react';

const Helicopter = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 41.997184,1.7695312 c 0.740046,0.00894 3.512962,2.6039807 3.375,3.0292969 -1.921781,5.5154959 -4.441445,10.4384829 -6.728515,15.3105469 -0.22401,2.762521 -0.665346,5.460903 -1.109376,7.701172 3.483571,4.69555 12.270789,16.544456 13.066407,17.740234 0.382727,0.575229 -3.988749,1.951643 -4.31836,1.585938 -3.859454,-4.472806 -6.88761,-9.176313 -10,-13.638672 l -0.308593,12.724609 h 4.964843 l -0.04102,4.109375 -4.982422,0.02539 c 0,0 0.329919,12.00087 -2.109375,12.074219 C 31.243106,62.5087 31.57921,50.357421 31.57921,50.357421 l -4.982422,-0.02539 -0.04102,-4.109375 h 4.966797 l -0.31836,-13.052734 c 0,0 -0.486666,-1.729223 -1.03125,-4.210938 -5.26671,0.607084 -21.229408,2.442792 -22.71289,2.53711 -0.689526,0.04382 0.305611,-4.426696 0.787109,-4.529297 7.347701,-1.400315 14.35321,-1.463076 21.105469,-2.275391 -0.469409,-2.886037 -0.805126,-6.064031 -0.646484,-8.798828 0.459255,-7.9170035 5.072265,-7.4941405 5.072265,-7.4941405 0,0 2.515653,-0.1551689 3.982422,2.7031245 1.95822,-4.4927589 3.758469,-8.5990866 4.080078,-9.2480464 0.0097,-0.01934 0.02172,-0.036549 0.03711,-0.048828 0.0298,-0.023795 0.06981,-0.035752 0.119141,-0.035156 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="3"
      style={{
        paintOrder: 'markers fill stroke'
      }}
    />
  </svg>
);

export default Helicopter;
