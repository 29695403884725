import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import useStyles from '../navIcon-styles';

const SharingIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon fontSize="large" className={classes.navIcon} width="32" height="32" viewBox="0 0 32 32">
      <path d="M24,22a4.972,4.972,0,0,0-3.645,1.6l-7.923-4.843a7.016,7.016,0,0,1-1.045,1.706l7.928,4.846A4.918,4.918,0,0,0,19,27a5,5,0,1,0,5-5Z" />
      <path d="M24,0a5.006,5.006,0,0,0-5,5,4.918,4.918,0,0,0,.315,1.69l-7.928,4.846a7.016,7.016,0,0,1,1.045,1.706L20.355,8.4A4.993,4.993,0,1,0,24,0Z" />
      <path d="M6,21a5,5,0,1,1,5-5A5.006,5.006,0,0,1,6,21Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,6,13Z" />
    </SvgIcon>
  );
};

export default SharingIcon;
