import React, { useEffect, useRef } from 'react';
import LatLon from 'geodesy/latlon-spherical';
import { useCursor } from 'contexts/cursor/useCursor';

// TODO: this component is stupidity. Needs to be revised!
const CursorPosition = ({
  precision = 7
}) => {
  const canvasRef = useRef(null);

  const [cursor] = useCursor();
  const validCursor = cursor && cursor.latitude !== 0 && cursor.longitude !== 0;

  useEffect(() => {
    const canvas = canvasRef.current;
    const { width, height } = canvas.getBoundingClientRect();

    const { devicePixelRatio: ratio = 1 } = window;

    if (ratio !== 1) {
      const ctx = canvas.getContext('2d');
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      ctx.scale(ratio, ratio);
    }
  }, []);

  useEffect(() => {
    let posString = '';
    if (validCursor) {
      const lat = (cursor.latitude || 0);
      let lng = (cursor.longitude || 0);

      if (lng > 180) lng -= 360;
      else if (lng < -180) lng += 360;

      const n = `${lat.toFixed(precision)}, ${lng.toFixed(precision)}`;
      const dm = new LatLon(lat, lng).toString('dm');
      const dms = new LatLon(lat, lng).toString('dms');

      posString = `${n}     ${dm}     ${dms}`;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.font = '0.9em objektiv-mk2,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
    ctx.fillStyle = '#A0A7B4';
    ctx.fillText(posString, 4, 14);
  }, [cursor, precision, validCursor]);

  return <canvas width={500} height={20} ref={canvasRef} style={{ display: 'block', width: '500px', height: '20px' }} />;
};

export default CursorPosition;
