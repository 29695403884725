import React from 'react';
import {
  Box,
  Grid,
  List,
  ListItem, Table, TableBody, TableCell, TableHead, TableRow,
  Typography,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import useStyles from './detailPanel-styles';
import actions from 'actions';

interface DetailPanelProps {
  currentMessage: Incident['currentMessage']
  context: Incident['context']
}

const DetailPanel = ({
  currentMessage,
  context: {
    customer,
    voiceResponders,
    smsResponders,
    emailResponders,
  }
}: DetailPanelProps): JSX.Element => {
  const classes = useStyles();
  const t = useTranslation('pages.organisationSettings.contact');

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item sm={6}>
          <Typography variant="h5" className={classes.detailPanelTitle}>Current message</Typography>
          <List>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Fix UTC:</Typography>
              <Typography>{currentMessage.fixUtc}</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Fix quality:</Typography>
              <Typography>{currentMessage.fixQuality}</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Fix DOP:</Typography>
              <Typography>{currentMessage.fixDop}</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Latitude:</Typography>
              <Typography>{currentMessage.position84.lat}</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Longitude:</Typography>
              <Typography>{currentMessage.position84.lon}</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Altitude:</Typography>
              <Typography>{currentMessage.altitudeM84}m</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Speed:</Typography>
              <Typography>{currentMessage.speedKph}km/h</Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Typography className={classes.label}>Track:</Typography>
              <Typography>{currentMessage.trackDt}</Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="h5" className={classes.detailPanelTitle}>Primary contact</Typography>
          {customer ? (
            <List>
              <ListItem className={classes.listItem}>
                <Typography className={classes.label}>Name:</Typography>
                <Typography>{customer.name}</Typography>
              </ListItem>
              {customer.phoneNumber1?.sqlParameterValue && (
                <ListItem className={classes.listItem}>
                  <Typography className={classes.label}>Phone:</Typography>
                  <Typography>{customer.phoneNumber1.sqlParameterValue}</Typography>
                </ListItem>
              )}
              {customer.phoneNumber2?.sqlParameterValue && (
                <ListItem className={classes.listItem}>
                  <Typography className={classes.label}>Alternate phone:</Typography>
                  <Typography>{customer.phoneNumber2.sqlParameterValue}</Typography>
                </ListItem>
              )}
              <ListItem className={classes.listItem}>
                <Typography className={classes.label}>Address:</Typography>
                <Typography>
                  {customer.address}<br />
                  {customer.city}<br />
                  {customer.state} {customer.postcode}<br />
                  {customer.country}
                </Typography>
              </ListItem>
            </List>
          ) : <Typography>None set</Typography>}
        </Grid>
      </Grid>

      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item sm={6}>
          <Typography variant="h5" className={classes.detailPanelTitle}>Voice responders</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell sx={{ width: '50%' }}>Phone</TableCell>
                <TableCell align="center" sx={{ width: 0 }}>Language</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {voiceResponders.map(responder => (
                <TableRow key={responder.id}>
                  <TableCell>{responder.name}</TableCell>
                  <TableCell>{responder.phoneNumber.sqlParameterValue}</TableCell>
                  <TableCell align="center">{responder.languageCode.toUpperCase()}</TableCell>
                </TableRow>
              ))}
              {!voiceResponders.length && (
                <TableRow>
                  <TableCell colSpan={3}>No voice responders set</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="h5" className={classes.detailPanelTitle}>SMS responders</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell sx={{ width: '50%' }}>Phone</TableCell>
                <TableCell align="center" sx={{ width: 0 }}>Language</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {smsResponders.map(responder => (
                <TableRow key={responder.id}>
                  <TableCell>{responder.name}</TableCell>
                  <TableCell>{responder.phoneNumber.sqlParameterValue}</TableCell>
                  <TableCell align="center">{responder.languageCode.toUpperCase()}</TableCell>
                </TableRow>
              ))}
              {!smsResponders.length && (
                <TableRow>
                  <TableCell colSpan={3}>No SMS responders set</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item sm={6}>
          <Typography variant="h5" className={classes.detailPanelTitle}>Email responders</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell sx={{ width: '50%' }}>Email</TableCell>
                <TableCell align="center" sx={{ width: 0 }}>Language</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailResponders.map(responder => (
                <TableRow key={responder.id}>
                  <TableCell>{responder.name}</TableCell>
                  <TableCell>{responder.email}</TableCell>
                  <TableCell align="center">{responder.languageCode.toUpperCase()}</TableCell>
                </TableRow>
              ))}
              {!emailResponders.length && (
                <TableRow>
                  <TableCell colSpan={3}>No email responders set</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailPanel;
