import React, { useContext, useMemo, useState } from 'react';
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import DisplayModal from 'components/shared/displayModal/displayModal-view';
import { NotificationContext } from 'contexts/notification/notificationContext';
import StatusIcon from './statusIcon-view';
import { SystemStatusServiceWidget } from './serviceWidget/serviceWidget-view';
import { SystemHealth } from './types';

const StatusButton = styled(ButtonBase)(({ theme }) => `
  width: statusIconWidth;
  padding: ${theme.spacing(0, 3)};
  border-right: ${theme.border.default};
  &:hover {
    background-color: ${theme.palette.common.lightBlue};
  }
`);

const SystemStatus = () => {
  const t = useTranslations('statusbar.systemStatus');
  const [dialogOpen, setDialogOpen] = useState(false);
  const notificationContext = useContext(NotificationContext);
  const notificationsStatus = useMemo(
    () => (notificationContext.connected === undefined ? 'YELLOW' : (notificationContext.connected ? 'GREEN' : 'RED')),
    [notificationContext]
  );

  const health: SystemHealth = {
    connection: notificationsStatus,
    services: [{
      name: 'Notifications',
      status: notificationsStatus
    }]
  };

  return (
    <>
      <Tooltip title={t('tooltip')} placement="top">
        <StatusButton onClick={() => setDialogOpen(true)} aria-label={t('tooltip')}>
          <StatusIcon status={health.connection} />
        </StatusButton>
      </Tooltip>

      <DisplayModal
        open={dialogOpen}
        setOpen={(open: boolean) => setDialogOpen(open)}
        modalHeader={t('title')}
        shouldCloseOnOverlayClick
      >
        <Stack spacing={2}>
          {health.services?.map(service => (
            <SystemStatusServiceWidget key={service.name} service={service} />
          ))}
          <Typography gutterBottom>{`TracPlus Cloud Version: ${import.meta.env.PACKAGE_VERSION}`}</Typography>
        </Stack>
      </DisplayModal>
    </>
  );
};

export default SystemStatus;
