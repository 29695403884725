import React, { useMemo, useRef, useState } from 'react';
import { useTranslations } from 'use-intl';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Delete, Edit, ExpandMore, Group } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetTemporalSharesFromList } from 'apis/rest/temporalShares/hooks';
import { TemporalGroupShare, TemporalShare } from 'apis/rest/temporalShares/types';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { isDefined } from 'utils/type';
import usePermissions from 'hooks/session/usePermissions';
import {
  DeviceCluster,
  groupData,
  GroupedData,
  isTemporalGroupShare,
  Period,
  RecipientCluster,
  selectAssetsByDeviceId,
  selectDevicesById,
  sortRecipientClusters,
  sortSharesByRecipient,
  useDeviceClusters,
  useFilterState,
  useRecipientClusters,
  useSortSharesByDevice,
} from './helpers';
import { AssetDetails, GroupAssetDetails } from './assetDetails';
import ShareTime from './shareTime';
import { DeviceDetails, GroupDeviceDetails } from './deviceDetails';
import Filters from './filters';
import CreateDialog from './dialogs/createDialog';
import EditDialog from './dialogs/editDialog';
import DeleteDialog from './dialogs/deleteDialog';
import { GroupParticipant, OrganisationParticipant } from './selectParticipants';
import VirtualizedClusters from './virtualizedClusters';
import useTimezone from "hooks/session/useTimezone";

type DialogState = { type: 'CREATE', cloneShare?: TemporalShare }
  | { type: 'EDIT', shareId: number, isGroupShare: boolean }
  | { type: 'DELETE', shareId: number, isGroupShare: boolean };

interface ShareRowProps {
  share: TemporalShare | TemporalGroupShare
  asset: AssetWithDevice | undefined
  device: DeviceBasic | undefined
  isAssetLoading: boolean
  isDeviceLoading: boolean
  timezone: string
  clusteredBy: 'device' | 'recipient'
  displayActions: boolean
  onEdit: (share: TemporalShare | TemporalGroupShare) => void
  onDelete: (share: TemporalShare | TemporalGroupShare) => void
}

const ShareRow = ({ share, asset, device, isAssetLoading, isDeviceLoading, timezone, clusteredBy, displayActions, onEdit, onDelete }: ShareRowProps) => {
  const t = useTranslations('pages.sharing');
  return (
    <>
      <TableRow sx={share.notes ? { 'td, th': { border: 0 } } : { '&:last-child td, &:last-child th': { border: 0 } }}>
        {clusteredBy === 'device' && (
          isTemporalGroupShare(share) ? (
            <TableCell><Stack direction="row" spacing={1} alignItems="center"><Group /><span>{share.groupName}</span></Stack></TableCell>
          ) : (
            <TableCell>{share.organisationName}</TableCell>
          )
        )}
        {clusteredBy === 'recipient' && <TableCell sx={{ py: 2 }}><AssetDetails asset={asset} isLoading={isAssetLoading} /></TableCell>}
        {clusteredBy === 'recipient' && <TableCell sx={{ py: 2 }}><DeviceDetails device={device} isLoading={isDeviceLoading} /></TableCell>}
        <TableCell>
          <ShareTime share={share} timezone={timezone} />
        </TableCell>
        <TableCell>
          {share.canViewCurrent && !share.canViewHistory && t('trails.live')}
          {share.canViewCurrent && share.canViewHistory && t('trails.all')}
          {!share.canViewCurrent && share.canViewHistory && t('trails.historic')}
          {!share.canViewCurrent && !share.canViewHistory && t('trails.none')}
        </TableCell>
        <TableCell align="center">
          {share.canViewForms && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canViewForms')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendTextMessages && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendTextMessages')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canSendConfiguration && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canSendConfiguration')}</Box>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {share.canEditCallSign && (
            <>
              <Check />
              <Box sx={visuallyHidden}>{t('permissionLabels.canEditCallSign')}</Box>
            </>
          )}
        </TableCell>
        {displayActions && (
          <TableCell sx={{ py: 2 }}>
            <Stack direction="row">
              <Tooltip title={t('actions.edit')}>
                <IconButton onClick={() => onEdit(share)}><Edit /></IconButton>
              </Tooltip>
              <Tooltip title={t('actions.delete')}>
                <IconButton onClick={() => onDelete(share)}><Delete /></IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        )}
      </TableRow>
      {share.notes && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell colSpan={100} sx={{ pt: 0 }}><Typography variant="body3">{t('columns.notes')}: {share.notes}</Typography></TableCell>
        </TableRow>
      )}
    </>
  );
};

const useRecipients = (data: GroupedData | undefined) => useMemo(() => {
  const organisationRecipients = data?.organisationIds.map<OrganisationParticipant | undefined>(organisationId => {
    const share = data.sharesByOrganisationId[organisationId][0];
    if ('groupId' in share) return undefined;
    return {
      organisationId,
      name: share.organisationName,
    };
  }).filter(isDefined) ?? [];

  const groupRecipients = data?.groupIds.map<GroupParticipant>(groupId => {
    const share = data.sharesByGroupId[groupId][0];
    return {
      groupId,
      name: share.groupName,
    };
  }) ?? [];

  return [...organisationRecipients, ...groupRecipients].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
}, [data]);

const useDialog = () => {
  const [state, setDialog] = useState<DialogState>();

  return {
    state,
    close: () => setDialog(undefined),
    create: () => setDialog({ type: 'CREATE' }),
    clone: (share: TemporalShare) => setDialog({ type: 'CREATE', cloneShare: share }),
    edit: (share: TemporalShare) => setDialog({ type: 'EDIT', shareId: share.id, isGroupShare: 'groupId' in share }),
    delete: (share: TemporalShare) => setDialog({ type: 'DELETE', shareId: share.id, isGroupShare: 'groupId' in share }),
  };
};

const SharesFrom = (): JSX.Element => {
  const t = useTranslations('pages.sharing');
  const timezone = useTimezone();

  const user = usePermissions();

  const dialog = useDialog();

  const [filters, setFilters] = useFilterState();

  const [clusteredBy, setClusteredBy] = useState<'device' | 'recipient'>('recipient');

  const sharesQuery = useGetTemporalSharesFromList({ select: groupData });

  const assetsByDeviceIdQuery = useGetAssetsList({ select: selectAssetsByDeviceId }).query;
  const assetsByDeviceId = assetsByDeviceIdQuery.data;

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const devicesById = devicesByIdQuery.data;

  const recipientClusters = useRecipientClusters(sharesQuery.data, filters, sortRecipientClusters);
  const deviceClusters = useDeviceClusters(sharesQuery.data, filters, assetsByDeviceId);

  const clusters = clusteredBy === 'device' ? deviceClusters : recipientClusters;

  const recipients = useRecipients(sharesQuery.data);

  const [groupingMenuOpen, setGroupingMenuOpen] = useState(false);
  const groupingMenuAnchor = useRef<HTMLButtonElement>(null);

  const sortSharesByDevice = useSortSharesByDevice(assetsByDeviceId);
  const sortShares = clusteredBy === 'recipient' ? sortSharesByDevice : sortSharesByRecipient;

  return (
    <Box pb={10}>
      <Paper elevation={0} sx={{ p: 3, mb: 6 }}>
        <Stack direction="row" spacing={3} flexWrap="wrap" useFlexGap>
          <Filters
            state={filters}
            setFilters={setFilters}
            assetsByDeviceId={assetsByDeviceId ?? {}}
            devicesById={devicesById ?? {}}
            data={sharesQuery.data}
            participants={recipients}
          />

          <Button
            ref={groupingMenuAnchor}
            variant="outlined"
            onClick={() => setGroupingMenuOpen(true)}
            endIcon={<ExpandMore />}
            sx={{ minHeight: '4rem' }}
            aria-label={t('groupByLabel')}
          >
            {clusteredBy === 'recipient' && t('groupBy.recipient')}
            {clusteredBy === 'device' && t('groupBy.device')}
          </Button>
          <Menu
            anchorEl={groupingMenuAnchor.current}
            open={groupingMenuOpen}
            onClose={() => setGroupingMenuOpen(false)}
            sx={{ mt: 1 }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setClusteredBy('recipient');
                setGroupingMenuOpen(false);
              }}
              sx={{ minWidth: '20ch' }}
            >
              <Radio checked={clusteredBy === 'recipient'} />
              <Typography>{t('groupByOptions.recipient')}</Typography>
            </MenuItem>
            <MenuItem
              sx={{ minWidth: '20ch' }}
              onClick={() => {
                setClusteredBy('device');
                setGroupingMenuOpen(false);
              }}
            >
              <Radio checked={clusteredBy === 'device'} />
              <Typography>{t('groupByOptions.device')}</Typography>
            </MenuItem>
          </Menu>

          {user.canEditShare && (
            <Stack direction="row" flex={1} justifyContent="flex-end">
              <Button variant="contained" onClick={dialog.create}>{t('actions.create')}</Button>
            </Stack>
          )}
        </Stack>
      </Paper>

      {sharesQuery.isLoading ? (
        <Paper elevation={0} sx={{ p: 3 }} component="article">
          <Typography textAlign="center">{t('loading')}</Typography>
        </Paper>
      ) : (
        clusters.length ? (
          <VirtualizedClusters<RecipientCluster | DeviceCluster>
            clusters={clusters}
            renderCluster={(virtualItem, cluster, virtualizer) => (
              <Paper
                key={virtualItem.key}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                elevation={0}
                sx={{ pb: 2, mb: 3 }}
                component="article"
              >
                <Box p={3} component="header">
                  {'deviceId' in cluster ? (
                    <>
                      <Typography variant="subtitle1">{t('sharedAsset')}</Typography>
                      <Stack direction="row" spacing={3}>
                        <Box width="40ch">
                          <GroupAssetDetails asset={assetsByDeviceId?.[cluster.deviceId]} isLoading={assetsByDeviceIdQuery.isLoading} />
                        </Box>
                        <GroupDeviceDetails device={devicesById?.[cluster.deviceId]} isLoading={devicesByIdQuery.isLoading} />
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle1">{t('sharedToRecipient')}</Typography>
                      {'groupId' in cluster ? (
                        <Typography variant="h3">
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Group />
                            <span>{cluster.groupName}</span>
                          </Stack>
                        </Typography>
                      ) : (
                        <Typography variant="h3">{cluster.organisationName}</Typography>
                      )}
                    </>
                  )}
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      {clusteredBy === 'recipient' && <TableCell sx={{ width: '25%', whiteSpace: 'nowrap' }}>{t('columns.asset')}</TableCell>}
                      {clusteredBy === 'recipient' && <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.device')}</TableCell>}
                      {clusteredBy === 'device' && <TableCell sx={{ width: '20%', whiteSpace: 'nowrap' }}>{t('columns.recipient')}</TableCell>}
                      <TableCell sx={{ width: '15%', whiteSpace: 'nowrap' }}>{t('columns.time')}</TableCell>
                      <TableCell sx={{ width: '10%', whiteSpace: 'nowrap' }}>{t('columns.trails')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.forms')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.message')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.configure')}</TableCell>
                      <TableCell align="center" sx={{ width: '0%', whiteSpace: 'nowrap' }}>{t('columns.callsign')}</TableCell>
                      {user.canEditShare && <TableCell sx={{ width: '0%' }} />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortShares(cluster.shares).map(share => (
                      <ShareRow
                        key={isTemporalGroupShare(share) ? `groupShare-${share.id}` : `share-${share.id}`}
                        share={share}
                        asset={assetsByDeviceId?.[share.deviceId]}
                        device={devicesById?.[share.deviceId]}
                        isAssetLoading={assetsByDeviceIdQuery.isLoading}
                        isDeviceLoading={devicesByIdQuery.isLoading}
                        timezone={timezone}
                        clusteredBy={clusteredBy}
                        displayActions={user.canEditShare}
                        onEdit={dialog.edit}
                        onDelete={dialog.delete}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          />
        ) : (
          <Paper elevation={0} sx={{ p: 3 }} component="article">
            <Alert severity="info">
              {(!!filters.deviceIds.length || !!filters.organisationIds.length || filters.period !== Period.All) ? t('noSharesForFilters') : t('noSharesFrom')}
            </Alert>
          </Paper>
        )
      )}

      {user.canEditShare && (
        <>
          <CreateDialog
            open={dialog.state?.type === 'CREATE'}
            onClose={dialog.close}
            cloneShare={dialog.state?.type === 'CREATE' ? dialog.state?.cloneShare : undefined}
          />
          <EditDialog
            shareId={dialog.state?.type === 'EDIT' ? dialog.state.shareId : undefined}
            isGroupShare={dialog.state?.type === 'EDIT' ? dialog.state.isGroupShare : undefined}
            onClose={dialog.close}
            onClone={share => dialog.clone(share)}
          />
          <DeleteDialog
            shareId={dialog.state?.type === 'DELETE' ? dialog.state.shareId : undefined}
            isGroupShare={dialog.state?.type === 'DELETE' ? dialog.state.isGroupShare : undefined}
            onClose={dialog.close}
          />
        </>
      )}
    </Box>
  );
};

export default SharesFrom;
