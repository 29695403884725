import React from 'react';

const A320 = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m 34.407726,58.840078 c 0,0 8.680556,2.054988 8.698503,1.786348 0.116252,-1.740258 -0.198723,-3.491041 -0.440271,-3.732589 -0.241548,-0.241548 -7.069051,-3.661934 -7.099246,-4.537546 -0.03019,-0.875612 0.667773,-2.916528 0.905717,-6.129017 0.237948,-3.212489 0.01515,-13.132144 0.01515,-13.132144 l 5.794678,-2.62e-4 c 0,0 17.438807,5.391492 17.45292,5.002501 0.122768,-3.38373 -1.266218,-4.542034 -1.266218,-4.542034 -1.573023,-0.79716 -14.578086,-7.397317 -14.738521,-7.525274 -0.160435,-0.127958 0.225223,-1.332854 0.197877,-2.804145 -0.03585,-1.929049 -0.510522,-3.18888 -0.533623,-3.311193 -0.04292,-0.227293 -3.827253,-0.467457 -4.257664,0.01071 -0.331303,0.368062 -0.132324,3.79992 -0.132324,3.79992 0,0 -2.178716,-0.933695 -2.359878,-1.296018 C 36.463664,22.067013 36.459817,13.988864 36.369233,9.731578 36.278653,5.4742931 33.35527,1.8820896 32.381116,1.8820896 c -0.974154,0 -3.715845,3.5922035 -3.806425,7.8494884 -0.09058,4.257286 -0.09443,12.335435 -0.275593,12.697757 -0.181162,0.362323 -2.359878,1.296018 -2.359878,1.296018 0,0 0.198979,-3.431858 -0.132324,-3.79992 -0.430411,-0.478165 -4.214742,-0.238001 -4.257664,-0.01071 -0.0231,0.122313 -0.497773,1.382144 -0.533623,3.311193 -0.02735,1.471291 0.358312,2.676187 0.197877,2.804145 -0.160435,0.127957 -13.1654986,6.728114 -14.7385211,7.525274 0,0 -1.3889862,1.158304 -1.266218,4.542034 0.014113,0.388991 17.4529201,-5.002501 17.4529201,-5.002501 l 5.794678,2.62e-4 c 0,0 -0.222803,9.919655 0.01515,13.132144 0.237944,3.212489 0.93591,5.253405 0.905717,6.129017 -0.03019,0.875612 -6.857698,4.295998 -7.099246,4.537546 -0.241549,0.241548 -0.556523,1.992331 -0.440271,3.732589 0.01795,0.26864 8.698503,-1.786348 8.698503,-1.786348 0,0 0.578674,3.238318 1.935768,3.248267 1.357093,-0.01 1.935768,-3.248267 1.935768,-3.248267 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default A320;
