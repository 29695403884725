import React from 'react';

const SarRequest = ({ size = 16 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
    <path
      className="icon-base"
      d="M14.16,1.01l-6-1C8.06,0,7.94,0,7.84,0.01l-6,1C1.35,1.09,1,1.51,1,2v7c0,3.87,3.13,7,7,7s7-3.13,7-7V2
C15,1.51,14.65,1.09,14.16,1.01z"
    />
    <path className="icon-detail" fill="#fff" d="M8.85,10.56H7.15c0,0.47,0.38,0.85,0.85,0.85S8.85,11.03,8.85,10.56z" />
    <path
      className="icon-detail"
      fill="#fff"
      d="M10.99,9.28h-0.21c-0.3-0.3-0.64-0.73-0.64-1.28V6.72c0-1.2-0.94-2.13-2.13-2.13S5.87,5.52,5.87,6.72V8
c0,0.55-0.34,0.98-0.64,1.28H5.01c-0.26,0-0.43,0.17-0.43,0.43s0.17,0.43,0.43,0.43h5.98c0.26,0,0.43-0.17,0.43-0.43
S11.24,9.28,10.99,9.28z"
    />
  </svg>
);

export default SarRequest;
