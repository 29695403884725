import moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import momentDurationFormatSetup from 'moment-duration-format';

moment.tz.setDefault('utc');
momentDurationFormatSetup(moment);

window.moment = moment;
export default moment;
