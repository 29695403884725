import React from 'react';

const Car = ({ fillColor, outlineOpacity, outlineColor }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 20.358066,55.081346 V 48.824105 H 17.978551 V 38.777268 h 2.203254 V 27.584738 H 17.714161 V 17.27351 c 0,0 2.431302,0.03919 2.436485,-0.280428 0.09613,-5.928303 10.342387,-5.572667 10.342387,-5.572667 0,0 10.183935,-0.449112 10.342387,5.572667 0.0084,0.319553 2.436485,0.280428 2.436485,0.280428 v 10.311228 h -2.467644 v 11.19253 h 2.203254 V 48.824105 H 40.628 v 6.257241 c -6.756645,0 -13.513289,0 -20.269934,0 z"
      fill={fillColor}
      fillOpacity="1"
      strokeOpacity={outlineOpacity}
      stroke={outlineColor}
      strokeWidth="5"
      style={{
        paintOrder: 'markers stroke fill'
      }}
    />
  </svg>
);

export default Car;
