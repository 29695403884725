import React from 'react';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'use-intl';
import { TPButton } from 'components/shared/button/TPButton';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import {
  closeDeleteSearchPatternDialog,
  removeSearchPattern,
  selectDeleteSearchPatternDialogState
} from 'slices/searchPatterns.slice';

export const DeleteSearchPatternDialog: React.FC = () => {
  const t = useTranslations('dialogs.searchPatterns');
  const dispatch = useDispatch();

  const { open, searchPattern } = useSelector(selectDeleteSearchPatternDialogState);

  const onClose = () => {
    dispatch(closeDeleteSearchPatternDialog());
  };

  const onConfirm = () => {
    if (!searchPattern) return;
    dispatch(removeSearchPattern({ id: searchPattern.id }));
    onClose();
  };

  if (!searchPattern) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <TPDialogTitle>{t('delete.title')}</TPDialogTitle>
      <DialogContent>
        <Stack pt={3}>
          <Typography>{t('delete.description', { name: searchPattern.name })}</Typography>
        </Stack>
      </DialogContent>
      <TPDialogActions>
        <TPButton onClick={onClose} variant="outlined">{t('cancel')}</TPButton>
        <TPButton onClick={onConfirm} color="error">{t('confirm')}</TPButton>
      </TPDialogActions>
    </Dialog>
  );
};
