import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  gridContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem 2rem',
    display: 'grid',
    gridColumnGap: 30,
    gridTemplateColumns: '1fr 1fr'
  },
  listItem: {
    padding: '3px 0'
  },
  detailPanelTitle: {
    fontWeight: 600,
    color: theme.palette.common.black
  },
  detailPanelDescription: {
    paddingTop: 10
  },
  label: {
    marginRight: 3,
    fontWeight: 500
  }
}));
