import { useMemo } from 'react';

export const useDeviceIdByAssets = (assetArray: AssetWithDevice[]) : number[] | undefined => (
  useMemo(
    () => {
      if (!assetArray) return ([]);
      return (Object.values(assetArray).map(a => a.deviceId));
    },
    [assetArray]
  ));
