import makeStyles from '@mui/styles/makeStyles';
import { amber } from '@mui/material/colors';

export default makeStyles(theme => ({
  wrapper: {
    height: '100%',
    borderLeft: theme.border.default,
    backgroundColor: theme.palette.common.white,
    textAlign: 'left',
    padding: theme.spacing(5)
  },
  list: {
    position: 'sticky',
    top: '30px'
  },
  link: {
    textDecoration: 'none',
    padding: theme.spacing(0),
    '&.is-current p': {
      position: 'relative',
      fontWeight: '700',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        bottom: '-2px',
        left: '-8px',
        width: '3px',
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  item: {
    padding: theme.spacing(1, 2),
    '&.subSection': {
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      '& p': {
        fontWeight: '400'
      }
    }
  },
  text: {
    fontSize: '1rem',
    lineHeight: 1.4,
    fontWeight: '500'
  },
  TOCbadge: {
    '&.warning:not(.error)': {
      '& span.MuiBadge-colorError': {
        top: '13px',
        right: '-15px',
        color: 'black',
        backgroundColor: amber[700]
      }
    },
    '&.error': {
      '& span.MuiBadge-colorError': {
        top: '13px',
        right: '-15px'
      }
    }
  }
}));
